import React, {Component, Fragment} from "react";
import {
    Avatar,
    Dialog,
    DialogActions,
    DialogTitle,
    Divider,
    FormControl,
    IconButton,
    MenuItem,
    Select,
    Typography,
    withStyles
} from "@material-ui/core";
import {connect} from "react-redux";
import {Trans, withTranslation} from "react-i18next";
import DialogContent from "@material-ui/core/DialogContent";
import _ from "lodash";
import Flex from "components/grid/Flex";
import {AddCircle, Block, ExpandMore, Launch, RemoveCircle as DeleteIcon} from "@material-ui/icons";
import H4Avatar from "components/avatar/H4Avatar";
import connector from "./OrganizationTreeMemberAssignmentPopup.connect";
import {audit, dustyGrey, christiGreen, silver, guardsmanRed, htmlGrey, htmlRed} from 'components/colors/Colors';
import utils from 'utils/Utils';
import BlankAvatar from 'assets/img/blank_avatar.png';
import Dropzone from "react-dropzone";
import DropAreaImage from 'assets/img/drop-area.png';
import FileGallery from "components/gallery/FileGallery";
import update from "immutability-helper";
import {MEMBERSHIPS} from "domain/audit/OrganizationLevel.model";
import configs from "configs/Configs";

export let instances = {};

export function findFileIndexByDownloadUrl(array, item) {
    return array.findIndex(itemInArray => itemInArray.downloadUrl === item.downloadUrl);
}

export const styles = theme => ({
    dialogContainer: {
        minHeight: 700,
        width: 1000,
        height: '70%',
        padding: 24
    },
    dialogContainerMinimized: {
        minHeight: 700,
        width: 500,
        height: '70%',
        padding: 24
    },
    dialogTitleRoot: {
        padding: "0px 0px 20px",
        marginRight: 4
    },
    dialogContentRoot: {
        padding: "0px 0px 0px"
    },
    dialogActionsRoot: {
        padding: "0px 0px 0px",
        margin: '0px 0px'
    },
    contentContainer: {
        display: 'flex',
        flex: '1 1 auto',
        justifyContent: 'space-between',
        flexWrap: 'wrap',
        width: '100%'
    },
    dialogLabel: {
        marginBottom: 10
    },
    userName: {
        marginLeft: 15,
        marginRight: 15,
        width: 180,
        fontSize: '1rem'
    },
    membership: {
        width: 140,
        marginRight: 10
    },
    timeZone: {
        width: 200,
        marginRight: 10
    },
    avatar: {
        width: 30,
        height: 30,
    },
    dropDownImage: {},
    dragAndDropText: {
        color: htmlGrey,
        fontStyle: 'italic'
    },
    warningText: {
        color: htmlRed,
        fontStyle: 'italic'
    },
});

const TimeZoneAssignment = (props) => {
    const {classes, timeZone, handleTimeZoneChange, timeZones} = props;
    return (
        <Fragment>
            <Typography classes={{root: classes.dialogLabel}}>
                <Trans i18nKey={"audit-administration.time-zone-assignment"}/>
            </Typography>
            <Flex item container style={{padding: 10}} alignItems={'center'}>
                <FormControl>
                    <Select
                        className={classes.timeZone}
                        value={timeZone}
                        onChange={handleTimeZoneChange}
                        name={'timeZone'}
                        IconComponent={ExpandMore}
                        disableUnderline
                    >
                        {
                            timeZones
                                .map((timeZone, index) => {
                                    return (
                                        <MenuItem key={index} value={timeZone}>
                                            {timeZone}
                                        </MenuItem>
                                    )
                                })
                        }
                    </Select>
                </FormControl>
            </Flex>
        </Fragment>
    );
};

const FileUpload = (props) => {
    const {classes, translate, filteredFiles, deleteAttachment, onDrop, isInvalidFile} = props;
    return (
        <Fragment>
            <FileGallery
                sources={filteredFiles}
                deleteSource={deleteAttachment}
                imageSize={450}
            />
            <Fragment>
                {
                    _.isEmpty(filteredFiles) &&
                    <Fragment>
                        <Dropzone onDrop={onDrop}>
                            {({getRootProps, getInputProps}) => {
                                return (
                                    <div {...getRootProps()}>
                                        <input {...getInputProps()} accept={'image/*'}/>
                                        {
                                            <img className={classes.dropDownImage} src={DropAreaImage}
                                                 alt={''}/>
                                        }
                                    </div>
                                )
                            }}
                        </Dropzone>
                        <Typography
                            className={classes.dragAndDropText}>{translate("attachments.dragAndDrop")}</Typography>
                        {
                            isInvalidFile &&
                            <Typography
                                className={classes.warningText}>{translate("attachments.uploadWarning", {
                                limit: (configs.getMaxFileUploadSizeInBytes() / 1024 / 1024)
                            })}</Typography>
                        }
                    </Fragment>
                }

            </Fragment>
        </Fragment>
    );
};

const NewMemberAssignment = (props) => {
    const {
        classes, user, assignableUsers, membership, translatedMemberships, assigningEnabled, onMembershipChange, onUserChange, addMemberShip, members
    } = props;
    const assignedMemberships = _.get(members.find(member =>
        _.get(member, 'user.businessId') === _.get(user, 'businessId')), 'memberships', []
    );
    const unassignedMemberships = translatedMemberships
        .filter(membership => !assignedMemberships.includes(membership.name));
    return (
        <Flex item container style={{marginBottom: 30}} alignItems={'center'}>
            {
                user ?
                    <H4Avatar {...{user}}/>
                    :
                    <Avatar src={BlankAvatar} className={classes.avatar}/>
            }
            <FormControl>
                <Select
                    className={classes.userName}
                    value={user || ""}
                    name={'user'}
                    onChange={onUserChange}
                    IconComponent={ExpandMore}
                    disableUnderline
                    renderValue={user => user ? utils.formattedUserName(user) : <em>Select user</em>}
                    displayEmpty
                >
                    {
                        assignableUsers.map((user, index) => {
                            return (
                                <MenuItem key={index} value={user}>
                                    <H4Avatar {...{user, style: {marginRight: 10}}}/>
                                    <Typography
                                        className={classes.userNameInSelector}> {utils.formattedUserName(user)}</Typography>
                                </MenuItem>
                            )
                        })
                    }
                </Select>
            </FormControl>
            <FormControl>
                <Select
                    className={classes.membership}
                    value={membership}
                    onChange={onMembershipChange}
                    name={'membership'}
                    IconComponent={ExpandMore}
                    disableUnderline
                >
                    {
                        unassignedMemberships
                            .map((membership, index) => {
                                return (
                                    <MenuItem key={index} value={membership.name}>
                                        {membership.label}
                                    </MenuItem>
                                )
                            })
                    }
                </Select>
            </FormControl>
            <IconButton
                disabled={!assigningEnabled}
                onClick={addMemberShip(user, membership)}
            >
                <AddCircle style={{color: assigningEnabled ? christiGreen : silver}}/>
            </IconButton>

        </Flex>
    );
};

const EditMemberList = (props) => {
    const {
        classes, organizationLevelMembers, translatedMemberships, updateMembership, removeMembership, loggedInUser
    } = props;

    return (
        <Fragment>
            {
                organizationLevelMembers.map((organizationLevelMember, index) => (
                    <Fragment key={index}>
                        {
                            organizationLevelMember.memberships.map((membership) => (
                                    <Fragment key={index + '-' + membership}>

                                        <Flex item container grow={0} alignItems={'center'}>
                                            <H4Avatar {...{user: organizationLevelMember.user}}/>
                                            <Typography
                                                className={classes.userName}
                                                style={{color: _.get(organizationLevelMember, 'user.businessId')
                                                        === loggedInUser.businessId && dustyGrey}}>
                                                {utils.formattedUserName(organizationLevelMember.user)}
                                            </Typography>
                                            <FormControl>
                                                <Select
                                                    className={classes.membership}
                                                    value={membership}
                                                    onChange={updateMembership(organizationLevelMember, membership)}
                                                    name={'organizationLevelMembership'}
                                                    IconComponent={ExpandMore}
                                                    disableUnderline
                                                    disabled={_.get(organizationLevelMember, 'user.businessId')
                                                    === loggedInUser.businessId}
                                                >
                                                    {
                                                        translatedMemberships
                                                            .map((membership, index) => {
                                                                return (
                                                                    <MenuItem key={index} value={membership.name}>
                                                                        {membership.label}
                                                                    </MenuItem>
                                                                )
                                                            })
                                                    }
                                                </Select>
                                            </FormControl>
                                            {
                                                _.get(organizationLevelMember, 'user.businessId') === loggedInUser.businessId ?
                                                    <IconButton>
                                                        <Block style={{color: silver}}/>
                                                    </IconButton>
                                                    :
                                                    <IconButton
                                                        onClick={removeMembership(organizationLevelMember, membership)}>
                                                        <DeleteIcon style={{color: guardsmanRed}}/>
                                                    </IconButton>
                                            }
                                        </Flex>
                                        <Divider style={{width: '100%'}}/>

                                    </Fragment>
                                )
                            )
                        }
                    </Fragment>
                ))
            }
        </Fragment>
    );
};

class OrganizationTreeMemberAssignmentPopup extends Component {

    constructor(props) {
        super(props);
        const inProgressFiles = _.differenceBy(instances[this.props.id] || [], props.files || [], 'filename');
        this.state = {
            settingsOpen: false,
            user: undefined,
            membership: 'AUDITOR',
            dialogOpen: props.dialogOpen,
            timeZone: props.selectedOrganizationLevel.timeZone,
            organizationLevel: props.selectedOrganizationLevel || {},
            inProgressFiles,
            files: _.cloneDeep(props.files || []),
            isInvalidFile: false
        };
    }

    handleMembershipChange = event => {
        this.setState({
            [event.target.name]: event.target.value,
        });
    };

    handleTimeZoneChange = event => {
        this.props.updateOrganizationLevelTimeZone(this.state.organizationLevel.businessId, event.target.value).then(() => {
            this.setState({
                [event.target.name]: event.target.value,
            });
        });
    };

    handleUserChange = event => {
        const assignedMemberships = _.get(this.state.organizationLevel.members
            .find(member => _.get(member, 'user.businessId') === event.target.value.businessId), 'memberships', []);
        const unassignedMemberships = this.translateMemberShips()
            .filter(membership => !assignedMemberships.includes(membership.name));
        this.setState({
            user: event.target.value,
            membership: unassignedMemberships[0].name,
        });
    };

    addMemberShip = (user, membership) => event => {
        this.props.addOrganizationLevelMembership(this.state.organizationLevel.businessId, user.businessId, membership).then(() => {
            const members = this.state.organizationLevel.members;
            const member = members.find(member => _.get(member, 'user.businessId') === user.businessId);
            if (member) {
                member.memberships.push(membership);
            } else {
                members.push({
                    memberships: [membership],
                    user: user
                });
            }
            this.setState({
                organizationLevel: {
                    ...this.state.organizationLevel,
                    members: members
                },
                user: undefined
            });
        });
    };

    updateMembership = (member, membership) => event => {
        const newMembership = event.target.value;
        this.props.updateOrganizationLevelMembership(this.state.organizationLevel.businessId, _.get(member, 'user.businessId'), newMembership, membership).then(() => {
            const members = this.state.organizationLevel.members;
            const matchingMember = members.find(m => _.get(m, 'user.businessId') === _.get(member, 'user.businessId'));
            const membershipIndex = matchingMember.memberships.indexOf(membership);
            matchingMember.memberships[membershipIndex] = newMembership;
            matchingMember.memberships = Array.from(new Set(matchingMember.memberships));
            this.setState({
                organizationLevel: {
                    ...this.state.organizationLevel,
                    members: members
                }
            });
        });
    };

    removeMembership = (member, membership) => event => {
        this.props.removeOrganizationLevelMembership(this.state.organizationLevel.businessId, _.get(member, 'user.businessId'), membership).then(() => {
            const members = this.state.organizationLevel.members;
            const matchingMember = members.find(m => _.get(m, 'user.businessId') === _.get(member, 'user.businessId'));
            const membershipIndex = matchingMember.memberships.indexOf(membership);
            matchingMember.memberships.splice(membershipIndex, 1);
            this.setState({
                organizationLevel: {
                    ...this.state.organizationLevel,
                    members: members
                }
            });

        });
    };

    translateMemberShips = () => {
        return MEMBERSHIPS.map(
            membership => ({
                name: membership,
                label: this.props.t(("audit-administration.membership." + membership))
            }));
    };

    componentWillUnmount() {
        _.set(instances, [this.props.id], this.state.inProgressFiles);
    }

    preventEventPropagation = event => {
        event.preventDefault();
        event.stopPropagation();
    };

    openAttachment = event => {
        this.preventEventPropagation(event);
        this.setState({
            isAttachmentOpen: true
        });
        _.get(this.props, 'onOpen') && this.props.onOpen();
    };

    closeAttachment = event => {
        this.setState({
            isAttachmentOpen: false
        });
        _.get(this.props, 'onClose') && this.props.onClose();
    };

    deleteAttachment = file => event => {
        this.props.deleteFileOnUrl(file.downloadUrl)
            .then(response => {
                _.get(this.props, 'onSuccessfulFileDelete')
                && this.props.onSuccessfulFileDelete(file);
                this.setState(oldState => {
                    const index = findFileIndexByDownloadUrl(oldState.files, file);
                    if (index !== -1) {
                        return {
                            files: update(oldState.files, {
                                $splice: [[index, 1]]
                            })
                        };
                    }
                });
            })
    };

    onDrop = (acceptedFiles, rejectedFiles) => {
        const validFiles = this.validateFiles(acceptedFiles);
        this.setState({
            isInvalidFile: acceptedFiles.length !== validFiles.length
        });
        validFiles.forEach(file => {
            const filePlaceholder = this.createFilePlaceholder(file);
            const id = this.props.id || utils.uuid();
            this.setState(
                this.addFilePlaceholder(filePlaceholder),
                () => this.props.uploadFile(file, id, this.onFileUploadProgress(filePlaceholder), 'ORGANIZATION')
                    .then(response => {
                        this.enrichSuccessfullyUploadedFile(response, filePlaceholder);
                        _.get(this.props, 'onSuccessfulFileUpload')
                        && this.props.onSuccessfulFileUpload(response.payload.data);
                    })
                    .catch(error => console.log('Error: ', error))
                    .then(() => {
                        this.removeFailedFile(filePlaceholder);
                    })
            );
        });
    };

    validateFiles = files => {
        return files.filter(file => {
            return file.size && file.size < 26214400;
        })
    };

    addFilePlaceholder = filePlaceholder => oldState => {
        return {
            inProgressFiles: update(oldState.inProgressFiles, {
                $push: [filePlaceholder]
            })
        };
    };

    createFilePlaceholder = file => {
        return {
            referenceId: utils.uuid(),
            filename: file.name,
            progress: 0,
            downloadUrl: utils.uuid(),
        }
    };

    onFileUploadProgress = filePlaceholder => progressEvent => {
        this.setState(oldState => {
            const index = findFileIndexByDownloadUrl(oldState.inProgressFiles, filePlaceholder);
            return {
                inProgressFiles: update(oldState.inProgressFiles, {
                    [index]: {
                        progress: {$set: (progressEvent.loaded / progressEvent.total) * 100}
                    }
                })
            };
        });
    };

    enrichSuccessfullyUploadedFile = (response, filePlaceholder) => {
        this.setState(oldState => {
            const index = findFileIndexByDownloadUrl(oldState.inProgressFiles, filePlaceholder);
            return {
                files: update(oldState.files, {
                    $push: [{
                        ...response.payload.data,
                        progress: 100
                    }]
                }),
                inProgressFiles: update(oldState.inProgressFiles, {
                    $splice: [[index, 1]]
                })
            };
        });
    };

    removeFailedFile = filePlaceholder => {
        this.setState(oldState => {
            const index = findFileIndexByDownloadUrl(oldState.inProgressFiles, filePlaceholder);
            if (index !== -1) {
                if (oldState.inProgressFiles[index].downloadUrl) {
                    return oldState;
                } else {
                    return {
                        inProgressFiles: update(oldState.inProgressFiles, {
                            $splice: [[index, 1]]
                        })
                    };
                }
            }
        });
    };

    openSettings = () => {
        this.setState({
            settingsOpen: true
        })
    };

    render() {
        const {classes, onClose, allUsers, loggedInUser, stopEvent, t: translate, timeZones} = this.props;
        const {user, membership, dialogOpen, organizationLevel, timeZone, isInvalidFile, files, inProgressFiles, settingsOpen} = this.state;
        const assignableUsers = _.differenceBy(allUsers, (organizationLevel.members || [])
            .filter(member => member.memberships.length === MEMBERSHIPS.length)
            .map(member => member.user), 'businessId');
        const translatedMemberships = this.translateMemberShips();
        const assigningEnabled = user && membership;
        const sortedFiles = _.sortBy(_.concat(files || [], inProgressFiles), 'creationDate');
        return (
            <Dialog
                open={dialogOpen}
                classes={{paper: settingsOpen ? classes.dialogContainer : classes.dialogContainerMinimized}}
                fullWidth
                fullScreen
                onClose={onClose}
                onClick={stopEvent}
            >
                <DialogTitle classes={{root: classes.dialogTitleRoot}}>

                    <Flex item container grow={0} direction={'row'}>
                        <Flex item container grow={0} direction={'column'} alignItems={'flex-start'}
                              justifyContent={'center'}>
                            <Trans i18nKey={"audit-administration.nodeMembers"}/>
                        </Flex>
                        <Flex item container grow={1} direction={'column'} alignItems={'flex-end'}
                              justifyContent={'center'} style={{paddingLeft: 15}}>
                            {
                                !settingsOpen &&
                                <IconButton onClick={this.openSettings}>
                                    <Launch style={{color: audit}}/>
                                </IconButton>
                            }
                        </Flex>
                    </Flex>
                </DialogTitle>
                <DialogActions classes={{root: classes.dialogActionsRoot}}>
                </DialogActions>
                <DialogContent classes={{root: classes.dialogContentRoot}}>
                    <div className={classes.contentContainer}>

                        <Flex item container grow={0} direction={'row'} alignItems={'flex-start'}
                              justifyContent={'center'}>

                            <Flex item container grow={0} direction={'column'} alignItems={'flex-start'}
                                  justifyContent={'center'}>
                                <Typography classes={{root: classes.dialogLabel}}>
                                    <Trans i18nKey={"audit-administration.new-membership-assigment"}/>
                                </Typography>
                                {
                                    assignableUsers.length === 0
                                        ?
                                        <Typography classes={{root: classes.userName}} style={{marginBottom: 30}}>
                                            <Trans i18nKey={"audit-administration.all-members-assigned"}/>
                                        </Typography>
                                        :
                                        <NewMemberAssignment {...{
                                            classes,
                                            user,
                                            membership,
                                            assignableUsers,
                                            members: organizationLevel.members,
                                            translatedMemberships,
                                            assigningEnabled,
                                            onMembershipChange: this.handleMembershipChange,
                                            onUserChange: this.handleUserChange,
                                            addMemberShip: this.addMemberShip
                                        }}
                                        />
                                }
                                <Typography classes={{root: classes.dialogLabel}}>
                                    <Trans i18nKey={"audit-administration.edit-membership"}/>
                                </Typography>
                                <EditMemberList {...{
                                    classes,
                                    organizationLevelMembers: organizationLevel.members,
                                    translatedMemberships,
                                    loggedInUser,
                                    updateMembership: this.updateMembership,
                                    removeMembership: this.removeMembership
                                }}
                                />
                            </Flex>
                            {

                                settingsOpen &&
                                <Flex item container grow={0} direction={'column'} alignItems={'flex-start'}
                                      justifyContent={'center'} style={{paddingLeft: 15}}>
                                    <TimeZoneAssignment {...{
                                        classes,
                                        timeZone,
                                        timeZones,
                                        handleTimeZoneChange: this.handleTimeZoneChange
                                    }}
                                    />
                                    <Divider style={{width: '100%'}}/>
                                    <FileUpload
                                        {...{
                                            classes,
                                            translate,
                                            filteredFiles: sortedFiles,
                                            deleteAttachment: this.deleteAttachment,
                                            onDrop: this.onDrop,
                                            isInvalidFile
                                        }}
                                    />
                                </Flex>
                            }
                        </Flex>

                    </div>
                </DialogContent>
                <DialogActions classes={{root: classes.dialogActionsRoot}}>
                </DialogActions>
            </Dialog>
        );
    }
}

export default withStyles(styles, {withTheme: true})(connect(connector.mapStateToProps, connector.mapDispatchToProps)(withTranslation()(OrganizationTreeMemberAssignmentPopup)));
