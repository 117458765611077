import {
    createSchedule,
    deleteSchedule,
    editSchedule,
    fetchEmptySchedule,
    fetchQuestionnaires,
    fetchScheduleWithQuestionnaires,
    loadCalendar,
    cloneSchedule
} from "./ScheduleEdit.action";
import {fetchAuditManagementConfigurationData} from "scenes/configuration/GeneralConfiguration.action";

export default {
    mapStateToProps: (state) => {
        return {
            schedule: state.scheduleEditReducer.schedule,
            selectedOrganizationLevel: state.organizationTreeReducer.selectedOrganizationLevel,
            organizationFilterBusinessId: state.organizationTreeReducer.organizationFilterBusinessId,
            durationUnits: state.generalConfigurationReducer.auditConfiguration.durationUnits,
            auditTypes: state.generalConfigurationReducer.auditConfiguration.auditTypes,
            auditResultDefinitions: state.generalConfigurationReducer.auditConfiguration.auditResultDefinitions,
            questionnaires: state.scheduleEditReducer.questionnaires,
            calendarPreviewDates: state.scheduleEditReducer.calendarPreviewDates
        };
    },
    mapDispatchToProps: (dispatch) => {
        return {
            createSchedule: (schedule) => dispatch(createSchedule(schedule)),
            editSchedule: (schedule) => dispatch(editSchedule(schedule)),
            fetchScheduleWithQuestionnaires: (scheduleId, levelBusinessId) => dispatch(fetchScheduleWithQuestionnaires(scheduleId, levelBusinessId)),
            fetchEmptySchedule: () => dispatch(fetchEmptySchedule()),
            deleteSchedule: (scheduleId) => dispatch(deleteSchedule(scheduleId)),
            fetchAuditManagementConfigurationData: () => dispatch(fetchAuditManagementConfigurationData()),
            fetchQuestionnaires: (levelBusinessId) => dispatch(fetchQuestionnaires(levelBusinessId)),
            loadCalendar: (duration, frequency, startDay, endDay, oneTimeOnly) => dispatch(loadCalendar(duration, frequency, startDay, endDay, oneTimeOnly)),
            cloneSchedule: (schedule) => dispatch(cloneSchedule(schedule))
        };
    }
};
