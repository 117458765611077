import {
    archiveTasks,
    listHistoryTasksFromServer,
    moveTasksToKanbanBoard,
    removeTasksFromKanbanBoard,
    sortTasks,
    listProjectTasks
} from 'scenes/tasks/Tasks.action'
import {createTask, fetchTask, cloneTask, editTask} from "scenes/tasks/task-edit/TaskEdit.action";
import {changeFilter} from 'scenes/tasks/task-list/TaskList.action';
import {
    removeUnseenActionTag,
    removeUnseenAttachmentTag,
    removeUnseenChecklistTag,
    removeUnseenCommentTag
} from "scenes/tasks/Tags.action";

import {
    toggleProjectFilterSelection,
    toggleProjectFilterSelectionByOrganizationLevelId,
    loadPreviousProjectSelection
} from 'scenes/tasks/Project.action';

export default {
    mapStateToProps: (state) => {
        return {
            activeTasks: state.tasksReducer.activeTasks,
            backlogTasks: state.tasksReducer.backlogTasks,
            historyTasks: state.tasksReducer.historyTasks,
            isTaskLoadingReady: state.tasksReducer.isTaskLoadingReady,
            isProjectLoaded: state.tasksReducer.isProjectLoaded,
            user: state.mainReducer.user,
            forceRerender: state.tasksReducer.forceRerender,
            applicationOnline: state.mainReducer.online,
            configurationFromBackend: state.tasksReducer.configurationFromBackend,
            tagSuggestions: state.tasksReducer.configuration.freeTags,
            selectedProject: state.projectReducer.selectedProject,
            selectedUserNames: state.projectReducer.selectedUserNames,
            todoTaskBusinessId: state.tasksReducer.todoTaskBusinessId,
            closableWorkflowStatus: state.tasksReducer.configuration.closableWorkflowStatus,
            startingWorkflowStatus: state.tasksReducer.configuration.startingWorkflowStatus,
            activeWorkflowStatuses: state.tasksReducer.configuration.activeWorkflowStatuses,
            backlogWorkflowStatuses: state.tasksReducer.configuration.backlogWorkflowStatuses,
            backlogWorkflowStatus: state.tasksReducer.configuration.backlogWorkflowStatus,
            historyWorkflowStatuses: state.tasksReducer.configuration.historyWorkflowStatuses,
        };
    },
    mapDispatchToProps: (dispatch) => {
        return {
            sortTasks:(draggedTaskId, destinationTaskId) => dispatch(sortTasks(draggedTaskId, destinationTaskId)),
            moveTasksToKanbanBoard: tasks => dispatch(moveTasksToKanbanBoard(tasks)),
            removeTasksFromKanbanBoard: tasks => dispatch(removeTasksFromKanbanBoard(tasks)),
            archiveTasks: tasks => dispatch(archiveTasks(tasks)),
            editTask: tasks => dispatch(editTask(tasks)),
            createTask: task => dispatch(createTask(task)),
            cloneTask: businessId => dispatch(cloneTask(businessId)),
            removeUnseenActionTag: task => dispatch(removeUnseenActionTag(task)),
            removeUnseenCommentTag: task => dispatch(removeUnseenCommentTag(task)),
            removeUnseenChecklistTag: task => dispatch(removeUnseenChecklistTag(task)),
            removeUnseenAttachmentTag: task => dispatch(removeUnseenAttachmentTag(task)),
            changeFilter: filter => dispatch(changeFilter(filter)),
            fetchTask: businessId => dispatch(fetchTask(businessId)),
            listHistoryTasksFromServer: () => dispatch(listHistoryTasksFromServer()),
            toggleProjectFilterSelectionByOrganizationLevelId: businessId => dispatch(toggleProjectFilterSelectionByOrganizationLevelId(businessId)),
            toggleProjectFilterSelection: businessId => dispatch(toggleProjectFilterSelection(businessId)),
            listProjectTasks: () => dispatch(listProjectTasks()),
            loadPreviousProjectSelection: () => dispatch(loadPreviousProjectSelection()),
        };
    }
};
