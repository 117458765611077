import React, {Component, Fragment} from 'react';
import _ from 'lodash';
import ConfirmationDialog from "components/confirmation-dialog/ConfirmationDialog";
import TreeFilter from 'components/tree-filter/TreeFilter';
import {connect} from 'react-redux';
import connector from './OrganizationTree.connect';
import OrganizationTreeRootLeaf from './OrganizationTreeRootLeaf';

export class OrganizationTree extends Component {

    state = {
        organizationDeleteCandidate: '',
        allSelected: false
    };

    createOrganization = (parentBusinessId, name) => {
        return this.props.createOrganizationLevel(parentBusinessId, name).then(() => {
            return this.props.fetchOrganizationLevels()
        });
    };

    editOrganization = (businessId, name) => {
        return this.props.editOrganizationLevel(businessId, name).then(() => {
            return this.props.fetchOrganizationLevels()
        });
    };

    deleteOrganization = () => {
        return this.props.deleteOrganizationLevel(this.state.organizationDeleteCandidate)
            .then(() => {
                this.toggleDeleteOrganizationDialog(undefined);
            });
    };

    toggleDeleteOrganizationDialog = (organizationBusinessId) => {
        if (_.isEmpty(organizationBusinessId)) {
            this.setState({
                organizationDeleteCandidate: ''
            });
        } else {
            return this.props.listOpenAuditsForOrganizationLevel(organizationBusinessId).then(() => {
                this.setState({
                    organizationDeleteCandidate: organizationBusinessId
                });
            });
        }
    };


    toggleOrganizationTree = () => {
        if (this.props.isOrganizationTreeOpen) {
            this.props.closeOrganizationLevelTree()
        } else {
            this.props.openOrganizationLevelTree();
        }
    };

    toggleFilterSelection = (businessId) => {
        if (this.props.multiSelect) {
            return this.props.toggleFilterMultiSelection(businessId)
        } else {
            return this.props.toggleFilterSelection(businessId)
        }
    };

    toggleSelectAll = () => {
        this.props.toggleFilterSelectAll();
        this.setState({
            allSelected: !this.state.allSelected
        });
    };

    componentDidMount() {
        if (this.props.questionnaireEditCallback) {
            this.props.questionnaireEditCallback.then(() => {
                this.props.clearFilterMultiSelection();
            })
        } else {
            this.props.fetchUsers();
            this.props.fetchOrganizationManagementConfigurationData();
            this.props.fetchOrganizationLevels();
        }
    }

    render() {
        const {
            organizationTree, toggleFilterOpen, isOrganizationTreeOpen, containerStyle, style, organizationFilterBusinessId,
            fetchOrganizationLevels, multiSelect, applicationOnline, updateRootNodeTitle,
            isRootTitleEditable, alwaysOpen, noEdit,openAudits, showOrganizationTitle
        } = this.props;
        const {organizationDeleteCandidate, allSelected} = this.state;
        const {createOrganization, editOrganization, deleteOrganization, toggleDeleteOrganizationDialog, toggleOrganizationTree, toggleFilterSelection, toggleSelectAll} = this;
        const selectedOrganizationLevel = organizationTree.find(organization => organization.businessId === organizationFilterBusinessId);
        return (
            <Fragment>
                <TreeFilter
                    {...{
                        isDroppable: false,
                        containerStyle,
                        style,
                        open: isOrganizationTreeOpen,
                        forceOpen: alwaysOpen,
                        nodes: organizationTree,
                        customSelectedAll: true,
                        toggleTree: toggleOrganizationTree,
                        toggleSelection: toggleFilterSelection,
                        toggleOpen: toggleFilterOpen,
                        multiSelect: multiSelect,
                        editNode: editOrganization,
                        applicationOnline: applicationOnline,
                        noEdit,
                        selectedNode: selectedOrganizationLevel,
                        showNodeTitle: showOrganizationTitle,
                        RootLeafComponent: function renderRootLeaf(props) {
                            return (
                                <OrganizationTreeRootLeaf
                                    {...{
                                        ...props,
                                        selectedOrganizationLevel,
                                        toggleSelection: toggleFilterSelection,
                                        toggleOpen: toggleFilterOpen,
                                        deleteSelection: toggleDeleteOrganizationDialog,
                                        addNode: createOrganization,
                                        addChildNode: createOrganization,
                                        editNode: editOrganization,
                                        fetchOrganizationLevels,
                                        multiSelect,
                                        updateRootNodeTitle,
                                        isRootTitleEditable,
                                        noEdit,
                                        isAllSelected: allSelected,
                                        toggleSelectAll: toggleSelectAll,
                                    }}
                                />
                            )
                        }
                    }}
                />
                <ConfirmationDialog {...{
                    titleStyles: {
                        fontSize:openAudits.length > 0 && '0.75rem'
                    },
                    dialogOpen: !_.isEmpty(organizationDeleteCandidate),
                    onDialogClose: toggleDeleteOrganizationDialog,
                    onConfirm: deleteOrganization,
                    confirmationTextKey: openAudits.length > 0 ?
                        "audit-administration.deleteOrganizationLevelConfirmationWithAuditCancellation"
                        :
                        "audit-administration.deleteOrganizationLevelConfirmation"
                }}/>
            </Fragment>
        );
    }
}

export default (connect(connector.mapStateToProps, connector.mapDispatchToProps)(OrganizationTree));
