export const FETCH_SCHEDULE = '@schedule_edit/FETCH_SCHEDULE';
export const FETCH_SCHEDULE_SUCCESS = '@schedule_edit/FETCH_SCHEDULE_SUCCESS';
export const FETCH_SCHEDULE_FAIL = '@schedule_edit/FETCH_SCHEDULE_FAIL';
export const FETCH_EMPTY_SCHEDULE = '@schedule_edit/FETCH_EMPTY_SCHEDULE';

export const DELETE_SCHEDULE = '@schedule_edit/DELETE_SCHEDULE';
export const DELETE_SCHEDULE_SUCCESS = '@schedule_edit/DELETE_SCHEDULE_SUCCESS';
export const DELETE_SCHEDULE_FAIL = '@schedule_edit/DELETE_SCHEDULE_FAIL';

export const CREATE_SCHEDULE = '@schedule_edit/CREATE_SCHEDULE';
export const CREATE_SCHEDULE_SUCCESS = '@schedule_edit/CREATE_SCHEDULE_SUCCESS';
export const CREATE_SCHEDULE_FAIL = '@schedule_edit/CREATE_SCHEDULE_FAIL';

export const EDIT_SCHEDULE = '@schedule_edit/EDIT_SCHEDULE';
export const EDIT_SCHEDULE_SUCCESS = '@schedule_edit/EDIT_SCHEDULE_SUCCESS';
export const EDIT_SCHEDULE_FAIL = '@schedule_edit/EDIT_SCHEDULE_FAIL';

export const FETCH_QUESTIONNAIRES = '@schedule_edit/FETCH_QUESTIONNAIRES';
export const FETCH_QUESTIONNAIRES_SUCCESS = '@schedule_edit/FETCH_QUESTIONNAIRES_SUCCESS';
export const FETCH_QUESTIONNAIRES_FAIL = '@schedule_edit/FETCH_QUESTIONNAIRES_FAIL';

export const LOAD_CALENDAR_PREVIEW  = '@schedule_edit/LOAD_CALENDAR_PREVIEW';
export const LOAD_CALENDAR_PREVIEW_SUCCESS  = '@schedule_edit/LOAD_CALENDAR_PREVIEW_SUCCESS';
export const LOAD_CALENDAR_PREVIEW_FAIL  = '@schedule_edit/LOAD_CALENDAR_PREVIEW_FAIL';

export const CLONE_SCHEDULE = '@schedule/CLONE_SCHEDULE';
