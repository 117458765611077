import {toggleSelectedUserNames, listProjects} from 'scenes/tasks/Project.action';
export default {
    mapStateToProps: (state) => {
        return {
            user: state.mainReducer.user,
            selectedProject: state.projectReducer.selectedProject,
            selectedUserNames: state.projectReducer.selectedUserNames
        };
    },
    mapDispatchToProps: (dispatch) => {
        return {
            toggleSelectedUserNames: username => dispatch(toggleSelectedUserNames(username)),
            listProjects: () => dispatch(listProjects()),
        };
    }
};
