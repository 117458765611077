import * as actionTypes from "./ScheduleEdit.action.types";
import {createScheduleInput, editScheduleInput, mapNextScheduleInput} from "domain/audit/Schedule.model";
import {
    fetchOrganizationLevels,
    toggleFilterSelection
} from "scenes/audit-manager/administration/organization-tree/OrganizationTree.action.js";
import _ from "lodash";

export function fetchScheduleWithQuestionnaires(scheduleId) {
    return (dispatch, getState) => {
        return dispatch(fetchSchedule(scheduleId)).then(() => {
            if (getState().organizationTreeReducer.organizationTree.initialized) {
                return dispatch(fetchQuestionnaires(getState().scheduleEditReducer.schedule.levelBusinessId))
            } else {
                return dispatch(fetchOrganizationLevels()).then(() => {
                    const levelBusinessId = getState().scheduleEditReducer.schedule.levelBusinessId;
                    dispatch(toggleFilterSelection(levelBusinessId));
                    return dispatch(fetchQuestionnaires(levelBusinessId));
                });
            }
        })
    }
}

export function fetchSchedule(scheduleId) {
    return (dispatch) => {
        return dispatch({
            type: actionTypes.FETCH_SCHEDULE,
            payload: {
                client: 'graphql',
                request: {
                    method: 'post',
                    data: {
                        query: `
                                query
                                ($scheduleId: String!){
                                    fetchAuditSchedule(scheduleId: $scheduleId) {
                                        businessId
                                        name
                                        status
                                        questionnaire {
                                            businessId
                                            name
                                            status
                                            numberOfQuestions
                                            numberOfRandomizedQuestions
                                        }
                                        type {
                                            label
                                        }
                                        duration 
                                        resultDefinition {
                                            businessId
                                        }
                                        auditor {
                                            businessId 
                                        }
                                        nextSchedule
                                        organizationLevel {
                                            businessId
                                        }
                                        reoccurance {
                                            unit
                                            amount
                                        }      
                                        shift {
                                            businessId
                                            label
                                            startTime
                                            endTime
                                            durationInHours
                                            ordinal
                                        }
                                    }
                                }
                           `,
                        variables: {
                            scheduleId: scheduleId
                        }
                    }
                }
            }
        })
    }
}

export function fetchQuestionnaires(levelBusinessId) {
    return {
        type: actionTypes.FETCH_QUESTIONNAIRES,
        payload: {
            client: 'graphql',
            request: {
                method: 'post',
                data: {
                    query: `
                                query
                                ($levelId: String!){
                                    listQuestionnairesForOrganizationLevel(levelId: $levelId) {
                                        businessId
                                        name
                                        numberOfQuestions
                                        numberOfRandomizedQuestions
                                        auditType {
                                            label
                                        }                                                                         
                                    }
                                }
                           `,
                    variables: {
                        levelId: levelBusinessId
                    }
                }
            }
        }
    };
}


export function fetchEmptySchedule() {
    return (dispatch, getState) => {
        const selectedOrganizationLevel = getState().organizationTreeReducer.selectedOrganizationLevel;
        if (!_.isEmpty(selectedOrganizationLevel)) {
            return dispatch(fetchQuestionnaires(selectedOrganizationLevel.businessId)).then(() => {
                return dispatch({
                    type: actionTypes.FETCH_EMPTY_SCHEDULE,
                    data: {
                        user: getState().mainReducer.user,
                        selectedOrganizationLevel: getState().organizationTreeReducer.selectedOrganizationLevel
                    }
                });
            });
        } else {
            return dispatch(fetchOrganizationLevels()).then(() => {
                const rootLevelBusinessId = getState().organizationTreeReducer.organizationTree[0].businessId;
                return dispatch(fetchQuestionnaires(rootLevelBusinessId)).then(() => {
                    return dispatch({
                        type: actionTypes.FETCH_EMPTY_SCHEDULE,
                        data: {
                            user: getState().mainReducer.user,
                            selectedOrganizationLevel: getState().organizationTreeReducer.selectedOrganizationLevel
                        }
                    });
                });
            });
        }
    }
}

export function deleteSchedule(auditScheduleId) {
    return {
        type: actionTypes.DELETE_SCHEDULE,
        payload: {
            client: 'graphql',
            request: {
                method: 'post',
                data: {
                    query: `mutation removeAuditSchedule($auditScheduleId: String){
    	                        removeAuditSchedule(auditScheduleId: $auditScheduleId)
                            }
                           `,
                    variables: {
                        auditScheduleId
                    }
                }
            }
        }
    }
}

export function createSchedule(data) {
    return (dispatch, getState) => {
        const input = createScheduleInput(data);
        return dispatch({
            type: actionTypes.CREATE_SCHEDULE,
            data: {
                user: getState().mainReducer.user,
                selectedOrganizationLevel: getState().organizationTreeReducer.selectedOrganizationLevel
            },
            payload: {
                client: 'graphql',
                request: {
                    method: 'post',
                    data: {
                        query: `mutation createAuditSchedule($createAuditScheduleInput: CreateAuditScheduleInput!){
    	                        createAuditSchedule(createAuditScheduleInput: $createAuditScheduleInput) {   
    	                            businessId   	                            
    	                        }
                            }
                           `,
                        variables: {
                            createAuditScheduleInput: input
                        }
                    }
                }
            }
        });
    }
}

export function editSchedule(data) {
    return (dispatch, getState) => {
        const input = editScheduleInput(data);
        return dispatch({
            type: actionTypes.EDIT_SCHEDULE,
            data: {
                user: getState().mainReducer.user,
                selectedOrganizationLevel: getState().organizationTreeReducer.selectedOrganizationLevel
            },
            payload: {
                client: 'graphql',
                request: {
                    method: 'post',
                    data: {
                        query: `mutation editAuditSchedule($editAuditScheduleInput: EditAuditScheduleInput!){
    	                        editAuditSchedule(editAuditScheduleInput: $editAuditScheduleInput) {   
    	                            businessId   	                            
    	                        }
                            }
                           `,
                        variables: {
                            editAuditScheduleInput: input
                        }
                    }
                }
            }
        });
    }
}

export function loadCalendar(duration, frequency, startDay, endDay, oneTimeOnly) {
    return (dispatch) => {
        const input = mapNextScheduleInput(duration, frequency, startDay, endDay, oneTimeOnly);
        return dispatch({
            type: actionTypes.LOAD_CALENDAR_PREVIEW,
            payload: {
                client: 'graphql',
                request: {
                    method: 'post',
                    data: {
                        query: `    	                   
    	                         query ($nextScheduleInput: NextScheduleInput!) {
                                    fetchNextScheduleDates(nextScheduleInput: $nextScheduleInput) {    	                   
    	                            start
    	                            end
    	                            days
    	                         }   
                            }
                           `,
                        variables: {
                            nextScheduleInput: input
                        }
                    }
                }
            }
        });
    }
}

export function cloneSchedule(schedule){
    return {
        type: actionTypes.CLONE_SCHEDULE,
        data: {
            schedule
        }
    }
}
