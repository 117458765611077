import React, {Component, Fragment} from "react";
import {Button, Checkbox, Paper, Typography, withStyles} from "@material-ui/core";
import {Trans, withTranslation} from "react-i18next";
import Flex from "components/grid/Flex";
import _ from "lodash";
import {AUDIT_ADMINISTRATION_QUESTIONS, routeTo} from "routes/routes";
import QuestionTab from './QuestionTab';
import TranslationTabs from './TranslationTabs';
import QuestionSelectors from './QuestionSelectors';
import UnsafeSaveDialog from './UnsafeSaveDialog';
import {htmlBlack} from 'components/colors/Colors';
import {PENDING} from "domain/audit/Questionnaire.model";
import {CheckBox as CheckBoxIcon} from "@material-ui/icons";
import classNames from "classnames";
import {christiGreen, htmlGrey} from "components/colors/Colors";

const styles = theme => ({
    basicContainer: {
        paddingTop: 25
    },
    paper: {
        display: 'flex',
        flexDirection: 'column',
        flex: '1 0 auto',
        borderRadius: 7,
        borderTop: 0,
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
        boxShadow: 'none'
    },
    controlButtons: {
        minWidth: 150,
    },
    buttonWithMargin: {
        minWidth: 150,
        marginRight: 15
    },
    title: {
        fontSize: 20,
        fontWeight: 'bold',
        color: htmlBlack
    },
    unsafeSaveText: {
        fontSize: 15,
        fontWeight: 'bold',
        textAlign: 'justify'
    },
    checkBoxIcon: {
        fill: christiGreen
    },
    approveQuestionCheckBoxRoot: {
        padding: 0,
        marginLeft: -3
    },
    footerText: {
        color: htmlGrey,
        fontSize: 12,
        fontWeight: 'bold',
        marginLeft: 5,
        marginRight: 25,
        marginTop: 7.5
    }
});

const TitleRow = (props) => {
    const {classes, question} = props;

    return (
        <Flex container justifyContent={'flex-start'} className={classes.basicContainer}>
            <Typography className={classes.title}>
                {
                    _.isEmpty(question.businessId) ?
                        <Trans i18nKey={"audit-administration.questionEdit.createNewQuestion"}/> :
                        <Trans i18nKey={"audit-administration.questionEdit.editQuestion"}/>
                }
            </Typography>
        </Flex>
    )
};

const FooterButtons = (props) => {
    const {classes} = props;
    const {isSaveEnabled, goBack, saveOrToggleDialog, questionStatus, toggleQuestionApproval} = props.actions;
    return (
        <Flex container grow={0} item
              className={classes.basicContainer}>
            <Flex container item={'1 0 auto'} direction={'row-reverse'}>


                <Button variant="contained"
                        color="primary"
                        onClick={saveOrToggleDialog}
                        className={classes.controlButtons}
                        disabled={!isSaveEnabled()}
                >
                    <Trans i18nKey={"global.save"}/>
                </Button>
                <Button variant="contained"
                        color="primary"
                        onClick={goBack}
                        className={classes.buttonWithMargin}
                >
                    <Trans i18nKey={"global.cancel"}/>
                </Button>
                <Typography className={classNames(classes.footerText)}>
                    <Trans i18nKey={"audit-administration.questionEdit.approvedQuestion"}/>
                </Typography>
                <Checkbox
                    classes={{root: classes.approveQuestionCheckBoxRoot}}
                    checked={questionStatus === 'APPROVED'}
                    onChange={toggleQuestionApproval}
                    checkedIcon={<CheckBoxIcon className={classes.checkBoxIcon}/>}
                />
            </Flex>
        </Flex>
    );
};

const TabRow = (props) => {
    const {
        questionStatus, translations, selectedTranslation, selectedTranslationIndex, handleTabChange,
        handleTranslationChange, toggleTranslationApproval, toggleQuestionApproval, onSuccessfulFileUpload, deletePicture,
        masterTranslation, clearTranslation  ,  answerTypes,        handleAnswerTypeChange,        question    } = props;
    return (
        <Fragment>
            <TranslationTabs
                {...{
                    translations,
                    selectedTranslation,
                    selectedTranslationIndex,
                    handleTabChange
                }}
            />
            <QuestionTab
                {...{
                    masterTranslation,
                    selectedTranslation,
                    questionStatus,
                    handleTranslationChange,
                    toggleTranslationApproval,
                    toggleQuestionApproval,
                    onSuccessfulFileUpload,
                    deletePicture,
                    clearTranslation,
                    answerTypes,
                    handleAnswerTypeChange,
                    question
                }}
            />
        </Fragment>
    );

};

export class DesktopQuestionEdit extends Component {

    state = {
        isSaveDialogOpen: false,
    };

    toggleDialog = () => {
        this.setState({
            isSaveDialogOpen: !this.state.isSaveDialogOpen
        })
    };

    goBack = () => {
        if (_.get(this.props, 'routeProps.location.state.prevPath')) {
            this.props.routeProps.history.goBack();
        } else {
            routeTo(AUDIT_ADMINISTRATION_QUESTIONS.path, this.props.routeProps);
        }
    };

    saveOrToggleDialog = () => {
        const noMasterChanges = !this.props.isUnsafeMasterTranslationChange() && !this.props.isUnsafeMasterPictureChange();
        const disapproved = this.props.question.assignedToQuestionnaire && this.props.question.status === PENDING;
        if (noMasterChanges && !disapproved) {
            this.props.saveQuestion();
            this.goBack();
        } else {
            this.toggleDialog();
        }
    };

    saveWithOverrideOptions = (forcePictureOverride, forceTranslateOverride) => {
        this.props.saveQuestionWithOverrideOptions(forcePictureOverride, forceTranslateOverride);
        this.goBack();
    };

    concatenateNotChangedManualTranslations = () => {
        const result = this.props.question.translations
            .filter(translation => !translation.isChanged && translation.origin === 'MANUAL')
            .map(translation => translation.language.code)
            .map(code => this.props.t(`global.languages.${code}.label`));
        return result.length > 0 ? result.toString().replace(',', ', ') : '';
    };

    render() {
        const {
            classes, question, selectedTranslationIndex, answerTypes, catalogues, cycles, masterLanguage, isSaveEnabled,
            handleTabChange, handleTranslationChange, handleQuestionChange, toggleTranslationApproval,
            isUnsafeMasterTranslationChange, toggleQuestionApproval, handleAnswerTypeChange, onSuccessfulFileUpload,
            deletePicture, isUnsafeMasterPictureChange, concatenateLanguagesWithAlreadyExistingPictures, clearTranslation
        } = this.props;
        const {isSaveDialogOpen} = this.state;
        const {goBack, saveOrToggleDialog, concatenateNotChangedManualTranslations, saveWithOverrideOptions, toggleDialog} = this;
        return (
            !_.isEmpty(question.translations) &&
            <Fragment>
                <Paper className={classes.paper}>
                    <Flex container item direction={"column"}>
                        <TitleRow {...{classes, question}}/>
                        <QuestionSelectors {...{
                            catalogues,
                            cycles,
                            question,
                            handleQuestionChange
                        }}/>
                        <TabRow {...{
                            translations: question.translations,
                            selectedTranslation: question.translations[selectedTranslationIndex],
                            selectedTranslationIndex,
                            masterLanguage,
                            handleTabChange,
                            handleTranslationChange,
                            toggleTranslationApproval,
                            onSuccessfulFileUpload,
                            deletePicture,
                            masterTranslation: question.translations[0],
                            clearTranslation,
                            answerTypes,
                            handleAnswerTypeChange,
                            question
                        }}/>
                    </Flex>
                    <FooterButtons {...{
                        classes,
                        actions: {
                            isSaveEnabled,
                            goBack,
                            saveOrToggleDialog,
                            questionStatus: question.status,
                            toggleQuestionApproval
                        }
                    }}/>
                </Paper>
                <UnsafeSaveDialog
                    {...{
                        isSaveDialogOpen,
                        masterLanguage,
                        isMasterTranslationChanged: isUnsafeMasterTranslationChange(),
                        toggleDialog,
                        isMasterPictureChanged: isUnsafeMasterPictureChange(),
                        isQuestionDisapproved: question.assignedToQuestionnaire && question.status === PENDING,
                        assignedQuestionnaires: question.assignedQuestionnaires,
                        concatenateNotChangedManualTranslations,
                        concatenateLanguagesWithAlreadyExistingPictures,
                        save: saveWithOverrideOptions
                    }}
                />

            </Fragment>
        );
    }
}

export default withStyles(styles)(withTranslation()(DesktopQuestionEdit));
