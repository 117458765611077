import * as actionTypes from './MyActionList.action.types';
import {ACTION_GRAPHQL} from 'domain/task/Action.model';
import query from 'store/GraphqlQueries.js';

export function selectAction(action) {
    return {
        type: actionTypes.SELECT_ACTION,
        data: {
            action
        }
    }
}

//TODO LM: This whole action.js (and particularly this function) should be merged with ActionList.action.js for better understanding
export function fetchActionFromServer(actionId) {
    return (dispatch, getState) => {
        return dispatch(
            query.graphql({
                type: actionTypes.FETCH_ACTION,
                data: {
                    supportedLanguages: getState().tasksReducer.configurationFromBackend.supportedLanguages,
                    user: getState().mainReducer.user
                },
                caller: 'fetchActionFromServer',
                query: `
                        query
                        ($actionId: String!){
                            fetchAction(actionId: $actionId) {
                                ${ACTION_GRAPHQL}
                            }
                        }
                       `,
                variables: {
                    actionId
                }
            })
        );
    }
}

export function fetchActions() {
    return fetchPersonalActionsFromServer();
}

//Temporary solution until actions can be handled offline without their tasks
export function fetchPersonalActionsFromServer() {
    return {
        type: actionTypes.FETCH_ACTIONS,
        payload: {
            client: 'graphql',
            request: {
                method: 'post',
                data: {
                    query: `
                            {
                                listMyPersonalActions {
                                    ${ACTION_GRAPHQL}
                                }
                            }
                           `
                }
            }
        }
    };
}


export function fetchActionsFromServer() {
    return {
        type: actionTypes.FETCH_ACTIONS,
        payload: {
            client: 'graphql',
            request: {
                method: 'post',
                data: {
                    query: `
                            {
                                listActions {
                                    ${ACTION_GRAPHQL}
                                }
                            }
                           `
                }
            }
        }
    };
}
