import React, {Component} from 'react';
import {withStyles,Typography} from "@material-ui/core";
import Flex from "components/grid/Flex";

const styles = theme => ({
    title: {
        fontWeight: 'bold',
        marginTop: 15,
    },
});

export class HeaderTitle extends Component {

    render() {
        const {classes, title, style} = this.props;

        return (
            <Flex container style={style}
                  justifyContent={'center'} alignItems={'center'} direction={'column'}>
                <Typography className={classes.title} align="center" variant="h6" color="inherit">
                    {title}
                </Typography>
            </Flex>
        );
    }
}

export default withStyles(styles)(HeaderTitle);
