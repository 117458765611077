import React, {Component} from 'react';
import Flex from "components/grid/Flex";
import AuditTile from "./AuditTile";

export class DailyAudit extends Component {

    render() {
        const {
            columnWidth, cell: audit, getTileColor, openAuditorUpdateDialog, openAuditDetailsDialog, hoveredAudit,
            isAuditorChangeAllowed, sendAuditReminder, user, toggleCancelAuditScheduleDialog, onAuditHovered
        } = this.props;
        const width = columnWidth - 25;

        return (
            <Flex container alignItems={'center'} justifyContent={'center'} style={{
                marginTop: 10,
                width: columnWidth,
                marginRight: 10,
                height: 75,
            }}>
                <AuditTile {...{
                    audit,
                    getTileColor,
                    width,
                    openAuditorUpdateDialog,
                    openAuditDetailsDialog,
                    isAuditorChangeAllowed,
                    sendAuditReminder,
                    length: 1,
                    user,
                    toggleCancelAuditScheduleDialog,
                    onAuditHovered,
                    hoveredAudit
                }}/>
            </Flex>
        );
    }
}

export default DailyAudit;
