import React, {Component} from 'react';
import {withStyles} from "@material-ui/core";
import Switch from '@material-ui/core/Switch';
import {low, htmlWhite, silver} from 'components/colors/Colors';

const styles = theme => ({
    root: {
        width: 42,
        height: 26,
        padding: 0,
        margin: theme.spacing(1),
    },
    iOSSwitchBase: {
        padding: 1,
        '&$iOSChecked': {
            transform: 'translateX(16px)',
            color: htmlWhite,
            '& + $iOSBar': {
                backgroundColor: low,
                opacity: 1,
                border: 'none',
            },
        },
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
            easing: theme.transitions.easing.sharp,
        }),
    },
    iOSChecked: {},
    iOSBar: {
        borderRadius: 13,
        border: 'solid 1px',
        borderColor: silver,
        backgroundColor: low,
        opacity: 1,
        transition: theme.transitions.create(['background-color', 'border']),
    },
    iOSIcon: {
        width: 24,
        height: 24,
    },
    iOSIconChecked: {
        boxShadow: theme.shadows[1],
    },
});

export class IOSSwitch extends Component {
    render() {
        const {classes, ...other} = this.props;

        return (
            <Switch
                classes={{
                    root: classes.root,
                    switchBase: classes.iOSSwitchBase,
                    track: classes.iOSBar,
                    thumb: classes.iOSIcon,
                    checked: classes.iOSChecked,
                }}
                disableRipple
                {...other}
            />
        );
    }
}

export default withStyles(styles)(IOSSwitch);
