import React, {Component, Fragment} from 'react';
import {Button, Divider, Tooltip, withStyles} from "@material-ui/core";
import {connect} from "react-redux";
import connector from "./Schedules.connect";
import {Trans, withTranslation} from "react-i18next";
import {withRouter} from "react-router";
import Flex from "components/grid/Flex";
import {doveGrey, htmlBlue, htmlWhite} from "components/colors/Colors";
import H4Avatar from "components/avatar/H4Avatar";
import utils from 'utils/Utils';
import _ from 'lodash';
import update from "immutability-helper";
import ScheduleTileHeader from "./components/ScheduleTileHeader";
import ScheduleTile from "./components/ScheduleTile";
import {Link} from "react-router-dom";
import {
    AUDIT_ADMINISTRATION_SCHEDULE_CREATE,
    AUDIT_ADMINISTRATION_SCHEDULE_EDIT
} from 'routes/routes';
import ConfirmationDialog from "components/confirmation-dialog/ConfirmationDialog";
import {ACTIVE, INACTIVE} from "domain/audit/Schedule.model";
import {routeTo, prevPath} from 'routes/routes';
import AuditAuthorizationErrorLabel from "scenes/audit-manager/components/AuditAuthorizationErrorLabel.jsx";
import {isExpired} from "domain/audit/Schedule.model";
import H4Tooltip from 'components/h4-tooltip/H4Tooltip';
import {content} from 'components/zindex/zIndex';

const styles = theme => ({
    paper: {
        marginTop: 20,
        marginRight: 20,
        paddingBottom: 20,
        backgroundColor: htmlWhite,
    },
    controlButtons: {
        minWidth: 150,
        marginTop: 15,
        marginLeft: 10
    },
    selectedOrganizationLevelLabel: {
        color: doveGrey
    },
    absoluteContainer: {
        overflow: 'auto',
        overflowScrolling: 'touch',
        position: 'absolute',
        top: 0,
        bottom: 0,
        right: 0,
        left: 0,
        zIndex: content
    },
    filterContainer: {
        paddingBottom: 30
    },
    name: {
        flex: '1 1 15%'
    },
    type: {
        flex: '1 1 10%'
    },
    lastOccurrence: {
        flex: '0 0 15%'
    },
    nextSchedule: {
        flex: '0 0 15%'
    },
    duration: {
        flex: '1 1 10%'
    },
    frequency: {
        flex: '1 1 10%'
    },
    auditor: {
        flex: '1 1 20%'
    },
    active: {
        flex: '1 1 5%'
    },
    popper: {
        marginBottom: -20
    }
});

const FootControlButtons = (props) => {
    const {classes, toggleDeleteDialogOpen, user, selectedSchedule, location, noAdminRights, questionnaires, translate} = props;
    const noAssignedQuestionnaire = questionnaires.length === 0;
    const tooltipTitle = noAssignedQuestionnaire
        ? translate("audit-administration.scheduleEdit.noAssignedQuestionnaire")
        : noAdminRights
            ? translate("audit-administration.noAdminRightOnOrganization")
            : "";
    return (
        <Flex container item={'0 0 50px'} justifyContent={'space-between'}>
            <Flex item={'1 0 auto'} container>
                <Button variant="contained"
                        color="primary"
                        onClick={toggleDeleteDialogOpen}
                        disabled={!user.isAdmin || _.isEmpty(selectedSchedule)}
                        className={classes.controlButtons}
                        style={{marginLeft: 0}}
                >
                    <Trans i18nKey="global.delete"/>
                </Button>
            </Flex>
            <Flex item={'1 0 auto'} container justifyContent={'flex-end'}>
                <Button variant="contained"
                        color="primary"
                        component={Link}
                        disabled={_.isEmpty(selectedSchedule)}
                        className={classes.controlButtons}
                        to={{
                            pathname: AUDIT_ADMINISTRATION_SCHEDULE_EDIT.pathWithId(_.get(selectedSchedule, 'businessId')),
                            state: {prevPath: prevPath({location})}
                        }}
                >
                    <Trans i18nKey="global.open"/>
                </Button>
                <H4Tooltip
                    title={tooltipTitle}
                    placement={"top"}
                    classes={{
                        popper: classes.popper
                    }}
                >
                    <Button variant="contained"
                            component={Link}
                            disabled={noAdminRights || noAssignedQuestionnaire}
                            to={{
                                pathname: AUDIT_ADMINISTRATION_SCHEDULE_CREATE.path,
                                state: {prevPath: prevPath({location})}
                            }} color="primary"
                            className={classes.controlButtons}
                    >
                        <Trans i18nKey="global.new"/>
                    </Button>
                </H4Tooltip>
            </Flex>
        </Flex>
    );
};


const ScheduleFilters = (props) => {
    const {
        classes, toggleUserSelection, selectedUserNames, auditors
    } = props;
    return (
        <Flex container item shrink={1} grow={0} className={classes.filterContainer}>
            <Flex container item justifyContent={'space-between'}>
                <Flex container item justifyContent={'flex-end'}>
                    {
                        auditors.length !== 0 &&
                        auditors.map((auditor, index) => (
                            <Flex item={'0 1 0'} key={index} style={{
                                position: 'relative'
                            }}>

                                <Flex container item>
                                    <Tooltip
                                        title={utils.formattedUserName(auditor)}
                                        placement={'bottom-start'}
                                        classes={{
                                            tooltip: classes.avatarTooltipLabel
                                        }}
                                    >

                                        <H4Avatar
                                            {...{user: auditor}}

                                            style={{
                                                marginLeft: 3,
                                                border: selectedUserNames.includes(auditor.username)
                                                    ? `2px solid ${htmlBlue}`
                                                    : 'none'
                                            }}
                                            onClick={() => toggleUserSelection(auditor)}
                                        />
                                    </Tooltip>
                                </Flex>
                            </Flex>
                        ))
                    }

                </Flex>
            </Flex>
        </Flex>
    )
};

const ScheduleList = (props) => {
    const {
        classes, bottom, schedules, selectedSchedule, handleDoubleClickOnSchedule, toggleScheduleSelection,
        toggleActive, toggleAuthorizationDialogOpen, noAdminRights
    } = props;
    return (
        <Flex container item direction={'column'} style={{position: 'relative'}}>
            <Flex container item direction={'column'} className={classes.absoluteContainer}>
                <ScheduleTileHeader {...{scheduleClasses: classes}}/>
                <Divider/>
                {
                    schedules.map(schedule => (
                        <div key={schedule.businessId}>
                            <ScheduleTile {...{
                                schedule,
                                isSelected: selectedSchedule.businessId === schedule.businessId,
                                handleDoubleClickOnSchedule,
                                toggleScheduleSelection,
                                scheduleClasses: classes,
                                toggleActive,
                                toggleAuthorizationDialogOpen,
                                noAdminRights
                            }}/>
                            <Divider/>
                        </div>
                    ))
                }
                <div id={'bottom-question-for-scroll'} ref={bottom}/>
            </Flex>
        </Flex>
    );
};

export class Schedules extends Component {

    state = {
        selectedUserNames: [],
        selectedSchedule: {},
        deleteDialogOpen: false,
        authorizationDialogOpen: false
    };

    bottom = React.createRef();

    componentDidMount() {
        if (this.props.scheduleEditCallBack) {
            this.props.scheduleEditCallBack.then(() => {
                this.loadSchedules();
            })
        } else {
            this.loadSchedules();
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (_.isEmpty(prevProps.organizationFilterBusinessId)
            || prevProps.organizationFilterBusinessId !== this.props.organizationFilterBusinessId) {
            this.loadSchedules();
        }
        if (prevProps.organizationFilterBusinessId !== this.props.organizationFilterBusinessId) {
            this.clearScheduleSelection();
        }
    }

    loadSchedules = () => {
        if (this.props.organizationFilterBusinessId && this.props.selectedOrganizationLevel.accessible) {
            return this.props.fetchQuestionnaires(this.props.organizationFilterBusinessId).then(() => {
                return this.props.fetchSchedules(this.props.organizationFilterBusinessId);
            });
        }
    };

    toggleUserSelection = (auditor) => {
        const indexOfUser = this.state.selectedUserNames.indexOf(auditor.username);
        if (indexOfUser !== -1) {
            this.setState(oldState => {
                return {
                    selectedUserNames: update(oldState.selectedUserNames, {
                        $splice: [[indexOfUser, 1]]
                    })
                }
            });
        } else {
            this.setState(oldState => {
                return {
                    selectedUserNames: update(oldState.selectedUserNames, {
                        $push: [auditor.username]
                    })
                }
            });
        }
    };

    handleDoubleClickOnSchedule = businessId => () => {
        routeTo(AUDIT_ADMINISTRATION_SCHEDULE_EDIT.pathWithId(businessId), this.props);
    };

    toggleScheduleSelection = schedule => () => {
        this.setState({
            selectedSchedule: this.state.selectedSchedule.businessId === schedule.businessId
                ? {}
                : schedule
        })
    };

    clearScheduleSelection = () => {
        this.setState({
            selectedSchedule: {}
        })
    };

    toggleDeleteDialogOpen = () => {
        this.setState({deleteDialogOpen: !this.state.deleteDialogOpen})
    };

    deleteSchedule = () => {
        this.props.deleteSchedule(this.state.selectedSchedule.businessId).then(() => {
            this.toggleDeleteDialogOpen();
            this.loadSchedules();
        });
    };

    toggleActive = schedule => {
        this.setState({
            selectedSchedule: schedule
        }, this.updateScheduleStatus(schedule))
    };

    updateScheduleStatus = schedule => () => {
        if (!_.isEmpty(schedule) || !isExpired(schedule)) {
            const newStatus = schedule.active ? INACTIVE : ACTIVE;
            this.props.updateScheduleStatus(schedule.businessId, newStatus)
                .then(() => {
                    this.props.fetchSchedules(this.props.organizationFilterBusinessId);
                });
        }
    };

    filterSchedules = (schedules, selectedUserNames) => {
        return schedules.filter(schedule => {
            if (_.isEmpty(selectedUserNames)) {
                return true;
            } else {
                if (_.isEmpty(schedule.auditor)) {
                    return false;
                } else {
                    return selectedUserNames.includes(schedule.auditor.username);
                }
            }
        });
    };

    toggleAuthorizationDialogOpen = () => {
        const authorizationDialogOpen = this.state.authorizationDialogOpen;
        this.setState(
            {
                authorizationDialogOpen: !authorizationDialogOpen
            }
        )
    };

    render() {
        const {
            classes, location, user, selectedOrganizationLevel, auditors, schedules, questionnaires, t: translate
        } = this.props;
        const {selectedUserNames, selectedSchedule, deleteDialogOpen, authorizationDialogOpen} = this.state;
        const {
            filterSchedules, toggleUserSelection, bottom, handleDoubleClickOnSchedule, toggleScheduleSelection,
            toggleDeleteDialogOpen, toggleActive, deleteSchedule, toggleAuthorizationDialogOpen
        } = this;
        const noAdminRights = !selectedOrganizationLevel.isUserAdmin;
        return (
            <Flex item container className={classes.paper}>
                {
                    !_.isEmpty(selectedOrganizationLevel) ?
                        selectedOrganizationLevel.accessible ?
                            <Flex item container direction={'column'} style={{marginLeft: 20}}>
                                <ScheduleFilters {...{
                                    classes,
                                    selectedOrganizationLevel,
                                    selectedUserNames,
                                    auditors,
                                    toggleUserSelection
                                }}/>
                                <ScheduleList
                                    {...{
                                        classes,
                                        bottom,
                                        schedules: filterSchedules(schedules, selectedUserNames),
                                        selectedSchedule,
                                        handleDoubleClickOnSchedule,
                                        toggleScheduleSelection,
                                        toggleActive,
                                        toggleAuthorizationDialogOpen,
                                        noAdminRights
                                    }}
                                />
                                <FootControlButtons {...{
                                    classes,
                                    location,
                                    user,
                                    selectedSchedule,
                                    toggleDeleteDialogOpen,
                                    questionnaires,
                                    translate,
                                    noAdminRights
                                }}/>
                            </Flex>
                            :
                            <AuditAuthorizationErrorLabel/>
                        :
                        <Fragment/>
                }

                <ConfirmationDialog dialogOpen={deleteDialogOpen}
                                    onDialogClose={toggleDeleteDialogOpen}
                                    onConfirm={deleteSchedule}
                                    confirmationTextKey={"audit-administration.scheduleEdit.deleteScheduleConfirmation"}
                />
                <ConfirmationDialog dialogOpen={authorizationDialogOpen}
                                    singleConfirmationButton={true}
                                    onDialogClose={toggleAuthorizationDialogOpen}
                                    onConfirm={toggleAuthorizationDialogOpen}
                                    confirmationTextKey={"audit-administration.scheduleEdit.nonAuthorizedEdit"}
                />
            </Flex>
        );
    }
}

export default withStyles(styles)(connect(connector.mapStateToProps,
    connector.mapDispatchToProps)(withTranslation()(withRouter(Schedules))));

