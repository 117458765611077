import {USER_GRAPHQL, mapUser} from 'domain/User.model';
import {STATUS_GRAPHQL} from 'domain/task/TaskStatus.model';

export const PERSONAL_PROJECT_TYPE = 'PERSONAL';

export const PROJECT_MEMBER_GRAPHQL = `
    membership
    user {
        ${USER_GRAPHQL}
    }
`;

export const PROJECT_GRAPHQL = `
    businessId
    name
    description
    parentProject {
        businessId
    }
    parentHierarchy {
        projectBusinessId
        projectName
        ordinal
    }
    activeTaskColumnOrders {
        status {${STATUS_GRAPHQL}}
        taskBusinessIds
    }
    taskBusinessIds
    members {
        membership
        user {
            ${USER_GRAPHQL}
        }
    }
    ordinal
    type
    organizationLevelId
`;

export function createProjectInput(name, parentBusinessId){
    return {
        name,
        parentBusinessId
    }
}
export function updateProjectDetailsInput(name, businessId){
    return {
        businessId,
        name
    }
}

export function apiProjectMember(projectMember) {
    return {
        membership: projectMember.membership,
        user: mapUser(projectMember.user),
    }
}