import {deleteFileOnUrl, uploadFile} from "components/attachment/Attachment.action";
import {
    addOrganizationLevelMembership,
    fetchOrganizationLevels,
    fetchOrganizationManagementConfigurationData,
    removeOrganizationLevelMembership,
    updateOrganizationLevelMembership,
    updateOrganizationLevelTimeZone
} from "./OrganizationTree.action";

export default {
    mapStateToProps: (state) => {
        return {
            allUsers: state.organizationTreeReducer.configuration.users,
            loggedInUser: state.mainReducer.user,
            timeZones: state.organizationTreeReducer.configuration.timeZones,
            selectedOrganizationLevel: state.organizationTreeReducer.selectedOrganizationLevel,
            id: state.organizationTreeReducer.selectedOrganizationLevel.businessId,
            files: state.organizationTreeReducer.selectedOrganizationLevel.attachments,
        };
    },
    mapDispatchToProps: (dispatch) => {
        return {
            addOrganizationLevelMembership: (businessId, userId, role) => dispatch(addOrganizationLevelMembership(businessId, userId, role)),
            updateOrganizationLevelMembership: (businessId, userId, newRole, oldRole) => dispatch(updateOrganizationLevelMembership(businessId, userId, newRole, oldRole)),
            removeOrganizationLevelMembership: (businessId, userId, membership) => dispatch(removeOrganizationLevelMembership(businessId, userId, membership)),
            fetchOrganizationLevels: () => dispatch(fetchOrganizationLevels()),
            fetchOrganizationManagementConfigurationData: () => dispatch(fetchOrganizationManagementConfigurationData()),
            updateOrganizationLevelTimeZone: (businessId, timeZone) => dispatch(updateOrganizationLevelTimeZone(businessId, timeZone)),
            uploadFile: (file, id, progressEvent, referenceType) => dispatch(uploadFile(file, id, progressEvent, referenceType)),
            deleteFileOnUrl: (url) => dispatch(deleteFileOnUrl(url)),
        };
    }
};