import React, {Component} from "react";
import {connect} from "react-redux";
import connector from "./QuestionEdit.connect";
import Flex from 'components/grid/Flex';
import {Route, Switch, withRouter} from "react-router";
import DesktopQuestionEdit from "./components/DesktopQuestionEdit";
import {
    AUDIT_ADMINISTRATION_QUESTION_CREATE,
    AUDIT_ADMINISTRATION_QUESTION_EDIT,
    AUDIT_ADMINISTRATION_QUESTIONS,
    routeTo
} from "routes/routes";
import utils from 'utils/Utils';
import _ from "lodash";
import update from 'immutability-helper';
import {withTranslation} from "react-i18next";
import configs from "configs/Configs";

export class QuestionEdit extends Component {

    constructor(props) {
        super(props);
        this.state = {
            question: {},
            selectedTranslationIndex: configs.getAllowedLanguages().indexOf(props.i18n.language)
        }
    }

    isTranslationTextChanged = (currentTranslation) => {
        const originalTranslation = this.props.question.translations
            .find(translation => translation.language.code === currentTranslation.language.code);
        return currentTranslation.isChanged
            && (currentTranslation.comment !== _.get(originalTranslation, 'comment')
                || currentTranslation.question !== _.get(originalTranslation, 'question')
                || currentTranslation.quickFix !== _.get(originalTranslation, 'quickFix'));
    };

    isUnsafeMasterTranslationChange = () => {
        const currentQuestion = this.state.question;
        const isMasterLanguageChanged = this.isTranslationTextChanged(currentQuestion.translations[0]);

        const isAnyManualTranslationUnchanged = _.some(currentQuestion.translations,
            currentTranslation => {
                return !this.isTranslationTextChanged(currentTranslation) && currentTranslation.origin === 'MANUAL'
            });
        return isMasterLanguageChanged && isAnyManualTranslationUnchanged;
    };

    isUnsafeMasterPictureChange = () => {
        const isAnyPictureManualAndUnchanged = _.some(this.state.question.translations
                .filter(translation => translation.language.code !== this.props.masterLanguage.code),
            currentTranslation => {
                const originalTranslation = this.props.question.translations
                    .find(translation => translation.language.code === currentTranslation.language.code);
                return originalTranslation.pictureReferenceId && originalTranslation.pictureReferenceId === currentTranslation.pictureReferenceId
            });
        return isAnyPictureManualAndUnchanged
            && (this.state.question.translations[0].pictureReferenceId !== this.props.question.translations[0].pictureReferenceId);
    };

    concatenateLanguagesWithAlreadyExistingPictures = () => {
        const result = this.state.question.translations
            .filter(translation => translation.language.code !== this.props.masterLanguage.code)
            .filter(currentTranslation => {
                const originalTranslation = this.props.question.translations
                    .find(translation => translation.language.code === currentTranslation.language.code);
                return originalTranslation.pictureReferenceId && originalTranslation.pictureReferenceId === currentTranslation.pictureReferenceId
            })
            .map(translation => translation.language.code)
            .map(code => this.props.t(`global.languages.${code}.label`));

        return result.length > 0 ? result.toString().replace(',', ', ') : '';
    };

    componentDidMount() {
        const questionBusinessIdForClone = _.get(this.props, 'routeProps.location.state.questionBusinessId');
        const questionBusinessId = this.props.questionBusinessId || questionBusinessIdForClone;

        if (questionBusinessId) {
            this.props.fetchQuestion(questionBusinessId)
                .then(() => {
                        const question = _.cloneDeep(this.props.question);
                        this.setState({
                            question: {
                                ...question,
                                businessId: _.isEmpty(questionBusinessIdForClone) ? question.businessId : '',
                                assignedToQuestionnaire: _.isEmpty(questionBusinessIdForClone) ? question.assignedToQuestionnaire : false,
                                translations: _.isEmpty(questionBusinessIdForClone) ? question.translations : question.translations.map(translation => {
                                    return {
                                        ...translation,
                                        isChanged: true,
                                        question: _.isEmpty(translation.question) ? '' : (translation.question + ' - ' + this.props.t("global.copied", {lng: translation.language.code})),
                                        comment: _.isEmpty(translation.comment) ? '' : (translation.comment + ' - ' + this.props.t("global.copied", {lng: translation.language.code}))
                                    }
                                })
                            }
                        })
                    }
                )
                .catch((error) => {
                    console.log('error: ', error);
                    routeTo(AUDIT_ADMINISTRATION_QUESTIONS.path, this.props);
                });
        } else {
            this.props.fetchEmptyQuestion().then(() =>
                this.setState({
                    question: _.cloneDeep(this.props.question)
                })
            );
        }
    }

    handleTranslationChange = (event) => {
        const target = event.target;
        this.setState(oldState => {
            return {
                question: update(oldState.question, {
                    translations: {
                        [oldState.selectedTranslationIndex]: {
                            [target.name]: {$set: target.value},
                            isChanged: {$set: this.props.question.translations[oldState.selectedTranslationIndex][target.name] !== target.value}
                        }
                    }
                }),
            };
        });
    };

    clearTranslation = (name) => {
        this.setState(oldState => {
            const value = '';
            return {
                question: update(oldState.question, {
                    translations: {
                        [oldState.selectedTranslationIndex]: {
                            [name]: {$set: value},
                            isChanged: {$set: this.props.question.translations[oldState.selectedTranslationIndex][name] !== value}
                        }
                    }
                }),
            };
        });
    };

    handleQuestionChange = (event) => {
        const target = event.target;
        this.setState(oldState => {
            return {
                question: update(oldState.question, {
                    [target.name]: {$set: target.value}
                }),
            };
        });
    };

    handleAnswerTypeChange = (event) => {
        const target = event.target;
        this.setState(oldState => {
            return {
                question: update(oldState.question, {
                    answerType: {$set: {label: target.value}}
                }),
            };
        });
    };

    toggleTranslationApproval = () => {
        const newStatus = this.state.question.translations[this.state.selectedTranslationIndex].status === 'APPROVED' ? 'PENDING' : 'APPROVED';
        this.setState(oldState => {
            return {
                question: update(oldState.question, {
                    translations: {
                        [oldState.selectedTranslationIndex]: {
                            status: {$set: newStatus},
                            isChanged: {$set: this.props.question.translations[oldState.selectedTranslationIndex].status !== newStatus}
                        }
                    }
                }),
            };
        });
    };

    toggleQuestionApproval = () => {
        this.setState(oldState => {
            return {
                question: update(oldState.question, {
                    status: {$set: oldState.question.status === 'APPROVED' ? 'PENDING' : 'APPROVED'}
                })
            };
        });
    };

    handleTabChange = (event, index) => {
        this.setState({selectedTranslationIndex: index})
    };

    isSaveEnabled = () => {
        const currentQuestion = this.state.question;
        const originalQuestion = this.props.question;
        const changedTranslations = currentQuestion.translations
            .filter(currentTranslation => currentTranslation.isChanged);
        const notFilledLanguageFields = changedTranslations
            .filter(translation => {
                return _.isEmpty(translation.question)
            });

        const areSelectorsChanged = currentQuestion.answerType.label !== originalQuestion.answerType.label
            || currentQuestion.catalogue !== originalQuestion.catalogue
            || currentQuestion.cycle !== originalQuestion.cycle;

        const areTranslationsChanged = changedTranslations.length !== 0
            && notFilledLanguageFields.length === 0;

        const isQuestionStatusChanged = originalQuestion.status !== currentQuestion.status;

        const areSelectorsEmpty = _.isEmpty(currentQuestion.answerType.label) || _.isEmpty(currentQuestion.catalogue) || _.isEmpty(currentQuestion.cycle);

        const areMasterLanguageTextsEmpty = currentQuestion.translations
            .find(translation => {
                return translation.language.code === this.props.masterLanguage.code ? _.isEmpty(translation.question) : false;
            });

        if (areSelectorsEmpty || areMasterLanguageTextsEmpty) {
            return false
        } else {
            return areSelectorsChanged || areTranslationsChanged || isQuestionStatusChanged;
        }
    };

    saveQuestion = () => {
        if (this.props.questionBusinessId) {
            this.props.registerQuestionEditCallback(this.props.editQuestion(this.state.question));
        } else {
            this.props.registerQuestionEditCallback(this.props.createQuestion(this.state.question));
        }
    };

    saveQuestionWithOverrideOptions = (forcePictureOverride, forceTranslateOverride) => {
        this.props.registerQuestionEditCallback(this.props.editQuestion({
            ...this.state.question,
            forceTranslate: forceTranslateOverride,
            forcePictureOverride
        }));
    };

    deletePicture = () => {
        this.updateTranslationWithPictureReferenceIdAndUrl('', '');
    };

    updateTranslationWithPictureReferenceIdAndUrl = (pictureReferenceId, downloadUrl) => {
        this.setState(oldState => {
            return {
                question: update(oldState.question, {
                    translations: {
                        [oldState.selectedTranslationIndex]: {
                            pictureReferenceId: {$set: pictureReferenceId},
                            pictureDownloadUrl: {$set: downloadUrl},
                            isChanged: {$set: true}
                        }
                    },
                }),
            };
        });
    };

    onSuccessfulFileUpload = response => {
        this.updateTranslationWithPictureReferenceIdAndUrl(response.referenceId, response.downloadUrl)
    };

    render() {
        const isMobile = utils.checkBrowser();
        const {answerTypes, catalogues, cycles, masterLanguage} = this.props;
        const {question, selectedTranslationIndex} = this.state;
        const {
            isSaveEnabled, handleTranslationChange, handleQuestionChange, toggleTranslationApproval, saveQuestion,
            toggleQuestionApproval, handleTabChange, handleAnswerTypeChange, onSuccessfulFileUpload, deletePicture,
            isUnsafeMasterTranslationChange, saveQuestionWithOverrideOptions, isUnsafeMasterPictureChange,
            concatenateLanguagesWithAlreadyExistingPictures,clearTranslation
        } = this;
        return (
            <Flex item container direction={'column'} style={{marginBottom: 20, marginRight: 20, marginLeft: 20}}>
                <Switch>
                    <Route
                        path={[AUDIT_ADMINISTRATION_QUESTION_CREATE.path, AUDIT_ADMINISTRATION_QUESTION_EDIT.path]}
                        render={function renderQuestionCreateOrEdit(props) {
                            return (
                                !isMobile
                                && <DesktopQuestionEdit
                                    {...{
                                        routeProps: props,
                                        answerTypes,
                                        catalogues,
                                        cycles,
                                        question,
                                        selectedTranslationIndex,
                                        masterLanguage,
                                        isSaveEnabled,
                                        handleTabChange,
                                        saveQuestion,
                                        saveQuestionWithOverrideOptions,
                                        handleTranslationChange,
                                        handleQuestionChange,
                                        handleAnswerTypeChange,
                                        toggleTranslationApproval,
                                        toggleQuestionApproval,
                                        isUnsafeMasterTranslationChange,
                                        onSuccessfulFileUpload,
                                        deletePicture,
                                        isUnsafeMasterPictureChange,
                                        concatenateLanguagesWithAlreadyExistingPictures,
                                        clearTranslation
                                    }}
                                />
                            )
                        }}
                    />
                </Switch>
            </Flex>
        );
    }
}

export default connect(connector.mapStateToProps, connector.mapDispatchToProps)(withRouter(withTranslation()(QuestionEdit)));
