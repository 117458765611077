import moment from "moment";
import _ from "lodash";

export const AUDIT_CONFIG_GRAPHQL = `
    catalogues {
        businessId
        label
    }
    cycles {
        label
    }
    answerTypes {
        label
    }
    supportedLanguages {
        code
        label
    }
    schedulingCalendar {
        supportedWeekdays
    }
    masterLanguage {
        code
        label
    }
    durationUnits
    durationTypes
    auditTypes {
        businessId
        label
    }
    auditTypesHistory
    auditResultDefinitions {
        businessId
        values {
            minimumPercentage
            maximumPercentage
        }
    }
`;

export function mapConfigurations(configurationData) {
    return {
        answerTypes: configurationData.answerTypes.map(rating => rating.label),
        catalogues: configurationData.catalogues.map(catalogue => catalogue.label),
        cataloguesForAdministration: configurationData.catalogues.map(catalogue => mapCatalogue(catalogue)),
        cycles: configurationData.cycles.map(cycle => cycle.label),
        supportedLanguages: configurationData.supportedLanguages,
        masterLanguage: configurationData.masterLanguage,
        auditTypes: configurationData.auditTypes.map(auditType => mapAuditType(auditType)),
        auditTypesHistory: configurationData.auditTypesHistory,
        durationTypes: configurationData.durationTypes,
        durationUnits: configurationData.durationUnits,
        auditResultDefinitions: mapAuditResultDefinitions(configurationData.auditResultDefinitions),
        supportedWeekdays: getActualWeek(_.get(configurationData, 'schedulingCalendar.supportedWeekdays', [])),
        ready: true
    }
}

function mapAuditResultDefinitions(auditResultDefinitions) {
    return auditResultDefinitions.map(definition => {
        const intervalBoundaries = [];
        for (let i = 0; i < definition.values.length - 1; i++) {
            intervalBoundaries.push(definition.values[i].maximumPercentage)
        }
        return {
            businessId: definition.businessId,
            numberOfIntervals: definition.values.length,
            intervalBoundaries: intervalBoundaries
        }
    })
}

function getActualWeek(supportedWeekdays) {
    const week = [];
    const start = moment().startOf('isoWeek');
    const end = moment().endOf('isoWeek');
    let temp = start;
    while (temp.isBefore(end)) {
        const day = temp.format('dddd').toUpperCase();
        if (supportedWeekdays.includes(day)) {
            week.push(temp);
        }
        temp = temp.clone().add(1, 'day');
    }
    return week;
}

export function mapCatalogueLabel(catalogue) {
    return _.get(catalogue, 'label', 'Unassigned');
}

export function mapCatalogueString(catalogue) {
    return (catalogue || 'Unassigned');
}

export function mapCatalogue(catalogue) {
    return {
        businessId: catalogue.businessId,
        label: catalogue.label
    };
}

export function mapAuditType(auditType) {
    return {
        businessId: auditType.businessId,
        label: auditType.label
    };
}
