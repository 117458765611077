import * as actionTypes from './TaskReporting.action.types';
import {STATUS_GRAPHQL} from 'domain/task/TaskStatus.model';

export function listDailyTaskStatusReportFromServer(from, to) {
    return (dispatch, getState) => {
        return dispatch({
            type: actionTypes.FETCH_DAILY_TASK_STATUS_REPORT,
            payload: {
                client: 'graphql',
                request: {
                    method: 'post',
                    data: {
                        query: `
                                query
                                ($filter: DailyTaskStatusReportInput!){
                                    listDailyTaskStatusReport(filter: $filter) {
                                        date
                                        counts {
                                            status {
                                                ${STATUS_GRAPHQL}
                                            }
                                            count
                                        }
                                    }
                                }
                           `,
                        variables: {
                            filter: {
                                projectBusinessId: getState().projectReducer.selectedProject.businessId,
                                from,
                                to
                            }
                        }
                    }
                }
            }
        });
    }
}
