export const LIST_PROJECTS = '@project/LIST_PROJECTS';
export const LIST_PROJECTS_SUCCESS = '@project/LIST_PROJECTS_SUCCESS';
export const LIST_PROJECTS_FAIL = '@project/LIST_PROJECTS_FAIL';
export const LIST_PROJECTS_FROM_CACHE = '@project/LIST_PROJECTS_FROM_CACHE';

export const UPDATE_PROJECT = '@project/UPDATE_PROJECT';
export const UPDATE_PROJECT_SUCCESS = '@project/UPDATE_PROJECT_SUCCESS';
export const UPDATE_PROJECT_FAIL = '@project/UPDATE_PROJECT_FAIL';

export const TOGGLE_PROJECT_FILTER_SELECTION = '@project/TOGGLE_PROJECT_FILTER_SELECTION';
export const TOGGLE_PROJECT_FILTER_SELECTION_BY_ORG_LEVEL_ID = '@project/TOGGLE_PROJECT_FILTER_SELECTION_BY_ORG_LEVEL_ID';
export const TOGGLE_PROJECT_FILTER_OPEN = '@project/TOGGLE_PROJECT_FILTER_OPEN';
export const NEW_PROJECT_SELECTED = '@project/NEW_PROJECT_SELECTED';

export const CREATE_PROJECT = '@project/CREATE_PROJECT';
export const CREATE_PROJECT_SUCCESS = '@project/CREATE_PROJECT_SUCCESS';
export const CREATE_PROJECT_FAIL = '@project/CREATE_PROJECT_FAIL';

export const EDIT_PROJECT = '@project/EDIT_PROJECT';
export const EDIT_PROJECT_SUCCESS = '@project/EDIT_PROJECT_SUCCESS';
export const EDIT_PROJECT_FAIL = '@project/EDIT_PROJECT_FAIL';

export const DELETE_PROJECT = '@project/DELETE_PROJECT';
export const DELETE_PROJECT_SUCCESS = '@project/DELETE_PROJECT_SUCCESS';
export const DELETE_PROJECT_FAIL = '@project/DELETE_PROJECT_FAIL';

export const OPEN_PROJECT_TREE = '@project/OPEN_PROJECT_TREE';
export const CLOSE_PROJECT_TREE = '@project/CLOSE_PROJECT_TREE';

export const ASSIGN_PROJECT_MEMBER ='@project/ASSIGN_PROJECT_MEMBER';
export const ASSIGN_PROJECT_MEMBER_SUCCESS = '@project/ASSIGN_PROJECT_MEMBER_SUCCESS';
export const ASSIGN_PROJECT_MEMBER_FAIL = '@project/ASSIGN_PROJECT_MEMBER_FAIL';

export const REMOVE_PROJECT_MEMBER ='@project/REMOVE_PROJECT_MEMBER';
export const REMOVE_PROJECT_MEMBER_SUCCESS = '@project/REMOVE_PROJECT_MEMBER_SUCCESS';
export const REMOVE_PROJECT_MEMBER_FAIL = '@project/REMOVE_PROJECT_MEMBER_FAIL';

export const UPDATE_PROJECT_MEMBERSHIP ='@project/UPDATE_PROJECT_MEMBERSHIP';
export const UPDATE_PROJECT_MEMBERSHIP_SUCCESS = '@project/UPDATE_PROJECT_MEMBERSHIP_SUCCESS';
export const UPDATE_PROJECT_MEMBERSHIP_FAIL = '@project/UPDATE_PROJECT_MEMBERSHIP_FAIL';

export const TOGGLE_SELECTED_USERS = '@mobileProjectTree/TOGGLE_SELECTED_USERS';

export const LOAD_PREVIOUS_PROJECT_SELECTION = '@project/LOAD_PREVIOUS_PROJECT_SELECTION';
