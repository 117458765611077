import React, {Component, Fragment} from 'react';
import Flex from "components/grid/Flex";
import {Button, Divider, withStyles} from "@material-ui/core";
import {connect} from "react-redux";
import {Trans, withTranslation} from "react-i18next";
import {Link, withRouter} from 'react-router-dom';
import QuestionnaireTile from './components/QuestionnaireTile';
import {gossipGreen, htmlBlue, htmlWhite} from 'components/colors/Colors';
import _ from 'lodash';
import {
    AUDIT_ADMINISTRATION_QUESTIONNAIRE_CREATE,
    AUDIT_ADMINISTRATION_QUESTIONNAIRE_EDIT,
    prevPath,
    routeTo
} from 'routes/routes';
import connector from "./Questionnaires.connect";
import QuestionnaireTileHeader from "./components/QuestionnaireTileHeader";
import ConfirmationDialog from "components/confirmation-dialog/ConfirmationDialog";
import {APPROVED, PENDING} from "domain/audit/Questionnaire.model";
import AuditAuthorizationErrorLabel from "scenes/audit-manager/components/AuditAuthorizationErrorLabel.jsx";
import AssignedSchedules from "scenes/audit-manager/administration/questionnaires/components/AssignedSchedules";
import SelectedOrganizationLevels from "./components/SelectedOrganizationLevels";
import DeactivateScheduleDialog from "./components/DeactivateScheduleDialog";
import H4Tooltip from 'components/h4-tooltip/H4Tooltip';
import {content} from 'components/zindex/zIndex';

const styles = theme => ({
    paper: {
        margin: 20,
        backgroundColor: htmlWhite,
    },
    controlButtons: {
        minWidth: 150,
        marginTop: 15,
        marginLeft: 10
    },
    absoluteContainer: {
        overflow: 'auto',
        overflowScrolling: 'touch',
        position: 'absolute',
        top: 0,
        bottom: 0,
        right: 0,
        left: 0,
        zIndex: content
    },
    hoverBackground: {
        backgroundColor: htmlBlue,
        '&:hover': {
            backgroundColor: gossipGreen
        }
    },
    id: {
        flex: '0 0 5%'
    },
    name: {
        flex: '1 1 60%'
    },
    catalogue: {
        flex: '1 1 10%'
    },
    numberOfQuestions: {
        flex: '1 1 15%'
    },
    cycle: {
        flex: '1 1 5%'
    },
    active: {
        flex: '1 1 5%'
    }
});

const QuestionnaireList = (props) => {
    const {
        classes, questionnaires, selectedQuestionnaire, handleDoubleClickOnQuestionnaire, toggleQuestionnaireSelection,
        bottom, openDeactivateDialog, toggleActive,
        toggleAuthorizationDialogOpen,
        noAdminRights
    } = props;
    return (
        <Flex container item direction={'column'} style={{position: 'relative'}}>
            <Flex container item direction={'column'} className={classes.absoluteContainer}>
                <QuestionnaireTileHeader {
                                             ...{
                                                 rootClasses: classes
                                             }
                                         }/>
                <Divider/>
                {
                    questionnaires.map(questionnaire => (
                        <div key={questionnaire.businessId}>
                            <QuestionnaireTile {...{
                                questionnaire,
                                isSelected: selectedQuestionnaire.businessId === questionnaire.businessId,
                                handleDoubleClickOnQuestionnaire,
                                toggleQuestionnaireSelection,
                                rootClasses: classes,
                                openDeactivateDialog,
                                toggleActive,
                                toggleAuthorizationDialogOpen,
                                noAdminRights
                            }}/>
                            <Divider/>
                        </div>
                    ))
                }
                <div id={'bottom-question-for-scroll'} ref={bottom}/>
            </Flex>
        </Flex>
    );
};

const FootControlButtons = (props) => {
    const {
        classes, selectedQuestionnaire, toggleDeleteDialogOpen, user, location, cloneQuestionnaire, noAdminRights,
        toggleAuthorizationDialogOpen
    } = props;
    return (
        <Flex container item={'0 0 50px'} justifyContent={'space-between'}>
            <Flex item={'1 0 auto'} container>
                <Button variant="contained"
                        color="primary"
                        onClick={noAdminRights ? toggleAuthorizationDialogOpen(selectedQuestionnaire) : toggleDeleteDialogOpen}
                        disabled={!user.isAdmin || _.isEmpty(selectedQuestionnaire)}
                        className={classes.controlButtons}
                        style={{marginLeft: 0}}
                >
                    <Trans i18nKey="global.delete"/>
                </Button>
                <Button variant="contained"
                        color="primary"
                        onClick={cloneQuestionnaire}
                        disabled={_.isEmpty(selectedQuestionnaire)}
                        className={classes.controlButtons}
                >
                    <Trans i18nKey="global.clone"/>
                </Button>
            </Flex>
            <Flex item={'1 0 auto'} container justifyContent={'flex-end'}>
                <Button variant="contained"
                        color="primary"
                        component={Link}
                        to={{
                            pathname: AUDIT_ADMINISTRATION_QUESTIONNAIRE_EDIT.pathWithId(_.get(selectedQuestionnaire, 'businessId')),
                            state: {prevPath: prevPath({location})}
                        }}
                        disabled={_.isEmpty(selectedQuestionnaire)}
                        className={classes.controlButtons}
                >
                    <Trans i18nKey="global.open"/>
                </Button>
                <H4Tooltip
                    title={noAdminRights ?
                        <Trans i18nKey={"audit-administration.noAdminRightOnOrganization"}/> : ""}
                    placement={"top"}
                >
                    <Button variant="contained"
                            component={Link}
                            disabled={noAdminRights}
                            to={{
                                pathname: AUDIT_ADMINISTRATION_QUESTIONNAIRE_CREATE.path,
                                state: {prevPath: prevPath({location})}
                            }}
                            color="primary"
                            className={classes.controlButtons}
                    >
                        <Trans i18nKey="global.new"/>
                    </Button>
                </H4Tooltip>
            </Flex>
        </Flex>
    );
};

export class Questionnaires extends Component {
    state = {
        selectedQuestionnaire: '',
        deleteDialogOpen: false,
        deactivateDialogOpen: false,
        authorizationDialogOpen: false,
        questionnaireToDeactivate: ''
    };

    bottom = React.createRef();

    componentDidMount() {
        if (this.props.questionnaireEditCallback) {
            this.props.questionnaireEditCallback.then(() => {
                this.props.fetchQuestionnaires().then(() => this.scrollToBottom());
            })
        } else {
            this.props.fetchQuestionnaires();
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.selectedOrganizationLevel !== this.props.selectedOrganizationLevel) {
            this.clearQuestionnaireSelection();
        }
    }

    scrollToBottom = () => {
        this.bottom.current && this.bottom.current.scrollIntoView({
            block: "end",
            inline: "nearest",
            behavior: "auto"
        });
    };

    handleDoubleClickOnQuestionnaire = questionnaireBusinessId => () => {
        routeTo(AUDIT_ADMINISTRATION_QUESTIONNAIRE_EDIT.pathWithId(questionnaireBusinessId), this.props);
    };

    toggleQuestionnaireSelection = questionnaire => () => {
        this.setState({
            selectedQuestionnaire: this.state.selectedQuestionnaire.businessId === questionnaire.businessId
                ? {}
                : questionnaire
        })
    };

    clearQuestionnaireSelection = () => {
        this.setState({
            selectedQuestionnaire: ''
        })
    };

    organizationLevelFilter = questionnaire => {
        return this.props.organizationFilterBusinessId === '' || questionnaire.organizationLevels
            .map(org => org.businessId)
            .some(organizationLevel => this.props.organizationFilterBusinessId === organizationLevel);
    };

    toggleActive = (questionnaire) => {
        this.setState({
            selectedQuestionnaire: questionnaire
        }, this.updateQuestionnaireStatus(questionnaire))
    };

    updateQuestionnaireStatus = (questionnaire) => () => {
        const newStatus = questionnaire.active ? PENDING : APPROVED;
        this.props.updateQuestionnaireStatus(questionnaire.businessId, newStatus).then(() => {
            this.props.fetchQuestionnaires().then(() => {
                this.closeDeactivateDialog();
            });
        });
    };

    toggleDeleteDialogOpen = () => {
        this.setState({deleteDialogOpen: !this.state.deleteDialogOpen})
    };

    openDeactivateDialog = questionnaire => {
        this.setState({
            deactivateDialogOpen: true,
            questionnaireToDeactivate: questionnaire
        });
    };

    closeDeactivateDialog = () => {
        this.setState({
            deactivateDialogOpen: false,
            questionnaireToDeactivate: ''
        });
    };

    deleteQuestionnaire = () => {
        this.props.deleteQuestionnaire(this.state.selectedQuestionnaire.businessId).then(() => {
            this.toggleDeleteDialogOpen();
            this.props.fetchQuestionnaires();
        });
    };

    cloneQuestionnaire = () => {
        let newName = this.clonedQuestionnaireName();
        const data = {
                ...this.state.selectedQuestionnaire,
                name: newName,
                organizationLevelIds: [this.props.organizationFilterBusinessId],
            }
        ;
        this.props.cloneQuestionnaire(data).then(() => {
            this.props.fetchQuestionnaires();
        });
    };

    clonedQuestionnaireName = () => {
        const existingNames = this.props.questionnaires.map(questionnaire => questionnaire.name);
        let index = 1;
        let newName = this.state.selectedQuestionnaire.name + ' (' + index + ')';
        while (existingNames.includes(newName)) {
            index = index + 1;
            newName = this.state.selectedQuestionnaire.name + ' (' + index + ')';
        }
        return newName;
    };

    toggleAuthorizationDialogOpen = (questionnaire) => () => {
        this.setState({
            authorizationDialogOpen: !this.state.authorizationDialogOpen,
            selectedQuestionnaire: questionnaire
        })
    };

    render() {
        const {
            classes, questionnaires, location, user, organizationFilterBusinessId,
            selectedOrganizationLevel
        } = this.props;
        const {selectedQuestionnaire, deleteDialogOpen, deactivateDialogOpen, questionnaireToDeactivate, authorizationDialogOpen} = this.state;
        const {
            handleDoubleClickOnQuestionnaire, toggleQuestionnaireSelection, bottom,
            organizationLevelFilter, toggleDeleteDialogOpen, deleteQuestionnaire,
            toggleActive, cloneQuestionnaire, openDeactivateDialog, closeDeactivateDialog,
            toggleAuthorizationDialogOpen
        } = this;
        const filteredQuestionnaires = organizationFilterBusinessId ? questionnaires.filter(organizationLevelFilter) : [];

        const notEditableOrganizationLevels = _.get(selectedQuestionnaire, 'organizationLevels', [])
            .filter(org => !org.isUserAdmin).map(org => org.title);
        return (
            <Flex item container className={classes.paper}>
                {
                    !_.isEmpty(selectedOrganizationLevel) ?
                        selectedOrganizationLevel.accessible ?
                            <Flex item container direction={'column'}>
                                <QuestionnaireList
                                    {...{
                                        classes,
                                        questionnaires: filteredQuestionnaires,
                                        selectedQuestionnaire,
                                        bottom,
                                        handleDoubleClickOnQuestionnaire,
                                        toggleQuestionnaireSelection,
                                        openDeactivateDialog,
                                        toggleActive,
                                        toggleAuthorizationDialogOpen,
                                        noAdminRights: notEditableOrganizationLevels.length > 0 || !selectedOrganizationLevel.isUserAdmin
                                    }}
                                />
                                <FootControlButtons {...{
                                    classes,
                                    selectedQuestionnaire,
                                    location,
                                    toggleDeleteDialogOpen,
                                    user,
                                    cloneQuestionnaire,
                                    toggleAuthorizationDialogOpen,
                                    noAdminRights: notEditableOrganizationLevels.length > 0 || !selectedOrganizationLevel.isUserAdmin
                                }}/>
                            </Flex>
                            :
                            <AuditAuthorizationErrorLabel/>
                        :
                        <Fragment/>
                }
                <ConfirmationDialog dialogOpen={deleteDialogOpen}
                                    singleConfirmationButton={selectedQuestionnaire.isAssignedToAuditSchedule}
                                    onDialogClose={toggleDeleteDialogOpen}
                                    onConfirm={selectedQuestionnaire.isAssignedToAuditSchedule ? toggleDeleteDialogOpen : deleteQuestionnaire}
                                    confirmationTextKey={selectedQuestionnaire.isAssignedToAuditSchedule ? "audit-administration.questionnaireEdit.deleteQuestionnaireRejection" : "audit-administration.questionnaireEdit.deleteQuestionnaireConfirmation"}
                                    children={
                                        <AssignedSchedules {...{questionnaire: selectedQuestionnaire}}/>
                                    }
                />
                <DeactivateScheduleDialog dialogOpen={deactivateDialogOpen}
                                          onDialogClose={closeDeactivateDialog}
                                          onConfirm={() => toggleActive(questionnaireToDeactivate)}
                                          questionnaire={questionnaireToDeactivate}
                />
                <ConfirmationDialog dialogOpen={authorizationDialogOpen}
                                    singleConfirmationButton={true}
                                    onDialogClose={toggleAuthorizationDialogOpen(selectedQuestionnaire)}
                                    onConfirm={toggleAuthorizationDialogOpen(selectedQuestionnaire)}
                                    confirmationTextKey={"audit-administration.questionnaireEdit.nonAuthorizedEdit"}
                                    children={
                                        notEditableOrganizationLevels.length > 0 ?
                                            <SelectedOrganizationLevels {...{organizationLevels: notEditableOrganizationLevels}}/>
                                            :
                                            <Fragment/>
                                    }
                />
            </Flex>
        );
    }
}

export default withStyles(styles)(connect(connector.mapStateToProps, connector.mapDispatchToProps)(withRouter(withTranslation()(Questionnaires))));
