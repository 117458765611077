import React, {
    Fragment, PureComponent
} from 'react';
import {
    CircularProgress,
    Tooltip,
    Typography,
    withStyles,
} from "@material-ui/core";
import moment from 'moment';
import utils from 'utils/Utils';
import Flex from 'components/grid/Flex';
import {
    htmlGrey,
    seenIconGrey,
    htmlBlack,
    blueHighlight,
    htmlWhite
} from 'components/colors/Colors';
import {getColorbyPriority} from 'components/colors/Priority';
import DueDateProgressBar from 'components/progress-bar/DueDateProgressBar'
import {withTranslation} from "react-i18next";
import H4Avatar from 'components/avatar/H4Avatar'
import {getTextColorByPriority} from 'components/colors/Priority';
import {BACKLOG_VALUE, ACTIVE_VALUE, HISTORY_VALUE} from "domain/task/Tasks.model";
import DoneIcon from '@material-ui/icons/Done';
import AttachmentIndicator from 'components/attachment/AttachmentIndicator';
import CommentIndicator from 'components/comment/CommentIndicator';
import ChecklistIndicator from 'components/checklist/ChecklistIndicator';
import ActionListIndicator from './ActionListIndicator';
import UnseenCircle from 'components/unseen/UnseenCircle';
import {TASK_EDIT} from 'routes/routes';
import Tag from 'components/tags/Tag';
import _ from 'lodash';
import DragTaskTile from 'scenes/tasks/components/DragTaskTile';
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';

export const styles = theme => ({
    mainContainer: {
        position: 'relative',
        marginLeft: 5,
        marginRight: 5,
        userSelect: 'none',
        minHeight: 40,
    },
    avatarTooltipLabel: {
        fontSize: 14
    },
    circularProgress: {
        margin: 'auto'
    },
    titleBarItem: {
        lineHeight: 1.5,
    },
    dueDate: {
        fontSize: 13,
        color: htmlGrey,
        textTransform: 'initial'
    },
    iconContainer: {
        display: 'flex',
        justifyContent: 'flex-start',
        flex: '0 0 50px',
        position: 'relative'
    },
    indicatorContainer: {
        display: 'none',
        '@media (min-width: 1300px)': {
            display: 'flex',
            flex: '0 0 200px',
            marginLeft: 10
        },
    },
    dateContainer: {
        display: 'none',
        '@media (min-width: 1000px)': {
            display: 'flex',
            flex: '0 0 45px',
            flexDirection: 'column',
            marginRight: 5,
            alignItems: 'center',
            marginLeft: 15
        },
    }
});

export class SortableTask extends PureComponent {

    state = {
        isLoaded: false,
    };

    ref = React.createRef();

    componentDidMount() {
        this.observer = new IntersectionObserver(this.load);
        if (this.ref.current) {
            this.observer.observe(this.ref.current);
        }
    }

    componentWillUnmount() {
        this.observer.unobserve(this.ref.current);
    }

    load = entries => {
        if (entries[0].isIntersecting) {
            this.setState({isLoaded: true});
            this.observer.unobserve(this.ref.current);
        }
    };

    isLoading = (array, lengthFromBackend) => {
        return array === undefined || (array.length === 0 && lengthFromBackend !== 0);
    };

    render() {
        const {
            classes, applicationOnline, isSelected, i18n, view, user, handleDoubleClickOnTask, toggleTaskSelection,
            removeUnseenAttachmentTag, removeUnseenActionTag, removeUnseenCommentTag, fetchTask,
            removeUnseenChecklistTag, task, id, selectedTags, toggleTagSelection, tasks, shrink, dragHandleProps
        } = this.props;
        const {
            businessId, translations, isUnseen, priority, hasUnseenAction, numberOfActions, comments,
            numberOfAttachments, numberOfComments, numberOfChecklistItems, hasUnseenAttachment, attachments, dueDate,
            hasUnseenComment, hasUnseenCriteria, checklist, dueDateProgress, finishedDate
        } = task;
        const assignee = _.get(task, 'assignee', {}) || {};
        const matchingTranslation = translations
            .find(translation => translation.language.code === i18n.language) || translations[0];
        const isTaskForMe = user.businessId === assignee.businessId;
        const isOfflineCreated = businessId.startsWith("OFFLINE");
        const formattedName = utils.formattedUserName(assignee);
        const refresh = () => fetchTask(businessId);
        return (
            !shrink
                ? <Flex item container grow={0} padding={1} alignItems={'center'} className={classes.mainContainer}
                        onDoubleClick={handleDoubleClickOnTask(businessId)}
                        onClick={toggleTaskSelection(tasks, task)}
                        id={id}
                        style={{
                            backgroundColor: isSelected ? blueHighlight : htmlWhite,
                        }}
                >
                    <div id={`${businessId}-observed`} ref={this.ref}/>
                    <div {...dragHandleProps}>
                        <DragIndicatorIcon/>
                    </div>
                    <Fragment>
                        {
                            view !== HISTORY_VALUE &&
                            <UnseenCircle {...{
                                visibilityCondition: isUnseen && user.businessId === assignee.businessId
                            }}/>
                        }
                        <Flex item container alignItems={'center'} style={{
                            padding: 5,
                            borderLeft: `5px solid ${getColorbyPriority(priority.type)}`
                        }}>
                            <Flex item={'0 0 30px'} container alignItems={'center'}
                                  style={{marginLeft: 10, marginRight: 10}}>
                                <Tooltip
                                    title={formattedName}
                                    placement={'bottom-start'}
                                    classes={{
                                        tooltip: classes.avatarTooltipLabel
                                    }}
                                >
                                    <H4Avatar {...{user: assignee}}/>
                                </Tooltip>
                            </Flex>
                            <Flex item container basis={'80%'}>
                                <Typography className={classes.titleBarItem}
                                            style={{
                                                marginRight: 5,
                                                marginLeft: 5,
                                                color: getTextColorByPriority(priority.type),
                                                fontWeight: isTaskForMe && isUnseen && view !== HISTORY_VALUE && 'bold'
                                            }}>
                                    {matchingTranslation.title}
                                </Typography>
                            </Flex>
                            <Flex item container basis={'20%'} justifyContent={'flex-end'}
                                  style={{flexWrap: 'wrap'}}>
                                {
                                    task.freeTags.map((tag, index) =>
                                        <Tag key={index} {...{
                                            tag,
                                            selected: _.some(selectedTags, ['name', tag.name]),
                                            toggleSelection: toggleTagSelection
                                        }}/>)
                                }
                            </Flex>
                        </Flex>
                        {
                            this.state.isLoaded &&
                            <div className={classes.indicatorContainer}>
                                <div className={classes.iconContainer}>
                                    <ActionListIndicator {...{
                                        numberOfActions: numberOfActions,
                                        url: TASK_EDIT.pathWithId(businessId),
                                        classes: {iconButtonRoot: classes.iconColor},
                                        onOpen: removeUnseenActionTag(task),
                                        isUnseen: hasUnseenAction && view !== HISTORY_VALUE
                                            && user.businessId === assignee.businessId,
                                        iconColor: seenIconGrey
                                    }}/>
                                </div>
                                {
                                    !isOfflineCreated && applicationOnline &&
                                    <div className={classes.iconContainer}>
                                        <AttachmentIndicator
                                            {...{
                                                id: businessId,
                                                numberOfAttachments: numberOfAttachments,
                                                files: attachments,
                                                referenceType: 'TASK',
                                                classes: {iconButtonRoot: classes.iconColor},
                                                uploadEnabled: true,
                                                onOpen: removeUnseenAttachmentTag(task),
                                                isUnseen: hasUnseenAttachment && view !== HISTORY_VALUE
                                                    && user.businessId === assignee.businessId,
                                                iconColor: seenIconGrey,
                                                isLoading: this.isLoading(attachments, numberOfAttachments),
                                                refresh
                                            }}
                                        />
                                    </div>
                                }
                                {
                                    !isOfflineCreated && applicationOnline &&
                                    <div className={classes.iconContainer}>
                                        <div style={{color: 'inherit'}}>
                                            <CommentIndicator
                                                {...{
                                                    classes: {iconButtonRoot: classes.iconColor},
                                                    entityId: businessId,
                                                    numberOfComments: numberOfComments,
                                                    comments: comments,
                                                    type: 'Task',
                                                    onOpen: removeUnseenCommentTag(task),
                                                    isUnseen: hasUnseenComment && view !== HISTORY_VALUE
                                                        && user.businessId === assignee.businessId,
                                                    iconColor: seenIconGrey,
                                                    isLoading: this.isLoading(comments, numberOfComments),
                                                    refresh
                                                }}
                                            />
                                        </div>
                                    </div>
                                }
                                {
                                    applicationOnline &&
                                    <div className={classes.iconContainer}>
                                        <div style={{color: 'inherit'}}>
                                            <ChecklistIndicator
                                                {...{
                                                    classes: {iconButtonRoot: classes.iconColor},
                                                    entityId: businessId,
                                                    numberOfChecklistItems: numberOfChecklistItems,
                                                    checklist: checklist,
                                                    onOpen: removeUnseenChecklistTag(task),
                                                    isUnseen: hasUnseenCriteria && view !== HISTORY_VALUE
                                                        && user.businessId === assignee.businessId,
                                                    iconColor: seenIconGrey,
                                                    isLoading: this.isLoading(checklist, numberOfChecklistItems),
                                                    refresh
                                                }}
                                            />
                                        </div>
                                    </div>
                                }
                            </div>
                        }
                        <Flex item={'0 0 50px'} container alignItems={'center'} justifyContent={'flex-end'}>
                            {
                                businessId.startsWith('OFFLINE') ?
                                    <Fragment>
                                        {
                                            applicationOnline &&
                                            <CircularProgress classes={{root: classes.circularProgress}} size={12}/>
                                        }
                                        <Typography className={classes.titleBarItem}
                                                    style={{
                                                        paddingLeft: 6,
                                                        color: view === BACKLOG_VALUE ? htmlGrey : htmlBlack
                                                    }}>TEMP-TASK</Typography>
                                    </Fragment>
                                    :
                                    <Typography className={classes.titleBarItem}
                                                style={{color: view === BACKLOG_VALUE ? htmlGrey : htmlBlack}}>#{businessId}</Typography>
                            }
                        </Flex>
                        {
                            view === ACTIVE_VALUE && this.state.isLoaded &&
                            <div className={classes.dateContainer}>
                                <Typography className={classes.dueDate}>
                                    {moment(dueDate).format(utils.DATE_FORMAT)}
                                </Typography>
                                <DueDateProgressBar value={dueDateProgress} style={{width: 45, height: 3}}/>
                            </div>
                        }
                        {
                            view === HISTORY_VALUE && this.state.isLoaded &&
                            <Flex item={'0 0 70px'} container alignItems={'center'} style={{marginRight: 5}}>
                                <DoneIcon/>
                                <Typography className={classes.dueDate}>
                                    {moment(finishedDate).format(utils.DATE_FORMAT)}
                                </Typography>
                            </Flex>
                        }
                    </Fragment>
                </Flex>
                : <DragTaskTile {...{
                    taskId: task.businessId,
                    assignee: task.assignee,
                    dragHandleProps
                }}/>
        );
    }
}

export default withStyles(styles)(withTranslation()(SortableTask));
