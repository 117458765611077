import React, {Fragment, PureComponent} from 'react';
import {
    withStyles, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Checkbox, Button, Typography
} from "@material-ui/core";
import {Trans, withTranslation} from "react-i18next";
import Flex from "components/grid/Flex";
import {htmlGrey} from 'components/colors/Colors';

const styles = theme => ({
    controlButtons: {
        minWidth: 150,
    },
    buttonWithMargin: {
        minWidth: 150,
        marginRight: 15
    },
});

const DialogButtons = (props) => {
    const {
        classes, toggleDialog, save, masterLanguage, translate, forcePictureOverride, forceTranslateOverride,
        isMasterPictureChanged, isMasterTranslationChanged
    } = props;
    const forcedSave = forcePictureOverride || forceTranslateOverride;
    const masterChanged = isMasterPictureChanged || isMasterTranslationChanged;
    return (
        <Flex container item={'1 0 auto'} style={{padding: '0 17px 10px'}}>
            <Flex item>
                <Button variant="contained"
                        color="primary"
                        onClick={toggleDialog}
                        className={classes.buttonWithMargin}
                >
                    <Trans i18nKey={"global.cancel"}/>
                </Button>
            </Flex>
            <Flex container item={'1 0 auto'} direction={'row-reverse'}>
                <Button variant="contained"
                        color="primary"
                        onClick={save}
                        className={classes.controlButtons}
                >
                    {
                        masterChanged ?
                            forcedSave
                                ?
                                <Trans i18nKey={"audit-administration.questionEdit.unsafeSave.yes"}/>
                                :
                                <Trans i18nKey={"audit-administration.questionEdit.unsafeSave.no"}
                                       values={{masterLanguage: translate(`global.languages.${masterLanguage.code}.label`)}}/>
                            :
                            <Trans i18nKey={"global.save"}/>
                    }
                </Button>
            </Flex>
        </Flex>
    );
};

export class UnsafeSaveDialog extends PureComponent {

    state = {
        forcePictureOverride: false,
        forceTranslateOverride: false
    };

    toggleForcePictureOverride = () => {
        this.setState({
            forcePictureOverride: !this.state.forcePictureOverride
        })
    };

    toggleForceTranslateOverride = () => {
        this.setState({
            forceTranslateOverride: !this.state.forceTranslateOverride
        })
    };

    save = () => {
        this.props.save(this.state.forcePictureOverride, this.state.forceTranslateOverride);
    };

    render() {
        const {
            classes, t: translate, isSaveDialogOpen, masterLanguage, isMasterTranslationChanged, toggleDialog,
            isMasterPictureChanged, concatenateNotChangedManualTranslations,
            concatenateLanguagesWithAlreadyExistingPictures, isQuestionDisapproved, assignedQuestionnaires
        } = this.props;
        const {forcePictureOverride, forceTranslateOverride} = this.state;
        const {toggleForcePictureOverride, toggleForceTranslateOverride, save} = this;
        return (
            <Dialog
                open={isSaveDialogOpen}
                maxWidth={'lg'}
            >
                <DialogTitle
                    id="alert-dialog-title">
                    {
                        isMasterTranslationChanged && !isMasterPictureChanged &&
                        translate('audit-administration.questionEdit.unsafeSave.translation.title',
                            {masterLanguage: translate(`global.languages.${masterLanguage.code}.label`)})
                    }
                    {
                        !isMasterTranslationChanged && isMasterPictureChanged &&
                        translate('audit-administration.questionEdit.unsafeSave.picture.title',
                            {masterLanguage: translate(`global.languages.${masterLanguage.code}.label`)})
                    }
                    {
                        isMasterTranslationChanged && isMasterPictureChanged &&
                        translate('audit-administration.questionEdit.unsafeSave.title',
                            {masterLanguage: translate(`global.languages.${masterLanguage.code}.label`)})
                    }
                    {
                        !isMasterTranslationChanged && !isMasterPictureChanged && isQuestionDisapproved &&
                        translate('audit-administration.questionEdit.unsafeSave.disapprove')
                    }
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description" style={{paddingBottom: 24}}>
                        {
                            isQuestionDisapproved &&
                            <Fragment>
                                <Fragment>
                                    {translate('audit-administration.questionEdit.unsafeSave.assignedQuestionnaire')}
                                </Fragment>
                                <ul>
                                    {
                                        assignedQuestionnaires.map((assignedQuestionnaire, index) => {
                                                return (
                                                    <li key={index}>
                                                        {assignedQuestionnaire}
                                                    </li>
                                                )
                                            }
                                        )
                                    }
                                </ul>
                            </Fragment>

                        }
                    </DialogContentText>
                    <DialogContentText id="alert-dialog-description" style={{paddingBottom: 24}}>
                        {
                            isMasterTranslationChanged && !isMasterPictureChanged &&
                            translate('audit-administration.questionEdit.unsafeSave.translation.content',
                                {otherLanguages: concatenateNotChangedManualTranslations()})
                        }
                        {
                            !isMasterTranslationChanged && isMasterPictureChanged &&
                            translate('audit-administration.questionEdit.unsafeSave.picture.content',
                                {otherLanguages: concatenateLanguagesWithAlreadyExistingPictures()})
                        }
                        {
                            isMasterTranslationChanged && isMasterPictureChanged &&
                            translate('audit-administration.questionEdit.unsafeSave.content',
                                {otherLanguages: concatenateNotChangedManualTranslations()})
                        }
                    </DialogContentText>
                    {
                        isMasterPictureChanged &&
                        <Flex item container alignItems={'center'} justifyContent={'flex-end'}>
                            <Checkbox checked={forcePictureOverride} onChange={toggleForcePictureOverride}/>
                            <Typography style={{color: htmlGrey, textAlign: 'right', minWidth: 152}}>
                                <Trans i18nKey={"audit-administration.questionEdit.unsafeSave.forcePictureOverride"}/>
                            </Typography>

                        </Flex>
                    }
                    {
                        isMasterTranslationChanged &&
                        <Flex item container alignItems={'center'} justifyContent={'flex-end'}>
                            <Checkbox checked={forceTranslateOverride} onChange={toggleForceTranslateOverride}/>
                            <Typography style={{color: htmlGrey, textAlign: 'right', minWidth: 152}}>
                                <Trans i18nKey={"audit-administration.questionEdit.unsafeSave.forceTranslateOverride"}/>
                            </Typography>

                        </Flex>
                    }
                </DialogContent>
                <DialogActions>
                    <DialogButtons {...{
                        classes,
                        toggleDialog,
                        save,
                        masterLanguage,
                        translate,
                        forcePictureOverride,
                        forceTranslateOverride,
                        isMasterPictureChanged,
                        isMasterTranslationChanged,
                        isQuestionDisapproved
                    }}/>
                </DialogActions>
            </Dialog>
        );
    }
}

export default withStyles(styles)(withTranslation()(UnsafeSaveDialog));
