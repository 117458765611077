import React, {PureComponent} from 'react';
import {Checkbox, IconButton, Typography, withStyles,} from "@material-ui/core";
import Flex from 'components/grid/Flex';
import ExpandMore from '@material-ui/icons/PlayArrow';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import DescriptionOutlinedIcon from '@material-ui/icons/InsertDriveFileOutlined';
import FolderIcon from '@material-ui/icons/Folder';
import {christiGreen, dustyGrey, ghostGrey, greyHighlight, doveGrey} from 'components/colors/Colors';
import {ContextMenu, ContextMenuTrigger, MenuItem} from "react-contextmenu";
import {contextMenu} from "components/zindex/zIndex";
import {Trans} from "react-i18next";
import SettingsIcon from '@material-ui/icons/Settings';
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import TextField from "@material-ui/core/TextField";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import _ from 'lodash';
import utils from "utils/Utils";
import {Droppable} from 'react-beautiful-dnd';

const styles = theme => ({
    input: {
        padding: '5px 10px',
        fontSize: '0.875rem'
    },
    checkBoxIcon: {
        fill: christiGreen
    },
    pointerCursor: {
        '&:hover': {
            backgroundColor: greyHighlight,
            cursor: 'pointer'
        }
    },
    icon: {
        fontSize: 20,
        marginRight: 5,
        color: dustyGrey
    },
    menuItem: {
        margin: 5,
        cursor: "pointer",
        zIndex: contextMenu
    },
    menu: {
        margin: 5,
        background: greyHighlight,
        zIndex: contextMenu,
        borderRadius: "2px",
        boxShadow: "0 1px 1px 1px " + ghostGrey,
        '&:hover': {
            background: ghostGrey,
            cursor: 'pointer'
        }
    },
    menuIcon: {
        paddingRight: 5,
        fontSize: 24
    }
});

const DynamicMenu = (props) => {
    const {node, classes, toggleSelection, onTitleEdit} = props;
    return (
        <ContextMenu id={node.businessId} className={classes.menu} onShow={toggleSelection(node.businessId)}>
            <MenuItem className={classes.menuItem} onClick={() => onTitleEdit(node)}>
                <Flex container item alignItems={'center'}>
                    <SettingsIcon className={classes.menuIcon}/>
                    <Trans i18nKey={"global.rename"}/>
                </Flex>
            </MenuItem>
        </ContextMenu>
    );
};

const RenameDialog = (props) => {
    const {node, renameDialogOpen, handleRenameDialogOpen, onTitleChange, title, handleRenameNode} = props;
    return (
        <Dialog open={renameDialogOpen} onClose={() => handleRenameDialogOpen(node)}>
            <DialogContent>
                <TextField
                    value={title}
                    onChange={onTitleChange}
                    autoFocus
                    margin="dense"
                    id="name"
                    fullWidth
                    onKeyDown={(event) => {
                        if (event.keyCode === utils.ENTER_KEY_CODE) {
                            handleRenameNode(node);
                        }
                    }}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={() => handleRenameDialogOpen()} color={'primary'} variant={'contained'}>
                    <Trans i18nKey={"global.cancel"}/>
                </Button>
                <Button onClick={() => handleRenameNode(node)} color={'primary'} variant={'contained'}
                        disabled={_.isEmpty(title)}>
                    <Trans i18nKey={"global.rename"}/>
                </Button>
            </DialogActions>
        </Dialog>
    );
};

const DroppableWrap = (props) => {
    const {children, node, isDroppable} = props;
    return (
        isDroppable
            ? <Droppable droppableId={'treeNode-' + node.businessId}>
                {(provided, snapshot) => (
                    <div {...provided.droppableProps}
                         ref={provided.innerRef}
                         style={{
                             border: snapshot.isDraggingOver ? '1px solid black' : 'none'
                         }}
                    >
                        {children}
                        <span style={{display: 'none'}}>{provided.placeholder}</span>
                    </div>
                )}
            </Droppable>
            : <>
                {children}
            </>
    );
};

export class TreeFilterLeaf extends PureComponent {

    state = {
        renameDialogOpen: false,
        title: ''
    };

    edit = event => {
        if (this.props.node.selected) {
            event.preventDefault();
            event.stopPropagation();
            if (this.props.node.editable) {
                this.setState({
                    isTitleEditable: true,
                    title: this.props.node.title
                })
            }
        }
    };

    toggleOpen = event => {
        event.preventDefault();
        event.stopPropagation();
        this.props.toggleOpen(this.props.node.businessId)();
    };


    handleRenameDialogOpen = (node) => {
        this.setState({
            title: _.get(node, 'name', ''),
            renameDialogOpen: !this.state.renameDialogOpen
        })
    };

    handleRenameNode = (node) => {
        this.props.editNode(node.businessId, this.state.title).then().catch().then(() => {
            this.handleRenameDialogOpen();
        });
    };

    onTitleChange = (event) => {
        this.setState({
            title: event.target.value
        });
    };

    render() {
        const {
            classes, node, isOpenAble, toggleSelection, multiSelect, applicationOnline, noEdit, isDroppable
        } = this.props;
        const {edit, toggleOpen, handleRenameDialogOpen, handleRenameNode, onTitleChange} = this;
        const {renameDialogOpen, title} = this.state;
        return (
            <DroppableWrap isDroppable={isDroppable} node={node}>
                <RenameDialog {...{
                    node,
                    renameDialogOpen,
                    handleRenameDialogOpen,
                    onTitleChange,
                    title,
                    handleRenameNode
                }}/>
                <Flex item
                      container
                      alignItems={'center'}
                      onClick={multiSelect ? () => {
                      } : toggleSelection(node.businessId)}
                >
                    {
                        applicationOnline && !noEdit &&
                        <DynamicMenu  {...{
                            node: node,
                            classes,
                            toggleSelection,
                            onTitleEdit: handleRenameDialogOpen
                        }}/>
                    }
                    {
                        node.open
                            ?
                            <IconButton style={{padding: 5, visibility: !isOpenAble ? 'hidden' : ''}}
                                        onClick={toggleOpen}
                                        disabled={!isOpenAble}>
                                <ExpandMore className={classes.icon}
                                            style={{marginRight: 0, transform: 'rotate(90deg)'}}/>
                            </IconButton>
                            :
                            <IconButton style={{padding: 5, visibility: !isOpenAble ? 'hidden' : ''}}
                                        onClick={toggleOpen}
                                        disabled={!isOpenAble}>
                                <ExpandMore className={classes.icon} style={{marginRight: 0}}/>
                            </IconButton>
                    }
                    {
                        multiSelect &&
                        <Checkbox
                            checked={node.selected}
                            style={{padding: 5, paddingLeft: 0}}
                            onChange={toggleSelection(node.businessId)}
                            icon={<CheckBoxOutlineBlankIcon style={{fontSize: 20}}/>}
                            checkedIcon={<CheckBoxIcon style={{fontSize: 20}}
                                                       className={classes.checkBoxIcon}/>}
                        />
                    }
                    <Flex container item alignItems={'center'}>
                        {
                            isOpenAble
                                ?
                                !multiSelect && <FolderIcon className={classes.icon}/>
                                :
                                !multiSelect && <DescriptionOutlinedIcon className={classes.icon}/>
                        }
                        {
                            <ContextMenuTrigger
                                {...{
                                    id: node.businessId
                                }}
                            >
                                <Typography onClick={edit}
                                            className={classes.pointerCursor}
                                            style={{
                                                color: doveGrey,
                                                padding: 0,
                                                paddingRight: !isOpenAble ? 15 : 0,
                                                fontWeight: node.selected ? 800 : 400
                                            }}>
                                    {node.title}
                                </Typography>
                            </ContextMenuTrigger>
                        }
                    </Flex>
                </Flex>
            </DroppableWrap>
        );
    }
}

export default withStyles(styles)(TreeFilterLeaf);
