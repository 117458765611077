import {fetchAuditQuestionStatistics} from "./AuditReportingPareto.action";

export default {
    mapStateToProps: (state) => {
        return {
            selectedOrganizationLevel: state.organizationTreeReducer.selectedOrganizationLevel,
            statistics: state.auditReportingParetoReducer.statistics,
            questionStatistics: state.auditReportingParetoReducer.questionStatistics,
            catalogueStatistics: state.auditReportingParetoReducer.catalogueStatistics,
        };
    },
    mapDispatchToProps: (dispatch) => {
        return {
            fetchAuditQuestionStatistics: (filterData) => dispatch(fetchAuditQuestionStatistics(filterData)),
        };
    }
};
