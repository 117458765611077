import React, {Component} from 'react';
import InlineComments from 'components/comment/InlineComments';
import {connect} from 'react-redux';
import connector from './DesktopActionEditComments.connect';

export class DesktopActionEditComments extends Component {

    componentDidMount() {
        if (this.props.onOpen) {
            this.props.onOpen();
        }
    }

    addComment = comment => {
        this.props.addActionComment(this.props.actionBusinessId, comment).then(() => {
            this.props.fetchAction();
        });
    };

    deleteComment = commentId => () => {
        this.props.deleteActionComment(commentId).then(() => {
            this.props.fetchAction();
        });
    };

    render() {
        const {comments, user} = this.props;
        const {deleteComment, addComment} = this;

        return (
            <InlineComments {...{comments, user, deleteComment, addComment}}/>
        );
    }
}

export default connect(connector.mapStateToProps, connector.mapDispatchToProps)(DesktopActionEditComments);
