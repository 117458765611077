import React, {Component} from 'react';
import {Tooltip} from "@material-ui/core";

/**
 * https://github.com/mui-org/material-ui/issues/8416
 * LM: Material ui tooltip does not work on disabled buttons, the "official" solution is to add a wrapper...
 */
export class H4Tooltip extends Component {
    render() {
        const {children, ...other} = this.props;

        return (
            <Tooltip
                {...other}
            >
                <span>
                    {children}
                </span>
            </Tooltip>
        );
    }
}

export default H4Tooltip;
