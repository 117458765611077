import * as actionTypes from "./OrganizationTree.action.types";
import * as mainActionTypes from 'scenes/Main.action.types';
import _ from "lodash";
import {mapOrganizationLevel} from "domain/audit/OrganizationLevel.model";

const FIRST_CLICK = 1; // The node and all subnodes are selected
const SECOND_CLICK = 2; // The subnodes are unselected, the clicked-on node stays selected
const THIRD_CLICK = 3; //  Main and subnodes are unselected

const initialState = {
    initialized: false,
    organizationTree: [],
    isOrganizationTreeOpen: true,

    isRootTitleEditable: false,

    organizationFilterBusinessId: '',
    organizationMultiFilterBusinessIds: [],

    selectedOrganizationLevel: {},
    multiSelectedOrganizationLevels: [],
    lastMultiSelectedOrganizationLevelId: '',
    multiSelection: 0,

    configuration: {
        users: [],
        timeZones: []
    },

    openAudits: []
};

export default function (state = initialState, action) {
    switch (action.type) {
        case actionTypes.FETCH_ORGANIZATION_MANAGEMENT_CONFIGURATION_DATA_SUCCESS: {
            const newState = {...state};
            newState.configuration.timeZones = action.payload.data.data.fetchOrganizationManagementConfigurationData.timeZones;
            return newState;
        }
        case actionTypes.FETCH_ORGANIZATION_LEVELS_SUCCESS: {
            const newState = _.cloneDeep(state);
            const user = action.meta.previousAction.data.user;
            newState.initialized = true;
            newState.organizations = mapOrganizations(action.payload.data.data.listOrganizationLevels);
            newState.organizationTree = mapOrganizationsToTree(newState.organizations, newState.organizationTree, user);
            const isOrganizationLevelTreeInitializing = newState.organizationFilterBusinessId === '';
            if (isOrganizationLevelTreeInitializing) {
                const autoSelectedOrganization = newState.organizationTree[0];
                if (autoSelectedOrganization) {
                    newState.organizationFilterBusinessId = autoSelectedOrganization.businessId;
                    toggleSelected(newState.organizationTree, newState.organizationFilterBusinessId);
                    toggleMultiSelected(newState.organizationTree, newState.organizationMultiFilterBusinessIds, newState.organizationFilterBusinessId);
                }
                toggleOpen(newState.organizationTree, newState.organizationTree.find(organization => !organization.parentBusinessId).businessId);
            }
            newState.selectedOrganizationLevel = newState.organizationTree.find(organizationTree => organizationTree.selected);
            newState.multiSelectedOrganizationLevels = newState.organizationTree.filter(organizationTree => organizationTree.selected);
            return newState;
        }
        case actionTypes.TOGGLE_FILTER_OPEN: {
            const newState = _.cloneDeep(state);
            toggleOpen(newState.organizationTree, action.data.businessId);
            return newState;
        }
        case actionTypes.TOGGLE_FILTER_SELECTION: {
            const newState = _.cloneDeep(state);
            newState.organizationTree = clearAllSelections(newState.organizationTree);
            toggleSelected(newState.organizationTree, action.data.businessId);
            newState.selectedOrganizationLevel = newState.organizationTree.find(organizationTree => organizationTree.selected);
            newState.organizationFilterBusinessId = action.data.businessId;

            newState.lastMultiSelectedOrganizationLevelId = action.data.businessId;
            newState.multiSelectedOrganizationLevels = newState.organizationTree.filter(organizationTree => organizationTree.selected);
            newState.organizationMultiFilterBusinessIds.push(action.data.businessId);
            return newState;
        }
        case actionTypes.TOGGLE_FILTER_MULTI_SELECTION: {
            const newState = _.cloneDeep(state);
            const clickedBusinessId = action.data.businessId;
            const leaf = newState.organizationTree.find(value => value.businessId === clickedBusinessId).isLeaf;
            if (clickedBusinessId === newState.lastMultiSelectedOrganizationLevelId) {
                if (leaf) {
                    newState.multiSelection = newState.multiSelection === THIRD_CLICK ? FIRST_CLICK : THIRD_CLICK;
                } else {
                    newState.multiSelection = newState.multiSelection === THIRD_CLICK ? FIRST_CLICK : (newState.multiSelection + 1);
                }
            } else {
                newState.lastMultiSelectedOrganizationLevelId = clickedBusinessId;
                newState.multiSelection = FIRST_CLICK;
            }

            newState.organizationTree = handleMultiSelect(newState.organizationTree, newState.multiSelection, clickedBusinessId);
            const selectedLevels = newState.organizationTree.filter(organizationTree => organizationTree.selected);

            newState.organizationMultiFilterBusinessIds = selectedLevels.map(value => value.businessId);
            newState.multiSelectedOrganizationLevels = selectedLevels;
            return newState;
        }
        case actionTypes.TOGGLE_FILTER_MULTI_SELECTIONS: {
            const newState = _.cloneDeep(state);
            const businessIds = action.data.businessIds;
            newState.multiSelection = SECOND_CLICK;

            newState.organizationTree = newState.organizationTree.map(organization => {
                return {
                    ...organization,
                    selected: !!businessIds.includes(organization.businessId)
                }
            });

            const selectedLevels = newState.organizationTree.filter(organizationTree => organizationTree.selected);
            newState.organizationMultiFilterBusinessIds = selectedLevels.map(value => value.businessId);
            newState.multiSelectedOrganizationLevels = selectedLevels;
            return newState;
        }
        case actionTypes.OPEN_ORGANIZATION_LEVEL_TREE: {
            const newState = {...state};
            newState.isOrganizationTreeOpen = true;
            return newState;
        }
        case actionTypes.CLOSE_ORGANIZATION_LEVEL_TREE: {
            const newState = {...state};
            newState.isOrganizationTreeOpen = false;
            return newState;
        }
        case actionTypes.DELETE_ORGANIZATION_LEVEL_SUCCESS: {
            const newState = _.cloneDeep(state);
            const deletedNode = newState.organizationTree.find(organizationTree => organizationTree.businessId === action.payload.data.data.deleteOrganizationLevel);
            const parentId = deletedNode.rootBusinessId;
            newState.organizationTree = newState.organizationTree.filter(function (org) {
                return org.businessId !== deletedNode.businessId;
            });
            newState.organizationFilterBusinessId = parentId;
            toggleSelected(newState.organizationTree, newState.organizationFilterBusinessId);
            newState.selectedOrganizationLevel = newState.organizationTree.find(organizationTree => organizationTree.selected);
            newState.multiSelectedOrganizationLevels = newState.organizationTree.filter(organizationTree => organizationTree.selected);
            return newState;
        }
        case actionTypes.FETCH_USERS_SUCCESS: {
            const newState = _.cloneDeep(state);
            newState.configuration.users = action.payload.data.data.listUsers;
            return newState;
        }
        case actionTypes.CLEAR_FILTER_MULTI_SELECTION: {
            const newState = {...state};
            const previouslySelectedOrganizationLevels = newState.organizationTree.filter(organizationTree => organizationTree.selected);
            const previouslySelectedBusinessId = previouslySelectedOrganizationLevels.length === 1 ? previouslySelectedOrganizationLevels[0].businessId : newState.organizationFilterBusinessId;
            if (previouslySelectedBusinessId) {
                newState.organizationTree = clearSelections(newState.organizationTree, previouslySelectedBusinessId);
                newState.organizationFilterBusinessId = previouslySelectedBusinessId;
                newState.selectedOrganizationLevel = newState.organizationTree.find(organizationTree => organizationTree.businessId === previouslySelectedBusinessId);
            }
            const selectedOrganizationLevels = newState.organizationTree.filter(organizationTree => organizationTree.selected);
            newState.organizationMultiFilterBusinessIds = selectedOrganizationLevels.map(value => value.businessId);
            newState.multiSelectedOrganizationLevels = selectedOrganizationLevels;
            return newState;
        }
        case actionTypes.TOGGLE_FILTER_SELECT_ALL: {
            const newState = _.cloneDeep(state);
            if (newState.organizationTree.filter(organization => organization.selected).length === newState.organizationTree.length) {
                const previouslySelected = newState.selectedOrganizationLevel;
                newState.organizationMultiFilterBusinessIds = [];
                newState.multiSelectedOrganizationLevels = [];
                newState.organizationTree = clearSelections(newState.organizationTree, previouslySelected.businessId);

                toggleMultiSelected(newState.organizationTree, newState.organizationMultiFilterBusinessIds, previouslySelected.businessId);

                newState.organizationMultiFilterBusinessIds = [previouslySelected.businessId];
                newState.multiSelectedOrganizationLevels = [previouslySelected];
            } else {
                newState.organizationMultiFilterBusinessIds = newState.organizationTree.map(value => value.businessId);
                newState.multiSelectedOrganizationLevels = newState.organizationTree;
                newState.organizationTree = selectAndOpenAll(newState.organizationTree);
            }
            return newState;
        }
        case actionTypes.UPDATE_ORGANIZATION_TREE_ROOT_TITLE: {
            const newState = {...state};
            newState.isRootTitleEditable = !newState.isRootTitleEditable;
            return newState;
        }
        case actionTypes.RESET_ORGANIZATION_LEVEL_TREE:
        case mainActionTypes.LOGOUT:
            return initialState;
        case actionTypes.FETCH_AUDITS_SUCCESS: {
            const newState = {...state};
            newState.openAudits = action.payload.data.data.listAudits;
            return newState
        }
        default:
            return state;
    }
}

export function mapOrganizations(organizations) {
    return organizations.map(mapOrganizationLevel)
}


export function mapOrganizationsToTree(organizations, organizationTree, user) {
    const newOrganizationLevelTree = _.sortBy(organizations, 'ordinal').map(organization => {
        const oldTreeNode = organizationTree
            .find(organizationInOldTree => organizationInOldTree.businessId === organization.businessId);
        const parentLevel = _.get(organization, 'parentLevel');
        const parent = parentLevel && {
            ...parentLevel,
            editable: canEditOrganization(user, organizations
                .find(organizationInArray => organizationInArray.businessId === parentLevel.businessId))
        };
        const children = organizations.filter(nodeInFilter => _.get(nodeInFilter, 'parentLevel.businessId') === organization.businessId);
        return {
            parent,
            rootBusinessId: organization.parentHierarchy[0] && organization.parentHierarchy[0].organizationLevelBusinessId,
            businessId: organization.businessId,
            members: organization.members,
            title: organization.shortName,
            open: oldTreeNode ? oldTreeNode.open : parent !== undefined,
            selected: oldTreeNode ? oldTreeNode.selected : false,
            editable: canEditOrganization(user, organization),
            accessible: canAccessOrganization(user, organization),
            isUserAdmin: isOrganizationAdmin(user, organization),
            timeZone: organization.timeZone,
            attachments: organization.attachments,
            shiftSchedule: organization.shiftSchedule,
            parentIds: organization.parentHierarchy.map(parent => parent.organizationLevelBusinessId),
            isLeaf: children.length === 0,
            hasChild: children.length > 0
        }
    });
    if (_.isEmpty(organizationTree)) {
        newOrganizationLevelTree[0].selected = true;
    }
    return newOrganizationLevelTree;
}


export function toggleOpen(organizationTree, businessId) {
    const indexOfChangedNode = organizationTree.findIndex(node => node.businessId === businessId);
    organizationTree[indexOfChangedNode].open = !organizationTree[indexOfChangedNode].open;
}

export function toggleSelected(organizationTree, businessId) {
    const indexOfChangedNode = organizationTree.findIndex(node => node.businessId === businessId);
    const organizationTreeElement = organizationTree[indexOfChangedNode];
    organizationTreeElement.selected = true;
}

export function toggleMultiSelected(organizationTree, organizationFilterBusinessIds, businessId) {
    const indexOfChangedNode = organizationTree.findIndex(node => node.businessId === businessId);
    organizationTree[indexOfChangedNode].selected = !organizationFilterBusinessIds.includes(businessId);
}

function handleMultiSelect(organizationTree, type, businessId) {
    switch (type) {
        case FIRST_CLICK: {
            return selectNodeAndSubNodes(organizationTree, businessId);
        }
        case SECOND_CLICK: {
            return selectNodeOnly(organizationTree, businessId);
        }
        case THIRD_CLICK: {
            return deselectNodeAndSubNodes(organizationTree, businessId);
        }
        default: {
            console.error("Not supported state:", type);
            return [];
        }
    }
}

function selectNodeAndSubNodes(organizationTree, businessId) {
    return organizationTree.map(organization => {
        return {
            ...organization,
            selected: businessId === organization.businessId || organization.parentIds.includes(businessId) ? true : organization.selected
        }
    });
}

function selectNodeOnly(organizationTree, businessId) {
    return organizationTree.map(organization => {
        let value;
        if (organization.businessId === businessId)
            value = true;
        else if (organization.parentIds.includes(businessId)) {
            value = false;
        } else {
            value = organization.selected
        }
        return {
            ...organization,
            selected: value
        }
    });
}

function deselectNodeAndSubNodes(organizationTree, businessId) {
    return organizationTree.map(organization => {
        return {
            ...organization,
            selected: businessId === organization.businessId || organization.parentIds.includes(businessId) ? false : organization.selected
        }
    });
}

function getMemberInOrganization(organization, user) {
    return organization && organization.members
        .find(member => _.get(member, 'user.businessId') === user.businessId);
}

export function canEditOrganization(user, organization) {
    const memberInOrganization = getMemberInOrganization(organization, user);
    return memberInOrganization ? memberInOrganization.memberships
        .some(membership => membership === 'ADMIN' || membership === 'OWNER') : false;
}

export function isOrganizationAdmin(user, organization) {
    const memberInOrganization = getMemberInOrganization(organization, user);
    return memberInOrganization ? memberInOrganization.memberships
        .some(membership => membership === 'ADMIN') : false;
}

function canAccessOrganization(user, organization) {
    return !_.isEmpty(getMemberInOrganization(organization, user));
}


function clearAllSelections(organizationTree) {
    return organizationTree.map(organization => {
        return {
            ...organization,
            selected: false
        }
    });
}

function clearSelections(organizationTree, businessId) {
    return organizationTree.map(organization => {
        return {
            ...organization,
            selected: organization.businessId === businessId
        }
    });
}

function selectAndOpenAll(organizationTree) {
    return organizationTree.map(organization => {
        return {
            ...organization,
            selected: true,
            open: true
        }
    });
}