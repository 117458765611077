import React, {Component} from "react";
import {withStyles} from "@material-ui/core";
import DayPicker from "react-day-picker";
import './CalenderPreview.css';
import Flex from "components/grid/Flex";
import {withTranslation} from "react-i18next";
import configs from "configs/Configs";

const styles = theme => ({});

class CalendarPreview extends Component {

    getMonths = (language) =>{
    const {t: translate} = this.props;
        return [
            translate('global.month.January', {lng: language}),
            translate('global.month.February', {lng: language}),
            translate('global.month.March', {lng: language}),
            translate('global.month.April', {lng: language}),
            translate('global.month.May', {lng: language}),
            translate('global.month.June', {lng: language}),
            translate('global.month.July', {lng: language}),
            translate('global.month.August', {lng: language}),
            translate('global.month.September', {lng: language}),
            translate('global.month.October', {lng: language}),
            translate('global.month.November', {lng: language}),
            translate('global.month.December', {lng: language}),
        ]

    };

    getWeekdaysShort= (language) => {
        const {t: translate} = this.props;
        return [
            translate('global.day.Su', {lng: language}),
            translate('global.day.Mo', {lng: language}),
            translate('global.day.Tu', {lng: language}),
            translate('global.day.We', {lng: language}),
            translate('global.day.Th', {lng: language}),
            translate('global.day.Fr', {lng: language}),
            translate('global.day.Sa', {lng: language}),
        ]
    };

    render() {
        const {selectedDays, numberOfMonths, modifiers, modifiersStyles, month, calendarStyle, handleDayClick} = this.props;
        const {getMonths,getWeekdaysShort } = this;
        return (
            <Flex container justifyContent={'center'} alignItems={'center'}>
                {
                   configs.getAllowedLanguages()
                        .filter(language => {
                            return language === this.props.i18n.language;
                        })
                        .map(language => {
                            return (
                                    <DayPicker
                                        key={language}
                                        locale={language}
                                        modifiers={modifiers}
                                        modifiersStyles={modifiersStyles}
                                        selectedDays={selectedDays}
                                        onDayClick={handleDayClick}
                                        numberOfMonths={numberOfMonths}
                                        canChangeMonth={false}
                                        showWeekNumbers
                                        firstDayOfWeek={1}
                                        month={month}
                                        months={getMonths(language)}
                                        weekdaysShort={getWeekdaysShort(language)}
                                        className={calendarStyle}
                                    />
                                )
                            }
                        )
                }
            </Flex>
        );
    }


}

export default withStyles(styles)(withTranslation()(CalendarPreview));
