import {
    fetchMachineDetails,
    fetchMachineStatusConfigurationData,
    fetchOrganizationLevel,
    updateMachineStatus,
    fetchUsers, fetchAllMachineDetails
} from "./DocumentMachineStatus.actions";

export default {
    mapStateToProps: (state) => {
        return {
            map: state.documentMachineStatusReducer.map,
            markers: state.documentMachineStatusReducer.markers,
            machine: state.documentMachineStatusReducer.machine,
            statuses: state.documentMachineStatusReducer.statuses,
            users: state.documentMachineStatusReducer.users,
            applicationOnline: state.mainReducer.online
        };
    },
    mapDispatchToProps: (dispatch) => {
        return {
            fetchOrganizationLevel: (levelBusinessId) => dispatch(fetchOrganizationLevel(levelBusinessId)),
            fetchMachineDetails: (machine) => dispatch(fetchMachineDetails(machine)),
            updateMachineStatus: (machineId, statusNotWorking, status, taskTitle) => dispatch(updateMachineStatus(machineId, statusNotWorking, status, taskTitle)),
            fetchMachineStatusConfigurationData: () => dispatch(fetchMachineStatusConfigurationData()),
            fetchUsers: () => dispatch(fetchUsers()),
            fetchAllMachineDetails: (machineIds) => dispatch(fetchAllMachineDetails(machineIds))

        };
    }
};
