import * as actionTypes from './DocumentMachineStatus.action.types';
import _ from 'lodash';
import {cacheAllMachineDetails} from "./DocumentMachineStatus.cache";

const initialState = {
    map: null,
    markers: [],
    machine: null,
    statuses: [],
    users: []
};

export default function (state = initialState, action) {

    switch (action.type) {
        case actionTypes.FETCH_ORGANIZATION_LEVEL_SUCCESS: {
            const newState = _.cloneDeep(state);
            if (_.get(action, 'payload.data.data.fetchOrganizationLevel')) {
                newState.map = mapMap(action.payload.data.data.fetchOrganizationLevel);
                newState.markers = mapMarkers(action.payload.data.data.fetchOrganizationLevel);
            }
            return newState;
        }
        case actionTypes.FETCH_ORGANIZATION_LEVEL_FROM_CACHE: {
            const newState = _.cloneDeep(state);
            newState.map = mapMap(action.data.cache.organizationLevel);
            newState.markers = mapMarkers(action.data.cache.organizationLevel);
            return newState;
        }
        case actionTypes.FETCH_MACHINE_DETAILS_SUCCESS: {
            const newState = _.cloneDeep(state);
            if (_.get(action, 'payload.data.data.fetchMachineDetails')) {
                newState.machine = mapMachineDetails(action.payload.data.data.fetchMachineDetails, newState)
            }
            return newState;
        }
        case actionTypes.FETCH_MACHINE_DETAILS_FROM_CACHE: {
            const newState = _.cloneDeep(state);
            newState.machine = mapMachineDetails(action.data.cache.details, newState);
            return newState;
        }
        case actionTypes.FETCH_ALL_MACHINE_DETAILS_SUCCESS: {
            if (_.get(action, 'payload.data.data.listMachineDetails')) {
                cacheAllMachineDetails(action.payload.data.data.listMachineDetails);
            }
            return state;
        }
        case actionTypes.UPDATE_MACHINE_STATUS_SUCCESS: {
            const newState = _.cloneDeep(state);
            newState.machine = null;
            return newState;
        }
        case actionTypes.FETCH_MACHINE_STATUS_CONFIGURATION_DATA_SUCCESS: {
            const newState = _.cloneDeep(state);
            if (_.get(action, 'payload.data.data.fetchMachineStatusConfigurationData')) {
                newState.statuses = mapStatuses(action.payload.data.data.fetchMachineStatusConfigurationData)
            }
            return newState;
        }
        case actionTypes.FETCH_MACHINE_STATUS_CONFIGURATION_DATA_FROM_CACHE: {
            const newState = _.cloneDeep(state);
            newState.configuration = mapStatuses(action.data.cache.config);
            return newState;
        }
        case actionTypes.FETCH_USERS_SUCCESS: {
            const newState = _.cloneDeep(state);
            newState.users = action.payload.data.data.listUsers;
            return newState;
        }
        case actionTypes.FETCH_USERS_FROM_CACHE: {
            const newState = _.cloneDeep(state);
            newState.users = action.data.cache.users;
            return newState;
        }
        default:
            return state;
    }
}

export function mapStatuses(data) {
    return _.get(data, "statuses", []).map(status => {
        return status.type;
    }).filter(status => {
        return status !== 'WORKING'
            && status !== 'UNKNOWN'
    });
}

export function mapMachineDetails(data, state) {
    const marker = state.markers.find(marker => marker.businessId === data.businessId);
    return {
        ...data,
        ownerId: marker.ownerId,
        status: marker.status,
        ordinal: marker.ordinal,
        reportedDate: marker.reportedDate,
        history: marker.history
    };
}

export function mapMap(data) {
    return {
        url: data.view.map.source.downloadUrl,
        width: data.view.map.resolution.width,
        height: data.view.map.resolution.height
    }
}

export function mapMarkers(data) {
    return data.view.childViews.map(childView => {
        return {
            ownerId: getOwnerId(data),
            ordinal: childView.ordinal,
            x: childView.x,
            y: childView.y,
            businessId: childView.businessId,
            status: childView.statusReport.lastKnownStatus.status.type ? childView.statusReport.lastKnownStatus.status.type : childView.statusReport.lastKnownStatus.status,
            reportedDate: childView.statusReport.lastKnownStatus.reportedDate,
            history: childView.statusReport.history.map(history => history.status.type)
        }
    })
}

function getOwnerId(data) {
    const members = data.members || [];
    const owner = _.get(members.find(value => _.get(value, 'memberships', [] ).includes('OWNER')), 'user');
    return _.get(owner, 'businessId', '')
}
