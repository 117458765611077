import {
    addActionComment,
    deleteActionComment
} from 'components/comment/Comment.action';

export default {
    mapStateToProps: (state) => {
        return {
            user: state.mainReducer.user,
            comments: state.actionEditReducer.action.comments,
            actionBusinessId: state.actionEditReducer.action.businessId
        };
    },
    mapDispatchToProps: (dispatch) => {
        return {
            addActionComment: (actionId, comment) => dispatch(addActionComment(actionId, comment)),
            deleteActionComment: commentId => dispatch(deleteActionComment(commentId)),
        };
    }
};

