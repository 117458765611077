import {deleteSchedule, fetchSchedules, updateScheduleStatus,fetchQuestionnaires} from "./Schedules.action";

export default {
    mapStateToProps: (state) => {
        return {
            selectedOrganizationLevel: state.organizationTreeReducer.selectedOrganizationLevel,
            organizationFilterBusinessId: state.organizationTreeReducer.organizationFilterBusinessId,
            schedules: state.schedulesReducer.schedules,
            auditors: state.schedulesReducer.auditors,
            questionnaires: state.schedulesReducer.questionnaires,
            user: state.mainReducer.user,
            applicationOnline: state.mainReducer.online
        };
    },
    mapDispatchToProps: (dispatch) => {
        return {
            fetchSchedules: (levelBusinessId) => dispatch(fetchSchedules(levelBusinessId)),
            updateScheduleStatus: (scheduleId, status) => dispatch(updateScheduleStatus(scheduleId, status)),
            deleteSchedule: (scheduleId) => dispatch(deleteSchedule(scheduleId)),
            fetchQuestionnaires: (levelBusinessId) => dispatch(fetchQuestionnaires(levelBusinessId)),
        };
    }
};
