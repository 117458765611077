const template = `
<p>
    <strong>Environment</strong>: Staging
</p>
<p>
    <strong>Device</strong>: Desktop
</p>
<p>
    <strong>Version</strong>: 27.101.0
</p>
<br/>
<p>
    <strong>Steps to reproduce</strong>
</p>
<br/>
<p>
    <ol>
        <li>Go to page</li>
        <li>Select settings A and B</li>
        <li>Click on button C</li>
    </ol>
</p>
<br/>
<p>
    <strong>Expected result</strong>
</p>
<br/>
<p>Something opens and shows this and that</p>
<br/>
<p>
    <strong>Actual result</strong>
</p>
<br/>
<p>Nothing happens just an error is displayed</p>
`;
export default template;
