import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import connector from './ProjectTree.connect';
import ProjectRootLeaf from './ProjectRootLeaf';
import _ from 'lodash';
import ConfirmationDialog from "components/confirmation-dialog/ConfirmationDialog";
import TreeFilter from 'components/tree-filter/TreeFilter';
import {canEditProject} from 'scenes/tasks/Project.reducer';

export class ProjectTree extends Component {

    state = {
        projectDeleteCandidate: '',
    };

    createProject = (parentBusinessId, name) => {
        return this.props.createProject(parentBusinessId, name).then(() => this.props.listProjects());
    };

    editProject = (businessId, name) => {
        return this.props.editProject(businessId, name).then(() => this.props.listProjects());
    };

    deleteProject = () => {
        return this.props.deleteProject(this.state.projectDeleteCandidate)
            .then(() => {
                this.toggleDeleteProjectDialog(undefined);
                this.props.listProjects();
            });
    };

    toggleDeleteProjectDialog = (projectBusinessId) => {
        this.setState({
            projectDeleteCandidate: projectBusinessId
        })
    };

    toggleProjectTree = () => {
        if (this.props.isProjectTreeOpen) {
            this.props.closeProjectTree()
        } else {
            this.props.openProjectTree();
        }
    };

    render() {
        const {
            projectTree, toggleProjectFilterSelection, toggleProjectFilterOpen, isProjectTreeOpen,
            selectedProject, user, applicationOnline, forceOpen, containerStyle, style, isDroppable
        } = this.props;
        const {projectDeleteCandidate} = this.state;
        const {createProject, editProject, deleteProject, toggleDeleteProjectDialog, toggleProjectTree} = this;
        const canEditRoot = canEditProject(user, projectTree[0]);
        return (
            <Fragment>
                <TreeFilter
                    {...{
                        isDroppable,
                        containerStyle,
                        style,
                        open: isProjectTreeOpen,
                        forceOpen,
                        nodes: projectTree,
                        isAllSelected: false,
                        noFirstChildMargin: true,
                        toggleSelection: toggleProjectFilterSelection,
                        toggleOpen: toggleProjectFilterOpen,
                        toggleTree: toggleProjectTree,
                        editNode: editProject,
                        applicationOnline,
                        selectedNode: selectedProject,
                        showNodeTitle: true,
                        RootLeafComponent: function renderRootLeaf(props) {
                            return (
                                <ProjectRootLeaf
                                    {...{
                                        ...props,
                                        selectedProject,
                                        toggleOpen: toggleProjectFilterOpen,
                                        deleteSelection: toggleDeleteProjectDialog,
                                        addNode: createProject,
                                        addChildNode: createProject,
                                        canEditRoot,
                                        applicationOnline
                                    }}
                                />
                            )
                        }
                    }}
                />
                <ConfirmationDialog {...{
                    dialogOpen: !_.isEmpty(projectDeleteCandidate),
                    onDialogClose: toggleDeleteProjectDialog,
                    onConfirm: deleteProject,
                    confirmationTextKey: "task-management.deleteProjectConfirmation"
                }}/>
            </Fragment>
        );
    }
}

export default (connect(connector.mapStateToProps, connector.mapDispatchToProps)(ProjectTree));
