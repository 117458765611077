import * as actionTypes from "scenes/configuration/GeneralConfiguration.action.types";
import query from 'store/GraphqlQueries.js';

export function createAuditType(name) {
    return query.graphql({
        type: actionTypes.ADD_AUDIT_TYPE_DATA,
        caller: 'createAuditType',
        query: `
            mutation
            ($label: String!){
                createAuditType(label: $label) {
                    businessId
                    label
                }
            }
           `,
        variables: {
            label: name
        }
    });
}

export function removeAuditType(businessId, confirmed) {
    return query.graphql({
        type: actionTypes.REMOVE_AUDIT_TYPE_DATA,
        caller: 'removeAuditType',
        data: {
            businessId: businessId
        },
        query: `
            mutation
            ($businessId: String!, $force: Boolean){
                removeAuditType(businessId: $businessId, force: $force) {
                    success {
                        businessId
                    }
                    error {
                        auditType {
                            businessId
                            label
                        }
                        assignedAuditSchedules {
                            businessId
                            name
                        }
                    }
                }
            }
           `,
        variables: {
            businessId: businessId,
            force: confirmed || false
        }
    });
}

export function resetToBeConfirmedAuditSchedules() {
    return {type: actionTypes.RESET_TO_BE_CONFIRMED_AUDIT_SCHEDULES}
}