import React, {Component} from 'react';
import {Dialog, IconButton, Slide, withStyles,} from "@material-ui/core";
import _ from 'lodash';
import update from 'immutability-helper';
import AuditHeader from 'scenes/audit/component/audit-header/AuditHeader';
import {athensGrey, htmlBlack, htmlOrange, htmlRed, htmlWhite, jungleGreen, red,} from 'components/colors/Colors';
import Flex from 'components/grid/Flex';
import {connect} from 'react-redux';
import connector from './ExecuteAudit.connect';
import {Route} from 'react-router-dom';
import {routeTo, SCHEDULED_AUDITS} from 'routes/routes';
import Gallery from 'components/gallery/Gallery';
import {Trans, withTranslation} from 'react-i18next';
import fileUtils from "utils/File.utils";
import {CloseOutlined as CloseIcon} from '@material-ui/icons';
import SnackbarContent from "@material-ui/core/SnackbarContent";
import Snackbar from "@material-ui/core/Snackbar";
import configs from "configs/Configs";
import utils, {joinUrl} from 'utils/Utils';
import fileCache from 'components/file/File.cache';
import RegularAuditExecutor from './components/RegularAuditExecutor';
import VdaAuditExecutor from './components/VdaAuditExecutor';
import {isVdaAudit} from 'scenes/audit-manager/administration/questionnaire-edit/QuestionnaireEdit';
import {getColor} from 'components/colors/Result';

const styles = theme => ({
    paper: {
        paddingTop: 0,
        marginBottom: 10,
        height: '100%',
        borderRadius: 4,
    },
    executeAuditContainer: {
        minHeight: '80vh'
    },
    snackbar: {
        paddingBottom: 10,
    },
    snackbarContent: {
        backgroundColor: htmlRed,
        justifyContent: 'center'
    },
    snackbarText: {
        fontSize: 12,
        color: htmlWhite,
    }
});

function TransitionDown(props) {
    return <Slide {...props} direction="down"/>;
}

export class ExecuteAudit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentQuestionNumber: 0,
            areAllQuestionsAnswered: false,
            showTooltip: false,
            questions: _.cloneDeep(props.questions),
            maximumFileSizeLimitExceeded: false,
            view: 'QUESTION',
            intermediateResult: '',
            auditType: ''
        };
    }

    componentDidMount() {
        this.setState({
            intermediateResult: _.get(this.props, 'result.label'),
            auditType: _.get(this.props, 'audit.type')
        })
    }

    componentWillUnmount() {
        this.state.questions.forEach(question => {
            const attachments = _.get(question, 'answer.attachments', []);
            attachments.forEach(attachment => {
                if (attachment.offlineId && attachment.downloadUrl) {
                    URL.revokeObjectURL(attachment.downloadUrl);
                }
            });
        });
        this.props.fetchAudits();
    }

    handleAnswer = answer => () => {
        const {i18n} = this.props;
        this.setState(oldState => {
            return {
                questions: update(oldState.questions, {
                    [oldState.currentQuestionNumber]: {
                        answer: {
                            point: {$set: answer.point},
                            severity: {$set: this.getSeverity(answer)},
                            comment: {$set: answer.point === 0 ? oldState.questions[oldState.currentQuestionNumber].answer.comment : ''},
                            language: {$set: i18n.language}
                        }
                    }
                }),
                showTooltip: false
            };
        });
    };

    getSeverity = (answer) => {
        if (isVdaAudit(this.state.auditType)) {
            if (answer.point === 0 || answer.point === 4) {
                return 'CRITICAL';
            } else if (answer.point === 6) {
                return 'MINOR';
            } else {
                return '';
            }
        } else {
            return answer.point === 0 ? 'MINOR' : ''
        }
    }

    handleComment = event => {
        const targetValue = event.target.value;
        this.setState(oldState => {
            return {
                questions: update(oldState.questions, {
                    [oldState.currentQuestionNumber]: {
                        answer: {
                            comment: {$set: targetValue}
                        }
                    }
                }),
                showTooltip: false
            };
        });
    };

    handleSeverity = (event, checked) => {
        this.setState(oldState => {
            return {
                questions: update(oldState.questions, {
                    [oldState.currentQuestionNumber]: {
                        answer: {
                            severity: {$set: checked ? 'CRITICAL' : 'MINOR'}
                        }
                    }
                }),
                showTooltip: false
            };
        });
    };

    handleFileUpload = (file, referenceId) => {
        fileUtils.fixImageRotation(file).then((result) => {
            this.props.uploadFile(result, referenceId).then(response => {
                this.setState(oldState => {
                    return {
                        questions: update(oldState.questions, {
                            [oldState.currentQuestionNumber]: {
                                answer: {
                                    attachments: {
                                        $push: [{
                                            offlineId: _.get(response, 'payload.data.offlineId'),
                                            downloadUrl: _.get(response, 'payload.data.downloadUrl')
                                        }]
                                    }
                                }
                            }
                        })
                    };
                });
            })
        })
    };

    handleFile = event => {
        const file = event.target.files[0];
        if (file) {
            if (fileUtils.isValidFileSize(file)) {
                const currentReferenceId = this.getCurrentAnswerReferenceId();
                this.handleFileUpload(file, currentReferenceId);
            } else {
                this.setState({
                    maximumFileSizeLimitExceeded: true
                })
            }
        }
    };

    getCurrentAnswerReferenceId = () => {
        let referenceId = this.state.questions[this.state.currentQuestionNumber].answer.referenceId;
        if (!referenceId) {
            referenceId = this.generateAnswerReferenceId();
            this.setState(oldState => {
                return {
                    questions: update(oldState.questions, {
                        [oldState.currentQuestionNumber]: {
                            answer: {
                                referenceId: {$set: referenceId}
                            }
                        }
                    })
                };
            });
        }
        return referenceId;
    };

    generateAnswerReferenceId = () => {
        return 'TEMP-answer-' + utils.uuid();
    };

    findAttachmentIndex = (attachments, attachment) => {
        return attachments.findIndex(itemInArray => itemInArray.downloadUrl === attachment.downloadUrl || (attachment.offlineId && itemInArray.offlineId === attachment.offlineId));
    };

    deleteAttachment = attachment => () => {
        this.props.deleteFileOnUrl(attachment.downloadUrl, attachment.offlineId).then(() => {
            this.setState(oldState => {
                const index = this.findAttachmentIndex(oldState.questions[oldState.currentQuestionNumber].answer.attachments, attachment);
                const foundAttachment = oldState.questions[oldState.currentQuestionNumber].answer.attachments[index];
                if (foundAttachment.offlineId && foundAttachment.downloadUrl) {
                    URL.revokeObjectURL(foundAttachment.downloadUrl);
                }
                return {
                    questions: update(oldState.questions, {
                        [oldState.currentQuestionNumber]: {
                            answer: {
                                attachments: {$splice: [[index, 1]]}
                            }
                        }
                    })
                };
            });
        });
    };

    jumpToQuestion = questionId => {
        const newCurrentQuestionNumber = this.state.questions.findIndex(question => question.businessId === questionId);
        this.setState({
            currentQuestionNumber: newCurrentQuestionNumber,
            showTooltip: false,
            maximumFileSizeLimitExceeded: false
        });
    }

    previousQuestion = () => {
        const newCurrentQuestionNumber = this.state.currentQuestionNumber - 1;
        this.setState(oldState => {
            return {
                currentQuestionNumber: newCurrentQuestionNumber >= 0 ? newCurrentQuestionNumber : oldState.currentQuestionNumber,
                showTooltip: false,
                maximumFileSizeLimitExceeded: false
            };
        });
    };

    nextQuestion = () => {
        const newCurrentQuestionNumber = this.state.currentQuestionNumber + 1;
        if (newCurrentQuestionNumber === this.state.questions.length) {
            if (this.areQuestionsAnswered()) {
                this.props.finishAudit(this.state.questions, true);
            } else {
                this.setState({
                    showTooltip: true,
                    maximumFileSizeLimitExceeded: false
                })
            }
        } else {
            const currentQuestion = this.state.questions[this.state.currentQuestionNumber];
            if ((currentQuestion.answer.point === 0) && (currentQuestion.answer.comment === '')) {
                this.setState({
                    showTooltip: true,
                    maximumFileSizeLimitExceeded: false
                })
            } else {
                this.setState(oldState => {
                    return {
                        currentQuestionNumber: newCurrentQuestionNumber < oldState.questions.length ? newCurrentQuestionNumber : oldState.currentQuestionNumber,
                        showTooltip: false,
                        maximumFileSizeLimitExceeded: false
                    };
                });
            }
        }
    };

    save = (goBack) => {
        if (_.get(this.props, 'audit.status') !== 'CLOSED') {
            this.props.saveAudit(this.state.questions, this.props.audit.topics)
                .then(response => {
                    this.setState({
                        intermediateResult: _.get(response, 'payload.data.data.executeAudit.result.label') || _.get(response, 'payload.data.data.createAdHocAudit.result.label')
                    })
                })
                .catch(error => {
                    console.log(error);
                })
                .finally(() => {
                    if (goBack) {
                        this.props.history.push(SCHEDULED_AUDITS.path);
                    }
                });
        }
    };

    areQuestionsAnswered = () => {
        const noAnswer = question => (question.answer.point === '');
        const negativeAnswer = question => (question.answer.point === 0);
        const noComment = question => (question.answer.comment === '');
        const noSeverity = question => (question.answer.severity === '');
        const questionsWithoutAnswer = this.state.questions
            .filter(question => noAnswer(question) ||
                (
                    negativeAnswer(question) &&
                    (noComment(question) || noSeverity(question))
                )
            );
        return questionsWithoutAnswer.length === 0;
    };

    sumNegativeAnswers = () => {
        return _.filter(this.state.questions, question => (_.get(question, 'answer.point') === 0)).length;
    };

    handleSnackbarClose = () => {
        this.setState({
            maximumFileSizeLimitExceeded: true
        })
    };

    enrichAttachments = async attachment => {
        if (attachment.offlineId !== undefined && attachment.downloadUrl === undefined) {
            const file = await fileCache.getFile(attachment.offlineId);
            const url = URL.createObjectURL(file.file);
            return {
                ...attachment,
                downloadUrl: url
            }
        } else {
            return attachment;
        }
    };

    openGallery = () => {
        const currentQuestion = this.state.questions[this.state.currentQuestionNumber];
        const attachments = _.get(currentQuestion, 'answer.attachments', []);
        const sourcePromises = Promise.all(attachments.map(this.enrichAttachments));
        sourcePromises.then((resolvedAttachments) => {
            this.setState(oldState => {
                return {
                    questions: update(oldState.questions, {
                        [oldState.currentQuestionNumber]: {
                            answer: {
                                attachments: {$set: resolvedAttachments}
                            }
                        }
                    })
                };
            }, () => routeTo(joinUrl(this.props.match.url, '/gallery'), this.props));
        });
    };

    setView = view => {
        this.setState({
            view
        }, this.save)
    }

    render() {
        const {classes, match, readOnly, result, audit, exportExcelAndCloseAudit} = this.props;
        const {duration, title: questionnaireName, area: subtext, type} = audit;
        const {currentQuestionNumber, questions, showTooltip, view, intermediateResult} = this.state;
        const currentQuestion = questions[currentQuestionNumber];
        const {
            deleteAttachment, handleAnswer, handleComment, handleSeverity, sumNegativeAnswers, openGallery,
            previousQuestion, nextQuestion, areQuestionsAnswered, handleFile, jumpToQuestion, save, setView
        } = this;
        const color = getColor(_.get(result, 'label'));
        const numberOfNegativeAnswers = sumNegativeAnswers();
        const isQuestionAnswered = _.isNumber(_.get(currentQuestion, 'answer.point'));
        const backgroundColor = isQuestionAnswered
            ? currentQuestion.answer.point === 0
                ? currentQuestion.answer.severity === 'CRITICAL' ? red : htmlOrange
                : jungleGreen
            : athensGrey;
        const textColor = backgroundColor === athensGrey ? htmlBlack : htmlWhite;
        const jumpAwayDisabled = _.get(currentQuestion, 'answer.point') === 0 && _.isEmpty(_.get(currentQuestion, 'answer.comment'));
        const editDisabled = _.get(this.props, 'audit.status') === 'CLOSED';
        return (
            <Flex item container direction="column" className={classes.executeAuditContainer}>

                <AuditHeader
                    {...{
                        questionnaireName,
                        subtext,
                        color,
                        duration,
                        editDisabled,
                        isVdaAudit: isVdaAudit(type),
                        disabled: jumpAwayDisabled,
                        save,
                        jumpToListView: isVdaAudit(type)
                            ? () => {
                                setView('LIST')
                            }
                            : undefined
                    }}
                />
                {
                    isVdaAudit(type)
                        ? <VdaAuditExecutor {...{
                            editDisabled,
                            audit,
                            currentQuestionNumber,
                            questions,
                            currentQuestion,
                            view,
                            intermediateResult,
                            jumpToQuestion,
                            handleAnswer,
                            handleComment,
                            handleSeverity,
                            handleFile,
                            openGallery,
                            setView,
                            exportExcelAndCloseAudit
                        }}/>
                        : <RegularAuditExecutor {...{
                            currentQuestionNumber,
                            showTooltip,
                            questions,
                            numberOfNegativeAnswers,
                            readOnly,
                            backgroundColor,
                            textColor,
                            currentQuestion,
                            areQuestionsAnswered,
                            handleAnswer,
                            handleComment,
                            handleSeverity,
                            handleFile,
                            openGallery,
                            previousQuestion,
                            nextQuestion,
                        }}/>
                }

                <Snackbar
                    className={classes.snackbar}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    open={this.state.maximumFileSizeLimitExceeded}
                    TransitionComponent={TransitionDown}
                    onClose={this.handleSnackbarClose}
                >
                    <SnackbarContent
                        className={classes.snackbarContent}
                        classes={{
                            action: classes.action
                        }}
                        message={
                            <div>
                                <span className={classes.snackbarText}>
                                     <Trans i18nKey={"attachments.uploadWarning"} values={{
                                         limit: (configs.getMaxFileUploadSizeInBytes() / 1024 / 1024)
                                     }}/>
                                </span>
                            </div>
                        }
                        action={[
                            <IconButton style={{padding: 6}} key={'snackbarAction'}
                                        onClick={this.handleSnackbarClose}>
                                <CloseIcon style={{fontSize: 25, color: htmlWhite}}/>
                            </IconButton>
                        ]}
                    />
                </Snackbar>

                <Route path={match.url + '/gallery'} render={
                    function renderGallery(props) {
                        return <Dialog
                            fullScreen
                            open={true}
                        >
                            <Gallery
                                {...{
                                    ...props,
                                    sources: _.get(currentQuestion, 'answer.attachments', []),
                                    parentPath: match.url,
                                    readOnly,
                                    deleteSource: deleteAttachment
                                }}
                            />
                        </Dialog>
                    }
                }/>
            </Flex>
        );
    }
}

export default withStyles(styles)(connect(connector.mapStateToProps, connector.mapDispatchToProps)(withTranslation()(ExecuteAudit)));
