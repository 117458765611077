import React, {Component, Fragment} from 'react';
import {IconButton, withStyles} from "@material-ui/core";
import Flex from "components/grid/Flex";
import {Trans} from "react-i18next";
import {dustyGrey, christiGreen} from "components/colors/Colors";
import _ from "lodash";
import Typography from "@material-ui/core/Typography";
import ConfiguredBootstrapSelect from "components/configured-bootstrap-select/ConfiguredBootstrapSelect";
import BootstrapInput from "components/bootstrap-input/BootstrapInput";
import {ACTIVE} from "domain/audit/Schedule.model";
import {CheckCircle} from "@material-ui/icons";
import EvaluationMethodSelector from "./EvaluationMethodSelector";
import Tooltip from "@material-ui/core/Tooltip";
import {isExpired} from "domain/audit/Schedule.model";

const styles = theme => ({
    firstRow: {
        paddingLeft: 25,
        margin: '5px 20px 15px 20px'
    },
    inputItem: {
        padding: 7,
    },
    inputField: {
        padding: 0,
    },
    label: {
        color: dustyGrey,
        textTransform: 'uppercase',
        fontSize: 12,
    },
    secondRow: {
        paddingLeft: 25,
        margin: '-10px 20px 15px 20px'
    },
});

export class ScheduleDetailsSelectors extends Component {
    render() {
        const {
            classes, schedule, auditTypes, handleScheduleChange, questionnaires, auditResultDefinitions,
            handleActiveCheck, isScheduleUpdated, oneTimeOnly, isEditDisabled
        } = this.props;
        const isScheduleNotUpdatedAndExpired = !isScheduleUpdated() && isExpired(schedule);
        return (
            <Fragment>
                <Flex container justifyContent={'space-between'} alignItems={'center'} className={classes.firstRow}>
                    <div className={classes.inputItem} style={{width: '20%'}}>
                        <Typography className={classes.label}><Trans
                            i18nKey={"audit-administration.scheduleEdit.auditType"}/>
                        </Typography>
                        <ConfiguredBootstrapSelect
                            value={_.get(schedule, 'type', '')}
                            onChange={handleScheduleChange}
                            disabled={isEditDisabled}
                            name={'type'}
                            items={auditTypes.map(auditType => auditType.label)}
                        />
                    </div>
                    <div className={classes.inputItem} style={{width: '20%'}}>
                        <Typography className={classes.label}><Trans
                            i18nKey={"audit-administration.scheduleEdit.questionnaire"}/>
                        </Typography>
                        <ConfiguredBootstrapSelect
                            value={_.get(schedule, 'questionnaireName', '')}
                            onChange={handleScheduleChange}
                            disabled={isEditDisabled || _.isEmpty(questionnaires)}
                            name={'questionnaireName'}
                            items={questionnaires.map(q11 => q11.name)}
                        />
                    </div>

                    <Flex container item className={classes.inputItem} justifyContent={'flex-end'}>
                        {
                            !oneTimeOnly &&
                            <Tooltip
                                title={
                                    !schedule.haveQuestions ?
                                        <Trans i18nKey={"audit-administration.scheduleList.noQuestionsForSchedule"}/>
                                        :

                                        isScheduleNotUpdatedAndExpired ?
                                            <Trans i18nKey={"audit-administration.scheduleList.expiredSchedule"}/>
                                            :
                                            ""
                                }
                                placement={"left"}
                            >
                                <Flex container item={'0 0 0'} direction={'column'} alignItems={'center'}>
                                    <Typography className={classes.label}>
                                        <Trans i18nKey={"audit-administration.scheduleEdit.active"}/>
                                    </Typography>
                                    <IconButton
                                        onClick={handleActiveCheck}
                                        disabled={isScheduleNotUpdatedAndExpired || isEditDisabled || !schedule.haveQuestions}
                                        style={{padding: 2}}
                                    >
                                        <CheckCircle
                                            style={{
                                                width: '2rem',
                                                height: '2rem',
                                                padding: 0,
                                                color: schedule.status === ACTIVE && christiGreen
                                            }}/>
                                    </IconButton>
                                </Flex>
                            </Tooltip>
                        }
                    </Flex>
                </Flex>
                <Flex container justifyContent={'flex-start'} alignItems={'center'} className={classes.secondRow}>
                    <Flex item basis={'50%'}>
                        <div className={classes.inputItem} style={{width: '80%'}}>
                            <Typography className={classes.label}>
                                <Trans i18nKey={"audit-administration.scheduleEdit.name"}/>
                            </Typography>
                            <BootstrapInput className={classes.inputField}
                                            value={_.get(schedule, 'name', '')}
                                            onChange={handleScheduleChange}
                                            disabled={isEditDisabled}
                                            autoComplete={'off'}
                                            name={'name'}
                            />

                        </div>
                    </Flex>
                    <Flex item basis={'50%'}>
                        <div className={classes.inputItem} style={{width: 325}}>
                            <Typography className={classes.label}>
                                <Trans i18nKey={"audit-administration.scheduleEdit.evaluationMethod"}/>
                            </Typography>
                            <EvaluationMethodSelector className={classes.inputField}
                                                      value={_.get(schedule, 'resultDefinitionId', '')}
                                                      onChange={handleScheduleChange}
                                                      disabled={isEditDisabled}
                                                      items={auditResultDefinitions}
                                                      name={'resultDefinitionId'}
                            />
                        </div>
                    </Flex>
                </Flex>
            </Fragment>
        );
    }
}

export default withStyles(styles)(ScheduleDetailsSelectors);
