import {uploadFile, deleteFileOnUrl} from './Attachment.action';

export default {
    mapStateToProps: (state) => {
        return {
        };
    },
    mapDispatchToProps: (dispatch) => {
        return {
            uploadFile:(file, id, progressEvent, referenceType) =>
                dispatch(uploadFile(file, id, progressEvent, referenceType)),
            deleteFileOnUrl:(url) => dispatch(deleteFileOnUrl(url)),
        };
    }
};
