import React, {Component} from 'react';
import {withStyles, Typography, Paper} from "@material-ui/core";
import Flex from 'components/grid/Flex';
import {withTranslation} from "react-i18next";
import ExpandMore from '@material-ui/icons/ExpandMore';
import ExpandLess from '@material-ui/icons/ExpandLess';
import {htmlWhite, transparentBlack02, transparentBlack04, sideBarColor, moduleIconColor} from 'components/colors/Colors';

const styles = theme => ({
    container: {
        margin: 25,
        marginBottom: 50,
        width: '15%',
        minWidth: 190,
        maxWidth: 210,
    },
    iconContainer: {
        backgroundColor: sideBarColor,
        padding: 15
    },
    description: {
        fontSize: 13,
        padding: 15,
        paddingBottom: 5,
        textAlign: 'justify',
        userSelect: 'none',
        lineHeight: 'normal',
    },
    headerImage: {
        userSelect: 'none',
        width: 60
    },
    paperRoot: {
        width: '100%',
        boxShadow: `0px 0px 5px 1px ${transparentBlack02}`,
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
        textAlign: 'center',
        '&:hover': {
            boxShadow: `0px 0px 5px 1px ${transparentBlack04}`,
        }
    },
    title: {
        fontSize: 13,
        fontWeight: 'bold',
        display: 'inline',
        textTransform: 'uppercase',
        whiteSpace: 'nowrap'
    },
    circleContainer: {
        borderRadius: '50%',
        width: 24,
        height: 24,
        border: `2px solid ${htmlWhite}`,
        position: 'absolute',
        left: -4,
        right: 0,
        bottom: -10,
        margin: 'auto'
    },
    expandIcon: {
        color: htmlWhite,
        position: 'absolute',
        left: -2,
        right: 0,
        bottom: 0,
        top: 0,
        margin: 'auto',
        opacity: 0.7
    }
});

export class LandingTile extends Component {

    render() {
        const {
            classes, t: translate, title, description, headerImage, isOpen, canBeOpened, toggleOpen, toggleClose, id
        } = this.props;
        return (
            <Flex item={'0 0 auto'} className={classes.container} style={{minHeight: 230}}>
                <Paper classes={{root: classes.paperRoot}} onClick={isOpen ? toggleClose : toggleOpen} id={id}>
                    <Flex container justifyContent={'center'} alignItems={'center'} className={classes.iconContainer}>
                        <img className={classes.headerImage} src={headerImage} alt="headerImage"/>
                    </Flex>

                    <Flex container item direction={'column'}>
                        <Flex item={'0 0 auto'} style={{marginTop: 15}}>
                            <Typography className={classes.title} style={{color: moduleIconColor}}>{title}</Typography>
                            <Typography className={classes.title}> </Typography>
                            <Typography className={classes.title}>{translate("landing.management.title")}</Typography>
                        </Flex>
                        <Flex item={'0 0 auto'}>
                            <Typography className={classes.description}
                                        style={{minHeight: 130}}>{description}</Typography>
                        </Flex>
                        {
                            isOpen &&
                            <Flex container item={'1 0 100px'} direction={'column-reverse'}>
                                {this.props.children}
                            </Flex>
                        }
                    </Flex>
                    <div style={{height:16, backgroundColor: sideBarColor}}/>
                    {
                        canBeOpened &&
                        <div style={{backgroundColor: sideBarColor}} className={classes.circleContainer}>
                            {
                                isOpen
                                    ? <ExpandLess className={classes.expandIcon}/>
                                    : <ExpandMore className={classes.expandIcon}/>
                            }
                        </div>
                    }
                </Paper>

            </Flex>
        );
    }
}

export default withStyles(styles)(withTranslation()(LandingTile));
