import React, {Component} from 'react';
import {
    withStyles,
    Typography,
} from "@material-ui/core";
import Flex from 'components/grid/Flex';
import {Trans} from "react-i18next";
import {ACTIVE_VALUE, BACKLOG_VALUE, MY_ACTIONS_VALUE} from 'domain/task/Tasks.model';
import _ from 'lodash';
import {projectBreadCrumbs} from 'routes/routes';

const styles = theme => ({
    title: {
        fontWeight: 'bold',
        fontSize: 17
    }
});

export class MobileBreadcrumbHeader extends Component {
    render() {
        const {classes, breadCrumb, user, view} = this.props;
        const breadCrumbs = breadCrumb && user ? projectBreadCrumbs(_.cloneDeep(breadCrumb)) : [];
        const breadCrumbTail = breadCrumbs.pop() || '';
        const breadCrumbHead = breadCrumbs.join(' > ');
        return (
            breadCrumbTail &&
            <Flex container justifyContent={'center'} style={{position: 'absolute', top: 5, left: 0, right: 0}}
                  direction={'column'} alignItems={'center'}>
                <Flex item container>
                    {
                        view === BACKLOG_VALUE &&
                        <Typography className={classes.title}>
                            <Trans i18nKey={"task-management.tasks-backlog-tab-mobile"}/>
                        </Typography>
                    }
                    {
                        view === ACTIVE_VALUE &&
                        <Typography className={classes.title}>
                            <Trans i18nKey={"task-management.tasks-kanban-tab"}/>
                        </Typography>
                    }
                    {
                        view === MY_ACTIONS_VALUE &&
                        <Typography className={classes.title}>
                            <Trans i18nKey={"global.my-actions"}/>
                        </Typography>
                    }
                </Flex>
                {
                    view !== MY_ACTIONS_VALUE &&
                    <Flex item container>
                        {
                            !_.isEmpty(breadCrumbHead) &&
                            <Typography style={{fontSize: 13}}>{breadCrumbHead} > &nbsp;</Typography>
                        }
                        <Typography style={{fontWeight: 'bold', fontSize: 13}}>{breadCrumbTail}</Typography>
                    </Flex>
                }

            </Flex>
        );
    }
}

export default withStyles(styles)(MobileBreadcrumbHeader);
