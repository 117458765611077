import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import {Button, Typography, withStyles,} from '@material-ui/core';
import {
    AUDIT,
    SCHEDULED_AUDITS,
    AUDIT_MANAGEMENT,
    AUDIT_ADMINISTRATION,
    AUDIT_REPORTING,
    KPI,
    KPI_DOCUMENT_MACHINE_STATUS,
    TASK,
    TASK_BACKLOG,
    TASK_KANBAN,
    TASK_REPORTING
} from "routes/routes";
import AuditHeader from 'assets/img/audit-icon-hexa-backup.svg';
import TaskHeader from 'assets/img/task-icon-hexa.svg';
import KpiHeader from 'assets/img/kpi-icon-hexa.svg';
import QualityHead from 'assets/img/quality-icon-hexa.svg';
import KnowledgeHead from 'assets/img/knowledge-icon-hexa.svg';
import Flex from 'components/grid/Flex';
import LandingTile from 'components/landing-tile/LandingTile';
import ExpandMore from '@material-ui/icons/ExpandMore';
import {withTranslation} from 'react-i18next';
import ids from 'components/ids/ids';

const styles = theme => ({
    landingTileContainer: {
        flexWrap: 'wrap',
        width: '100%',
    },
    linkButton: {
        maxHeight: 40,
        paddingTop: 8,
        textTransform: 'none',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    svgIcon: {
        height: 25,
        width: 25,
    },
    rightArrow: {
        transform: 'rotate(270deg)'
    },
    buttonLabel: {
        fontWeight: 'bold',
        fontSize: 13,
    }
});

const LinkButton = (props) => {
    const {classes, disabled, to, Icon, label, id} = props;
    return (
        <Button fullWidth className={classes.linkButton} disabled={disabled} component={Link} to={to} id={id}
                style={{opacity: disabled ? 0.7 : 1}}>
            <Icon className={classes.svgIcon}/>
            <Typography className={classes.buttonLabel}>{label}</Typography>
            <ExpandMore className={classes.rightArrow}/>
        </Button>
    );
};

export class Landing extends Component {
    state = {
        tileOpen: ''
    };

    openTile = tile => () => {
        this.setState({
            tileOpen: tile
        });
    };

    closeTile = () => () => {
        this.setState({
            tileOpen: ""
        });
    };

    render() {
        const {classes, routes, t: translate} = this.props;
        const {tileOpen} = this.state;
        const allowedPaths = routes.map(route => route.path);

        const auditDescription = translate("landing.audit-management.description");
        const taskDescription = translate("landing.task-management.description");
        const kpiDescription = translate("landing.kpi-management.description");
        const qualityDescription = translate("landing.quality-management.description");
        const knowledgeDescription = translate("landing.knowledge-management.description");
        return (
            <Flex container justifyContent={'center'} className={classes.landingTileContainer}>
                <LandingTile
                    {...{
                        id: ids.landingAuditTile,
                        title: translate("landing.audit-management.title"),
                        description: auditDescription,
                        headerImage: AuditHeader,
                        isOpen: tileOpen === 'audit',
                        canBeOpened: true,
                        toggleOpen: this.openTile('audit'),
                        toggleClose: this.closeTile()
                    }}
                >
                    <LinkButton
                        classes={classes}
                        disabled={!allowedPaths.includes(AUDIT_MANAGEMENT.path)}
                        to={AUDIT_REPORTING.path}
                        Icon={AUDIT_REPORTING.icon}
                        label={translate(AUDIT_REPORTING.subtitle)}
                        id={ids.landingAuditReporting}
                    />
                    <LinkButton
                        classes={classes}
                        disabled={!allowedPaths.includes(AUDIT_MANAGEMENT.path)}
                        to={AUDIT_ADMINISTRATION.path}
                        Icon={AUDIT_ADMINISTRATION.icon}
                        label={translate(AUDIT_ADMINISTRATION.subtitle)}
                        id={ids.landingAuditAdministration}
                    />
                    <LinkButton
                        classes={classes}
                        disabled={!allowedPaths.includes(AUDIT.path)}
                        to={SCHEDULED_AUDITS.path}
                        Icon={SCHEDULED_AUDITS.icon}
                        label={translate("global.my-audits")}
                        id={ids.landingAuditMyAudits}
                    />
                </LandingTile>
                <LandingTile
                    {...{
                        id: ids.landingTaskTile,
                        title: translate("landing.task-management.title"),
                        description: taskDescription,
                        headerImage: TaskHeader,
                        isOpen: tileOpen === 'task',
                        canBeOpened: true,
                        toggleOpen: this.openTile('task'),
                        toggleClose: this.closeTile()
                    }}
                >
                    <LinkButton
                        classes={classes}
                        disabled={!allowedPaths.includes(TASK.path)}
                        to={TASK_REPORTING.path}
                        Icon={TASK_REPORTING.icon}
                        label={translate(TASK_REPORTING.subtitle)}
                        id={ids.landingTaskStatistics}
                    />
                    <LinkButton
                        classes={classes}
                        disabled={!allowedPaths.includes(TASK.path)}
                        to={TASK_BACKLOG.path}
                        Icon={TASK_BACKLOG.icon}
                        label={translate(TASK_BACKLOG.subtitle)}
                        id={ids.landingTaskStoryList}
                    />
                    <LinkButton
                        classes={classes}
                        disabled={!allowedPaths.includes(TASK.path)}
                        to={TASK_KANBAN.path}
                        Icon={TASK_KANBAN.icon}
                        label={translate(TASK_KANBAN.subtitle)}
                        id={ids.landingTaskStoryKanban}
                    />
                </LandingTile>
                <LandingTile
                    {...{
                        id: ids.landingKpiTile,
                        title: translate("landing.kpi-management.title"),
                        description: kpiDescription,
                        headerImage: KpiHeader,
                        isOpen: tileOpen === 'kpi',
                        canBeOpened: true,
                        imageModifierStyle: {
                            marginBottom: 11
                        },
                        toggleOpen: this.openTile('kpi'),
                        toggleClose: this.closeTile()
                    }}
                >
                    <LinkButton
                        classes={classes}
                        disabled={!allowedPaths.includes(KPI.path)}
                        to={KPI_DOCUMENT_MACHINE_STATUS.path}
                        Icon={KPI_DOCUMENT_MACHINE_STATUS.icon}
                        label={translate(KPI_DOCUMENT_MACHINE_STATUS.subtitle)}
                        id={ids.landingKpiMachineStatusButton}
                    />
                </LandingTile>
                <LandingTile
                    {...{
                        id: tileOpen,
                        title: translate("landing.quality-management.title"),
                        disabled: true,
                        description: qualityDescription,
                        headerImage: QualityHead,
                        canBeOpened: false,
                        toggleOpen: () => {}
                    }}
                >
                </LandingTile>
                <LandingTile
                    {...{
                        id: tileOpen,
                        title: translate("landing.knowledge-management.title"),
                        disabled: true,
                        description: knowledgeDescription,
                        headerImage: KnowledgeHead,
                        canBeOpened: false,
                        toggleOpen: () => {}
                    }}
                >
                </LandingTile>
            </Flex>
        );
    }
}

export default withStyles(styles)(withTranslation()(Landing));
