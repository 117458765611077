import React, {Component} from 'react';
import TaskUpdateEventBus from 'scenes/tasks/TaskUpdateEventBus';
import DesktopMyActionList from 'scenes/tasks/actions/my-action-list/components/DesktopMyActionList';
import MobileMyActionList from 'scenes/tasks/actions/my-action-list/components/mobile/MobileMyActionList';
import utils from 'utils/Utils';
import {connect} from 'react-redux';
import connector from './MyActionList.connect';

export class MyActionList extends Component {
    componentDidMount() {
        this.updateSubscription = TaskUpdateEventBus.update.subscribe(payload => {
            //TODO LM: fetchAction needs to be wired onto comments/ attachment updates the current create edit reducer action is not enough
            if (payload.type.includes('ACTION')) {
                this.props.fetchActionFromServer(payload.actionBusinessId);
            }
        });
    }

    componentWillUnmount() {
        TaskUpdateEventBus.update.unSubscribe(this.updateSubscription);
    }

    render() {
        const {handleTabChange, view, switchTabToStories} = this.props;
        const isMobile = utils.checkBrowser();
        return isMobile
            ? <MobileMyActionList/>
            : <DesktopMyActionList {...{view, handleTabChange, switchTabToStories}}/>;
    }
}

export default connect(connector.mapStateToProps, connector.mapDispatchToProps)(MyActionList);
