import _ from "lodash";
import * as actionTypes from "./OrganizationLeveLConfiguration.action.types";

const initialState = {
    organizationLevel: undefined,
    configuration: {
        users: [],
    }
};

export default function (state = initialState, action) {
    switch (action.type) {
        case actionTypes.FETCH_ORGANIZATION_LEVEL_SUCCESS: {
            const newState = _.cloneDeep(state);
            if (_.get(action, 'payload.data.data.fetchOrganizationLevel')) {
                newState.organizationLevel = action.payload.data.data.fetchOrganizationLevel
            }
            return newState;
        }
        case actionTypes.FETCH_USERS_SUCCESS: {
            const newState = _.cloneDeep(state);
            newState.configuration.users = action.payload.data.data.listUsers;
            return newState;
        }
        default:
            return state;
    }
}