import {
    toggleOnline
} from 'scenes/Main.action'

export default {
    mapStateToProps: state => {
        return {
            online: state.mainReducer.online,
        }
    },
    mapDispatchToProps: dispatch => {
        return {
            toggleOnline: () => dispatch(toggleOnline())
        }
    }
}
