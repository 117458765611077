import {fetchTaskManagementData, fetchTaskConfiguration} from './Tasks.action';
import {fetchTask} from 'scenes/tasks/task-edit/TaskEdit.action';
import {fetchActions} from 'scenes/tasks/actions/my-action-list/MyActionList.action';

export default {
    mapStateToProps: (state) => {
        return {
            isTaskManagementReady: state.tasksReducer.isTaskManagementReady,
            isTaskConfigurationReady: state.tasksReducer.isTaskConfigurationReady,
            isSelectedProjectLoaded: !!state.projectReducer.selectedProject.businessId,
        };
    },
    mapDispatchToProps: (dispatch) => {
        return {
            fetchTaskManagementData:() => dispatch(fetchTaskManagementData()),
            fetchTask: businessId => dispatch(fetchTask(businessId)),
            fetchTaskConfiguration: () => dispatch(fetchTaskConfiguration()),
            fetchActions: () => dispatch(fetchActions()),
        };
    }
};
