import React, {Component} from 'react';
import InlineComments from 'components/comment/InlineComments';
import {connect} from 'react-redux';
import connector from './DesktopTaskEditComments.connect';

export class DesktopTaskEditComments extends Component {

    componentDidMount() {
        if(this.props.onOpen){
            this.props.onOpen();
        }
    }

    addComment = comment => {
        this.props.addTaskComment(this.props.taskBusinessId, comment)
            .then(() => this.props.fetchTask(this.props.taskBusinessId));
    };

    deleteComment = commentId => () => {
        this.props.deleteTaskComment(commentId)
            .then(() => this.props.fetchTask(this.props.taskBusinessId));
    };

    render() {
        const {comments, user} = this.props;
        const {deleteComment, addComment} = this;

        return (
            <InlineComments {...{comments, user, deleteComment, addComment}}/>
        );
    }
}

export default connect(connector.mapStateToProps, connector.mapDispatchToProps)(DesktopTaskEditComments);
