export function isNewVersionAvailable(applicationVersion, suggestedApplicationVersion) {
    if (applicationVersion
        && suggestedApplicationVersion
        && notDevelopment(applicationVersion)) {
        return newMajorVersion(applicationVersion, suggestedApplicationVersion) ?
            true : newMinorVersion(applicationVersion, suggestedApplicationVersion) ?
                true : newPatchVersion(applicationVersion, suggestedApplicationVersion);
    }
}

function notDevelopment(applicationVersion) {
    return applicationVersion !== 'snapshot';
}

function newMajorVersion(applicationVersion, suggestedApplicationVersion) {
    return applicationVersion.split('.')[0] < suggestedApplicationVersion.split('.')[0];
}

function newMinorVersion(applicationVersion, suggestedApplicationVersion) {
    return applicationVersion.split('.')[1] < suggestedApplicationVersion.split('.')[1];
}

function newPatchVersion(applicationVersion, suggestedApplicationVersion) {
    return applicationVersion.split('.')[2] < suggestedApplicationVersion.split('.')[2];
}

const versionUtils = {
    isNewVersionAvailable
};

export default versionUtils;

