import * as actionTypes from "./GeneralConfiguration.action.types";
import query from 'store/GraphqlQueries.js';
import {AUDIT_CONFIG_GRAPHQL} from "domain/audit/AuditConfiguration.model";

export function fetchAuditManagementConfigurationData() {
    return query.graphql({
        type: actionTypes.FETCH_AUDIT_MANAGEMENT_CONFIGURATION_DATA,
        caller: 'fetchAuditManagementConfigurationData',
        query: `
            {
                fetchAuditManagementConfigurationData {
                    ${AUDIT_CONFIG_GRAPHQL}
                }
             }
           `,
    });
}
