import {uploadFile} from 'components/attachment/Attachment.action';

export default {
    mapStateToProps: (state) => {
        return {
            applicationOnline: state.mainReducer.online,
        };
    },
    mapDispatchToProps: (dispatch) => {
        return {
            uploadFile:(file, id, progressEvent, referenceType) =>
                dispatch(uploadFile(file, id, progressEvent, referenceType)),
        };
    }
};
