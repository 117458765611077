import * as actionTypes from './Checklist.action.types';
import {CHECKLIST_GRAPHQL, createChecklistItemInput, updateChecklistItemInput} from 'domain/task/Checklist.model'

export function createChecklistItem(entityId, text, languageCode) {
    return {
        type: actionTypes.ADD_CHECKLIST_ITEM,
        payload: {
            client: 'graphql',
            request: {
                method: 'post',
                data: {
                    query: `mutation createChecklistItem($createChecklistItemInput: CreateChecklistItemInput!){
    	                        createChecklistItem(createChecklistItemInput: $createChecklistItemInput){
      	                            ${CHECKLIST_GRAPHQL}
    	                        }
                            }
                           `,
                    variables: {
                        createChecklistItemInput: createChecklistItemInput(entityId, text, languageCode)
                    }
                }
            }
        }
    }
}

export function updateChecklistItem(checklistItem, isChecked, text, languageCode) {
    return {
        type: actionTypes.EDIT_CHECKLIST_ITEM,
        payload: {
            client: 'graphql',
            request: {
                method: 'post',
                data: {
                    query: `mutation updateChecklistItem($updateChecklistItemInput: UpdateChecklistItemInput!){
    	                        updateChecklistItem(updateChecklistItemInput: $updateChecklistItemInput){
      	                            ${CHECKLIST_GRAPHQL}
    	                        }
                            }
                           `,
                    variables: {
                        updateChecklistItemInput: updateChecklistItemInput(checklistItem, isChecked, text, languageCode)
                    }
                }
            }
        }
    }
}

export function deleteChecklistItem(checklistItemBusinessId) {
    return {
        type: actionTypes.DELETE_CHECKLIST_ITEM,
        payload: {
            client: 'graphql',
            request: {
                method: 'post',
                data: {
                    query: `mutation deleteChecklistItem($checklistItemId: String!){
    	                        deleteChecklistItem(checklistItemId: $checklistItemId)
                            }
                           `,
                    variables: {
                        checklistItemId: checklistItemBusinessId
                    }
                }
            }
        }
    }
}
