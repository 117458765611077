import * as actionTypes from './Schedules.action.types'
import {USER_GRAPHQL} from "domain/User.model";
import query from "../../../../store/GraphqlQueries";

export function fetchSchedules(levelBusinessId) {
    return {
        type: actionTypes.FETCH_SCHEDULES,
        payload: {
            client: 'graphql',
            request: {
                method: 'post',
                data: {
                    query: `                            
                                 query ($levelBusinessId: String!){
                                    listAuditSchedules(levelBusinessId: $levelBusinessId) {                               
                                        businessId
                                        name
                                        auditor {
                                             ${USER_GRAPHQL}
                                        }
                                        type {
                                            label
                                        }
                                        nextSchedule,
                                        lastOccurrence
                                        duration,
                                        reoccurance {
                                            unit
                                            amount
                                        }
                                        status
                                        questionnaire {
                                            numberOfRandomizedQuestions
                                            numberOfQuestions
                                            status
                                            name
                                        }
                                        shift {
                                            businessId
                                            label
                                            startTime
                                            endTime
                                            durationInHours
                                            ordinal
                                        }
                                    }
                                 }                             
                           `,
                    variables: {
                        levelBusinessId: levelBusinessId
                    }
                }
            }
        }
    };
}

export function fetchQuestionnaires(levelBusinessId) {
    return (dispatch) => {
        return dispatch(
            query.graphql({
                type: actionTypes.FETCH_QUESTIONNAIRES,
                caller: 'fetchQuestionnaires',
                query: `
                                query
                                ($levelId: String!){
                                    listQuestionnairesForOrganizationLevel(levelId: $levelId) {
                                        businessId                                                                                                                   
                                    }
                                }
                           `,
                variables: {
                    levelId: levelBusinessId
                }
            })
        );
    }
}

export function updateScheduleStatus(scheduleId, status) {
    return {
        type: actionTypes.UPDATE_SCHEDULE,
        payload: {
            client: 'graphql',
            request: {
                method: 'post',
                data: {
                    query: `mutation updateAuditScheduleStatus($auditScheduleId: String!, $status: String!){
    	                        updateAuditScheduleStatus(auditScheduleId: $auditScheduleId, status: $status)
                            }
                           `,
                    variables: {
                        auditScheduleId: scheduleId,
                        status

                    }

                }

            }

        }

    }

}

export function deleteSchedule(auditScheduleId) {
    return {
        type: actionTypes.DELETE_SCHEDULE,
        payload: {
            client: 'graphql',
            request: {
                method: 'post',
                data: {
                    query: `mutation removeAuditSchedule($auditScheduleId: String){
    	                        removeAuditSchedule(auditScheduleId: $auditScheduleId)
                            }
                           `,
                    variables: {
                        auditScheduleId
                    }
                }
            }
        }
    }
}
