import * as actionTypes from "./Socket.action.types";

const initialState = {
    connected: false,
    client: null,
};

export default function reducer(state = initialState, action = {}) {
    switch (action.type) {
        case actionTypes.SOCKET_CONNECTION_INIT:
            return {
                ...state,
                connected: false,
                client: action.client
            };
        case actionTypes.SOCKET_CONNECTION_SUCCESS:
            return {
                ...state,
                connected: true
            };

        case actionTypes.SOCKET_CONNECTION_ERROR:
            return {
                ...state,
                connected: false
            };
        case actionTypes.SOCKET_CONNECTION_CLOSED:
            return {
                ...state,
                connected: false

            };
        default:
            return state;
    }
}