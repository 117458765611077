export const CREATE_QUESTION = '@question_edit/CREATE_QUESTION';
export const CREATE_QUESTION_SUCCESS = '@question_edit/CREATE_QUESTION_SUCCESS';
export const CREATE_QUESTION_FAIL = '@question_edit/CREATE_QUESTION_FAIL';
export const EDIT_QUESTION = '@question_edit/EDIT_QUESTION';
export const EDIT_QUESTION_SUCCESS = '@question_edit/EDIT_QUESTION_SUCCESS';
export const EDIT_QUESTION_FAIL = '@question_edit/EDIT_QUESTION_FAIL';
export const FETCH_QUESTION = '@question_edit/FETCH_QUESTION';
export const FETCH_QUESTION_SUCCESS = '@question_edit/FETCH_QUESTION_SUCCESS';
export const FETCH_QUESTION_FAIL = '@question_edit/FETCH_QUESTION_FAIL';
export const FETCH_EMPTY_QUESTION = '@question_edit/FETCH_EMPTY_QUESTION';
