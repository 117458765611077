import {cacheAndLoad} from "cache/Cache.utils";
import {kpiMonitoringDatabase} from "scenes/kpi-monitoring/KpiMonitoring.database";
import _ from "lodash";

export function cacheUsers(originalReduxCall, cacheReduxCall) {
    return cacheAndLoad(kpiMonitoringDatabase, 'users', originalReduxCall, cacheReduxCall);
}

export function cacheMachineStatusConfigurationData(originalReduxCall, cacheReduxCall) {
    return cacheAndLoad(kpiMonitoringDatabase, 'machineStatusConfigurationData', originalReduxCall, cacheReduxCall);
}

export function cacheAllMachineDetails(machines) {
    machines.forEach(machine => {
        kpiMonitoringDatabase.machineDetails.put(machine)
            .then(() => {
                console.log("machineDetails", '[', kpiMonitoringDatabase.name, '][', kpiMonitoringDatabase.verno, '] cache updated');
            })
            .catch(error => {
                console.log('Cannot get cache from local database', error);
            });
    });
}

export function cacheMachineDetails(machine, originalReduxCall, cacheReduxCall) {
    return dispatch => {
        return dispatch(originalReduxCall)
            .catch(error => {
                console.log('Could not reach the server, serving machine from local cache. Error: ', error);
                return kpiMonitoringDatabase.machineDetails.get(machine.businessId)
                    .then(data => {
                        return dispatch(cacheReduxCall(data));
                    })
                    .catch(error => {
                        console.log('Cannot get cache from local database', error);
                    });
            });
    };
}

export function cacheOrganizationLevel(levelBusinessId, originalReduxCall, cacheReduxCall) {
    return dispatch => {
        return dispatch(originalReduxCall)
            .then(response => {
                const dataWrapper = _.get(response, 'payload.data.data');
                const actualPayload = dataWrapper[Object.keys(dataWrapper)[0]];
                return kpiMonitoringDatabase.organizationLevel.put(actualPayload)
                    .then(() => {
                        console.log("organizationLevel", '[', kpiMonitoringDatabase.name, '][', kpiMonitoringDatabase.verno, '] cache updated');
                        return response;
                    })
                    .catch(error => {
                        console.log('Could not write to the local cache. Error: ', error);
                    });
            })
            .catch(error => {
                console.log('Could not reach the server, serving organization Level  from local cache. Error: ', error);
                return kpiMonitoringDatabase.organizationLevel.get(levelBusinessId)
                    .then(backupOrganizationLevel => {
                        console.log('Successfully getting data from cache:', backupOrganizationLevel);

                        return kpiMonitoringDatabase.editedDetails
                            .toCollection()
                            .toArray().then(editedDetails => {
                                if (editedDetails) {
                                    editedDetails.forEach(editedDetail => {
                                        backupOrganizationLevel.view.childViews.forEach(childView => {
                                            if (editedDetail.machineId === childView.businessId) {
                                                childView.statusReport.lastKnownStatus = {
                                                    status: editedDetail.status,
                                                    reportedDate: editedDetail.reportedDate
                                                }

                                            }
                                        });
                                    });
                                    return dispatch(cacheReduxCall(backupOrganizationLevel));

                                } else {
                                    return dispatch(cacheReduxCall(backupOrganizationLevel));
                                }
                            });

                    })
                    .catch(error => {
                        console.log('Cannot get cache from local database', error);
                    })
            });
    };
}

export function cacheEditedDetails(details) {
    return (dispatch) => {
        return kpiMonitoringDatabase.editedDetails.put(details)
            .then(() => {
                console.log("editedDetails", '[', kpiMonitoringDatabase.name, '][', kpiMonitoringDatabase.verno, '] cache updated');
            })
            .catch(error => {
                console.log('Cannot get cache from local database', error);
            });
    }
}
