import React, {Component} from 'react';
import {connect} from 'react-redux';
import connector from './TaskList.connect';
import MobileTaskList from 'scenes/tasks/task-list/components/mobile/MobileTaskList';
import utils from 'utils/Utils';
import {TASK_BACKLOG} from 'routes/routes';
import {Redirect} from 'react-router-dom';

export class TaskList extends Component {

    render() {
        const {
            user, tasks, filters, applicationOnline, moveTaskToKanbanBoard, forceRerender, selectedProject,
            removeTaskFromKanbanBoard, fetchTaskConfiguration, createTask, archiveTask, configurationFromBackend,
            selectedUserNames, activeWorkflowStatuses, backlogWorkflowStatuses, startingWorkflowStatus,
            backlogWorkflowStatus, changeFilter, toggleProjectFilterSelectionByOrganizationLevelId
        } = this.props;
        const isMobile = utils.checkBrowser();
        return (
            isMobile
                ?
                <MobileTaskList {...{
                    tasks,
                    user,
                    applicationOnline,
                    filters,
                    fetchTaskConfiguration,
                    createTask,
                    moveTaskToKanbanBoard,
                    removeTaskFromKanbanBoard,
                    archiveTask,
                    configurationFromBackend,
                    forceRerender,
                    selectedProject,
                    selectedUserNames,
                    activeWorkflowStatuses,
                    backlogWorkflowStatuses,
                    startingWorkflowStatus,
                    backlogWorkflowStatus,
                    changeFilter,
                    toggleProjectFilterSelectionByOrganizationLevelId
                }}/>
                :
                <Redirect to={TASK_BACKLOG.path}/>
        );
    }
}

export default connect(connector.mapStateToProps, connector.mapDispatchToProps)(TaskList);
