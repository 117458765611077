import React, {Component} from "react";
import {IconButton, ClickAwayListener, withStyles} from "@material-ui/core";
import {withTranslation} from "react-i18next";
import DoneIcon from '@material-ui/icons/Done';
import CloseIcon from '@material-ui/icons/Close';
import {transparentWhite05, transparentWhite08} from 'components/colors/Colors';
import {Flex} from 'components/grid/Flex';

const styles = theme => ({
    iconButton: {
        backgroundColor: transparentWhite05,
        padding: 8
    },
    iconButtonRoot: {
        '&:hover': {
            backgroundColor: transparentWhite08,
        }
    }
});

class InlineConfirmation extends Component {

    state = {
        isVisible: false
    };

    close = () => {
        this.setState({isVisible: false});
        if (this.props.onCancel) {
            this.props.onCancel();
        }
    };

    confirm = () => {
        this.setState({isVisible: false});
        if (this.props.onConfirm) {
            this.props.onConfirm();
        }
    };

    open = () => {
        this.setState({isVisible: true});
    };

    render() {
        const {classes, children, style} = this.props;
        const {isVisible} = this.state;
        const {close, confirm, open} = this;
        return (
            isVisible ?
                <Flex item container>
                    <IconButton className={classes.iconButton} classes={{root: classes.iconButtonRoot}}>
                        <CloseIcon/>
                    </IconButton>
                    <ClickAwayListener onClickAway={close}>
                        <IconButton onClick={confirm} className={classes.iconButton}
                                    classes={{root: classes.iconButtonRoot}}>
                            <DoneIcon/>
                        </IconButton>
                    </ClickAwayListener>
                </Flex>
                :
                <div onClick={open} style={style}>
                    {children}
                </div>

        );
    }
}

export default withStyles(styles)(withTranslation()(InlineConfirmation));
