export const FETCH_SCHEDULES = '@schedules/FETCH_SCHEDULES';
export const FETCH_SCHEDULES_SUCCESS = '@schedules/FETCH_SCHEDULES_SUCCESS';
export const FETCH_SCHEDULES_FAIL = '@schedules/FETCH_SCHEDULES_FAIL';

export const DELETE_SCHEDULE = '@schedules/DELETE_SCHEDULE';
export const DELETE_SCHEDULE_SUCCESS = '@schedules/DELETE_SCHEDULE_SUCCESS';
export const DELETE_SCHEDULE_FAIL = '@schedules/DELETE_SCHEDULE_FAIL';

export const UPDATE_SCHEDULE = '@schedules/UPDATE_SCHEDULE';
export const UPDATE_SCHEDULE_SUCCESS = '@schedules/UPDATE_SCHEDULE_SUCCESS';
export const UPDATE_SCHEDULE_FAIL = '@schedules/UPDATE_SCHEDULE_FAIL';

export const FETCH_QUESTIONNAIRES = '@schedules/FETCH_QUESTIONNAIRES';
export const FETCH_QUESTIONNAIRES_SUCCESS = '@schedules/FETCH_QUESTIONNAIRES_SUCCESS';
export const FETCH_QUESTIONNAIRES_FAIL = '@schedules/FETCH_QUESTIONNAIRES_FAIL';

