import {
    findDeviationByAnswerId
} from './DeviationRedirect.action'

export default {
    mapStateToProps: (state) => {
        return {
        };
    },
    mapDispatchToProps: (dispatch) => {
        return {
            findDeviationByAnswerId: answerId => dispatch(findDeviationByAnswerId(answerId))
        };
    }
};
