export default {
    mapStateToProps: (state) => {
        return {
            websocketClient: state.socketReducer.client,
            websocketClientConnected: state.socketReducer.connected,
            user: state.mainReducer.user
        };
    },
    mapDispatchToProps: (dispatch) => {
        return {
        };
    }
};
