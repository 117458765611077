export const MANUAL = 'MANUAL';
export const GOOGLE_TRANSLATE = 'GOOGLE_TRANSLATE';
export const UNKNOWN = 'UNKNOWN';
export const NEW = 'NEW';

function shouldForceTranslateAutomatically(currentTranslations, language) {
    let result = true;
    currentTranslations.forEach(translation => {
       if(translation.language.code !== language) {
           if(translation.origin !== GOOGLE_TRANSLATE){
               result = false;
           }
       }
    });
    return result;
}

function calculateTranslationsChange(newEditorState, currentTranslations, originalTranslations, language) {
    const matchingTranslationIndex = currentTranslations
        .findIndex(translation => translation.language.code === language);
    const currentEditorState = currentTranslations[matchingTranslationIndex].description;
    let translations;
    let isContentDifferent;

    if (currentEditorState !== newEditorState) {
        isContentDifferent = true;
        translations = currentTranslations.map((translation, index) => {
            if (index === matchingTranslationIndex) {
                return {
                    ...translation,
                    description: newEditorState,
                    origin: NEW
                }
            } else {
                return originalTranslations[index]
            }
        });
    }

    return {
        translations: translations || [],
        isContentDifferent: isContentDifferent || false
    }
}

const editorUtils = {
    calculateTranslationsChange,
    shouldForceTranslateAutomatically
};

export default editorUtils;
