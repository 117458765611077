import React, {PureComponent} from "react";
import {Checkbox, Typography, withStyles} from "@material-ui/core";
import {Trans, withTranslation} from "react-i18next";
import Flex from "components/grid/Flex";
import {CheckBox as CheckBoxIcon} from '@material-ui/icons';
import BootstrapInput from "components/bootstrap-input/BootstrapInput";
import {christiGreen, auditPaperGrey, materialUIBorder, htmlBlack, htmlWhite} from "components/colors/Colors";
import classNames from 'classnames';
import _ from "lodash";
import ToggleAndPeakTooltip from 'components/toggle-and-peak-tooltip/ToggleAndPeakTooltip';
import Attachment from 'components/attachment/Attachment';
import Divider from "@material-ui/core/Divider";
import {htmlGrey} from "components/colors/Colors";
import ConfiguredBootstrapSelect from "components/configured-bootstrap-select/ConfiguredBootstrapSelect";
import {htmlLightGrey} from "components/colors/Colors";
import AnswerTypePreview from "./AnswerTypePreview";

const styles = theme => ({
    title: {
        paddingTop: 14,
        paddingBottom: 14,
        fontSize: 20,
        fontWeight: 'bold',
        color: htmlBlack
    },
    languageTitle: {
        fontSize: 18,
        fontWeight: 'bold',
        color: htmlBlack
    },
    tab: {
        marginRight: 10,
        borderColor: htmlBlack,
        borderStyle: 'solid',
        borderWidth: '1px 1px 0px 1px',
        fontWeight: 'bold',
        color: htmlBlack,
    },
    tabContainer: {
        backgroundColor: auditPaperGrey,
        border: `1px solid ${materialUIBorder}`,
        borderRadius: 4,
        borderTopLeftRadius: 0,
        padding: 30
    },
    approveTranslationCheckBoxRoot: {
        padding: 0,
        marginLeft: 25
    },
    tabText: {
        color: htmlGrey,
        fontSize: 12,
        fontWeight: 'bold'
    },
    tabCheckboxText: {
        marginLeft: 5,
    },
    tabTitleCheckboxText: {
        marginLeft: 5,
        marginTop: 5,
        color: htmlGrey
    },
    inputField: {
        padding: 0
    },
    checkBoxIcon: {
        fill: christiGreen
    },
    tabLeftColumn: {
        width: '70%',
        margin: 15,
    },
    basicContainer: {
        paddingTop: 25
    },
    tabRightColumn: {
        width: '30%',
    },
    tooltip: {
        fontSize: '0.85rem',
        marginBottom: 4,
        color: htmlWhite,
        backgroundColor: htmlBlack,
        '@media (min-width: 1100px)': {
            maxWidth: 400
        },
        '@media (min-width: 1300px)': {
            maxWidth: 600
        },
        '@media (min-width: 1600px)': {
            maxWidth: 800
        },
        '@media (min-width: 1900px)': {
            maxWidth: 1000
        }
    },
    divider: {
        marginTop: 25,
        marginBottom: 25,
        backgroundColor: htmlLightGrey
    },
    quickFixAddedCheckBoxRoot: {
        padding: 0,
        marginLeft: -3
    }
});

export class QuestionTab extends PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            showTooltip: '',
            peakTooltip: '',
            quickFixAdded: !_.isEmpty(props.selectedTranslation["quickFix"])
        };
    }

    toggleShowTooltip = target => () => {
        this.setState({
            showTooltip: target !== this.state.showTooltip ? target : ''
        })
    };

    togglePeakTooltip = target => () => {
        this.setState({
            peakTooltip: target !== this.state.peakTooltip ? target : ''
        })
    };

    toggleQuickFixAdding = () => {
        const actualState = !this.state.quickFixAdded;
        this.setState({
            quickFixAdded: actualState
        });

        if (!actualState) {
            this.props.clearTranslation('quickFix')
        }
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        const isLanguageChanged = this.props.selectedTranslation.language.code !== prevProps.selectedTranslation.language.code;
        if (isLanguageChanged) {
            this.setState({
                quickFixAdded: !_.isEmpty(this.props.selectedTranslation["quickFix"])
            });
        }
    }

    render() {
        const {
            classes, selectedTranslation, handleTranslationChange, onSuccessfulFileUpload,
            toggleTranslationApproval, deletePicture, masterTranslation, answerTypes, handleAnswerTypeChange, question
        } = this.props;
        const {showTooltip, peakTooltip, quickFixAdded} = this.state;
        const {toggleShowTooltip, togglePeakTooltip, toggleQuickFixAdding} = this;
        const isMasterTranslationOpen = selectedTranslation.language.code === masterTranslation.language.code;
        const pictureUrl = _.get(selectedTranslation, 'pictureDownloadUrl');
        return (
            <Flex item container className={classes.tabContainer}>
                <Flex item container direction={'column'} className={classes.tabLeftColumn}>
                    <Flex container item={'0 0 0'}>
                        <Typography className={classes.languageTitle}>
                            <Trans i18nKey={"global.languages." + selectedTranslation.language.code + ".label"}/>
                        </Typography>
                        <Flex container item>
                            <Checkbox
                                checked={selectedTranslation.status === 'APPROVED'}
                                onChange={toggleTranslationApproval}
                                classes={{root: classes.approveTranslationCheckBoxRoot}}
                                checkedIcon={<CheckBoxIcon className={classes.checkBoxIcon}/>}
                            />
                            <Typography className={classNames(classes.tabText, classes.tabTitleCheckboxText)}>
                                <Trans i18nKey={"audit-administration.questionEdit.approveTranslation"}/>
                            </Typography>
                        </Flex>
                    </Flex>

                    <Flex container>
                        <Flex item={'1 1 0'} direction={'column'} style={{
                            paddingTop: 15,
                            paddingRight: 10
                        }}>
                            <Flex item container alignItems={'center'}>
                                <Typography className={classes.tabText}>
                                    <Trans i18nKey={"audit-administration.questionEdit.question"}/>
                                </Typography>
                                <ToggleAndPeakTooltip
                                    {...{
                                        classes,
                                        title: masterTranslation.question,
                                        show: !isMasterTranslationOpen,
                                        name: 'question',
                                        showTooltip,
                                        peakTooltip,
                                        toggleShowTooltip,
                                        togglePeakTooltip
                                    }}
                                />
                            </Flex>
                            <BootstrapInput
                                rows="4"
                                multiline={true}
                                className={classes.inputField}
                                value={selectedTranslation.question}
                                onChange={handleTranslationChange}
                                autoComplete={'off'}
                                name={'question'}
                            />
                        </Flex>
                        <Flex item={'1 1 0'} direction={'column'}  style={{
                            paddingLeft: 10,
                            paddingTop: 15
                        }}>
                            <Flex item container alignItems={'center'}>
                                <Typography className={classes.tabText}>
                                    <Trans i18nKey={"audit-administration.questionEdit.comment"}/>
                                </Typography>
                                <ToggleAndPeakTooltip
                                    {...{
                                        classes,
                                        title: masterTranslation.comment,
                                        show: !isMasterTranslationOpen,
                                        name: 'comment',
                                        showTooltip,
                                        peakTooltip,
                                        toggleShowTooltip,
                                        togglePeakTooltip
                                    }}
                                />
                            </Flex>
                            <BootstrapInput
                                rows="4"
                                multiline={true}
                                className={classes.inputField}
                                value={selectedTranslation.comment}
                                onChange={handleTranslationChange}
                                autoComplete={'off'}
                                name={'comment'}
                            />
                        </Flex>
                    </Flex>
                    <Flex item={'0 0 0'} container direction={'column'}
                          style={{
                              paddingTop: 25,
                              width: "25%"
                          }}>
                        <Typography className={classNames(classes.tabText, classes.tabCheckboxText)}>
                            <Trans i18nKey={"audit-administration.questionEdit.ratingType"}/>
                        </Typography>
                        <ConfiguredBootstrapSelect
                            value={_.get(question, 'answerType.label', '')}
                            translationPath={"audit-administration.answerType"}
                            onChange={handleAnswerTypeChange}
                            name={'answerType'}
                            items={answerTypes}
                        />
                    </Flex>
                    {
                        _.get(question, "answerType.label") &&
                            <AnswerTypePreview
                                {...{
                                    answerType: question.answerType.label
                                }}
                            />
                    }
                    <Divider classes={{
                        root: classes.divider
                    }}/>
                    <Flex container item={'0 0 0'} alignItems={'center'}>
                        <Checkbox
                            classes={{root: classes.quickFixAddedCheckBoxRoot}}
                            checked={quickFixAdded}
                            onChange={toggleQuickFixAdding}
                            checkedIcon={<CheckBoxIcon className={classes.checkBoxIcon}/>}
                        />
                        <Typography className={classNames(classes.tabText, classes.tabCheckboxText)}>
                            <Trans i18nKey={"audit-administration.questionEdit.quickFixInCaseOfFailure"}/>
                        </Typography>
                        <Flex item container alignItems={'center'}>
                            <ToggleAndPeakTooltip
                                {...{
                                    classes,
                                    title: masterTranslation.quickFix,
                                    show: !isMasterTranslationOpen,
                                    name: 'quickFix',
                                    showTooltip,
                                    peakTooltip,
                                    toggleShowTooltip,
                                    togglePeakTooltip
                                }}
                            />
                        </Flex>
                    </Flex>
                    {
                        quickFixAdded &&
                            <BootstrapInput
                                rows="2"
                                multiline={true}
                                className={classes.inputField}
                                value={selectedTranslation.quickFix}
                                onChange={handleTranslationChange}
                                autoComplete={'off'}
                                name={'quickFix'}
                            />
                    }
                </Flex>
                <Flex item direction={'column'} justifyContent={'center'} container className={classes.tabRightColumn}>
                    <Attachment
                        {...{
                            attachmentType: 'SINGLE_IMAGE',
                            pictureUrl: pictureUrl || masterTranslation.pictureDownloadUrl,
                            isDeletePossible: !!pictureUrl,
                            deletePicture,
                            referenceType: 'QUESTION',
                            isMobile: false,
                            onSuccessfulFileUpload,
                            uploadEnabled: true
                        }}
                    />
                </Flex>
            </Flex>
        );
    }
}

export default withStyles(styles)(withTranslation()(QuestionTab));
