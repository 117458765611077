export const actionDescription = 0;
export const header = 3;
export const mobileTaskListMenu = 4;
export const headerIcon = 5;
export const documentMachineStatus = 1;
export const taskEditDescription = 0;
export const taskEditTagSuggestions = 1;
export const profileContainer = 6;
export const questionnaireHeaderIndex = 1;
export const auditReportingMap = 1;
export const selectMenu = 2;
export const sideBarIndex = 7;
export const treeIndex = 8;
export const headerTitles = -1;
export const content = 0;
export const contextMenu = 9;

