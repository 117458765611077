import React, { Component } from 'react';
import { withStyles, Divider, Typography } from "@material-ui/core";
import Flex from 'components/grid/Flex';
import {Trans, withTranslation} from "react-i18next";
import QuestionnaireSelectors from "./QuestionnaireSelectors";
import {
    htmlBlack,
} from "components/colors/Colors";
import _ from 'lodash';

const styles = theme => ({
    title: {
        fontSize: 26,
        fontWeight: 'bold',
        color: htmlBlack
    },
});

export class QuestionnaireHeader extends Component {
    render() {
        const {
            classes, questionnaire, cycles, auditTypes, handleQuestionnaireChange, handleActiveCheck,
            handleAuditTypeChange, isVdaAudit,selectedQuestions
        } = this.props;
        const existingQuestionnaire = _.get(questionnaire, 'businessId');
        return (
            <Flex container item={'0 0 0'} direction={"column"}>
                <Typography className={classes.title}>
                    {
                        existingQuestionnaire ?
                            <Trans i18nKey={"audit-administration.questionnaireEdit.editQuestionnaire"}/>
                            :
                            <Trans i18nKey={"audit-administration.questionnaireEdit.createNewQuestionnaire"}/>
                    }
                </Typography>
                <QuestionnaireSelectors {...{
                    isVdaAudit,
                    questionnaire,
                    cycles,
                    selectedQuestions,
                    handleQuestionnaireChange,
                    handleAuditTypeChange,
                    handleActiveCheck,
                    auditTypes
                }}/>
                <Divider/>
            </Flex>
        )
    }
}

export default withStyles(styles)(withTranslation()(QuestionnaireHeader));
