import React, {Component, Fragment} from 'react';
import {Route, Switch} from 'react-router';
import {connect} from 'react-redux';
import Login from 'scenes/login/Login';
import connector from './Main.connector';
import utils from 'utils/Utils';
import SidebarLayout from "components/sidebar-layout/SidebarLayout";
import HeaderLayout from "components/header-layout/HeaderLayout";
import LandingHeaderLayout from "components/header-layout/LandingHeaderLayout";
import {MOBILE_LANDING as MobileLanding, LANDING} from 'routes/routes';
import IdleTimer from 'react-idle-timer'
import {isNewVersionAvailable} from "utils/Version.utils";
import SynchronizationOverlay from './SynchronizationOverlay';
import {Helmet} from "react-helmet";
import FeatureSwitch from 'utils/FeatureSwitch';
import Logout from 'components/logout/Logout';
import ConnectionLostMessage from "components/snack-messages/ConnectionLostMessage";
import ConnectionBackMessage from "components/snack-messages/ConnectionBackMessage";
import VersionMessage from "components/snack-messages/VersionMessage";
import WifiOffIcon from '@material-ui/icons/WifiOff';
import {htmlWhite, crimsonRed} from 'components/colors/Colors';
import {
    withStyles,
    IconButton
} from "@material-ui/core";
import Unsubscribe from "./unsubscribe/Unsubscribe";
import GeneralOkMessage from "components/snack-messages/GeneralOkMessage";
import SynchronizationEventBus from "./SynchronizationEventBus";

const styles = theme => ({
    wifiIconButton: {
        position: 'absolute',
        bottom: 30,
        left: 30,
        backgroundColor: crimsonRed,
        opacity: 0.2,
    },
    wifiIcon: {
        width: 40,
        height: 40,
        fill: htmlWhite,
    },
});

export class Main extends Component {

    constructor(props) {
        super(props);
        this.idleTimer = null;
        this.state = {
            versionInfoSnackbarOpen: false,
            versionInfoSnackbarShowed: false,
            connectionLostSnackbarShowed: false,
            connectionBackSnackbarShowed: false,
            subscribedToWebSocket: false,
            showSynchronizationSuccessful: false
        }
    }

    componentDidMount() {
        if (FeatureSwitch.websocket.active) {
            this.props.initializeWebsocket();
        }
        this.props.loadAccessToken();
        this.subscribeToWebsocketTopics();
        if (FeatureSwitch.automaticOfflineSwitch.active) {
            this.props.checkServerConnection();
        }
        this.successSynchronizationSubscription = SynchronizationEventBus.update.subscribe(() => this.showSynchronizationSuccessfulMessage());
    }

    componentWillUnmount() {
        this.unsubscribeFromWebsocketTopics();
        SynchronizationEventBus.update.unSubscribe(this.successSynchronizationSubscription)
    }

    subscribeToWebsocketTopics() {
        if (!this.state.subscribedToWebSocket && this.props.websocketClientConnected) {
            if (FeatureSwitch.versionNotification.active) {
                this.setState({subscribedToWebSocket: true});
                this.props.websocketClient.subscribe(FeatureSwitch.versionNotification.websocketTopic, (fragment) => {
                    this.props.updateApplicationInformation(JSON.parse(fragment.body));
                });
            }
        }
    }

    unsubscribeFromWebsocketTopics() {
        if (this.state.subscribedToWebSocket && this.props.websocketClientConnected) {
            if (FeatureSwitch.versionNotification.active) {
                this.setState({subscribedToWebSocket: false});
                this.props.websocketClient.unsubscribe(FeatureSwitch.versionNotification.websocketTopic);
            }
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {suggestedApplicationVersion, authenticated, online, synchronize} = this.props;
        const applicationVersion = process.env.REACT_APP_VERSION;
        if (authenticated && !prevProps.online && online) {
            synchronize();
        }

        if (!this.state.versionInfoSnackbarShowed
            && authenticated
            && isNewVersionAvailable(applicationVersion, suggestedApplicationVersion)) {
            this.setState({versionInfoSnackbarOpen: true});
            this.setState({versionInfoSnackbarShowed: true});
        }

        if (prevProps.authenticated && !authenticated) {
            this.setState({versionInfoSnackbarOpen: false});
            this.setState({versionInfoSnackbarShowed: false});
            this.setState({connectionSnackbarShowed: false});
        }

        if (prevProps.online !== online) {
            this.setState({connectionLostSnackbarShowed: false});
            this.setState({connectionBackSnackbarShowed: false});
        }
        if (prevProps.websocketClientConnected && !this.props.websocketClientConnected) {
            this.setState({subscribedToWebSocket: false});
        }
        if (!prevProps.websocketClientConnected && this.props.websocketClientConnected) {
            this.subscribeToWebsocketTopics();
        }
    }

    LoginPage = () => {
        return <HeaderLayout {...{showHeader: true}}>
            <Login actions={{login: this.props.login}}/>
        </HeaderLayout>
    };

    UnsubscribePage = () => {
        return <HeaderLayout {...{showHeader: true}}>
            <Unsubscribe {...{unsubscribe: this.props.unsubscribe, result: this.props.unsubscriptionResult}}/>
        </HeaderLayout>
    };

    LandingPageRoute = () => {
        const isMobile = utils.checkBrowser();
        const {routesAllowedByUser} = this.props;
        return isMobile
            ?
            <Route path={MobileLanding.path}
                   exact
                   render={
                       function LandingRender(props) {
                           return <HeaderLayout
                               {...{
                                   showHeader: true,
                               }}
                           >
                               <MobileLanding.component {...props}/>
                           </HeaderLayout>
                       }
                   }
            />
            :
            <Route path={LANDING.path}
                   exact
                   render={
                       function LandingRender(props) {
                           return <LandingHeaderLayout
                               {...{
                                   showHeader: true
                               }}
                           >
                               <LANDING.component routes={routesAllowedByUser} {...props}/>
                           </LandingHeaderLayout>
                       }
                   }
            />
    };

    componentRoutes = () => {
        const isMobile = utils.checkBrowser();
        const {routesAllowedByUser, online} = this.props;
        return isMobile
            ?
            routesAllowedByUser.map((route, key) =>
                <Route path={route.path}
                       key={key}
                       render={
                           function mobileComponentRender(props) {
                               return <HeaderLayout
                                   {...{
                                       showHeader: true,
                                   }}
                               >
                                   <route.component {...props}
                                                    isMobile={isMobile}
                                                    data={{applicationOnline: online}}
                                   />
                               </HeaderLayout>
                           }
                       }
                />
            )
            :
            routesAllowedByUser.map((route, key) =>
                <Route path={route.path}
                       key={key}
                       render={
                           function desktopComponentRender(props) {
                               return <SidebarLayout>
                                   <route.component
                                       routes={routesAllowedByUser}
                                       {...props}
                                       isMobile={isMobile}
                                       data={{
                                           applicationOnline: online
                                       }}
                                   />
                               </SidebarLayout>
                           }
                       }
                />
            )
    };

    onUserAction = () => {
        const {logout} = this.props;
        const {idleTimer} = this;
        const idleInBackground = Date.now() - idleTimer.getLastActiveTime();
        if (idleInBackground > FeatureSwitch.idleLogout.time) {
            logout();
        }
    };

    handleVersionInfoSnackbarClose = () => {
        this.setState({
            versionInfoSnackbarOpen: false,
            versionInfoSnackbarShowed: true
        }, () => {
            utils.refreshApplication();
        });
    };

    handleConnectionLostSnackbarClose = () => {
        this.setState({
            connectionLostSnackbarShowed: true,
        });
    };

    handleConnectionBackSnackbarClose = () => {
        this.setState({
            connectionBackSnackbarShowed: true,
        });
    };

    showSynchronizationSuccessfulMessage = () => {
        this.setState({
            showSynchronizationSuccessful: true,
        });
    };

    hideSynchronizationSuccessfulMessage = () => {
        this.setState({
            showSynchronizationSuccessful: false,
        });
    };


    render() {
        const {classes, user, logout, isOverlayActive, stableServerConnection, online, manualOffline, toggleOnline} = this.props;
        const {LandingPageRoute, LoginPage, componentRoutes, onUserAction, UnsubscribePage} = this;
        const {connectionLostSnackbarShowed, connectionBackSnackbarShowed, showSynchronizationSuccessful} = this.state;
        const isMobile = utils.checkBrowser();
        return (
            <Fragment>
                <Helmet>
                    <title>{window.appConfig.environment}</title>
                </Helmet>
                {
                    FeatureSwitch.idleLogout.active &&
                    <IdleTimer
                        ref={ref => {
                            this.idleTimer = ref
                        }}
                        timeout={FeatureSwitch.idleLogout.time}
                        onIdle={logout}
                        onAction={onUserAction}
                    />
                }
                <Switch>
                    <Route path={'/unsubscribe/:id'} component={UnsubscribePage}/>
                    {user && componentRoutes()}
                    {user && <LandingPageRoute/>}
                    <Route path={'/logout'} component={Logout}/>
                    <Route component={LoginPage}/>
                </Switch>
                {
                    FeatureSwitch.versionNotification.active &&
                    <VersionMessage {...{
                        open: this.state.versionInfoSnackbarOpen,
                        onClose: this.handleReadOnlyDialogClose,
                        onClick: this.handleVersionInfoSnackbarClose
                    }}/>
                }
                {
                    FeatureSwitch.automaticOfflineSwitch.active &&
                    <ConnectionLostMessage {...{
                        open: !connectionLostSnackbarShowed && !stableServerConnection && !manualOffline,
                        onClose: this.handleConnectionLostSnackbarClose
                    }}/>
                }
                {
                    FeatureSwitch.automaticOfflineSwitch.active &&
                    <ConnectionBackMessage {...{
                        open: !connectionBackSnackbarShowed && !online && stableServerConnection,
                        toggleOnline,
                        onClose: this.handleConnectionBackSnackbarClose
                    }}/>
                }
                {
                    !online && isMobile &&
                    <IconButton item container justifyContent={'center'} alignItems={'center'}
                                className={classes.wifiIconButton}
                                style={{backgroundColor: crimsonRed}}
                                onClick={toggleOnline}
                    >
                        <WifiOffIcon className={classes.wifiIcon}/>
                        <div/>
                    </IconButton>
                }
                <SynchronizationOverlay {...{isOverlayActive}}/>
                <GeneralOkMessage {...{
                    open: !isOverlayActive && showSynchronizationSuccessful,
                    onClose: this.hideSynchronizationSuccessfulMessage,
                    textTranslationKey: 'global.synchronization-successful'
                }}/>
            </Fragment>
        )
    }
}

export default withStyles(styles)(connect(connector.mapStateToProps, connector.mapDispatchToProps)(Main));
