import {
    moveTaskToKanbanBoard,
    removeTaskFromKanbanBoard,
    archiveTask
} from 'scenes/tasks/Tasks.action'
import {
    createTask
} from "scenes/tasks/task-edit/TaskEdit.action";
import {changeFilter} from 'scenes/tasks/task-list/TaskList.action';
import {
    toggleProjectFilterSelectionByOrganizationLevelId
} from 'scenes/tasks/Project.action'

export default {
    mapStateToProps: (state) => {
        return {
            //TODO handle backlog and active tasks splitted, see MobileTaskList.jsx viewFilter function
            tasks: state.tasksReducer.activeTasks.concat(state.tasksReducer.backlogTasks),
            selectedProject: state.projectReducer.selectedProject,
            applicationOnline: state.mainReducer.online,
            user: state.mainReducer.user,
            filters: state.taskListReducer.filters,
            configurationFromBackend: state.tasksReducer.configurationFromBackend,
            startingWorkflowStatus: state.tasksReducer.configuration.startingWorkflowStatus,
            backlogWorkflowStatus: state.tasksReducer.configuration.backlogWorkflowStatus,
            activeWorkflowStatuses: state.tasksReducer.configuration.activeWorkflowStatuses,
            backlogWorkflowStatuses: state.tasksReducer.configuration.backlogWorkflowStatuses,
            forceRerender: state.tasksReducer.forceRerender,
            selectedUserNames: state.projectReducer.selectedUserNames,
        };
    },
    mapDispatchToProps: (dispatch) => {
        return {
            moveTaskToKanbanBoard: task => dispatch(moveTaskToKanbanBoard(task)),
            removeTaskFromKanbanBoard: task => dispatch(removeTaskFromKanbanBoard(task)),
            archiveTask: task => dispatch(archiveTask(task)),
            createTask: task => dispatch(createTask(task)),
            changeFilter: filter => dispatch(changeFilter(filter)),
            toggleProjectFilterSelectionByOrganizationLevelId: businessId => dispatch(toggleProjectFilterSelectionByOrganizationLevelId(businessId)),
        };
    }
};
