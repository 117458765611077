import * as actionTypes from './TaskEdit.action.types'
import utils from 'utils/Utils';
import taskCache from "scenes/tasks/Tasks.cache";
import _ from 'lodash';
import {validateApplicationStatusForCache} from "cache/Cache.utils";
import {
    EDIT_TASK_GRAPHQL,
    editTaskInput,
    createTaskInput,
    offlineTask,
    mapSyncTaskInput
} from 'domain/task/Tasks.model';
import TaskUpdateEventBus from 'scenes/tasks/TaskUpdateEventBus';
import query from 'store/GraphqlQueries.js';
import {TASK_EDIT_RETURN_PATHS} from 'routes/routes';

export function fetchTask(taskId) {
    return taskCache.getTaskFromServerOrCache(taskId, fetchTaskFromServer(taskId), fetchTaskFromCache);
}

export function fetchTaskFromCache(task) {
    return (dispatch, getState) => {
        return dispatch({
            type: actionTypes.FETCH_TASK_FROM_CACHE,
            data: {
                cache: {
                    task
                },
                supportedLanguages: getState().tasksReducer.configurationFromBackend.supportedLanguages
            }
        });
    }
}

export function fetchTaskFromServer(taskId) {
    return (dispatch, getState) => {
        return dispatch(
            query.graphql({
                type: actionTypes.FETCH_TASK,
                data: {
                    supportedLanguages: getState().tasksReducer.configurationFromBackend.supportedLanguages
                },
                caller: 'fetchTaskFromServer',
                query: `
                        query
                        ($taskId: String!){
                            fetchTask(taskId: $taskId) {
                                ${EDIT_TASK_GRAPHQL}
                            }
                        }
                       `,
                variables: {
                    taskId
                }
            }))
            .then(response => {
                updateTaskInCache(response);
                return response;
            })
    }
}

export function updateTaskInCache(response) {
    const dataWrapper = _.get(response, 'payload.data.data');
    const actualPayload = dataWrapper[Object.keys(dataWrapper)[0]];
    return taskCache.updateOrCreateTaskInCache(actualPayload);
}

export function createTask(task) {
    return (dispatch, getState) => {
        return dispatch(
            query.graphql({
                type: actionTypes.CREATE_TASK,
                data: {
                    taskBusinessId: task.businessId
                },
                caller: 'createTask',
                query: `mutation createTask($createTaskInput: CreateTaskInput){
    	                    createTask(createTaskInput: $createTaskInput){
      	                        ${EDIT_TASK_GRAPHQL}
    	                    }
                        }
                       `,
                variables: {
                    createTaskInput: createTaskInput(
                        task,
                        getState().taskEditReducer.newTaskRefId || utils.uuid(),
                        getState().projectReducer.selectedProject.businessId
                    )
                }
            }))
            .then(response => {
                TaskUpdateEventBus.update.notify({
                    taskBusinessId: response.payload.data.data.createTask.businessId,
                    type: actionTypes.CREATE_TASK_SUCCESS
                });
                updateTaskInCache(response);
                return response;
            })
            .catch((originalError) => {
                const taskToCache = offlineTask(task, undefined,
                    getState().projectReducer.selectedProject.businessId);
                return dispatch(validateApplicationStatusForCache(taskCache.cacheCreatedTask(taskToCache), originalError))
                    .then(() => {
                        TaskUpdateEventBus.update.notify({
                            taskBusinessId: taskToCache.businessId,
                            type: actionTypes.CREATE_TASK_TO_CACHE
                        });
                        return dispatch({
                            type: actionTypes.CREATE_TASK_TO_CACHE,
                            data: {task: taskToCache}
                        })
                    })
                    .catch((error) => {
                        console.error(error, originalError)
                    })
            });
    }
}

/**
 * syncTask is called with an already popped task from indexedDb (so the task only exists in the lifetime of this function)
 * the catch branch takes care of putting the task back on the stack if the backend call failed because we are no longer
 * online.
 * @param task - apiTask as if from backend, that's why no mapping happen when we cache the task back
 */
export function syncTask(task) {
    return (dispatch) => {
        return dispatch(
            query.graphql({
                type: actionTypes.SYNCHRONIZE_TASK,
                data: {task},
                caller: 'syncTask',
                query: `mutation synchronizeTask($syncTaskInput: SyncTaskInput){
    	                        synchronizeTask(syncTaskInput: $syncTaskInput){
      	                            businessId
                                    actions {
                                            businessId
                                            referenceId
                                    }
    	                        }
                            }
                           `,
                variables: {
                    syncTaskInput: mapSyncTaskInput(task)
                }
            }))
            .then(response => {
                const syncedActions = _.get(response, 'meta.previousAction.data.task.actions', []);
                const responseActions = _.get(response, 'payload.data.data.synchronizeTask.actions', []);
                const currentlyOpenedActionSynced = syncedActions
                    .find(action => window.location.pathname.includes(action.businessId));

                const matchingActionByHash = currentlyOpenedActionSynced && !_.isEmpty(responseActions)
                    ? responseActions.find(action => action.referenceId === currentlyOpenedActionSynced.businessId)
                    : undefined;
                dispatch(fetchTask(response.payload.data.data.synchronizeTask.businessId));
                //TODO LM: CURRENT_ACTION_SYNCHRONIZED might not be needed anymore, still checking
                if (currentlyOpenedActionSynced && matchingActionByHash) {
                    dispatch({
                        type: actionTypes.CURRENT_ACTION_SYNCHRONIZED,
                        data: {
                            onlineActionId: matchingActionByHash.businessId
                        }
                    })
                }

                return _.get(response, 'payload.data.data.synchronizeTask.businessId', null);
            })
            .catch(() => {
                if (!task.businessId.startsWith("OFFLINE")) {
                    return dispatch(taskCache.cacheEditedTask(task))
                } else {
                    return dispatch(taskCache.cacheCreatedTask(task))
                }
            });
    }
}

export function editTask(task, forceTranslate) {
    return (dispatch, getState) => {
        return dispatch(
            query.graphql({
                type: actionTypes.EDIT_TASK,
                data: {
                    taskBusinessId: task.businessId,
                    task
                },
                caller: 'editTask',
                query: `mutation editTask($editTaskInput: EditTaskInput){
    	                    editTask(editTaskInput: $editTaskInput){
      	                        ${EDIT_TASK_GRAPHQL}
    	                    }
                        }
                       `,
                variables: {
                    editTaskInput: editTaskInput(task, forceTranslate)
                }
            }))
            .then(response => {
                updateTaskInCache(response);
                return response;
            })
            .catch((originalError) => {
                const taskToCache = offlineTask(task, forceTranslate, getState().projectReducer.selectedProject.businessId);
                return dispatch(validateApplicationStatusForCache(taskCache.cacheEditedTask(taskToCache), originalError))
                    .then(() => {
                        TaskUpdateEventBus.update.notify({
                            taskBusinessId: task.businessId,
                            type: actionTypes.EDIT_TASK_TO_CACHE
                        });
                        return dispatch({
                            type: actionTypes.EDIT_TASK_TO_CACHE,
                            data: {
                                task: taskToCache,
                                supportedLanguages: getState().tasksReducer.configurationFromBackend.supportedLanguages
                            }
                        })
                    })
                    .catch((error) => {
                        console.error(error, originalError)
                    });
            });
    }
}

export function cloneTask(task) {
    return (dispatch) => {
        return dispatch(
            query.graphql({
                type: actionTypes.CLONE_TASK,
                data: {
                    taskBusinessId: task.businessId
                },
                caller: 'cloneTask',
                query: `mutation cloneTask($taskId: String!){
    	                    cloneTask(taskId: $taskId){
      	                        ${EDIT_TASK_GRAPHQL}
    	                    }
                        }
                       `,
                variables: {
                    taskId: task.businessId
                }
            }))
            .then(response => {
                TaskUpdateEventBus.update.notify({
                    taskBusinessId: response.payload.data.data.cloneTask.businessId,
                    type: actionTypes.CREATE_TASK_SUCCESS
                });
                updateTaskInCache(response);
                return response;
            })
            .catch((originalError) => {
                console.error(originalError)
            });
    }
}

export function getTemporaryTaskReferenceId() {
    return (dispatch, getState) => {
        return dispatch({
            type: actionTypes.FETCH_TEMPORARY_TASK_REFERENCE_ID,
            data: {
                user: getState().mainReducer.user,
                supportedLanguages: getState().tasksReducer.configurationFromBackend.supportedLanguages,
                deadlines: getState().tasksReducer.configurationFromBackend.deadlines,
                startingWorkflowStatus: getState().tasksReducer.configuration.startingWorkflowStatus,
            },
            payload: {
                client: 'api',
                request: {
                    url: '/task-management/task-reference-id',
                    method: 'GET',
                }
            }
        }).catch(() => {
            return {
                type: actionTypes.FETCH_TEMPORARY_TASK_REFERENCE_ID_OFFLINE,
                data: {
                    referenceId: "OFFLINE-" + utils.uuid()
                }

            }
        });
    }
}

/**
 * Since I always try to remove this, here is a reminder: For new scene scenarios (like mounting TaskEdit, or ActionList)
 * wiring the reset to FETCH_TASK type would be perfect, BUT for trivial scenarios (like updating the action counter
 * on DesktopActionList) this is bad since a reset would reload the page which we do not want (we just want to silently
 * update the counter when the new info arrives)
 * @returns {{type: string}}
 */
export function reset() {
    return {
        type: actionTypes.RESET
    }
}

export function arrivedFrom(path) {
    return (dispatch) => {
        const shouldUpdateArrivedFrom = TASK_EDIT_RETURN_PATHS.find(returnPath => path && path.startsWith(returnPath));
        if (shouldUpdateArrivedFrom) {
            return dispatch({
                type: actionTypes.ARRIVED_FROM,
                data: {path}
            });
        }
    }
}
