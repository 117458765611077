import React, {Component, Fragment} from 'react';
import StandardSelectedQuestions from './selected-questions/StandardSelectedQuestions';
import VDASelectedQuestions from './selected-questions/VDASelectedQuestions';

export class QuestionnaireSelectedQuestions extends Component {
    render() {
        const {isVdaAudit, ...rest} = this.props;
        return (
            <Fragment>
                {
                    isVdaAudit
                        ?
                        <VDASelectedQuestions {...rest}/>
                        :
                        <StandardSelectedQuestions {...rest}/>
                }
            </Fragment>
        );
    }
}

export default QuestionnaireSelectedQuestions;
