import React, {Fragment} from 'react';
import {
    withStyles,
    Typography,
    IconButton,
    CircularProgress,
} from "@material-ui/core";
import Flex from 'components/grid/Flex';
import {htmlGrey, htmlBlack, htmlWhite, transparentBlack08} from 'components/colors/Colors';
import _ from 'lodash';
import moment from 'moment';
import utils from 'utils/Utils';
import {TASK_EDIT} from 'routes/routes';
import {ReactComponent as FlashIcon} from 'assets/img/lightning-icon-full-red.svg';
import H4Avatar from 'components/avatar/H4Avatar';
import {withRouter} from 'react-router-dom';
import {withTranslation} from "react-i18next";
import {routeTo} from 'routes/routes';
import DoneIcon from '@material-ui/icons/Done';
import BacklogIcon from '@material-ui/icons/SubdirectoryArrowLeft';

const styles = theme => ({
    taskButton: {
        padding: 0,
        width: '100%',
        borderRadius: 'unset',
        lineHeight: 'unset',
        fontSize: 'unset'
    },
    container: {
        backgroundColor: htmlWhite,
        position: 'relative',
        padding: 8,
    },
    titleBarItem: {
        fontWeight: 'bold',
        lineHeight: 1,
        color: htmlGrey
    },
    secondaryText: {
        fontSize: 13,
        color: htmlGrey,
        textTransform: 'initial',
        margin: '0 8px'
    },
    title: {
        fontWeight: 'normal',
        lineHeight: '1.3em',
        color: transparentBlack08,
        textTransform: 'initial',
        marginBottom: 8,
        '&:hover': {
            cursor: 'pointer'
        }
    },
    compactTitle: {
        fontWeight: 'bold',
        lineHeight: '1.15em',
        fontSize: 13,
        color: htmlGrey,
        textTransform: 'initial'
    },
    flashIcon: {
        height: 15,
        width: 15,
        fill: htmlGrey
    },
    circularProgress: {
        margin: 'auto'
    },
});

const StoryNumber = (props) => {
    const {classes, task, applicationOnline, styleChangeOnUnseen, taskIdLabel, isUnseen} = props;
    return (
        <Flex item={'0 1 auto'} container justifyContent={'flex-end'}>
            {task.type === 'DEVIATION' && <FlashIcon className={classes.flashIcon}/>}
            {
                task.businessId.startsWith('OFFLINE') ?
                    <Fragment>
                        {
                            applicationOnline &&
                            <CircularProgress classes={{root: classes.circularProgress}} size={12}/>
                        }
                        <Typography className={classes.secondaryText} style={{paddingLeft: 6}}>TEMP-TASK</Typography>
                    </Fragment>
                    :
                    <Typography className={classes.secondaryText}
                                style={styleChangeOnUnseen ? {
                                    color: isUnseen ? htmlBlack : '',
                                    fontWeight: isUnseen ? 'bold' : ''
                                } : {}}
                    >{taskIdLabel}</Typography>
            }
        </Flex>
    );
};

const Title = (props) => {
    const {classes, compactView, styleChangeOnUnseen, isUnseen, title, onClick} = props;
    return (
        <Typography className={!compactView ? classes.title : classes.compactTitle}
                    style={styleChangeOnUnseen ? {
                        color: isUnseen ? htmlBlack : '',
                        fontWeight: isUnseen ? 'bold' : ''
                    } : {}}
                    onClick={onClick}
        >{title}</Typography>
    );
};

const DueDate = (props) => {
    const {classes, styleChangeOnUnseen, isUnseen, dueDate} = props;
    return (
        <Typography className={classes.secondaryText}
                    style={styleChangeOnUnseen ? {
                        color: isUnseen ? htmlBlack : '',
                        fontWeight: isUnseen ? 'bold' : ''
                    } : {}}
        >
            {moment(dueDate).format(utils.DATE_FORMAT)}
        </Typography>
    );
};

export class TaskTile extends React.PureComponent {
    openTask = () => {
        routeTo(TASK_EDIT.pathWithId(this.props.task.businessId), this.props);
    };

    render() {
        const {
            classes, user, task, applicationOnline, isSwiped, styleChangeOnUnseen, i18n, compactView,
            closeTask, removeTaskFromKanbanBoard, isCloseable, isStarting
        } = this.props;
        const matchingTranslation = task.translations
            .find(translation => translation.language.code === i18n.language) || task.translations[0];
        const taskIdLabel = '#' + task.businessId;
        const isUnseen = task.isUnseen && (_.get(task, 'assignee.businessId') === _.get(user, 'businessId'));
        return (
            <Flex item container style={{width: '100%'}}>
                <Flex item container direction={'column'} className={classes.container}>
                    {
                        !isSwiped &&
                        <Title {...{
                            classes,
                            compactView,
                            styleChangeOnUnseen,
                            isUnseen,
                            onClick: this.openTask,
                            priority: task.priority.type,
                            title: matchingTranslation.title
                        }}/>
                    }
                    <Flex item={'1 1 1'} container alignItems={'center'} justifyContent={'space-between'}>
                        <Flex item grow={0} container alignItems={'center'}>

                            <H4Avatar {...{user: task.assignee}} style={{margin: 0}}/>
                            <DueDate {...{
                                classes, styleChangeOnUnseen, isUnseen,
                                dueDate: task.dueDate, progress: task.dueDateProgress
                            }}/>
                        </Flex>
                        <Flex item container justifyContent={'flex-end'} alignItems={'center'}>
                            <StoryNumber {...{
                                classes,
                                task,
                                applicationOnline,
                                styleChangeOnUnseen,
                                taskIdLabel,
                                isUnseen
                            }}/>
                            {
                                isCloseable &&
                                <IconButton onClick={() => closeTask(task)} style={{padding: 3}}>
                                    <DoneIcon style={{color: htmlGrey}}/>
                                </IconButton>
                            }
                            {
                                isStarting &&
                                <IconButton onClick={() => removeTaskFromKanbanBoard(task)} style={{padding: 3}}>
                                    <BacklogIcon style={{color: htmlGrey}}/>
                                </IconButton>
                            }
                        </Flex>
                    </Flex>
                </Flex>
            </Flex>
        );
    }
}

export default withStyles(styles)(withRouter(withTranslation()(TaskTile)));
