import {uploadFile, deleteFileOnUrl} from 'components/attachment/Attachment.action';
import {getAnswerReferenceId, saveAudit, fetchAudits, exportExcelAndCloseAudit} from 'scenes/audit/Audit.action';

export default {
    mapStateToProps: (state) => {
        return {};
    },
    mapDispatchToProps: (dispatch) => {
        return {
            uploadFile: (file, id, progressEvent) => dispatch(uploadFile(file, id, progressEvent, 'ANSWER')),
            deleteFileOnUrl: (url, offlineId) => dispatch(deleteFileOnUrl(url, offlineId)),
            getAnswerReferenceId: () => dispatch(getAnswerReferenceId()),
            saveAudit: (answeredQuestions, topics) => dispatch(saveAudit(answeredQuestions, topics)),
            fetchAudits: () => dispatch(fetchAudits()),
            exportExcelAndCloseAudit: auditId => dispatch(exportExcelAndCloseAudit(auditId)),
        };
    }
};
