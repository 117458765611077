import React, {Fragment, PureComponent} from 'react';
import {withStyles} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Flex from "components/grid/Flex";
import moment from "moment";
import _ from "lodash";
import StatisticCircle from "./StatisticCircle";
import {withTranslation} from "react-i18next";


const styles = theme => ({
    title: {
        textTransform: 'uppercase',
        fontWeight: 'bold',
        fontSize: '1.3vw'
    },
    subTitle: {
        fontSize: '1.1vw',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        width: '100%',
        textAlign: 'center'
    }
});

export class StatisticCircles extends PureComponent {
    render() {
        const {classes, t: translate, successfulAudits, failedCriticalAudits, failedMinorAudits, missedAudits, selectedOrganizationLevel, timePeriodValue} = this.props;
        const year = moment().format('YYYY');
        const month = translate('global.month.' + moment().format('MMMM'));
        return (
            <Fragment>
                <Typography className={classes.title}>
                    {(() => {
                        switch (timePeriodValue) {
                            case 'ALL':
                                return translate('audit-management.reporting.map.allTime');
                            case 'MONTH':
                                return translate('global.dateTimeFormats.month', {
                                    year: year,
                                    month: month.substr(0, 3)
                                });
                            case 'PERIOD':
                                return translate('audit-management.reporting.map.period');
                            default:
                                return ''
                        }
                    })()}
                </Typography>
                {
                    _.isEmpty(selectedOrganizationLevel) &&
                        <Typography className={classes.subTitle}>
                            {translate('audit-management.reporting.map.childrenStatistics')}
                        </Typography>
                }
                <Flex container item grow={0} direction={"column"}>
                    <StatisticCircle {...{
                        statisticItem: successfulAudits,
                        color: 'green',
                        title: translate('audit-management.reporting.map.successful')
                    }}/>
                    <StatisticCircle {...{
                        statisticItem: failedCriticalAudits,
                        color: 'red',
                        title: translate('audit-management.reporting.map.failedCritical')
                    }}/>
                    <StatisticCircle {...{
                        statisticItem: failedMinorAudits,
                        color: 'orange',
                        title: translate('audit-management.reporting.map.failedMinor')
                    }}/>
                    <StatisticCircle {...{
                        statisticItem: missedAudits,
                        color: 'grey',
                        title: translate('audit-management.reporting.map.missed')
                    }}/>
                </Flex>
            </Fragment>
        )
    }
}

export default withStyles(styles)(withTranslation()(StatisticCircles));
