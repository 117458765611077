import {
    loadAccessToken,
    login,
    logout,
    synchronize,
    updateApplicationInformation,
    checkServerConnection,
    toggleOnline,
    unsubscribe,
} from './Main.action'
import {initializeWebsocket} from "socket/Socket.action";

export default {
    mapStateToProps: state => {
        return {
            user: state.mainReducer.user,
            authenticated: state.mainReducer.authenticated,
            defaultRouteForUser: state.mainReducer.defaultRouteForUser,
            routesAllowedByUser: state.mainReducer.routesAllowedByUser,
            online: state.mainReducer.online,
            manualOffline: state.mainReducer.manualOffline,
            suggestedApplicationVersion: state.mainReducer.suggestedApplicationVersion,
            websocketClient: state.socketReducer.client,
            websocketClientConnected: state.socketReducer.connected,
            isOverlayActive: state.mainReducer.isOverlayActive,
            stableServerConnection: state.mainReducer.stableServerConnection,
            unsubscriptionResult: state.mainReducer.unsubscriptionResult
        }
    },
    mapDispatchToProps: dispatch => {
        return {
            loadAccessToken: () => dispatch(loadAccessToken()),
            login: (username, password) => dispatch(login(username, password)),
            logout: () => dispatch(logout()),
            synchronize: () => dispatch(synchronize()),
            initializeWebsocket: () => dispatch(initializeWebsocket()),
            updateApplicationInformation: (applicationInformation) => dispatch(updateApplicationInformation(applicationInformation)),
            checkServerConnection: () => dispatch(checkServerConnection()),
            toggleOnline: () => dispatch(toggleOnline()),
            unsubscribe: (id) => dispatch(unsubscribe(id)),
        }
    }
}
