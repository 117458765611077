import React, { Component } from 'react';
import { withStyles, LinearProgress } from "@material-ui/core";
import {guardsmanRed, htmlOrange} from 'components/colors/Colors';

const styles = theme => ({
    redProgress: {
        backgroundColor: guardsmanRed
    },
    orangeProgress: {
        backgroundColor: htmlOrange
    },
});

export class WorkDoneProgressBar extends Component {
    render() {
        const {classes, value, style} = this.props;

        return (
            <LinearProgress
                classes={{
                    barColorPrimary: (
                        value >= 50 && value <= 75 && classes.orangeProgress)
                        || (value > 75 && classes.redProgress)
                }}
                style={style}
                variant="determinate"
                value={value || 0}/>
        );
    }
}

export default withStyles(styles)(WorkDoneProgressBar);
