export const REMOVE_UNSEEN_ATTACHMENT_TAG_TO_ACTION = '@action_list/REMOVE_UNSEEN_ATTACHMENT_TAG_TO_ACTION';
export const REMOVE_UNSEEN_ATTACHMENT_TAG_TO_ACTION_SUCCESS = '@action_list/REMOVE_UNSEEN_ATTACHMENT_TAG_TO_ACTION_SUCCESS';
export const REMOVE_UNSEEN_ATTACHMENT_TAG_TO_ACTION_FAIL = '@action_list/REMOVE_UNSEEN_ATTACHMENT_TAG_TO_ACTION_FAIL';
export const REMOVE_UNSEEN_CONTENT_TAG_TO_ACTION = '@action_list/REMOVE_UNSEEN_CONTENT_TAG_TO_ACTION';
export const REMOVE_UNSEEN_CONTENT_TAG_TO_ACTION_SUCCESS = '@action_list/REMOVE_UNSEEN_CONTENT_TAG_TO_ACTION_SUCCESS';
export const REMOVE_UNSEEN_CONTENT_TAG_TO_ACTION_FAIL = '@action_list/REMOVE_UNSEEN_CONTENT_TAG_TO_ACTION_FAIL';
export const REMOVE_UNSEEN_COMMENT_TAG_TO_ACTION = '@action_list/REMOVE_UNSEEN_COMMENT_TAG_TO_ACTION';
export const REMOVE_UNSEEN_COMMENT_TAG_TO_ACTION_SUCCESS = '@action_list/REMOVE_UNSEEN_COMMENT_TAG_TO_ACTION_SUCCESS';
export const REMOVE_UNSEEN_COMMENT_TAG_TO_ACTION_FAIL = '@action_list/REMOVE_UNSEEN_COMMENT_TAG_TO_ACTION_FAIL';

export const ARCHIVE_ACTION = '@action_list/ARCHIVE_ACTION';
export const ARCHIVE_ACTION_SUCCESS = '@action_list/ARCHIVE_ACTION_SUCCESS';
export const ARCHIVE_ACTION_FAIL = '@action_list/ARCHIVE_ACTION_FAIL';

export const DONE_ACTION = '@action_list/DONE_ACTION';
export const DONE_ACTION_SUCCESS = '@action_list/DONE_ACTION_SUCCESS';
export const DONE_ACTION_FAIL = '@action_list/DONE_ACTION_FAIL';
