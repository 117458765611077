import {
    sortTasksAndEditStatus,
    closeTask,
    removeTaskFromKanbanBoard,
    listProjectTasks
} from 'scenes/tasks/Tasks.action';
import {editTask} from "scenes/tasks/task-edit/TaskEdit.action";

export default {
    mapStateToProps: (state) => {
        return {
            taskColumns: state.tasksReducer.kanbanTaskColumns,
            user: state.mainReducer.user,
            applicationOnline: state.mainReducer.online,
            tagSuggestions: state.tasksReducer.configuration.freeTags,
            selectedProject: state.projectReducer.selectedProject,
            selectedUserNames: state.projectReducer.selectedUserNames,
            closableWorkflowStatus: state.tasksReducer.configuration.closableWorkflowStatus,
            startingWorkflowStatus: state.tasksReducer.configuration.startingWorkflowStatus,
            isTaskLoadingReady: state.tasksReducer.isTaskLoadingReady,
            isProjectLoaded: state.tasksReducer.isProjectLoaded,
        };
    },
    mapDispatchToProps: (dispatch) => {
        return {
            sortTasksAndEditStatus: (draggedTaskStatus, draggedTaskId, destinationTaskStatus, destinationTaskBusinessId) =>
                dispatch(sortTasksAndEditStatus(draggedTaskStatus, draggedTaskId, destinationTaskStatus, destinationTaskBusinessId)),
            closeTask: task => dispatch(closeTask(task)),
            editTask: task => dispatch(editTask(task)),
            removeTaskFromKanbanBoard: task => dispatch(removeTaskFromKanbanBoard(task)),
            listProjectTasks: () => dispatch(listProjectTasks())
        };
    }
};
