import Dexie from 'dexie';

export const taskDatabase = new Dexie('tasks');

taskDatabase.version(1).stores({
    tasks: "++id",
    taskConfiguration: "++id",
    users: "++id",
    tags: "++id",
    editedTasks: "businessId",
    createdTasks: "++id",
    taskManagementBoard: "++id",
    projects: "++id"
});

taskDatabase.version(2).stores({
    tasks: "++id",
    taskConfiguration: "++id",
    users: "++id",
    tags: "++id",
    editedTasks: "businessId",
    taskManagementBoard: "++id",
    projects: "++id",
    editedActions: "businessId"
});

taskDatabase.version(3).stores({
    tasks: "++id",
    taskConfiguration: "++id",
    users: "++id",
    tags: "++id",
    editedTasks: "businessId",
    taskManagementBoard: "++id",
    projects: "++id",
    editedActions: "businessId",
    editedProjects: "businessId",
    editedTagAssignments: "taskBusinessId",
    removedTagAssignments: "taskBusinessId"
});

taskDatabase.version(4).stores({
    tasks: "++id",
    taskConfiguration: "++id",
    users: "++id",
    tags: "++id",
    editedTasks: "businessId",
    taskManagementBoard: "++id",
    projects: "++id",
    editedActions: "businessId",
    editedProjects: "businessId",
    editedTagAssignments: "taskBusinessId",
    tagAssignments: "businessId",
    removedTagAssignments: "taskBusinessId"
});
