import React, {Component} from 'react';
import {withStyles, IconButton, Dialog} from "@material-ui/core";
import Dropzone from 'react-dropzone';
import DropAreaImage from 'assets/img/gallery.svg';
import {Delete as DeleteIcon} from '@material-ui/icons';
import {Cached as ReplaceIcon} from '@material-ui/icons';
import {auditPaperGrey, fileDropdownArea, silver} from "components/colors/Colors";

const styles = theme => ({
    dropDownArea: {
        backgroundColor: fileDropdownArea,
        height: '70%',
        width: '100%',
        display: 'flex',
        flex: '0 0 auto',
        justifyContent: 'center',
        alignItems: 'center',
    },
    dropDownImage: {
        height: 50,
        width: 50,
    },
    questionPicture: {
        height: '100%',
        maxHeight: 380,
        width: '100%',
        maxWidth: 500,
        objectFit: 'contain'
    },
    imageButton: {
        position: 'absolute',
        color: auditPaperGrey,
        bottom: 8,
        right: 8,
        padding: 5,
        backgroundColor: silver
    }
});

export class SingleImageAttachment extends Component {
    state = {
        showDialog: false
    };

    toggleDialog = () => {
        this.setState({
            showDialog: !this.state.showDialog
        })
    };

    render() {
        const {classes, pictureUrl, deletePicture, onDrop, isDeletePossible} = this.props;
        const {toggleDialog} = this;
        return (
            pictureUrl ?
                <div style={{position: 'relative'}}>
                    <img className={classes.questionPicture} src={pictureUrl} alt={''}
                         onClick={toggleDialog}/>
                    {
                        isDeletePossible
                            ? <IconButton className={classes.imageButton} onClick={deletePicture}>
                                <DeleteIcon/>
                            </IconButton>
                            : <Dropzone onDrop={onDrop} accept="image/*">
                                {({getRootProps, getInputProps, isDragActive}) => {
                                    return (
                                        <div {...getRootProps()}>
                                            <input {...getInputProps()} />
                                            <IconButton className={classes.imageButton}>
                                                <ReplaceIcon/>
                                            </IconButton>
                                        </div>
                                    )
                                }}
                            </Dropzone>
                    }
                    <Dialog
                        onClose={toggleDialog}
                        open={this.state.showDialog}
                    >
                        <img src={pictureUrl} alt={''}/>
                    </Dialog>
                </div>
                :
                <Dropzone onDrop={onDrop} accept="image/*">
                    {({getRootProps, getInputProps, isDragActive}) => {
                        return (
                            <div {...getRootProps()} className={classes.dropDownArea}>
                                <input {...getInputProps()} />
                                <img className={classes.dropDownImage} src={DropAreaImage} alt={''}/>
                            </div>
                        )
                    }}
                </Dropzone>
        );
    }
}

export default withStyles(styles)(SingleImageAttachment);
