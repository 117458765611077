import * as actionTypes from './Questionnaires.action.types';
import _ from 'lodash';
import {mapQuestionnairesForListing} from "domain/audit/Questionnaire.model";

const initialState = {
    questionnaires: []
};

export default function (state = initialState, action) {
    switch (action.type) {
        case actionTypes.FETCH_QUESTIONNAIRES_SUCCESS: {
            const newState = _.cloneDeep(state);
            if (_.get(action, 'payload.data.data.listQuestionnaires')) {
                const user = _.get(action, 'data.user') || _.get(action, 'meta.previousAction.data.user');
                newState.questionnaires = sortByBusinessId(mapQuestionnairesForListing(action.payload.data.data.listQuestionnaires, user));
            }
            return newState;
        }
        default:
            return state;
    }
}

export function sortByBusinessId(array) {
    return _.sortBy(array, item => parseInt(item.businessId));
}