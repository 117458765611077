import {listDailyTaskStatusReportFromServer} from './TaskReporting.action';

export default {
    mapStateToProps: (state) => {
        return {
            isProjectTreeOpen: state.projectReducer.isProjectTreeOpen,
            report: state.taskReportingReducer.report,
        };
    },
    mapDispatchToProps: (dispatch) => {
        return {
            listDailyTaskStatusReportFromServer: (from, to) => dispatch(listDailyTaskStatusReportFromServer(from, to)),
        };
    }
};
