import {
    clearFilterMultiSelection,
    closeOrganizationLevelTree,
    createOrganizationLevel,
    deleteOrganizationLevel,
    editOrganizationLevel,
    fetchOrganizationLevels,
    fetchOrganizationManagementConfigurationData,
    fetchUsers,
    listOpenAuditsForOrganizationLevel,
    openOrganizationLevelTree,
    toggleFilterMultiSelection,
    toggleFilterOpen,
    toggleFilterSelectAll,
    toggleFilterSelection,
    updateRootNodeTitle
} from './OrganizationTree.action';

export default {
    mapStateToProps: (state) => {
        return {
            organizationTree: state.organizationTreeReducer.organizationTree,
            organizationFilterBusinessId: state.organizationTreeReducer.organizationFilterBusinessId,
            isOrganizationTreeOpen: state.organizationTreeReducer.isOrganizationTreeOpen,
            isRootTitleEditable: state.organizationTreeReducer.isRootTitleEditable,
            applicationOnline: state.mainReducer.online,
            openAudits: state.organizationTreeReducer.openAudits
        };
    },
    mapDispatchToProps: (dispatch) => {
        return {
            fetchOrganizationLevels: () => dispatch(fetchOrganizationLevels()),
            toggleFilterSelection: (businessId) => () => dispatch(toggleFilterSelection(businessId)),
            toggleFilterMultiSelection: (businessId) => () => dispatch(toggleFilterMultiSelection(businessId)),
            toggleFilterOpen: (businessId) => () => dispatch(toggleFilterOpen(businessId)),
            createOrganizationLevel: (parentBusinessId, name) => dispatch(createOrganizationLevel(parentBusinessId, name)),
            editOrganizationLevel: (businessId, name) => dispatch(editOrganizationLevel(businessId, name)),
            deleteOrganizationLevel: (businessId) => dispatch(deleteOrganizationLevel(businessId)),
            openOrganizationLevelTree: () => dispatch(openOrganizationLevelTree()),
            closeOrganizationLevelTree: () => dispatch(closeOrganizationLevelTree()),
            fetchUsers: () => dispatch(fetchUsers()),
            fetchOrganizationManagementConfigurationData: () => dispatch(fetchOrganizationManagementConfigurationData()),
            toggleFilterSelectAll: () => dispatch(toggleFilterSelectAll()),
            clearFilterMultiSelection: () => dispatch(clearFilterMultiSelection()),
            updateRootNodeTitle: () => dispatch(updateRootNodeTitle()),
            listOpenAuditsForOrganizationLevel: (levelBusinessId) => dispatch(listOpenAuditsForOrganizationLevel(levelBusinessId))
        };
    }
};
