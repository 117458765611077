export const FETCH_ORGANIZATION_LEVEL = '@organization_level_configuration/FETCH_ORGANIZATION_LEVEL';
export const FETCH_ORGANIZATION_LEVEL_SUCCESS = '@organization_level_configuration/FETCH_ORGANIZATION_LEVEL_SUCCESS';
export const FETCH_ORGANIZATION_LEVEL_FAIL = '@organization_level_configuration/FETCH_ORGANIZATION_LEVEL_FAIL';

export const ASSIGN_ORGANIZATION_LEVEL_MEMBER = '@organizationTree/ASSIGN_ORGANIZATION_LEVEL_MEMBER';
export const ASSIGN_ORGANIZATION_LEVEL_MEMBER_SUCCESS = '@organizationTree/ASSIGN_ORGANIZATION_LEVEL_MEMBER_SUCCESS';
export const ASSIGN_ORGANIZATION_LEVEL_MEMBER_FAIL = '@organizationTree/ASSIGN_ORGANIZATION_LEVEL_MEMBER_FAIL';

export const REMOVE_ORGANIZATION_LEVEL_MEMBER = '@organizationTree/REMOVE_ORGANIZATION_LEVEL_MEMBER';
export const REMOVE_ORGANIZATION_LEVEL_MEMBER_SUCCESS = '@organizationTree/REMOVE_ORGANIZATION_LEVEL_MEMBER_SUCCESS';
export const REMOVE_ORGANIZATION_LEVEL_MEMBER_FAIL = '@organizationTree/REMOVE_ORGANIZATION_LEVEL_MEMBER_FAIL';

export const UPDATE_ORGANIZATION_LEVEL_MEMBERSHIP = '@organizationTree/UPDATE_ORGANIZATION_LEVEL_MEMBERSHIP';
export const UPDATE_ORGANIZATION_LEVEL_MEMBERSHIP_SUCCESS = '@organizationTree/UPDATE_ORGANIZATION_LEVEL_MEMBERSHIP_SUCCESS';
export const UPDATE_ORGANIZATION_LEVEL_MEMBERSHIP_FAIL = '@organizationTree/UPDATE_ORGANIZATION_LEVEL_MEMBERSHIP_FAIL';

export const FETCH_USERS = '@organizationTree/FETCH_USERS';
export const FETCH_USERS_SUCCESS = '@organizationTree/FETCH_USERS_SUCCESS';
export const FETCH_USERS_FAIL = '@organizationTree/FETCH_USERS_FAIL';