import * as actionTypes from "./OrganizationTree.action.types";
import {
    createOrganizationLevelInput,
    ORGANIZATION_LEVEL_GRAPHQL,
    ORGANIZATION_MANAGEMENT_CONFIGURATION_DATA_GRAPHQL,
    updateOrganizationLevelDetailsInput
} from "domain/audit/OrganizationLevel.model";
import {USER_GRAPHQL} from "domain/User.model";
import query from "store/GraphqlQueries";

export function fetchOrganizationLevels() {
    return (dispatch, getState) => {
        return dispatch({
            type: actionTypes.FETCH_ORGANIZATION_LEVELS,
            data: {user: getState().mainReducer.user},
            payload: {
                client: 'graphql',
                request: {
                    method: 'post',
                    data: {
                        query: `
                    {
                        listOrganizationLevels {
                            ${ORGANIZATION_LEVEL_GRAPHQL}
                        }
                    }
                    `
                    }
                }
            }
        })
    }
}

export function toggleFilterSelection(businessId) {
    return {
        type: actionTypes.TOGGLE_FILTER_SELECTION,
        data: {businessId}
    }
}

export function toggleFilterMultiSelection(businessId) {
    return {
        type: actionTypes.TOGGLE_FILTER_MULTI_SELECTION,
        data: {businessId}
    }
}

export function toggleFilterMultiSelections(businessIds) {
    return {
        type: actionTypes.TOGGLE_FILTER_MULTI_SELECTIONS,
        data: {businessIds}
    }
}

export function toggleFilterOpen(businessId) {
    return {
        type: actionTypes.TOGGLE_FILTER_OPEN,
        data: {businessId}
    }
}

export function toggleFilterSelectAll() {
    return {
        type: actionTypes.TOGGLE_FILTER_SELECT_ALL,
    }
}

export function createOrganizationLevel(parentBusinessId, name) {
    return (dispatch, getState) => {
        return dispatch({
            type: actionTypes.CREATE_ORGANIZATION_LEVEL,
            payload: {
                client: 'graphql',
                request: {
                    method: 'post',
                    data: {
                        query: `mutation createOrganizationLevel($createOrganizationLevelInput: CreateOrganizationLevelInput){
    	                        createOrganizationLevel(createOrganizationLevelInput: $createOrganizationLevelInput){
      	                            businessId
    	                        }
                            }
                           `,
                        variables: {
                            createOrganizationLevelInput:
                                createOrganizationLevelInput(name, parentBusinessId, getState().mainReducer.user.businessId)
                        }
                    }
                }
            }
        })
    }
}

export function editOrganizationLevel(businessId, name) {
    return {
        type: actionTypes.EDIT_ORGANIZATION_LEVEL,
        payload: {
            client: 'graphql',
            request: {
                method: 'post',
                data: {
                    query: `mutation updateOrganizationLevelDetails($updateOrganizationLevelDetailsInput: UpdateOrganizationLevelDetailsInput){
    	                        updateOrganizationLevelDetails(updateOrganizationLevelDetailsInput: $updateOrganizationLevelDetailsInput){
      	                            businessId
    	                        }
                            }
                           `,
                    variables: {
                        updateOrganizationLevelDetailsInput: updateOrganizationLevelDetailsInput(name, businessId)
                    }
                }
            }
        }
    }
}

export function deleteOrganizationLevel(businessId) {
    return {
        type: actionTypes.DELETE_ORGANIZATION_LEVEL,
        payload: {
            client: 'graphql',
            request: {
                method: 'post',
                data: {
                    query: `mutation deleteOrganizationLevel($organizationLevelBusinessId: String!){
    	                        deleteOrganizationLevel(organizationLevelBusinessId: $organizationLevelBusinessId)
                            }
                           `,
                    variables: {
                        organizationLevelBusinessId: businessId
                    }
                }
            }
        }
    }
}

export function addOrganizationLevelMembership(businessId, userId, role) {
    return {
        type: actionTypes.ASSIGN_ORGANIZATION_LEVEL_MEMBER,
        payload: {
            client: 'graphql',
            request: {
                method: 'post',
                data: {
                    query: `mutation addOrganizationLevelMembership($organizationLevelId: String!, $userId: String!, $membership: OrganizationMembership!){
    	                        addOrganizationLevelMembership(organizationLevelId: $organizationLevelId, userId: $userId, membership: $membership)
                            }
                           `,
                    variables: {
                        organizationLevelId: businessId,
                        userId: userId,
                        membership: role
                    }
                }
            }
        }
    }
}

export function updateOrganizationLevelMembership(businessId, userId, newRole, oldRole) {
    return {
        type: actionTypes.UPDATE_ORGANIZATION_LEVEL_MEMBERSHIP,
        payload: {
            client: 'graphql',
            request: {
                method: 'post',
                data: {
                    query: `mutation updateOrganizationLevelMembership($organizationLevelId: String!, $userId: String!, $newMembership: OrganizationMembership!, $oldMembership: OrganizationMembership!){
    	                        updateOrganizationLevelMembership(organizationLevelId: $organizationLevelId, userId: $userId, newMembership: $newMembership, oldMembership: $oldMembership)
                            }
                           `,
                    variables: {
                        organizationLevelId: businessId,
                        userId: userId,
                        newMembership: newRole,
                        oldMembership: oldRole
                    }
                }
            }
        }
    }
}

export function removeOrganizationLevelMembership(businessId, userId, membership) {
    return {
        type: actionTypes.REMOVE_ORGANIZATION_LEVEL_MEMBER,
        payload: {
            client: 'graphql',
            request: {
                method: 'post',
                data: {
                    query: `mutation removeOrganizationLevelMembership($organizationLevelId: String!, $userId: String!, $membership:OrganizationMembership!){
    	                        removeOrganizationLevelMembership(organizationLevelId: $organizationLevelId, userId: $userId, membership: $membership)
                            }
                           `,
                    variables: {
                        organizationLevelId: businessId,
                        userId: userId,
                        membership: membership
                    }
                }
            }
        }
    }
}

export function openOrganizationLevelTree() {
    return {
        type: actionTypes.OPEN_ORGANIZATION_LEVEL_TREE,
    }
}

export function closeOrganizationLevelTree() {
    return {
        type: actionTypes.CLOSE_ORGANIZATION_LEVEL_TREE,
    }
}

export function fetchUsers() {
    return fetchUsersFromServer();
}

export function fetchUsersFromServer() {
    return {
        type: actionTypes.FETCH_USERS,
        payload: {
            client: 'graphql',
            request: {
                method: 'post',
                data: {
                    query: `
                            {
                                listUsers {
                                    ${USER_GRAPHQL}
                                }
                            }
                           `
                }
            }
        }
    };
}

export function fetchOrganizationManagementConfigurationData() {
    return (dispatch, getState) => {
        return dispatch({
            type: actionTypes.FETCH_ORGANIZATION_MANAGEMENT_CONFIGURATION_DATA,
            payload: {
                client: 'graphql',
                request: {
                    method: 'post',
                    data: {
                        query: `
                    {
                        fetchOrganizationManagementConfigurationData {
                            ${ORGANIZATION_MANAGEMENT_CONFIGURATION_DATA_GRAPHQL}
                        }
                    }
                    `
                    }
                }
            }
        })
    }
}

export function updateOrganizationLevelTimeZone(businessId, timeZone) {
    return {
        type: actionTypes.UPDATE_ORGANIZATION_LEVEL_TIMEZONE,
        payload: {
            client: 'graphql',
            request: {
                method: 'post',
                data: {
                    query: `mutation updateOrganizationLevelTimeZone($organizationLevelId: String!, $newTimeZone: TimeZone!){
    	                        updateOrganizationLevelTimeZone(organizationLevelId: $organizationLevelId, newTimeZone: $newTimeZone)
                            }
                           `,
                    variables: {
                        organizationLevelId: businessId,
                        newTimeZone: timeZone
                    }
                }
            }
        }
    }
}

export function clearFilterMultiSelection() {
    return {
        type: actionTypes.CLEAR_FILTER_MULTI_SELECTION,
    }
}

export function updateRootNodeTitle() {
    return {
        type: actionTypes.UPDATE_ORGANIZATION_TREE_ROOT_TITLE
    }
}

export function resetOrganizationLevelTree() {
    return {
        type: actionTypes.RESET_ORGANIZATION_LEVEL_TREE
    }
}

export function listOpenAuditsForOrganizationLevel(levelBusinessId) {
    const input = {
        organizationLevelIds: [levelBusinessId],
        statuses: ['OPEN']
    };
    return (dispatch) => {
        return dispatch(
            query.graphql({
                type: actionTypes.FETCH_AUDITS,
                caller: 'listAudits',
                query: `
                            query ($filter: AuditFilter!) {
                                listAudits(filter: $filter) { 	
                                        businessId                                        
                                }  
                            }
                           `,
                variables: {
                    filter: input
                }
            })
        );
    }
}
