import _ from "lodash";

export function cacheAndLoad(database, table, originalReduxCall, cacheReduxCall) {
    return dispatch => {
        return dispatch(originalReduxCall())
            .then(response => {
                const dataWrapper = _.get(response, 'payload.data.data');
                const actualPayload = dataWrapper[Object.keys(dataWrapper)[0]];

                return database[table].put({id: 1, response: actualPayload})
                    .then(() => {
                        console.log(table, '[', database.name, '][', database.verno, '] cache updated');
                        return response;
                    })
                    .catch(error => {
                        console.log('Could not write to the local cache. Error: ', error);
                    });
            })
            .catch(error => {
                console.log('Could not reach the server, serving ' + table + ' from local cache. Error: ', error);
                return database[table].get(1)
                    .then(backup => {
                        console.log('Successfully getting data from cache:', backup);
                        return dispatch(cacheReduxCall(backup.response));
                    })
                    .catch(error => {
                        console.log('Cannot get cache from local database', error);
                    })
            });
    };
}

export function loadAndFilter(database, table, originalReduxCall, cacheReduxCall, filter) {
    return dispatch => {
        return dispatch(originalReduxCall)
            .catch(error => {
                console.log('Could not reach the server, serving ' + table + ' from local cache. Error: ', error);
                return database[table].get(1)
                    .then(backup => {
                        const data = backup.response.find(filter);
                        console.log('Successfully getting data from cache:', data);
                        dispatch(cacheReduxCall(data));
                    })
                    .catch(error => {
                        console.log('Cannot get cache from local database', error);
                    })
            });
    };
}

export function validateApplicationStatusForCache(cachingCall, response) {
    return dispatch => {
        const errorCode = _.get(response, 'error.response.status');
        if ( errorCode === 500 || errorCode === 503) {
            console.log('Application is online, possible erroneous call to the backend, no cache needed');
            return Promise.reject('Application is online, possible erroneous call to the backend, no cache needed');
        } else {
            return dispatch(cachingCall);
        }
    }
}
