import React, {Component, Fragment} from 'react';
import {withTranslation} from "react-i18next";
import {TASK_CREATE} from 'routes/routes';
import {emptyTaskForQuickCreate} from 'scenes/tasks/task-edit/TaskEdit.reducer';
import {withRouter} from 'react-router-dom';
import MyActionList from 'scenes/tasks/actions/my-action-list/MyActionList';
import MobileKanban from 'scenes/tasks/kanban/components/mobile/MobileKanban';
import MobileBacklog from 'scenes/tasks/backlog/components/mobile/MobileBacklog';
import {ACTIVE_VALUE, BACKLOG_VALUE, MY_ACTIONS_VALUE} from 'domain/task/Tasks.model';
import MobileBreadcrumbHeader from 'scenes/tasks/components/mobile/MobileBreadcrumbHeader';
import {prevPath} from 'routes/routes';
import {NEW} from 'components/quill-editor/EditorUtils';
import {haveMatchingStatus} from 'scenes/tasks/Task.utils';
import {parse} from 'query-string';
import _ from 'lodash';

export class MobileTaskList extends Component {

    state = {
        searchText: '',
        swipedTask: ''
    };

    bottom = React.createRef();

    componentDidMount() {
        const params = parse(this.props.location.search);

        const view = _.get(params,'view', MY_ACTIONS_VALUE);
        this.props.changeFilter({view: view});

        const organizationLevelId = _.get(params,'organizationLevelId');
        if (organizationLevelId) {
            this.props.toggleProjectFilterSelectionByOrganizationLevelId(organizationLevelId);
        }
    }

    scrollToBottom = () => {
        this.bottom.current && this.bottom.current.scrollIntoView({
            block: "end",
            inline: "nearest",
            behavior: "smooth"
        });
    };

    handleChange = event => {
        this.setState({
            [event.target.name]: event.target.value,
        });
    };

    searchTextFilter = task => {
        const matchingTranslation = task.translations
            .find(translation => translation.language.code === this.props.i18n.language) || task.translations[0];
        return this.state.searchText === ''
            ? true
            :
            matchingTranslation.title.toLowerCase().includes(this.state.searchText.toLowerCase()) ||
            task.businessId.includes(this.state.searchText);
    };

    viewFilter = task => {
        switch (this.props.filters.view) {
            case ACTIVE_VALUE:
                return haveMatchingStatus(this.props.activeWorkflowStatuses, task);
            case BACKLOG_VALUE:
                return haveMatchingStatus(this.props.backlogWorkflowStatuses, task);
            default:
                return false;
        }
    };


    doneFilter = task => {
        return task.status.label !== 'Done'
    };

    projectFilter = task => {
        return task.projectBusinessId === this.props.selectedProject.businessId;
    };

    userFilter = task => {
        return this.props.selectedUserNames.length === 0 || this.props.selectedUserNames.includes(_.get(task, 'assignee.username'));
    };

    createQuickTask = () => {
        if (this.state.searchText !== '') {
            let task = emptyTaskForQuickCreate(this.props.user,
                this.props.configurationFromBackend.supportedLanguages,
                this.props.configurationFromBackend.deadlines,
                this.props.filters.view === ACTIVE_VALUE
                    ? this.props.startingWorkflowStatus
                    : this.props.backlogWorkflowStatus);
            const matchingTranslation = task.translations
                .find(translation => translation.language.code === this.props.i18n.language);
            matchingTranslation.title = this.state.searchText;
            matchingTranslation.origin = NEW;
            this.setState({
                menuItem: '',
                searchText: ''
            });
            if (matchingTranslation.title !== '') {
                this.props.createTask(task).then(() => {
                    this.scrollToBottom();
                });
            }
        } else {
            this.props.history.push(TASK_CREATE.path, {
                taskTitle: this.state.searchText,
                prevPath: prevPath(this.props)
            });
        }
    };

    archive = task => () => {
        this.resetSwipe();
        this.props.archiveTask(task)
    };

    toggleKanban = task => () => {
        this.resetSwipe();
        if (haveMatchingStatus(this.props.activeWorkflowStatuses, task)) {
            this.props.removeTaskFromKanbanBoard(task)
        } else {
            this.props.moveTaskToKanbanBoard(task)
        }
    };

    handleTaskSwipe = task => index => {
        if (index === 0) {
            this.setState({
                swipedTask: ''
            })
        } else if (index === 1) {
            this.setState({
                swipedTask: task.businessId
            })
        }
    };

    resetSwipe = () => {
        this.setState({
            swipedTask: ''
        })
    };

    sortByMyUnseen = (a, b) => {
        if (a.isUnseen && !b.isUnseen && (_.get(a, 'assignee.businessId') === this.props.user.businessId)) {
            return -1;
        }
        if (!a.isUnseen && b.isUnseen) {
            return 1;
        }
        return 0;
    };

    render() {
        const {tasks, applicationOnline, user, filters, selectedProject, activeWorkflowStatuses} = this.props;
        const view = filters.view;
        const {searchText, swipedTask} = this.state;
        const {handleChange, createQuickTask, archive, toggleKanban, handleTaskSwipe} = this;

        const filteredTasks = tasks
            .filter(this.projectFilter)
            .filter(this.userFilter)
            .filter(this.doneFilter)
            .filter(this.searchTextFilter)
            .filter(this.viewFilter)
            .sort(this.sortByMyUnseen);

        return (
            <Fragment>
                <MobileBreadcrumbHeader {...{breadCrumb: selectedProject.breadCrumb, user, view}}/>
                {
                    filters.view === MY_ACTIONS_VALUE &&
                    <MyActionList/>
                }
                {
                    filters.view === ACTIVE_VALUE &&
                    <MobileKanban {...{
                        searchText, createQuickTask, handleChange, filteredTasks, applicationOnline,
                        swipedTask, handleTaskSwipe, archive, toggleKanban, view, user, activeWorkflowStatuses
                    }}/>
                }
                {
                    filters.view === BACKLOG_VALUE &&
                    <MobileBacklog {...{
                        searchText, createQuickTask, handleChange, filteredTasks, applicationOnline, swipedTask,
                        handleTaskSwipe, archive, toggleKanban, view, user
                    }}/>
                }
            </Fragment>
        );
    }
}

export default withTranslation()(withRouter(MobileTaskList));
