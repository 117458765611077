import {
    createQuestion, fetchQuestion, fetchEmptyQuestion, editQuestion,
} from "./QuestionEdit.action";
import {uploadFile} from 'components/attachment/Attachment.action';

export default {
    mapStateToProps: (state) => {
        return {
            answerTypes: state.generalConfigurationReducer.auditConfiguration.answerTypes,
            catalogues: state.generalConfigurationReducer.auditConfiguration.catalogues,
            cycles: state.generalConfigurationReducer.auditConfiguration.cycles,
            masterLanguage: state.generalConfigurationReducer.auditConfiguration.masterLanguage,
            question: state.questionEditReducer.question
        };
    },
    mapDispatchToProps: (dispatch) => {
        return {
            createQuestion: (question) => dispatch(createQuestion(question)),
            editQuestion: (question) => dispatch(editQuestion(question)),
            fetchQuestion: (questionBusinessId) => dispatch(fetchQuestion(questionBusinessId)),
            fetchEmptyQuestion: () => dispatch(fetchEmptyQuestion()),
            uploadFile:(file, id, progressEvent) =>
                dispatch(uploadFile(file, id, progressEvent, 'QUESTION')),
        };
    }
};
