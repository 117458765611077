import React, {Component} from 'react';
import {LinearProgress, Typography, withStyles} from "@material-ui/core";
import {withTranslation} from "react-i18next";
import Flex from "components/grid/Flex";
import {
    transparentBlack05,
    silver,
    greyHighlight,
    audit,
    htmlWhite,
    ghostGrey
} from "components/colors/Colors";
import moment from 'moment';

const styles = theme => ({
    columnContainer: {
        marginBottom: 'auto',
        minWidth: 200,
        height: '100%',
        marginRight: 10,
        marginLeft: 0,
        "&:last-child": {
            marginRight: 0
        },
        "&:first-child": {
            marginLeft: 10
        },
        borderRadius: 5
    },
    columnTitle: {
        paddingTop: 8,
        marginBottom: 5,
        minWidth: 180,
        textAlign: 'center',
        fontWeight: 'bold'
    },
    columnSubTitle: {
        textAlign: 'center',
        fontWeight: 'bold',
        color: transparentBlack05,
        padding: 4,
        marginBottom: 5,
        minWidth: 180,
    },
    linearProgressBaseColor: {
        backgroundColor: htmlWhite
    },
    linearProgressBarColorNormal: {
        backgroundColor: audit
    },
    progressBarContainer: {
        position: 'relative',
        marginLeft: 20,
        marginRight: 20
    },
    progressBarSeparatorContainer: {
        position: 'absolute',
        left: 0,
        top: 0,
        height: 4,
        width: '100%'
    },
    progressBarSeparator: {
        borderLeft: `2px solid ${ghostGrey}`,
        width: '100%'
    }
});

const TodayProgressBar = (props) => {
    const {classes, value} = props;
    return (
        <div className={classes.progressBarContainer}>
            <LinearProgress
                classes={{
                    colorPrimary: classes.linearProgressBaseColor,
                    barColorPrimary: classes.linearProgressBarColorNormal
                }}
                variant="determinate"
                value={value || 0}/>
            <Flex container justifyContent={'space-between'} className={classes.progressBarSeparatorContainer}>
                <div style={{width: '100%'}}/>
                <div className={classes.progressBarSeparator}/>
                <div className={classes.progressBarSeparator}/>
            </Flex>
        </div>
    );
};

const Column = (props) => {
    const {classes, translate, day, columnRef} = props;
    const isWeekend = moment(day).isoWeekday() === 6 || moment(day).isoWeekday() === 7;

    const isToday = moment().day() === moment(day).day();
    const month = translate('global.month.' + moment(day).format('MMMM')).substr(0, 3);
    const dailyStatusBarValue = moment().hour() / moment().endOf('day').hour() * 100;
    return (
        <Flex item={'1 1 auto'} container direction={'column'} className={classes.columnContainer} style={{
            backgroundColor: isWeekend ? silver : greyHighlight
        }}>
            <div ref={columnRef} style={{
                width: '100%',
                height: '100%'
            }}>
                <div style={{
                    border: (isToday && `2px solid ` + audit),
                    width: '100%',
                    height: '100%',
                    borderRadius: 5
                }}>
                    <Typography
                        className={classes.columnTitle} style={{
                        backgroundColor: isWeekend ? silver : greyHighlight
                    }}>
                        {translate('global.day.' + moment(day).format('dddd'))}
                    </Typography>
                    {
                        isToday &&
                        <TodayProgressBar {...{
                            classes, value: dailyStatusBarValue
                        }}/>
                    }
                    <Typography className={classes.columnSubTitle} style={{
                        backgroundColor: isWeekend ? silver : greyHighlight
                    }}>
                        {translate('global.dateTimeFormats.monthAndDay', {
                            day: moment(day).date(),
                            month: month
                        })}
                    </Typography>
                </div>
            </div>
        </Flex>
    );
};

export class MonitoringColumns extends Component {

    render() {
        const {columnHeight, classes, columnRef, t: translate, days} = this.props;
        return (
            <Flex item container style={{
                height: (columnHeight + 100),
                minHeight: '67.5vh'
            }}>
                {
                    days.map(day => (
                        <Column
                            key={day}
                            {...{
                                classes,
                                translate,
                                day: day,
                                columnRef
                            }}
                        />
                    ))
                }
            </Flex>

        );
    }
}

export default withStyles(styles)(withTranslation()(MonitoringColumns));
