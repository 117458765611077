import {
    any,
    shape,
} from "prop-types"
import {OWNER, USER_GRAPHQL} from 'domain/User.model';

export const DELTA = {
    user: shape(OWNER),
    dateTime: any
};

export const DELTA_GRAPHQL = `
    dateTime
    user {${USER_GRAPHQL}}
`;
