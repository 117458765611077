export default {
    mapStateToProps: (state) => {
        return {
            task: state.taskEditReducer.task,
            actions: state.taskEditReducer.task.actions,
            applicationOnline: state.mainReducer.online,
        };
    },
    mapDispatchToProps: (dispatch) => {
        return {
        };
    }
};
