import axios from 'axios/index';
import configs from 'configs/Configs';

export const axiosClients = {
    graphql: {
        client: axios.create({
            baseURL: configs.getBackendServiceUrl() + '/graphql',
            responseType: 'json',
            headers: {
                'Content-Type': 'application/json'
            }
        })
    },
    graphqlMultipart: {
        client: axios.create({
            baseURL: configs.getBackendServiceUrl() + '/graphql',
            responseType: 'json',
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
    },
    api: {
        client: axios.create({
            baseURL: configs.getBackendServiceUrl() + '/api',
            responseType: 'json',
            headers: {
                'Content-Type': 'application/json'
            }
        })
    },
    managementApi: {
        client: axios.create({
            baseURL: configs.getBackendServiceUrl() + '/actuator',
            responseType: 'json',
            headers: {
                'Content-Type': 'application/json'
            }
        })
    },
    versionApi: {
        client: axios.create()
    },
    auth: {
        client: axios.create({
            baseURL: configs.getBackendServiceUrl() + '/auth',
            responseType: 'json',
            headers: {
                'Content-Type': 'application/json'
            }
        })
    },
    empty: {
        client: axios.create({
            baseURL: '',
            responseType: 'json',
            headers: {
                'Content-Type': 'application/json'
            }
        })
    }
};

