import React, {Component} from "react";
import {Button, Dialog, DialogActions, withStyles} from "@material-ui/core";
import {Trans, withTranslation} from "react-i18next";
import DialogContent from "@material-ui/core/DialogContent";
import Flex from "components/grid/Flex";
import Typography from "@material-ui/core/Typography";
import {htmlOrange, htmlWhite, jaffaOrange}  from 'components/colors/Colors';

export const styles = theme => ({
    dialogContainer: {
        height: 50
    },
    warningConfirmation: {
        color: htmlWhite,
        backgroundColor: htmlOrange,
        '&:hover': {
            backgroundColor: jaffaOrange,
        },
    }
});

class ConfirmationDialog extends Component {

    render() {
        const {dialogOpen, onDialogClose, onConfirm, confirmationTextKey,
            confirmationTexValues, children, classes, confirmI18NKey, cancelI18NKey,
            singleConfirmationButton, warningConfirmation, titleStyles,confirmationDisabled} = this.props;
        return (
            <Dialog
                open={dialogOpen}
            >
                <DialogContent>
                    <Flex container item grow={1} shrink={0} className={classes.dialogContainer}>
                        <Typography style={titleStyles}>
                            <Trans i18nKey={confirmationTextKey}  values={confirmationTexValues}/>
                        </Typography>
                    </Flex>
                    {children}
                </DialogContent>
                {
                    singleConfirmationButton ?
                        <DialogActions>
                            <Button onClick={() => onConfirm()}
                                    color="primary"
                                    variant="contained"
                                    autoFocus>
                                <Trans i18nKey={confirmI18NKey || "global.ok"}/>
                            </Button>
                        </DialogActions>
                        :
                        <DialogActions>
                            <Button onClick={() => onDialogClose()}
                                    color="primary"
                                    variant="contained">
                                <Trans i18nKey={cancelI18NKey || "global.no"}/>
                            </Button>
                            <Button onClick={() => onConfirm()}
                                    variant="contained"
                                    autoFocus
                                    color="primary"
                                    disabled={confirmationDisabled}
                                    classes={{
                                        root: warningConfirmation ? classes.warningConfirmation : {}
                                    }}
                            >
                                <Trans i18nKey={confirmI18NKey || "global.yes"}/>
                            </Button>
                        </DialogActions>
                }
            </Dialog>
        );
    }
}

export default withStyles(styles)(withTranslation()(ConfirmationDialog));
