import {
    string,
    shape
} from "prop-types"
import {USER, USER_GRAPHQL} from 'domain/User.model';

export const SOURCE_AUDIT = {
    type: string,
    auditor: shape(USER),
    finishedDate: string,
    auditBusinessId: string,
    answerBusinessId: string,
    questionBusinessId: string
};

export const SOURCE_AUDIT_GRAPHQL = `
    type
    auditor {${USER_GRAPHQL}}
    finishedDate
    questionBusinessId
    auditBusinessId
    answerBusinessId
    questionBusinessId
`;
