import React, {Component, Fragment} from "react";
import {withStyles} from "@material-ui/core";
import {withTranslation} from "react-i18next";
import {christiGreen, silver, guardsmanRed, htmlWhite, htmlBlack} from 'components/colors/Colors';
import classNames from "classnames";
import Flex from "components/grid/Flex";
import {documentMachineStatus} from "components/zindex/zIndex";

const sizeOfCircle = 50;
const morningShifts = [0, 1, 2];
const afternoonShifts = [3, 4, 5];

const shiftBasis = (100 / (morningShifts.length + afternoonShifts.length)) + '%';

export const styles = theme => ({
    marker: {
        width: sizeOfCircle,
        height: sizeOfCircle,
        position: 'absolute',
        color: htmlWhite,
        padding: sizeOfCircle / 5,
        transform: 'translate3d(-50%, -50%, 0)',
        borderRadius: '50%',
        border: `2px solid ${htmlBlack}`,
        textAlign: 'center',
        zIndex: documentMachineStatus,
        '& :hover': {
            cursor: 'pointer'
        }
    },
    shiftBar: {
        width: sizeOfCircle,
        height: 15,
        display: 'inline-block',
        position: 'absolute',
        border: '2px solid black',
    },
    shift: {
        display: 'inline-block',
        position: 'relative',
        zIndex: documentMachineStatus,
        margin: 1
    },
    number: {
        fontWeight: 'bold',
        fontSize: 22.5
    },
    shiftSeparator: {
        border: `1px solid ${htmlBlack}`,
    }
});

class DocumentMachineStatusMarker extends Component {

    getMarkerPosition = (area) => {
        return {
            top: `${area.center[1]}px`,
            left: `${area.center[0]}px`
        };
    };

    getShiftBarPosition = (area) => {
        const top = area.center[1] + (sizeOfCircle / 2) + 2;
        const left = area.center[0] - (sizeOfCircle / 2);
        return {
            top: `${top}px`,
            left: `${left}px`
        };
    };

    getMarkerColor = (status) => {
        switch (status) {
            case 'WORKING':
                return christiGreen;
            case 'UNKNOWN':
                return silver;
            default:
                return guardsmanRed;
        }
    };

    getShiftColor = (status) => {
        if (status) {
            return this.getMarkerColor(status);
        } else {
            return silver
        }
    };


    render() {
        const {classes, marker, onMarkerClick, applicationOnline} = this.props;
        return (
            <Fragment>
            <span key={marker.businessId} className={classes.marker}
                  style={{
                      ...this.getMarkerPosition(marker),
                      background: this.getMarkerColor(marker.status)
                  }} onClick={() => onMarkerClick(marker)}>
                            <span className={classes.number}>
                                {marker.ordinal}
                            </span>
                </span>
                {
                    applicationOnline &&
                    <Flex container item style={{
                        ...this.getShiftBarPosition(marker)
                    }}
                          className={classNames(classes.shiftBar)}
                    >
                        {
                            morningShifts
                                .map(shiftIndex => {
                                    return (

                                        <Flex key={shiftIndex} item grow={0} basis={shiftBasis}
                                              className={classNames(classes.shift)}
                                              style={{
                                                  background: this.getShiftColor(marker.history[shiftIndex])
                                              }}>
                                        </Flex>
                                    )
                                })
                        }
                        <span className={classes.shiftSeparator}/>
                        {
                            afternoonShifts
                                .map(shiftIndex => {
                                    return (

                                        <Flex key={shiftIndex} item grow={0} basis={shiftBasis}
                                              className={classNames(classes.shift)}
                                              style={{
                                                  background: this.getShiftColor(marker.history[shiftIndex])
                                              }}>
                                        </Flex>
                                    )
                                })
                        }
                    </Flex>
                }
            </Fragment>
        );
    }
}

export default withStyles(styles)(withTranslation()(DocumentMachineStatusMarker));
