import React, {Component, Fragment} from 'react';
import {
    withStyles,
    IconButton,
    Dialog,
    DialogTitle,
    DialogContent,
    Grid,
    Typography,
    DialogActions,
    Paper
} from "@material-ui/core";
import ForumIcon from '@material-ui/icons/Forum';
import DeleteIcon from '@material-ui/icons/Delete';
import NearMe from '@material-ui/icons/NearMe';
import BootstrapInput from 'components/bootstrap-input/BootstrapInput';
import _ from 'lodash';
import update from 'immutability-helper';
import classNames from 'classnames';
import moment from 'moment';
import utils from 'utils/Utils';
import {connect} from 'react-redux';
import connector from './Comment.connect';
import {Trans, withTranslation} from "react-i18next";
import UnseenNumber from 'components/unseen/UnseenNumber';
import {surfCrestPastel, alabasterWhite, frolyPink} from 'components/colors/Colors.js'

const styles = theme => ({
    iconButtonRoot: {
        padding: 2,
        fontSize: 12
    },
    dialogContainer: {
        minHeight: 300,
        minWidth: 600
    },
    mobileDialogContainer: {
        minHeight: 300,
        minWidth: '95%'
    },
    commentsContainer: {
        maxHeight: 300,
        overflowY: 'auto',
        padding: 5,
    },
    commentContainer: {
        marginTop: 8
    },
    dialogActionsRoot: {
        justifyContent: 'center',
        paddingLeft: 22
    },
    paperRoot: {
        display: 'flex',
        alignItems: 'center',
        backgroundColor: alabasterWhite,
        padding: 10,
    },
    paperRootForCurrentUser: {
        display: 'flex',
        alignItems: 'center',
        backgroundColor: surfCrestPastel,
        padding: 10,
        '&:hover': {
            backgroundColor: frolyPink
        }
    },
    justifyRight: {
        marginLeft: 'auto',
        marginRight: 10
    },
    justifyLeft: {
        marginRight: 'auto'
    },
    dialogTitleRoot: {
        fontWeight: 'bold'
    },
    dialogContentRoot: {
        overflow: 'hidden',
    },
    searchInput: {
        marginBottom: 10
    }
});

export class CommentsDialogContent extends Component {
    messagesEnd = React.createRef();

    scrollToBottom = () => {
        this.messagesEnd.current && this.messagesEnd.current.scrollIntoView({behavior: "smooth"});
    };

    componentDidMount() {
        this.scrollToBottom();
    }

    componentDidUpdate() {
        this.scrollToBottom();
    }

    render() {
        const {classes, filteredComments, user, deleteComment, t: translate} = this.props;
        return (
            <Grid container className={classes.commentsContainer}>
                {
                    filteredComments.map(comment => {
                        const isCurrentUserComment = user.businessId === _.get(comment, 'user.businessId');
                        return <Grid
                            className={classNames(classes.commentContainer, {
                                [classes.justifyRight]: isCurrentUserComment
                            })}
                            container
                            item
                            xs={10}
                            key={comment.businessId}>
                            <Grid item xs={12}>
                                <Typography>{moment(comment.creationDate).format(translate("global.dateTimeFormat"))}, {utils.formattedUserName(comment.user)}</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Paper
                                    classes={{root: isCurrentUserComment ? classes.paperRootForCurrentUser : classes.paperRoot}}>
                                    <Grid item xs={isCurrentUserComment ? 11 : 12}>
                                        <Typography>{comment.description}</Typography>
                                    </Grid>
                                    <Grid item xs={1}
                                          style={{display: isCurrentUserComment ? 'initial' : 'none'}}>
                                        <IconButton classes={{root: classes.iconButtonRoot}}
                                                    onClick={deleteComment(comment.businessId)}>
                                            <DeleteIcon/>
                                        </IconButton>
                                    </Grid>
                                </Paper>
                            </Grid>
                        </Grid>
                    })
                }
                <div id={'bottom-comment-for-scroll'} ref={this.messagesEnd}/>
            </Grid>
        );
    }
}

export class Comment extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isCommentOpen: this.props.forceOpen || false,
            addComment: '',
            comments: _.cloneDeep(props.comments) || [],
        };
    }

    openComment = event => {
        this.preventEventPropagation(event);
        this.setState({
            isCommentOpen: true
        });
        _.get(this.props, 'onOpen') && this.props.onOpen();
    };

    closeComment = event => {
        this.setState({
            isCommentOpen: false
        });
        _.get(this.props, 'onClose') && this.props.onClose();
    };

    deleteComment = commentId => event => {
        this.typedDeleteComment()(commentId)
            .then(response =>
                this.setState(oldState => {
                    const index = oldState.comments.findIndex(comment => comment.businessId === commentId);
                    if (index !== -1) {
                        return {
                            comments: update(oldState.comments, {
                                $splice: [[index, 1]]
                            })
                        };
                    }
                })
            );
    };

    handleChange = event => {
        this.setState({
            [event.target.name]: event.target.value,
        });
    };

    addComment = () => {
        if (this.state.addComment) {
            this.typedAddComment()(this.props.entityId, this.state.addComment)
                .then(response =>
                    this.setState(oldState => {
                        const commentResponse = response.payload.data.data[Object.keys(response.payload.data.data)[0]];
                        return {
                            comments: update(oldState.comments, {
                                $push: [commentResponse]
                            }),
                            addComment: ''
                        };
                    })
                );
        }
    };

    typedAddComment = () => {
        return this.props.type === 'Task' ? this.props.addTaskComment : this.props.addActionComment;
    };

    typedDeleteComment = () => {
        return this.props.type === 'Task' ? this.props.deleteTaskComment : this.props.deleteActionComment;
    };

    preventEventPropagation = event => {
        event.preventDefault();
        event.stopPropagation();
    };

    render() {
        const {classes, user, t: translate, isMobile, isUnseen, iconColor} = this.props;
        const {isCommentOpen, comments, addComment} = this.state;
        const filteredComments = _.sortBy(comments, ['creationDate']);
        return (
            <Fragment>
                <IconButton classes={{root: classes.iconButtonRoot}} onClick={this.openComment}
                style={{color: iconColor}}>
                    <ForumIcon/>
                    <UnseenNumber {...{isUnseen, number: comments.length}}/>
                </IconButton>
                <Dialog
                    open={isCommentOpen}
                    disableEscapeKeyDown
                    onClose={this.closeComment}
                    aria-labelledby="form-dialog-title-comment"
                    classes={{
                        paper: isMobile ? classes.mobileDialogContainer : classes.dialogContainer
                    }}
                    onClick={this.preventEventPropagation}
                >
                    <DialogTitle id="form-dialog-title-comment" classes={{
                        root: classes.dialogTitleRoot
                    }}>
                        <Trans i18nKey={"comment.title"}/>
                    </DialogTitle>
                    <DialogContent classes={{
                        root: classes.dialogContentRoot
                    }}>
                        <CommentsDialogContent classes={classes}
                                               filteredComments={filteredComments}
                                               user={user}
                                               t={translate}
                                               deleteComment={this.deleteComment}/>
                    </DialogContent>
                    <DialogActions classes={{
                        root: classes.dialogActionsRoot
                    }}>
                        <BootstrapInput
                            value={addComment}
                            onChange={this.handleChange}
                            onEnterKey={this.addComment}
                            name="addComment"
                            id="addComment-input"
                            autoFocus
                        />
                        <IconButton style={{padding: 0}} onClick={this.addComment}>
                            <NearMe/>
                        </IconButton>
                    </DialogActions>
                </Dialog>
            </Fragment>
        );
    }
}

export default withStyles(styles)(connect(connector.mapStateToProps, connector.mapDispatchToProps)(withTranslation()(Comment)));
