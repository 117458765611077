import _ from 'lodash';

function successfulAudits(array){
    return getByOutcome(array,"SUCCESSFUL");
}

function failedCriticalAudits(array){
    return getByOutcome(array,"FAILED_CRITICAL");
}

function failedMinorAudits(array){
    return getByOutcome(array,"FAILED_MINOR");
}

function missedAudits(array){
    return getByOutcome(array,"MISSED");
}

function getByOutcome(array, outcome){
    return _.find(array, array => array.outcome === outcome)
}

export default {
    successfulAudits,
    failedCriticalAudits,
    failedMinorAudits,
    missedAudits,
}