export const FETCH_TEMPORARY_ACTION_REFERENCE_ID = '@action_edit/FETCH_TEMPORARY_ACTION_REFERENCE_ID';
export const FETCH_TEMPORARY_ACTION_REFERENCE_ID_SUCCESS = '@action_edit/FETCH_TEMPORARY_ACTION_REFERENCE_ID_SUCCESS';
export const FETCH_TEMPORARY_ACTION_REFERENCE_ID_FAIL = '@action_edit/FETCH_TEMPORARY_ACTION_REFERENCE_ID_FAIL';
export const CREATE_ACTION = '@action_edit/CREATE_ACTION';
export const CREATE_ACTION_SUCCESS = '@action_edit/CREATE_ACTION_SUCCESS';
export const CREATE_ACTION_FAIL = '@action_edit/CREATE_ACTION_FAIL';
export const CREATE_ACTION_TO_CACHE = '@action_edit/CREATE_ACTION_TO_CACHE';
export const EDIT_ACTION = '@action_edit/EDIT_ACTION';
export const EDIT_ACTION_SUCCESS = '@action_edit/EDIT_ACTION_SUCCESS';
export const EDIT_ACTION_FAIL = '@action_edit/EDIT_ACTION_FAIL';
export const EDIT_ACTION_TO_CACHE = '@action_edit/EDIT_ACTION_TO_CACHE';
export const GET_ACTION_FROM_OPEN_TASK = '@action_edit/GET_ACTION_FROM_OPEN_TASK';
export const FETCH_TEMPORARY_ACTION_REFERENCE_ID_OFFLINE = '@action_edit/FETCH_TEMPORARY_ACTION_REFERENCE_ID_OFFLINE';
export const FETCH_USERS_FROM_CACHE = '@action_edit/FETCH_USERS_FROM_CACHE';
export const FETCH_ACTIONS_FROM_CACHE = '@action_edit/FETCH_ACTIONS_FROM_CACHE';
export const FETCH_ACTION = '@action_edit/FETCH_ACTION';
export const FETCH_ACTION_SUCCESS = '@action_edit/FETCH_ACTION_SUCCESS';
export const FETCH_ACTION_FAIL = '@action_edit/FETCH_ACTION_FAIL';
export const FETCH_ACTION_FROM_CACHE = '@action_edit/FETCH_ACTION_FROM_CACHE';

export const FETCH_PROJECT = '@action_edit/FETCH_PROJECT';