import React, {Component} from 'react';
import {IconButton, Typography, withStyles} from "@material-ui/core";
import Flex from "components/grid/Flex";
import _ from "lodash";
import {Trans, withTranslation} from "react-i18next";
import {KeyboardArrowLeftRounded, KeyboardArrowRightRounded} from '@material-ui/icons';
import {
    sanMarinoBlue,
    athensGrey,
} from "components/colors/Colors";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import {BootstrapInput} from "components/bootstrap-input/BootstrapInput";

const styles = theme => ({
    questionButtonArea: {
        display: 'flex',
        flex: '1 1 auto',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'center',
        width: '4%'
    },
    label: {
        textTransform: 'uppercase',
        color: sanMarinoBlue,
        fontSize: 12,
        fontWeight: 'bold',
        marginTop: 7
    },
    roundIcon: {
        border: `2px solid ${sanMarinoBlue}`,
        width: 28,
        height: 28,
        padding: 0,
    },
    arrow: {
        fill: sanMarinoBlue
    },
    formControl: {
        paddingTop: 10
    },
});

export class QuestionnaireQuestionPickerButtons extends Component {
    render() {
        const {
            classes, handleAddQuestions, handleRemoveQuestion, markedQuestion, markedCatalogue,
            selectableCatalogueQuestions, handleAddRandomQuestions, allowedRandomSelections, selectedRandomNumber,
            handleRandomNumberSelect, isRandomQuestionsDisabled, selectedTopic, isVdaAudit
        } = this.props;
        const options = [...Array(allowedRandomSelections)].map((x, i) => String(i));
        const isAddDisabled = isVdaAudit
            ? _.isEmpty(selectedTopic)
                || _.isEmpty(selectableCatalogueQuestions.filter(question => question.selected))
                || !_.isEmpty(selectedTopic.children)
            : _.isEmpty(selectableCatalogueQuestions.filter(question => question.selected));
        const isRemoveDisabled = _.isEmpty(markedQuestion) && _.isEmpty(markedCatalogue);
        return (
            <div className={classes.questionButtonArea}>

                <Flex container item direction={'column'} justifyContent={'center'} alignItems={'center'}>
                    <IconButton
                        className={classes.roundIcon}
                        onClick={handleAddQuestions}
                        disabled={isAddDisabled}
                        style={{marginTop: 50, opacity: isAddDisabled ? 0.3 : 1}}
                    >
                        <KeyboardArrowLeftRounded className={classes.arrow}/>
                    </IconButton>
                    <Typography classes={{root: classes.label}} style={{opacity: isAddDisabled ? 0.3 : 1}}>
                        <Trans i18nKey={"audit-administration.questionnaireEdit.add"}/>
                    </Typography>
                    {
                        !isVdaAudit &&
                        <>
                            <IconButton
                                className={classes.roundIcon}
                                onClick={handleRemoveQuestion}
                                disabled={isRemoveDisabled}
                                style={{
                                    marginTop: 20,
                                    opacity: isRemoveDisabled ? 0.3 : 1
                                }}
                            >
                                <KeyboardArrowRightRounded className={classes.arrow}/>
                            </IconButton>
                            <Typography classes={{root: classes.label}} style={{opacity: isRemoveDisabled ? 0.3 : 1}}>
                                <Trans i18nKey={"audit-administration.questionnaireEdit.remove"}/>
                            </Typography>
                        </>
                    }
                </Flex>

                {
                    !isVdaAudit && !_.isEmpty(selectableCatalogueQuestions) &&
                    <Flex container item direction={'column'} alignItems={'center'}>
                        <IconButton
                            className={classes.roundIcon}
                            onClick={handleAddRandomQuestions}
                        >
                            <KeyboardArrowLeftRounded/>
                            <span style={{
                                fontSize: 10,
                                fontWeight: 'bold',
                                left: -8,
                                position: 'relative',
                                top: 1
                            }}>R</span>
                        </IconButton>

                        {
                            !isRandomQuestionsDisabled &&
                            <FormControl variant="filled" className={classes.formControl}>
                                <Select
                                    value={selectedRandomNumber}

                                    onChange={handleRandomNumberSelect}
                                    input={<BootstrapInput name="allowedRandomSelections"
                                                           style={{
                                                               border: `1px solid ${athensGrey}`,
                                                               textAlign: 'end'
                                                           }}
                                    />}
                                >
                                    {
                                        options.map((i) =>
                                            <MenuItem key={i} value={i}
                                                      style={{
                                                          textAlign: "end"
                                                      }}
                                            >{i}</MenuItem>
                                        )

                                    }
                                </Select>
                                <Typography classes={{root: classes.label}}>
                                    <Trans i18nKey={"audit-administration.questionnaireEdit.randomly"}/>
                                </Typography>
                            </FormControl>
                        }
                    </Flex>
                }
            </div>
        )
    }
}

export default withStyles(styles)(withTranslation()(QuestionnaireQuestionPickerButtons));
