import React, { PureComponent } from 'react';
import { withStyles } from "@material-ui/core";
import GoogleIconPng from 'assets/img/google-transparent-svg.png';

const styles = theme => ({
    googleIcon: {
        width: 23,
        height: 23
    }
});

export class GoogleIcon extends PureComponent {
    render() {
        const {classes} = this.props;

        return (
            <img src={GoogleIconPng} alt={'Google'} className={classes.googleIcon}/>
        );
    }
}

export default withStyles(styles)(GoogleIcon);
