import {any, arrayOf, bool, number, shape, string} from "prop-types"
import {mapOwner, mapUser, OWNER, OWNER_GRAPHQL, USER, USER_GRAPHQL} from 'domain/User.model';
import {ACTION_BACKEND_PROPTYPE, ACTION_FOR_LISTING, ACTION_GRAPHQL, apiAction, syncActionInput} from './Action.model';
import {ATTACHMENT, ATTACHMENT_GRAPHQL, mapAttachment} from 'domain/Attachment.model';
import {COMMENT, COMMENT_GRAPHQL, mapComment} from 'domain/Comment.model';
import {
    apiTranslation,
    editableTranslation,
    emptyEditableTranslations,
    manualTranslationInput,
    offlineTranslations,
    TRANSLATION_BACKEND,
    TRANSLATION_FOR_EDIT,
    TRANSLATION_GRAPHQL
} from './Translation.model';
import {mapFreeTag, mapTag, TAG, TAG_GRAPHQL, tagInput} from 'domain/Tag.model';
import {PRIORITY, PRIORITY_GRAPHQL} from './Priority.model';
import {mapStatus, STATUS, STATUS_GRAPHQL} from 'domain/task/TaskStatus.model';
import {DELTA, DELTA_GRAPHQL} from './Delta.model';
import {SOURCE_AUDIT, SOURCE_AUDIT_GRAPHQL} from './SourceAudit.model';
import _ from 'lodash';
import utils from 'utils/Utils';
import moment from 'moment';
import {CHECKLIST_BACKEND_PROPTYPE, CHECKLIST_GRAPHQL, mapChecklist} from 'domain/task/Checklist.model';

export const ALL_VALUE = 'All';
export const DEVIATION_VALUE = 'Deviation';
export const TASK_VALUE = 'Task';
export const ACTIVE_VALUE = 'Active';
export const BACKLOG_VALUE = 'Backlog';
export const HISTORY_VALUE = 'History';
export const MY_ACTIONS_VALUE = 'My actions';

export const LIST_TASK_GRAPHQL = `
    businessId
    priority {
        ${PRIORITY_GRAPHQL}
    }
    creationDate
    dueDate
    finishedDate
    translations {
        ${TRANSLATION_GRAPHQL}
    }
    assignee {
        ${USER_GRAPHQL}
    }
    status {
        ${STATUS_GRAPHQL}
    }
    progress
    numberOfActions
    numberOfAttachments
    numberOfComments
    numberOfChecklistItems
    tags {
        ${TAG_GRAPHQL}
    }
    freeTags {
        label
    }
    projectBusinessId
    type
`;

export const LIST_TASK_WITH_ACTIONS_GRAPHQL = `
    businessId
    priority {
        ${PRIORITY_GRAPHQL}
    }
    creationDate
    dueDate
    finishedDate
    translations {
        ${TRANSLATION_GRAPHQL}
    }
    assignee {
        ${USER_GRAPHQL}
    }
    status {
        ${STATUS_GRAPHQL}
    }
    progress
    numberOfActions
    numberOfAttachments
    numberOfComments
    numberOfChecklistItems
    tags {
        ${TAG_GRAPHQL}
    }
    freeTags {
        label
    }
    projectBusinessId
    projectHierarchy {
        projectBusinessId
        projectName
        ordinal
    }
    type,
    actions {
        ${ACTION_GRAPHQL}
    }
`;

export const EDIT_TASK_GRAPHQL = `
    businessId
    priority {
        ${PRIORITY_GRAPHQL}
    }
    creationDate
    dueDate
    finishedDate
    translations {
        ${TRANSLATION_GRAPHQL}
    }
    owner {
        ${OWNER_GRAPHQL}
    }
    assignee {
        ${USER_GRAPHQL}
    }
    createdBy {
        ${USER_GRAPHQL}
    }
    status {
        ${STATUS_GRAPHQL}
    }
    progress
    numberOfActions
    numberOfAttachments
    numberOfComments
    numberOfChecklistItems
    actions {
        ${ACTION_GRAPHQL}
    }
    attachments {
        ${ATTACHMENT_GRAPHQL}
    }
    comments {
        ${COMMENT_GRAPHQL}
    }
    delta {
        ${DELTA_GRAPHQL}
    }
    tags {
        ${TAG_GRAPHQL}
    }
    freeTags {
        label
    }
    projectBusinessId
    type
    sources {
        ${SOURCE_AUDIT_GRAPHQL}
    }
    projectHierarchy {
        projectBusinessId
        projectName
        ordinal
    }
    checklist {
        ${CHECKLIST_GRAPHQL}
    }
`;

/**
 * Task received from backend, the only sanity check is on description of translations, which really has to be a string
 * @param task
 */
export function apiTask(task) {
    return {
        businessId: task.businessId,
        priority: {
            type: task.priority.type,
            ordinal: task.priority.ordinal
        },
        creationDate: task.creationDate,
        dueDate: task.dueDate,
        finishedDate: task.finishedDate,
        translations: task.translations.map(apiTranslation),
        owner: mapOwner(task.owner),
        assignee: mapUser(task.assignee),
        status: mapStatus(task.status),
        progress: task.progress,
        numberOfActions: task.numberOfActions,
        actions: task.actions && task.actions.map(apiAction),
        numberOfAttachments: task.numberOfAttachments,
        attachments: task.attachments ? task.attachments.map(mapAttachment) : [],
        numberOfComments: task.numberOfComments,
        comments: task.comments && task.comments.map(mapComment),
        delta: task.delta,
        tags: task.tags && task.tags.map(mapTag),
        ordinal: task.ordinal,
        projectBusinessId: task.projectBusinessId,
        type: task.type,
        sources: task.sources,
        numberOfChecklistItems: task.numberOfChecklistItems,
        checklist: task.checklist && task.checklist.map(mapChecklist),
        isUnseen: _.some(_.get(task, 'tags', []), ['label', 'UNSEEN_CONTENT']),
        hasUnseenAttachment: _.some(_.get(task, 'tags', []), ['label', 'UNSEEN_ATTACHMENT']),
        hasUnseenAction: _.some(_.get(task, 'tags', []), ['label', 'UNSEEN_ACTION']),
        hasUnseenComment: _.some(_.get(task, 'tags', []), ['label', 'UNSEEN_COMMENT']),
        hasUnseenCriteria: _.some(_.get(task, 'tags', []), ['label', 'UNSEEN_CHECKLIST_ITEM']),
    }
}

export function apiTaskForListing(task) {
    return {
        ...apiTask(task),
        dueDateProgress: utils.dueDateProgress(task.creationDate, task.dueDate, new Date()),
        typeId: task.type && task.type.charAt(0),
        freeTags: task.freeTags && task.freeTags.map(mapFreeTag),
    }
}

export function editableTask(task, supportedLanguages) {
    const missingTranslations = emptyEditableTranslations(supportedLanguages
        .filter(language =>
            !_.some(task.translations, translation => translation.language.code === language.code)
        ));
    const translations = task.translations.map(editableTranslation);
    let earliestSource = task.sources && task.sources.length > 0 ? task.sources[0] : null;
    return {
        ...apiTask(task),
        createdBy: task.createdBy,
        translations: translations.concat(missingTranslations),
        sourceType: earliestSource ? earliestSource.type : null,
        auditor: earliestSource ? mapUser(earliestSource.auditor) : null,
        auditTime: earliestSource ? moment.utc(earliestSource.finishedDate).format(utils.DATE_FORMAT) : null,
        dueDateProgress: utils.dueDateProgress(task.creationDate, task.dueDate, new Date()),
        isDeviation: task.type === 'DEVIATION',
        freeTags: task.freeTags && task.freeTags.map(mapFreeTag),
        projectBreadCrumb: task.projectHierarchy || [],
    }
}

export function editableEmptyTask(assignee, languages, startingWorkflowStatus) {
    return {
        translations: emptyEditableTranslations(languages),
        priority: {type: 'LOW'},
        dueDate: new Date(),
        creationDate: new Date(),
        assignee,
        owner: assignee,
        createdBy: assignee,
        status: startingWorkflowStatus,
        actions: [],
        tags: [],
        freeTags: [],
        attachments: [],
        comments: [],
        checklist: [],
        numberOfActions: 0,
        type: 'TASK',
    }
}

export function editableQuickTask(options, languages, startingWorkflowStatus) {
    return {
        ...editableEmptyTask(options.assignee, languages, startingWorkflowStatus),
        ...options
    }
}

/**
 * This one have to mimic the behavior of mapping values as mutation inputs, received by the backend and sent back
 * We also expect this to only come with translation.description instanceof EditorState
 * @param task
 */
export function offlineTask(task, forceTranslate, projectBusinessId) {
    return {
        ...apiTask(task),
        translations: offlineTranslations(task.translations, forceTranslate),
        businessId: task.businessId || ('OFFLINE-' + utils.uuid()),
        dueDate: moment.utc(task.dueDate).format(utils.API_DATE_FORMAT),
        creationDate: moment.utc(task.creationDate || (new Date())).format(utils.API_DATE_FORMAT),
        freeTags: task.freeTags,
        projectBusinessId
    }
}

export function createTaskInput(task, referenceId, projectBusinessId) {
    return {
        referenceId,
        translation: manualTranslationInput(task.translations, true),
        assigneeId: _.get(task, 'assignee.businessId'),
        priority: task.priority.type,
        dueDate: moment.utc(task.dueDate).format(utils.API_DATE_FORMAT),
        status: {
            businessId: task.status.businessId
        },
        tags: task.freeTags && task.freeTags.map(tagInput),
        projectBusinessId: projectBusinessId
    }
}

export function editTaskInput(task, forceTranslate) {
    return {
        businessId: task.businessId,
        translation: manualTranslationInput(task.translations, forceTranslate),
        assigneeId: _.get(task, 'assignee.businessId'),
        priority: task.priority.type,
        dueDate: moment.utc(task.dueDate).format(utils.API_DATE_FORMAT),
        status: {
            businessId: task.status.businessId
        },
        tags: task.freeTags && task.freeTags.map(tagInput),
        projectBusinessId: task.projectBusinessId
    }
}

export function mapSyncTaskInput(task) {
    const isNewTask = task.businessId.startsWith("OFFLINE");
    return {
        businessId: task.businessId,
        translation: manualTranslationInput(task.translations, isNewTask),
        assigneeId: _.get(task, 'assignee.businessId'),
        priority: task.priority.type,
        dueDate: moment.utc(task.dueDate).format(utils.API_DATE_FORMAT),
        status: {
            businessId: task.status.businessId,
        },
        actions: task.actions.map(syncActionInput),
        tags: task.freeTags && task.freeTags.map(tagInput),
        projectBusinessId: task.projectBusinessId
    }
}
