import React, {Component, Fragment} from "react";
import {Avatar, Dialog, DialogContent, Divider, Typography, withStyles} from "@material-ui/core";
import {withTranslation} from "react-i18next";
import Flex from "components/grid/Flex";
import _ from "lodash";
import DurationCircle from "./DurationCircle";
import {
    berylGreen,
    blueHighlight,
    christiGreen,
    criticalHighlight,
    greyHighlight,
    guardsmanRed,
    htmlGrey,
    htmlWhite,
    logoBlueLight,
    medium,
    mediumHighlight,
    random,
    transparentDownRiver025
} from "components/colors/Colors";
import {getName, getNameInitials} from "domain/User.model";
import {getColor} from "components/colors/Result";
import Tooltip from "@material-ui/core/Tooltip";
import {withRouter} from "react-router";
import {routeTo, TASK_ACTION_EDIT, TASK_EDIT} from 'routes/routes';
import H4Avatar from "components/avatar/H4Avatar";
import BlueGreenProgressBar from "components/progress-bar/BlueGreenProgressBar";
import moment from "moment";
import utils from "utils/Utils";
import {getActionBusinessId} from "domain/task/Action.model";
import classNames from 'classnames';
import MinorDropAreaImage from 'assets/img/gallery_minor.svg';
import CriticalDropAreaImage from 'assets/img/gallery_critical.svg';
import DeviationAttachmentsDialog from "./DeviationAttachmentsDialog";
import configs from "configs/Configs";

const styles = theme => ({
    root: {
        maxWidth: 'unset'
    },
    dialogContainer: {
        width: 600
    },
    avatarRoot: {
        width: 50,
        height: 50,
        fontSize: 25
    },
    circle: {
        width: 50,
        height: 50,
        borderRadius: '50%',
        border: `2px solid ${htmlWhite}`,
    },
    circleText: {
        color: htmlWhite,
        fontWeight: 'bold',
        fontSize: 20
    },
    label: {
        fontSize: 17.5
    },
    answerTileContainer: {
        maxHeight: 400,
        overflowY: 'auto',
        overflowX: 'auto',
        marginTop: 15
    },
    questionId: {
        fontWeight: 'bold',
        fontSize: 20
    },
    index: {
        color: htmlWhite,
        fontWeight: 'bold'
    },
    deviationTile: {
        backgroundColor: htmlWhite,
        margin: '5px 10px 10px 5px',
        '&:hover': {
            cursor: 'pointer'
        }
    },
    deviationId: {
        color: htmlGrey,
        fontWeight: 'bold',
        fontSize: 15,
        paddingRight: 5,
        textTransform: 'uppercase'
    },
    actionTile: {
        backgroundColor: blueHighlight,
        '&:hover': {
            cursor: 'pointer'
        }
    },
    smallAvatarRoot: {
        width: 25,
        height: 25,
        fontSize: 12.5
    },
    actionId: {
        color: htmlGrey,
        fontWeight: 'bold'
    },
    actionTitle: {
        color: htmlGrey,
    },
    dueDate: {
        fontSize: 13,
        color: htmlGrey
    },
    overflowWithWordWrap: {
        overflow: 'auto',
        maxWidth: 450,
        wordWrap: 'break-word'

    },
    dropDownImage: {
        height: 20,
        width: 20,
        paddingRight: 5,
        '&:hover': {
            cursor: 'pointer'
        }
    }
});

const AuditTile = (props) => {
    const {audit, classes, getTileColor, translate} = props;
    return (
        <Flex container alignItems={'center'}
              style={{
                  width: '100%',
                  backgroundColor: getTileColor(audit),
                  borderRadius: 4,
                  boxShadow: `0px 1px 2px 0px ${transparentDownRiver025}`

              }}>
            <Flex item grow={0} style={{
                marginLeft: -20
            }}>
                <DurationCircle {...{audit, circeClass: classes.circle, textClass: classes.circleText}}/>
            </Flex>
            <Flex container item grow={1} alignItems={'center'} style={{paddingLeft: 15}}>
                <Flex container item direction={'column'} grow={0}>
                    <Typography
                        className={classes.overflowWithWordWrap}
                        style={{
                            fontWeight: 'bold'
                        }}>
                        {audit.title}
                    </Typography>
                    <Typography>
                        {audit.organizationLevel.name}
                    </Typography>
                </Flex>
                <Flex container item direction={'row'} justifyContent={'center'} alignItems={'center'} grow={1}>
                    <Typography
                        className={classes.label}
                        style={{
                            fontWeight: 'bold',
                            color: audit.numberOfFailedQuestions === 0 ? htmlGrey : getColor(audit.result),
                        }}>
                        {audit.numberOfFailedQuestions}
                    </Typography>
                    <Typography
                        className={classes.label}
                        style={{
                            color: htmlGrey
                        }}>
                        &nbsp;/&nbsp;
                        {audit.numberOfQuestions}
                    </Typography>
                </Flex>
                <Flex container item justifyContent={'flex-end'} alignItems={'center'} grow={0}>
                    <Flex container item direction={'column'} grow={0} style={{
                        paddingLeft: 10,
                        paddingRight: 10
                    }}>
                        <Typography className={classes.label}>
                            {
                                _.isEmpty(audit.finishedDate) ? '' : translate('global.dateTimeFormats.monthAndDay', {
                                    month: translate('global.month.' + audit.finishedDate.format("MMMM")).substring(0, 3),
                                    day: audit.finishedDate.format("DD")
                                })
                            }
                        </Typography>
                    </Flex>
                    <Flex container item direction={'column'} grow={0} style={{
                        paddingLeft: 10,
                        paddingRight: 10
                    }}>
                        {
                            _.isEmpty(audit.auditor) || !_.get(audit.auditor, 'avatar.downloadUrl') ?
                                <Tooltip
                                    title={_.isEmpty(audit.auditor) ? translate('audit-administration.monitoringView.random') : getName(audit.auditor)}>
                                    <Avatar
                                        classes={{root: classes.avatarRoot}}
                                        style={{
                                            backgroundColor: logoBlueLight,
                                            color: _.isEmpty(audit.auditor) ? random : htmlWhite
                                        }}
                                    >
                                        {_.isEmpty(audit.auditor) ? '?' : getNameInitials(audit.auditor)}
                                    </Avatar>
                                </Tooltip>
                                :
                                <Tooltip title={getName(audit.auditor)}>
                                    <Avatar
                                        classes={{root: classes.avatarRoot}}
                                        src={audit.auditor.avatar.downloadUrl}
                                    />
                                </Tooltip>
                        }
                    </Flex>
                </Flex>
            </Flex>
        </Flex>
    )
};

const DeviationTile = (props) => {
    const {deviation, classes, i18n, getDeviationColor, history, location, translate, openAttachment} = props;
    const deviationTitle = _.get(deviation.translations
        .find(translation => translation.languageCode === i18n.language), 'title', '');
    return (
        <Fragment>
            <Flex container item alignItems={"center"}>
                {
                    deviation.attachments.length > 0 &&
                    <Fragment>
                        <img
                            src={deviation.priority === 'MINOR' ? MinorDropAreaImage : CriticalDropAreaImage}
                            alt={''}
                            className={classes.dropDownImage}
                            onClick={openAttachment(deviation)}
                        />
                        <Typography>{'(' + deviation.attachments.length + ')'}</Typography>
                    </Fragment>
                }
                <Flex container item alignItems={'center'} justifyContent={'space-between'}
                      className={classes.deviationTile}
                      onClick={
                          () => {
                              routeTo(TASK_EDIT.pathWithId(deviation.businessId), {location, history});
                          }
                      }>
                    <Flex container item grow={1} alignItems={'center'} style={{}}>
                        <div style={{
                            width: 5,
                            height: 40,
                            backgroundColor: getDeviationColor(deviation)
                        }}/>
                        <Typography className={classes.overflowWithWordWrap}
                                    style={{
                                        color: getDeviationColor(deviation),
                                        paddingLeft: 10
                                    }}>
                            {deviationTitle}
                        </Typography>
                    </Flex>
                    <Typography className={classes.deviationId}>
                        {configs.getDeviationPrefix() + '-' + deviation.businessId}
                    </Typography>
                </Flex>
            </Flex>
            {
                deviation.actions.map((action, index) => {
                    return (
                        <ActionTile key={index} {...{classes, history, location, action, i18n, deviation, translate}} />
                    );
                })
            }
        </Fragment>
    );
};

const ActionTile = (props) => {
    const {deviation, action, classes, i18n, history, location, translate} = props;
    const title = _.get(action.translations
        .find(translation => translation.languageCode === i18n.language), 'title', '');
    const actionBusinessId = getActionBusinessId(action, translate);
    return (
        <Flex container item alignItems={'center'} justifyContent={'space-between'}
              className={classes.actionTile}
              style={{
                  margin: deviation.attachments.length > 0 ? '5px 10px 10px 50px' : '5px 10px 10px 5px'
              }}
              onClick={
                  () => {
                      routeTo(TASK_ACTION_EDIT.pathWithIds(deviation.businessId, action.businessId), {
                          location,
                          history
                      });
                  }
              }>
            <Flex container item justifyContent={'flex-start'}>
                <Flex item container alignItems={'center'} grow={0} style={{
                    paddingLeft: 10
                }}>
                    <H4Avatar {...{user: action.assignee}} style={{marginLeft: 0}}/>
                </Flex>
                <Flex item container alignItems={'flex-start'} direction={'column'} style={{
                    paddingLeft: 10
                }}>
                    <Typography className={classes.actionId}>
                        {actionBusinessId}
                    </Typography>
                    <Typography className={classNames(classes.actionTitle, classes.overflowWithWordWrap)}>
                        {title}
                    </Typography>
                </Flex>
            </Flex>
            <Flex item container alignItems={'flex-end'} direction={'column'} grow={0} style={{
                width: 80,
                paddingRight: 10
            }}>
                <BlueGreenProgressBar value={action.progress}
                                      style={{width: '100%', height: 8, marginBottom: 3}}/>
                <Flex item container direction={'column'} alignItems={'center'}>
                    <Flex item>
                        <Typography className={classes.dueDate}>
                            {moment(action.dueDate).format(utils.DATE_FORMAT)}
                        </Typography>
                    </Flex>
                </Flex>
            </Flex>
        </Flex>
    )
};

const AnswerTile = (props) => {
    const {classes, i18n, answer, index, getAnswerTileColor, getAnswerTileBorderColor, deviation, getDeviationColor, history, location, translate, openAttachment} = props;
    const translation = answer.question.translations
        .find(translation => translation.languageCode === i18n.language);
    const comment = _.get(translation, 'comment', '');
    return (
        <Flex item container direction={'column'} grow={0}
              style={{
                  paddingBottom: 10
              }}
        >
            <Flex container item grow={0} justifyContent={'center'} alignItems={'center'}
                  style={{
                      height: 30,
                      backgroundColor: getAnswerTileColor(answer),
                      border: `1px solid ${(getAnswerTileBorderColor(answer))}`
                  }}>
                <Typography className={classes.index}>
                    {index + 1}
                </Typography>
            </Flex>
            <Flex item container justifyContent={'space-between'} grow={0} style={{
                borderColor: getAnswerTileBorderColor(answer),
                borderStyle: 'solid',
                borderWidth: '0px 1px 1px 1px',
                backgroundColor: greyHighlight
            }}>
                <Flex container item basis={'10%'} justifyContent={'center'}
                      alignItems={'center'}
                >
                    <Typography className={classes.questionId}>
                        {answer.question.businessId}
                    </Typography>
                </Flex>
                <Flex container item basis={'90%'} direction={"column"}>
                    <Flex container item alignItems={'center'} style={{
                        padding: 5
                    }}>
                        <Typography className={classes.overflowWithWordWrap}>
                            {comment}
                        </Typography>
                    </Flex>
                    {
                        deviation &&
                        <DeviationTile {...{
                            classes,
                            deviation,
                            i18n,
                            getDeviationColor,
                            history,
                            location,
                            translate,
                            openAttachment
                        }}/>
                    }
                </Flex>
            </Flex>
        </Flex>
    );
};

export class AuditDetailsDialog extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isAttachmentOpen: false,
            selectedDeviation: {}
        };
    }

    getAnswerTileBorderColor = (answer) => {
        return _.isEmpty(answer.severity) ? christiGreen : answer.severity === 'MINOR' ? medium : guardsmanRed;
    };

    getAnswerTileColor = (answer) => {
        return _.isEmpty(answer.severity) ? berylGreen : answer.severity === 'MINOR' ? mediumHighlight : criticalHighlight;
    };

    getDeviationColor = (deviation) => {
        return deviation.priority === 'MINOR' ? medium : guardsmanRed;
    };

    preventEventPropagation = event => {
        event.preventDefault();
        event.stopPropagation();
    };

    openAttachment = (deviation) => event => {
        this.preventEventPropagation(event);
        this.setState({
            isAttachmentOpen: true,
            selectedDeviation: deviation
        });
        _.get(this.props, 'onOpen') && this.props.onOpen();
    };

    closeAttachment = event => {
        this.setState({
            isAttachmentOpen: false
        });
        _.get(this.props, 'onClose') && this.props.onClose();
    };

    getDeviationsByQuestionsId =(deviations) => {
        const deviationsByQuestionsId = new Map();
        deviations.forEach(deviation => {
            deviation.questionBusinessIds.forEach(questionBusinessId => {
                deviationsByQuestionsId.set(questionBusinessId, deviation)
            });
        });
        return deviationsByQuestionsId;
    };

    render() {
        const {classes, dialogOpen, closeDialog, t: translate, audit, getTileColor, i18n, deviations, history, location} = this.props;
        const {isAttachmentOpen, selectedDeviation} = this.state;
        const {getAnswerTileBorderColor, getAnswerTileColor, getDeviationColor, preventEventPropagation, openAttachment, closeAttachment, getDeviationsByQuestionsId} = this;
        return (
            <Fragment>
                <DeviationAttachmentsDialog
                    {...{
                        classes,
                        isMobile: utils.checkBrowser(),
                        isAttachmentOpen,
                        allFiles: selectedDeviation.attachments,
                        openAttachment,
                        closeAttachment,
                        preventEventPropagation
                    }}/>
                <Dialog
                    open={dialogOpen}
                    classes={{root: classes.root}}
                    maxWidth={'lg'}
                    onClose={closeDialog}
                >
                    <DialogContent classes={{
                        root: classes.dialogContainer
                    }}>
                        <Flex container item style={{
                            paddingBottom: 15
                        }}>
                            <AuditTile {...{classes, audit, getTileColor, translate}}/>
                        </Flex>
                        <Divider style={{
                            margin: 0
                        }}/>
                        <Flex container direction={'column'} className={classes.answerTileContainer}>
                            {
                                audit.answers.map((answer, index) => {
                                    return (
                                        <AnswerTile key={index} {...{
                                            classes,
                                            i18n,
                                            answer,
                                            index,
                                            getAnswerTileBorderColor,
                                            getAnswerTileColor,
                                            getDeviationColor,
                                            deviation: getDeviationsByQuestionsId(deviations).get(answer.question.businessId),
                                            history,
                                            location,
                                            translate,
                                            openAttachment
                                        }}
                                        />
                                    );
                                })
                            }
                        </Flex>
                    </DialogContent>
                </Dialog>
            </Fragment>
        );
    }
}

export default withStyles(styles)(withTranslation()(withRouter(AuditDetailsDialog)));
