import * as actionTypes from "scenes/configuration/GeneralConfiguration.action.types";
import query from 'store/GraphqlQueries.js';

export function createQuestionCatalogue(name) {
    return query.graphql({
        type: actionTypes.ADD_QUESTION_CATEGORY_DATA,
        caller: 'createQuestionCatalogue',
        query: `
            mutation
            ($label: String!){
                createQuestionCatalogue(label: $label) {
                    businessId
                    label
                }
            }
           `,
        variables: {
            label: name
        }
    });
}

export function removeQuestionCatalogue(businessId, confirmed) {
    return query.graphql({
        type: actionTypes.REMOVE_QUESTION_CATEGORY_DATA,
        caller: 'removeQuestionCatalogue',
        data: {
            businessId: businessId
        },
        query: `
            mutation
            ($businessId: String!, $force: Boolean){
                removeQuestionCatalogue(businessId: $businessId, force: $force) {
                    success {
                        businessId
                    }
                    error {
                        assignedQuestionnaires {
                            businessId
                            name
                        }
                    }
                }
            }
           `,
        variables: {
            businessId: businessId,
            force: confirmed || false
        }
    });
}

export function resetToBeConfirmedAssignedQuestionnaires() {
    return {type: actionTypes.RESET_TO_BE_CONFIRMED_QUESTIONNAIRES}
}