import React, { Component } from 'react';
import { CircularProgress } from "@material-ui/core";
import Flex from 'components/grid/Flex';

export class PageIsLoading extends Component {
    render() {
        return (
            <Flex item container alignItems={'center'} justifyContent={'center'} style={{height: '100%', width: '100%'}}>
                <CircularProgress/>
            </Flex>
        );
    }
}

export default PageIsLoading;
