import {mapUser} from "domain/User.model";
import _ from 'lodash';
import moment from "moment";
import utils from 'utils/Utils';

export const ACTIVE = 'ACTIVE';
export const INACTIVE = 'INACTIVE';
export const EXPIRED = 'EXPIRED';

export const RANDOM = 'RANDOM';

export const DURATION_UNITS = ["DAY", "WEEK", "MONTH", "QUARTER", "HALF_YEAR", "YEAR"];
export const SCHEDULE_STATUSES = ['ACTIVE', 'INACTIVE', 'EXPIRED'];

export function isActive(schedule) {
    return schedule.status === ACTIVE;
}

export function isExpired(schedule) {
    return schedule.status === EXPIRED;
}

function hasQuestions(schedule) {
    return (_.get(schedule, 'questionnaire.numberOfQuestions', 0) +
        _.get(schedule, 'questionnaire.numberOfRandomizedQuestions', 0)) > 0;
}

export function mapSchedulesForListing(schedules) {
    return schedules.map(schedule => {
        let shiftBusinessId = _.get(schedule, 'shift.businessId');
        let duration = shiftBusinessId ? 'SHIFT' : schedule.duration;
        return {
            businessId: schedule.businessId,
            name: schedule.name,
            type: schedule.type.label,
            nextSchedule: moment.utc(schedule.nextSchedule),
            duration: duration,
            frequency: mapReocurrence(schedule.reoccurance),
            auditor: mapUser(schedule.auditor),
            status: schedule.status,
            active: isActive(schedule),
            lastOccurrence: _.isEmpty(schedule.lastOccurrence) ? null : moment.utc(schedule.lastOccurrence),
            isExpired: schedule.status === EXPIRED,
            questionnaireName: schedule.questionnaire.name,
            haveActiveQuestionnaire: schedule.questionnaire.status === 'APPROVED',
            haveQuestions: hasQuestions(schedule),
            shift: schedule.shift,
        }
    })
}

export function emptySchedule(user, selectedOrganizationLevel) {
    const userId = _.get(user, 'businessId', 'RANDOM');
    const userIsAuditor = _.get(selectedOrganizationLevel, 'members', [])
        .find(member => _.get(member, 'user.businessId') === userId && member.memberships.includes("AUDITOR"));
    return {
        businessId: '',
        type: '',
        questionnaireBusinessId: '',
        questionnaireName: '',
        name: '',
        resultDefinitionId: '',
        auditorId: _.isEmpty(userIsAuditor) ? 'RANDOM' : userId,
        duration: 'DAY',
        nextSchedule: moment(),
        frequency: {
            unit: 'DAY',
            amount: 1
        },
        status: ACTIVE,
        shift: undefined
    }
}

export function mapSchedule(schedule) {
    const shiftBusinessId = _.get(schedule, 'shift.businessId');
    const duration = shiftBusinessId ? 'SHIFT' : schedule.duration;
    return {
        businessId: schedule.businessId,
        type: schedule.type.label,
        questionnaireBusinessId: schedule.questionnaire.businessId,
        questionnaireName: schedule.questionnaire.name,
        haveActiveQuestionnaire: schedule.questionnaire.status === 'APPROVED',
        name: schedule.name,
        duration: duration,
        status: schedule.status,
        resultDefinitionId: schedule.resultDefinition.businessId,
        auditorId: _.get(schedule.auditor, 'businessId', RANDOM),
        nextSchedule: schedule.nextSchedule,
        frequency: mapReocurrence(schedule.reoccurance),
        levelBusinessId: schedule.organizationLevel.businessId,
        isExpired: schedule.status === EXPIRED,
        haveQuestions: hasQuestions(schedule),
        shift: schedule.shift
    }
}

export function mapCloneSchedule(schedule) {
    return {
        ...schedule,
        name: '',
        businessId: '',
        nextSchedule: moment(),
        status: ACTIVE,
        isExpired: false
    }
}

export function createScheduleInput(data) {
    const duration = mapDuration(data.duration);
    return {
        type: data.type,
        name: data.name,
        questionnaireId: data.questionnaireId,
        duration: duration,
        resultDefinitionId: data.resultDefinitionId,
        auditorId: data.auditorId,
        status: data.status,
        nextSchedule: mapNextSchedule(data.nextSchedule, duration),
        organizationLevelId: data.organizationLevelId,
        reoccurance: data.frequency ? {
            unit: data.frequency.unit,
            amount: data.frequency.amount
        } : null,
        shiftBusinessId: data.duration === 'SHIFT' ? _.get(data, 'shift.businessId') : null
    }
}

export function editScheduleInput(data) {
    return {
        ...createScheduleInput(data),
        businessId: data.businessId
    };
}

function mapReocurrence(reocurrence) {
    if (_.isEmpty(reocurrence)) {
        return {}
    } else {
        return {
            unit: reocurrence.unit,
            amount: reocurrence.amount
        }
    }
}

export function mapNextSchedule(nextSchedule, duration) {
    switch (duration) {
        case 'DAY':
            return moment(nextSchedule).format(utils.API_LOCAL_DATE_FORMAT);
        case 'WEEK':
            return moment(nextSchedule).startOf('isoWeek').format(utils.API_LOCAL_DATE_FORMAT);
        case 'MONTH':
            return moment(nextSchedule).startOf('month').format(utils.API_LOCAL_DATE_FORMAT);
        case 'QUARTER':
            return moment(nextSchedule).startOf('quarter').format(utils.API_LOCAL_DATE_FORMAT);
        case 'HALF_YEAR':
            const quarter = moment(nextSchedule).quarter();
            if (quarter === 2 || quarter === 3) {
                return moment(nextSchedule).add(-1, 'quarter').startOf('quarter').format(utils.API_LOCAL_DATE_FORMAT)
            } else {
                return moment(nextSchedule).startOf('quarter').format(utils.API_LOCAL_DATE_FORMAT)
            }
        case 'YEAR':
            return moment(nextSchedule).startOf('year').format(utils.API_LOCAL_DATE_FORMAT);
        default:
            throw new Error('Not supported duration: ' + duration);
    }
}

export function getMinDate(duration) {
    switch (duration) {
        case 'DAY':
            return moment().startOf("day");
        case 'YEAR':
            return moment().startOf("year");
        case 'MONTH':
            return moment().startOf("month");
        case 'WEEK':
            return moment().startOf("isoWeek");
        default:
            return moment();
    }
}

export function mapNextScheduleInput(duration, frequency, startDay, endDay, oneTimeOnly) {
    return {
        duration: mapDuration(duration),
        reoccurance: oneTimeOnly ? null : {
            unit: frequency.unit,
            amount: frequency.amount
        },
        nextSchedule: moment(startDay).format(utils.API_LOCAL_DATE_FORMAT),
        latestDate: moment(endDay).format(utils.API_LOCAL_DATE_FORMAT),
    }
}

export function mapDuration(duration) {
    return duration === 'SHIFT' ? 'DAY' : duration;
}

