import React, {PureComponent} from 'react';
import {
    withStyles,
    Dialog,
    IconButton,
    Typography
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import {isImage, getIcon} from 'components/attachment/FileIcons';
import utils from 'utils/Utils';
import CloudDownload from '@material-ui/icons/CloudDownload'
import Flex from 'components/grid/Flex';
import {darkGreyHighlight, htmlGrey, transparentWhite05, transparentWhite08} from 'components/colors/Colors';
import InlineConfirmation from 'components/confirmation-dialog/InlineConfirmation';

const rectangleSize = 150;

const styles = theme => ({
    galleryImg: {
        margin: 5,
        objectFit: 'cover',
    },
    tile: {
        position: 'relative'
    },
    iconContainer: {
        position: 'absolute',
        bottom: 42,
        right: 6
    },
    iconButton: {
        backgroundColor: transparentWhite05,
        padding: 8
    },
    iconButtonRoot: {
        '&:hover': {
            backgroundColor: transparentWhite08,
        }
    },
    fullImage: {
        objectFit: 'contain',
        '@media (min-width: 300px)': {
            width: 280
        },
        '@media (min-width: 600px)': {
            width: 550
        },
        '@media (min-width: 800px)': {
            width: 750
        },
        '@media (min-width: 1500px)': {
            width: 1450
        },
    },
    fileInfo: {
        fontStyle: 'italic',
        color: htmlGrey,
        fontSize: 12,
        marginLeft: 4,
        borderBottom: `solid 1px ${darkGreyHighlight}`,
        height: 36,
        overflowWrap: 'break-word'
    }
});

export class FileGalleryItem extends PureComponent {
    state = {
        showImage: false
    };

    toggleFullImage = () => {
        this.setState({
            showImage: !this.state.showImage
        })
    };

    render() {
        const {classes, filename, index, downloadUrl, deleteFile, imageSize = rectangleSize, readOnly} = this.props;
        return (
            <Flex item={'0 0 auto'} container key={index} className={classes.tile}>
                <Flex container item={'0 0 auto'} direction={'column'}>
                    {
                        isImage(filename)
                            ?
                            <img src={downloadUrl} alt={index} className={classes.galleryImg}
                                 style={{
                                     height: imageSize,
                                     width: imageSize,
                                 }}
                                 onClick={this.toggleFullImage}/>
                            :
                            <img className={classes.galleryImg} style={{
                                height: imageSize,
                                width: imageSize,
                            }} src={getIcon(filename)} alt={''}/>
                    }

                    <Flex container className={classes.iconContainer}
                          style={{
                              width: imageSize
                          }}
                          justifyContent={!readOnly ? 'space-between' : 'flex-end'}>
                        {
                            !readOnly &&
                            <InlineConfirmation {...{
                                onConfirm: deleteFile,
                            }}>
                                <IconButton className={classes.iconButton}
                                            classes={{root: classes.iconButtonRoot}}>
                                    <DeleteIcon/>
                                </IconButton>
                            </InlineConfirmation>
                        }
                        <Flex item={'0 0 auto'} container alignItems={'flex-end'}>
                            <IconButton
                                onClick={() => downloadUrl && utils.triggerNativeDownload(downloadUrl, filename)}
                                classes={{root: classes.iconButtonRoot}}
                                className={classes.iconButton}
                            >
                                <CloudDownload/>
                            </IconButton>
                        </Flex>
                    </Flex>
                    <Typography className={classes.fileInfo} style={{
                        width: imageSize,
                    }}>
                        {filename}
                    </Typography>
                </Flex>

                <Dialog
                    open={this.state.showImage}
                    maxWidth={false}
                    style={{overflow: 'none'}}
                    onClose={this.toggleFullImage}
                >
                    <img
                        onClick={this.toggleFullImage}
                        className={classes.fullImage}
                        src={downloadUrl}
                        alt={''}
                    />
                </Dialog>
            </Flex>
        );
    }
}

export default withStyles(styles)(FileGalleryItem);
