import React, {Component, Fragment} from 'react';
import {
    withStyles,
    Typography,
} from "@material-ui/core";
import Flex from 'components/grid/Flex';
import {mobileTaskListMenu} from 'components/zindex/zIndex';
import {mobileStatusHeader, htmlGrey} from 'components/colors/Colors';
import SwipeAbleTask from 'scenes/tasks/components/mobile/SwipeAbleTask';
import QuickTaskCreate from 'scenes/tasks/components/mobile/QuickTaskCreate';

const styles = theme => ({
    rootContainer: {
        position: 'absolute',
        top: 0,
        bottom: 0,
        right: 0,
        left: 0,
        marginTop: 54,
        paddingLeft: 10,
        paddingRight: 10
    },
    taskTileAbsoluteContainer: {
        overflow: 'auto',
        overflowScrolling: 'touch',
        position: 'absolute',
        top: 0,
        bottom: 0,
        right: 0,
        left: 0,
    },
    pdcaStatusItem: {
        backgroundColor: mobileStatusHeader,
        padding: 4,
        fontWeight: 'bold',
        position: 'sticky',
        width: '100%',
        top: 0,
        zIndex: mobileTaskListMenu,
        marginBottom: 5
    },
    pdcaStatusItemIcon: {
        width: 20,
        height: 20,
        marginRight: 6,
        marginLeft: 6,
        fill: htmlGrey
    },
});

const Tasks = (props) => {
    const {
        classes, tasks, applicationOnline, statusLabel, swipedTask, handleTaskSwipe, archive, toggleKanban,
        view, user
    } = props;
    const pdcaFilteredTasks = tasks.filter(task => task.status.label === statusLabel);
    return (
        <div>
            <Flex container item={'0 1 auto'} className={classes.pdcaStatusItem} justifyContent={'center'}>
                <Typography style={{fontWeight: 'bold', fontSize: 17}}>{statusLabel}</Typography>
            </Flex>
            {
                pdcaFilteredTasks.map((task, index) => (
                    <SwipeAbleTask {...{
                        key: task.businessId,
                        index: swipedTask === task.businessId ? 1 : 0,
                        task, handleTaskSwipe, archive, toggleKanban, view, applicationOnline, user
                    }}/>
                ))
            }
        </div>
    )
};

export class MobileKanban extends Component {
    render() {
        const {
            classes, searchText, createQuickTask, handleChange, filteredTasks, applicationOnline,
            swipedTask, handleTaskSwipe, archive, toggleKanban, view, user, activeWorkflowStatuses
        } = this.props;

        return (
            <Flex container item direction={'column'} className={classes.rootContainer}>

                <QuickTaskCreate {...{searchText, createQuickTask, handleChange}}/>

                <Flex container item direction={'column'} style={{position: 'relative', padding: 10}}>
                    <div className={classes.taskTileAbsoluteContainer}>
                        {
                            activeWorkflowStatuses.map((status, index) => (
                                <Fragment key={status.label}>
                                    <Tasks {...{
                                        classes,
                                        tasks: filteredTasks,
                                        statusLabel: status.label,
                                        applicationOnline,
                                        swipedTask, handleTaskSwipe, archive, toggleKanban, view, user
                                    }} />
                                    {
                                        index === 0 &&
                                        <div id={'bottom-task-for-scroll'} ref={this.bottom}/>
                                    }
                                </Fragment>
                            ))
                        }
                    </div>
                </Flex>
            </Flex>
        );
    }
}

export default withStyles(styles)(MobileKanban);
