import React, {Component} from 'react';
import {MuiThemeProvider, createMuiTheme} from '@material-ui/core/styles';
import {htmlWhite} from 'components/colors/Colors.js'

const h4Theme = createMuiTheme({
    palette: {
        background: {
            default: htmlWhite
        }
    },
    overrides: {
        MuiTypography: {
            root: {
                cursor: 'default'
            },
            body1: {
                fontSize: '0.875rem'
            }
        }
    }
});

class H4Theme extends Component {
    render() {
        return (
            <MuiThemeProvider theme={h4Theme}>
                {this.props.children}
            </MuiThemeProvider>
        );
    }
}

export default H4Theme;
