import React, {Component, Fragment} from 'react';
import {IconButton, Typography, withStyles} from "@material-ui/core";
import {logoBlueLight, htmlWhite, transparentBlack02, materialUIBorder, mercuryGrey} from 'components/colors/Colors';
import ReactTags from "react-tag-autocomplete";
import {taskEditTagSuggestions} from 'components/zindex/zIndex';
import {CloseOutlined as CloseIcon} from '@material-ui/icons';

const styles = theme => ({
    root: {
        position: 'relative',
        borderRadius: 4,
        padding: 6,
        display: 'flex',
        backgroundColor: htmlWhite
    },
    tagSearch: {
        position: 'relative',
        display: 'inline-box'
    },
    tagSearchInput: {
        margin: 2,
        '& input': {
            border: `1px dashed ${logoBlueLight}`,
            padding: 3,
            borderRadius: 7,
            outline: 'none',
            boxSizing: 'unset'
        }
    },
    tagIcon: {
        fill: 'inherit',
        fontSize: 'inherit'
    },
    selected: {
        display: 'flex',
        flexWrap: 'wrap'
    },
    selectedTag: {
        backgroundColor: logoBlueLight,
        borderRadius: 7,
        color: htmlWhite,
        margin: 2,
        fontSize: 12,
        fontWeight: 'bold',
        textTransform: 'capitalize',
        display: 'flex',
        alignItems: 'center',
        padding: '1px 7px 2px',
    },
    selectedTagName: {
        color: 'inherit',
        fontWeight: 'inherit',
        fontSize: 'inherit',
        marginRight: 4,
    },
    tagSuggestions: {
        zIndex: taskEditTagSuggestions,
        position: 'absolute',
        background: htmlWhite,
        width: 240,
        fontSize: 14,
        textTransform: 'uppercase',
        '& ul': {
            margin: '4px -1px',
            padding: 7,
            listStyle: 'none',
            background: htmlWhite,
            border: `1px solid ${materialUIBorder}`,
            borderRadius: 4,
            boxShadow: `0 2px 6px ${transparentBlack02}`
        },
        '& li': {
            borderBottom: `1px solid ${mercuryGrey}`,
            padding: '6px 8px',
            '& mark': {
                textDecoration: 'underline',
                background: 'none',
                fontWeight: 'bold',
            }
        }
    }
});

const Tag = (props) => {
    return (
        <Fragment>
            <div className={props.classNames.selectedTag}>
                <Typography className={props.classNames.selectedTagName}>
                    {props.tag.name}
                </Typography>
                <IconButton
                    onClick={props.onDelete}
                    size="small"
                    style={{color: 'inherit', fontSize: 'inherit', padding: 1}}>
                    <CloseIcon style={{color: 'inherit', fontSize: 'inherit'}}/>
                </IconButton>
            </div>
        </Fragment>
    )
};

export class TagInput extends Component {
    render() {
        const {
            classes, tags, suggestions, onDelete, onAddition, isNewAllowed, placeholder, disabled
        } = this.props;
        return (
            <ReactTags
                classNames={{
                    root: classes.root,
                    selected: classes.selected,
                    selectedTag: classes.selectedTag,
                    selectedTagName: classes.selectedTagName,
                    searchInput: classes.tagSearchInput,
                    suggestions: classes.tagSuggestions,
                }}
                placeholder={placeholder}
                autofocus={false}
                inputAttributes={{
                    disabled: disabled,
                    id: 'relatedTo'
                }}
                allowNew={isNewAllowed}
                tags={tags}
                tagComponent={Tag}
                suggestions={suggestions}
                handleDelete={onDelete}
                handleAddition={onAddition}/>
        )
    }
}

export default withStyles(styles)(TagInput);
