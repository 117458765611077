import React, {Component} from 'react';
import {IconButton, Tooltip, withStyles} from "@material-ui/core";
import {withTranslation} from "react-i18next";
import Flex from "components/grid/Flex";
import {
    auditNotificationSent,
    htmlWhite,
    logoBlueLight,
    random,
    christiGreen,
    htmlGreen
} from "components/colors/Colors";
import H4Avatar from "components/avatar/H4Avatar";
import _ from "lodash";
import utils from "utils/Utils";
import NotificationsNone from '@material-ui/icons/NotificationsNone';
import NotificationsActive from '@material-ui/icons/NotificationsActive';
import dateTimeUtils from "utils/DateTime.utils";

const styles = theme => ({
    checkBoxIcon: {
        fill: christiGreen
    },
    avatarRoot: {
        width: 25,
        height: 25,
        fontSize: 12
    },
    clickableAvatarRoot: {
        width: 25,
        height: 25,
        fontSize: 12,
        '&:hover': {
            cursor: 'pointer'
        }
    },
    snackbar: {
        paddingBottom: 10,
    },
    snackbarContent: {
        backgroundColor: htmlGreen,
        justifyContent: 'center'
    },
    avatarTooltipLabel: {
        fontSize: 10
    }
});

export class AuditTileIcons extends Component {

    render() {
        const {audit, classes, openAuditorUpdateDialog, isAuditorChangeAllowed, sendAuditReminder, t: translate} = this.props;
        const lastReminder = audit.lastReminder;
        const auditor = audit.auditor || {};
        const tooltip = _.isEmpty(audit.auditor)
            ? translate('audit-administration.monitoringView.random')
            : utils.formattedUserName(audit.auditor);

        return (
            <Flex container item direction={'column'} grow={0} alignContent={'center'}
                  style={{
                      paddingLeft: 5,
                      paddingRight: 5
                  }}>
                <Tooltip title={tooltip} placement={'right'} classes={{tooltip: classes.avatarTooltipLabel}}>
                    {
                        <H4Avatar
                            {...{user: auditor}}
                            onClick={openAuditorUpdateDialog(audit)}
                            classes={{avatarRoot: isAuditorChangeAllowed(audit) ? classes.clickableAvatarRoot : classes.avatarRoot}}
                            style={{
                                backgroundColor: logoBlueLight,
                                color: _.isEmpty(audit.auditor) ? random : htmlWhite
                            }}
                        >
                        </H4Avatar>
                    }
                </Tooltip>
                {
                    audit.ready &&
                    <IconButton style={{padding: 0}} onClick={() => {
                        sendAuditReminder(audit);
                    }}>
                        {
                            !_.isEmpty(lastReminder) ?
                                <Tooltip
                                    title={translate('audit-administration.monitoringView.lastReminder', {
                                        givenName: lastReminder.sender.givenName,
                                        familyName: lastReminder.sender.familyName,
                                        time: dateTimeUtils.translateDateTime(translate, lastReminder.timestamp)
                                    })}
                                    placement={"right-end"}
                                >
                                    <NotificationsActive classes={{root: classes.avatarRoot}} style={{
                                        color: auditNotificationSent
                                    }}/>
                                </Tooltip>
                                :
                                <NotificationsNone classes={{root: classes.avatarRoot}}/>
                        }
                    </IconButton>
                }
            </Flex>
        );
    }
}

export default withStyles(styles)(withTranslation()(AuditTileIcons));
