import React, {Component} from 'react';
import {
    Grid,
    withStyles,
} from '@material-ui/core';
import LoginPage from "./login-page/LoginPage";
import {withTranslation} from "react-i18next";

const styles = theme => ({
    loginPage: {
        marginTop: 50
    }
});

export class Login extends Component {
    state = {
        password: '',
        userName: '',
        warning: ''
    };

    changeState = prop => event => {
        this.setState({[prop]: event.target.value});
    };

    handleSubmit = event => {
        const {t: translate} = this.props;

        this.props.actions.login(this.state.userName, this.state.password)
            .catch((error) => {
                if (error.type === 'ONLINE_LOGIN_NEEDED') {
                    this.setState({warning: translate("login.onlineLoginFirst")})
                } else {
                    this.setState({warning: translate("login.incorrectUsernameOrPassword")})
                }
            });
        event.preventDefault();
    };

    render() {
        const {classes} = this.props;
        return (
            <Grid container justify="center">
                <Grid item xs={12} lg={3} xl={3} md={6} sm={6}>
                    <LoginPage data={this.state}
                               actions={{
                                   changeState: this.changeState,
                                   handleSubmit: this.handleSubmit,
                               }}
                               className={classes.loginPage}
                    />
                </Grid>
            </Grid>
        );
    }
}

export default withStyles(styles)(withTranslation()(Login));
