import React, {Component} from 'react';
import {withStyles} from "@material-ui/core";
import {DatePicker , MuiPickersUtilsProvider} from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import {malibuBlue, transparentAzureBlue025, materialUIBorder, htmlWhite} from 'components/colors/Colors.js'

const styles = theme => ({
    input: {
        borderRadius: 4,
        position: 'relative',
        backgroundColor: htmlWhite,
        border: `1px solid ${materialUIBorder}`,
        fontSize: 16,
        padding: '8px 10px 8px 10px',
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        '&:focus': {
            borderRadius: 4,
            borderColor: malibuBlue,
            boxShadow: `0 0 0 0.2rem ${transparentAzureBlue025}`,
        },
    },
});

export class BootstrapDatepicker extends Component {
    render() {
        const {classes, inputProps, ...otherProps} = this.props;
        return (
            <MuiPickersUtilsProvider utils={MomentUtils}>
                <DatePicker variant={"inline"} InputProps={{classes, inputProps}} {...otherProps}/>
            </MuiPickersUtilsProvider>
        )
    }
}

export default withStyles(styles)(BootstrapDatepicker);
