import React, {PureComponent} from "react";
import {Typography, withStyles} from "@material-ui/core";
import {withTranslation} from "react-i18next";
import Flex from "components/grid/Flex";
import {header} from 'components/zindex/zIndex';
import {htmlWhite, transparentBlack02} from "components/colors/Colors";

export const styles = theme => ({
    container: {
        position: 'sticky',
        top: 0,
        paddingBottom: 15,
        zIndex: header,
        backgroundColor: htmlWhite,
        boxShadow: `0px 0px 4px 0px ${transparentBlack02}`
    },
    titleBarItem: {
        lineHeight: 1,
        fontWeight: 'bold',
        paddingRight: 25
    }
});

export class ScheduleTileHeader extends PureComponent {
    render() {
        const {classes, t: translate, scheduleClasses} = this.props;
        return (
            <Flex item container grow={0} alignItems={'center'} className={classes.container}>
                <div className={scheduleClasses.active}>
                    <Typography className={classes.titleBarItem}
                                style={{marginLeft: 0}}>{translate('audit-administration.scheduleList.active')}</Typography>
                </div>
                <div className={scheduleClasses.name}>
                    <Typography className={classes.titleBarItem}
                                style={{marginLeft: -5}}>{translate('audit-administration.scheduleList.name')}</Typography>
                </div>
                <div className={scheduleClasses.type}>
                    <Typography className={classes.titleBarItem}
                                style={{marginLeft: -5}}>{translate('audit-administration.scheduleList.type')}</Typography>
                </div>
                <div className={scheduleClasses.lastOccurrence}>
                    <Typography className={classes.titleBarItem}
                                style={{marginLeft: -5}}>{translate('audit-administration.scheduleList.lastOccurrence')}</Typography>
                </div>
                <div className={scheduleClasses.nextSchedule}>
                    <Typography className={classes.titleBarItem}
                                style={{marginLeft: -5}}>{translate('audit-administration.scheduleList.nextSchedule')}</Typography>
                </div>
                <div className={scheduleClasses.duration}>
                    <Typography className={classes.titleBarItem}
                                style={{marginLeft: -5}}>{translate('audit-administration.scheduleList.duration')}</Typography>
                </div>
                <div className={scheduleClasses.frequency}>
                    <Typography className={classes.titleBarItem}
                                style={{marginLeft: -5}}>{translate('audit-administration.scheduleList.frequency')}</Typography>
                </div>
                <div className={scheduleClasses.auditor}>
                    <Typography className={classes.titleBarItem}
                                style={{marginLeft: -10}}>{translate('audit-administration.scheduleList.auditor')}</Typography>
                </div>
            </Flex>
        );
    }
}

export default withStyles(styles)(withTranslation()(withTranslation()(ScheduleTileHeader)));
