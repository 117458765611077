import React, {Component, Fragment} from "react";
import {withStyles} from "@material-ui/core";
import {withTranslation} from "react-i18next";
import {
    christiGreen,
    doveGrey,
    guardsmanRed,
    htmlGrey,
    htmlLightGrey,
    htmlWhite,
    medium,
    silver
} from 'components/colors/Colors';
import {auditReportingMap} from "components/zindex/zIndex";
import classNames from "classnames";
import Flex from "components/grid/Flex";
import Typography from "@material-ui/core/Typography";
import {Circle} from 'rc-progress';
import _ from "lodash";

const sizeOfCircle = 40;
const sizeOfCircleProgressBar = sizeOfCircle + 10;

export const styles = theme => ({
    marker: {
        width: sizeOfCircle,
        height: sizeOfCircle,
        position: 'absolute',
        padding: sizeOfCircle / 5,
        transform: 'translate3d(-50%, -50%, 0)',
        borderRadius: '50%',
        border: `1px solid ${htmlGrey}`,
        textAlign: 'center',
        zIndex: auditReportingMap,
        '& :hover': {
            cursor: 'pointer'
        }
    },
    markerProgress: {
        width: sizeOfCircleProgressBar,
        height: sizeOfCircleProgressBar,
        position: 'absolute',
        zIndex: auditReportingMap
    },
    number: {
        fontWeight: 'bold',
        fontSize: sizeOfCircle / 2
    },
    text: {
        width: sizeOfCircle,
        height: 15,
        display: 'inline-block',
        position: 'absolute',
        fontWeight: 'bold'
    },
});

class AuditReportingMapMarker extends Component {

    getMarkerPosition = (area) => {
        return {
            top: `${area.center[1]}px`,
            left: `${area.center[0]}px`
        };
    };

    getMarkerProgressPosition = (area) => {
        return {
            top: `${area.center[1] - sizeOfCircleProgressBar / 2}px`,
            left: `${area.center[0] - sizeOfCircleProgressBar / 2}px`
        };
    };

    getTextPosition = (area) => {
        const top = area.center[1] + (sizeOfCircle / 2) + 5;
        const left = area.center[0] - (sizeOfCircle / 2);
        return {
            top: `${top}px`,
            left: `${left}px`
        };
    };

    getMarkerColor = (status) => {
        switch (status) {
            case 'FAILED_CRITICAL':
                return guardsmanRed;
            case 'FAILED_MINOR':
                return medium;
            case 'SUCCESSFUL':
                return christiGreen;
            case 'MISSED':
                return silver;
            default:
                return htmlWhite;
        }
    };

    render() {
        const {classes, marker, onMarkerClick, statistics, auditType, t: translate} = this.props;
        const status = _.get(statistics, 'outcome');
        const numberOfQuestions = _.get(statistics, 'numberOfQuestions', 0);
        const numberOfFailedQuestions = _.get(statistics, 'numberOfFailedQuestions', 0);
        const percent = numberOfQuestions === 0 ? 0 : (numberOfFailedQuestions / numberOfQuestions) * 100;
        return (
            <Fragment>
                <Circle percent={percent}
                        strokeWidth={5}
                        strokeColor={doveGrey}
                        trailWidth={5}
                        trailColor={htmlLightGrey}
                        className={classes.markerProgress}
                        style={{
                            ...this.getMarkerProgressPosition(marker),
                        }}
                />
                <span key={marker.businessId}
                      className={classes.marker}
                      style={{
                          ...this.getMarkerPosition(marker),
                          background: this.getMarkerColor(status)
                      }}
                      onClick={() => onMarkerClick(marker)}>
                            <span
                                className={classes.number}
                                style={{
                                    color: _.isEmpty(status) ? htmlGrey : htmlWhite,
                                    fontSize: _.isEmpty(status) && sizeOfCircle / 3
                                }}>
                              {_.isEmpty(status) ? translate('global.na') : numberOfFailedQuestions === 0 ? '' : numberOfFailedQuestions}
                            </span>
                </span>
                {
                    auditType === 'ALL' &&
                    <Flex container item justifyContent={'center'} style={{
                        ...this.getTextPosition(marker)
                    }}
                          className={classNames(classes.text)}
                    >
                        <Typography
                            style={{
                                color: this.getMarkerColor(status)
                            }}
                        >
                            {_.get(statistics, 'auditType')}
                        </Typography>
                    </Flex>
                }
            </Fragment>
        );
    }
}

export default withStyles(styles)(withTranslation()(AuditReportingMapMarker));
