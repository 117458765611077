import Landing from 'scenes/landing/Landing';
import MobileLanding from 'scenes/landing/mobile/MobileLanding';
import AuditAdministration from 'scenes/audit-manager/administration/AuditAdministration';
import AuditConfiguration from 'scenes/audit-manager/configuration/AuditConfiguration';
import GeneralConfiguration from 'scenes/configuration/GeneralConfiguration';
import AuditTypes from 'scenes/configuration/audit-types/AuditTypes';
import QuestionCatalogs from 'scenes/configuration/question-categories/QuestionCategories';
import UserManagement from 'scenes/configuration/user-management/UserManagement';
import AuditReporting from 'scenes/audit-manager/reporting/AuditReporting';
import KpiMonitoring from "scenes/kpi-monitoring/KpiMonitoring";
import Audit from "scenes/audit/Audit";
import Quality from "scenes/quality/Quality";
import Tasks from "scenes/tasks/Tasks";
import Knowledge from "scenes/knowledge/Knowledge";
import Home from '@material-ui/icons/Home';
import Timeline from '@material-ui/icons/Timeline';
import HighQuality from '@material-ui/icons/HighQuality';
import Cloud from '@material-ui/icons/Cloud';
import PieChart from '@material-ui/icons/PieChart';
import ShowChart from '@material-ui/icons/ShowChart';
import SettingsIcon from '@material-ui/icons/Settings';
import DescriptionIcon from '@material-ui/icons/Description';
import {ReorderOutlined, DashboardOutlined} from '@material-ui/icons';
import {ReactComponent as TaskManagementIcon} from 'assets/img/task-management.svg';
import {ReactComponent as TeamModuleIcon} from 'assets/img/users-icon.svg';
import {ReactComponent as AuditManagementIcon} from 'assets/img/audit-management.svg';
import {ReactComponent as MachineStatusIcon} from 'assets/img/machine-switches.svg';
import utils from 'utils/Utils';
import AuditManagement from 'scenes/audit-manager/AuditManagement';
import _ from 'lodash';
import {
    audit as auditManagementColor,
    guardsmanRed as taskManagementColor,
    easternBlue as kpiManagementColor
} from 'components/colors/Colors'
// eslint-disable-next-line
export const OFFLINE_TASK_ID_REGEX = new RegExp('(task\\/)(OFFLINE)(.*?)(?=(\\/|$))');
// eslint-disable-next-line
export const OFFLINE_ACTION_ID_REGEX = new RegExp('(action\\/)(OFFLINE)(.*?)(?=(\\/|$))');

const routes = {
    LANDING: {
        path: '/',
        title: '',
        component: Landing,
        icon: Home
    },
    MOBILE_LANDING: {
        path: '/',
        title: '',
        component: MobileLanding
    },
    AUDIT: {
        path: '/audit',
        title: 'Audit',
        component: Audit,
        color: auditManagementColor
    },
    SETUP_AUDIT: {
        path: '/audit/setup',
    },
    SETUP_AUDIT_MODE_SELECTION: {
        path: '/audit/setup/mode-selection',
    },
    SETUP_AUDIT_SCANNING: {
        path: '/audit/setup/scanning',
    },
    SETUP_AUDIT_ORGANIZATION_TREE: {
        path: '/audit/setup/organization-tree',
    },
    SETUP_AUDIT_QUESTIONNAIRE_SELECTION: {
        path: '/audit/setup/questionnaire-selection',
    },
    SCHEDULED_AUDITS: {
        path: '/audit/scheduled',
        icon: AuditManagementIcon
    },
    EXECUTE_AUDIT: {
        path: '/audit/execute',
    },
    REVIEW_AUDIT: {
        path: '/audit/review',
    },
    AD_HOC_AUDIT: {
        path: '/audit/execute/ad-hoc',
    },
    AUDIT_RESULT: {
        path: '/audit/result',
    },
    AUDIT_MANAGEMENT: {
        path: '/audit-management',
        component: AuditManagement,
        color: auditManagementColor
    },
    AUDIT_CONFIGURATION: {
        path: '/audit-management/configuration',
        title: 'Audit Management',
        subtitle: 'routes.audit-management.configuration.subtitle',
        component: AuditConfiguration,
        icon: DescriptionIcon,
    },
    AUDIT_CONFIGURATION_ORGANIZATION_LEVEL: {
        path: '/audit-management/configuration/organization-level/:organizationLevelBusinessId',
        pathWithId: organizationLevelBusinessId => `/audit-management/configuration/organization-level/${organizationLevelBusinessId}`
    },
    AUDIT_CONFIGURATION_ORGANIZATION_LEVEL_USERS: {
        path: '/audit-management/configuration/organization-level/:organizationLevelBusinessId/users',
        pathWithId: organizationLevelBusinessId => `/audit-management/configuration/organization-level/${organizationLevelBusinessId}/users`
    },
    AUDIT_CONFIGURATION_ORGANIZATION_LEVEL_TIMEZONE: {
        path: '/audit-management/configuration/organization-level/:organizationLevelBusinessId/timezone',
        pathWithId: organizationLevelBusinessId => `/audit-management/configuration/organization-level/${organizationLevelBusinessId}/timezone`
    },
    AUDIT_CONFIGURATION_ORGANIZATION_LEVEL_IMAGE: {
        path: '/audit-management/configuration/organization-level/:organizationLevelBusinessId/image',
        pathWithId: organizationLevelBusinessId => `/audit-management/configuration/organization-level/${organizationLevelBusinessId}/image`
    },
    AUDIT_CONFIGURATION_ORGANIZATION_LEVEL_MAP: {
        path: '/audit-management/configuration/organization-level/:organizationLevelBusinessId/map',
        pathWithId: organizationLevelBusinessId => `/audit-management/configuration/organization-level/${organizationLevelBusinessId}/map`
    },
    AUDIT_ADMINISTRATION: {
        path: '/audit-management/administration',
        title: 'Audit Management',
        subtitle: 'routes.audit-management.administration.subtitle',
        component: AuditAdministration,
        icon: SettingsIcon,
    },
    AUDIT_REPORTING: {
        path: '/audit-management/reporting',
        title: 'Audit Management',
        subtitle: 'routes.audit-management.reporting.subtitle',
        component: AuditReporting,
        icon: PieChart,
    },
    AUDIT_ADMINISTRATION_QUESTIONS: {
        path: '/audit-management/administration/question/list',
        title: 'Audit Management',
        subtitle: 'audit-administration.questions',
        pathWithFilterParams: params => {
            let queryString = utils.queryString(params);
            return `/audit-management/administration/question/list${queryString}`;
        },
    },
    AUDIT_ADMINISTRATION_QUESTIONNAIRES: {
        path: '/audit-management/administration/questionnaire/list',
    },
    AUDIT_ADMINISTRATION_QUESTIONNAIRE_CREATE: {
        path: '/audit-management/administration/questionnaire/create',
    },
    AUDIT_ADMINISTRATION_QUESTIONNAIRE_EDIT: {
        path: '/audit-management/administration/questionnaire/:questionnaireBusinessId',
        pathWithId: questionnaireBusinessId => `/audit-management/administration/questionnaire/${questionnaireBusinessId}`
    },
    AUDIT_ADMINISTRATION_SCHEDULES: {
        path: '/audit-management/administration/schedule/list',
    },
    AUDIT_ADMINISTRATION_SCHEDULE_CREATE: {
        path: '/audit-management/administration/schedule/create',
    },
    AUDIT_ADMINISTRATION_SCHEDULE_EDIT: {
        path: '/audit-management/administration/schedule/:scheduleBusinessId',
        pathWithId: scheduleBusinessId => `/audit-management/administration/schedule/${scheduleBusinessId}`
    },
    AUDIT_ADMINISTRATION_MONITORING: {
        path: '/audit-management/administration/monitoring',
        title: 'Audit Management',
        subtitle: 'audit-administration.monitoring',
    },
    AUDIT_ADMINISTRATION_QUESTION_CREATE: {
        path: '/audit-management/administration/question/create',
    },
    AUDIT_ADMINISTRATION_QUESTION_EDIT: {
        path: '/audit-management/administration/question/:questionBusinessId',
        pathWithId: questionBusinessId => `/audit-management/administration/question/${questionBusinessId}`
    },
    AUDIT_ADMINISTRATION_ORGANIZATION_LEVEL_CONFIGURATION: {
        path: '/audit-management/administration/organization-level/:organizationLevelBusinessId',
        pathWithId: organizationLevelBusinessId => `/audit-management/administration/organization-level/${organizationLevelBusinessId}`
    },
    AUDIT_REPORTING_HISTORY: {
        path: '/audit-management/reporting/history',
        title: 'Audit Management',
    },
    AUDIT_REPORTING_PARETO: {
        path: '/audit-management/reporting/pareto',
        title: 'Audit Management',
    },
    AUDIT_REPORTING_MAP: {
        path: '/audit-management/reporting/map',
        title: 'Audit Management',
    },
    AUDIT_REPORTING_DEVIATIONS: {
        path: '/audit-management/reporting/deviations',
        title: 'Audit Management',
    },
    KPI: {
        path: '/kpi',
        title: 'KPI Monitoring',
        component: KpiMonitoring,
        icon: Timeline,
        color: kpiManagementColor
    },
    QUALITY: {
        path: '/quality',
        title: 'Quality management',
        component: Quality,
        icon: HighQuality
    },
    TASK: {
        path: '/task',
        component: Tasks,
        icon: TaskManagementIcon,
        color: taskManagementColor
    },
    TASK_ADMINISTRATION: {
        path: '/task/administration',
        title: 'routes.task.administration.title',
        subtitle: 'routes.task.administration.subtitle',
        icon: ShowChart
    },
    TASK_REPORTING: {
        path: '/task/reporting',
        title: 'routes.task.reporting.title',
        subtitle: 'routes.task.reporting.subtitle',
        breadCrumb: projectBreadCrumbs,
        icon: ShowChart
    },
    TASK_BACKLOG: {
        path: '/task/backlog',
        title: 'routes.task.backlog.title',
        subtitle: 'routes.task.backlog.subtitle',
        breadCrumb: projectBreadCrumbs,
        icon: ReorderOutlined,
        pathWithFilterParams: params => {
            let queryString = utils.queryString(params);
            return `/task/backlog${queryString}`;
        },
        queryStringFrom: utils.queryString
    },
    TASK_KANBAN: {
        path: '/task/kanban',
        title: 'routes.task.kanban.title',
        subtitle: 'routes.task.kanban.subtitle',
        breadCrumb: projectBreadCrumbs,
        icon: DashboardOutlined,
        pathWithFilterParams: params => {
            let queryString = utils.queryString(params);
            return `/task/kanban${queryString}`;
        }
    },
    TASK_CREATE: {
        path: '/task/create',
    },
    TASK_EDIT: {
        path: '/task/:taskBusinessId',
        pathWithId: taskBusinessId => `/task/${taskBusinessId}`,
        pathFromOfflineToId: (currentPath, taskBusinessId) => {
            return currentPath.replace(OFFLINE_TASK_ID_REGEX, "$1" + taskBusinessId);
        }
    },
    TASK_ACTION: {
        path: '/task/:taskBusinessId/action'
    },
    TASK_ACTION_LIST: {
        path: '/task/:taskBusinessId/action/list',
        pathWithId: taskBusinessId => `/task/${taskBusinessId}/action/list`,
        pathFromOfflineToId: (currentPath, taskBusinessId) => {
            return currentPath.replace(OFFLINE_TASK_ID_REGEX, "$1" + taskBusinessId);
        }
    },
    TASK_ACTION_CREATE: {
        path: '/task/:taskBusinessId/action/create',
        pathWithId: taskBusinessId => `/task/${taskBusinessId}/action/create`
    },
    TASK_ACTION_EDIT: {
        path: '/task/:taskBusinessId/action/:actionBusinessId',
        pathWithIds: (taskBusinessId, actionBusinessId) => `/task/${taskBusinessId}/action/${actionBusinessId}`,
        pathFromOfflineToId: (currentPath, actionBusinessId, taskBusinessId) => {
            return currentPath
                .replace(OFFLINE_ACTION_ID_REGEX, "$1" + actionBusinessId)
                .replace(OFFLINE_TASK_ID_REGEX, "$1" + taskBusinessId);
        }
    },
    DEVIATION_REDIRECT: {
        path: '/task/deviation/:answerId',
        pathWithId: answerId => `/task/deviation/${answerId}`
    },
    TASK_TEAM_MODULE: {
        path: '',
        title: 'Task Management',
        subtitle: 'Team Module',
        component: () => {
        },
        icon: TeamModuleIcon
    },
    KNOWLEDGE: {
        path: '/knowledge',
        title: 'Knowledge',
        component: Knowledge,
        icon: Cloud
    },
    KPI_DOCUMENT_MACHINE_STATUS: {
        path: '/kpi/document-machine-status',
        title: 'routes.kpi.document-machine-status.title',
        subtitle: 'routes.kpi.document-machine-status.subtitle',
        icon: MachineStatusIcon,
    },
    GENERAL_CONFIGURATION: {
        path: '/general-configuration',
        title: 'routes.general-configuration.title',
        component: GeneralConfiguration,
        icon: SettingsIcon,
    },
    GENERAL_CONFIGURATION_USER_MANAGEMENT: {
        path: '/general-configuration/user-management',
        title: 'routes.general-configuration.user-management.title',
        component: UserManagement,
        icon: SettingsIcon,
    },
    GENERAL_CONFIGURATION_AUDIT_TYPES: {
        path: '/general-configuration/audit-types',
        title: 'routes.general-configuration.audit-types.title',
        component: AuditTypes,
        icon: SettingsIcon,
    },
    GENERAL_CONFIGURATION_QUESTION_CATALOGS: {
        path: '/general-configuration/question-catalogs',
        title: 'routes.general-configuration.question-catalogs.title',
        component: QuestionCatalogs,
        icon: SettingsIcon,
    },
};

export const LANDING = routes.LANDING;
export const MOBILE_LANDING = routes.MOBILE_LANDING;
export const AUDIT = routes.AUDIT;
export const SETUP_AUDIT = routes.SETUP_AUDIT;
export const SETUP_AUDIT_MODE_SELECTION = routes.SETUP_AUDIT_MODE_SELECTION;
export const SETUP_AUDIT_SCANNING = routes.SETUP_AUDIT_SCANNING;
export const SETUP_AUDIT_ORGANIZATION_TREE = routes.SETUP_AUDIT_ORGANIZATION_TREE;
export const SETUP_AUDIT_QUESTIONNAIRE_SELECTION = routes.SETUP_AUDIT_QUESTIONNAIRE_SELECTION;
export const SCHEDULED_AUDITS = routes.SCHEDULED_AUDITS;
export const AD_HOC_AUDIT = routes.AD_HOC_AUDIT;
export const AUDIT_MANAGEMENT = routes.AUDIT_MANAGEMENT;
export const AUDIT_ADMINISTRATION = routes.AUDIT_ADMINISTRATION;
export const AUDIT_CONFIGURATION = routes.AUDIT_CONFIGURATION;
export const AUDIT_CONFIGURATION_ORGANIZATION_LEVEL = routes.AUDIT_CONFIGURATION_ORGANIZATION_LEVEL;
export const AUDIT_CONFIGURATION_ORGANIZATION_LEVEL_USERS = routes.AUDIT_CONFIGURATION_ORGANIZATION_LEVEL_USERS;
export const AUDIT_CONFIGURATION_ORGANIZATION_LEVEL_TIMEZONE = routes.AUDIT_CONFIGURATION_ORGANIZATION_LEVEL_TIMEZONE;
export const AUDIT_CONFIGURATION_ORGANIZATION_LEVEL_MAP = routes.AUDIT_CONFIGURATION_ORGANIZATION_LEVEL_MAP;
export const AUDIT_CONFIGURATION_ORGANIZATION_LEVEL_IMAGE = routes.AUDIT_CONFIGURATION_ORGANIZATION_LEVEL_IMAGE;
export const AUDIT_REPORTING = routes.AUDIT_REPORTING;
export const AUDIT_ADMINISTRATION_QUESTIONS = routes.AUDIT_ADMINISTRATION_QUESTIONS;
export const AUDIT_ADMINISTRATION_QUESTION_CREATE = routes.AUDIT_ADMINISTRATION_QUESTION_CREATE;
export const AUDIT_ADMINISTRATION_QUESTION_EDIT = routes.AUDIT_ADMINISTRATION_QUESTION_EDIT;
export const AUDIT_ADMINISTRATION_QUESTIONNAIRES = routes.AUDIT_ADMINISTRATION_QUESTIONNAIRES;
export const AUDIT_ADMINISTRATION_QUESTIONNAIRE_CREATE = routes.AUDIT_ADMINISTRATION_QUESTIONNAIRE_CREATE;
export const AUDIT_ADMINISTRATION_QUESTIONNAIRE_EDIT = routes.AUDIT_ADMINISTRATION_QUESTIONNAIRE_EDIT;
export const AUDIT_ADMINISTRATION_SCHEDULES = routes.AUDIT_ADMINISTRATION_SCHEDULES;
export const AUDIT_ADMINISTRATION_SCHEDULE_CREATE = routes.AUDIT_ADMINISTRATION_SCHEDULE_CREATE;
export const AUDIT_ADMINISTRATION_SCHEDULE_EDIT = routes.AUDIT_ADMINISTRATION_SCHEDULE_EDIT;
export const AUDIT_ADMINISTRATION_MONITORING = routes.AUDIT_ADMINISTRATION_MONITORING;
export const AUDIT_ADMINISTRATION_ORGANIZATION_LEVEL_CONFIGURATION = routes.AUDIT_ADMINISTRATION_ORGANIZATION_LEVEL_CONFIGURATION;
export const AUDIT_REPORTING_HISTORY = routes.AUDIT_REPORTING_HISTORY;
export const AUDIT_REPORTING_PARETO = routes.AUDIT_REPORTING_PARETO;
export const AUDIT_REPORTING_MAP = routes.AUDIT_REPORTING_MAP;
export const AUDIT_REPORTING_DEVIATIONS = routes.AUDIT_REPORTING_DEVIATIONS;
export const KPI = routes.KPI;
export const QUALITY = routes.QUALITY;
export const TASK = routes.TASK;
export const TASK_ADMINISTRATION = routes.TASK_ADMINISTRATION;
export const TASK_BACKLOG = routes.TASK_BACKLOG;
export const TASK_KANBAN = routes.TASK_KANBAN;
export const TASK_REPORTING = routes.TASK_REPORTING;
export const TASK_CREATE = routes.TASK_CREATE;
export const TASK_EDIT = routes.TASK_EDIT;
export const TASK_ACTION = routes.TASK_ACTION;
export const TASK_ACTION_LIST = routes.TASK_ACTION_LIST;
export const TASK_ACTION_CREATE = routes.TASK_ACTION_CREATE;
export const TASK_ACTION_EDIT = routes.TASK_ACTION_EDIT;
export const DEVIATION_REDIRECT = routes.DEVIATION_REDIRECT;
export const KNOWLEDGE = routes.KNOWLEDGE;
export const EXECUTE_AUDIT = routes.EXECUTE_AUDIT;
export const REVIEW_AUDIT = routes.REVIEW_AUDIT;
export const AUDIT_RESULT = routes.AUDIT_RESULT;
export const MOBILE_ROUTES = [AUDIT, TASK, KPI];
export const KPI_DOCUMENT_MACHINE_STATUS = routes.KPI_DOCUMENT_MACHINE_STATUS;
export const GENERAL_CONFIGURATION = routes.GENERAL_CONFIGURATION;
export const GENERAL_CONFIGURATION_USER_MANAGEMENT = routes.GENERAL_CONFIGURATION_USER_MANAGEMENT;
export const GENERAL_CONFIGURATION_AUDIT_TYPES = routes.GENERAL_CONFIGURATION_AUDIT_TYPES;
export const GENERAL_CONFIGURATION_QUESTION_CATALOGS = routes.GENERAL_CONFIGURATION_QUESTION_CATALOGS;
export const DESKTOP_ROUTES = [GENERAL_CONFIGURATION, KPI, QUALITY, TASK, KNOWLEDGE, AUDIT_MANAGEMENT, AUDIT];

export const TASK_EDIT_RETURN_PATHS = [
    AUDIT_ADMINISTRATION_MONITORING.path,
    TASK_KANBAN.path,
    TASK_BACKLOG.path,
];

export const pathToRouteMap = Object.values(routes).reduce((result, item) => {
    result[item.path] = item;
    return result;
}, {});

export const subRoutes = [
    {mainRoute: TASK, subRoutes: [TASK_BACKLOG, TASK_KANBAN, TASK_REPORTING, KPI_DOCUMENT_MACHINE_STATUS]},
    {mainRoute: AUDIT_MANAGEMENT, subRoutes: [AUDIT_ADMINISTRATION, AUDIT_REPORTING]},
    {
        mainRoute: AUDIT,
        subRoutes: [SETUP_AUDIT, SCHEDULED_AUDITS, EXECUTE_AUDIT, REVIEW_AUDIT, AD_HOC_AUDIT, AUDIT_RESULT]
    },
    {mainRoute: KPI, subRoutes: [TASK_BACKLOG, TASK_KANBAN, TASK_REPORTING, KPI_DOCUMENT_MACHINE_STATUS]},
    {mainRoute: GENERAL_CONFIGURATION},
];

export function projectBreadCrumbs(projectHierarchy) {
    return projectHierarchy.map(project => project.projectName || project.name)
}

export default Object.values(routes);

export function routeTo(path, props) {
    const prevPath = _.get(props, 'location')
        ? _.get(props, 'location.pathname', '') + _.get(props, 'location.search', '')
        : undefined;
    props.history.push(path, {prevPath});
}

export const routeTo2 = (path, props) => {
    const prevPath = _.get(props, 'location')
        ? _.get(props, 'location.pathname', '') + _.get(props, 'location.search', '')
        : undefined;
    props.history.push(path, {prevPath});
};

export function prevPath(props) {
    return _.get(props, 'location.pathname', '') + _.get(props, 'location.search', '');
}

export function newTab(path) {
    window.open(window.location.origin + path, '_blank');
}
