import {cancelScheduledAudit, fetchAudits, fetchDeviations, sendAuditReminder, updateAuditor} from "./Monitor.action";
import {clearFilterMultiSelection} from "scenes/audit-manager/administration/organization-tree/OrganizationTree.action";

export default {
    mapStateToProps: (state) => {
        return {
            audits: state.monitoringReducer.audits,
            dailyAuditColumns: state.monitoringReducer.dailyAuditColumns,
            longAuditRows: state.monitoringReducer.longAuditRows,
            supportedWeekdays: state.generalConfigurationReducer.auditConfiguration.supportedWeekdays,
            user: state.mainReducer.user,
            multiSelectedOrganizationLevels: state.organizationTreeReducer.multiSelectedOrganizationLevels,
            auditors: state.monitoringReducer.auditors,
            deviations: state.monitoringReducer.deviations
        };
    },
    mapDispatchToProps: (dispatch) => {
        return {
            fetchAudits: (organizationLevelIds, fromDate, toDate) => dispatch(fetchAudits(organizationLevelIds, fromDate, toDate)),
            fetchDeviations: (auditId) => dispatch(fetchDeviations(auditId)),
            updateAuditor: (auditId, auditorId) => dispatch(updateAuditor(auditId, auditorId)),
            sendAuditReminder: (auditId) => dispatch(sendAuditReminder(auditId)),
            cancelScheduledAudit: (auditScheduleId, scheduleDate) => dispatch(cancelScheduledAudit(auditScheduleId, scheduleDate)),
            clearFilterMultiSelection: () => dispatch(clearFilterMultiSelection()),
        };
    }
};
