export const FETCH_AUDIT_MANAGEMENT_CONFIGURATION_DATA = '@general-configuration/FETCH_AUDIT_MANAGEMENT_CONFIGURATION_DATA';
export const FETCH_AUDIT_MANAGEMENT_CONFIGURATION_DATA_SUCCESS = '@general-configuration/FETCH_AUDIT_MANAGEMENT_CONFIGURATION_DATA_SUCCESS';
export const FETCH_AUDIT_MANAGEMENT_CONFIGURATION_DATA_FAIL = '@general-configuration/FETCH_AUDIT_MANAGEMENT_CONFIGURATION_DATA_FAIL';

export const ADD_QUESTION_CATEGORY_DATA = '@general-configuration/ADD_QUESTION_CATEGORY_DATA';
export const ADD_QUESTION_CATEGORY_DATA_SUCCESS = '@general-configuration/ADD_QUESTION_CATEGORY_DATA_SUCCESS';
export const ADD_QUESTION_CATEGORY_DATA_FAIL = '@general-configuration/ADD_QUESTION_CATEGORY_DATA_FAIL';

export const REMOVE_QUESTION_CATEGORY_DATA = '@general-configuration/REMOVE_QUESTION_CATEGORY_DATA';
export const REMOVE_QUESTION_CATEGORY_DATA_SUCCESS = '@general-configuration/REMOVE_QUESTION_CATEGORY_DATA_SUCCESS';
export const REMOVE_QUESTION_CATEGORY_DATA_FAIL = '@general-configuration/REMOVE_QUESTION_CATEGORY_DATA_FAIL';

export const RESET_TO_BE_CONFIRMED_QUESTIONNAIRES = '@general-configuration/RESET_TO_BE_CONFIRMED_QUESTIONNAIRES';

export const ADD_AUDIT_TYPE_DATA = '@general-configuration/ADD_AUDIT_TYPE_DATA';
export const ADD_AUDIT_TYPE_DATA_SUCCESS = '@general-configuration/ADD_AUDIT_TYPE_DATA_SUCCESS';
export const ADD_AUDIT_TYPE_DATA_FAIL = '@general-configuration/ADD_AUDIT_TYPE_DATA_FAIL';

export const REMOVE_AUDIT_TYPE_DATA = '@general-configuration/REMOVE_AUDIT_TYPE_DATA';
export const REMOVE_AUDIT_TYPE_DATA_SUCCESS = '@general-configuration/REMOVE_AUDIT_TYPE_DATA_SUCCESS';
export const REMOVE_AUDIT_TYPE_DATA_FAIL = '@general-configuration/REMOVE_AUDIT_TYPE_DATA_FAIL';

export const RESET_TO_BE_CONFIRMED_AUDIT_SCHEDULES = '@general-configuration/RESET_TO_BE_CONFIRMED_AUDIT_SCHEDULES';
