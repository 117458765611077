import * as actionTypes from "./OrganizationLeveLConfiguration.action.types";
import query from "store/GraphqlQueries";
import {USER_GRAPHQL} from "domain/User.model";
import {ORGANIZATION_LEVEL_WITH_MAP_GRAPHQL} from "domain/audit/OrganizationLevel.model";

export function fetchOrganizationLevel(levelBusinessId) {
    return (dispatch, getState) => {
        return dispatch(
            query.graphql({
                type: actionTypes.FETCH_ORGANIZATION_LEVEL,
                caller: 'fetchOrganizationLevel',
                query: `
                          query
                                ($levelBusinessId: String!){
                                    fetchOrganizationLevel(levelBusinessId: $levelBusinessId) {
                                        ${ORGANIZATION_LEVEL_WITH_MAP_GRAPHQL}
                                }
                        }
                       `,
                variables: {
                    levelBusinessId
                }
            })
        );
    }
}

export function addOrganizationLevelMembership(businessId, userId, role) {
    return {
        type: actionTypes.ASSIGN_ORGANIZATION_LEVEL_MEMBER,
        payload: {
            client: 'graphql',
            request: {
                method: 'post',
                data: {
                    query: `mutation addOrganizationLevelMembership($organizationLevelId: String!, $userId: String!, $membership: OrganizationMembership!){
    	                        addOrganizationLevelMembership(organizationLevelId: $organizationLevelId, userId: $userId, membership: $membership)
                            }
                           `,
                    variables: {
                        organizationLevelId: businessId,
                        userId: userId,
                        membership: role
                    }
                }
            }
        }
    }
}

export function updateOrganizationLevelMembership(businessId, userId, newRole, oldRole) {
    return {
        type: actionTypes.UPDATE_ORGANIZATION_LEVEL_MEMBERSHIP,
        payload: {
            client: 'graphql',
            request: {
                method: 'post',
                data: {
                    query: `mutation updateOrganizationLevelMembership($organizationLevelId: String!, $userId: String!, $newMembership: OrganizationMembership!, $oldMembership: OrganizationMembership!){
    	                        updateOrganizationLevelMembership(organizationLevelId: $organizationLevelId, userId: $userId, newMembership: $newMembership, oldMembership: $oldMembership)
                            }
                           `,
                    variables: {
                        organizationLevelId: businessId,
                        userId: userId,
                        newMembership: newRole,
                        oldMembership: oldRole
                    }
                }
            }
        }
    }
}

export function removeOrganizationLevelMembership(businessId, userId, membership) {
    return {
        type: actionTypes.REMOVE_ORGANIZATION_LEVEL_MEMBER,
        payload: {
            client: 'graphql',
            request: {
                method: 'post',
                data: {
                    query: `mutation removeOrganizationLevelMembership($organizationLevelId: String!, $userId: String!, $membership:OrganizationMembership!){
    	                        removeOrganizationLevelMembership(organizationLevelId: $organizationLevelId, userId: $userId, membership: $membership)
                            }
                           `,
                    variables: {
                        organizationLevelId: businessId,
                        userId: userId,
                        membership: membership
                    }
                }
            }
        }
    }
}

export function fetchUsers() {
    return fetchUsersFromServer();
}

export function fetchUsersFromServer() {
    return {
        type: actionTypes.FETCH_USERS,
        payload: {
            client: 'graphql',
            request: {
                method: 'post',
                data: {
                    query: `
                            {
                                listUsers {
                                    ${USER_GRAPHQL}
                                }
                            }
                           `
                }
            }
        }
    };
}
