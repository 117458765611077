import {
    fetchUsers,
    fetchOrganizationLevel,
    addOrganizationLevelMembership,
    removeOrganizationLevelMembership,
    updateOrganizationLevelMembership,
} from "scenes/audit-manager/configuration/organization-level/OrganizationLevelConfiguration.action";

export default {
    mapStateToProps: (state) => {
        return {
            allUsers: state.organizationLevelConfigurationReducer.configuration.users,
            loggedInUser: state.mainReducer.user,
            organizationLevel: state.organizationLevelConfigurationReducer.organizationLevel,
        };
    },
    mapDispatchToProps: (dispatch) => {
        return {
            fetchUsers: () => dispatch(fetchUsers()),
            fetchOrganizationLevel: (levelBusinessId) => dispatch(fetchOrganizationLevel(levelBusinessId)),
            addOrganizationLevelMembership: (businessId, userId, role) => dispatch(addOrganizationLevelMembership(businessId, userId, role)),
            updateOrganizationLevelMembership: (businessId, userId, newRole, oldRole) => dispatch(updateOrganizationLevelMembership(businessId, userId, newRole, oldRole)),
            removeOrganizationLevelMembership: (businessId, userId, membership) => dispatch(removeOrganizationLevelMembership(businessId, userId, membership)),
        };
    }
};