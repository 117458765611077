import React, {PureComponent,} from 'react';
import {withStyles, IconButton} from "@material-ui/core";
import Flex from 'components/grid/Flex';
import {withTranslation} from "react-i18next";
import {greyBackground, htmlGrey, logoBlueLight, htmlWhite, silver} from 'components/colors/Colors';
import H4InputWithAdornment from 'components/input/H4InputWithAdornment';
import TagInputWithSuggestions from 'components/tags/TagInputWithSuggestions';
import {Search, ExpandMore, ExpandLess, CloseOutlined as CloseIcon} from "@material-ui/icons";
import ProjectUserManagement from "scenes/tasks/components/project-tree/ProjectUserManagement";
import ids from 'components/ids/ids';
import _ from 'lodash';

export const styles = theme => ({
    topLineFilters: {
        paddingBottom: 10
    },
    inputContainerMargins: {
        flexWrap: 'wrap'
    },
    tags: {
        backgroundColor: greyBackground,
        borderRadius: 10,
        border: 'unset',
        marginTop: 5
    },
    showAllLabel: {
        marginRight: 5,
        marginLeft: 15,
        marginTop: 5,
        marginBottom: 5,
        fontSize: 13,
        paddingRight: 6,
        borderRadius: 7,
        border: `1px dashed ${logoBlueLight}`,
        textTransform: 'capitalize',
        color: htmlGrey,
        display: 'inline',
        whiteSpace: 'nowrap',
        '&:hover': {
            cursor: 'pointer'
        },
    },
    tagNumberIndicator: {
        backgroundColor: logoBlueLight,
        color: htmlWhite,
        borderRadius: '50%',
        padding: 3,
        width: 20,
        marginLeft: 5,
        fontSize: 12
    }
});

export class BacklogFilters extends PureComponent {

    state = {
        taggingFeatureEnabled: true,
        showTags: false
    };

    toggleShowTags = () => {
        this.setState({
            showTags: !this.state.showTags
        });
    };

    reset = () => {
        this.props.resetSelectedTags();
        this.setState({
            showTags: false
        });
    };

    render() {
        const {
            classes, t: translate, filterState, tagSuggestions, selectedTags, handleNamedStateChange, tagFilterRelation,
            resetSearchText, toggleTagSelection
        } = this.props;
        const {showTags} = this.state;
        const {toggleShowTags, reset} = this;
        return (
            <Flex container className={classes.topLineFilters} direction={'column'}>
                <Flex container item justifyContent={'space-between'} alignItems={'center'} style={{flexWrap: 'wrap'}}>
                    <Flex item container className={classes.inputContainerMargins} alignItems={'center'}>
                        <H4InputWithAdornment
                            id={ids.freeTextSearchInput}
                            value={filterState.searchText}
                            placeholder={translate('global.search')}
                            onChange={handleNamedStateChange}
                            name={'searchText'}
                            rootStyle={{padding: 0, paddingLeft: 10, width: 420}}
                            startAdornment={<Search/>}
                            endAdornment={
                                !_.isEmpty(filterState.searchText) &&
                                <IconButton style={{padding: 3, backgroundColor: silver, fontSize: 13, marginRight: 3}}
                                            onClick={resetSearchText}>
                                    <CloseIcon style={{color: greyBackground, fontSize: 'inherit'}}/>
                                </IconButton>
                            }
                        />
                        <Flex container item={'0 1 auto'} className={classes.showAllLabel} alignItems={'center'}>
                            <Flex item container onClick={toggleShowTags} alignItems={'center'}>
                                {showTags ? <ExpandLess/> : <ExpandMore/>}
                                <span>{showTags ? translate('task-management.hideTagsLabel') : translate('task-management.showTagsLabel')}</span>
                            </Flex>
                            {
                                selectedTags.length > 0 &&
                                <Flex item container className={classes.tagNumberIndicator}
                                      justifyContent={'center'}>
                                    <IconButton style={{padding: 0, color: 'inherit', fontSize: 'inherit'}}
                                                onClick={reset}>
                                        <CloseIcon style={{color: 'inherit', fontSize: 'inherit'}}/>
                                    </IconButton>
                                </Flex>
                            }
                        </Flex>
                    </Flex>
                    <ProjectUserManagement/>

                </Flex>
                {
                    showTags &&
                    <TagInputWithSuggestions {...{
                        selectedTags,
                        tagSuggestions,
                        tagFilterRelation,
                        handleNamedStateChange,
                        toggleTagSelection
                    }}/>
                }
            </Flex>
        );
    }
}

export default withStyles(styles)(withTranslation()(BacklogFilters));
