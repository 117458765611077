import React, {Component} from 'react';
import {withStyles,} from "@material-ui/core";
import 'assets/css/circle.css';

const styles = theme => ({});

export class CircleBar extends Component {

    render() {
        const {progressValue, color} = this.props;
        const progress = "c100 p" + progressValue + " " + color + " small";

        return (
            <div className={progress}>
                <div className="progress">
                    <span>{progressValue}</span>
                    <span className="percentage">%</span>
                </div>
                <div className="slice">
                    <div className="bar"/>
                    <div className="fill"/>
                </div>
            </div>
        );
    }
}

export default withStyles(styles)(CircleBar);
