import React, {Component, Fragment} from 'react';
import {withStyles, Typography, Checkbox, IconButton} from "@material-ui/core";
import Flex from 'components/grid/Flex';
import {Trans, withTranslation} from "react-i18next";
import BootstrapInput from 'components/bootstrap-input/BootstrapInput';
import BootstrapInputWithStartadornment from 'components/bootstrap-input/BootstrapInputWithStartadornment';
import BootstrapDatepicker from 'components/bootstrap-datepicker/BootstrapDatepicker';
import Responsible from 'components/responsible/Responsible';
import {Link, Route} from 'react-router-dom';
import {
    doveGrey,
    christiGreen,
    guardsmanRed,
    auditPaperGrey,
    silver,
    swipeActionGreen,
    htmlWhite,
    htmlBlack,
    materialUIBorder
} from 'components/colors/Colors';
import utils from 'utils/Utils';
import WorkDoneProgressBar from 'components/progress-bar/WorkDoneProgressBar';
import BlueGreenProgressBar from 'components/progress-bar/BlueGreenProgressBar';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import {getColorbyPriority, getTextfieldColorByPriority} from 'components/colors/Priority';
import Attachment from 'components/attachment/Attachment';
import Comment from 'components/comment/Comment';
import _ from 'lodash';
import GoogleIcon from 'components/google-icon/GoogleIcon';
import TranslationChangedDialog from 'scenes/tasks/components/TranslationChangedDialog';
import ConfirmationDialog from 'components/confirmation-dialog/ConfirmationDialog';
import {CloseOutlined as CloseIcon, Done as DoneIcon, Delete as DeleteIcon} from '@material-ui/icons';
import MobileDescription from 'scenes/tasks/components/mobile/MobileDescription';
import {routeTo} from 'routes/routes';
import taskUtils from 'scenes/tasks/Task.utils';

const styles = theme => ({
    inline: {
        display: 'inline'
    },
    overFlowDays: {
        display: 'inline',
        color: guardsmanRed,
    },
    doneCheckBoxRoot: {
        padding: '0px 0px 0px 0px'
    },
    iconColor: {
        color: htmlWhite
    },
    descriptionPreviewContainer: {
        overflow: 'auto',
        border: `1px solid ${materialUIBorder}`,
        padding: '8px 10px 8px 10px',
        borderRadius: 4,
        backgroundColor: htmlWhite,
        height: 100,
        maxHeight: 100,
        flex: '0 0 100px',
        display: 'flex',
        color: htmlBlack,
        textDecoration: 'none'
    },
    controlButtonBox: {
        backgroundColor: auditPaperGrey,
        borderRadius: 10,
        border: `1px solid ${doveGrey}`,
        padding: 2,
    },
    fieldTitle: {
        color: silver,
        textTransform: 'uppercase',
        marginTop: 12,
        marginBottom: 2,
        fontSize: 12
    },
    inlineTitle: {
        color: silver,
        textTransform: 'uppercase',
        fontSize: 12
    },
});

const ActionHeader = (props) => {
    const {
        actionBusinessIdLabel, isOfflineCreated, applicationOnline, newActionRefId, isSelected, actionBusinessId,
        action
    } = props;
    return (
        <Flex id={'action-edit-header-container'} container item grow={0}
              style={{
                  border: '2px solid ' + getTextfieldColorByPriority(action.priority.type),
                  backgroundColor: getTextfieldColorByPriority(action.priority.type),
                  paddingLeft: 10,
                  paddingRight: 10
              }}
        >
            <Flex item>
                <Typography variant="h6" style={{whiteSpace: 'nowrap', fontWeight: 'bold'}}>
                    {actionBusinessIdLabel}
                </Typography>
            </Flex>
            <Flex container item>
                <Flex item container justifyContent={'flex-end'}>
                    {
                        !isOfflineCreated && applicationOnline &&
                        <Attachment
                            {...{
                                id: actionBusinessId || newActionRefId,
                                referenceType: 'ACTION',
                                files: action.attachments,
                                isMobile: true,
                                uploadEnabled: true
                            }}
                        />
                    }
                    {
                        isSelected && !isOfflineCreated && applicationOnline &&
                        <Comment
                            {...{
                                entityId: actionBusinessId,
                                comments: action.comments,
                                type: 'Action',
                                isMobile: true
                            }}
                        />
                    }
                </Flex>
            </Flex>
        </Flex>
    );
};

const Title = (props) => {
    const {classes, matchingTranslation, handleTitleChange, isActionEditable, action} = props;
    return (
        <Flex id={'action-edit-title-container'} item grow={0}>
            <Typography className={classes.fieldTitle}><Trans
                i18nKey={"task-management.action-edit.title"}/></Typography>
            <BootstrapInputWithStartadornment
                fullWidth
                value={matchingTranslation.title}
                inputProps={{
                    style: {
                        color: getColorbyPriority(action.priority.type),
                        fontWeight: 'bold'
                    },
                }}
                name={'title'}
                autoFocus={true}
                onChange={handleTitleChange}
                disabled={!isActionEditable}
                startAdornment={matchingTranslation.origin === 'GOOGLE_TRANSLATE' && <GoogleIcon/>}
            />
        </Flex>
    );
};

const ResponsibleLine = (props) => {
    const {classes, action, handleResponsibleChange, users, isPersonalAction, isActionEditable} = props;
    return (
        <Flex item basis={'60%'} style={{maxWidth: '60%'}}>
            <Typography className={classes.fieldTitle}><Trans
                i18nKey={"task-management.action-edit.responsible"}/></Typography>
            <Responsible
                {...{
                    previousAssignee: _.get(action, 'delta.user', action.assignee),
                    dateTime: _.get(action, 'delta.dateTime'),
                    users,
                    assignee: action.assignee,
                    handleResponsibleChange,
                    backgroundColor: htmlWhite,
                    isDisabled: isPersonalAction || !isActionEditable,
                }}
            />
        </Flex>
    );
};

const Priority = (props) => {
    const {classes, action, handlePriorityChange, isActionEditable} = props;
    return (
        <Flex item container alignItems={'flex-end'}>
            <Flex item basis={'40%'} container justifyContent={'flex-end'} alignItems={'center'}>
                <Checkbox
                    classes={{root: classes.doneCheckBoxRoot}}
                    checked={action.priority.type === 'URGENT'}
                    onChange={handlePriorityChange}
                    icon={<CheckBoxOutlineBlankIcon style={{fontSize: 30}}/>}
                    checkedIcon={<CheckBoxIcon style={{fontSize: 30}}/>}
                    disabled={!isActionEditable}
                />
                <Typography className={classes.inlineTitle} style={{marginLeft: 5}}><Trans
                    i18nKey={"task-management.action-edit.urgent"}/></Typography>
            </Flex>
        </Flex>
    );
};

const EstimatedEffort = (props) => {
    const {classes, action, handleNumberChangeOnAction, isActionEditable} = props;
    return (
        <Flex item basis={0} container direction={'column'}>
            <Flex item grow={0}>
                <Typography className={classes.fieldTitle}><Trans
                    i18nKey={"task-management.action-edit.estimatedEffort"}/></Typography>
            </Flex>
            <Flex item grow={0} container alignItems={'center'}>
                <Flex item basis={50}>
                    <BootstrapInput
                        style={{maxWidth: 50}}
                        value={action.estimatedEffort || ''}
                        onChange={handleNumberChangeOnAction}
                        variant="outlined"
                        name={'estimatedEffort'}
                        type={'number'}
                        disabled={!isActionEditable}
                    />
                </Flex>
                <Flex item padding={5}>
                    <Typography className={classes.inlineTitle} style={{display: 'inline'}}>
                        <Trans i18nKey={"task-management.action-edit.hours"}/>
                    </Typography>
                </Flex>
            </Flex>
        </Flex>
    );
};

const TimeSpent = (props) => {
    const {classes, action, handleNumberChangeOnAction, isActionEditable} = props;
    return (
        <Flex item container basis={0} justifyContent={'flex-end'}>
            <Flex item container grow={0} direction={'column'}>

                <Flex item grow={0}>
                    <Typography className={classes.fieldTitle}><Trans
                        i18nKey={"task-management.action-edit.timeSpent"}/></Typography>
                </Flex>
                <Flex item grow={0} container alignItems={'center'}>
                    <Flex item grow={0} basis={50}>
                        <BootstrapInput
                            style={{maxWidth: 50}}
                            value={action.timeSpent || ''}
                            onChange={handleNumberChangeOnAction}
                            name={'timeSpent'}
                            variant="outlined"
                            type={'number'}
                            disabled={!isActionEditable}
                        />
                    </Flex>
                    <Flex item style={{paddingLeft: 5}}>
                        <Typography className={classes.inlineTitle} style={{display: 'inline'}}>
                            <Trans i18nKey={"task-management.action-edit.hours"}/>
                        </Typography>
                    </Flex>
                </Flex>
            </Flex>
        </Flex>
    );
};

const Progress = (props) => {
    const {classes, handleProgressChange, action, handleStatusChange, isActionEditable} = props;
    return (
        <Flex item grow={0} container direction={'column'}>
            <Flex item>
                <Typography className={classes.fieldTitle}><Trans
                    i18nKey={"task-management.action-edit.progress"}/></Typography>
            </Flex>
            <Flex item>
                <Flex item style={{marginBottom: 5}}>
                    <BlueGreenProgressBar value={action.progress} style={{height: 6}}/>
                </Flex>
                <Flex item container>
                    <Flex item grow={0} container alignItems={'center'}>
                        <Flex item={'0 0 50px'} container alignItems={'center'}>
                            <BootstrapInput
                                value={action.progress}
                                onChange={handleProgressChange}
                                name={'progress'}
                                variant="outlined"
                                type={'number'}
                                disabled={!isActionEditable}
                            />
                        </Flex>
                        <Flex item padding={5}>
                            <Typography className={classes.inlineTitle} style={{display: 'inline'}}>%</Typography>
                        </Flex>
                    </Flex>
                    <Flex item container alignItems={'center'} justifyContent={'flex-end'}>
                        <Flex item grow={0}>
                            <Checkbox
                                classes={{root: classes.doneCheckBoxRoot}}
                                checked={action.status === 'DONE'}
                                onChange={handleStatusChange}
                                icon={<CheckBoxOutlineBlankIcon style={{fontSize: 30}}/>}
                                checkedIcon={<CheckBoxIcon style={{fontSize: 30, color: christiGreen}}/>}
                                disabled={!isActionEditable}
                            />
                        </Flex>
                        <Flex item grow={0} style={{marginLeft: 18}}>
                            <Typography className={classes.inlineTitle}><Trans
                                i18nKey={"task-management.action-edit.done"}/></Typography>
                        </Flex>
                    </Flex>
                </Flex>
            </Flex>
        </Flex>
    );
};

const SolveUntil = (props) => {
    const {classes, action, maxDueDate, handleDueDateChange, isActionEditable} = props;
    return (
        <Flex item container direction={'column'} style={{marginBottom: 5}}>
            <Flex item>
                <Typography style={{whiteSpace: 'nowrap'}} className={classes.fieldTitle}>
                    <Trans i18nKey={"task-management.action-edit.solveUntil"}/>
                </Typography>
            </Flex>
            <Flex item>
                <Flex item style={{marginBottom: 5}}>
                    <WorkDoneProgressBar value={action.dueDateProgress} style={{height: 6}}/>
                </Flex>
                <Flex item>
                    <BootstrapDatepicker
                        fullWidth
                        value={action.dueDate}
                        maxDate={maxDueDate}
                        onChange={handleDueDateChange}
                        name={'dueDate'}
                        variant="outlined"
                        disablePast
                        minDateMessage={''}
                        format={utils.DATE_FORMAT}
                        clearable
                        disabled={!isActionEditable}
                    />
                </Flex>
            </Flex>
        </Flex>
    );
};

const FooterButtons = (props) => {
    const {
        classes, save, isSaveDisabled, routeProps, saveWithOptions, cancelDelete, deleteAction, user, isSelected
    } = props;
    return (
        <Flex item container className={classes.controlButtonBox}
              style={{marginTop: 10, padding: 5}}
        >
            <Flex item container justifyContent={'flex-start'}>
                {
                    isSelected &&
                    <Flex item>
                        <IconButton
                            component={Link}
                            to={utils.joinUrl(routeProps.match.url, '/delete')}
                            style={{backgroundColor: !user.isAdmin ? silver : guardsmanRed, padding: 8}}
                            disabled={!user.isAdmin}
                        >
                            <DeleteIcon style={{color: htmlWhite}}/>
                        </IconButton>
                    </Flex>
                }
            </Flex>
            <Flex item grow={0} container justifyContent={'flex-end'}>
                <Flex item>
                    <IconButton
                        onClick={() => {
                            save(true)
                        }}
                        style={{backgroundColor: doveGrey, marginRight: 5, padding: 8}}
                    ><CloseIcon style={{color: htmlWhite}}/></IconButton>
                </Flex>
                <Flex item>
                    <IconButton onClick={save}
                                disabled={isSaveDisabled}
                                style={{backgroundColor: isSaveDisabled ? silver : swipeActionGreen, padding: 8}}
                    >
                        <DoneIcon style={{color: htmlWhite}}/>
                    </IconButton>
                </Flex>
            </Flex>
            <Route path={routeProps.match.url + '/overwrite'} render={

                function renderTranslationOverWriteDialog(props) {
                    return <TranslationChangedDialog
                        {...{
                            originUrl: routeProps.match.url,
                            saveWithOptions,
                        }}
                    />
                }
            }/>
            <Route path={routeProps.match.url + '/delete'} render={

                function renderDeleteTaskDialog(props) {
                    return <ConfirmationDialog {...{
                        dialogOpen: true,
                        onDialogClose: cancelDelete,
                        onConfirm: deleteAction,
                        confirmationTextKey: "task-management.deleteActionConfirmation"
                    }}/>
                }
            }/>
        </Flex>
    );
};

export class MobileActionEdit extends Component {
    state = {
        goBackAfterSave: false
    };

    save = goBack => {
        taskUtils.unBoundSaveLogic.bind(this)(goBack);
    };

    saveWithOptions = forceTranslate => {
        taskUtils.unBoundSaveWithOptionsLogic.bind(this)(forceTranslate);
    };

    deleteAction = () => {
        this.props.leaveEdit();
        this.props.archiveAction(this.props.action).then(() => {
            routeTo(this.props.prevPath, this.props.routeProps);
        });
    };

    cancelDelete = () => {
        this.props.routeProps.history.goBack();
    };

    render() {
        const {
            classes, t: translate, isSaveDisabled, action, newActionRefId, user, users, maxDueDate, matchingTranslation,
            isSelected, hasEditRight, applicationOnline, isOfflineCreated,
            routeProps, handleNumberChangeOnAction, handleDueDateChange, handlePriorityChange, handleProgressChange,
            handleStatusChange, handleResponsibleChange, handleDescriptionChange, leaveEdit, handleTitleChange, prevPath,
            isTranslationChanged, isPersonalAction
        } = this.props;
        const {save, saveWithOptions, deleteAction, cancelDelete} = this;
        const actionBusinessId = _.get(action, 'businessId');
        const actionBusinessIdLabel = isSelected
            ? utils.startCase(actionBusinessId.startsWith('OFFLINE') ? 'OFFLINE-' + translate('global.action') : actionBusinessId)
            : translate('global.create', {type: translate('global.action')});
        const isActionEditable = hasEditRight;
        return (
            <Fragment>
                <Flex container item style={{marginTop: 10}}>
                    <Flex item container style={{
                        maxWidth: 5,
                        width: 5,
                        backgroundColor: getColorbyPriority(action.priority.type)
                    }}>
                    </Flex>
                    <Flex container item direction={'column'}>
                        <ActionHeader {...{
                            actionBusinessIdLabel,
                            isOfflineCreated,
                            applicationOnline,
                            newActionRefId,
                            isSelected,
                            actionBusinessId,
                            action
                        }}/>
                        <Flex container item direction={'column'}
                              style={{
                                  paddingLeft: 5,
                                  paddingRight: 5,
                                  minHeight: '70vh',
                                  backgroundColor: auditPaperGrey
                              }}>
                            <Title {...{classes, action, matchingTranslation, handleTitleChange, isActionEditable}}/>

                            <Flex container item grow={0}>
                                <ResponsibleLine {...{
                                    classes,
                                    action,
                                    handleResponsibleChange,
                                    users,
                                    isActionEditable,
                                    isPersonalAction
                                }}/>
                                <Priority {...{classes, action, handlePriorityChange, isActionEditable}}/>
                            </Flex>

                            <MobileDescription {...{
                                isEditable: isActionEditable,
                                matchingTranslation,
                                handleDescriptionChange,
                                businessId: actionBusinessId || newActionRefId,
                                referenceType: 'ACTION',
                                borderColor: materialUIBorder
                            }}/>

                            <Flex item grow={0} container>
                                <EstimatedEffort {...{classes, action, handleNumberChangeOnAction, isActionEditable}}/>
                                <TimeSpent {...{classes, action, handleNumberChangeOnAction, isActionEditable}}/>
                            </Flex>

                            <Progress {...{
                                classes,
                                handleProgressChange,
                                action,
                                handleStatusChange,
                                isActionEditable
                            }}/>

                            <SolveUntil {...{classes, action, maxDueDate, handleDueDateChange, isActionEditable}}/>


                        </Flex>
                    </Flex>
                </Flex>
                <FooterButtons {...{
                    classes,
                    user,
                    save,
                    isSaveDisabled,
                    isTranslationChanged,
                    routeProps,
                    prevPath,
                    leaveEdit,
                    saveWithOptions,
                    deleteAction,
                    cancelDelete,
                    isSelected,
                }}/>
            </Fragment>
        );
    }
}

export default withStyles(styles)(withTranslation()(MobileActionEdit));
