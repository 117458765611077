import React, {Component} from 'react';
import {withStyles, Snackbar, SnackbarContent} from "@material-ui/core";
import {withTranslation} from "react-i18next";
import {laPalmaGreen, htmlWhite} from "components/colors/Colors";

const styles = theme => ({
    snackbar: {
        paddingBottom: 10,
    },
    snackbarContent: {
        backgroundColor: laPalmaGreen,
        justifyContent: 'center'
    },
    snackbarText: {
        fontSize: 14,
        color: htmlWhite,
    },
});

export class EmailSentMessage extends Component {
    render() {
        const {classes, open, onClose, recipient, t: translate} = this.props;

        return (
            <Snackbar
                className={classes.snackbar}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                open={open}
                onClose={onClose}
                autoHideDuration={2500}
            >
                <SnackbarContent
                    className={classes.snackbarContent}
                    message={
                        <span className={classes.snackbarText}>
                            {translate('global.email-sent', {username: recipient})}
                        </span>
                    }
                />
            </Snackbar>
        )
    }
}

export default withStyles(styles)(withTranslation()(EmailSentMessage))