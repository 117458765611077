import React, { Component, Fragment } from 'react';
import { withStyles } from "@material-ui/core";

const styles = theme => ({
});

export class Image extends Component {
    render() {
        // const {classes} = this.props;

        return (
            <Fragment>
                Image
            </Fragment>
        );
    }
}

export default withStyles(styles)(Image);
