import React, {Component} from 'react';
import utils from 'utils/Utils';
import Flex from 'components/grid/Flex';
import {connect} from 'react-redux';
import connector from './ActionList.connect';
import MobileActionList from './mobile/MobileActionList';
import {routeTo, TASK_ACTION_LIST, TASK_EDIT} from 'routes/routes';
import {withTranslation} from 'react-i18next';
import _ from 'lodash';
import {Redirect} from 'react-router-dom';

export class ActionList extends Component {

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {task} = this.props;
        const isPreviousBusinessIdOffline = _.get(prevProps, 'task.businessId', '').startsWith('OFFLINE');
        const isCurrentBusinessIdOnline = _.isFinite(Number(task.businessId));
        if (isPreviousBusinessIdOffline && isCurrentBusinessIdOnline) {
            routeTo(TASK_ACTION_LIST.pathFromOfflineToId(this.props.location.pathname, task.businessId), this.props);
        }
    }

    render() {
        const {
            task, actions, match, taskBusinessId, applicationOnline, location, history
        } = this.props;
        const isMobile = utils.checkBrowser();
        return (
            <Flex item container direction={'column'}>
                {
                    isMobile
                        ?
                        <MobileActionList
                            {...{
                                task,
                                actions,
                                url: match.url,
                                applicationOnline,
                                match,
                                location,
                                history,
                                taskBusinessId
                            }}
                        />
                        :
                        <Redirect to={TASK_EDIT.pathWithId(taskBusinessId)}/>
                }

            </Flex>
        );
    }
}

export default connect(connector.mapStateToProps, connector.mapDispatchToProps)(withTranslation()(ActionList));
