import React, {PureComponent} from 'react';
import {withStyles, Dialog, CircularProgress, DialogTitle, DialogContent} from "@material-ui/core";
import {withTranslation} from "react-i18next";

const styles = theme => ({
    dialogContentRoot: {
        display: 'flex',
        justifyContent: 'center'
    },
});

export class SynchronizationOverlay extends PureComponent {
    preventEventPropagation = event => {
        event.preventDefault();
        event.stopPropagation();
    };

    render() {
        const {classes, isOverlayActive, t: translate} = this.props;

        return (

            <Dialog
                open={isOverlayActive}
                disableEscapeKeyDown
                aria-labelledby="synchronization-overlay"
                onClick={this.preventEventPropagation}
            >
                <DialogTitle id="synchronization-overlay-title">{translate('global.synchronizationMessage')}</DialogTitle>
                <DialogContent classes={{root: classes.dialogContentRoot}}>
                    <CircularProgress size={24}/>
                </DialogContent>
            </Dialog>
        );
    }
}

export default withStyles(styles)(withTranslation()(SynchronizationOverlay));
