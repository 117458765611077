import React, {Component, Fragment} from 'react';
import {Checkbox, IconButton, Slide, Tooltip, Typography, withStyles} from "@material-ui/core";
import {connect} from "react-redux";
import connector from "./Monitoring.connect";
import {Trans, withTranslation} from "react-i18next";
import Flex from "components/grid/Flex";
import {
    auditHighlight,
    christiGreen,
    ghostGrey,
    htmlBlue,
    htmlGreen,
    htmlWhite,
    random,
    silver,
} from "components/colors/Colors";
import moment from "moment";
import H4Avatar from "components/avatar/H4Avatar";
import update from "immutability-helper";
import _ from "lodash";
import utils from "utils/Utils";
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import ReactResizeDetector from 'react-resize-detector';
import {getHighlightColor} from "components/colors/Result";
import UpdateAuditorDialog from "./components/UpdateAuditorDialog";
import AuditDetailsDialog from "./components/AuditDetailsDialog";
import Snackbar from "@material-ui/core/Snackbar";
import SnackbarContent from "@material-ui/core/SnackbarContent";
import CloseIcon from "@material-ui/icons/Close";
import {RANDOM} from "domain/audit/Schedule.model";
import ConfirmationDialog from "components/confirmation-dialog/ConfirmationDialog";
import DailyAudit from './components/DailyAudit';
import LongAudit from './components/LongAudit';
import MonitoringColumns from './components/MonitoringColumns';
import SynchronizationEventBus from "../../../SynchronizationEventBus";

const styles = theme => ({
    paper: {
        marginTop: 20,
        paddingBottom: 20,
        marginLeft: 10,
        backgroundColor: htmlWhite,
    },
    filterContainer: {
        paddingBottom: 5,
        paddingLeft: 20,
        paddingRight: 20
    },
    checkBoxIcon: {
        fill: christiGreen
    },
    snackbar: {
        paddingBottom: 10,
    },
    snackbarContent: {
        backgroundColor: htmlGreen,
        justifyContent: 'center'
    },
    snackbarText: {
        fontSize: 12,
        color: htmlWhite,
    },
    action: {
        margin: 0,
    },
    absoluteContainer: {
        overflow: 'auto',
        overflowScrolling: 'touch',
        position: 'absolute',
        bottom: 0,
        right: 0,
        left: 0,
        top: 0,
        paddingLeft: 20,
        paddingRight: 20
    },
    avatarTooltipLabel: {
        fontSize: 10
    }
});

const AuditorAvatarFilter = (props) => {
    const {auditor, classes, selectedUserNames, toggleUserSelection, translate} = props;
    return (
        <Flex item={'0 1 0'} style={{
            position: 'relative'
        }}>
            <Tooltip
                title={_.isEmpty(auditor) ? translate('audit-administration.monitoringView.random') : utils.formattedUserName(auditor)}
                placement={'bottom-start'}
                classes={{
                    tooltip: classes.avatarTooltipLabel
                }}
            >
                <H4Avatar
                    {...{user: auditor}}

                    style={{
                        fontSize: 12.5,
                        color: auditor.username === RANDOM ? random : htmlWhite,
                        marginLeft: 3,
                        border: selectedUserNames.includes(auditor.username)
                            ? `2px solid ${htmlBlue}`
                            : 'none'
                    }}
                    onClick={() => toggleUserSelection(auditor)}
                />
            </Tooltip>
        </Flex>
    );
};

const MonitoringFilters = (props) => {
    const {classes, toggleUserSelection, selectedUserNames, auditors, showOpenOnly, toggleShowOpenOnly, translate} = props;
    return (
        <Flex container item shrink={1} grow={0} className={classes.filterContainer}>
            <Flex container item style={{
                paddingTop: 10,
                paddingLeft: 10
            }}>
                <Flex item grow={0} container alignItems={'center'}>
                    <Checkbox
                        checked={showOpenOnly}
                        style={{padding: 5, marginRight: 5}}
                        onChange={toggleShowOpenOnly}
                        icon={<CheckBoxOutlineBlankIcon style={{fontSize: 20}}/>}
                        checkedIcon={<CheckBoxIcon style={{fontSize: 20}} className={classes.checkBoxIcon}/>}
                    />
                    <Typography>
                        <Trans
                            i18nKey={"audit-administration.monitoringView.showOpenOnly"}/>
                    </Typography>
                </Flex>
            </Flex>
            {
                !_.isEmpty(auditors) &&
                <Flex container item justifyContent={'flex-end'}>
                    {
                        auditors.length !== 0 &&
                        auditors.map((auditor, index) => (
                                <AuditorAvatarFilter key={index} {...{
                                    auditor,
                                    classes,
                                    selectedUserNames,
                                    toggleUserSelection
                                }} />
                            )
                        )
                    }
                    <AuditorAvatarFilter {...{
                        auditor: {}, classes, selectedUserNames, toggleUserSelection, translate
                    }} />
                </Flex>
            }
        </Flex>
    )
};

const EmptyAudit = (props) => {
    const {columnWidth} = props;
    return (
        <Flex item style={{
            marginTop: 10,
            width: columnWidth,
            marginRight: 10,
            height: 75
        }}
        />
    );
};

const DailyAuditColumns = (props) => {
    const {
        columnWidth, dailyAuditColumns, getTileColor, openAuditorUpdateDialog, hoveredAudit, onAuditHovered, user,
        openAuditDetailsDialog, isAuditorChangeAllowed, sendAuditReminder, toggleCancelAuditScheduleDialog,
    } = props;
    return (
        <Flex item container style={{
            marginLeft: 5,
            marginRight: 5
        }}>
            {
                dailyAuditColumns.map((column, index) => (
                    <Flex item container direction='column' key={index}>
                        {
                            column.map((cell, index) => (
                                _.isEmpty(cell) ?
                                    <EmptyAudit key={index} {...{columnWidth}}/>
                                    :
                                    <DailyAudit key={index} {...{
                                        cell, columnWidth, getTileColor, openAuditorUpdateDialog, hoveredAudit,
                                        openAuditDetailsDialog, isAuditorChangeAllowed, sendAuditReminder,
                                        user, toggleCancelAuditScheduleDialog, onAuditHovered,
                                    }}/>
                            ))
                        }
                    </Flex>
                ))
            }
        </Flex>
    );
};

const LongAuditRows = (props) => {
    const {
        columnWidth, longAuditRows, getTileColor, openAuditorUpdateDialog, openAuditDetailsDialog, hoveredAudit,
        isAuditorChangeAllowed, sendAuditReminder, user, toggleCancelAuditScheduleDialog, onAuditHovered,
    } = props;
    return (
        <Fragment>
            {
                longAuditRows.map((row, index) => (
                    <Flex key={index} container item style={{
                        marginLeft: 5,
                        marginRight: 5
                    }}>
                        <LongAudit {...{
                            columnWidth, row, longAuditRows, user, toggleCancelAuditScheduleDialog, sendAuditReminder,
                            getTileColor, openAuditorUpdateDialog, onAuditHovered, hoveredAudit, isAuditorChangeAllowed,
                            openAuditDetailsDialog
                        }}/>
                    </Flex>
                ))
            }
        </Fragment>
    );
};

function TransitionDown(props) {
    return <Slide {...props} direction="down"/>;
}

export class Monitoring extends Component {

    state = {
        selectedUserNames: [],
        dailyAuditColumns: [],
        longAuditRows: [],
        showOpenOnly: false,
        columnWidth: 0,
        columnHeight: 0,
        auditorUpdateDialogOpen: false,
        auditDetailsDialogOpen: false,
        selectedAudit: {},
        deviations: [],
        snackbarOpen: false,
        auditScheduleCancellationCandidate: {},
        hoveredAudit: {}
    };

    columnRef = React.createRef();

    contentRef = React.createRef();

    componentDidMount() {
        this.initializeData();
        this.synchronizationSubscription = SynchronizationEventBus.update.subscribe(() => this.initializeData());
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if ((_.isEmpty(prevProps.multiSelectedOrganizationLevels) && !_.isEmpty(this.props.multiSelectedOrganizationLevels))
            || prevProps.multiSelectedOrganizationLevels.length !== this.props.multiSelectedOrganizationLevels.length) {
            this.fetchAudits();
        }
    }

    componentWillUnmount() {
        this.props.clearFilterMultiSelection();
        SynchronizationEventBus.update.unSubscribe(this.synchronizationSubscription);
    }

    initializeData = () => {
        if (!_.isEmpty(this.props.multiSelectedOrganizationLevels)) {
            this.fetchAudits();
        }
    };

    fetchAudits = () => {
        const organizationLevelIds = this.props.multiSelectedOrganizationLevels
            .filter(multiSelectedOrganizationLevel => {
                return multiSelectedOrganizationLevel.accessible;
            })
            .map(value => value.businessId);
        const start = this.props.supportedWeekdays[0];
        const end = this.props.supportedWeekdays[this.props.supportedWeekdays.length - 1];
        this.props.fetchAudits(organizationLevelIds, start, end).then(() => {
            this.setState({
                dailyAuditColumns: this.props.dailyAuditColumns,
                longAuditRows: this.props.longAuditRows
            }, this.filterAudits)
        });
    };

    toggleShowOpenOnly = () => {
        this.setState({
            showOpenOnly: !this.state.showOpenOnly
        }, this.filterAudits);
    };

    toggleUserSelection = (auditor) => {
        const indexOfUser = this.state.selectedUserNames.indexOf(auditor.username);
        if (indexOfUser !== -1) {
            this.setState(oldState => {
                return {
                    selectedUserNames: update(oldState.selectedUserNames, {
                        $splice: [[indexOfUser, 1]]
                    })
                }
            }, this.filterAudits);
        } else {
            this.setState(oldState => {
                return {
                    selectedUserNames: update(oldState.selectedUserNames, {
                        $push: [auditor.username]
                    })
                }
            }, this.filterAudits);
        }
    };

    filterAudits = () => {
        const selectedUserNames = this.state.selectedUserNames;
        const showOpenOnly = this.state.showOpenOnly;
        this.filterDailyAudits(showOpenOnly, selectedUserNames);
        this.filterLongAudits(showOpenOnly, selectedUserNames);
    };

    filterDailyAudits = (showOpenOnly, selectedUserNames) => {
        const filteredDailyAuditColumns = _.cloneDeep(this.props.dailyAuditColumns).map(column => {
            return column.map(audit => {
                if (_.isEmpty(audit)) {
                    return audit;
                } else if (showOpenOnly && !audit.open) {
                    return null;
                } else if (_.isEmpty(selectedUserNames)) {
                    return audit;
                } else {
                    if (!_.isEmpty(audit.auditor)) {
                        return selectedUserNames.includes(audit.auditor.username) ? audit : null;
                    } else {
                        return selectedUserNames.includes(RANDOM) ? audit : null;
                    }
                }
            })
        });
        this.setState({
            dailyAuditColumns: this.consolidateDailyAuditRows(filteredDailyAuditColumns)
        }, this.resize)
    };

    filterLongAudits = (showOpenOnly, selectedUserNames) => {
        const filteredLongAudits = _.cloneDeep(this.props.longAuditRows).filter(row => {
            const audit = row.audit;
            if (showOpenOnly && !audit.open) {
                return false;
            } else if (_.isEmpty(selectedUserNames)) {
                return true;
            } else {
                if (_.isEmpty(audit.auditor)) {
                    return false;
                } else {
                    return selectedUserNames.includes(audit.auditor.username);
                }
            }
        });

        this.setState({
            longAuditRows: filteredLongAudits
        }, this.resize)
    };

    consolidateDailyAuditRows = (dailyAuditColumns) => {
        const length = dailyAuditColumns[0].length;
        for (let i = 0; i < length; i++) {
            let emptyLine = true;
            dailyAuditColumns.forEach(column => {
                if (!_.isEmpty(column[i])) {
                    emptyLine = false;
                }
            });
            if (emptyLine) {
                dailyAuditColumns.map((column) => {
                    delete column[i];
                    return column;
                })
            }
        }
        return dailyAuditColumns;
    };

    resize = () => {
        this.setState({
            columnWidth: _.get(this.columnRef.current, 'clientWidth'),
            columnHeight: _.get(this.contentRef.current, 'clientHeight')
        })
    };

    getTileColor = (audit) => {
        if (audit.missed) {
            return silver;
        } else if (audit.done) {
            return getHighlightColor(audit.result);
        } else if (audit.open) {
            return auditHighlight;
        } else {
            return ghostGrey;
        }
    };

    openAuditorUpdateDialog = (audit) => () => {
        if (this.isAuditorChangeAllowed(audit, this.props.user)) {
            this.setState({
                auditorUpdateDialogOpen: true,
                selectedAudit: audit
            })
        }
    };

    isAuditorChangeAllowed = (audit) => {
        const isAdmin = audit.organizationLevel.members
            .find(member => _.get(member, 'user.businessId') === _.get(this.props, 'user.businessId')
                && member.memberships.includes('ADMIN')
            );
        return isAdmin && audit.open && !_.isEmpty(audit.businessId);
    };

    closeAuditorUpdateDialog = () => {
        this.setState({
            auditorUpdateDialogOpen: false,
            selectedAudit: {}
        })
    };

    openAuditDetailsDialog = (audit) => () => {
        if (audit.done) {
            this.props.fetchDeviations(audit.businessId).then(data => {
                this.setState({
                    auditDetailsDialogOpen: true,
                    selectedAudit: audit,
                    deviations: this.props.deviations
                })

            });
        }
    };

    closeAuditDetailsDialog = () => {
        this.setState({
            auditDetailsDialogOpen: false,
            selectedAudit: {},
            deviations: []
        })
    };

    updateAuditor = (audit, auditor) => {
        this.props.updateAuditor(audit.businessId, auditor.businessId)
            .then(() => {
                this.fetchAudits();
                this.closeAuditorUpdateDialog();
            });
    };

    handleSnackbarClose = () => {
        this.setState({
            snackbarOpen: false,
        });
    };

    sendAuditReminder = (audit) => {
        this.props.sendAuditReminder(audit.businessId).then(() => {
            this.setState({
                snackbarOpen: true,
                dailyAuditColumns: this.props.dailyAuditColumns,
                longAuditRows: this.props.longAuditRows
            });
        })
    };

    toggleCancelAuditScheduleDialog = (audit) => {
        this.setState({
            auditScheduleCancellationCandidate: audit
        })
    };

    cancelAuditSchedule = () => {
        this.props.cancelScheduledAudit(this.state.auditScheduleCancellationCandidate.scheduleId, this.state.auditScheduleCancellationCandidate.interval.start).then(() => {
            this.setState({
                auditScheduleCancellationCandidate: {}
            }, this.fetchAudits)
        })
    };

    onAuditHovered = (audit) => () => {
        this.setState({
            hoveredAudit: audit
        })
    };

    render() {
        const {classes, t: translate, supportedWeekdays, auditors, multiSelectedOrganizationLevels, user} = this.props;
        const {
            toggleUserSelection, toggleShowOpenOnly, columnRef, contentRef, resize, getTileColor, cancelAuditSchedule,
            openAuditorUpdateDialog, closeAuditorUpdateDialog, updateAuditor, openAuditDetailsDialog, onAuditHovered,
            isAuditorChangeAllowed, handleSnackbarClose, sendAuditReminder, toggleCancelAuditScheduleDialog,
            closeAuditDetailsDialog
        } = this;
        const {
            selectedUserNames, showOpenOnly, columnWidth, columnHeight, dailyAuditColumns, longAuditRows, selectedAudit,
            auditorUpdateDialogOpen, auditDetailsDialogOpen, deviations, snackbarOpen, hoveredAudit,
            auditScheduleCancellationCandidate
        } = this.state;
        const selectedAuditOrganizationLevel = multiSelectedOrganizationLevels
            .find(orgLevel => orgLevel.businessId === _.get(selectedAudit, 'organizationLevel.businessId'));
        return (
            <Flex container item style={{marginRight: 20}}>
                {
                    !_.isEmpty(auditScheduleCancellationCandidate) &&
                    <ConfirmationDialog {...{
                        dialogOpen: !_.isEmpty(auditScheduleCancellationCandidate),
                        onDialogClose: toggleCancelAuditScheduleDialog,
                        onConfirm: cancelAuditSchedule,
                        confirmationTextKey: "audit-administration.monitoringView.cancelAuditSchedule",
                        confirmationTexValues: {
                            nextAuditDate: translate('global.dateTimeFormats.monthAndDay', {
                                month: translate('global.month.' + moment(auditScheduleCancellationCandidate.nextAuditDate).format("MMMM")).substring(0, 3),
                                day: moment(auditScheduleCancellationCandidate.nextAuditDate).format("DD")
                            })
                        }
                    }}/>
                }
                {
                    auditorUpdateDialogOpen &&
                    <UpdateAuditorDialog {...{
                        organizationLevel: selectedAuditOrganizationLevel,
                        audit: selectedAudit,
                        auditors: _.get(selectedAuditOrganizationLevel, 'members').filter(auditors => auditors.memberships.includes('AUDITOR')),
                        updateAuditor: updateAuditor,
                        dialogOpen: auditorUpdateDialogOpen,
                        closeDialog: closeAuditorUpdateDialog,
                        user
                    }}/>
                }
                {
                    auditDetailsDialogOpen &&
                    <AuditDetailsDialog {...{
                        audit: selectedAudit,
                        dialogOpen: auditDetailsDialogOpen,
                        closeDialog: closeAuditDetailsDialog,
                        user,
                        getTileColor,
                        deviations
                    }}/>}
                {
                    <Flex item container className={classes.paper} direction={'column'}>
                        <Flex item={'0 1 0'}>
                            <MonitoringFilters
                                {...{
                                    classes,
                                    toggleUserSelection,
                                    selectedUserNames,
                                    auditors,
                                    toggleShowOpenOnly,
                                    showOpenOnly,
                                    translate
                                }}
                            />
                        </Flex>
                        <Flex item container style={{position: 'relative'}}>
                            <Flex item container className={classes.absoluteContainer}>
                                <MonitoringColumns {...{
                                    days: supportedWeekdays,
                                    columnRef,
                                    columnHeight,
                                }}/>
                                <ReactResizeDetector handleWidth onResize={resize}>
                                    <Flex container direction={"column"} style={{
                                        position: 'absolute',
                                        marginTop: 75,
                                        marginRight: 5,
                                        marginLeft: 5,
                                    }}>
                                        <div ref={contentRef}>
                                            <DailyAuditColumns {...{
                                                columnWidth,
                                                dailyAuditColumns,
                                                getTileColor,
                                                openAuditorUpdateDialog,
                                                openAuditDetailsDialog,
                                                isAuditorChangeAllowed,
                                                sendAuditReminder,
                                                user,
                                                toggleCancelAuditScheduleDialog,
                                                onAuditHovered, hoveredAudit
                                            }}/>
                                            <LongAuditRows  {...{
                                                columnWidth,
                                                longAuditRows,
                                                getTileColor,
                                                openAuditorUpdateDialog,
                                                openAuditDetailsDialog,
                                                isAuditorChangeAllowed,
                                                sendAuditReminder,
                                                user,
                                                toggleCancelAuditScheduleDialog,
                                                onAuditHovered, hoveredAudit
                                            }}/>
                                        </div>
                                    </Flex>
                                </ReactResizeDetector>
                            </Flex>
                        </Flex>
                    </Flex>
                }
                <Snackbar
                    className={classes.snackbar}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    open={snackbarOpen}
                    TransitionComponent={TransitionDown}
                >
                    <SnackbarContent
                        className={classes.snackbarContent}
                        classes={{
                            action: classes.action
                        }}
                        message={
                            <div>
                                <span className={classes.snackbarText}>
                                     <Trans i18nKey={"audit-administration.monitoringView.notificationSent"}/>
                                </span>
                            </div>
                        }
                        action={[
                            <IconButton style={{padding: 6}} key={'snackbarAction'}
                                        onClick={handleSnackbarClose}>
                                <CloseIcon style={{fontSize: 25}}/>
                            </IconButton>
                        ]}
                    />
                </Snackbar>
            </Flex>
        );
    }
}

export default withStyles(styles)(connect(connector.mapStateToProps,
    connector.mapDispatchToProps)(withTranslation()(Monitoring)));
