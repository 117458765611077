import * as actionTypes from './QuestionnaireEdit.action.types'
import {mapQuestionnaireForCreate, mapQuestionnaireForEdit} from 'domain/audit/Questionnaire.model'
import {USER_GRAPHQL} from "domain/User.model";
import {TOPIC_GRAPHQL} from "domain/audit/Topic.model";

export function createQuestionnaire(data) {
    return (dispatch) => {
        const input = mapQuestionnaireForCreate(data);
        return dispatch({
            type: actionTypes.CREATE_QUESTIONNAIRE,
            payload: {
                client: 'graphql',
                request: {
                    method: 'post',
                    data: {
                        query: `mutation createQuestionnaire($createQuestionnaireInput: CreateQuestionnaireInput){
    	                        createQuestionnaire(createQuestionnaireInput: $createQuestionnaireInput) {   
    	                            businessId   	                            
    	                        }
                            }
                           `,
                        variables: {
                            createQuestionnaireInput: input
                        }
                    }
                }
            }
        });
    }
}

export function editQuestionnaire(data) {
    return (dispatch) => {
        const input = mapQuestionnaireForEdit(data);
        return dispatch({
            type: actionTypes.EDIT_QUESTIONNAIRE,
            payload: {
                client: 'graphql',
                request: {
                    method: 'post',
                    data: {
                        query: `mutation editQuestionnaire($editQuestionnaireInput: EditQuestionnaireInput){
    	                        editQuestionnaire(editQuestionnaireInput: $editQuestionnaireInput) {   
    	                            businessId   	                            
    	                        }
                            }
                           `,
                        variables: {
                            editQuestionnaireInput: input
                        }
                    }
                }
            }
        });
    }
}

export function fetchQuestionnaire(businessId) {
    return (dispatch, getState) => {
        return dispatch({
            type: actionTypes.FETCH_QUESTIONNAIRE,
            data: {
                user: getState().mainReducer.user,
            },
            payload: {
                client: 'graphql',
                request: {
                    method: 'post',
                    data: {
                        query: `
                                query
                                ($questionnaireId: String!){
                                    fetchQuestionnaire(questionnaireId: $questionnaireId) {
                                        businessId
                                        name
                                        status
                                        numberOfQuestions
                                        numberOfRandomizedQuestions
                                        catalogue
                                        cycle {
                                            businessId
                                            label                                       
                                        }      
                                        questions {
                                            businessId
                                            catalogue {
                                                label
                                            }
                                            translations {
                                                language {
                                                    code
                                                    label
                                                    ordinal
                                                }
                                                question
                                                status
                                            }
                                            status
                                            tags {
                                                label
                                                category
                                            }
                                        }
                                        randomizedQuestions {
                                            numberOfQuestions
                                            catalogue
                                        }
                                        organizationLevels {
                                             shortName
                                             businessId
                                             members {
                                                memberships
                                                user {
                                                    ${USER_GRAPHQL} 
                                                }
                                             }
                                        }
                                        assignedAuditSchedules {
                                            name
                                            status
                                        }
                                        auditType {
                                            businessId
                                            label
                                        }
                                        topics {
                                            ${TOPIC_GRAPHQL}
                                        }
                                    }
                                }
                           `,
                        variables: {
                            questionnaireId: businessId
                        }
                    }
                }
            }
        })
    };
}

export function fetchEmptyQuestionnaire() {
    return (dispatch) => {
        return Promise.resolve(dispatch({
            type: actionTypes.FETCH_EMPTY_QUESTIONNAIRE
        }));
    }
}

export function fetchQuestions() {
    return {
        type: actionTypes.FETCH_QUESTIONS,
        payload: {
            client: 'graphql',
            request: {
                method: 'post',
                data: {
                    query: `
                            {
                                listQuestions {
                                    businessId
                                    status
                                    catalogue {
                                        label
                                    }
                                    answerType {
                                        label
                                        values {
                                            point
                                        }
                                    }
                                    translations {
                                        language {
                                            code
                                            label
                                            ordinal
                                        }
                                        question
                                        status
                                    }
                                    tags {
                                        label
                                        category
                                    }                                    
                                }
                             }
                           `
                }
            }
        }
    };
}

export function deleteQuestionnaire(questionnaireId) {
    return {
        type: actionTypes.DELETE_QUESTIONNAIRE,
        payload: {
            client: 'graphql',
            request: {
                method: 'post',
                data: {
                    query: `mutation removeQuestionnaire($questionnaireId: String){
    	                        removeQuestionnaire(questionnaireId: $questionnaireId)
                            }
                           `,
                    variables: {
                        questionnaireId
                    }
                }
            }
        }
    }
}
