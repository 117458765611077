export const ADD_TASK_COMMENT = '@comment/ADD_TASK_COMMENT';
export const ADD_TASK_COMMENT_SUCCESS = '@comment/ADD_TASK_COMMENT_SUCCESS';
export const ADD_TASK_COMMENT_FAIL = '@comment/ADD_TASK_COMMENT_FAIL';
export const DELETE_TASK_COMMENT = '@comment/DELETE_TASK_COMMENT';
export const DELETE_TASK_COMMENT_SUCCESS = '@comment/DELETE_TASK_COMMENT_SUCCESS';
export const DELETE_TASK_COMMENT_FAIL = '@comment/DELETE_TASK_COMMENT_FAIL';
export const ADD_ACTION_COMMENT = '@comment/ADD_ACTION_COMMENT';
export const ADD_ACTION_COMMENT_SUCCESS = '@comment/ADD_ACTION_COMMENT_SUCCESS';
export const ADD_ACTION_COMMENT_FAIL = '@comment/ADD_ACTION_COMMENT_FAIL';
export const DELETE_ACTION_COMMENT = '@comment/DELETE_ACTION_COMMENT';
export const DELETE_ACTION_COMMENT_SUCCESS = '@comment/DELETE_ACTION_COMMENT_SUCCESS';
export const DELETE_ACTION_COMMENT_FAIL = '@comment/DELETE_ACTION_COMMENT_FAIL';
