import * as fixRotation from "fix-image-rotation";
import configs from "configs/Configs";

const fixImageRotation = (file) => {
    return fixRotation.fixRotation([file]).then((result) => {
        const fixedFile = blobToFile(result[0], file.name);
        return new Promise((resolve) => {
            resolve(fixedFile);
        });
    })
};

const blobToFile = (blob, fileName) => {
    blob.lastModifiedDate = new Date();
    blob.name = fileName;
    return blob;
};


const isValidFileSize = (file) => {
  return file.size && file.size < configs.getMaxFileUploadSizeInBytes();
};

const fileUtils = {
    blobToFile,
    fixImageRotation,
    isValidFileSize
};

export default fileUtils;