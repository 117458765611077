import FeatureSwitch from 'utils/FeatureSwitch';

function graphql(options) {
    let caller = {};

    if (FeatureSwitch.extendedHttpHeaders.active) {
        caller = {
            "Caller-Function": getFormattedStackTrace(options.caller)
        };
    }

    return {
        type: options.type,
        data: options.data,
        payload: {
            client: 'graphql',
            request: {
                method: 'post',
                headers: options.headers || caller,
                data: {
                    query: options.query,
                    variables: options.variables
                }
            }
        }
    }
}

function getFormattedStackTrace(manualCaller) {
    const trace = (new Error()).stack;
    let stackTrace = trace.split("at ")
        .filter(
            stack => !stack.startsWith("http")
                && !stack.startsWith("dispatch")
                && !stack.startsWith("Object.dispatch")
                && !stack.startsWith("Object.graphql")
                && !stack.includes("Error")
                && !stack.includes("commit")
                && !stack.includes("invokeGuard")
        );
    stackTrace.length = stackTrace.length < 6 ? stackTrace.length : 6;
    stackTrace.splice(0, 1, manualCaller || '|');

    return stackTrace
        .map(stack => {
            const firstSpaceIndex = stack.indexOf(" ") > 0 ? stack.indexOf(" ") : stack.length;
            return stack.substring(0, firstSpaceIndex)
        })
        .join(' <= ')
        .substring(0, 250);
}

export default {
    graphql
}
