import * as actionTypes from './Audit.action.types';
import _ from "lodash";
import auditCache from 'scenes/audit/Audit.cache';
import {validateApplicationStatusForCache} from "cache/Cache.utils";
import query from 'store/GraphqlQueries.js';
import moment from "moment";
import utils from 'utils/Utils';
import {TOPIC_GRAPHQL} from 'domain/audit/Topic.model';
import configs from 'configs/Configs';

export function fetchAudits() {
    return auditCache.cacheOrLoadPersonalAudits(fetchAuditsFromServer, fetchAuditsFromCache)
}

export function fetchAuditsFromServer(auditorId) {
    return (dispatch) => {
        return dispatch(
            query.graphql({
                type: actionTypes.FETCH_AUDITS,
                caller: 'fetchAuditsFromServer',
                query: `
                    query
                        ($auditorId: String!){
                            listCurrentAuditsForAuditor(auditorId: $auditorId) {
                                businessId
                                type
                                schedule {
                                    name
                                }
                                cycle {
                                    label
                                }
                                durationType
                                organizationLevel {
                                    shortName
                                    parentLevel {
                                        shortName
                                    }
                                }
                                questions {
                                    businessId
                                    translations {
                                        language {
                                            code
                                            label
                                        }
                                        question
                                        comment
                                        picture {
                                            referenceId
                                            downloadUrl
                                        }
                                    }
                                    answerType {
                                        values {
                                            point
                                            label
                                        }
                                    }
                                }
                                status
                                creationDate
                                activationDate
                                dueDate
                                finishedDate
                                topics {
                                    ${TOPIC_GRAPHQL}
                                }
                                result {
                                    label
                                    answerResults {
                                        label
                                        percentage                                        
                                    }
                                }
                                answers {
                                    question {
                                        businessId
                                        translations {
                                            language {
                                                code
                                                label
                                            }
                                            question
                                            comment
                                            picture {
                                                referenceId
                                                downloadUrl
                                            }
                                        }
                                        answerType {
                                            values {
                                                point
                                                label
                                            }
                                        }
                                    }
                                    value {
                                        point
                                        label
                                    }
                                    comment
                                    language {
                                        code
                                        label
                                    }
                                    severity
                                    attachments {
                                        downloadUrl
                                    }
                                    topic {
                                        name
                                        ordinal
                                    }
                                    subtopic {
                                        name
                                        ordinal
                                    }
                                }                                    
                            }
                    }
               `,
                variables: {
                    auditorId
                }
            })
        );
    }
}

export function fetchAuditsFromCache(audits) {
    return (dispatch) => {
        return dispatch({
            type: actionTypes.FETCH_AUDITS_FROM_CACHE,
            data: {
                cache: {
                    audits,
                }
            }
        })
    }
}

export function getAnswerReferenceId() {
    return {
        type: actionTypes.GET_ANSWER_REFERENCE_ID,
        payload: {
            client: 'api',
            request: {
                url: '/audit-management/answer-reference-id',
                method: 'GET',
            }
        }
    }
}

export function fetchInitDataForAdHocAudit(levelId) {
    return {
        type: actionTypes.FETCH_INIT_DATA_FOR_AD_HOC_AUDIT,
        payload: {
            client: 'graphql',
            request: {
                method: 'post',
                data: {
                    query: `
                            query
                                ($levelId: String!){
                                    fetchInitDataForAdHocAudit(levelId: $levelId) {
                                        organizationLevel {
                                            businessId
                                            shortName
                                            parentLevel {
                                                businessId
                                                shortName
                                            }
                                        }
                                        questionnaires {
                                            name
                                            businessId                                           
                                            questions {
                                                businessId
                                                translations {
                                                    language {
                                                        code
                                                        label
                                                    }
                                                    question
                                                    comment
                                                    picture {
                                                        referenceId
                                                        downloadUrl
                                                    }
                                                }
                                                answerType {
                                                    values {
                                                      point
                                                      label
                                                    }
                                                }
                                            }
                                            topics {
                                                ${TOPIC_GRAPHQL}
                                            }
                                            auditType {
                                                label
                                            }
                                        }
                                    }
                                }
                           `,
                    variables: {
                        levelId
                    }
                }
            }
        }
    };
}

export function executeAudit(audit) {
    return {
        type: actionTypes.EXECUTE_SCHEDULED_AUDIT,
        data: {
            audit
        }
    }
}

export function reviewAudit(audit) {
    return {
        type: actionTypes.REVIEW_AUDIT,
        data: {
            audit
        }
    }
}

export function finishReview() {
    return {
        type: actionTypes.FINISH_REVIEW,
        data: {}
    }
}


export function setEmptyInitDataForAdHocAudit() {
    return {
        type: actionTypes.SET_EMPTY_INIT_DATA_FOR_AD_HOC_AUDIT,
        data: {}
    }
}

export function submitAdHocAuditForm(questionnaireId, randomizedNumber) {
    return {
        type: actionTypes.SUBMIT_AD_HOC_AUDIT_FORM,
        data: {
            questionnaireId,
            randomizedNumber
        }
    };
}

export function saveAudit(answeredQuestions, topics) {
    return (dispatch, getState) => {
        const auditId = getState().auditReducer.auditSetup.audit.businessId;
        if (auditId) {
            const auditInput = executeAuditInput({auditId, answeredQuestions, topics});
            return dispatch(uploadFinishedScheduledAudit(auditInput, '@save_audit/SAVE_AUDIT'));
        } else {
            return dispatch(uploadFinishedAudit(createAdHocAuditInput(
                answeredQuestions,
                getState().auditReducer.adHocAuditForm.levelId,
                getState().auditReducer.auditSetup.questionnaireId,
                topics
            ), '@save_audit/SAVE_AUDIT'));
        }
    }
}

export function finishAudit(answeredQuestions, isFinished) {
    return (dispatch, getState) => {
        const auditId = getState().auditReducer.auditSetup.audit.businessId;
        if (auditId) {
            const auditInput = executeAuditInput({auditId, answeredQuestions, isFinished});
            return dispatch(uploadFinishedScheduledAudit(auditInput))
                .catch(originalError => {
                    return dispatch(validateApplicationStatusForCache(auditCache.cacheExecutedScheduledAudit(auditInput),
                        originalError))
                        .then(() => {
                            dispatch(auditCache.updateAuditWithAnswers(auditId, auditInput, answeredQuestions))
                                .then(updatedAudit => {
                                    return dispatch({
                                        type: actionTypes.UPLOAD_FINISHED_SCHEDULED_AUDIT_TO_CACHE,
                                        data: {result: updatedAudit.result}
                                    })
                                });
                        })
                        .catch((error) => {
                            console.error(error, originalError)
                        })
                });
        } else {
            return dispatch(uploadFinishedAudit(createAdHocAuditInput(
                answeredQuestions,
                getState().auditReducer.adHocAuditForm.levelId,
                getState().auditReducer.auditSetup.questionnaireId
            )));
        }
    };
}

export function syncScheduledAudit(executedAuditInput) {
    return dispatch => {
        return dispatch(uploadFinishedScheduledAudit(executedAuditInput))
            .catch(originalError =>
                dispatch(auditCache.cacheExecutedScheduledAudit(executedAuditInput))
                    .catch((error) => {
                        console.error(error, originalError)
                    }));
    };
}

export function uploadFinishedAudit(audit, type) {
    return {
        type: type || actionTypes.UPLOAD_FINISHED_AUDIT,
        payload: {
            client: 'graphqlMultipart',
            request: {
                method: 'post',
                data: {
                    query: `mutation createAdHocAudit($audit: CreateAdHocAuditInput){
    	                        createAdHocAudit(createAdHocAuditInput: $audit){
      	                           businessId
      	                           result {
                                       label
                                       percentage
                                       answerResults {
                                           label
                                           percentage                                        
                                       }
                                   }
    	                        }
                            }
                            `,
                    variables: {
                        audit
                    }
                }
            }
        }
    }
}

export function uploadFinishedScheduledAudit(audit, type) {
    return {
        type: type || actionTypes.UPLOAD_FINISHED_SCHEDULED_AUDIT,
        payload: {
            client: 'graphqlMultipart',
            request: {
                method: 'post',
                data: {
                    query: `mutation executeAudit($audit: ExecuteAuditInput){
    	                        executeAudit(executeAuditInput: $audit){
      	                           businessId
      	                           result {
                                       label
                                       percentage
                                       answerResults {
                                           label
                                           percentage                                        
                                       }
                                   }
    	                        }
                            }
                            `,
                    variables: {
                        audit
                    }
                }
            }
        }
    }
}

export function createAdHocAuditInput(answeredQuestions, levelId, questionnaireId, topics) {
    return {
        levelId,
        questionnaireId,
        answers: mapAnsweredQuestions(answeredQuestions, topics)
    }
}

export function executeAuditInput({auditId, answeredQuestions, topics, isFinished}) {
    return {
        auditId,
        finishedDate: isFinished ? moment.utc().format(utils.API_DATE_FORMAT) : null,
        answers: mapAnsweredQuestions(answeredQuestions, topics)
    }
}

function mapAnsweredQuestions(answeredQuestions, topics){
    return answeredQuestions
        .map(enrichQuestionWithTopics(topics))
        .filter(question => (question.answer.point !== '') && (question.answer.point !== undefined))
        .map((question) => {
            const topic = question.topic;
            const subTopic = question.subTopic;
            return {
                ...(question.answer.referenceId && {referenceId: question.answer.referenceId}),
                ...(topic && {topic: {name: topic.name, ordinal: topic.ordinal}}),
                ...(subTopic && {subtopic: {name: subTopic.name, ordinal: subTopic.ordinal}}),
                questionId: question.businessId,
                point: question.answer.point,
                translation: getTranslation(question),
                severity: question.answer.severity || null,
                questionOrdinal: question.ordinal
            }
        })
}

const enrichQuestionWithTopics = (topics) => (question, index) => {
    const topic = (topics || []).find(topicInArray => topicInArray.businessId === question.topicId);
    const subTopic = topic
        ? topic.children.find(subTopicInArray => subTopicInArray.businessId === question.subTopicId)
        : null;
    question.topic = topic;
    question.subTopic = subTopic
    question.ordinal = _.get(topic, 'ordinal')
        ? ("" + _.get(topic, 'ordinal') + (_.get(subTopic, 'ordinal') ? ("." + subTopic.ordinal) : '') + "." + (index + 1))
        : ("" + (index + 1));
    return question;
}

function getTranslation(question) {
    const answer = question.answer;
    const language = answer.language;
    return _.isEmpty(answer.comment) ? {
        comment: null,
        language: {
            code: language
        }
    } : {
        comment: answer.comment || null,
        language: {
            code: language
        }
    };
}

export function exportExcelAndCloseAudit(auditId) {
    return {
        type: '@download-file/DOWNLOAD_FILE',
        payload: {
            client: 'empty',
            request: {
                method: 'get',
                url: `${configs.getBackendServiceUrl()}/api/audit-management/vda-audit-export/${auditId}`,
                responseType: 'blob',
            }
        }
    }
}
