import React, {Component} from 'react';
import {Typography} from "@material-ui/core";
import * as colors from 'components/colors/Colors';
import H4Avatar from 'components/avatar/H4Avatar';
import Flex from 'components/grid/Flex';

export class DragTaskTile extends Component {

    render() {
        const {assignee, taskId, dragHandleProps} = this.props;

        return (
            <Flex item={'0 0 auto'} container style={{maxHeight: 20}} center {...dragHandleProps}>
                <H4Avatar {...{user: assignee}} style={{margin: 0}}/>
                <Typography
                    style={{
                        fontSize: 13,
                        marginLeft: 5,
                        color: colors.htmlGrey,
                        textTransform: 'initial',
                    }}
                >#{taskId}</Typography>
            </Flex>
        );
    }
}

export default DragTaskTile;
