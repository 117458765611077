import React, { Component } from 'react';
import { withStyles, LinearProgress } from "@material-ui/core";
import {critical} from 'components/colors/Colors'

const styles = theme => ({
    linearProgressBaseColor: {
    },
    linearProgressBarColorNormal: {
    },
    linearProgressBarColorCritical: {
        backgroundColor: critical,
    },
});

export class DueDateProgressBar extends Component {
    render() {
        const {classes, value, style} = this.props;

        return (
            <LinearProgress
                classes={{
                    colorPrimary: classes.linearProgressBaseColor,
                    barColorPrimary: value >= 100
                        ?
                        classes.linearProgressBarColorCritical
                        :
                        classes.linearProgressBarColorNormal
                }}
                style={style}
                variant="determinate"
                value={value || 0}/>
        );
    }
}

export default withStyles(styles)(DueDateProgressBar);
