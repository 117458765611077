import * as colors from 'components/colors/Colors';

export function getColor(result) {
    switch (result) {
        case "happy":
        case "accepted":
            return colors.jungleGreen;
        case "neutral":
        case "partially accepted":
            return colors.htmlOrange;
        case "sad":
        case "not accepted":
            return colors.red;
        default:
            return undefined;
    }
}

export function getHighlightColor(result) {
    switch (result) {
        case "happy":
            return colors.berylGreen;
        case "neutral":
            return colors.mediumHighlight;
        case "sad":
            return colors.criticalHighlight;
        default:
            return undefined;
    }
}
