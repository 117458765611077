import * as actionTypes from './Tasks.action.types';
import {NEW_PROJECT_SELECTED} from './Project.action.types';
import _ from 'lodash';
import utils from 'utils/Utils';
import {
    CREATE_TASK_SUCCESS,
    CREATE_TASK_TO_CACHE,
    EDIT_TASK_SUCCESS,
    EDIT_TASK_TO_CACHE,
    FETCH_TASK_SUCCESS,
    FETCH_TASK_FROM_CACHE,
    CLONE_TASK_SUCCESS,
    EDIT_TASK
} from "./task-edit/TaskEdit.action.types";
import {apiTaskForListing} from 'domain/task/Tasks.model';
import {mapFreeTag} from 'domain/Tag.model';
import {haveMatchingStatus} from './Task.utils';
import {ARCHIVED_STATUS_ID} from "domain/task/TaskStatus.model";

const initialState = {
    forceRerender: '',

    isProjectLoaded: false,

    activeTasks: [],
    backlogTasks: [],
    historyTasks: [],

    kanbanTaskColumns: [],
    configuration: {
        users: [],
        archiveTagId: '',
        freeTags: [],
        backlogWorkflowStatus: '',
        startingWorkflowStatus: '',
        closableWorkflowStatus: '',
        closeWorkflowStatus: '',
        activeWorkflowStatuses: [],
        selectableStatuses: []
    },
    configurationFromBackend: {
        statuses: [],
        deadlines: [],
        tags: [],
        supportedLanguages: [],
        tabs: []
    },
    isTaskManagementReady: false,
    isTaskConfigurationReady: false,
    isTaskLoadingReady: false,
    todoTaskBusinessId: undefined,
};

export default function (state = initialState, action) {
    switch (action.type) {
        case actionTypes.LIST_TASKS_FROM_CACHE:
        case actionTypes.LIST_TASKS_SUCCESS: {
            const newState = _.cloneDeep(state);

            const selectedProject = _.get(action, 'data.selectedProject') || _.get(action, 'meta.previousAction.data.selectedProject');
            const personalProject = _.get(action, 'data.personalProject') || _.get(action, 'meta.previousAction.data.personalProject');
            const online = _.get(action, 'meta.previousAction.data.online');
            const tasks = extractTasks(action, selectedProject.businessId);
            const myTodoTasks = personalProject ? extractTasks(action, personalProject.businessId) : undefined;
            const activeWorkflowStatuses = newState.configuration.activeWorkflowStatuses;

            newState.activeTasks = [];
            newState.backlogTasks = [];
            newState.kanbanTaskColumns = [];

            if (tasks) {
                tasks.forEach(task => {
                    if (haveMatchingStatus(activeWorkflowStatuses, task)) {
                        newState.activeTasks.push(apiTaskForListing(task));
                    } else {
                        newState.backlogTasks.push(apiTaskForListing(task));
                    }
                });

                if (!_.isEmpty(activeWorkflowStatuses)) {
                    newState.kanbanTaskColumns = mapTaskColumns(
                        newState.activeTasks,
                        activeWorkflowStatuses,
                        selectedProject.activeTaskColumnOrders
                    );
                }
                newState.isProjectLoaded = true;
                if (myTodoTasks) {
                    const todoTaskBusinessIdCandidate = findTodoTaskBusinessId(myTodoTasks);
                    if (todoTaskBusinessIdCandidate) {
                        newState.todoTaskBusinessId = todoTaskBusinessIdCandidate;
                    }
                }
            } else {
                newState.isProjectLoaded = online;
            }
            newState.isTaskLoadingReady = true;
            return newState;
        }
        case actionTypes.LIST_HISTORY_TASKS_SUCCESS: {
            const newState = _.cloneDeep(state);
            const tasks = _.get(action, 'payload.data.data.listTasks') || _.get(action, 'payload.data.data.listTasksByProjects[0].tasks');
            newState.historyTasks = mapTasks(tasks);
            newState.isTaskLoadingReady = true;
            return newState;
        }
        case actionTypes.FETCH_TASK_CONFIGURATION_FROM_CACHE:
        case actionTypes.FETCH_TASK_CONFIGURATION_SUCCESS: {
            const newState = {...state};
            const newConfigurationFromBackend = _.get(action, 'payload.data.data.fetchTaskConfigurationData')
                || _.get(action, 'data.cache.config');
            newState.configurationFromBackend = {...newState.configurationFromBackend, ...newConfigurationFromBackend};

            newState.configuration.selectableStatuses = newState.configurationFromBackend.statuses.filter(status => status.businessId !== ARCHIVED_STATUS_ID);

            const activeTab = newState.configurationFromBackend.tabs.find(tab => tab.key.toUpperCase() === 'ACTIVE');
            const backlogTab = newState.configurationFromBackend.tabs.find(tab => tab.key.toUpperCase() === 'BACKLOG');
            const historyTab = newState.configurationFromBackend.tabs.find(tab => tab.key.toUpperCase() === 'HISTORY');
            const activeStatuses = activeTab.statuses;
            newState.configuration.activeWorkflowStatuses = activeStatuses;
            newState.configuration.backlogWorkflowStatuses = backlogTab.statuses;
            newState.configuration.historyWorkflowStatuses = historyTab.statuses;

            newState.configuration.closableWorkflowStatus = activeStatuses[activeStatuses.length - 1];
            newState.configuration.startingWorkflowStatus = activeStatuses[0];
            newState.configuration.backlogWorkflowStatus = backlogTab.statuses[0];
            newState.configuration.closeWorkflowStatus = historyTab.statuses[0];

            newState.configuration.taskSeverities = newState.configurationFromBackend.deadlines
                .filter(deadline => deadline.taskType === 'TASK')
                .map(deadline => deadline.priority.type);
            newState.configuration.deviationSeverities = newState.configurationFromBackend.deadlines
                .filter(deadline => deadline.taskType === 'DEVIATION')
                .map(deadline => deadline.priority.type);

            newState.configuration.freeTags = newState.configurationFromBackend.freeTags
                && newState.configurationFromBackend.freeTags.map(mapFreeTag);
            newState.isTaskConfigurationReady = true;
            return newState;
        }
        case actionTypes.LIST_TASK_MANAGEMENT_TAGS_FROM_CACHE:
        case actionTypes.LIST_TASK_MANAGEMENT_TAGS_SUCCESS: {
            const newState = {...state};
            newState.configurationFromBackend.tags = _.get(action, 'payload.data.data.listTaskManagementTags')
                || _.get(action, 'data.cache.tags');
            newState.configuration = {
                ...newState.configuration,
                ...mapConfigurationTagIds(newState.configurationFromBackend.tags)
            };
            return newState;
        }
        case actionTypes.SAVE_NEW_TASK_ORDER: {
            const newState = {...state};
            const taskBusinessIds = action.data.taskBusinessIds;
            newState.activeTasks = _.sortBy(
                state.activeTasks,
                task => taskBusinessIds.indexOf(task.businessId)
            );
            newState.backlogTasks = _.sortBy(
                state.backlogTasks,
                task => taskBusinessIds.indexOf(task.businessId)
            );
            return newState;
        }
        case actionTypes.SAVE_NEW_TASK_COLUMN_ORDER: {
            const newState = {...state};
            newState.kanbanTaskColumns = action.data.taskColumns;
            return newState;
        }
        case CREATE_TASK_TO_CACHE:
        case CREATE_TASK_SUCCESS:
        case CLONE_TASK_SUCCESS: {
            const newState = {...state};
            const createdTask = _.get(action, 'payload.data.data.createTask') || _.get(action, 'payload.data.data.cloneTask') || _.get(action, 'data.task');
            if (createdTask) {
                const task = apiTaskForListing(createdTask);
                const taskList = getTaskListByStatusBusinessId(task.status.businessId, newState);
                if (taskList !== undefined) {
                    taskList.push(task);
                }
                const toBeUpdatedTaskColumn = newState.kanbanTaskColumns.find(column => column.businessId === createdTask.status.businessId);
                if (toBeUpdatedTaskColumn !== undefined) {
                    toBeUpdatedTaskColumn.tasks.push(task);
                }
                newState.forceRerender = utils.uuid();
            }
            return newState;
        }
        case EDIT_TASK: {
            const newState = {...state};
            const currentTaskList = getTaskListByStatusBusinessId(action.data.task.status.businessId, newState);
            updateTaskList(currentTaskList, action.data.task);

            newState.forceRerender = utils.uuid();
            return newState;
        }
        case FETCH_TASK_SUCCESS:
        case FETCH_TASK_FROM_CACHE:
        case EDIT_TASK_TO_CACHE:
        case EDIT_TASK_SUCCESS: {
            const newState = {...state};
            const editedTask = _.get(action, 'payload.data.data.editTask')
                || _.get(action, 'payload.data.data.fetchTask')
                || _.get(action, 'data.task')
                || _.get(action, 'data.editTask');
            if (editedTask) {
                const enrichedTask = apiTaskForListing(editedTask);
                const previousTaskList = findTaskListContainingTask(enrichedTask, newState);
                const currentTaskList = getTaskListByStatusBusinessId(enrichedTask.status.businessId, newState);

                if (previousTaskList && currentTaskList && previousTaskList === currentTaskList) {
                    updateTaskList(currentTaskList, enrichedTask);
                } else {
                    if (previousTaskList) {
                        _.remove(previousTaskList, task => task.businessId === enrichedTask.businessId);
                    }
                    if (currentTaskList && _.get(currentTaskList[0],'projectBusinessId') === enrichedTask.projectBusinessId) {
                        currentTaskList.push(enrichedTask);
                    }
                }

                const previousKanbanColumn = newState.kanbanTaskColumns
                    .find(column => column.tasks.find(task => task.businessId === enrichedTask.businessId));
                const currentKanbanColumn = newState.kanbanTaskColumns
                    .find(column => column.businessId === enrichedTask.status.businessId);

                if (previousKanbanColumn && currentKanbanColumn && previousKanbanColumn === currentKanbanColumn) {
                    updateTaskList(currentKanbanColumn.tasks, enrichedTask);
                } else {
                    if (previousKanbanColumn) {
                        _.remove(previousKanbanColumn.tasks, task => task.businessId === enrichedTask.businessId);
                    }
                    if (currentKanbanColumn !== undefined) {
                        //TODO LM: This might be the reason why the task blinks to the end of the column after drag&drop
                        currentKanbanColumn.tasks.push(enrichedTask);
                    }
                }

                newState.forceRerender = utils.uuid();
            }
            return newState;
        }

        case actionTypes.FETCH_USERS_SUCCESS: {
            const newState = _.cloneDeep(state);
            newState.configuration.users = action.payload.data.data.listUsers;
            return newState;
        }
        case actionTypes.FETCH_USERS_FROM_CACHE: {
            const newState = _.cloneDeep(state);
            newState.configuration.users = action.data.cache.users;
            return newState;
        }
        case actionTypes.REMOVE_UNSEEN_CONTENT_TAG_TO_TASK: {
            const newState = _.cloneDeep(state);
            const taskToEdit = unseenTaskToEdit(action, newState);
            taskToEdit.isUnseen = false;
            return newState;
        }
        case actionTypes.REMOVE_UNSEEN_COMMENT_TAG_TO_TASK: {
            const newState = _.cloneDeep(state);
            const taskToEdit = unseenTaskToEdit(action, newState);
            taskToEdit.hasUnseenComment = false;
            return newState;
        }
        case actionTypes.REMOVE_UNSEEN_ACTION_TAG_TO_TASK: {
            const newState = _.cloneDeep(state);
            const taskToEdit = unseenTaskToEdit(action, newState);
            taskToEdit.hasUnseenAction = false;
            return newState;
        }
        case actionTypes.REMOVE_UNSEEN_ATTACHMENT_TAG_TO_TASK: {
            const newState = _.cloneDeep(state);
            const taskToEdit = unseenTaskToEdit(action, newState);
            taskToEdit.hasUnseenAttachment = false;
            return newState;
        }
        case actionTypes.REMOVE_UNSEEN_CHECKLIST_ITEM_TAG_TO_TASK: {
            const newState = _.cloneDeep(state);
            const taskToEdit = unseenTaskToEdit(action, newState);
            taskToEdit.hasUnseenCriteria = false;
            return newState;
        }
        case actionTypes.START_FETCHING_TASK_MANAGEMENT_DATA:
        case actionTypes.DONE_FETCHING_TASK_MANAGEMENT_DATA: {
            const newState = {...state};
            newState.isTaskManagementReady = action.data.isTaskManagementReady;
            return newState;
        }
        case NEW_PROJECT_SELECTED: {
            const newState = {...state};
            newState.activeTasks = [];
            newState.backlogTasks = [];
            newState.historyTasks = [];
            newState.kanbanTaskColumns = [];
            newState.isTaskLoadingReady = false;
            return newState;
        }
        default:
            return state;
    }
}

export function unseenTaskToEdit(action, newState) {
    const taskBusinessId = _.get(action, 'payload.request.data.variables.taskBusinessId');
    const taskToEdit = newState.activeTasks.concat(newState.backlogTasks).find(task => task.businessId === taskBusinessId);
    return taskToEdit || {};
}

function updateTaskList(tasks, newTask) {
    const index = tasks.findIndex(oldTask => newTask.businessId === oldTask.businessId);
    if (index > -1 && tasks[index].projectBusinessId !== newTask.projectBusinessId) {
        tasks.splice(index, 1);
    }
    const matchingTask = tasks
        .find(oldTask => oldTask.businessId === newTask.businessId);
    if (matchingTask) {
        tasks[tasks.indexOf(matchingTask)] = newTask;
    }

    return tasks;
}

export function mapTasks(tasks) {
    return tasks.map(apiTaskForListing);
}

export function findIndexOfItemInPrimaryOrSecondaryArray(item, primaryArray, secondaryArray) {
    if (primaryArray && secondaryArray) {
        const primaryIndex = primaryArray.indexOf(item);
        if (primaryIndex > -1) {
            return primaryIndex;
        } else {
            return secondaryArray.indexOf(item)
        }
    } else {
        return -1;
    }
}

export function sortTasksByProvidedOrder(unsortedTasks, orderedTaskBusinessIds) {
    if (unsortedTasks) {
        const unsortedTaskBusinessIds = unsortedTasks.map(task => task.businessId);
        return _.sortBy(
            unsortedTasks,
            task => findIndexOfItemInPrimaryOrSecondaryArray(
                task.businessId, orderedTaskBusinessIds, unsortedTaskBusinessIds));
    }
    return []
}

export function mapTaskColumns(activeTasks, activeWorkflowStatuses, activeTaskColumnOrders) {
    const taskColumns = sortTasksToColumns(activeTasks, activeWorkflowStatuses);
    sortByActiveTaskColumnOrders(taskColumns, activeTaskColumnOrders);
    return taskColumns;
}

export function sortTasksToColumns(tasks, activeWorkflowStatuses) {
    return activeWorkflowStatuses
        .map(status => ({
                businessId: status.businessId,
                label: status.label,
                tasks: tasks.filter(task => task.status.businessId === status.businessId)
            })
        );
}

export function sortByActiveTaskColumnOrders(taskColumns, activeTaskColumnOrders) {
    activeTaskColumnOrders.forEach(taskColumnFromBackend => {
        const matchingTaskColumn = taskColumns.find(tasksColumn => tasksColumn.businessId === taskColumnFromBackend.status.businessId);

        if (matchingTaskColumn) {
            matchingTaskColumn.tasks = _.sortBy(
                matchingTaskColumn.tasks,
                task => {
                    const index = taskColumnFromBackend.taskBusinessIds.indexOf(task.businessId);
                    return index !== -1 ? index : matchingTaskColumn.tasks.length
                }
            )
        }
    });
}

export function calculateInitialDateFilters(tasks) {
    const dueDates = tasks.map(task => task.dueDate) || [];
    const maxDate = (previousDate, currentDate) => {
        return previousDate > currentDate ? previousDate : currentDate
    };
    const minDate = (previousDate, currentDate) => {
        return previousDate < currentDate ? previousDate : currentDate
    };
    return {
        maxDate: _.isEmpty(dueDates) ? new Date() : dueDates.reduce(maxDate),
        minDate: _.isEmpty(dueDates) ? new Date() : dueDates.reduce(minDate),
    };
}

export function mapConfigurationTagIds(tags) {
    const archiveTag = tags.find(tag => tag.label === 'ARCHIVED');
    return {
        archiveTagId: archiveTag && archiveTag.businessId
    }
}

export function findTodoTaskBusinessId(tasks) {
    const todoTask = tasks.find((task) => {
        const tags = _.get(task, 'tags', []);
        return tags.find(tag => tag.label === 'TODO' && tag.category === 'GENERAL');
    });
    return _.get(todoTask, 'businessId');
}

function findTaskListContainingTask(taskToFind, state) {
    const taskLists = [state.activeTasks, state.backlogTasks, state.historyTasks];
    return taskLists.find(list => list.find(task => task.businessId === taskToFind.businessId));
}

function getTaskListByStatusBusinessId(statusBusinessId, state) {
    if (haveMatchingStatusById(state.configuration.activeWorkflowStatuses, statusBusinessId)) {
        return state.activeTasks;
    }
    else if (haveMatchingStatusById(state.configuration.backlogWorkflowStatuses, statusBusinessId)) {
        return state.backlogTasks;
    }
    else if (haveMatchingStatusById(state.configuration.historyWorkflowStatuses, statusBusinessId)) {
        return state.historyTasks;
    } else {
        return [];
    }

}

function haveMatchingStatusById(statuses, statusBusinessId) {
    return statuses.findIndex(status => status.businessId === statusBusinessId) !== -1;
}

function extractTasks(action, selectedProjectBusinessId) {
    const tasksByProjectFromBackend = _.get(action, 'payload.data.data.listTasksByProjects');
    if (tasksByProjectFromBackend) {
        const tasksByProject = tasksByProjectFromBackend.find(tasksByProject => tasksByProject.projectBusinessId === selectedProjectBusinessId);
        return _.get(tasksByProject, 'tasks', []);
    }
    return _.get(action, 'data.cache.tasks');
}
