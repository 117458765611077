import React, {Component} from 'react';
import {
    withStyles,
    Typography,
} from "@material-ui/core";
import Flex from 'components/grid/Flex';
import {Trans} from "react-i18next";
import {silver, htmlWhite} from 'components/colors/Colors';
import QuillEditor from 'components/quill-editor/QuillEditor';

const styles = theme => ({
    fieldTitle: {
        color: silver,
        textTransform: 'uppercase',
        marginTop: 12,
        marginBottom: 2,
        fontSize: 12
    },
    descriptionRoot: {
        flex: '1 1 auto',
        padding: 9,
        paddingTop: 0,
        '& *': {
            fontSize: '14px'
        }
    },
    descriptionToolbarRoot: {
        paddingBottom: 0,
        marginBottom: 0
    }
});

export class MobileDescription extends Component {
    render() {
        const {
            classes, isEditable, matchingTranslation, handleDescriptionChange, businessId, referenceType, borderColor,language
        } = this.props;
        return (
            <Flex container item direction={'column'}>
                <Typography className={classes.fieldTitle}><Trans
                    i18nKey={"task-management.task-edit.description"}/></Typography>
                <QuillEditor {...{
                    text: matchingTranslation.description,
                    language,
                    isEditable,
                    handleTextChange: handleDescriptionChange,
                    toolbarOptions: [
                        [{'indent': '-1'}, {'indent': '+1'}],
                        [{'list': 'ordered'}, {'list': 'bullet'}]
                    ],
                    fileUploadOptions: {
                        id: businessId,
                        referenceType: referenceType,
                    },
                    containerStyle: {
                        border: `1px solid`,
                        borderColor,
                        backgroundColor: htmlWhite
                    }
                }}/>
            </Flex>
        );
    }
}

export default withStyles(styles)(MobileDescription);
