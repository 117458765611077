import React, {Component} from 'react';
import {withStyles} from "@material-ui/core";
import {DatePicker, MuiPickersUtilsProvider} from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import {DateRangeIcon} from "@material-ui/pickers/_shared/icons/DateRangeIcon";
import BootstrapInputWithStartadornment from "components/bootstrap-input/BootstrapInputWithStartadornment";

const styles = theme => ({
    input: {
        fontSize: 16,
        padding: '8px 10px 8px 10px',
    },
});

export class MaterialDatePicker extends Component {

    renderInput = (props) => {
        return (
            <BootstrapInputWithStartadornment
                fullWidth
                value={props.value}
                onClick={props.onClick}
                startAdornment={<DateRangeIcon/>}
                disabled={props.disabled}
                readOnly
            />
        )
    };

    render() {
        const {classes, label, ...otherProps} = this.props;
        return (
            <MuiPickersUtilsProvider utils={MomentUtils}>
                <DatePicker
                    label={label}
                    variant={"outlined"}
                    InputProps={{classes}}
                    TextFieldComponent={this.renderInput}
                    {...otherProps}
                />
            </MuiPickersUtilsProvider>
        )
    }
}

export default withStyles(styles)(MaterialDatePicker);
