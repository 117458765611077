export const FETCH_ORGANIZATION_LEVELS = '@organizationTree/FETCH_ORGANIZATION_LEVELS';
export const FETCH_ORGANIZATION_LEVELS_SUCCESS = '@organizationTree/FETCH_ORGANIZATION_LEVELS_SUCCESS';
export const FETCH_ORGANIZATION_LEVELS_FAIL = '@organizationTree/FETCH_ORGANIZATION_LEVELS_FAIL';

export const TOGGLE_FILTER_SELECTION = '@organizationTree/TOGGLE_FILTER_SELECTION';
export const TOGGLE_FILTER_MULTI_SELECTION = '@organizationTree/TOGGLE_FILTER_MULTI_SELECTION';
export const TOGGLE_FILTER_MULTI_SELECTIONS = '@organizationTree/TOGGLE_FILTER_MULTI_SELECTIONS';

export const TOGGLE_FILTER_OPEN = '@organizationTree/TOGGLE_FILTER_OPEN';
export const TOGGLE_FILTER_SELECT_ALL = '@organizationTree/TOGGLE_FILTER_SELECT_ALL';

export const CREATE_ORGANIZATION_LEVEL = '@organizationTree/CREATE_ORGANIZATION_LEVEL';
export const CREATE_ORGANIZATION_LEVEL_SUCCESS = '@organizationTree/CREATE_ORGANIZATION_LEVEL_SUCCESS';
export const CREATE_ORGANIZATION_LEVEL_FAIL = '@organizationTree/CREATE_ORGANIZATION_LEVEL_FAIL';

export const EDIT_ORGANIZATION_LEVEL = '@organizationTree/EDIT_ORGANIZATION_LEVEL';
export const EDIT_ORGANIZATION_LEVEL_SUCCESS = '@organizationTree/EDIT_ORGANIZATION_LEVEL_SUCCESS';
export const EDIT_ORGANIZATION_LEVEL_FAIL = '@organizationTree/EDIT_ORGANIZATION_LEVEL_FAIL';

export const DELETE_ORGANIZATION_LEVEL = '@organizationTree/DELETE_ORGANIZATION_LEVEL';
export const DELETE_ORGANIZATION_LEVEL_SUCCESS = '@organizationTree/DELETE_ORGANIZATION_LEVEL_SUCCESS';
export const DELETE_ORGANIZATION_LEVEL_FAIL = '@organizationTree/DELETE_ORGANIZATION_LEVEL_FAIL';

export const OPEN_ORGANIZATION_LEVEL_TREE = '@organizationTree/OPEN_ORGANIZATION_LEVEL_TREE';
export const CLOSE_ORGANIZATION_LEVEL_TREE = '@organizationTree/CLOSE_ORGANIZATION_LEVEL_TREE';

export const ASSIGN_ORGANIZATION_LEVEL_MEMBER = '@organizationTree/ASSIGN_ORGANIZATION_LEVEL_MEMBER';
export const ASSIGN_ORGANIZATION_LEVEL_MEMBER_SUCCESS = '@organizationTree/ASSIGN_ORGANIZATION_LEVEL_MEMBER_SUCCESS';
export const ASSIGN_ORGANIZATION_LEVEL_MEMBER_FAIL = '@organizationTree/ASSIGN_ORGANIZATION_LEVEL_MEMBER_FAIL';

export const REMOVE_ORGANIZATION_LEVEL_MEMBER = '@organizationTree/REMOVE_ORGANIZATION_LEVEL_MEMBER';
export const REMOVE_ORGANIZATION_LEVEL_MEMBER_SUCCESS = '@organizationTree/REMOVE_ORGANIZATION_LEVEL_MEMBER_SUCCESS';
export const REMOVE_ORGANIZATION_LEVEL_MEMBER_FAIL = '@organizationTree/REMOVE_ORGANIZATION_LEVEL_MEMBER_FAIL';

export const UPDATE_ORGANIZATION_LEVEL_MEMBERSHIP = '@organizationTree/UPDATE_ORGANIZATION_LEVEL_MEMBERSHIP';
export const UPDATE_ORGANIZATION_LEVEL_MEMBERSHIP_SUCCESS = '@organizationTree/UPDATE_ORGANIZATION_LEVEL_MEMBERSHIP_SUCCESS';
export const UPDATE_ORGANIZATION_LEVEL_MEMBERSHIP_FAIL = '@organizationTree/UPDATE_ORGANIZATION_LEVEL_MEMBERSHIP_FAIL';

export const FETCH_USERS = '@organizationTree/FETCH_USERS';
export const FETCH_USERS_SUCCESS = '@organizationTree/FETCH_USERS_SUCCESS';
export const FETCH_USERS_FAIL = '@organizationTree/FETCH_USERS_FAIL';

export const FETCH_ORGANIZATION_MANAGEMENT_CONFIGURATION_DATA = '@organizationTree/FETCH_ORGANIZATION_MANAGEMENT_CONFIGURATION_DATA';
export const FETCH_ORGANIZATION_MANAGEMENT_CONFIGURATION_DATA_SUCCESS = '@organizationTree/FETCH_ORGANIZATION_MANAGEMENT_CONFIGURATION_DATA_SUCCESS';
export const FETCH_ORGANIZATION_MANAGEMENT_CONFIGURATION_DATA_FAIL = '@organizationTree/FETCH_ORGANIZATION_MANAGEMENT_CONFIGURATION_DATA_FAIL';

export const UPDATE_ORGANIZATION_LEVEL_TIMEZONE = '@organizationTree/UPDATE_ORGANIZATION_LEVEL_TIMEZONE';
export const UPDATE_ORGANIZATION_LEVEL_TIMEZONE_SUCCESS = '@organizationTree/UPDATE_ORGANIZATION_LEVEL_TIMEZONE_SUCCESS';
export const UPDATE_ORGANIZATION_LEVEL_TIMEZONE_FAIL = '@organizationTree/UPDATE_ORGANIZATION_LEVEL_TIMEZONE_FAIL';

export const CLEAR_FILTER_MULTI_SELECTION = '@organizationTree/CLEAR_FILTER_MULTI_SELECTION';

export const RESET_ORGANIZATION_LEVEL_TREE = '@organizationTree/RESET_ORGANIZATION_LEVEL_TREE';

export const UPDATE_ORGANIZATION_TREE_ROOT_TITLE = '@organizationTree/UPDATE_ORGANIZATION_TREE_ROOT_TITLE';

export const FETCH_AUDITS = '@organizationTree/FETCH_AUDITS';
export const FETCH_AUDITS_SUCCESS = '@organizationTree/FETCH_AUDITS_SUCCESS';
export const FETCH_AUDITS_FAIL = '@organizationTree/FETCH_AUDITS_FAIL';