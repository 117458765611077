import React, {PureComponent,} from 'react';
import {DialogContent, Typography, withStyles} from "@material-ui/core";
import {withTranslation} from "react-i18next";
import {htmlGrey} from "components/colors/Colors";

export const styles = theme => ({
    text: {
        fontSize: 14,
        color: htmlGrey
    }
});

export class SelectedOrganizationLevels extends PureComponent {

    render() {
        const {classes, organizationLevels, t: translate} = this.props;
        return (
            <DialogContent>
                <div style={{paddingBottom: 24}}>
                    <Typography className={classes.text}>
                        {translate('audit-administration.questionnaireEdit.selectedOrganizationLevels')}
                    </Typography>
                    <ul>
                        {
                            organizationLevels.map((organizationLevel, index) => {
                                    return (
                                        <li key={index} className={classes.text}>
                                            <Typography className={classes.text}>
                                                {organizationLevel}
                                            </Typography>
                                        </li>
                                    )
                                }
                            )
                        }
                    </ul>
                </div>
            </DialogContent>
        );
    }
}

export default withStyles(styles)(withTranslation()(SelectedOrganizationLevels));
