import React, {PureComponent} from 'react';
import {
    withStyles,
    Snackbar,
    SnackbarContent,
    IconButton
} from "@material-ui/core";
import {LockOutlined as LockIcon, CloseOutlined as CloseIcon} from '@material-ui/icons';
import {Trans} from 'react-i18next';
import {
    silver,
    greyHighlight,
} from 'components/colors/Colors';

const styles = theme => ({
    snackbarContent: {
        backgroundColor: silver
    },
    lockIcon: {
        height: 15,
        width: 15
    },
    closeIcon: {
        height: 15,
        width: 15
    },
});

export class ReadonlySnackbar extends PureComponent {
    render() {
        const {classes, readOnlyDialogOpened, handleReadOnlyDialogClose} = this.props;

        return (
            <Snackbar
                className={classes.snackbarContent}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                open={readOnlyDialogOpened}
                autoHideDuration={6000}
                onClose={handleReadOnlyDialogClose}
            >
                <SnackbarContent
                    className={classes.snackbarContent}
                    message={
                        <span style={{
                            fontSize: 12,
                            color: greyHighlight
                        }}>
                            <IconButton
                                key="lock"
                                disabled>
                                <LockIcon className={classes.lockIcon}/>
                            </IconButton>
                                 <Trans i18nKey={"task-management.task-edit.readOnlyWarning"}/>
                            </span>
                    }
                    action={[
                        <IconButton
                            key="close"
                            onClick={handleReadOnlyDialogClose}
                        >
                            <CloseIcon className={classes.closeIcon}/>
                        </IconButton>
                    ]}
                />
            </Snackbar>
        );
    }
}

export default withStyles(styles)(ReadonlySnackbar);
