import {STATUS_GRAPHQL} from 'domain/task/TaskStatus.model';
import {PRIORITY_GRAPHQL} from 'domain/task/Priority.model';

export const TASK_CONFIGURATION_GRAPHQL = `
    deadlines {
        priority {${PRIORITY_GRAPHQL}}
        quantity
        taskType
    }
    statuses {${STATUS_GRAPHQL}}
    supportedLanguages {
        code
        label
        ordinal
    }
    freeTags {
        label
    }
    tabs {
        key
        statuses {${STATUS_GRAPHQL}}
    }
`;
