import React, {Component} from 'react';
import ActionList from 'scenes/tasks/actions/action-list/ActionList';
import ActionEdit from 'scenes/tasks/actions/action-edit/ActionEdit';
import {Route, Switch} from 'react-router-dom';
import Flex from 'components/grid/Flex';
import {TASK_ACTION_LIST, TASK_ACTION_CREATE, TASK_ACTION_EDIT} from 'routes/routes';
import {connect} from 'react-redux';
import connector from './Actions.connect';
import _ from 'lodash';
import PageIsLoading from 'components/page-is-loading-progress/PageIsLoading';

export class Actions extends Component {

    componentDidMount() {
        if (this.props.taskBusinessId !== this.props.task.businessId) {
            this.props.reset();
        }
        this.props.fetchTask(this.props.taskBusinessId);
    }

    render() {
        const {taskBusinessId, task} = this.props;
        const isTaskReady = !_.isEmpty(task);
        return (
            <Flex item container direction={'column'}>
                <Switch>
                    <Route path={TASK_ACTION_LIST.path}
                           render={function renderActionList(props) {
                               return isTaskReady ? <ActionList {...{...props, taskBusinessId}}/> : <PageIsLoading/>
                           }}
                    />
                    <Route path={TASK_ACTION_CREATE.path}
                           render={function renderActionCreate(props) {
                               return isTaskReady ?
                                   <ActionEdit {...{
                                       routeProps: props,
                                       taskBusinessId,
                                       key: 'create'
                                   }}/>
                                   :
                                   <PageIsLoading/>
                           }}
                    />
                    <Route path={TASK_ACTION_EDIT.path}
                           render={function renderActionEdit(props) {
                               return isTaskReady ?
                                   <ActionEdit {...{
                                       routeProps: props,
                                       taskBusinessId,
                                       key: _.get(props.match, 'params.actionBusinessId'),
                                       actionBusinessId: _.get(props.match, 'params.actionBusinessId')
                                   }}/>
                                   :
                                   <PageIsLoading/>
                           }}
                    />
                </Switch>
            </Flex>
        );
    }
}

export default connect(connector.mapStateToProps, connector.mapDispatchToProps)(Actions);
