import React, {Component} from 'react';
import {IconButton} from "@material-ui/core";
import {ReactComponent as RocketIcon} from 'assets/img/rocket-icon.svg';
import {controlButtonBlue} from 'components/colors/Colors';
import {Link} from 'react-router-dom';
import _ from 'lodash';
import UnseenNumber from 'components/unseen/UnseenNumber';
import {joinUrl} from 'utils/Utils';

export class ActionListIndicator extends Component {

    onClick = () => {
        _.get(this.props, 'onOpen') && this.props.onOpen();
    };

    render() {
        const {numberOfActions, url, isUnseen, iconColor} = this.props;
        return (
            <IconButton
                style={{padding: 2, fontSize: 12}}
                component={Link}
                to={joinUrl(url, '/action/list')}
                onClick={this.onClick}
            >
                <RocketIcon style={{width: 22, fill: isUnseen ? controlButtonBlue : iconColor, marginRight: 3}}/>
                <UnseenNumber {...{isUnseen, number: numberOfActions, seenColor: iconColor}}/>
            </IconButton>
        );
    }
}

export default ActionListIndicator;
