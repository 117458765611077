import React, {PureComponent} from 'react';
import {
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    Typography,
    withStyles,
} from "@material-ui/core";
import Flex from 'components/grid/Flex';
import Add from '@material-ui/icons/Add';
import AddToPhotos from '@material-ui/icons/AddToPhotos';
import Delete from '@material-ui/icons/Delete'
import Settings from '@material-ui/icons/Settings';
import {Trans, withTranslation} from "react-i18next";
import _ from 'lodash';
import ExpandMore from '@material-ui/icons/PlayArrow';
import DescriptionOutlinedIcon from '@material-ui/icons/InsertDriveFileOutlined';
import FolderIcon from '@material-ui/icons/Folder';
import BootstrapInput from 'components/bootstrap-input/BootstrapInput';
import OrganizationTreeMemberAssignmentPopup from "./OrganizationTreeMemberAssignmentPopup";
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import {
    nodeUnselectedColor,
    nodeSelectedColor,
    christiGreen,
    concreteWhite,
    dustyGrey,
    greyHighlight,
    htmlWhite,
} from 'components/colors/Colors';
import Divider from "@material-ui/core/Divider";
import {AUDIT_CONFIGURATION_ORGANIZATION_LEVEL, routeTo} from "routes/routes";
import {withRouter} from "react-router";
import Tooltip from "@material-ui/core/Tooltip";

const styles = theme => ({
    input: {
        padding: '5px 10px',
        fontSize: '0.875rem'
    },
    icon: {
        fontSize: 20,
        marginRight: 5,
        color: dustyGrey
    },
    iconButtonRoot: {
        padding: 5
    },
    pointerCursor: {
        '&:hover': {
            backgroundColor: greyHighlight,
            cursor: 'pointer'
        }
    },
    checkBoxIcon: {
        fill: christiGreen
    },
});

const AddDialog = (props) => {
    const {
        isAddDialogOpen, reset, addType, translate, title, handleTitleChange, finishAdd, finishAddChild,
        stopEvent
    } = props;
    return (
        <Dialog open={isAddDialogOpen} onClose={reset} onClick={stopEvent}>
            <DialogTitle id="alert-dialog-title">
                {
                    addType === 'ADD'
                        ?
                        `${translate("global.new")} ${translate("global.sibling")}`
                        :
                        <Trans i18nKey={"global.new-child"}/>
                }
            </DialogTitle>
            <DialogContent>
                <BootstrapInput
                    style={{marginTop: 5}}
                    value={title}
                    autoFocus
                    onClick={stopEvent}
                    onChange={handleTitleChange}
                    onEnterKey={addType === 'ADD' ? finishAdd : finishAddChild}
                    name={'title'}
                />
            </DialogContent>
            <DialogActions>
                <Button variant="contained"
                        color="primary"
                        onClick={addType === 'ADD' ? finishAdd : finishAddChild}
                ><Trans i18nKey={"global.save"}/></Button>
            </DialogActions>
        </Dialog>
    );
};

const Menu = (props) => {
    const {classes, selectedOrganizationLevel, add, addChild, deleteOrganizationLevel, stopEvent, goToConfiguration, translate} = props;
    const isDisabledDelete = selectedOrganizationLevel ? !selectedOrganizationLevel.editable : true;
    const isSettingsDisable = selectedOrganizationLevel ? !selectedOrganizationLevel.editable : true;
    const isDisabledAddChild = selectedOrganizationLevel && !selectedOrganizationLevel.editable;
    const isParentEditable = _.get(selectedOrganizationLevel, 'parent.editable', undefined);
    const isDisabledAdd = isParentEditable !== undefined ? !isParentEditable : true;
    return (
        <Flex item container style={{
            backgroundColor: htmlWhite,
            paddingTop: 5,
            paddingBottom: 5,
            borderBottom: `1px solid ${concreteWhite}`
        }}
              onClick={stopEvent}>
            <Tooltip title={isDisabledAdd ? '' : `${translate("global.new")} ${translate("global.sibling")}`}>
                <IconButton className={classes.iconButtonRoot} onClick={add} disabled={isDisabledAdd}>
                    <Add/>
                </IconButton>
            </Tooltip>
            <Tooltip title={isDisabledAddChild ? '' : translate("global.new-child")}>
                <IconButton className={classes.iconButtonRoot} onClick={addChild} disabled={isDisabledAddChild}>
                    <AddToPhotos/>
                </IconButton>
            </Tooltip>
            <Tooltip title={isDisabledDelete ? '' : translate("global.delete")}>
                <IconButton className={classes.iconButtonRoot} onClick={deleteOrganizationLevel}
                            disabled={isDisabledDelete}>
                    <Delete/>
                </IconButton>
            </Tooltip>
            <Tooltip title={isSettingsDisable ? '' : translate("global.configuration")}>
                <IconButton className={classes.iconButtonRoot}
                            onClick={goToConfiguration}
                            disabled={isSettingsDisable}>
                    <Settings/>
                </IconButton>
            </Tooltip>
        </Flex>
    );
};

export class OrganizationTreeRootLeaf extends PureComponent {

    state = {
        isAddDialogOpen: false,
        title: '',
        addType: '',
        dialogOpen: false
    };

    reset = event => {
        if (event) {
            event.preventDefault();
            event.stopPropagation();
        }
        this.setState({
            isAddDialogOpen: false,
            title: '',
            addType: ''
        });
    };

    edit = event => {
        if (this.props.node.selected) {
            event.preventDefault();
            event.stopPropagation();
            if (this.props.node.editable) {
                this.props.updateRootNodeTitle();
            }
        }
    };

    finishEdit = event => {
        event.preventDefault();
        event.stopPropagation();
        this.props.editNode(this.props.node.businessId, this.state.title).then().catch().then(() => {
            this.props.updateRootNodeTitle();
            this.reset();
        });
    };

    add = event => {
        event.preventDefault();
        event.stopPropagation();
        this.setState({
            addType: 'ADD',
            isAddDialogOpen: true,
        })
    };

    addChild = event => {
        event.preventDefault();
        event.stopPropagation();
        this.setState({
            addType: 'ADD_CHILD',
            isAddDialogOpen: true,
        })
    };

    handleTitleChange = event => {
        this.setState({
            title: event.target.value
        })
    };

    finishAdd = event => {
        event.preventDefault();
        event.stopPropagation();
        this.props.addNode(
            _.get(this.props, 'selectedOrganizationLevel.parent.businessId', this.props.node.businessId),
            this.state.title
        ).then().catch().then(() => {
            this.reset();
        });
    };

    finishAddChild = event => {
        event.preventDefault();
        event.stopPropagation();
        const addChildTo = this.props.selectedOrganizationLevel.businessId;
        this.props.addChildNode(addChildTo, this.state.title).then().catch().then(() => {
            this.reset();
        });
    };

    deleteOrganizationLevel = event => {
        event.preventDefault();
        event.stopPropagation();
        this.props.deleteSelection(this.props.selectedOrganizationLevel.businessId);
    };

    stopEvent = event => {
        event.stopPropagation();
    };

    toggleSettingsDialog = () => {
        this.setState({
            dialogOpen: !this.state.dialogOpen
        })
    };

    closeSettingsDialog = () => {
        this.props.fetchOrganizationLevels().then(() => {
            this.toggleSettingsDialog();
        })
    };

    goToConfiguration = () => {
        const route = AUDIT_CONFIGURATION_ORGANIZATION_LEVEL.pathWithId(this.props.selectedOrganizationLevel.businessId);
        routeTo(route, this.props);
    };

    componentDidMount() {
        this.setState(
            {title: this.props.node.title}
        )
    }

    render() {
        const {
            classes, node, toggleOpen, toggleSelection, selectedOrganizationLevel, t: translate, multiSelect,
            updateRootNodeTitle, isRootTitleEditable, noEdit, isAllSelected, toggleSelectAll
        } = this.props;
        const {title, isAddDialogOpen, addType} = this.state;
        const {
            add, addChild, handleTitleChange, finishAdd, finishAddChild, reset, deleteOrganizationLevel,
            stopEvent, finishEdit, edit, toggleSettingsDialog, closeSettingsDialog, goToConfiguration
        } = this;
        const nodeHasChild = _.get(node, "hasChild", false);
        return (

            <Flex item
                  container
                  alignItems={'center'}
                  onClick={multiSelect ? () => {
                  } : toggleSelection(node.businessId)}
            >
                <Flex item container direction={'column'}>
                    <Flex container item justifyContent={'center'} padding={5}
                          style={{backgroundColor: node.selected ? nodeSelectedColor : nodeUnselectedColor}}>
                        <Typography style={{fontWeight: 'bold', color: htmlWhite, fontSize: 16}}>
                            <Trans i18nKey={"audit-management.organization"}/>
                        </Typography>
                    </Flex>
                    {
                        multiSelect &&
                        <Flex item grow={0} container alignItems={'center'}>
                            <Checkbox
                                checked={isAllSelected}
                                style={{padding: 5, marginRight: 5, marginLeft: 2}}
                                onChange={toggleSelectAll}
                                icon={<CheckBoxOutlineBlankIcon style={{fontSize: 20}}/>}
                                checkedIcon={<CheckBoxIcon style={{fontSize: 20}} className={classes.checkBoxIcon}/>}
                            />
                            <Typography><Trans i18nKey={"global.selectAll"}/></Typography>
                        </Flex>
                    }
                    {
                        multiSelect || noEdit ?
                            <Divider/>
                            :
                            <Menu
                                {...{
                                    classes,
                                    selectedOrganizationLevel,
                                    add,
                                    addChild,
                                    deleteOrganizationLevel,
                                    stopEvent,
                                    toggleSettingsDialog,
                                    goToConfiguration,
                                    translate
                                }}/>
                    }
                    <Flex item container alignItems={'center'}>
                        {
                            nodeHasChild
                                ?
                                node.open
                                    ?
                                    <IconButton style={{padding: 5}}
                                                onClick={toggleOpen(node.businessId)}>
                                        <ExpandMore style={{fontSize: 20, transform: 'rotate(90deg)'}}/>
                                    </IconButton>
                                    :
                                    <IconButton style={{padding: 5}}
                                                onClick={toggleOpen(node.businessId)}>
                                        <ExpandMore style={{fontSize: 20}}/>
                                    </IconButton>
                                :
                                <div style={{marginRight: 20}}/>
                        }
                        {
                            nodeHasChild
                                ?
                                !multiSelect && <FolderIcon className={classes.icon}/>
                                :
                                !multiSelect && <DescriptionOutlinedIcon className={classes.icon}/>
                        }
                        {
                            multiSelect &&
                            <Checkbox
                                checked={node.selected}
                                style={{padding: 5, paddingLeft: 0}}
                                onChange={toggleSelection(node.businessId)}
                                icon={<CheckBoxOutlineBlankIcon style={{fontSize: 20}}/>}
                                checkedIcon={<CheckBoxIcon style={{fontSize: 20}} className={classes.checkBoxIcon}/>}
                            />
                        }
                        <Flex container item={'0 0 auto'}>
                            {
                                isRootTitleEditable && node.selected && !noEdit
                                    ?
                                    <BootstrapInput
                                        classes={{
                                            input: classes.input
                                        }}
                                        value={title}
                                        autoFocus
                                        onClick={stopEvent}
                                        onChange={handleTitleChange}
                                        onEnterKey={finishEdit}
                                        onBlur={updateRootNodeTitle}
                                        name={'title'}
                                    />
                                    :
                                    <Typography onClick={edit}
                                                className={classes.pointerCursor}
                                                style={{
                                                    padding: 0,
                                                    fontWeight: node.selected ? 800 : 400
                                                }}>
                                        {node.title}
                                    </Typography>
                            }
                        </Flex>
                    </Flex>
                </Flex>
                <AddDialog
                    {...{
                        isAddDialogOpen,
                        reset,
                        addType,
                        translate,
                        title,
                        handleTitleChange,
                        finishAdd,
                        finishAddChild,
                        stopEvent
                    }}
                />
                {
                    this.state.dialogOpen &&
                    <OrganizationTreeMemberAssignmentPopup
                        dialogOpen={this.state.dialogOpen}
                        onClose={closeSettingsDialog}
                        stopEvent={stopEvent}
                    />
                }
            </Flex>

        );
    }
}

export default withStyles(styles)(withTranslation()(withRouter(OrganizationTreeRootLeaf)));
