import React, {Component, Fragment} from "react";
import {MenuItem, withStyles} from "@material-ui/core";
import _ from "lodash";
import BootstrapSelect from "components/bootstrap-select/BootstrapSelect";
import Flex from "components/grid/Flex";
import {htmlGreen, htmlOrange, htmlRed} from 'components/colors/Colors.js'

const styles = theme => ({
});

const EvaluationMethodItem = (props) => {
    const {item} = props;
    return (
        <Flex container>
            <Flex container
                  alignItems={'center'}
                  style={{
                      width: (100 - item.intervalBoundaries[1]) + '%',
                      paddingLeft: 5,

                  }}>
                <svg width={'100%'} height="5">
                    <rect width={'100%'} height="5" style={{
                        fill: htmlGreen
                    }}/>
                </svg>
            </Flex>
            <Flex item style={{
                width: '10%',
                paddingLeft: 7.5,
                paddingRight: 7.5
            }}>
                <span style={{width: '5%'}}>{item.intervalBoundaries[1]}</span>
            </Flex>
            <Flex container
                  alignItems={'center'}
                  style={{
                      width: (item.intervalBoundaries[1] - item.intervalBoundaries[0]) + '%'
                  }}>
                <svg width={'100%'} height="5">
                    <rect width={'100%'} height="5" style={{
                        fill: htmlOrange
                    }}/>
                </svg>
            </Flex>
            <Flex item style={{
                width: '10%',
                paddingLeft: 7.5,
                paddingRight: 7.5
            }}>
                <span style={{width: '5%'}}>{item.intervalBoundaries[0]}</span>
            </Flex>
            <Flex container
                  alignItems={'center'}
                  style={{
                      width: item.intervalBoundaries[0] + '%' ,
                      paddingRight: 15
                  }}>
                <svg width={'100%'} height="5">
                    <rect width={'100%'} height="5" style={{
                        fill: htmlRed
                    }}/>
                </svg>
            </Flex>
        </Flex>
    )
};

export class EvaluationMethodSelector extends Component {
    render() {
        const {value, items, onChange, name, disabled} = this.props;
        return (
            <Fragment>
                <BootstrapSelect
                    name={name}
                    value={value}
                    onChange={onChange}
                    disabled={disabled}
                    renderValue={value => {
                        return (
                            <EvaluationMethodItem {...{item: items.find(item => item.businessId === value)}} />
                        )
                    }}
                >
                    {
                        (items || []).map((item, index) =>
                            <MenuItem value={_.get(item, 'businessId', '')}
                                      key={index} style={{width: 325}
                            }>

                                {
                                    <EvaluationMethodItem {...{item: item}} />
                                }
                            </MenuItem>
                        )
                    }
                </BootstrapSelect>
            </Fragment>
        );
    }
}

export default withStyles(styles)(EvaluationMethodSelector);
