import React, {Component} from 'react';
import {
    withStyles,
} from "@material-ui/core";
import Flex from 'components/grid/Flex';
import {mobileTaskListMenu} from 'components/zindex/zIndex';
import {
    greyBackground,
    transparentBlack02
} from 'components/colors/Colors';
import SwipeAbleTask from 'scenes/tasks/components/mobile/SwipeAbleTask';
import QuickTaskCreate from 'scenes/tasks/components/mobile/QuickTaskCreate';

const styles = theme => ({
    rootContainer: {
        position: 'absolute',
        top: 0,
        bottom: 0,
        right: 0,
        left: 0,
        marginTop: 54,
        paddingLeft: 10,
        paddingRight: 10
    },
    taskTileAbsoluteContainer: {
        overflow: 'auto',
        overflowScrolling: 'touch',
        position: 'absolute',
        top: 0,
        bottom: 0,
        right: 0,
        left: 0,
    },
    pdcaStatusItem: {
        backgroundColor: greyBackground,
        padding: 4,
        fontWeight: 'bold',
        position: 'sticky',
        width: '100%',
        top: 0,
        zIndex: mobileTaskListMenu,
        boxShadow: `0px 4px 8px 0px ${transparentBlack02}`
    },
});

export class MobileBacklog extends Component {
    render() {
        const {
            classes, searchText, createQuickTask, handleChange, filteredTasks, applicationOnline, swipedTask,
            handleTaskSwipe, archive, toggleKanban, view, user
        } = this.props;

        return (
            <Flex container item direction={'column'} className={classes.rootContainer}>

                <QuickTaskCreate {...{searchText, createQuickTask, handleChange}}/>

                <Flex container item direction={'column'} style={{position: 'relative', padding: 10}}>
                    <div className={classes.taskTileAbsoluteContainer}>
                        <div>
                            {
                                filteredTasks.map((task, index) => (
                                    <SwipeAbleTask {...{
                                        key: task.businessId,
                                        index: swipedTask === task.businessId ? 1 : 0,
                                        task, handleTaskSwipe, archive, toggleKanban, view, applicationOnline, user
                                    }}/>
                                ))
                            }
                        </div>

                    </div>
                </Flex>
            </Flex>
        );
    }
}

export default withStyles(styles)(MobileBacklog);
