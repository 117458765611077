import * as actionTypes from './Main.action.types';
import _ from "lodash";
import configs from 'configs/Configs';
import jwt_decode from "jwt-decode";
import utils from 'utils/Utils';
import {MOBILE_ROUTES, DESKTOP_ROUTES} from 'routes/routes';
import FeatureSwitch from 'utils/FeatureSwitch';

const MAX_SERVER_CONNECTION_STRENGTH = 3;

const initialState = {
    authenticated: false,
    user: '',
    defaultRouteForUser: '/',
    online: true,
    manualOffline: false,
    routesAllowedByUser: [],
    suggestedApplicationVersion: null,
    pageRefreshId: utils.uuid(),
    isOverlayActive: false,
    accessTokenForSynchronization: '',
    stableServerConnection: true,
    serverConnectionStrength: MAX_SERVER_CONNECTION_STRENGTH,
    unsubscriptionResult: ''
};

export default function (state = initialState, action) {
    switch (action.type) {
        case actionTypes.LOAD_ACCESS_TOKEN: {
            const newState = _.cloneDeep(state);
            newState.accessTokenForSynchronization = '';
            newState.user = mapUser(action.payload.data.accessToken, configs.getBackendServiceUrl());
            newState.routesAllowedByUser = mapRoutes(newState.user);
            newState.authenticated = true;
            return newState;
        }
        case actionTypes.LOGOUT: {
            const newState = _.cloneDeep(state);
            newState.accessTokenForSynchronization = state.user.accessToken;
            newState.user = '';
            newState.routesAllowedByUser = [];
            newState.authenticated = false;
            return newState;
        }
        case actionTypes.UPDATE_APPLICATION_INFORMATION: {
            const newState = {...state};
            newState.suggestedApplicationVersion = action.payload.data.frontendVersion;
            return newState;
        }
        case actionTypes.FETCH_USER_SUCCESS: {
            const newState = _.cloneDeep(state);
            newState.user = {
                ...state.user,
                avatar: _.get(action, 'payload.data.data.fetchUser.avatar')
            };
            return newState;
        }
        case actionTypes.SYNCHRONIZATION_STARTED: {
            const newState = {...state};
            newState.isOverlayActive = true;
            return newState;
        }
        case actionTypes.SYNCHRONIZATION_FINISHED: {
            const newState = {...state};
            newState.isOverlayActive = false;
            return newState;
        }
        case actionTypes.TOGGLE_ONLINE: {
            const newState = {...state};
            if (FeatureSwitch.automaticOfflineSwitch.active) {
                if (state.online === true || state.stableServerConnection === true) {
                    newState.online = !state.online;
                    if (newState.online === false) {
                        newState.stableServerConnection = false;
                        newState.serverConnectionStrength = 0;
                        newState.manualOffline = true;
                    } else {
                        newState.manualOffline = false;
                    }
                }
            }
            newState.online = !state.online;
            return newState;
        }
        case actionTypes.BAD_SERVER_CONNECTION_DETECTED: {
            if (FeatureSwitch.automaticOfflineSwitch.active) {
                const newState = {...state};
                if (newState.serverConnectionStrength > 0) {
                    newState.serverConnectionStrength = newState.serverConnectionStrength - 1;
                    if (newState.serverConnectionStrength === 0) {
                        newState.online = false;
                        newState.stableServerConnection = false;
                    }
                }
                return newState;
            }
            return state;
        }
        case actionTypes.FAST_SERVER_CONNECTION_DETECTED: {
            if (FeatureSwitch.automaticOfflineSwitch.active) {
                const newState = {...state};
                if (newState.serverConnectionStrength < MAX_SERVER_CONNECTION_STRENGTH) {
                    newState.serverConnectionStrength = newState.serverConnectionStrength + 1;
                    if (newState.serverConnectionStrength === MAX_SERVER_CONNECTION_STRENGTH) {
                        newState.stableServerConnection = true;
                    }
                }
                return newState;
            }
            return state;
        }
        case actionTypes.SERVER_CONNECTION_LOST: {
            if (FeatureSwitch.automaticOfflineSwitch.active) {
                const newState = {...state};
                newState.online = false;
                newState.stableServerConnection = false;
                newState.serverConnectionStrength = 0;
                return newState;
            }
            return state;
        }
        case actionTypes.UNSUBSCRIBE_SUCCESS: {
            const newState = {...state};
            newState.unsubscriptionResult = 'Successful';
            return newState;
        }
        case actionTypes.UNSUBSCRIBE_FAIL: {
            const newState = {...state};
            newState.unsubscriptionResult = 'Failed';
            return newState;
        }
        default:
            return state;
    }
}

export function mapUser(token, backendUrl) {
    const accessToken = jwt_decode(token);
    const roles = _.get(accessToken, 'resource_access.h4-client-app.roles');

    return {
        businessId: accessToken.sub,
        username: accessToken.preferred_username,
        name: accessToken.name,
        givenName: accessToken.given_name,
        familyName: accessToken.family_name,
        email: accessToken.email,
        roles: roles,
        isAdmin: roles.includes('ROLE_ADMIN'),
        accessToken: token,
    }
}

export function mapRoutes(user) {
    const isMobile = utils.checkBrowser();
    const routes = isMobile ? MOBILE_ROUTES : DESKTOP_ROUTES;
    return utils.filterRoutesBasedOnUser(routes, user);
}
