import {
    createAuditType,
    removeAuditType,
    resetToBeConfirmedAuditSchedules
} from "./AuditTypes.action"

export default {
    mapStateToProps: (state) => {
        return {
            auditTypes: state.generalConfigurationReducer.auditConfiguration.auditTypes,
            assignedAuditSchedules: state.auditTypesReducer.assignedAuditSchedules,
        };
    },
    mapDispatchToProps: (dispatch) => {
        return {
            createAuditType: name => dispatch(createAuditType(name)),
            removeAuditType: (businessId, confirmed) => dispatch(removeAuditType(businessId, confirmed)),
            resetToBeConfirmedAuditSchedules: () => dispatch(resetToBeConfirmedAuditSchedules()),
        };
    }
};
