import React, {Component} from 'react';
import {withStyles, Tabs} from '@material-ui/core';
import {logoBlueDark} from 'components/colors/Colors';

const styles = theme => ({
    tabIndicator: {
        backgroundColor: logoBlueDark,
        height: 6,
        bottom: 5,
    },
});

export class H4Tabs extends Component {

    render() {
        const {classes, children, ...other} = this.props;
        return (
            <Tabs {...other} classes={{indicator: classes.tabIndicator}}>
                {children}
            </Tabs>
        );
    }
}

export default withStyles(styles)(H4Tabs);
