import React, {Component} from 'react';
import {Button, Checkbox, Divider, withStyles, IconButton} from "@material-ui/core";
import {apiAction} from 'domain/task/Action.model';
import Flex from 'components/grid/Flex';
import {Link, withRouter} from 'react-router-dom';
import {newTab, prevPath, routeTo, TASK_ACTION_CREATE, TASK_ACTION_EDIT, TASK_BACKLOG} from 'routes/routes';
import {connect} from 'react-redux';
import connector from './DesktopMyActionList.connect';
import {Trans, withTranslation} from "react-i18next";
import update from 'immutability-helper/index';
import {
    dustyGrey,
    gossipGreen,
    greyBackground,
    htmlBlue,
    htmlWhite,
    logoBlueDark,
    silver
} from 'components/colors/Colors';
import _ from 'lodash';
import {parse} from 'query-string/index';
import ConfirmationDialog from "components/confirmation-dialog/ConfirmationDialog";
import QuickIssueInput from 'scenes/tasks/components/QuickIssueInputField';
import {CREATE_ACTION_SUCCESS, CREATE_ACTION_TO_CACHE} from 'scenes/tasks/actions/action-edit/ActionEdit.action.types';
import ActionListTile from 'scenes/tasks/actions/action-list/components/ActionListTile';
import H4InputWithAdornment from 'components/input/H4InputWithAdornment';
import {Search, CloseOutlined as CloseIcon} from "@material-ui/icons";
import GreySwitch from 'components/switch/GreySwitch';
import {emptyActionForQuickCreate} from 'scenes/tasks/actions/my-action-list/MyActionList.reducer';
import {NEW} from 'components/quill-editor/EditorUtils';
import TaskUpdateEventBus from 'scenes/tasks/TaskUpdateEventBus';
import {treeIndex} from 'components/zindex/zIndex'

export const styles = theme => ({
    rightAlignedButton: {
        display: 'flex',
        flex: '1 0 auto',
        flexDirection: 'row-reverse'
    },
    footerButtonContainer: {
        marginTop: 8,
    },
    controlButtons: {
        minWidth: 150,
    },
    buttonWithMargin: {
        minWidth: 150,
        marginRight: 15
    },
    absoluteContainer: {
        overflow: 'auto',
        overflowScrolling: 'touch',
        position: 'absolute',
        bottom: 0,
        right: 0,
        left: 0,
        top: 0
    },
    hoverBackground: {
        backgroundColor: htmlBlue,
        '&:hover': {
            backgroundColor: gossipGreen
        }
    },
    titleBarItem: {
        lineHeight: 1,
    },
    tab: {
        textTransform: 'initial',
        fontSize: 16,
        fontWeight: 'bold',
        opacity: 0.3
    },
    tabIndicator: {
        backgroundColor: logoBlueDark
    },
    topLineFilters: {
        paddingBottom: 10
    },
    inputContainerMargins: {
        marginRight: 10
    },
    tags: {
        backgroundColor: greyBackground,
        borderRadius: 10,
        border: 'unset',
        marginTop: 5
    },
    tagSearchInput: {
        '& input': {
            backgroundColor: greyBackground,
            fontFamily: 'inherit',
            width: 70,
            fontSize: 16,
            '&::placeholder': {
                color: dustyGrey,
            }
        }
    },
    checkBox: {
        padding: 0
    },
    workspace: {
        marginLeft: 20,
        marginRight: 20,
        marginBottom: 10,
        marginTop: 10,
        zIndex: treeIndex + 1,
    }
});

const ListTabs = (props) => {
    const {switchTabToStories} = props;
    return (
        <Flex item={'0 0 0'} container justifyContent={'flex-end'} alignItems={'center'}>
            <div style={{height: 48}}/>
            <Trans i18nKey="global.tasks"/>
            <GreySwitch
                checked={true}
                onChange={switchTabToStories}
            />
            <span style={{color: logoBlueDark, fontWeight: 'bold'}}><Trans i18nKey="global.my-actions"/></span>
        </Flex>
    )
};

const Filters = (props) => {
    const {
        classes, searchText, translate, handleNamedStateChange, showOpenActionsOnly, handleOpenOnlyFilterChange,
        resetSearchText
    } = props;
    return (
        <Flex container className={classes.topLineFilters} direction={'column'}>
            <Flex container item justifyContent={'space-between'}>
                <Flex item={'0 1 410px'} className={classes.inputContainerMargins}>
                    <H4InputWithAdornment
                        value={searchText}
                        placeholder={translate('global.search')}
                        onChange={handleNamedStateChange}
                        name={'searchText'}
                        fullWidth
                        startAdornment={<Search/>}
                        endAdornment={
                            !_.isEmpty(searchText) &&
                            <IconButton style={{padding: 3, backgroundColor: silver, fontSize: 13, marginRight: 3}}
                                        onClick={resetSearchText}>
                                <CloseIcon style={{color: greyBackground, fontSize: 'inherit'}}/>
                            </IconButton>
                        }
                    />
                </Flex>
                <Flex item container justifyContent={'flex-end'} alignItems={'center'}>
                    <Checkbox
                        color={'primary'}
                        checked={showOpenActionsOnly}
                        onChange={handleOpenOnlyFilterChange}
                        style={{padding: 0, marginRight: 6}}
                    />
                    <Trans i18nKey="task-management.action-list.show-open-only"/>
                </Flex>
            </Flex>
        </Flex>
    )
};

const FootControlButtons = (props) => {
    const {classes, selectedAction, openDeleteTaskDialog, location, user, actionTitle} = props;
    return (
        <Flex container item={'0 0 50px'} alignItems={'center'} justifyContent={'space-between'}
              style={{backgroundColor: htmlWhite}} className={classes.footerButtonContainer}>
            <Button variant="contained"
                    color="primary"
                    onClick={openDeleteTaskDialog}
                    disabled={!user.isAdmin || _.isEmpty(selectedAction) || !_.isEmpty(actionTitle)}
                    className={classes.controlButtons}
            >
                <Trans i18nKey="global.delete"/>
            </Button>
            <Button variant="contained"
                    color="primary"
                    component={Link}
                    to={{
                        pathname: TASK_ACTION_EDIT.pathWithIds(
                            _.get(selectedAction, 'taskBusinessId'),
                            _.get(selectedAction, 'businessId')
                        ),
                        state: {prevPath: prevPath({location})}
                    }}
                    disabled={_.isEmpty(selectedAction) || !_.isEmpty(actionTitle)}
                    className={classes.controlButtons}
            >
                <Trans i18nKey="global.open"/>
            </Button>
        </Flex>
    );
};

const ActionList = (props) => {
    const {
        classes, actions, selectedAction, handleDoubleClickOnAction, toggleActionSelection, applicationOnline,
        isTaskOfflineCreated, user, removeUnseenCommentTag, removeUnseenAttachmentTag, bottom, fetchActionFromServer
    } = props;
    return (
        <Flex container item direction={'column'} style={{position: 'relative'}}>
            <Flex container item direction={'column'} className={classes.absoluteContainer}>
                {
                    actions.map(action => (
                        <div key={action.businessId} style={{marginLeft: 15, marginRight: 15}}>
                            <Divider/>
                            <ActionListTile {...{
                                action,
                                isSelected: selectedAction.businessId === action.businessId,
                                handleDoubleClickOnAction,
                                handleClickOnAction: toggleActionSelection,
                                applicationOnline,
                                isTaskOfflineCreated,
                                user,
                                refresh: () => fetchActionFromServer(action.businessId),
                                removeUnseenCommentTag,
                                removeUnseenAttachmentTag
                            }}/>
                        </div>
                    ))
                }
                <div id={'bottom-task-for-scroll'} ref={bottom}/>
                <Divider style={{marginLeft: 15, marginRight: 15}}/>
            </Flex>
        </Flex>
    );
};

export class DesktopMyActionList extends Component {

    bottom = React.createRef();
    selectedRef = null;
    quickActionInput = React.createRef();

    constructor(props) {
        super(props);
        let params = parse(props.location.search);
        this.state = {
            ...this.mapFilterParamsToState(params),
            deleteActionDialogOpen: false,
            actionTitle: '',
            showOpenActionsOnly: false
        };
    }

    componentDidMount() {
        if (!_.isEmpty(this.props.selectedAction)) {
            this.scrollToSelectedRef();
        }
        this.updateSubscription = TaskUpdateEventBus.update.subscribe(payload => {
            if (payload.type === CREATE_ACTION_SUCCESS || payload.type === CREATE_ACTION_TO_CACHE) {
                this.scrollToBottom();
            }
        });
        this.props.toggleProjectFilterSelection(this.props.personalProject.businessId);
    }

    componentWillUnmount() {
        TaskUpdateEventBus.update.unSubscribe(this.updateSubscription);
    }

    mapFilterParamsToState = (params) => {
        const {selectedTags, searchText} = params;
        const selectedTagsInArray = selectedTags ? selectedTags.split(',').map(tag => ({name: tag})) : [];
        return {
            selectedTags: selectedTagsInArray,
            searchText: searchText ? searchText : '',
        };
    };

    updateUrlWithNewFilters = () => {
        routeTo(TASK_BACKLOG.pathWithFilterParams({
            selectedTags: this.state.selectedTags.map(tag => tag.name),
            view: this.props.view,
            searchText: this.state.searchText
        }), this.props);
    };

    scrollToSelectedRef = () => {
        this.selectedRef && this.selectedRef.scrollIntoView({
            block: "end",
            inline: "nearest",
            behavior: "auto"
        });
    };

    toggleActionSelection = action => event => {
        this.props.selectAction(this.props.selectedAction.businessId === action.businessId ? {} : action);
        this.quickActionInput.current && this.quickActionInput.current.blur();
        if (event && event.ctrlKey) {
            newTab(TASK_ACTION_EDIT.pathWithIds(_.get(action, 'taskBusinessId'), _.get(action, 'businessId')));
        }
    };

    deleteSelectedAction = () => {
        this.props.archiveAction(this.props.selectedAction).then(() => {
            this.closeDeleteActionDialog();
            this.updateUrlWithNewFilters();
            this.props.selectAction({});
        });
    };

    openDeleteTaskDialog = () => {
        this.setState({deleteActionDialogOpen: true});
    };

    closeDeleteActionDialog = () => {
        this.setState({deleteActionDialogOpen: false});
    };

    handleNamedStateChange = event => {
        this.setState({[event.target.name]: event.target.value}, this.updateUrlWithNewFilters);
    };

    handleChange = event => {
        this.setState({
            [event.target.name]: event.target.value,
        });
    };

    freeTextFilter = action => {
        const matchingTranslation = action.translations
            .find(translation => translation.language.code === this.props.i18n.language) || action.translations[0];
        return action.businessId.includes(this.state.searchText)
            || (matchingTranslation.title && matchingTranslation.title.toLowerCase().includes(this.state.searchText.toLowerCase()));
    };

    openOnlyFilter = action => {
        return this.state.showOpenActionsOnly
            ? action.status === 'IN_PROGRESS'
            : true;
    };

    handleOpenOnlyFilterChange = event => {
        this.setState({
            showOpenActionsOnly: event.target.checked
        });
    };

    handleDoubleClickOnAction = selectedAction => () => {
        routeTo(TASK_ACTION_EDIT.pathWithIds(
            _.get(selectedAction, 'taskBusinessId'),
            _.get(selectedAction, 'businessId')), this.props);
    };

    scrollToBottom = () => {
        this.bottom.current && this.bottom.current.scrollIntoView({
            block: "end",
            inline: "nearest",
            behavior: "auto"
        });
    };

    createQuickAction = () => {
        let action = {
            ...emptyActionForQuickCreate(
                this.props.todoTaskBusinessId,
                this.props.user,
                this.props.configurationFromBackend.supportedLanguages,
                this.props.configurationFromBackend.deadlines,
            ),
        };
        const matchingTranslation = action.translations.find(translation => translation.language.code === this.props.i18n.language);
        matchingTranslation.title = this.state.actionTitle;
        matchingTranslation.origin = NEW;
        this.setState({
            actionTitle: ''
        });
        if (matchingTranslation.title !== '') {
            this.props.createAction(this.props.todoTaskBusinessId, action).then(response => {
                this.scrollToBottom();
                const actionFromServer = _.get(response, 'payload.data.data.createAction');
                const actionFromCache = _.get(response, 'data.action');
                const createdAction = apiAction(actionFromServer || actionFromCache);
                this.toggleActionSelection(createdAction)();
            });
        }
    };

    handleTagDelete = index => {
        this.setState(oldState => {
            return {
                selectedTags: update(oldState.selectedTags, {
                    $splice: [[index, 1]]
                })
            };
        }, this.updateUrlWithNewFilters)
    };

    handleTagAddition = tag => {
        this.setState(oldState => {
            return {
                selectedTags: update(oldState.selectedTags || [], {
                    $push: [tag]
                })
            }
        }, this.updateUrlWithNewFilters)
    };

    removeUnseenAttachmentTag = task => () => {
        if (task.hasUnseenAttachment) {
            this.props.removeUnseenAttachmentTag(task);
        }
    };

    removeUnseenCommentTag = task => () => {
        if (task.hasUnseenComment) {
            this.props.removeUnseenCommentTag(task);
        }
    };

    userFilter = action => {
        return this.props.selectedUserNames.length === 0 || this.props.selectedUserNames.includes(_.get(action, 'assignee.username'));
    };

    resetSearchText = event => {
        this.setState({searchText: ''}, this.updateUrlWithNewFilters);
    };

    render() {
        const {handleChange} = this;
        const {
            classes, actions, user, t: translate, todoTaskBusinessId, fetchActionFromServer,
            location, applicationOnline, selectedAction, tagSuggestions, switchTabToStories
        } = this.props;
        const {
            searchText, deleteActionDialogOpen, actionTitle, selectedTags, showOpenActionsOnly
        } = this.state;
        const {
            handleDoubleClickOnAction, toggleActionSelection, createQuickAction, handleNamedStateChange,
            quickActionInput, openDeleteTaskDialog, closeDeleteActionDialog,
            deleteSelectedAction, removeUnseenAttachmentTag, removeUnseenCommentTag,
            handleTagAddition, handleTagDelete, handleOpenOnlyFilterChange, bottom, resetSearchText
        } = this;
        const filteredActions = actions
            .filter(this.userFilter)
            .filter(this.openOnlyFilter)
            .filter(this.freeTextFilter);
        return (
                <Flex item container direction={'column'} className={classes.workspace}>
                    <ListTabs {...{classes, switchTabToStories}}/>
                    <Filters {...{
                        classes, searchText,
                        handleNamedStateChange,
                        selectedTags,
                        tagSuggestions,
                        handleTagAddition,
                        handleTagDelete,
                        translate,
                        showOpenActionsOnly,
                        handleOpenOnlyFilterChange,
                        resetSearchText
                    }}/>
                    <ActionList
                        {...{
                            classes,
                            actions: filteredActions,
                            selectedAction,
                            applicationOnline,
                            isTaskOfflineCreated: false,
                            handleDoubleClickOnAction,
                            toggleActionSelection,
                            user,
                            removeUnseenCommentTag,
                            removeUnseenAttachmentTag,
                            fetchActionFromServer,
                            bottom
                        }}
                    />
                    <QuickIssueInput {...{
                        user,
                        location,
                        name: 'actionTitle',
                        issueTitle: actionTitle,
                        onChange: handleChange,
                        onEnter: createQuickAction,
                        reference: quickActionInput,
                        placeHolder: translate('task-management.action-list.quickActionPlaceholder'),
                        pathname: TASK_ACTION_CREATE.pathWithId(todoTaskBusinessId)
                    }}/>
                    <FootControlButtons {...{
                        classes,
                        selectedAction,
                        user,
                        location,
                        openDeleteTaskDialog,
                        actionTitle
                    }}/>
                    <ConfirmationDialog {...{
                        dialogOpen: deleteActionDialogOpen,
                        onDialogClose: closeDeleteActionDialog,
                        onConfirm: deleteSelectedAction,
                        confirmationTextKey: "task-management.deleteActionConfirmation"
                    }}/>
                </Flex>
        );
    }
}

export default withStyles(styles)(connect(connector.mapStateToProps, connector.mapDispatchToProps)(withTranslation()(withRouter(DesktopMyActionList))));
