import React, {Component, Fragment} from 'react';
import _ from 'lodash';
import {Link} from 'react-router-dom';
import {ButtonBase, Typography, withStyles} from "@material-ui/core";
import Flex from 'components/grid/Flex';
import {EXECUTE_AUDIT, REVIEW_AUDIT, SETUP_AUDIT} from 'routes/routes';
import {
    audit as auditColor,
    auditHighlight,
    christiGreen,
    doveGrey,
    ghostGrey as disabledColor,
    guardsmanRed,
    htmlBlack,
    htmlGrey,
    htmlWhite,
    superNovaYellow,
    transparentDownRiver025
} from 'components/colors/Colors';
import {getColor, getHighlightColor} from 'components/colors/Result';
import classNames from 'classnames';
import {Trans, withTranslation} from 'react-i18next';
import AddIcon from '@material-ui/icons/Add';
import moment from 'moment';
import FeatureSwitch from 'utils/FeatureSwitch';

const styles = theme => ({
    circle: {
        position: 'absolute',
        width: 45,
        height: 45,
        '@media (min-width: 800px)': {
            width: 60,
            height: 60,
        },
        borderRadius: '50%',
        boxShadow: `0 0 7px 0 ${htmlGrey}`,
        zIndex: 1,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    scheduledItemCircle: {
        border: `3px solid ${htmlWhite}`,
    },
    adhocAuditButtonCircle: {
        border: `5px solid ${auditColor}`,
        backgroundColor: htmlWhite,
        color: auditColor,
    },
    scheduleText: {
        fontSize: 22,
        fontWeight: 'bold',
        color: htmlWhite,
        '@media (min-width: 800px)': {
            fontSize: '1.75em',
        },
    },
    scheduledItemInfo: {
        marginLeft: 20,
        paddingLeft: 25,
        '@media (min-width: 800px)': {
            paddingLeft: 45,
            paddingTop: 5,
            paddingBottom: 5,
        },
        width: '100%',
        boxShadow: `0px 1px 2px 0px ${transparentDownRiver025}`,
        borderRadius: 7
    },
    title: {
        fontSize: '1em',
        fontWeight: 'bold'
    },
    area: {
        fontSize: '0.875rem',
    },
    sectionTitle: {
        fontSize: '1.15rem',
        fontWeight: 'bold',
        marginTop: 30
    },
    greenProgress: {
        backgroundColor: christiGreen
    },
    yellowProgress: {
        backgroundColor: superNovaYellow
    },
    redProgress: {
        backgroundColor: guardsmanRed
    },
    scheduledItemContainer: {
        marginTop: 20,
        '@media (min-width: 800px)': {
            marginTop: 30,
        },
    },
    additionalInfo: {
        paddingRight: 5,
        textAlign: 'right'
    },
    auditsContainer: {
        overflow: 'visible',
        padding: 12,
        marginBottom: 5,
        '@media (min-width: 700px)': {
            top: 25,
            paddingLeft: 60,
            paddingRight: 60,
            marginBottom: 15,
            marginTop: 80,
        },
        '@media (min-width: 1000px)': {
            top: 25,
            paddingLeft: 200,
            paddingRight: 200,
            marginBottom: 15,
            marginTop: 80,
        },
    },
    adhocAuditButton: {
        width: '100%',
        boxShadow: `0 0 7px 0 ${htmlGrey}`,
        position: 'relative',
        minHeight: 30,
        justifyContent: 'start',
        borderRadius: '50px 7px 7px 50px',
        '@media (min-width: 800px)': {
            height: 40,
        },
    },
    adhocAuditButtonText: {
        fontSize: '1em',
        fontWeight: 'bold',
        color: auditColor,
        textAlign: 'left',
        marginLeft: 55,
        '@media (min-width: 800px)': {
            paddingLeft: 20,
        },
    },
    adhocAuditButtonContainer: {
        marginTop: 10,
    },
    pageTitle: {
        fontWeight: 'bold',
        fontSize: '1.25em',
        color: doveGrey,
        '@media (min-width: 800px)': {
            fontSize: '2em',
        },
    },
    pageTitleContainer: {
        position: 'absolute',
        top: 10,
        '@media (min-width: 800px)': {
            top: 22,
            zIndex: -1
        },
        left: 0,
        right: 0,
    },
    numberOfQuestions: {
        fontSize: 18,
        marginRight: 15,
        '@media (min-width: 800px)': {
            fontSize: '1.75em',
            marginRight: 25,
        },
    },
    infoContainer: {
        marginTop: 20,
        '@media (min-height: 800px)': {
            marginTop: 30,
        },
    },
    infoHeadline: {
        fontWeight: 'bold',
        fontSize: '1.15rem',
        marginBottom: 30
    },
    infoTitle: {
        color: htmlGrey,
        textAlign: 'center',
        fontSize: '1.15rem',
    },
    noAuditsContainer: {
        marginTop: 50,
        '@media (min-height: 800px)': {
            marginTop: 120,
        },
    },
    infoSpace: {
        marginTop: 30,
    },
});

const PicturesCache = (props) => {
    const {urls} = props;
    return (
        urls.map((url, key) => {
            return <img key={key} src={url} alt={''} style={{display: 'none'}}/>
        })
    )
};

const ScheduledItem = (props) => {
    const {classes, onClick, color, highlightColor, path, translate, name, audit} = props;
    const {duration, activationDate, title, numberOfQuestions, area} = audit;
    const textColor = htmlBlack;
    const durationTypeLetter = translate('audit-administration.duration.' + duration).charAt(0);
    const disabled = moment(activationDate).isAfter(moment());
    return (
        <ButtonBase onClick={onClick}
                    component={Link}
                    to={path}
                    className={classes.scheduledItemContainer}
                    disabled={disabled}
        >
            <Flex item container alignItems={'center'}>
                <div className={classNames(classes.circle, classes.scheduledItemCircle)}
                     style={{backgroundColor: disabled ? disabledColor : color}}>
                    <Typography className={classes.scheduleText}>{durationTypeLetter}</Typography>
                </div>
                <div className={classes.scheduledItemInfo} style={{
                    backgroundColor: disabled ? disabledColor : highlightColor,
                }}>
                    <Flex item container grow={6} alignItems={'center'} justifyContent={'space-between'}
                          style={{
                              marginLeft: 10,
                          }}
                    >
                        <Flex item container direction={'column'}>
                            <Typography style={{color: textColor, textAlign: 'left'}}
                                        className={classes.title}>
                                {title} <Trans i18nKey="audit-management.scheduled-list.title"/>
                            </Typography>
                            <Typography style={{color: textColor}} className={classes.area}>
                                {area}
                            </Typography>
                            {
                                FeatureSwitch.devSwitch.active &&
                                <Typography style={{
                                    color: 'black',
                                    fontSize: 12,
                                    maxWidth: '70vw',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis'
                                }}>
                                    {`${audit.businessId} --- ${name}`}
                                </Typography>
                            }

                            {
                                FeatureSwitch.devSwitch.active &&
                                <Typography style={{
                                    color: 'black',
                                    fontSize: 12,
                                    maxWidth: '70vw',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis'
                                }}>
                                    {`answers : ${audit.answers.length}`}
                                </Typography>
                            }
                        </Flex>
                        <Typography className={classes.numberOfQuestions} style={{color: textColor}}>
                            {numberOfQuestions}
                        </Typography>
                    </Flex>
                </div>
            </Flex>
        </ButtonBase>
    );
};

export class ScheduledAudits extends Component {

    render() {
        const {classes, t: translate} = this.props;
        const {audits, pictureUrlsToDownload, applicationOnline} = this.props.data;
        const {executeAudit, reviewAudit} = this.props.actions;
        const openAudits = _.orderBy(
            audits.filter(audit => audit.status === 'OPEN'),
            ['dueDate'],
            ['desc']
        );

        const inProgressAudits = _.orderBy(
            audits.filter(audit => audit.status === 'IN_PROGRESS'),
            ['dueDate'],
            ['desc']
        );

        const closedAudits = _.orderBy(
            audits.filter(audit => audit.status === 'CLOSED'),
            ['finishedDate'],
            ['desc']
        );
        const onlyClosedAudits = closedAudits.length > 0 && openAudits.length === 0 && inProgressAudits.length === 0;
        const noAudits = closedAudits.length === 0 && openAudits.length === 0  && inProgressAudits.length === 0;;
        const onlineStatusPostfix = applicationOnline ? "online" : "offline";
        return (
            <Flex container item direction={'column'} className={classes.auditsContainer}
                  style={{
                      height: noAudits ? '25%' : 'auto'
                  }}
            >
                <PicturesCache urls={pictureUrlsToDownload}/>
                <Flex item container justifyContent={'center'} className={classes.pageTitleContainer}>
                    <div className={classes.pageTitle}><Trans i18nKey={"global.my-audits"}/></div>
                </Flex>
                {
                    applicationOnline &&
                    <Flex item className={classes.adhocAuditButtonContainer}>
                        <ButtonBase component={Link}
                                    to={SETUP_AUDIT.path}
                                    className={classes.adhocAuditButton}
                        >
                            <AddIcon className={classNames(classes.circle, classes.adhocAuditButtonCircle)}/>
                            <Typography className={classes.adhocAuditButtonText}><Trans
                                i18nKey="audit-management.scheduled-list.createAdhocAuditButton"/></Typography>
                        </ButtonBase>
                    </Flex>
                }
                <Flex container item direction={'column'}>
                    {
                        !noAudits && openAudits.length > 0 &&
                        <Flex item container direction={'column'}>
                            <Typography className={classes.sectionTitle}>
                                <Trans i18nKey="audit-management.scheduled-list.openAuditsTitle"/>
                            </Typography>
                            {

                                openAudits.map((audit, index) => {
                                        return <ScheduledItem
                                            key={index}
                                            color={auditColor}
                                            highlightColor={auditHighlight}
                                            onClick={() => executeAudit(audit)}
                                            path={EXECUTE_AUDIT.path}
                                            classes={classes}
                                            translate={translate}
                                            audit={audit}
                                            name={_.get(audit, 'scheduleName')}
                                        />
                                    }
                                )
                            }
                        </Flex>
                    }
                    {
                        !noAudits && inProgressAudits.length > 0 &&
                        <Flex item container direction={'column'}>
                            <Typography className={classes.sectionTitle}>
                                <Trans i18nKey="audit-management.scheduled-list.inProgressAuditsTitle"/>
                            </Typography>
                            {

                                inProgressAudits.map((audit, index) => {
                                        return <ScheduledItem
                                            key={index}
                                            color={auditColor}
                                            highlightColor={auditHighlight}
                                            onClick={() => executeAudit(audit)}
                                            path={EXECUTE_AUDIT.path}
                                            classes={classes}
                                            audit={audit}
                                            translate={translate}
                                            name={_.get(audit, 'scheduleName')}
                                        />
                                    }
                                )
                            }
                        </Flex>
                    }
                    {
                        onlyClosedAudits &&
                        <Fragment>
                            <Typography className={classes.sectionTitle}>
                                <Trans i18nKey="audit-management.scheduled-list.openAuditsTitle"/>
                            </Typography>
                            <Flex container item className={classes.infoContainer} direction={'column'}
                                  justifyContent={'center'} alignContent={'center'}>
                                <Typography className={classes.infoTitle}>
                                    <Trans i18nKey="audit-management.scheduled-list.onlyClosedAuditsTitlePart1"/>
                                </Typography>
                                <Typography className={classes.infoTitle}>
                                    <Trans i18nKey="audit-management.scheduled-list.onlyClosedAuditsTitlePart2"/>
                                </Typography>
                            </Flex>
                        </Fragment>
                    }
                    {
                        !noAudits && closedAudits.length > 0 &&
                        <Fragment>
                            <Typography className={classes.sectionTitle}>
                                <Trans i18nKey="audit-management.scheduled-list.doneAuditsTitle"/>
                            </Typography>
                            <Flex item container direction={'column'}>
                                {
                                    closedAudits.map((audit, index) => {
                                            return <ScheduledItem
                                                key={index}
                                                color={getColor(audit.result.label)}
                                                highlightColor={getHighlightColor(audit.result.label)}
                                                onClick={() => reviewAudit(audit)}
                                                path={REVIEW_AUDIT.path}
                                                classes={classes}
                                                audit={audit}
                                                translate={translate}
                                            />
                                        }
                                    )
                                }
                            </Flex>
                        </Fragment>
                    }
                    {
                        noAudits &&
                        <Flex container className={classes.noAuditsContainer} item direction={'column'}
                              justifyContent={'center'} alignContent={'center'}>
                            <Typography
                                className={classNames(classes.infoTitle, classes.infoSpace, classes.infoHeadline)}>
                                <Trans
                                    i18nKey={"audit-management.scheduled-list.noAudits." + onlineStatusPostfix + ".TitlePart1"}/>
                            </Typography>
                            <Typography className={classNames(classes.infoTitle, classes.infoSpace)}>
                                <Trans
                                    i18nKey={"audit-management.scheduled-list.noAudits." + onlineStatusPostfix + ".TitlePart2"}/>
                            </Typography>
                            <Typography className={classNames(classes.infoTitle, classes.infoSpace)}>
                                <Trans
                                    i18nKey={"audit-management.scheduled-list.noAudits." + onlineStatusPostfix + ".TitlePart3"}/>
                            </Typography>
                        </Flex>
                    }

                </Flex>
            </Flex>
        );
    }
}

export default withStyles(styles)(withTranslation()(ScheduledAudits));
