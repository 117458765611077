import React, {PureComponent} from "react";
import {withStyles} from "@material-ui/core";
import {silver, christiGreen, htmlOrange, auditPaperGrey, materialUIBorder, inactiveTabGrey, htmlBlack} from "components/colors/Colors";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import CircleIcon from '@material-ui/icons/Lens';
import _ from 'lodash';
import classNames from 'classnames';
import Flex from 'components/grid/Flex';

const styles = theme => ({
    basicContainer: {
        paddingTop: 25
    },
    title: {
        fontSize: 20,
        fontWeight: 'bold',
        color: htmlBlack
    },
    tabs: {
        marginBottom: -1
    },
    tab: {
        marginRight: 10,
        marginTop: 10,
        borderColor: materialUIBorder,
        borderStyle: 'solid',
        borderWidth: '1px 1px 0px 1px',
        fontWeight: 'bold',
        borderTopRightRadius: 4,
        borderTopLeftRadius: 4,
        color: htmlBlack,
        backgroundColor: auditPaperGrey,
        opacity: 1
    },
    indicator: {
        color: htmlBlack,
        zIndex: -1
    },
    languageStatusIcon: {
        height: 18,
        width: 18,
        marginLeft: 10,
    },
});


function getTabStatusColor(translation) {
    if (_.isEmpty(translation.question)) {
        return silver;
    } else {
        if (_.eq(translation.status, 'APPROVED')) {
            return christiGreen;
        } else {
            return htmlOrange;
        }
    }
}

export class TranslationTabs extends PureComponent {
    render() {
        const {classes, translations, selectedTranslation, selectedTranslationIndex, handleTabChange} = this.props;

        return (
            <Tabs value={selectedTranslationIndex}
                  onChange={handleTabChange}
                  textColor='inherit'
                  indicatorColor='primary'
                  className={classNames(classes.basicContainer, classes.tabs)}
                  classes={{
                      indicator: classes.indicator
                  }}>
                >
                {
                    translations.map((translation, index) => {
                        const isMatchingLanguage = selectedTranslation.language.code === translation.language.code;
                        return (
                            <Tab key={translation.language.label + '-tab'}
                                 label={
                                     <Flex container justifyContent={'center'} alignItems={'center'}>
                                         <div>{translation.language.label}</div>
                                         <CircleIcon className={classes.languageStatusIcon}
                                                     style={{
                                                         color: getTabStatusColor(translation),
                                                     }}/>
                                     </Flex>
                                 }
                                 value={index}
                                 className={classes.tab}
                                 style={{
                                     backgroundColor: isMatchingLanguage ? auditPaperGrey : inactiveTabGrey
                                 }}
                            />
                        );
                    })
                }
            </Tabs>
        );
    }
}

export default withStyles(styles)(TranslationTabs);
