import * as actionTypes from './ActionEdit.action.types';
import utils from 'utils/Utils';
import taskCache from "scenes/tasks/Tasks.cache";
import {validateApplicationStatusForCache} from "cache/Cache.utils";
import {ACTION_GRAPHQL, createActionInput, editActionInput, offlineAction} from 'domain/task/Action.model';
import TaskUpdateEventBus from 'scenes/tasks/TaskUpdateEventBus';
import _ from 'lodash';
import query from 'store/GraphqlQueries.js';

export function fetchAction(actionId, taskId){
    return taskCache.getActionFromServerOrCache(actionId, taskId, fetchActionFromServer(actionId), fetchActionFromCache);
}

export function fetchActionFromServer(actionId){
    return (dispatch, getState) => {
        return dispatch(
            query.graphql({
                type: actionTypes.FETCH_ACTION,
                data: {
                    supportedLanguages: getState().tasksReducer.configurationFromBackend.supportedLanguages
                },
                caller: 'fetchAction',
                query: `
                        query
                        ($actionId: String!){
                            fetchAction(actionId: $actionId) {
                                ${ACTION_GRAPHQL}
                            }
                        }
                       `,
                variables: {
                    actionId
                }
            })
        );
    }
}

export function fetchActionFromCache(action) {
    return (dispatch, getState) => {
        return dispatch({
            type: actionTypes.FETCH_ACTION_FROM_CACHE,
            data: {
                cache: {
                    action
                },
                supportedLanguages: getState().tasksReducer.configurationFromBackend.supportedLanguages
            }
        });
    }
}

export function getTemporaryActionReferenceId() {
    return (dispatch, getState) => {
        return dispatch({
            type: actionTypes.FETCH_TEMPORARY_ACTION_REFERENCE_ID,
            data: {
                taskBusinessId: getState().taskEditReducer.task.businessId,
                user: getState().mainReducer.user,
                languages: getState().tasksReducer.configurationFromBackend.supportedLanguages,
                dueDate: getState().taskEditReducer.task.dueDate
            },
            payload: {
                client: 'api',
                request: {
                    url: '/task-management/action-reference-id',
                    method: 'GET',
                }
            }
        }).catch(() => {
            return {
                type: actionTypes.FETCH_TEMPORARY_ACTION_REFERENCE_ID_OFFLINE,
                data: {
                    referenceId: "OFFLINE-" + utils.uuid()
                }

            }
        });
    }
}

export function updateActionInCache(response) {
    const dataWrapper = _.get(response, 'payload.data.data');
    const actualPayload = dataWrapper[Object.keys(dataWrapper)[0]];
    return taskCache.updateOrCreateActionInCache(actualPayload);
}

export function createAction(taskBusinessId, action) {
    return (dispatch, getState) => {
        return dispatch({
            type: actionTypes.CREATE_ACTION,
            data: {user: getState().mainReducer.user},
            payload: {
                client: 'graphql',
                request: {
                    method: 'post',
                    data: {
                        query: `mutation createAction($createActionInput: CreateActionInput){
    	                        createAction(createActionInput: $createActionInput){
      	                            ${ACTION_GRAPHQL}
    	                        }
                            }
                           `,
                        variables: {
                            createActionInput: createActionInput(action,
                                getState().actionEditReducer.newActionRefId,
                                //TODO LM: OFFLINE TECH-DEBT
                                //TODO LM: This might wrong when the task is offline made and still in sync, and we try to crateAction
                                //TODO LM: Backend will blow up.
                                taskBusinessId)
                        }
                    }
                }
            }
        })
            .then(response => {
                TaskUpdateEventBus.update.notify({
                    taskBusinessId,
                    actionBusinessId: response.payload.data.data.createAction.businessId,
                    type: actionTypes.CREATE_ACTION_SUCCESS
                });
                updateActionInCache(response);
                return response;
            })
            .catch((originalError) => {
                //TODO LM: OFFLINE TECH-DEBT
                //TODO LM: We assume that the Id is valid, however there is small window during task sync when its not
                //TODO LM: When the sync kicks in the reducer still have the offline id until the process finishes
                //TODO LM: If we try to create new actions during that time the backend will blow up and since we are online
                //TODO LM: The new action will not be saved in the offline store.
                const actionToCache = offlineAction(taskBusinessId, action);
                return dispatch(
                    validateApplicationStatusForCache(
                        taskCache.cacheCreatedAction(
                            actionToCache,
                            taskBusinessId
                        ),
                        originalError
                    ))
                    .then(() => {
                        TaskUpdateEventBus.update.notify({
                            taskBusinessId,
                            actionBusinessId: actionToCache.businessId,
                            type: actionTypes.CREATE_ACTION_TO_CACHE
                        });
                        return dispatch({
                            type: actionTypes.CREATE_ACTION_TO_CACHE,
                            data: {action: actionToCache, user: getState().mainReducer.user}
                        })
                    })
                    .catch((error) => {
                        console.error(error, originalError)
                    });

            });
    }
}

export function editAction(action, forceTranslate) {
    return (dispatch, getState) => {
        const user = getState().mainReducer.user;
        return dispatch({
            type: actionTypes.EDIT_ACTION,
            data: {user: user},
            payload: {
                client: 'graphql',
                request: {
                    method: 'post',
                    data: {
                        query: `mutation editAction($editActionInput: EditActionInput){
    	                        editAction(editActionInput: $editActionInput){
      	                            ${ACTION_GRAPHQL}
    	                        }
                            }
                           `,
                        variables: {
                            editActionInput: editActionInput(action, forceTranslate)
                        }
                    }
                }
            }
        })
            .then(response => {
                TaskUpdateEventBus.update.notify({
                    taskBusinessId: action.taskBusinessId,
                    actionBusinessId: action.businessId,
                    type: actionTypes.EDIT_ACTION_SUCCESS
                });
                updateActionInCache(response);
                return response;
            })
            .catch((originalError) => {
                const actionToCache = offlineAction(action.taskBusinessId, action, forceTranslate);
                return dispatch(
                    validateApplicationStatusForCache(
                        taskCache.cacheEditedAction(
                            actionToCache,
                            action.taskBusinessId
                        ),
                        originalError
                    )
                ).then(() => {
                    TaskUpdateEventBus.update.notify({
                        taskBusinessId: action.taskBusinessId,
                        actionBusinessId: action.businessId,
                        type: actionTypes.EDIT_ACTION_TO_CACHE
                    });
                    return dispatch({
                        type: actionTypes.EDIT_ACTION_TO_CACHE,
                        data: {action: actionToCache,  user: getState().mainReducer.user}
                    })
                }).catch((error) => {
                    console.error(error, originalError)
                });
            });
    }
}

export function fetchProject(projectBusinessId) {
    return (dispatch, getState) => {
        const result = getState().projectReducer.projectTree.find(project => project.businessId === projectBusinessId);
        return dispatch({
            type: actionTypes.FETCH_PROJECT,
            data: {
                project: result
            }
        });
    }
}
