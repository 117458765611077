import {
    cloneQuestionnaire,
    deleteQuestionnaire,
    fetchQuestionnaires,
    updateQuestionnaireStatus
} from './Questionnaires.action'

export default {
    mapStateToProps: (state) => {
        return {
            questionnaires: state.questionnairesReducer.questionnaires,
            user: state.mainReducer.user,
            applicationOnline: state.mainReducer.online,
            organizationFilterBusinessId: state.organizationTreeReducer.organizationFilterBusinessId,
            selectedOrganizationLevel: state.organizationTreeReducer.selectedOrganizationLevel
        };
    },
    mapDispatchToProps: (dispatch) => {
        return {
            fetchQuestionnaires: () => dispatch(fetchQuestionnaires()),
            updateQuestionnaireStatus: (questionnaireId, status) => dispatch(updateQuestionnaireStatus(questionnaireId, status)),
            deleteQuestionnaire: (questionnaireId) => dispatch(deleteQuestionnaire(questionnaireId)),
            cloneQuestionnaire: (questionnaire) => dispatch(cloneQuestionnaire(questionnaire)),
        };
    }
};
