import React, {Component} from 'react';
import {Route, Switch, Redirect} from 'react-router-dom';
import {connect} from 'react-redux';
import connector from './Audit.connect';
import {AUDIT_RESULT, SETUP_AUDIT, SCHEDULED_AUDITS, EXECUTE_AUDIT, REVIEW_AUDIT} from "routes/routes";
import ScheduledAudits from 'scenes/audit/schedule/ScheduledAudits';
import SetupQuickAudit from "scenes/audit/setup/SetupQuickAudit";
import ExecuteAudit from 'scenes/audit/execute/ExecuteAudit';
import AuditResult from 'scenes/audit/result/AuditResult';
import {audit as auditColor} from 'components/colors/Colors';
import SynchronizationEventBus from "scenes/SynchronizationEventBus";

export class Audit extends Component {

    componentDidMount() {
        this.initializeData();
        this.synchronizationSubscription = SynchronizationEventBus.update.subscribe(() => this.initializeData());
    }

    componentWillUnmount() {
        SynchronizationEventBus.update.unSubscribe(this.synchronizationSubscription);
    }

    initializeData = () => {
        this.props.fetchAudits();
    };

    render() {
        const {
            audits, result, adHocAuditForm, auditSetup, fetchInitDataForAdHocAudit, submitAdHocAuditForm,
            finishAudit, executeAudit, reviewAudit, finishReview, setEmptyInitDataForAdHocAudit,
            selectedOrganizationLevel, pictureUrlsToDownload, fetchAudits, applicationOnline
        } = this.props;
        return (
            <Switch>
                <Route path={SCHEDULED_AUDITS.path}
                       render={function renderScheduledAudits(props) {
                           return <ScheduledAudits data={{audits, pictureUrlsToDownload, applicationOnline}}
                                                   actions={{
                                                       executeAudit,
                                                       reviewAudit
                                                   }}
                                                   {...props}/>
                       }}/>
                <Route path={SETUP_AUDIT.path}
                       render={function renderSetupAudit() {
                           return <SetupQuickAudit
                               {...{
                                   questionnaires: adHocAuditForm.questionnaires,
                                   levelTitle: adHocAuditForm.levelTitle,
                                   levelId: adHocAuditForm.levelId,
                                   selectedOrganizationLevel,
                                   fetchInitDataForAdHocAudit,
                                   setEmptyInitDataForAdHocAudit,
                                   submitAdHocAuditForm
                               }}
                           />
                       }}/>
                {
                    auditSetup.questions &&
                    <Route path={EXECUTE_AUDIT.path}
                           render={function renderExecuteAudit(props) {
                               return <ExecuteAudit
                                   {...{
                                       ...props,
                                       questions: auditSetup.questions,
                                       audit: auditSetup.audit,
                                       color: auditColor,
                                       finishAudit
                                   }}
                               />
                           }}/>
                }
                {
                    auditSetup.questions &&
                    <Route path={REVIEW_AUDIT.path}
                           render={function renderReviewAudit(props) {
                               return <ExecuteAudit
                                   {...{
                                       ...props,
                                       questions: auditSetup.questions,
                                       audit: auditSetup.audit,
                                       result: auditSetup.result,
                                       readOnly: true,
                                       finishAudit: finishReview,
                                   }}
                               />
                           }}/>
                }
                {
                    result.isLoading !== undefined &&
                    <Route path={AUDIT_RESULT.path}
                           render={function renderAuditResult(props) {
                               return <AuditResult
                                   data={{
                                       result: result.result,
                                       questionnaireName: result.questionnaireName,
                                       isLoading: result.isLoading,
                                       readOnly: result.readOnly,
                                   }}
                                   actions={{
                                       fetchAudits,
                                   }}
                                   {...props}
                               />
                           }}/>
                }
                <Route render={function redirectToScheduledAudits(props) {
                           return <Redirect to={SCHEDULED_AUDITS.path}/>
                       }}/>
            </Switch>
        );
    }
}

export default connect(connector.mapStateToProps, connector.mapDispatchToProps)(Audit);
