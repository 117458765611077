import React, {Component} from 'react';
import {withStyles, MenuItem} from "@material-ui/core";
import {htmlWhite, jungleGreen, jungleGreen50, htmlOrange, red50, red} from 'components/colors/Colors';
import BootstrapSelect from 'components/bootstrap-select/BootstrapSelect';
import _ from 'lodash';

const styles = theme => ({
    icon: {
        fill: htmlWhite,
        color: htmlWhite
    }
});

export function getRatingColor(point) {
    switch (point) {
        case 10:
            return jungleGreen;
        case 8:
            return jungleGreen50;
        case 6:
            return htmlOrange;
        case 4:
            return red50;
        case 0:
            return red;
        default:
            return undefined;
    }
}

export class RatingSelector010 extends Component {

    render() {
        const {classes, options, ...other} = this.props;

        return (
            <BootstrapSelect {...{
                ...other,
                SelectDisplayProps: {
                    ..._.get(other, 'SelectDisplayProps', {}),
                    style: {
                        ..._.get(other, 'SelectDisplayProps.style', {}),
                        backgroundColor: getRatingColor(_.get(other, 'value.point')),
                        textAlign: 'center',
                        ...(getRatingColor(_.get(other, 'value.point')) && {color: htmlWhite})
                    }
                },
                classes: {
                    ..._.get(other, 'classes', {}),
                    ...(getRatingColor(_.get(other, 'value.point')) && {icon: classes.icon})
                }
            }}>
                {
                    !_.isEmpty(options) && options.map(possibleAnswer => (
                        <MenuItem style={{
                            ...(getRatingColor(possibleAnswer.point) && {color: htmlWhite}),
                            backgroundColor: getRatingColor(possibleAnswer.point
                            )}}
                                  key={possibleAnswer.point}
                                  value={possibleAnswer}>{possibleAnswer.label}</MenuItem>
                    ))
                }
                {
                    _.isEmpty(options) && [10, 8, 6, 4, 0].map(value => (
                        <MenuItem style={{color: htmlWhite, backgroundColor: getRatingColor(value)}}
                                  key={value}
                                  value={value}>{value}</MenuItem>
                    ))
                }
            </BootstrapSelect>
        );
    }
}

export default withStyles(styles)(RatingSelector010);
