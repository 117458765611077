import {
    createQuestionCatalogue,
    removeQuestionCatalogue,
    resetToBeConfirmedAssignedQuestionnaires
} from "./QuestionCategories.action"

export default {
    mapStateToProps: (state) => {
        return {
            categories: state.generalConfigurationReducer.auditConfiguration.cataloguesForAdministration,
            assignedQuestionnaires: state.generalConfigurationReducer.assignedQuestionnaires,
        };
    },
    mapDispatchToProps: (dispatch) => {
        return {
            createQuestionCatalogue: name => dispatch(createQuestionCatalogue(name)),
            removeQuestionCatalogue: (businessId, confirmed) => dispatch(removeQuestionCatalogue(businessId, confirmed)),
            resetToBeConfirmedAssignedQuestionnaires: () => dispatch(resetToBeConfirmedAssignedQuestionnaires()),
        };
    }
};
