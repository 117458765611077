import * as actionTypes from "./ScheduleEdit.action.types";
import _ from "lodash";
import {emptySchedule, mapSchedule, mapCloneSchedule} from "domain/audit/Schedule.model";
import moment from "moment";

const initialState = {
    schedule: {},
    questionnaires: [],
    calendarPreviewDates: []
};

export default function (state = initialState, action) {
    switch (action.type) {
        case actionTypes.FETCH_EMPTY_SCHEDULE:
        case actionTypes.CREATE_SCHEDULE_SUCCESS:
        case actionTypes.EDIT_SCHEDULE_SUCCESS:
        case actionTypes.CREATE_SCHEDULE_FAIL:
        case actionTypes.EDIT_SCHEDULE_FAIL: {
            const newState = _.cloneDeep(state);
            const user = _.get(action, 'data.user') || _.get(action, 'meta.previousAction.data.user');
            const selectedOrganizationLevel = _.get(action, 'data.selectedOrganizationLevel') || _.get(action, 'meta.previousAction.data.selectedOrganizationLevel');
            newState.schedule = emptySchedule(user, selectedOrganizationLevel);
            return newState;
        }
        case actionTypes.FETCH_SCHEDULE_SUCCESS: {
            const newState = _.cloneDeep(state);
            newState.schedule = mapSchedule(action.payload.data.data.fetchAuditSchedule);
            return newState;
        }
        case actionTypes.FETCH_QUESTIONNAIRES_SUCCESS: {
            const newState = _.cloneDeep(state);
            newState.questionnaires = _.sortBy(mapQuestionnaires(action.payload.data.data.listQuestionnairesForOrganizationLevel),
                questionnaire => {
                    return parseInt(questionnaire.businessId);
                }
            ).filter(questionnaire => questionnaire.hasQuestions);
            return newState;
        }
        case actionTypes.LOAD_CALENDAR_PREVIEW_SUCCESS: {
            const newState = _.cloneDeep(state);
            newState.calendarPreviewDates = action.payload.data.data.fetchNextScheduleDates.map(dates => dates.days).flat().map(day => moment(day).toDate());
            return newState;
        }
        case actionTypes.CLONE_SCHEDULE: {
            const newState = _.cloneDeep(state);
            newState.schedule = mapCloneSchedule(action.data.schedule);
            return newState;
        }
        default:
            return state;
    }
}

function mapQuestionnaires(questionnaires) {
    return questionnaires.map(questionnaire => {
        return {
            businessId: questionnaire.businessId,
            name: questionnaire.name,
            hasQuestions: questionnaire.numberOfQuestions + questionnaire.numberOfRandomizedQuestions > 0,
            auditType: _.get(questionnaire, "auditType.label")
        }
    });
}


