import React, {Component, Fragment, PureComponent} from 'react';
import {Checkbox, IconButton, InputBase, withStyles} from "@material-ui/core";
import AddIcon from '@material-ui/icons/Add';
import BootstrapInput from 'components/bootstrap-input/BootstrapInput';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import Flex from 'components/grid/Flex';
import DeleteIcon from '@material-ui/icons/Delete';
import {htmlGreen} from 'components/colors/Colors.js'
import Divider from "@material-ui/core/Divider";

const styles = theme => ({
    checkBoxIcon: {
        fill: htmlGreen
    },
    divider: {
        margin: '5px 40px 5px 40px'
    }
});

export class ChecklistItem extends PureComponent {
    render() {
        const {classes, businessId, status, text, handleTextChange, deleteChecklistItem, toggleChecklistItem} = this.props;

        return (
            <Flex item container key={businessId}>
                <Checkbox
                    checked={status === 'DONE'}
                    onChange={toggleChecklistItem}
                    checkedIcon={<CheckBoxIcon className={classes.checkBoxIcon}/>}
                />
                <InputBase className={classes.margin}
                           fullWidth
                           multiline
                           style={{minWidth: 200}}
                           value={text}
                           onChange={handleTextChange}
                />
                <IconButton onClick={deleteChecklistItem}>
                    <DeleteIcon/>
                </IconButton></Flex>
        );
    }
}

export class ChecklistWindow extends Component {

    componentDidMount() {
        if (this.props.onOpen) {
            this.props.onOpen();
        }
    }

    render() {
        const {
            classes, i18n, checklist, newChecklistItem, handleTextChange, deleteChecklistItem, toggleChecklistItem,
            handleChange, addChecklistItem
        } = this.props;

        return (
            <Flex item container direction={'column'}>
                {
                    checklist.map(checklistItem => {
                        const matchingTranslation = checklistItem.translations
                            .find(translation => translation.language.code === i18n.language);
                        return (
                            <Fragment>
                                <ChecklistItem
                                    {...{
                                        classes,
                                        key: checklistItem.businessId,
                                        text: matchingTranslation.text,
                                        businessId: checklistItem.businessId,
                                        status: checklistItem.status,
                                        handleTextChange: handleTextChange(checklistItem),
                                        deleteChecklistItem: deleteChecklistItem(checklistItem),
                                        toggleChecklistItem: toggleChecklistItem(checklistItem),
                                    }}
                                />
                                <Divider className={classes.divider}/>
                            </Fragment>
                        )
                    })
                }
                <Flex item container style={{marginTop: 10}}>
                    <BootstrapInput
                        value={newChecklistItem}
                        onChange={handleChange}
                        onEnterKey={addChecklistItem}
                        name="newChecklistItem"
                        autoFocus
                    />
                    <IconButton onClick={addChecklistItem}>
                        <AddIcon/>
                    </IconButton>
                </Flex>
            </Flex>
        );
    }
}

export default withStyles(styles)(ChecklistWindow);
