import {normal, critical, medium, criticalTextField, mediumTextField, lowTextField, textField, htmlBlack, htmlGrey} from 'components/colors/Colors';

export function getTitleColorByPriority(priority){
    switch(priority.toLowerCase()) {
        case 'urgent':
            return normal;
        case 'critical':
        case 'high':
            return critical;
        case 'medium':
        case 'minor':
            return medium;
        default:
            return htmlBlack;
    }
}


export function getColorbyPriority(priority) {
    switch(priority.toLowerCase()) {
        case 'urgent':
            return normal;
        case 'critical':
        case 'high':
            return critical;
        case 'medium':
        case 'minor':
            return medium;
        case 'low':
        case 'normal':
            return lowTextField;
        default:
            return htmlGrey;
    }
}

export function getTextfieldColorByPriority(priority) {
    switch(priority.toLowerCase()) {
        case 'critical':
        case 'high':
            return criticalTextField;
        case 'medium':
        case 'minor':
            return mediumTextField;
        case 'urgent':
        case 'normal':
            return textField;
        default:
            return lowTextField;
    }
}

export function getTextColorByPriority(priority) {
    switch(priority.toLowerCase()) {
        case 'critical':
        case 'high':
            return critical;
        case 'medium':
        case 'minor':
            return medium;
        default:
            return '';
    }
}
