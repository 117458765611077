import * as actionTypes from './Questions.action.types'

export function fetchQuestions() {
    return {
        type: actionTypes.FETCH_QUESTIONS,
        payload: {
            client: 'graphql',
            request: {
                method: 'post',
                data: {
                    query: `
                            {
                                listQuestions {
                                    businessId
                                    status
                                    translations {
                                        language {
                                            code
                                            label
                                            ordinal
                                        }
                                        question
                                        comment
                                        quickFix
                                        status
                                    }
                                    catalogue {
                                        label
                                    }
                                    tags {
                                        businessId
                                        label
                                        category
                                    }
                                    answerType {
                                        businessId
                                        label
                                        values {
                                            point
                                            label
                                        }
                                    }
                                    assignedQuestionnaires {
                                        name
                                    }
                                }
                             }
                           `
                }
            }
        }
    };
}



export function deleteQuestion(questionId) {
    return {
        type: actionTypes.DELETE_QUESTION,
        payload: {
            client: 'graphql',
            request: {
                method: 'post',
                data: {
                    query: `mutation removeQuestion($questionId: String){
    	                        removeQuestion(questionId: $questionId)
                            }
                           `,
                    variables: {
                        questionId
                    }
                }
            }
        }
    }
}
