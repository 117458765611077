import React, {PureComponent} from "react";
import {Snackbar} from "@material-ui/core";

export class SnackBarSimple extends PureComponent {
    state = {
        open: true
    };

    close = () =>{
        this.setState({open: false});
    };

    render() {
        const {message} = this.props;
        const {open} = this.state;
        const {close} = this;
        return (
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                open={open}
                autoHideDuration={6000}
                onClose={close}
                ContentProps={{
                    'aria-describedby': 'message-id',
                }}
                message={<span id="message-id">{message}</span>}
            />
        );
    }
}

export default SnackBarSimple;
