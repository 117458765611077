import _ from 'lodash';

const defaultValue = {active: false};
const websocket = _.get(window, 'appConfig.featureSwitch.websocket', defaultValue);
const serverConnectionChecker = _.get(window, 'appConfig.featureSwitch.serverConnectionChecker', defaultValue);
const versionNotification = _.get(window, 'appConfig.featureSwitch.versionNotification', defaultValue);
const versionInProfile = _.get(window, 'appConfig.featureSwitch.versionInProfile', defaultValue);
const extendedHttpHeaders = _.get(window, 'appConfig.featureSwitch.extendedHttpHeaders', defaultValue);
const idleLogout = _.get(window, 'appConfig.featureSwitch.idleLogout', defaultValue);
const manualSync = _.get(window, 'appConfig.featureSwitch.manualSync', defaultValue);
const bugTemplate = _.get(window, 'appConfig.featureSwitch.bugTemplate', defaultValue);
const automaticOfflineSwitch= _.get(window, 'appConfig.featureSwitch.automaticOfflineSwitch', defaultValue);
const devSwitch = _.get(window, 'appConfig.featureSwitch.devSwitch', defaultValue);

export default {
    websocket,
    serverConnectionChecker,
    versionNotification,
    extendedHttpHeaders,
    idleLogout,
    manualSync,
    versionInProfile,
    bugTemplate,
    automaticOfflineSwitch,
    devSwitch
};
