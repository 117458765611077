import * as actionTypes from './Project.action.types';
import taskCache from "./Tasks.cache";
import {PROJECT_GRAPHQL} from 'domain/task/Project.model';
import {createProjectInput, updateProjectDetailsInput} from 'domain/task/Project.model';

export function listProjects() {
    return taskCache.cacheProjects(listProjectsFromServer, listProjectsFromCache)
}

export function listProjectsFromCache(projects) {
    return (dispatch, getState) => {
        return dispatch({
            type: actionTypes.LIST_PROJECTS_FROM_CACHE,
            data: {
                cache: {
                    projects
                },
                user: getState().mainReducer.user
            }
        })
    }
}

export function listProjectsFromServer() {
    return (dispatch, getState) => {
        return dispatch({
            type: actionTypes.LIST_PROJECTS,
            data: {user: getState().mainReducer.user},
            payload: {
                client: 'graphql',
                request: {
                    method: 'post',
                    data: {
                        query: `
                                query {
                                    listProjects {
                                        ${PROJECT_GRAPHQL}
                                    }
                                }
                           `,
                        variables: {
                            userId: getState().mainReducer.user.businessId
                        }
                    }
                }
            }
        })
    }
}

export function updateProject(options) {
    return (dispatch, getState) => {
        const updateProjectInput = {
            businessId: getState().projectReducer.selectedProject.businessId,
            ...options
        };

        return dispatch(synchronizeProject(updateProjectInput));
    }
}

export function synchronizeProject(updateProjectInput) {
    return (dispatch) => dispatch({
        type: actionTypes.UPDATE_PROJECT,
        payload: {
            client: 'graphql',
            request: {
                method: 'post',
                data: {
                    query: `mutation updateProject($updateProjectInput: UpdateProjectInput){
    	                        updateProject(updateProjectInput: $updateProjectInput){
      	                            businessId
    	                        }
                            }
                           `,
                    variables: {
                        updateProjectInput: updateProjectInput
                    }
                }
            }
        }
    }).catch(() => {
        return dispatch(taskCache.cacheEditedProject(updateProjectInput));
    });
}

export function toggleProjectFilterSelection(businessId) {
    return (dispatch, getState) => {
        dispatch(toggleProjectSelection(businessId));
        return dispatch({
            type: actionTypes.NEW_PROJECT_SELECTED,
            data: {selectedProject: getState().projectReducer.selectedProject}
        });
    }
}

export function toggleProjectFilterSelectionByOrganizationLevelId(organizationLevelId) {
    return (dispatch, getState) => {
        dispatch(toggleProjectSelectionByOrganizationLevelId(organizationLevelId));
        return dispatch({
            type: actionTypes.NEW_PROJECT_SELECTED,
            data: {selectedProject: getState().projectReducer.selectedProject}
        });
    }
}

function toggleProjectSelection(businessId){
    return {
        type: actionTypes.TOGGLE_PROJECT_FILTER_SELECTION,
        data: {businessId}
    }
}

function toggleProjectSelectionByOrganizationLevelId(organizationLevelId){
    return {
        type: actionTypes.TOGGLE_PROJECT_FILTER_SELECTION_BY_ORG_LEVEL_ID,
        data: {organizationLevelId}
    }
}

export function toggleProjectFilterOpen(businessId) {
    return {
        type: actionTypes.TOGGLE_PROJECT_FILTER_OPEN,
        data: {businessId}
    }
}

export function loadPreviousProjectSelection() {
    return {
        type: actionTypes.LOAD_PREVIOUS_PROJECT_SELECTION
    }
}

export function createProject(parentBusinessId, name) {
    return {
        type: actionTypes.CREATE_PROJECT,
        payload: {
            client: 'graphql',
            request: {
                method: 'post',
                data: {
                    query: `mutation createProject($createProjectInput: CreateProjectInput){
    	                        createProject(createProjectInput: $createProjectInput){
      	                            businessId
    	                        }
                            }
                           `,
                    variables: {
                        createProjectInput: createProjectInput(name, parentBusinessId)
                    }
                }
            }
        }
    }
}

export function editProject(businessId, name) {
    return {
        type: actionTypes.EDIT_PROJECT,
        payload: {
            client: 'graphql',
            request: {
                method: 'post',
                data: {
                    query: `mutation updateProjectDetails($updateProjectDetailsInput: UpdateProjectDetailsInput){
    	                        updateProjectDetails(updateProjectDetailsInput: $updateProjectDetailsInput){
      	                            businessId
    	                        }
                            }
                           `,
                    variables: {
                        updateProjectDetailsInput: updateProjectDetailsInput(name, businessId)
                    }
                }
            }
        }
    }
}

export function deleteProject(businessId) {
    return {
        type: actionTypes.DELETE_PROJECT,
        payload: {
            client: 'graphql',
            request: {
                method: 'post',
                data: {
                    query: `mutation deleteProject($projectBusinessId: String!){
    	                        deleteProject(projectBusinessId: $projectBusinessId)
                            }
                           `,
                    variables: {
                        projectBusinessId: businessId
                    }
                }
            }
        }
    }
}

export function assignProjectMember(businessId, userId, role) {
    return {
        type: actionTypes.ASSIGN_PROJECT_MEMBER,
        payload: {
            client: 'graphql',
            request: {
                method: 'post',
                data: {
                    query: `mutation addProjectMember($projectBusinessId: String!, $userId: String!, $membership: ProjectMembership!){
    	                        addProjectMember(projectBusinessId: $projectBusinessId, userId: $userId, membership: $membership)
                            }
                           `,
                    variables: {
                        projectBusinessId: businessId,
                        userId: userId,
                        membership: role
                    }
                }
            }
        }
    }
}

export function updateProjectMembership(businessId, userId, newRole) {
    return {
        type: actionTypes.UPDATE_PROJECT_MEMBERSHIP,
        payload: {
            client: 'graphql',
            request: {
                method: 'post',
                data: {
                    query: `mutation updateProjectMember($projectBusinessId: String!, $userId: String!, $newMembership: ProjectMembership!){
    	                        updateProjectMember(projectBusinessId: $projectBusinessId, userId: $userId, newMembership: $newMembership)
                            }
                           `,
                    variables: {
                        projectBusinessId: businessId,
                        userId: userId,
                        newMembership: newRole
                    }
                }
            }
        }
    }
}

export function removeProjectMember(projectBusinessId, userId, substituteUserId) {
    return {
        type: actionTypes.REMOVE_PROJECT_MEMBER,
        payload: {
            client: 'graphql',
            request: {
                method: 'post',
                data: {
                    query: `mutation removeProjectMember($projectBusinessId: String!, $userId: String!, $substituteUserId: String){
    	                        removeProjectMember(projectBusinessId: $projectBusinessId, userId: $userId, substituteUserId: $substituteUserId)
                            }
                           `,
                    variables: {
                        projectBusinessId,
                        userId,
                        substituteUserId
                    }
                }
            }
        }
    }
}

export function openProjectTree() {
    return {
        type: actionTypes.OPEN_PROJECT_TREE,
    }
}

export function closeProjectTree() {
    return {
        type: actionTypes.CLOSE_PROJECT_TREE,
    }
}

export function toggleSelectedUserNames(username) {
    return {
        type: actionTypes.TOGGLE_SELECTED_USERS,
        data: {username}
    }
}
