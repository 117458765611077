import {
    string,
    shape,
} from "prop-types"
import {LANGUAGE, LANGUAGE_GRAPHQL} from 'domain/Language.model';
import {convertFromRaw} from 'draft-js';
import _ from 'lodash';
import {NEW, UNKNOWN, MANUAL} from 'components/quill-editor/EditorUtils';
import {convertToHTML} from 'draft-convert';

export const TRANSLATION_BACKEND = {
    language: shape(LANGUAGE).isRequired,
    origin: string.isRequired,
    title: string.isRequired,
    description: string.isRequired
};

export const CHECKLIST_TRANSLATION_BACKEND = {
    language: shape(LANGUAGE).isRequired,
    origin: string.isRequired,
    text: string.isRequired
};

export const TRANSLATION_FOR_EDIT = {
    language: shape(LANGUAGE).isRequired,
    origin: string.isRequired,
    title: string.isRequired,
    description: string
};

export const TRANSLATION_GRAPHQL = `
    language {${LANGUAGE_GRAPHQL}}
    origin
    title
    description
`;

export const CHECKLIST_TRANSLATION_GRAPHQL = `
    language {${LANGUAGE_GRAPHQL}}
    origin
    text
`;

export function apiTranslation(translation) {
    return {
        language: {
            code: translation.language.code,
            label: translation.language.label,
            ordinal: translation.language.ordinal,
        },
        origin: translation.origin,
        title: translation.title,
        description: apiDescription(translation.description),
    }
}

export function offlineTranslations(translations, forceTranslate) {
    return translations.map(translation => {
        return {
            language: {
                code: translation.language.code,
                label: translation.language.label,
                ordinal: translation.language.ordinal,
            },
            origin: translation.origin,
            title: translation.title,
            description: apiDescription(translation.description),
            forceTranslate: forceTranslate && translation.origin === NEW
        }
    })
}

function translationInput(translation, forceTranslate) {
    return {
        language: {
            code: translation.language.code,
            label: translation.language.label,
            ordinal: translation.language.ordinal,
        },
        title: translation.title,
        description: apiDescription(translation.description),
        forceTranslate: translation.forceTranslate || forceTranslate
    }
}

export function editableTranslation(translation) {
    return {
        language: {
            code: translation.language.code,
            label: translation.language.label,
            ordinal: translation.language.ordinal,
        },
        origin: translation.origin,
        title: translation.title,
        description: editableDescription(translation.description),
        forceTranslate: translation.forceTranslate
    }
}

export function editableDescription(description) {
    try {
        const rawDraftContent = JSON.parse(description);
        return convertToHTML(convertFromRaw(rawDraftContent));
    } catch (e) {
        return description;
    }
}

export function apiDescription(description) {
    if(_.isString(description) || !description){
        return description;
    } else {
        return convertToHTML(description.getCurrentContent());
    }
}

export function emptyEditableTranslations(languages) {
    return languages.map(language => {
        return {
            language: {
                code: language.code,
                label: language.label,
                ordinal: language.ordinal,
            },
            origin: UNKNOWN,
            title: '',
            description: '</>',
        }
    })
}

export function manualTranslationInput(translations, forceTranslate) {
    const newTranslation = translations.find(translation => translation.origin === NEW);
    const manualTranslation = translations.find(translation => translation.origin === MANUAL);
    return newTranslation || manualTranslation
        ? translationInput(newTranslation || manualTranslation, forceTranslate)
        : undefined;
}
