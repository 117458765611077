import React, {Component, Fragment} from 'react';
import {heatheredGray} from 'components/colors/Colors';
import {
    withStyles,
    Avatar,
} from "@material-ui/core";
import _ from 'lodash';
import {getNameInitials} from "domain/User.model";

const styles = theme => ({
    avatarRoot: {
        width: 30,
        height: 30,
    },
});

export class H4Avatar extends Component {

    render() {
        const {classes, user, style, ...other} = this.props;
        const downloadUrl = _.get(user, 'avatar.downloadUrl');
        const nameInitials = getNameInitials(user);

        return (
            <Fragment>
                {
                    downloadUrl
                        ?
                        <Avatar classes={{root: classes.avatarRoot}} src={downloadUrl} style={style} {...other}/>
                        :
                        <Avatar
                            classes={{root: classes.avatarRoot}}
                            style={{backgroundColor: heatheredGray, fontSize: 15, ...style}}
                            {...other}
                        >
                            {nameInitials}
                        </Avatar>
                }
            </Fragment>
        )
    }
}

export default withStyles(styles)(H4Avatar);
