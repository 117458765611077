import React, {Component, Fragment} from 'react';
import update from 'immutability-helper';
import _ from 'lodash';
import {connect} from 'react-redux';
import connector from './Attachment.connect';
import utils from 'utils/Utils';
import SingleImageAttachment from './components/SingleImageAttachment';
import AttachmentDialog from './components/AttachmentDialog';
import AttachmentWindow from './components/AttachmentWindow';
import fileUtils from "utils/File.utils";

export let instances = {};

export function findFileIndexByDownloadUrl(array, item) {
    return array.findIndex(itemInArray => itemInArray.downloadUrl === item.downloadUrl);
}

export class Attachment extends Component {

    constructor(props) {
        super(props);
        const inProgressFiles = _.differenceBy(instances[this.props.id] || [], props.files || [], 'filename');
        this.state = {
            isAttachmentOpen: this.props.forceOpen || false,
            filterText: '',
            inProgressFiles,
            files: _.cloneDeep(props.files || []),
            isInvalidFile: false
        };
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {files} = this.props;
        const previousFiles = prevProps.files;
        if (_.get(files, 'length', 0) !== _.get(previousFiles, 'length', 0)) {
            this.setState({
                files: _.cloneDeep(this.props.files || []),
                inProgressFiles: _.differenceBy(instances[this.props.id] || [], this.props.files || [], 'filename')
            });
        }
    }

    componentWillUnmount() {
        _.set(instances, [this.props.id], this.state.inProgressFiles);
    }

    handleChange = event => {
        this.setState({
            [event.target.name]: event.target.value,
        });
    };

    preventEventPropagation = event => {
        event.preventDefault();
        event.stopPropagation();
    };

    openAttachment = event => {
        this.preventEventPropagation(event);
        this.setState({
            isAttachmentOpen: true
        });
        _.get(this.props, 'onOpen') && this.props.onOpen();
    };

    closeAttachment = event => {
        this.setState({
            isAttachmentOpen: false
        });
        _.get(this.props, 'onClose') && this.props.onClose();
    };

    deleteAttachment = file => event => {
        this.props.deleteFileOnUrl(file.downloadUrl)
            .then(response => {
                _.get(this.props, 'onSuccessfulFileDelete')
                && this.props.onSuccessfulFileDelete(file);
                this.setState(oldState => {
                    const index = findFileIndexByDownloadUrl(oldState.files, file);
                    if (index !== -1) {
                        return {
                            files: update(oldState.files, {
                                $splice: [[index, 1]]
                            })
                        };
                    }
                });
            })
    };

    onDrop = (acceptedFiles, rejectedFiles) => {
        const validFiles = this.validateFiles(acceptedFiles);
        this.setState({
            isInvalidFile: acceptedFiles.length !== validFiles.length
        });
        validFiles.forEach(file => {
            const filePlaceholder = this.createFilePlaceholder(file);
            const id = this.props.id || utils.uuid();
            this.setState(
                this.addFilePlaceholder(filePlaceholder),
                () => this.props.uploadFile(file, id, this.onFileUploadProgress(filePlaceholder), this.props.referenceType)
                    .then(response => {
                        this.enrichSuccessfullyUploadedFile(response, filePlaceholder);
                        _.get(this.props, 'onSuccessfulFileUpload')
                        && this.props.onSuccessfulFileUpload(response.payload.data);
                    })
                    .catch(error => console.log('Error: ', error))
                    .then(() => {
                        this.removeFailedFile(filePlaceholder);
                    })
            );
        });
    };

    validateFiles = files => {
        return files.filter(file => {
            return fileUtils.isValidFileSize(file)
        })
    };

    addFilePlaceholder = filePlaceholder => oldState => {
        return {
            inProgressFiles: update(oldState.inProgressFiles, {
                $push: [filePlaceholder]
            })
        };
    };

    createFilePlaceholder = file => {
        return {
            referenceId: utils.uuid(),
            filename: file.name,
            progress: 0,
            downloadUrl: utils.uuid(),
        }
    };

    onFileUploadProgress = filePlaceholder => progressEvent => {
        this.setState(oldState => {
            const index = findFileIndexByDownloadUrl(oldState.inProgressFiles, filePlaceholder);
            return {
                inProgressFiles: update(oldState.inProgressFiles, {
                    [index]: {
                        progress: {$set: (progressEvent.loaded / progressEvent.total) * 100}
                    }
                })
            };
        });
    };

    enrichSuccessfullyUploadedFile = (response, filePlaceholder) => {
        this.setState(oldState => {
            const index = findFileIndexByDownloadUrl(oldState.inProgressFiles, filePlaceholder);
            return {
                files: update(oldState.files, {
                    $push: [{
                        ...response.payload.data,
                        progress: 100
                    }]
                }),
                inProgressFiles: update(oldState.inProgressFiles, {
                    $splice: [[index, 1]]
                })
            };
        });
    };

    removeFailedFile = filePlaceholder => {
        this.setState(oldState => {
            const index = findFileIndexByDownloadUrl(oldState.inProgressFiles, filePlaceholder);
            if (index !== -1) {
                if (oldState.inProgressFiles[index].downloadUrl) {
                    return oldState;
                } else {
                    return {
                        inProgressFiles: update(oldState.inProgressFiles, {
                            $splice: [[index, 1]]
                        })
                    };
                }
            }
        });
    };

    render() {
        const {
            isMobile, attachmentType, deletePicture, pictureUrl, classes, isDeletePossible, uploadEnabled, isUnseen,
            iconColor, onOpen
        } = this.props;
        const {isAttachmentOpen, inProgressFiles, filterText, isInvalidFile, files} = this.state;
        const {openAttachment, closeAttachment, preventEventPropagation, handleChange, deleteAttachment, onDrop} = this;
        const allFiles = _.concat(files || [], inProgressFiles);
        return (
            <Fragment>
                {
                    !attachmentType &&
                    <AttachmentDialog
                        {...{
                            classes,
                            isMobile,
                            isAttachmentOpen,
                            allFiles,
                            filterText,
                            isInvalidFile,
                            openAttachment,
                            closeAttachment,
                            preventEventPropagation,
                            handleChange,
                            deleteAttachment,
                            onDrop,
                            uploadEnabled,
                            isUnseen,
                            iconColor
                        }}/>
                }
                {
                    attachmentType && attachmentType === 'SINGLE_IMAGE' &&
                    <SingleImageAttachment
                        {...{
                            classes,
                            pictureUrl,
                            deletePicture,
                            onDrop,
                            isDeletePossible
                        }}
                    />
                }
                {
                    attachmentType && attachmentType === 'INLINE_WINDOW' &&
                    <AttachmentWindow
                        {...{
                            allFiles,
                            filterText,
                            isInvalidFile,
                            handleChange,
                            deleteAttachment,
                            onDrop,
                            uploadEnabled,
                            onOpen
                        }}
                    />
                }
            </Fragment>
        );
    }

}

export default connect(connector.mapStateToProps, connector.mapDispatchToProps)(Attachment);
