import * as actionTypes from './Comment.action.types';
import {COMMENT_GRAPHQL} from 'domain/Comment.model'

export function addActionComment(actionId, comment) {
    return {
        type: actionTypes.ADD_ACTION_COMMENT,
        payload: {
            client: 'graphql',
            request: {
                method: 'post',
                data: {
                    query: `mutation addActionComment($actionId: String, $comment: String){
    	                        addActionComment(actionId: $actionId, comment: $comment){
      	                            ${COMMENT_GRAPHQL}
    	                        }
                            }
                           `,
                    variables: {
                        comment,
                        actionId
                    }
                }
            }
        }
    }
}

export function deleteActionComment(commentId) {
    return {
        type: actionTypes.DELETE_ACTION_COMMENT,
        payload: {
            client: 'graphql',
            request: {
                method: 'post',
                data: {
                    query: `mutation deleteActionComment($commentId: String){
    	                        deleteActionComment(commentId: $commentId)
                            }
                           `,
                    variables: {
                        commentId
                    }
                }
            }
        }
    }
}

export function addTaskComment(taskId, comment) {
    return {
        type: actionTypes.ADD_TASK_COMMENT,
        payload: {
            client: 'graphql',
            request: {
                method: 'post',
                data: {
                    query: `mutation addTaskComment($taskId: String, $comment: String){
    	                        addTaskComment(taskId: $taskId, comment: $comment){
      	                            ${COMMENT_GRAPHQL}
    	                        }
                            }
                           `,
                    variables: {
                        comment,
                        taskId
                    }
                }
            }
        }
    }
}

export function deleteTaskComment(commentId) {
    return {
        type: actionTypes.DELETE_TASK_COMMENT,
        payload: {
            client: 'graphql',
            request: {
                method: 'post',
                data: {
                    query: `mutation deleteTaskComment($commentId: String){
    	                        deleteTaskComment(commentId: $commentId)
                            }
                           `,
                    variables: {
                        commentId
                    }
                }
            }
        }
    }
}
