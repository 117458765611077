import {arrivedFrom, createTask, editTask, fetchTask, getTemporaryTaskReferenceId, cloneTask} from './TaskEdit.action'
import {archiveTask} from 'scenes/tasks/Tasks.action'
import {toggleProjectFilterSelection} from 'scenes/tasks/Project.action';
import {
    removeUnseenActionTag,
    removeUnseenAttachmentTag,
    removeUnseenChecklistTag,
    removeUnseenCommentTag,
    removeUnseenContentTag
} from "scenes/tasks/Tags.action";

export default {
    mapStateToProps: (state) => {
        return {
            task: state.taskEditReducer.task,
            comments: state.taskEditReducer.task.comments,
            attachments: state.taskEditReducer.task.attachments,
            checklist: state.taskEditReducer.task.checklist,
            actions: state.taskEditReducer.task.actions,
            freeTags: state.tasksReducer.configuration.freeTags,
            deviationSeverities: state.tasksReducer.configuration.deviationSeverities,
            taskSeverities: state.tasksReducer.configuration.taskSeverities,
            activeWorkflowStatuses: state.tasksReducer.configuration.activeWorkflowStatuses,
            statuses: state.tasksReducer.configurationFromBackend.statuses,
            selectableStatuses: state.tasksReducer.configuration.selectableStatuses,
            deadlines: state.tasksReducer.configurationFromBackend.deadlines,
            newTaskRefId: state.taskEditReducer.newTaskRefId,
            users: state.tasksReducer.configuration.users,
            user: state.mainReducer.user,
            applicationOnline: state.mainReducer.online,
            selectedProject: state.projectReducer.selectedProject,
            filters: state.taskListReducer.filters,
            leavePath: state.taskEditReducer.leavePath,
            todoTaskBusinessId: state.tasksReducer.todoTaskBusinessId,
        };
    },
    mapDispatchToProps: (dispatch) => {
        return {
            fetchTask: (taskId) => dispatch(fetchTask(taskId)),
            createTask: task => dispatch(createTask(task)),
            cloneTask: taskId => dispatch(cloneTask(taskId)),
            editTask: (task, forceTranslate) => dispatch(editTask(task, forceTranslate)),
            archiveTask: taskBusinessId => dispatch(archiveTask(taskBusinessId)),
            getTemporaryTaskReferenceId: () => dispatch(getTemporaryTaskReferenceId()),
            removeUnseenContentTag: task => dispatch(removeUnseenContentTag(task)),
            toggleProjectFilterSelection: businessId => dispatch(toggleProjectFilterSelection(businessId)),
            arrivedFrom: path => dispatch(arrivedFrom(path)),
            removeUnseenActionTag: task => dispatch(removeUnseenActionTag(task)),
            removeUnseenCommentTag: task => dispatch(removeUnseenCommentTag(task)),
            removeUnseenChecklistTag: task => dispatch(removeUnseenChecklistTag(task)),
            removeUnseenAttachmentTag: task => dispatch(removeUnseenAttachmentTag(task)),
        };
    }
};
