import moment from "moment";
import _ from "lodash";
import {mapUser, USER, USER_GRAPHQL} from "domain/User.model";
import {any, arrayOf, bool, number, object, shape, string} from "prop-types";
import {ATTACHMENT, mapAttachment} from "domain/Attachment.model.js";

export const AUDIT_GRAPHQL = `
    businessId
    type
    cycle {
        label
    }
    organizationLevel {
        businessId
        shortName
        members {
            user {
                ${USER_GRAPHQL}
            }
            memberships
        }
    }                                   
    status
    creationDate
    dueDate
    finishedDate
    nextAuditDate
    result {
        label
        numberOfQuestions
        answerResults {
            label
            numberOfAnswers
        }                                 
    }
    auditor {
       ${USER_GRAPHQL}
    }
    schedule {
        businessId
        nextSchedule
        duration
        reoccurance {
            unit
            amount
        }
    }
    interval {
        start
        end
        days
    }
    answers {
        businessId
        severity
        value {
            point
        }                                      
        question {                                               
            businessId
            translations {
                comment
                language {
                    code
                }
            }
        }
    }
    deviations {
        businessId
        source {
            answerBusinessId
        }
        translations {
            language {
                code
            }
            title
        }
        assignee {
           ${USER_GRAPHQL}
        }
        priority {
            type
        }
        actions {
            businessId
            assignee {
                ${USER_GRAPHQL}
            }
            translations {
                language {
                    code
                }
                title
            }
            progress
            creationDate
            dueDate
        }                                                                           
    }
`;

export function mapAuditsForMonitoringListing(listedAudits) {
    return listedAudits.map(listedAudit => mapApiAudit(listedAudit));
}

export function mapApiAudit(listedAudit) {
    const hasFinishDate = !_.isEmpty(listedAudit.finishedDate);
    const isClosed = listedAudit.status === 'CLOSED';
    const isOpen = listedAudit.status === 'OPEN';
    const hasResult = !_.isEmpty(listedAudit.result);
    const beforeToday = moment().isAfter(moment(listedAudit.interval.end).endOf('day'));
    return {
        businessId: listedAudit.businessId,
        status: listedAudit.status,
        title: listedAudit.type,
        cycle: listedAudit.cycle.label,
        duration:listedAudit.durationType,
        creationDate: moment(listedAudit.creationDate),
        dueDate: moment(listedAudit.dueDate),
        finishedDate: listedAudit.finishedDate ? moment(listedAudit.finishedDate) : null,
        result: _.get(listedAudit.result, 'label'),
        organizationLevel: {
            name: listedAudit.organizationLevel.shortName,
            businessId: listedAudit.organizationLevel.businessId,
            members: mapMembers(listedAudit.organizationLevel.members)
        },
        auditor: mapUser(listedAudit.auditor),
        nextAuditDate: listedAudit.nextAuditDate,
        answers: mapsAnswers(listedAudit.answers),
        numberOfQuestions: _.get(listedAudit.result, 'numberOfQuestions', 0),
        numberOfFailedQuestions: mapNumberOfFailedQuestions(listedAudit.answers),
        interval: {
            start: listedAudit.interval.start,
            end: listedAudit.interval.end
        },
        scheduleId: _.get(listedAudit.schedule, 'businessId'),
        nextSchedule: _.get(listedAudit.schedule, 'nextSchedule'),
        frequency: mapFrequency(listedAudit.reoccurance),
        missed: !hasFinishDate && beforeToday,
        done: hasFinishDate && isClosed && hasResult,
        open: !hasFinishDate && isOpen && !beforeToday,
        ready: !hasFinishDate && isOpen && !beforeToday && !_.isEmpty(listedAudit.businessId),
        deviations: listedAudit.deviations ? mapDeviations(listedAudit.deviations) : [],
        lastReminder: !_.isEmpty(listedAudit.lastReminder) ? mapLastReminder(listedAudit.lastReminder) : null,
        shift: listedAudit.shift
    }
}

export const AUDIT = {
    businessId: string,
    title: string.isRequired,
    status: string.isRequired,
    cycle: string.isRequired,
    creationDate: object.isRequired,
    dueDate: object.isRequired,
    finishedDate: object,
    missed: bool.isRequired,
    done: bool.isRequired,
    open: bool.isRequired,
    ready: bool.isRequired,
    result: string,
    organizationLevel: shape({
        name: string.isRequired,
        businessId: string.isRequired,
        members: arrayOf(shape({
            user: shape(USER),
            memberships: arrayOf(string)
        }))
    }),
    auditor: shape(USER),
    scheduleId: string,
    nextSchedule: any,
    nextAuditDate: any,
    frequency: shape({
        unit: string.isRequired,
        amount: number.isRequired
    }),
    interval: shape({
        start: any.isRequired,
        end: any.isRequired,
        days: arrayOf(any)
    }),
    numberOfQuestions: number.isRequired,
    numberOfFailedQuestions: number.isRequired,
    answers: arrayOf(shape({
        businessId: string.isRequired,
        severity: string,
        question: shape({
            businessId: string.isRequired,
            translations: arrayOf(shape({
                languageCode: string.isRequired,
                comment: string.isRequired
            }))
        })
    })),
    lastReminder: shape({
        sender: shape(USER),
        timestamp: any.isRequired
    })
};

export const DEVIATION = {
    businessId: string.isRequired,
    answerBusinessIds: arrayOf(string),
    questionBusinessIds: arrayOf(string),
    translations: arrayOf(shape({
        title: string.isRequired,
        languageCode: string.isRequired
    })),
    assignee: shape(USER),
    priority: string.isRequired,
    actions: arrayOf(shape({
        businessId: string.isRequired,
        translations: arrayOf(shape({
            title: string.isRequired,
            languageCode: string.isRequired
        })),
        assignee: shape(USER),
        progress: number.isRequired,
        dueDate: any.isRequired
    })),
    attachments: arrayOf(shape(ATTACHMENT)),
};

function mapFrequency(reoccurance) {
    return _.isEmpty(reoccurance) ?
        null
        :
        {
            unit: reoccurance.unit,
            amount: reoccurance.amount
        }
}

function mapMembers(members) {
    return members.map(member => {
        return {
            user: mapUser(member.user),
            memberships: member.memberships
        }
    })
}

function mapsAnswers(answers) {
    return answers.map(answer => {
        return {
            businessId: answer.businessId,
            severity: answer.severity,
            question: mapQuestion(answer.question)
        }
    })
}

function mapQuestion(question) {
    return {
        businessId: question.businessId,
        translations: mapQuestionTranslations(question.translations)
    }
}

function mapQuestionTranslations(translations) {
    return translations.map(translation => ({
        languageCode: translation.language.code,
        comment: translation.comment || ''
    }));
}


function mapNumberOfFailedQuestions(answers) {
    if (_.isEmpty(answers)) {
        return 0
    } else {
        return answers
            .filter(answer => answer.value.point === 0)
            .length;
    }
}

function mapDeviationTranslation(translations) {
    return translations.map(translation => ({
        languageCode: translation.language.code,
        title: translation.title || ''
    }));
}

function mapAndSortActions(actions) {
    return _.sortBy(actions, ['creationDate']).map(action => {
        return {
            businessId: action.businessId,
            translations: mapDeviationTranslation(action.translations),
            dueDate: action.dueDate,
            progress: action.progress,
            assignee: mapUser(action.assignee),
        }
    })
}

export function mapDeviations(deviations) {
    return deviations.map(deviation => {
        const sources = _.get(deviation, 'sources', []);
        return {
            businessId: deviation.businessId,
            answerBusinessIds: sources.map(source => source.answerBusinessId),
            questionBusinessIds: sources.map(source => source.questionBusinessId),
            translations: mapDeviationTranslation(deviation.translations),
            assignee: mapUser(deviation.assignee),
            priority: deviation.priority.type,
            actions: mapAndSortActions(deviation.actions),
            attachments: deviation.attachments ? deviation.attachments.map(attachment => mapAttachment(attachment)) : []
        }
    })
}

export function mapLastReminder(lastReminder) {
    return {
        sender: mapUser(lastReminder.sender),
        timestamp: moment(lastReminder.timestamp)
    };
}

