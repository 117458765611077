import React, {Component} from 'react';
import {withStyles, Typography} from "@material-ui/core";
import Attachment from 'components/attachment/Attachment';
import Comment from 'components/comment/Comment';
import utils from 'utils/Utils';
import {withTranslation} from "react-i18next";
import Flex from 'components/grid/Flex';
import {getTextfieldColorByPriority} from 'components/colors/Priority';
import ActionOpenButton from 'scenes/tasks/task-edit/components/ActionOpenButton';
import Checklist from 'components/checklist/Checklist';
import {htmlBlack} from 'components/colors/Colors';
import taskUtils from "scenes/tasks/Task.utils.js";

const styles = theme => ({
    titleBarContainer: {
        paddingRight: 10,
        paddingLeft: 10,
    }
});

export class MobileTaskHeader extends Component {
    render() {
        const {
            classes, t: translate, task, fetchTask, url, applicationOnline, onSuccessfulFileUpload,
            onSuccessfulFileDelete, comments, attachments, checklist, actions, removeUnseenActionTag,
            removeUnseenCommentTag, removeUnseenChecklistTag, removeUnseenAttachmentTag
        } = this.props;
        const {businessId, referenceId} = task;
        const type = 'Task';
        const idPrefix = taskUtils.idPrefix(task, translate);
        const color = getTextfieldColorByPriority(task.priority.type);
        const isSelected = !!businessId;
        const isOfflineCreated = isSelected && businessId.startsWith("OFFLINE");
        const idLabel = isOfflineCreated ? '' : idPrefix ? idPrefix + businessId : businessId;
        return (
            <Flex className={classes.titleBarContainer} container item grow={0} justifyContent={'space-between'}
                  style={{
                      border: '2px solid ' + color,
                      backgroundColor: color
                  }}>
                <Flex item>
                    <Typography variant="h6" style={{whiteSpace: 'nowrap', color: htmlBlack, fontWeight: 'bold'}}>
                        {isSelected
                            ? utils.startCase(idLabel)
                            : translate('global.create', {type: translate(`global.${(type || '').toLowerCase()}`)})
                        }
                    </Typography>
                </Flex>
                <Flex item container justifyContent={'flex-end'}>
                    {
                        isSelected &&
                        <ActionOpenButton
                            {...{
                                numberOfActions: actions.length,
                                url,
                                onOpen: removeUnseenActionTag,
                            }}
                        />
                    }
                    {
                        !isOfflineCreated && applicationOnline && attachments !== undefined &&
                        <Attachment
                            {...{
                                id: businessId || referenceId,
                                referenceType: 'TASK',
                                files: attachments,
                                isMobile: true,
                                onSuccessfulFileUpload,
                                onSuccessfulFileDelete,
                                uploadEnabled: true,
                                onOpen: removeUnseenAttachmentTag,
                            }}
                        />
                    }
                    {
                        isSelected && !isOfflineCreated && applicationOnline && comments !== undefined &&
                        <Comment
                            {...{
                                entityId: businessId,
                                comments,
                                type,
                                isMobile: true,
                                onOpen: removeUnseenCommentTag,
                            }}
                        />
                    }
                    {
                        isSelected && !isOfflineCreated && applicationOnline && checklist !== undefined &&
                        <div style={{color: 'inherit', marginRight: 5, marginLeft: 5}}>
                            <Checklist
                                {...{
                                    entityId: businessId,
                                    checklist,
                                    onClose: fetchTask,
                                    onOpen: removeUnseenChecklistTag,
                                }}
                            />
                        </div>
                    }
                </Flex>
            </Flex>
        );
    }
}

export default withStyles(styles)(withTranslation()(MobileTaskHeader));
