import React, {Component} from 'react';
import {IconButton, Tooltip} from "@material-ui/core";
import BugIcon from '@material-ui/icons/BugReport';
import bugTemplateHtml from './bug-template.js';

export default class BugTemplate extends Component {

    addTemplate = () => {
        const {editor} = this.props;
        const bugTemplate = bugTemplateHtml;
        const index = editor.getSelection(true).index;
        editor.insertText(index, '\n');
        editor.clipboard.dangerouslyPasteHTML(index + 1 , bugTemplate, 'user');
    };

    render() {
        return (
            <Tooltip placement="bottom-start"
                     title="Add Bug template">
                <IconButton onClick={this.addTemplate} style={{padding: 0, float: 'unset'}}>
                    <BugIcon/>
                </IconButton>
            </Tooltip>
        );
    }
}
