import React, {Component, Fragment, PureComponent} from 'react';
import {
    withStyles,
    IconButton,
    Dialog,
    DialogTitle,
    DialogContent,
    Checkbox,
    InputBase
} from "@material-ui/core";
import AddIcon from '@material-ui/icons/Add';
import BootstrapInput from 'components/bootstrap-input/BootstrapInput';
import _ from 'lodash';
import update from 'immutability-helper';
import utils from 'utils/Utils';
import {connect} from 'react-redux';
import connector from './Checklist.connect';
import {Trans, withTranslation} from "react-i18next";
import CheckListIcon from '@material-ui/icons/PlaylistAddCheck';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import Flex from 'components/grid/Flex';
import DeleteIcon from '@material-ui/icons/Delete';
import {htmlGreen} from 'components/colors/Colors.js'
import ChecklistWindow from './ChecklistWindow';
import Divider from "@material-ui/core/Divider";

const styles = theme => ({
    iconButtonRoot: {
        padding: 2,
        fontSize: 12
    },
    dialogContainer: {
        minHeight: 300,
        minWidth: 600
    },
    mobileDialogContainer: {
        minHeight: 300,
        minWidth: '95%'
    },
    dialogTitleRoot: {
        fontWeight: 'bold'
    },
    checkBoxIcon: {
        fill: htmlGreen
    },
    divider: {
        margin: '5px 40px 5px 40px'
    }
});

export class ChecklistItem extends PureComponent {
    render() {
        const {classes, businessId, status, text, handleTextChange, deleteChecklistItem, toggleChecklistItem} = this.props;

        return (
            <Flex item container key={businessId}>
                <Checkbox
                    checked={status === 'DONE'}
                    onChange={toggleChecklistItem}
                    checkedIcon={<CheckBoxIcon className={classes.checkBoxIcon}/>}
                />
                <InputBase className={classes.margin}
                           fullWidth
                           multiline
                           style={{minWidth: 200}}
                           value={text}
                           onChange={handleTextChange}
                />
                <IconButton onClick={deleteChecklistItem}>
                    <DeleteIcon/>
                </IconButton>
            </Flex>
        );
    }
}

export class Checklist extends Component {
    timeout = 0;

    state = {
        isChecklistOpen: this.props.forceOpen || false,
        checklist: [],
        newChecklistItem: ''
    };

    componentDidMount() {
        this.setState({
            checklist: _.cloneDeep(this.props.checklist)
        })
    }

    openChecklist = event => {
        this.preventEventPropagation(event);
        this.setState({
            isChecklistOpen: true
        });
        _.get(this.props, 'onOpen') && this.props.onOpen();
    };

    closeChecklist = event => {
        this.setState({
            isChecklistOpen: false
        });
        _.get(this.props, 'onClose') && this.props.onClose();
    };

    toggleChecklistItem = (checklistItem) => event => {
        const matchingTranslation = checklistItem.translations
            .find(translation => translation.language.code === this.props.i18n.language);
        const isChecked = event.target.checked;
        this.props.updateChecklistItem(
            checklistItem,
            isChecked,
            matchingTranslation.text,
            this.props.i18n.language
        ).then(response => {
            const checklistItemResponse = response.payload.data.data.updateChecklistItem;
            this.setState(oldState => {
                const index = oldState.checklist
                    .findIndex(checklistItemInArray => checklistItemInArray.businessId === checklistItem.businessId);
                if (index !== -1) {
                    return {
                        checklist: update(oldState.checklist, {
                            [index]: {
                                status: {$set: checklistItemResponse.status}
                            }
                        })
                    };
                }
            }, () => {
                this.props.refresh && this.props.refresh();
            })
        })
    };

    handleTextChange = (checklistItem) => event => {
        const matchingTranslation = checklistItem.translations
            .find(translation => translation.language.code === this.props.i18n.language);
        const newText = event.target.value;

        this.setState(oldState => {
            const index = oldState.checklist
                .findIndex(checklistItemInArray => checklistItemInArray.businessId === checklistItem.businessId);
            const translationIndex = oldState.checklist[index].translations
                .findIndex(translation => translation.language.code === this.props.i18n.language);
            if (index !== -1) {
                return {
                    checklist: update(oldState.checklist, {
                        [index]: {
                            translations: {
                                [translationIndex]: {
                                    text: {$set: newText}
                                }
                            }
                        }
                    })
                };
            }
        });
        if (this.timeout) {
            clearTimeout(this.timeout)
        }
        this.timeout = setTimeout(() => {
            this.props.updateChecklistItem(
                checklistItem,
                checklistItem.status === 'DONE',
                matchingTranslation.text,
                this.props.i18n.language
            ).then(() => {
                this.props.refresh && this.props.refresh();
            })
        }, 300);

    };

    deleteChecklistItem = (checklistItem) => event => {
        this.props.deleteChecklistItem(checklistItem.businessId).then(response => {
            this.setState(oldState => {
                const index = oldState.checklist
                    .findIndex(checklistItemInArray => checklistItemInArray.businessId === checklistItem.businessId);
                if (index !== -1) {
                    return {
                        checklist: update(oldState.checklist, {
                            $splice: [[index, 1]]
                        })
                    };
                }
            }, () => {
                this.props.refresh && this.props.refresh();
            })
        })
    };

    handleChange = event => {
        this.setState({
            [event.target.name]: event.target.value,
        });
    };

    addChecklistItem = () => {
        if (!_.isEmpty(this.state.newChecklistItem)) {
            this.props.createChecklistItem(
                this.props.entityId,
                this.state.newChecklistItem,
                this.props.i18n.language
            ).then(response => {
                this.setState(oldState => {
                    const checklistItemResponse = response.payload.data.data.createChecklistItem;
                    return {
                        checklist: update(oldState.checklist, {
                            $push: [checklistItemResponse]
                        }),
                        newChecklistItem: ''
                    };
                }, () => {
                    this.props.refresh && this.props.refresh();
                })
            });
        }
    };

    preventEventPropagation = event => {
        event.preventDefault();
        event.stopPropagation();
    };

    render() {
        const {classes, i18n, iconColor, isInline, onOpen} = this.props;
        const {checklist, isChecklistOpen, newChecklistItem} = this.state;
        const isMobile = utils.checkBrowser();
        return (
            !isInline ?
                <Fragment>
                    <IconButton classes={{root: classes.iconButtonRoot}}
                                style={{color: iconColor}}
                                onClick={this.openChecklist}>
                        <CheckListIcon/>
                        <span>{checklist.length}</span>
                    </IconButton>
                    <Dialog
                        open={isChecklistOpen}
                        disableEscapeKeyDown
                        onClose={this.closeChecklist}
                        aria-labelledby="form-dialog-title-checklist"
                        classes={{
                            paper: isMobile ? classes.mobileDialogContainer : classes.dialogContainer
                        }}
                        onClick={this.preventEventPropagation}
                    >
                        <DialogTitle id="form-dialog-title-checklist" classes={{
                            root: classes.dialogTitleRoot
                        }}>
                            <Trans i18nKey={"global.checklist"}/>
                        </DialogTitle>
                        <DialogContent>
                            <Flex item container direction={'column'}>
                                {
                                    checklist.map(checklistItem => {
                                        const matchingTranslation = checklistItem.translations
                                            .find(translation => translation.language.code === i18n.language);
                                        return (
                                            <Fragment>
                                                <ChecklistItem
                                                    {...{
                                                        classes,
                                                        key: checklistItem.businessId,
                                                        text: matchingTranslation.text,
                                                        businessId: checklistItem.businessId,
                                                        status: checklistItem.status,
                                                        handleTextChange: this.handleTextChange(checklistItem),
                                                        deleteChecklistItem: this.deleteChecklistItem(checklistItem),
                                                        toggleChecklistItem: this.toggleChecklistItem(checklistItem),
                                                    }}
                                                />
                                                <Divider className={classes.divider}/>
                                            </Fragment>
                                        )
                                    })
                                }
                                <Flex item container style={{marginTop: 5}}>
                                    <BootstrapInput
                                        value={newChecklistItem}
                                        onChange={this.handleChange}
                                        onEnterKey={this.addChecklistItem}
                                        name="newChecklistItem"
                                        autoFocus
                                    />
                                    <IconButton onClick={this.addChecklistItem}>
                                        <AddIcon/>
                                    </IconButton>
                                </Flex>
                            </Flex>
                        </DialogContent>
                    </Dialog>
                </Fragment>
                :
                <ChecklistWindow {...{
                    i18n, checklist, newChecklistItem, onOpen,
                    handleTextChange: this.handleTextChange,
                    deleteChecklistItem: this.deleteChecklistItem,
                    toggleChecklistItem: this.toggleChecklistItem,
                    handleChange: this.handleChange,
                    addChecklistItem: this.addChecklistItem
                }}/>
        );
    }
}

export default withStyles(styles)(connect(connector.mapStateToProps, connector.mapDispatchToProps)(withTranslation()(Checklist)));
