import React, {Component} from 'react';
import {withStyles, IconButton} from "@material-ui/core";
import {ReactComponent as RocketIcon} from 'assets/img/rocket-icon.svg';
import {controlButtonBlue} from 'components/colors/Colors';
import {KeyboardArrowLeft} from '@material-ui/icons';
import {Link} from 'react-router-dom';
import _ from 'lodash';

const styles = theme => ({
    iconButtonRoot: {
        padding: 2,
        fontSize: 12,
    },
});

export class ActionOpenButton extends Component {

    onClick = () => {
        _.get(this.props, 'onOpen') && this.props.onOpen();
    };

    render() {
        const {classes, numberOfActions, url} = this.props;
        const isActionOpen = (url || '').includes('/action');

        return (
            <IconButton
                classes={{root: classes.iconButtonRoot}}
                component={Link}
                to={isActionOpen ? url.split('/action')[0] : url + '/action/list'}
                onClick={this.onClick}
            >
                {
                    isActionOpen &&
                        <KeyboardArrowLeft/>
                }
                <RocketIcon style={{width: 22, fill: controlButtonBlue, marginRight: 3}}/>
                <span>{numberOfActions}</span>
            </IconButton>
        );
    }
}

export default withStyles(styles)(ActionOpenButton);
