import React, {Component} from 'react';
import {Button, withStyles} from '@material-ui/core';
import {Trans, withTranslation} from "react-i18next";
import Typography from "@material-ui/core/Typography";
import {withRouter} from "react-router";
import Flex from "components/grid/Flex";
import _ from 'lodash';
import {htmlGrey} from "components/colors/Colors";
import {htmlRed} from "components/colors/Colors";

const styles = theme => ({
    page: {
        marginTop: 50
    },
    unsubscribe: {
        marginTop: 20,
        width: 200
    },
    title: {
        fontSize: '1.25rem'
    },
    result: {
        paddingTop: 20,
        fontSize: '1rem'
    }
});

export class Unsubscribe extends Component {

    render() {
        const {classes, unsubscribe, result} = this.props;

        return (
            <Flex item container direction={'column'} alignItems={'center'}>
                {
                    _.isEmpty(result) &&
                    <Typography className={classes.title}>
                        <Trans i18nKey={'global.unsubscribeWarning'}/>
                    </Typography>
                }
                {
                    _.isEmpty(result) &&
                    <Button
                        variant="contained"
                        color="primary"
                        type="submit"
                        className={classes.unsubscribe}
                        onClick={() => (unsubscribe(this.props.match.params.id))}
                    >
                        <Trans i18nKey={'global.unsubscribe'}/>
                    </Button>
                }
                {
                    result === 'Failed' &&
                    <Typography className={classes.result} style={{
                        color: htmlRed
                    }}>
                        <Trans i18nKey={'global.failedUnsubscription'}/>
                    </Typography>
                }
                {
                    result === 'Successful' &&
                    <Typography className={classes.result} style={{
                        color: htmlGrey
                    }}>
                        <Trans i18nKey={'global.successfulSubscription'}/>
                    </Typography>
                }
            </Flex>
        );
    }
}

export default withStyles(styles)(withTranslation()(withRouter(Unsubscribe)));
