import React, {Component} from 'react';
import {IconButton, Typography, withStyles} from "@material-ui/core";
import Flex from 'components/grid/Flex';
import {withTranslation} from 'react-i18next';
import * as colors from 'components/colors/Colors';
import {ArrowBackIos as BackIcon, List as ListIcon, Save as SaveIcon} from '@material-ui/icons';
import {Link} from 'react-router-dom';
import {SCHEDULED_AUDITS} from 'routes/routes';

const styles = theme => ({
    subtext: {
        fontSize: 12,
        color: 'white'
    },
    circle: {
        width: 30,
        height: 30,
        '@media (min-width: 800px)': {
            width: 40,
            height: 40,
        },
        borderRadius: '50%',
        boxShadow: `0 0 7px 0 ${colors.htmlGrey}`,
        zIndex: 1,
    },
    scheduleText: {
        fontSize: 18,
        fontWeight: 'bold',
        color: colors.htmlWhite,
        '@media (min-width: 800px)': {
            fontSize: '1.75em',
        },
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    scheduledItemInfo: {
        marginLeft: 20,
        color: colors.doveGrey,
        justifyContent: 'flex-start',
        '@media (min-width: 800px)': {
            justifyContent: 'center',
            paddingRight: 130
        }
    },
    title: {
        maxWidth: '50vw',
        fontSize: '1em',
        lineHeight: 'unset',
        '@media (min-width: 800px)': {
            fontSize: '2em',
            height: 30
        }
    },
    area: {
        fontSize: '0.875em',
        lineHeight: 'unset',
        '@media (min-width: 800px)': {
            fontSize: '1.75em',
        },
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    questionnaireName: {
        color: colors.htmlWhite,
        overflow: 'hidden',
        textOverflow: 'ellipsis'
    },
    pageTitleContainer: {
        position: 'absolute',
        top: 0,
        minHeight: 50,
        backgroundColor: colors.blackPearl2,
        '@media (min-width: 800px)': {
            marginLeft: 60
        },
        left: 0,
        right: 0,
        zIndex: 6
    },
});

export class AuditHeader extends Component {

    render() {
        const {
            classes, duration, color, questionnaireName, subtext, t: translate, isVdaAudit, save, jumpToListView,
            disabled, editDisabled
        } = this.props;
        const durationTypeLetter = translate('audit-administration.duration.' + duration).charAt(0);
        return (
            <Flex item container alignItems={'center'} className={classes.pageTitleContainer}>
                <IconButton
                    component={Link}
                    to={SCHEDULED_AUDITS.path}
                    style={{marginLeft: 20}}
                >
                    <BackIcon style={{color: colors.htmlWhite}}/>
                </IconButton>
                {
                    !isVdaAudit &&
                    <Flex item grow={0} container justifyContent={'flex-end'}>
                        <Flex container className={classes.circle} justifyContent={'center'} alignItems={'center'}
                              style={{backgroundColor: colors.htmlWhite}}>
                            <Typography className={classes.scheduleText}
                                        style={{color}}>{durationTypeLetter}</Typography>
                        </Flex>
                    </Flex>
                }

                {
                    isVdaAudit
                        ? <Flex item container className={classes.scheduledItemInfo} alignItems={'center'}>
                            <Typography style={{color: colors.htmlWhite, fontWeight: 'bold', textTransform: 'uppercase'}}>
                                VDA
                            </Typography>
                            <Typography style={{color: colors.htmlWhite, fontWeight: 'bold', margin: '0 5px 7px 5px'}}>
                                .
                            </Typography>
                            <Typography style={{
                                color: colors.htmlWhite,
                                textOverflow: 'ellipsis',
                                overflow: 'hidden',
                                maxWidth: '55vw',
                                maxHeight: '6vh'
                            }}>
                                {subtext}
                            </Typography>
                        </Flex>

                        : <Flex item container grow={0} className={classes.scheduledItemInfo}
                                direction={'column'} alignItems={'center'}>
                            <Flex container grow={0} className={classes.title}>
                                <div
                                    className={classes.questionnaireName}>{questionnaireName + ' '
                                + translate("audit-management.scheduled-list.title")}</div>
                            </Flex>
                            <Typography style={{color: colors.paleSky}} className={classes.area}>
                                {subtext}
                            </Typography>
                        </Flex>
                }

                {
                    isVdaAudit && !editDisabled &&
                    <IconButton
                        disabled={disabled}
                        onClick={() => save(true)}>
                        <SaveIcon style={{color: disabled ? colors.grey : colors.htmlWhite}}/>
                    </IconButton>
                }
                {
                    jumpToListView && isVdaAudit &&
                    <IconButton
                        disabled={disabled}
                        onClick={jumpToListView}>
                        <ListIcon style={{color: disabled ? colors.grey : colors.htmlWhite}}/>
                    </IconButton>
                }

            </Flex>
        );
    }
}

export default withStyles(styles)(withTranslation()(AuditHeader));
