import {fetchOrganizationLevel} from "./OrganizationLevelConfiguration.action";

export default {
    mapStateToProps: (state) => {
        return {
            organizationLevel: state.organizationLevelConfigurationReducer.organizationLevel
        };
    },
    mapDispatchToProps: (dispatch) => {
        return {
            fetchOrganizationLevel: (levelBusinessId) => dispatch(fetchOrganizationLevel(levelBusinessId))
        };
    }
};
