import React, {Component} from 'react';
import {
    FormControl,
    IconButton,
    InputAdornment,
    InputLabel,
    ListItemText,
    MenuItem,
    Select,
    Tab,
    Tabs,
    TextField,
    Typography,
    withStyles
} from "@material-ui/core";
import {Autocomplete} from '@material-ui/lab';
import utils from 'utils/Utils';
import {getTextfieldColorByPriority} from 'components/colors/Priority';
import Title from 'components/title/Title';
import {blackPearl, guardsmanRed, htmlWhite, textInputFieldBorder} from 'components/colors/Colors';
import Flex from 'components/grid/Flex';
import {Trans, withTranslation} from "react-i18next";
import moment from 'moment';
import {projectBreadCrumbs, routeTo, TASK_EDIT} from 'routes/routes';
import _ from "lodash";
import ConfirmationDialog from "components/confirmation-dialog/ConfirmationDialog";
import {taskEditDescription} from 'components/zindex/zIndex';
import TagInput from 'components/tags/TagInput'
import {Link, Route, withRouter} from 'react-router-dom';
import TranslationChangedDialog from 'scenes/tasks/components/TranslationChangedDialog';
import ids from 'components/ids/ids';
import H4Avatar from "components/avatar/H4Avatar";
import Attachment from 'components/attachment/Attachment';
import Checklist from 'components/checklist/Checklist';
import {
    ArrowBack as BackIcon,
    ArrowDropDown as OpenIcon,
    Delete as DeleteIcon,
    Done as DoneIcon,
    FileCopy as FileCopyIcon
} from '@material-ui/icons';
import DesktopActionList from 'scenes/tasks/actions/action-list/components/DesktopActionList';
import DesktopTaskEditComments from 'scenes/tasks/task-edit/components/DesktopTaskEditComments';
import QuillEditor from 'components/quill-editor/QuillEditor';
import taskUtils from 'scenes/tasks/Task.utils';
import CalendarPage from "components/calendar/CalendarPage";
import DueDateProgressBar from "components/progress-bar/DueDateProgressBar";

const styles = theme => ({
    paper: {
        marginTop: 45
    },
    overFlowDays: {
        display: 'inline',
        color: guardsmanRed,
        padding: '0px 0px 0px 5px',
        position: 'absolute',
        left: '45%',
        '@media (min-width: 1300px)': {
            left: '65%',
        },
        bottom: 15
    },
    inputItem: {
        padding: '15px 7px',
    },
    leftColumn: {
        display: 'flex',
        flex: '4 4 0px',
        '@media (min-width: 1900px)': {
            flex: '5 5 0px',
        },
        paddingRight: 15,
        flexDirection: 'column',
    },
    rightColumn: {
        display: 'flex',
        flex: '2 2 0px',
        '@media (min-width: 1000px)': {
            flex: '1 1 0px',
        },
        flexDirection: 'column',
    },
    iconColor: {
        color: 'inherit'
    },
    iconWithMargin: {
        padding: 3,
        marginRight: 30
    },
    titleBarRoot: {
        zIndex: 6,
        marginLeft: 60,
        position: 'fixed',
        left: 0,
        right: 0,
        marginTop: 0
    },
    titleBarContainer: {
        minHeight: 45,
        color: blackPearl,
    },
    tabIndicator: {
        backgroundColor: blackPearl,
    },
    tabSelected: {
        fontSize: 18,
        fontWeight: 'bold'
    },
    tabRoot: {
        textTransform: 'capitalize'
    },
    tagsMargin: {
        marginLeft: -9
    }
});

const Tags = props => {
    const {classes, freeTags, isTaskEditable, tagSuggestions, handleTagAddition, handleTagDelete, translate} = props;
    return (
        <div className={classes.inputItem}>
            <InputLabel shrink>{translate("task-management.task-edit.relatedTo")}</InputLabel>
            <TagInput {...{
                classes: {root: classes.tagsMargin},
                isNewAllowed: true,
                tags: freeTags,
                disabled: !isTaskEditable,
                suggestions: tagSuggestions,
                onAddition: handleTagAddition,
                onDelete: handleTagDelete,
                placeholder: isTaskEditable
                    ? translate('task-management.task-edit.tagPlaceholder')
                    : _.isEmpty(freeTags)
                        ? translate('task-management.task-edit.noTagsPlaceholder')
                        : null
            }}
            />
        </div>
    )
};

const Description = props => {
    const {
        classes, isTaskEditable, matchingTranslation, handleDescriptionChange, fileUploadOptions, translate, language
    } = props;
    return (
        <Flex item container direction={'column'} className={classes.inputItem}
              style={{minHeight: 300, paddingTop: 'unset'}}>
            <Flex item container grow={0} alignItems={'center'} style={{marginBottom: 5}}>
                <InputLabel shrink>{translate("task-management.task-edit.description")}</InputLabel>
            </Flex>
            <QuillEditor {...{
                text: matchingTranslation.description,
                language: language,
                isEditable: isTaskEditable,
                handleTextChange: handleDescriptionChange,
                fileUploadOptions,
                containerStyle: {
                    border: `1px solid ${textInputFieldBorder}`,
                    borderRadius: 4,
                    zIndex: taskEditDescription,
                    backgroundColor: htmlWhite
                }
            }}/>
        </Flex>
    )
};

const SolveUntil = props => {
    const {classes, task, handleDueDateChange, isTaskEditable, translate} = props;
    return (
        <Flex container justifyContent={'space-between'} alignItems={'flex-end'} className={classes.inputItem}>
            <CalendarPage {...{
                title: translate("task-management.task-edit.created"),
                date: task.creationDate,
                name: 'creationDate',
                disabled: true
            }}/>
            <DueDateProgressBar value={task.dueDateProgress} style={{width: '45%', height: 8}}/>
            <CalendarPage {...{
                title: translate("task-management.task-edit.solveUntil"),
                date: task.dueDate,
                name: 'dueDate',
                onChange: handleDueDateChange,
                disabled: !isTaskEditable
            }}/>
        </Flex>
    )
};

const ResponsibleField = props => {
    const {classes, users, handleResponsibleChange, isTaskEditable, assignee, translate} = props;
    return (
        <div className={classes.inputItem}>
            <Autocomplete
                options={users}
                onChange={handleResponsibleChange}
                value={assignee}
                disableClearable
                disabled={!isTaskEditable}
                getOptionSelected={(option, value) => {
                    return option.businessId === value.businessId
                }}
                getOptionLabel={user => utils.formattedUserName(user)}
                renderInput={params =>
                    <TextField {...params}
                               label={translate('task-management.task-edit.responsible')}
                               fullWidth
                               InputProps={{
                                   ...params.InputProps,
                                   endAdornment: <InputAdornment position={'end'}
                                                                 style={{position: 'absolute', right: -4, top: 14}}>
                                       <H4Avatar {...{user: assignee}}/>
                                       <IconButton style={{padding: 4}}>
                                           <OpenIcon/>
                                       </IconButton>
                                   </InputAdornment>
                               }}
                    />}
                renderOption={user =>
                    <Flex item container key={user.businessId}>
                        <ListItemText primary={utils.formattedUserName(user)}/>
                        <H4Avatar {...{user}}/>
                    </Flex>
                }
            />
        </div>
    )
};

const CreatedBy = props => {
    const {
        classes, translate, creator
    } = props;
    return (
        <div className={classes.inputItem}>
            <TextField
                value={utils.formattedUserName(creator)}
                label={translate('task-management.task-edit.createdBy')}
                disabled
                fullWidth
                InputProps={{
                    endAdornment: <InputAdornment position={'end'} style={{position: 'absolute', right: 28, top: 14}}>
                        <H4Avatar {...{user: creator}}/>
                    </InputAdornment>
                }}
            />
        </div>
    )
};

const PreviousAssignee = props => {
    const {
        classes, translate, previousAssignee
    } = props;
    return (
        <div className={classes.inputItem}>
            <TextField
                value={utils.formattedUserName(previousAssignee)}
                label={translate('task-management.task-edit.previousAssignee')}
                disabled
                fullWidth
                InputProps={{
                    endAdornment: <InputAdornment position={'end'} style={{position: 'absolute', right: 28, top: 14}}>
                        <H4Avatar {...{user: previousAssignee}}/>
                    </InputAdornment>
                }}
            />
        </div>
    )
};

const SourceType = props => {
    const {
        classes, translate, sourceType
    } = props;
    return (
        <div className={classes.inputItem}>
            <TextField
                value={sourceType}
                label={translate('task-management.task-edit.source')}
                disabled
                fullWidth
            />
        </div>
    )
};

const Severity = props => {
    const {
        classes,
        translate,
        handlePriorityChange,
        deviationSeverities,
        taskSeverities,
        isDeviation,
        isTaskEditable,
        priorityType
    } = props;
    const severities = (isDeviation ? deviationSeverities : taskSeverities) || [];
    return (
        <div className={classes.inputItem}>
            <FormControl fullWidth>
                <InputLabel>{translate("task-management.task-edit.severity")}</InputLabel>
                <Select
                    value={priorityType}
                    onChange={handlePriorityChange}
                    fullWidth
                    disabled={isDeviation || !isTaskEditable}
                    name={'priority'}
                    renderValue={priority => translate(`task-management.priority.${priority}`)}
                >
                    {severities.map(priority => (
                        <MenuItem key={priority} value={priority}>
                            <ListItemText primary={translate(`task-management.priority.${priority}`)}/>
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </div>
    )
};

const WorkflowStatus = props => {
    const {classes, translate, status, handleChangeOnTask, statuses, isTaskEditable} = props;
    return (
        <div className={classes.inputItem}>
            <FormControl fullWidth>
                <InputLabel>{translate("task-management.task-edit.workflowStatus")}</InputLabel>
                <Select
                    value={status}
                    onChange={handleChangeOnTask}
                    fullWidth
                    disabled={!isTaskEditable}
                    name={'status'}
                    renderValue={status => status.label}
                >
                    {statuses && statuses.map(status => (
                        <MenuItem key={status.label} value={status}>
                            <ListItemText primary={status.label}/>
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </div>
    )
};

const FinishedAt = props => {
    const {classes, finishedDate, overFlowDays, translate} = props;
    return (
        <div className={classes.inputItem} style={{position: 'relative'}}>
            <TextField
                disabled
                label={translate('task-management.task-edit.finishedAt')}
                fullWidth
                value={moment(finishedDate).format(utils.DATE_FORMAT) || ''}
                onChange={() => {
                }}
            />
            {
                overFlowDays > 0 &&
                <Typography className={classes.overFlowDays}>
                    <Trans i18nKey={"task-management.task-edit.overflowDay"} values={{days: overFlowDays}}/>
                </Typography>
            }
        </div>
    )
};

const TitleBar = props => {
    const {
        classes,
        translate,
        businessId,
        isSelected,
        priorityType,
        breadCrumbs,
        idPrefix,
        routeProps,
        isTaskEditable,
        save,
        isSaveDisabled,
        saveWithOptions,
        cancelDelete,
        deleteTask,
        cloneTask,
        isDeviation,
        isApplicationOnline,
        user
    } = props;
    const breadCrumbsCopy = breadCrumbs ? _.cloneDeep(breadCrumbs) : [];
    const breadCrumbTail = breadCrumbsCopy.pop();
    const lightColor = getTextfieldColorByPriority(priorityType);
    const isOfflineCreated = isSelected && businessId.startsWith("OFFLINE");
    const idLabel = isOfflineCreated
        ? ''
        : idPrefix
            ? idPrefix + businessId
            : businessId;

    return (
        <Flex container item className={classes.titleBarRoot}
              style={{backgroundColor: lightColor}}>
            <Flex item container justifyContent={'space-between'} alignItems={'center'} padding={5}
                  className={classes.titleBarContainer}>
                <Flex item={'0 1 auto'} container alignItems={'center'}>
                    <Flex item={'0 1 auto'} style={{marginLeft: 5, marginRight: 15}}>
                        <IconButton
                            onClick={() => {
                                save(true)
                            }}
                            id={ids.cancelButton}
                            style={{padding: 3}}
                        >
                            <BackIcon style={{color: "inherit"}}/>
                        </IconButton>
                    </Flex>
                    <Flex item={'0 0 auto'}>
                        <Typography variant="h6" id={ids.editId} style={{color: blackPearl}}
                                    onClick={isSelected ? () => {
                                        utils.copyToClipboard(idLabel)
                                    } : () => {
                                    }}
                        >
                            {breadCrumbTail}
                            &nbsp; #
                            {
                                isSelected
                                    ? businessId
                                    : translate('global.new-with-type', {
                                        type: translate('global.task')
                                    })
                            }
                        </Typography>
                    </Flex>
                </Flex>
                <Flex item={'1 1 auto'} container justifyContent={'flex-start'}
                      style={{paddingLeft: 10, marginLeft: 10}}>
                </Flex>

                <Flex item={'0 1 auto'} container style={{paddingRight: 14}}>
                    {
                        isApplicationOnline &&
                        <IconButton
                            id={ids.cloneButton}
                            onClick={cloneTask}
                            disabled={!isTaskEditable || isDeviation}
                            className={classes.iconWithMargin}
                        >
                            <FileCopyIcon style={{color: "inherit"}}/>
                        </IconButton>
                    }
                    {
                        isSelected && user.isAdmin &&
                        <IconButton
                            component={Link}
                            to={utils.joinUrl(routeProps.match.url, '/delete')}
                            disabled={!isTaskEditable || !isSelected}
                            className={classes.iconWithMargin}
                        >
                            <DeleteIcon style={{color: "inherit"}}/>
                        </IconButton>
                    }
                    <IconButton
                        onClick={save}
                        disabled={isSaveDisabled}
                        style={{padding: 3}}
                    >
                        <DoneIcon style={{color: "inherit"}}/>
                    </IconButton>
                </Flex>

            </Flex>
            <Route path={routeProps.match.url + '/overwrite'} render={

                function renderTranslationOverWriteDialog(props) {
                    return <TranslationChangedDialog
                        {...{
                            originUrl: routeProps.match.url,
                            saveWithOptions,
                        }}
                    />
                }
            }/>
            <Route path={routeProps.match.url + '/delete'} render={

                function renderDeleteTaskDialog(props) {
                    return <ConfirmationDialog {...{
                        dialogOpen: true,
                        onDialogClose: cancelDelete,
                        onConfirm: deleteTask,
                        confirmationTextKey: "task-management.deleteTaskConfirmation"
                    }}/>
                }
            }/>
        </Flex>
    );
};

export class DesktopTaskEdit extends Component {

    state = {
        deleteTaskDialogOpen: false,
        goBackAfterSave: false,
        activityTab: 'Tasks',
    };

    save = goBack => {
        taskUtils.unBoundSaveLogic.bind(this)(goBack);
    };

    saveWithOptions = forceTranslate => {
        taskUtils.unBoundSaveWithOptionsLogic.bind(this)(forceTranslate);
    };

    changeActivityTab = (event, value) => {
        this.setState({activityTab: value})
    };

    handleResponsibleChange = (event, value) => {
        this.props.handleResponsibleChange(value);
    };

    cancelDelete = () => {
        this.props.routeProps.history.goBack();
    };

    deleteTask = () => {
        this.props.archiveTask(this.props.task).then(() => {
            this.props.goBack();
        });
    };

    cloneTask = () => {
        this.props.cloneTask(this.props.task).then(response => {
            if (response && response.payload) {
                routeTo(TASK_EDIT.pathWithId(response.payload.data.data.cloneTask.businessId), this.props);
            }
        });
    };

    render() {
        const {
            classes,
            task,
            idPrefix,
            isSelected,
            newTaskRefId,
            freeTags,
            deviationSeverities,
            handleTagDelete,
            taskSeverities,
            statuses,
            routeProps,
            attachmentUploadEnabled,
            fetchStateTask,
            handleTagAddition,
            isTaskEditable,
            applicationOnline,
            handleChangeOnTask,
            handlePriorityChange,
            handleDueDateChange,
            handleDescriptionChange,
            onSuccessfulFileUpload,
            onSuccessfulFileDelete,
            isSaveDisabled,
            handleTitleChange,
            t: translate,
            projectBreadCrumb,
            users,
            comments,
            attachments,
            checklist,
            actions,
            removeUnseenActionTag,
            removeUnseenCommentTag,
            removeUnseenChecklistTag,
            removeUnseenAttachmentTag,
            language,
            matchingTranslation,
            user
        } = this.props;
        const {
            cancelDelete, deleteTask, save, saveWithOptions, changeActivityTab, handleResponsibleChange, cloneTask
        } = this;
        const breadCrumbs = projectBreadCrumbs(projectBreadCrumb);
        const overFlowDays = task.dueDate && task.finishedDate
            ? moment(task.finishedDate).diff(moment(task.dueDate), 'days')
            : 0;
        const previousAssignee = _.get(task, 'delta.user', undefined);

        return (
            <Flex item container column style={{marginBottom: 20, marginRight: 20, marginLeft: 20}}>
                <TitleBar
                    {...{
                        classes, translate, breadCrumbs, idPrefix, routeProps, isTaskEditable, isSelected,
                        save, isSaveDisabled, saveWithOptions, cancelDelete, deleteTask, cloneTask,
                        businessId: task.businessId,
                        referenceId: newTaskRefId,
                        priorityType: _.get(task, 'priority.type'),
                        isDeviation: task.type === 'DEVIATION',
                        isApplicationOnline: applicationOnline,
                        user
                    }}
                />
                <Flex container item={'1 0 auto'} padding={10} direction={'column'} className={classes.paper}>
                    <Flex container item={'0 1 auto'}>
                        <div className={classes.leftColumn}>

                            <Title {...{
                                translate,
                                title: matchingTranslation.title,
                                isTranslated: matchingTranslation.origin === 'GOOGLE_TRANSLATE',
                                autofocus: !isSelected,
                                disabled: task.isDeviation || !isTaskEditable,
                                handleTitleChange,
                            }}/>

                            <Description {...{
                                classes,
                                translate,
                                isTaskEditable,
                                matchingTranslation,
                                handleDescriptionChange,
                                language,
                                fileUploadOptions: {
                                    id: task.businessId || newTaskRefId,
                                    referenceType: 'TASK',
                                    onSuccessfulFileUpload
                                }
                            }}/>

                            {
                                isSelected &&
                                <Flex item container direction={'column'} padding={7}>
                                    <Tabs value={this.state.activityTab} onChange={changeActivityTab}
                                          classes={{indicator: classes.tabIndicator}}>
                                        <Tab value={'Tasks'}
                                             classes={{
                                                 selected: classes.tabSelected,
                                                 root: classes.tabRoot
                                             }}
                                             label={`${translate("task-management.actions")}${actions.length ? ` (${actions.length})` : ''}`}/>
                                        {
                                            applicationOnline &&
                                            <Tab value={'Comments'}
                                                 classes={{
                                                     selected: classes.tabSelected,
                                                     root: classes.tabRoot
                                                 }}
                                                 label={`${translate("comment.title")}${comments.length ? ` (${comments.length})` : ''}`}/>
                                        }
                                        {
                                            applicationOnline &&
                                            <Tab value={'Attachments'}
                                                 classes={{
                                                     selected: classes.tabSelected,
                                                     root: classes.tabRoot
                                                 }}
                                                 label={`${translate("attachments.title")}${attachments.length ? ` (${attachments.length})` : ''}`}/>
                                        }
                                        {
                                            applicationOnline &&
                                            <Tab value={'Checklist'}
                                                 classes={{
                                                     selected: classes.tabSelected,
                                                     root: classes.tabRoot
                                                 }}
                                                 label={`${translate("global.checklist")}${checklist.length ? ` (${checklist.length})` : ''}`}/>
                                        }
                                    </Tabs>
                                    {
                                        this.state.activityTab === 'Tasks' &&
                                        <DesktopActionList onOpen={() => {
                                            removeUnseenActionTag(task)
                                        }}/>
                                    }
                                    {
                                        this.state.activityTab === 'Comments' &&
                                        <DesktopTaskEditComments onOpen={() => {
                                            removeUnseenCommentTag(task)
                                        }}/>
                                    }
                                    {
                                        this.state.activityTab === 'Attachments' &&
                                        <Attachment
                                            {...{
                                                id: task.businessId || newTaskRefId,
                                                files: attachments,
                                                referenceType: 'TASK',
                                                attachmentType: 'INLINE_WINDOW',
                                                onSuccessfulFileUpload,
                                                onSuccessfulFileDelete,
                                                uploadEnabled: attachmentUploadEnabled,
                                                onOpen: removeUnseenAttachmentTag,
                                                iconColor: attachments.length > 0 ? blackPearl : 'inherit'
                                            }}
                                        />
                                    }
                                    {
                                        this.state.activityTab === 'Checklist' &&
                                        <Checklist {...{
                                            isInline: true,
                                            entityId: task.businessId,
                                            checklist,
                                            refresh: fetchStateTask,
                                            onOpen: removeUnseenChecklistTag,
                                        }}/>
                                    }
                                </Flex>
                            }
                        </div>
                        <div className={classes.rightColumn}>
                            <Flex item container direction={'column'}>

                                <SolveUntil {...{
                                    classes,
                                    task,
                                    handleDueDateChange,
                                    isTaskEditable,
                                    translate
                                }}/>

                                <ResponsibleField {...{
                                    classes,
                                    users,
                                    handleResponsibleChange,
                                    isTaskEditable,
                                    assignee: task.assignee,
                                    translate
                                }}/>

                                {
                                    previousAssignee &&
                                    (previousAssignee.businessId !== _.get(task, 'assignee.businessId')) &&
                                    (previousAssignee.businessId !== _.get(task, 'createdBy.businessId')) &&
                                    <PreviousAssignee {...{
                                        classes,
                                        translate,
                                        previousAssignee,
                                        forwardedAt: _.get(task, 'delta.dateTime'),
                                    }}/>
                                }

                                {
                                    task.createdBy &&
                                    task.createdBy.businessId !== _.get(task, 'assignee.businessId') &&
                                    <CreatedBy {...{
                                        classes,
                                        translate,
                                        creator: task.createdBy
                                    }}/>
                                }

                                <WorkflowStatus {...{
                                    classes,
                                    status: task.status,
                                    handleChangeOnTask,
                                    statuses,
                                    isTaskEditable,
                                    translate
                                }}/>

                                <Severity {...{
                                    classes,
                                    handlePriorityChange,
                                    deviationSeverities,
                                    taskSeverities,
                                    isDeviation: task.isDeviation,
                                    isTaskEditable,
                                    priorityType: task.priority.type,
                                    translate
                                }}/>

                                {
                                    task.isDeviation && task.sourceType &&
                                    <SourceType {...{
                                        classes,
                                        translate,
                                        sourceType: task.sourceType,
                                    }}/>
                                }

                                {
                                    task.finishedDate &&
                                    <FinishedAt {...{
                                        classes,
                                        finishedDate: task.finishedDate,
                                        overFlowDays,
                                        translate
                                    }}/>
                                }

                                <Tags {...{
                                    classes,
                                    freeTags: task.freeTags,
                                    isTaskEditable,
                                    tagSuggestions: freeTags,
                                    handleTagAddition,
                                    handleTagDelete,
                                    translate
                                }}/>

                            </Flex>

                        </div>
                    </Flex>
                </Flex>
            </Flex>
        );
    }
}

export default withStyles(styles)(withTranslation()(withRouter(DesktopTaskEdit)));
