import React, {Component} from 'react';
import {withStyles} from '@material-ui/core';
import Header from 'components/header/Header';
import Flex from 'components/grid/Flex';
import utils from 'utils/Utils';
import {htmlWhite} from 'components/colors/Colors.js'

const styles = theme => ({
    appFrame: {
        backgroundColor: htmlWhite,
        touchAction: 'manipulation',
        fontFamily: 'Arial',
    }
});

class HeaderLayout extends Component {

    render() {
        const {classes, showHeader} = this.props;
        const isMobile = utils.checkBrowser();
        return (
            <Flex container direction={'column'} className={classes.appFrame}>
                {
                    showHeader &&
                    <Flex item grow={0}>
                        <Header {...{isMobile, isTitleVisible: false}}/>
                    </Flex>
                }

                <Flex item container direction={'column'}
                      style={{marginTop: isMobile ? 0 : 80}}>
                    {this.props.children}
                </Flex>
            </Flex>
        );
    }
}

export default withStyles(styles, {withTheme: true})(HeaderLayout);
