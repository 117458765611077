import React, {Component} from 'react';
import {
    withStyles,
} from "@material-ui/core";
import Flex from 'components/grid/Flex';
import TaskTile from 'scenes/tasks/components/TaskTile';
import {transparentDownRiver025, htmlWhite} from 'components/colors/Colors';
import SimpleTaskTile from "scenes/tasks/components/themes/SimpleTaskTile";
import {getColorbyPriority} from 'components/colors/Priority';
import DragTaskTile from 'scenes/tasks/components/DragTaskTile';

const styles = theme => ({
    draggableTaskContainer: {
        backgroundColor: htmlWhite,
        marginTop: 8,
        position: 'relative',
        minWidth: 200,
        boxShadow: `0px 1px 2px 0px ${transparentDownRiver025}`
    },
});

export class DraggableTask extends Component {

    state = {
        isOpen: false
    };

    toggleOpen = () => {
        this.setState({isOpen: !this.state.isOpen});
    };

    render() {
        const {
            classes, closeTask, task, applicationOnline, isCloseable, layout, isStarting, removeTaskFromKanbanBoard, id,
            shrink, dragHandleProps
        } = this.props;
        const compactView = layout && layout.toLowerCase() === 'compact';
        const simpleView = layout && layout.toLowerCase() === 'simple';
        return (
            !shrink
                ? <Flex container className={classes.draggableTaskContainer} key={task.businessId}
                        id={id} style={{borderLeft: `5px solid ${getColorbyPriority(task.priority.type)}`}}>
                    {
                        simpleView ?
                            <SimpleTaskTile {...{
                                task,
                                applicationOnline,
                                toggleOpen: this.toggleOpen
                            }}/>
                            :
                            <TaskTile {...{
                                task,
                                applicationOnline,
                                compactView,
                                closeTask,
                                removeTaskFromKanbanBoard,
                                isCloseable,
                                isStarting
                            }}/>
                    }
                </Flex>
                : <DragTaskTile {...{
                    taskId: task.businessId,
                    assignee: task.assignee,
                    dragHandleProps
                }}/>
        );
    }
}

export default withStyles(styles)(DraggableTask);
