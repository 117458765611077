import {fetchAuditManagementConfigurationData} from "scenes/configuration/GeneralConfiguration.action"
import {resetOrganizationLevelTree} from "./administration/organization-tree/OrganizationTree.action";

export default {
    mapStateToProps: (state) => {
        return {
        };
    },
    mapDispatchToProps: (dispatch) => {
        return {
            fetchAuditManagementConfigurationData: () => dispatch(fetchAuditManagementConfigurationData()),
            resetOrganizationLevelTree: () => dispatch(resetOrganizationLevelTree())
        };
    }
};
