import * as actionTypes from './Tasks.action.types';
import {
    UNSEEN_ACTION_TAG,
    UNSEEN_ATTACHMENT_TAG,
    UNSEEN_CHECKLIST_ITEM_TAG,
    UNSEEN_COMMENT_TAG,
    UNSEEN_CONTENT_TAG
} from 'domain/Tag.model';
import _ from 'lodash';
import query from "store/GraphqlQueries";
import taskCache from "./Tasks.cache";
import {validateApplicationStatusForCache} from "cache/Cache.utils";


export function removeUnseenContentTag(task) {
    return findAndRemoveTaskTag(task, UNSEEN_CONTENT_TAG, actionTypes.REMOVE_UNSEEN_CONTENT_TAG_TO_TASK)
}

export function removeUnseenActionTag(task) {
    return findAndRemoveTaskTag(task, UNSEEN_ACTION_TAG, actionTypes.REMOVE_UNSEEN_ACTION_TAG_TO_TASK)
}

export function removeUnseenAttachmentTag(task) {
    return findAndRemoveTaskTag(task, UNSEEN_ATTACHMENT_TAG, actionTypes.REMOVE_UNSEEN_ATTACHMENT_TAG_TO_TASK)
}

export function removeUnseenCommentTag(task) {
    return findAndRemoveTaskTag(task, UNSEEN_COMMENT_TAG, actionTypes.REMOVE_UNSEEN_COMMENT_TAG_TO_TASK)
}

export function removeUnseenChecklistTag(task) {
    return findAndRemoveTaskTag(task, UNSEEN_CHECKLIST_ITEM_TAG, actionTypes.REMOVE_UNSEEN_CHECKLIST_ITEM_TAG_TO_TASK)
}

function findAndRemoveTaskTag(task, tagLabel, dispatchType) {
    return (dispatch, getState) => {
        const loggedInUser = getState().mainReducer.user;
        const unseenTagId = _.get(_.find(task.tags, ['label', tagLabel]), 'businessId');
        if (unseenTagId && loggedInUser.businessId === _.get(task, 'assignee.businessId')) {
            return dispatch(removeTaskTag(task.businessId, unseenTagId, dispatchType))
        }
    }
}

function removeTaskTag(taskBusinessId, tagBusinessId, dispatchType) {
    return query.graphql({
        type: dispatchType,
        query: `mutation removeTaskTag($taskBusinessId: String!, $tagBusinessId: String!){
    	                        removeTaskTag(taskBusinessId: $taskBusinessId, tagBusinessId: $tagBusinessId)
                            }
                           `,
        variables: {
            taskBusinessId,
            tagBusinessId
        }
    });
}

export function handleTagAssignment(input) {
    return (dispatch) => dispatch(
        taskTagAssignmentQuery(input)
    ).catch(response => {
        return dispatch(validateApplicationStatusForCache(taskCache.cacheTaskTagAssignment(input), response));
    });
}

export function synchronizeTaskTagAssignment(input) {
    return (dispatch) => dispatch(
        taskTagAssignmentQuery(input)
    ).catch(() => {
        return dispatch(taskCache.cacheTaskTagAssignment(input));
    });
}

function taskTagAssignmentQuery(input) {
    return query.graphql({
        type: input.type,
        data: {
            taskBusinessId: input.taskBusinessId,
            fromSynchronization: input.fromSynchronization
        },
        query: `mutation assignTaskTag($taskBusinessId: String!, $tagBusinessId: String!){
    	                    assignTaskTag(taskBusinessId: $taskBusinessId, tagBusinessId: $tagBusinessId)
                        }
                   `,
        variables: {
            taskBusinessId: input.taskBusinessId,
            tagBusinessId: input.tagBusinessId
        }
    })
}

export function synchronizeTaskTagAssignmentRemoval(input) {
    return (dispatch) => dispatch(
        taskTagAssignmentRemovalQuery(input)
    ).catch(() => {
        return dispatch(taskCache.cacheTaskTagAssignmentRemoval(input));
    });
}

function taskTagAssignmentRemovalQuery(input) {
    return query.graphql({
        type: input.type,
        data: {
            taskBusinessId: input.taskBusinessId,
            fromSynchronization: input.fromSynchronization
        },
        query: `mutation removeTaskTag($taskBusinessId: String!, $tagBusinessId: String!){
    	                        removeTaskTag(taskBusinessId: $taskBusinessId, tagBusinessId: $tagBusinessId)
                            }
                           `,
        variables: {
            taskBusinessId: input.taskBusinessId,
            tagBusinessId: input.tagBusinessId
        }
    })
}
