export const FETCH_ORGANIZATION_LEVEL = '@document_machine_details/FETCH_ORGANIZATION_LEVEL';
export const FETCH_ORGANIZATION_LEVEL_SUCCESS = '@document_machine_details/FETCH_ORGANIZATION_LEVEL_SUCCESS';
export const FETCH_ORGANIZATION_LEVEL_FAIL = '@document_machine_details/FETCH_ORGANIZATION_LEVEL_FAIL';

export const FETCH_MACHINE_DETAILS = '@document_machine_details/FETCH_MACHINE_DETAILS';
export const FETCH_MACHINE_DETAILS_SUCCESS = '@document_machine_details/FETCH_MACHINE_DETAILS_SUCCESS';
export const FETCH_MACHINE_DETAILS_FAIL = '@document_machine_details/FETCH_MACHINE_DETAILS_FAIL';

export const UPDATE_MACHINE_STATUS = '@document_machine_details/UPDATE_MACHINE_STATUS';
export const UPDATE_MACHINE_STATUS_SUCCESS = '@document_machine_details/UPDATE_MACHINE_STATUS_SUCCESS';
export const UPDATE_MACHINE_STATUS_FAIL = '@document_machine_details/UPDATE_MACHINE_STATUS_FAIL';

export const FETCH_MACHINE_STATUS_CONFIGURATION_DATA = '@document_machine_details/FETCH_MACHINE_STATUS_CONFIGURATION_DATA';
export const FETCH_MACHINE_STATUS_CONFIGURATION_DATA_SUCCESS = '@document_machine_details/FETCH_MACHINE_STATUS_CONFIGURATION_DATA_SUCCESS';
export const FETCH_MACHINE_STATUS_CONFIGURATION_DATA_FAIL = '@document_machine_details/FETCH_MACHINE_STATUS_CONFIGURATION_DATA_FAIL';

export const FETCH_USERS = '@document_machine_details/FETCH_USERS';
export const FETCH_USERS_SUCCESS = '@document_machine_details/FETCH_USERS_SUCCESS';
export const FETCH_USERS_FAIL = '@document_machine_details/FETCH_USERS_FAIL';

export const FETCH_USERS_FROM_CACHE = '@document_machine_details/FETCH_USERS_FROM_CACHE';

export const FETCH_MACHINE_STATUS_CONFIGURATION_DATA_FROM_CACHE = '@document_machine_details/FETCH_MACHINE_STATUS_CONFIGURATION_DATA_FROM_CACHE';

export const FETCH_MACHINE_DETAILS_FROM_CACHE = '@document_machine_details/FETCH_MACHINE_DETAILS_FROM_CACHE';

export const FETCH_ALL_MACHINE_DETAILS = '@document_machine_details/FETCH_ALL_MACHINE_DETAILS';
export const FETCH_ALL_MACHINE_DETAILS_SUCCESS = '@document_machine_details/FETCH_ALL_MACHINE_DETAILS_SUCCESS';
export const FFETCH_ALL_MACHINE_DETAILS_FAIL = '@document_machine_details/FETCH_ALL_MACHINE_DETAILS_FAIL';

export const FETCH_ORGANIZATION_LEVEL_FROM_CACHE = '@document_machine_details/FETCH_ORGANIZATION_LEVEL_FROM_CACHE';