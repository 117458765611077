import React, {Component} from 'react';
import ReactDOM from 'react-dom';
import Quill from 'quill';
import 'quill/dist/quill.snow.css';
import Flex from 'components/grid/Flex';
import _ from 'lodash';
import {connect} from 'react-redux';
import connector from './QuillEditor.connect';
import BugTemplate from './BugTemplate';
import utils from 'utils/Utils';
import FeatureSwitch from 'utils/FeatureSwitch';
import {withTranslation} from "react-i18next";
import './quillEditor.css'
/**
 * https://quilljs.com/docs/api/
 */
export class QuillEditor extends Component {
    editorRef = React.createRef();
    editor = null;
    htmlToCommit = null;
    options = {
        theme: 'snow',
        modules: {
            toolbar: [
                ['bold', 'italic', 'underline', 'strike'],
                ['link', 'blockquote', 'code-block'],
                [{'script': 'sub'}, {'script': 'super'}],
                [{'indent': '-1'}, {'indent': '+1'}],
                [{'list': 'ordered'}, {'list': 'bullet'}],
                [{'direction': 'rtl'}],
                [{'header': [1, 2, 3, 4, 5, 6, false]}],
                [{'align': ['', 'center', 'right']}],
                [{'size': ['small', false, 'large', 'huge']}],
            ]
        },
        formats:
            [
                'align', 'background', 'header', 'font', 'size', 'align', 'bold', 'italic', 'underline', 'strike',
                'script', 'list', 'bullet', 'indent', 'link', 'color', 'clean', 'image', 'blockquote', 'code-block'
            ],
    };

    componentDidMount() {
        this.initializeQuillJs();
        this.turnOffImageUrlSanitization();
        this.appendBugTemplateToolbarAsOption();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        //NOTE LM: react-i18next uses a mutable singleton for i18n object, because of performance reasons.
        //While it would be logical to check whether the languages differ its not possible this way.
        if(this.props.t !== prevProps.t || this.props.language !== prevProps.language){
            this.editor.root.innerHTML = this.props.text;
            this.editor.update();
        }
    }

    initializeQuillJs = () => {
        const options = {
            ...this.options,
            modules: {
                toolbar: this.props.toolbarOptions || this.options.modules.toolbar,
            }
        };
        this.editor = new Quill(this.editorRef.current, options);
        this.editor.root.innerHTML = this.props.text;
        this.editor.update();
        this.editor.on('text-change', this.onChange);
    };

    turnOffImageUrlSanitization = () => {
        const Image = Quill.import('formats/image');
        Image.sanitize = function (url) {
            return url;
        };
    };

    appendBugTemplateToolbarAsOption = () => {
        if (FeatureSwitch.bugTemplate.active && !utils.checkMobileBrowser()) {
            const toolbar = document.querySelector('.ql-toolbar');
            const bugTemplatePortal = document.createElement("span");
            bugTemplatePortal.id = utils.uuid();
            toolbar.appendChild(bugTemplatePortal);
            ReactDOM.render(<BugTemplate editor={this.editor} commitChanges={this.commitText}/>, document.getElementById(bugTemplatePortal.id));
        }
    };

    onChange = (value, delta, source) => {
        if (source === 'api') {
            return;
        }
        this.htmlToCommit = this.editor.root.innerHTML;
        if (this.onChangeTimeout) {
            clearTimeout(this.onChangeTimeout);
            this.onChangeTimeout = null;
        }
        this.onChangeTimeout = setTimeout(() => this.commitText(), 300);
    };

    commitText() {
        clearTimeout(this.onChangeTimeout);
        this.onChangeTimeout = null;
        if (this.props.isEditable) {
            this.props.handleTextChange(this.htmlToCommit);
        }
    }

    onPaste = event => {
        if (this.props.fileUploadOptions) {
            const file = _.get(event, 'dataTransfer.files[0]') || _.get(event, 'clipboardData.files[0]');

            if (file) {
                event.stopPropagation();
                event.preventDefault();
            }

            if (file) {
                this.props.uploadFile(
                    file,
                    this.props.fileUploadOptions.id,
                    () => {
                    },
                    this.props.fileUploadOptions.referenceType
                ).then(response => {
                    const url = response.payload.data.downloadUrl;
                    const index = this.editor.getSelection(true).index;

                    this.editor.insertText(index, '\n', Quill.sources.USER);
                    this.editor.insertEmbed(index + 1, 'image', url, Quill.sources.USER);
                    this.editor.setSelection(index + 2, Quill.sources.SILENT);

                    if (this.props.fileUploadOptions.onSuccessfulFileUpload) {
                        this.props.fileUploadOptions.onSuccessfulFileUpload(response.payload.data);
                    }
                }).catch(error => {
                    console.log('Error during upload: ', error);
                })
            }
        }
    };

    onDragOver = (event) => {
        event.stopPropagation();
        event.preventDefault();
    };

    onDragEnter = (event) => {
        event.stopPropagation();
        event.preventDefault();
    };

    onContainerClick = (event) => {
        event.stopPropagation();
        event.preventDefault();
        this.editor.focus();
    };

    render() {
        return (
            <Flex container item direction={'column'} onDragEnter={this.onDragEnter} style={this.props.containerStyle}
                  onPaste={this.onPaste} onDrop={this.onPaste} onDragOver={this.onDragOver}>
                <div ref={this.editorRef} style={{flex: '1 1 auto'}} onClick={this.onContainerClick}/>
            </Flex>
        );
    }
}

export default connect(connector.mapStateToProps, connector.mapDispatchToProps)(withTranslation()(QuillEditor));
