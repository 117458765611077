import * as actionTypes from './ActionEdit.action.types';
import {CURRENT_ACTION_SYNCHRONIZED} from 'scenes/tasks/task-edit/TaskEdit.action.types';
import _ from 'lodash';
import {editableAction, emptyAction} from 'domain/task/Action.model';

const initialState = {
    newActionRefId: '',
    action: {},
    project: {}
};

export default function (state = initialState, action) {
    switch (action.type) {
        case actionTypes.FETCH_ACTION_FROM_CACHE:
        case actionTypes.FETCH_ACTION_SUCCESS: {
            const newState = _.cloneDeep(state);
            const h4Action = _.get(action, 'payload.data.data.fetchAction') || _.get(action, 'data.cache.action');
            const supportedLanguages =  _.get(action, 'meta.previousAction.data.supportedLanguages') || _.get(action, 'data.supportedLanguages');
            if (h4Action) {
                newState.action = editableAction(h4Action, supportedLanguages);
            }
            return newState;
        }
        case actionTypes.FETCH_TEMPORARY_ACTION_REFERENCE_ID: {
            const newState = _.cloneDeep(state);
            newState.action = emptyAction(action.data.taskBusinessId, action.data.user, action.data.dueDate, action.data.languages);
            return newState;
        }
        case actionTypes.FETCH_TEMPORARY_ACTION_REFERENCE_ID_SUCCESS: {
            const newState = _.cloneDeep(state);
            newState.newActionRefId = action.payload.data.referenceId;
            return newState;
        }
        case actionTypes.FETCH_TEMPORARY_ACTION_REFERENCE_ID_OFFLINE: {
            const newState = _.cloneDeep(state);
            newState.newActionRefId = action.data.referenceId;
            return newState;
        }
        case actionTypes.CREATE_ACTION_TO_CACHE:
        case actionTypes.CREATE_ACTION_SUCCESS:
        case actionTypes.CREATE_ACTION_FAIL: {
            const newState = _.cloneDeep(state);
            newState.newActionRefId = '';
            return newState;
        }
        case CURRENT_ACTION_SYNCHRONIZED: {
            const newState = _.cloneDeep(state);
            newState.action.businessId = action.data.onlineActionId;
            return newState;
        }
        case actionTypes.FETCH_PROJECT: {
            const newState = _.cloneDeep(state);
            newState.project = _.get(action, 'data.project');
            return newState;
        }
        default:
            return state;
    }
}
