import React from 'react';
import {
    withStyles,
    Typography,
    Button,
} from "@material-ui/core";
import Flex from 'components/grid/Flex';
import {htmlGrey, darkGreyHighlight, htmlWhite, transparentBlack08} from 'components/colors/Colors';
import {Link} from 'react-router-dom';
import {TASK_EDIT} from 'routes/routes';
import H4Avatar from 'components/avatar/H4Avatar'
import {withRouter} from 'react-router-dom';
import {withTranslation} from "react-i18next";
import {prevPath} from 'routes/routes';

const styles = theme => ({
    taskButton: {
        padding: 0,
        width: '100%',
        borderRadius: 'unset',
        lineHeight: 'unset',
        fontSize: 'unset'
    },
    container: {
        backgroundColor: htmlWhite,
        position: 'relative',
        margin: 8,
        '&:active': {
            backgroundColor: darkGreyHighlight
        }
    },
    dueDate: {
        fontSize: 13,
        color: htmlGrey,
        textTransform: 'initial'
    },
    title: {
        fontWeight: 'normal',
        lineHeight: '1.3em',
        color: transparentBlack08,
        textTransform: 'initial',
    },
});

export class SimpleTaskTile extends React.PureComponent {
    render() {
        const {
            classes, location, task, isSwiped, i18n
        } = this.props;
        const matchingTranslation = task.translations
            .find(translation => translation.language.code === i18n.language) || task.translations[0];
        return (
            <Flex item container style={{width: '100%'}}>
                <Button component={Link}
                        to={{
                            pathname: TASK_EDIT.pathWithId(task.businessId),
                            state: {prevPath: prevPath({location})}
                        }}
                        className={classes.taskButton}>
                    <Flex item container className={classes.container} justify-content={'flex-start'}
                          alignItems={'center'}>
                        <H4Avatar {...{user: task.assignee}} style={{marginRight: 8}}/>
                        {
                            !isSwiped &&
                            <Typography className={classes.title}>
                                {matchingTranslation.title}
                            </Typography>
                        }
                    </Flex>
                </Button>
            </Flex>
        );
    }
}

export default withStyles(styles)(withRouter(withTranslation()(SimpleTaskTile)));
