import * as actionTypes from './TaskList.action.types';
import * as taskEditActionTypes from 'scenes/tasks/task-edit/TaskEdit.action.types'
import query from 'store/GraphqlQueries.js';
import taskCache from "scenes/tasks/Tasks.cache";

export function changeFilter(filter) {
    return {
        type: actionTypes.FILTER_CHANGE,
        data: {
            filter
        }
    }
}

export function setTasksStatus(status, tasks) {
    return (dispatch) => {
        return dispatch(
            query.graphql({
                type: actionTypes.SET_TASKS_STATUS,
                caller: 'setTasksStatus',
                query: `mutation setTasksStatus($status: TaskStatusInput!, $taskIds: [String!]!){
    	                    setTasksStatus(status: $status, taskIds: $taskIds){
      	                        businessId
    	                    }
                        }
                       `,
                variables: {
                    status: {businessId: status.businessId},
                    taskIds: tasks.map(task => task.businessId)
                }
            }))
            .then(response => {
                tasks.forEach(editedTask => {
                    taskCache.updateOrCreateTaskInCache({...editedTask, status});
                    dispatch({
                        type: taskEditActionTypes.EDIT_TASK_SUCCESS,
                        data: {editTask: {...editedTask, status}}
                    })
                });
                return response;
            })
    }
}
