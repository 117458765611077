import FeatureSwitch from 'utils/FeatureSwitch';
import _ from 'lodash';
import {logout} from 'scenes/Main.action';
import Logger from 'utils/Logger';

export const interceptors = {
    request: [
        (getState, config) => {
            const mainReducer = getState.getState().mainReducer;
            if (!mainReducer.online) {
                return undefined;
            }
            const accessToken = _.get(mainReducer, 'user.accessToken') || _.get(mainReducer, 'accessTokenForSynchronization');
            if (accessToken) {
                config.headers['Authorization'] = 'Bearer ' + accessToken
            }
            if (FeatureSwitch.extendedHttpHeaders.active) {
                config.headers['Action-Type'] = config.reduxSourceAction.type;
            }
            return config
        }
    ],
    response: [{
        error: function ({getState, dispatch, getSourceAction}, error) {
            // const sourceAction = getSourceAction(error.config);
            // const state = getState();
            // const retryCounter = state.mainReducer.retryCount;
            // const errorCode = _.get(error, 'response.status', 401);

            // Action before token refresh happened 3 times
            // if (retryCounter === 0) {
            // }
            // Token refresh failed
            // if (sourceAction.type === REFRESH_TOKEN) {
            //
            // } else if (errorCode === 401) {
            // Token expired
            // }
            let source;
            try {
                const sourceAction = getSourceAction(_.get(error, 'response.config'));
                source = _.get(sourceAction, 'payload.request.headers.Caller-Function') || _.get(sourceAction, 'payload.request.data')
            } catch (e) {}
            Logger.addToStorage(true, _.get(error, 'response.message', error.response), source);
            // Dispatch Error handling Redux actions.
            const errorCode = _.get(error, 'response.status');
            if (errorCode === 401) {
                dispatch(logout());
            }
            throw (error);
        }
    }]
};
