import React, { Component } from 'react';
import { withStyles } from "@material-ui/core";
import {getColorbyPriority} from 'components/colors/Priority';

const styles = theme => ({
    priorityCircle: {
        height: 8,
        width: 8,
        borderRadius: '50%',
    }
});

export class PriorityCircle extends Component {
    render() {
        const {classes, priority, style} = this.props;

        return (
            <span className={classes.priorityCircle} style={{
                display: 'inline-block',
                backgroundColor: getColorbyPriority(priority),
                ...style
            }}>
            </span>
        );
    }
}

export default withStyles(styles)(PriorityCircle);
