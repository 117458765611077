import React, {Component} from 'react';
import {withStyles, Typography} from "@material-ui/core";
import Flex from "components/grid/Flex";
import {
    ghostGrey,
    concreteWhite,
} from "components/colors/Colors";
import _ from "lodash";
import DurationCircle from "./DurationCircle";
import AuditTileIcons from "./AuditTileIcons";

const styles = theme => ({
    clickableText: {
        '&:hover': {
            cursor: 'pointer'
        }
    }
});

export class AuditTile extends Component {

    calculateBackground = audit => {
        const resultColor = this.props.getTileColor(audit);
        if (audit.duration === 'SHIFT') {
            switch (audit.shift.ordinal) {
                case 0:
                    return `linear-gradient(90deg, ${resultColor} 33%, ${concreteWhite} 33%)`;
                case 1:
                    return `linear-gradient(90deg, ${concreteWhite} 33%, ${resultColor} 33%, ${resultColor} 66%, ${concreteWhite} 66%)`;
                case 2:
                    return `linear-gradient(90deg, ${concreteWhite} 66%, ${resultColor} 66%)`;
                default:
                    return resultColor;
            }
        } else {
            return resultColor;
        }
    };

    render() {
        const {
            audit, length, classes, width, openAuditorUpdateDialog, openAuditDetailsDialog, hoveredAudit,
            isAuditorChangeAllowed, sendAuditReminder, user, toggleCancelAuditScheduleDialog, onAuditHovered
        } = this.props;
        const hovered = audit.scheduleId === hoveredAudit.scheduleId
            && _.get(audit, 'interval.start') === _.get(hoveredAudit, 'interval.start')
            && _.get(audit, 'interval.end') === _.get(hoveredAudit, 'interval.end');
        return (
            <Flex container alignItems={'center'}
                  style={{
                      width: width,
                      height: 60,
                      background: this.calculateBackground(audit),
                      border: `1px solid ${ghostGrey}`,
                      borderRadius: 4,
                  }}>
                <Flex item grow={0}
                      onMouseEnter={onAuditHovered(audit)}
                      onMouseLeave={onAuditHovered({})}
                      style={{
                          marginLeft: -10
                      }}>
                    <DurationCircle {...{
                        audit,
                        hovered,
                        cancelable: true,
                        user,
                        toggleCancelAuditScheduleDialog
                    }}/>
                </Flex>
                <Flex container item grow={1} justifyContent={length === 1 ? 'flex-start' : 'center'} alignItems={'center'}
                      onMouseEnter={onAuditHovered(audit)}
                      onMouseLeave={onAuditHovered({})}
                      style={{
                          paddingLeft: 5
                      }}>
                    <Flex container item direction={'column'} grow={0}>
                        <Typography onClick={openAuditDetailsDialog(audit)}
                                    className={audit.done ? classes.clickableText : ''}
                                    style={{
                                        fontWeight: 'bold'
                                    }}>
                            {audit.title}
                        </Typography>
                        <Typography onClick={openAuditDetailsDialog(audit)}
                                    className={audit.done ? classes.clickableText : ''}>
                            {audit.organizationLevel.name}
                        </Typography>
                    </Flex>
                    {
                        length > 1 &&
                        <AuditTileIcons {...{
                            audit,
                            openAuditorUpdateDialog,
                            isAuditorChangeAllowed,
                            sendAuditReminder,
                        }}/>
                    }
                </Flex>
                {
                    length === 1 &&
                    <AuditTileIcons {...{
                        audit,
                        openAuditorUpdateDialog,
                        isAuditorChangeAllowed,
                        sendAuditReminder,
                    }}/>
                }
            </Flex>
        );
    }
}

export default withStyles(styles)(AuditTile);
