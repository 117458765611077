import {
    string,
    number,
} from "prop-types"

export const LANGUAGE = {
    code: string.isRequired,
    label: string.isRequired,
    ordinal: number.isRequired
};

export const LANGUAGE_GRAPHQL = `
    code
    label
    ordinal
`;
