import * as actionTypes from './Audit.action.types';
import _ from 'lodash';
import moment from 'moment';

const initialState = {
    audits: [],
    auditSetup: {},
    adHocAuditForm: {},
    result: {},
    pictureUrlsToDownload: []
};

export default function (state = initialState, action) {
    switch (action.type) {
        case actionTypes.FETCH_AUDITS_SUCCESS:
        case actionTypes.FETCH_AUDITS_FROM_CACHE: {
            const newState = {...state};
            const audits =
                _.get(action, 'payload.data.data.listCurrentAuditsForAuditor') ||
                _.get(action, 'data.cache.audits', []);
            newState.audits = mapAudits(audits.filter(auditShouldBeVisible));
            newState.pictureUrlsToDownload = getPictureUrls(audits);
            return newState;
        }
        case actionTypes.FETCH_INIT_DATA_FOR_AD_HOC_AUDIT_SUCCESS: {
            const newState = _.cloneDeep(state);
            newState.auditSetup = {};
            newState.result = {};
            newState.adHocAuditForm = mapAdhocAuditFormData(action.payload.data.data.fetchInitDataForAdHocAudit);
            return newState
        }
        case actionTypes.SET_EMPTY_INIT_DATA_FOR_AD_HOC_AUDIT: {
            const newState = _.cloneDeep(state);
            newState.auditSetup = {};
            newState.result = {};
            return newState
        }
        case actionTypes.FETCH_INIT_DATA_FOR_AD_HOC_AUDIT_FAIL: {
            const newState = {...state};
            newState.adHocAuditForm = {};
            return newState
        }
        case actionTypes.EXECUTE_SCHEDULED_AUDIT: {
            const newState = _.cloneDeep(state);
            newState.adHocAuditForm = {};
            newState.result = {};
            newState.auditSetup.questions = mapQuestionsForExecution(
                action.data.audit.questions,
                action.data.audit.topics,
                action.data.audit.answers
            );
            newState.auditSetup.audit = action.data.audit;
            return newState;
        }
        case actionTypes.REVIEW_AUDIT: {
            const newState = _.cloneDeep(state);
            newState.adHocAuditForm = {};
            newState.auditSetup = {};
            newState.auditSetup.questions = mapQuestionsForExecution(
                action.data.audit.questions,
                action.data.audit.topics,
                action.data.audit.answers
            );
            newState.auditSetup.audit = action.data.audit;
            newState.auditSetup.result = action.data.audit.result;
            return newState;
        }
        case actionTypes.FINISH_REVIEW: {
            const newState = _.cloneDeep(state);
            newState.result.result = newState.auditSetup.result;
            newState.result.questionnaireName = newState.auditSetup.audit.title;
            newState.result.readOnly = true;
            newState.result.isLoading = false;
            newState.result.duration = newState.auditSetup.duration;
            newState.adHocAuditForm = {};
            newState.auditSetup = {};
            return newState;
        }
        case actionTypes.SUBMIT_AD_HOC_AUDIT_FORM: {
            const newState = {...state};
            const questionnaire = newState.adHocAuditForm.questionnaires.find(questionnaire => questionnaire.businessId === action.data.questionnaireId);
            newState.auditSetup.questions = mapQuestionsForExecution(
                _.sampleSize(
                    questionnaire.questions,
                    action.data.randomizedNumber
                ),
                questionnaire.topics
            );
            // newState.auditSetup.questionnaireName = questionnaire.name;
            newState.auditSetup.levelTitle = newState.adHocAuditForm.organizationLevel.shortName;
            newState.auditSetup.audit = mapQuestionnaireIntoAuditForExecution(questionnaire, newState.auditSetup.levelTitle);
            newState.auditSetup.questionnaireId = action.data.questionnaireId;
            // newState.auditSetup.duration = 'ADHOC';
            return newState;
        }
        case actionTypes.UPLOAD_FINISHED_AUDIT: {
            const newState = _.cloneDeep(state);
            newState.result.isLoading = true;
            return newState;
        }
        case actionTypes.UPLOAD_FINISHED_AUDIT_SUCCESS: {
            const newState = _.cloneDeep(state);
            newState.result.result = action.payload.data.data.createAdHocAudit.result;
            newState.result.questionnaireName = newState.auditSetup.questionnaireName;
            newState.result.isLoading = false;
            newState.adHocAuditForm = {};
            newState.auditSetup = {};
            return newState;
        }
        case actionTypes.UPLOAD_FINISHED_SCHEDULED_AUDIT: {
            const newState = _.cloneDeep(state);
            newState.result.isLoading = true;
            return newState;
        }
        case actionTypes.UPLOAD_FINISHED_SCHEDULED_AUDIT_TO_CACHE:
        case actionTypes.UPLOAD_FINISHED_SCHEDULED_AUDIT_SUCCESS: {
            const newState = _.cloneDeep(state);
            newState.result.result = _.get(action, 'payload.data.data.executeAudit.result') || _.get(action, 'data.result');
            newState.result.questionnaireName = newState.auditSetup.questionnaireName;
            newState.result.isLoading = false;
            newState.adHocAuditForm = {};
            newState.auditSetup = {};
            return newState;
        }
        default:
            return state;
    }
}

function mapQuestionnaireIntoAuditForExecution(questionnaire, levelTitle){
    return {
        type: questionnaire.auditType.label,
        title: questionnaire.name,
        status: 'OPEN',
        topics: questionnaire.topics,
        area: levelTitle, ///subtext
        duration: 'ADHOC'
    }
}

//TODO LM: Tests running in docker are retarded and there is no flatmap on the array....
function mapTopicsByQuestion(topics) {
    return _.isEmpty(topics) ? [] : topics.flatMap((topic) => {
        const topicIdObjects = topic.questionIds.map(id => ({
            questionId: id,
            topicId: topic.businessId,
            subTopicId: undefined
        }));
        const subTopicIdObjects = topic.children.flatMap(subTopic => {
            return subTopic.questionIds.map(id => ({
                questionId: id,
                topicId: topic.businessId,
                subTopicId: subTopic.businessId
            }));
        });
        return [...topicIdObjects, ...subTopicIdObjects];
    });
}

export function mapQuestionsForExecution(questions, topics = [], answers = []) {

    const topicsByQuestion = mapTopicsByQuestion(topics);

    return sortByBusinessId(questions.map(question => {
        const topicToQuestion = topicsByQuestion.find(topicByQuestion => topicByQuestion.questionId === question.businessId);
        const topicPosition = topicToQuestion ? {
            topicId: topicToQuestion.topicId,
            subTopicId: topicToQuestion.subTopicId
        } : {};
        const answerFromBackend = answers.find(answerInArray => answerInArray.question.businessId === question.businessId) || {};
        const answer = {
            comment: _.get(answerFromBackend, 'comment', '') || '',
            point: _.get(answerFromBackend, 'value.point', ''),
            severity: _.get(answerFromBackend, 'severity', '') || '',
            language: _.get(answerFromBackend, 'language.code', '') || {},
            attachments: _.get(answerFromBackend, 'attachments', [])
        };
        return {
            businessId: question.businessId,
            translations: question.translations.map(translation => {
                return {
                    languageCode: translation.languageCode,
                    question: translation.question,
                    comment: translation.comment,
                    picture: translation.picture,
                }
            }),
            answer,
            possibleAnswers: _.orderBy(question.answerType.values,
                question.answerType.values.length > 2 ? ['point'] : [value => value.label.toLowerCase()],
                ['desc'])
                .map(answer => ({...answer})),
            ...(topics && topicPosition)
        }
    }));
}

export function mapAnswersForReview(answers, topics) {
    const topicsByQuestion = mapTopicsByQuestion(topics);

    return sortByBusinessId(answers.map(answer => {
        const topicToQuestion = topicsByQuestion
            .find(topicByQuestion => topicByQuestion.questionId === answer.question.businessId);
        const topicPosition = topicToQuestion ? {
            topicId: topicToQuestion.topicId,
            subTopicId: topicToQuestion.subTopicId
        } : {};
        return {
            businessId: answer.question.businessId,
            translations: answer.question.translations.map(translation => {
                return {
                    languageCode: translation.languageCode,
                    question: translation.question,
                    comment: translation.comment,
                    picture: translation.picture,
                }
            }),
            answer: {
                comment: answer.comment,
                point: answer.value.point,
                severity: answer.severity,
                language: _.get(answer, 'language.code', '') || {},
                attachments: answer.attachments || []
            },
            possibleAnswers: _.orderBy(answer.question.answerType.values, [value => value.label.toLowerCase()], ['desc'])
                .map(answerType => ({...answerType})),
            ...(topics && topicPosition)
        }
    }));
}

export function sortByBusinessId(array) {
    return _.sortBy(array, ['businessId']);
}

export function mapAudits(listedAudits) {
    return listedAudits.map(listedAudit => (
            {
                ...listedAudit,
                businessId: listedAudit.businessId,
                status: listedAudit.status,
                questions: listedAudit.questions.map(question => {
                    return {
                        businessId: question.businessId,
                        translations: question.translations.map(translation => {
                            return {
                                languageCode: translation.language.code,
                                question: translation.question,
                                comment: translation.comment,
                                picture: translation.picture,
                            }
                        }),
                        answerType: {
                            values: question.answerType.values.map(value => {
                                return {
                                    point: value.point,
                                    label: value.label
                                };
                            })
                        }
                    }
                }),
                result: listedAudit.result,
                duration: listedAudit.durationType,
                answers: listedAudit.answers,
                schedule: mapSchedule(listedAudit.cycle.label),
                scheduleName: _.get(listedAudit, 'schedule.name'),
                title: listedAudit.type,
                numberOfQuestions: listedAudit.questions.length,
                area: listedAudit.organizationLevel.shortName,
                creationDate: listedAudit.creationDate,
                activationDate: listedAudit.activationDate,
                dueDate: listedAudit.dueDate,
                finishedDate: listedAudit.finishedDate,
                topics: listedAudit.topics,
                progress: mapProgress(new Date(listedAudit.creationDate), new Date(listedAudit.dueDate), new Date())
            }
        )
    );
}

export function getPictureUrls(audits) {
    var urls = [];
    audits.forEach(audit => {
        audit.questions.forEach(question => {
            question.translations.forEach(translation => {
                if (translation.picture) {
                    urls.push(translation.picture.downloadUrl)
                }
            })
        });
        audit.answers.forEach(answer => {
            answer.attachments.forEach(attachment => {
                if (attachment.downloadUrl) {
                    urls.push(attachment.downloadUrl);
                }
            })
        });
    });
    return urls;
}

export function mapProgress(creationDate, dueDate, currentDate) {
    if (moment(dueDate).isBefore(currentDate)) {
        return 100;
    }
    const totalTime = dueDate.getTime() - creationDate.getTime();
    const currentTime = dueDate.getTime() - currentDate.getTime();
    const elapsedTime = totalTime - currentTime;
    return (elapsedTime > 0) && (totalTime > 0) ? elapsedTime / totalTime * 100 : 0;
}


function mapArea(organizationLevel) {
    if (organizationLevel.parentLevel) {
        return organizationLevel.parentLevel.shortName + " \\ " + organizationLevel.shortName;
    }
    return organizationLevel.shortName;
}

function mapSchedule(label) {
    return label.charAt(0).toUpperCase()
}

function mapQuestionnairesForAdHocAudit(questionnaires) {
    return questionnaires.map(questionnaire => {
        return {
            ...questionnaire,
            name: questionnaire.name,
            businessId: questionnaire.businessId,
            questions: questionnaire.questions.map(question => {
                return {
                    businessId: question.businessId,
                    translations: question.translations.map(translation => {
                        return {
                            languageCode: translation.language.code,
                            comment: translation.comment,
                            question: translation.question,
                            picture: translation.picture,
                        }
                    }),
                    answerType: {
                        values: question.answerType.values.map(value => {
                            return {
                                point: value.point,
                                label: value.label
                            };
                        })
                    }
                }
            })
        }
    });
}

function mapAdhocAuditFormData(adhocAuditInitData) {
    return {
        organizationLevel: adhocAuditInitData.organizationLevel,
        levelId: adhocAuditInitData.organizationLevel.businessId,
        levelTitle: mapArea(adhocAuditInitData.organizationLevel),
        questionnaires: mapQuestionnairesForAdHocAudit(adhocAuditInitData.questionnaires)
    }
}

export function auditShouldBeVisible() {
    const now = moment();
    return audit => now.isSameOrBefore(moment(audit.dueDate))
        && (audit.finishedDate === undefined || moment(audit.finishedDate).isAfter(now.startOf('day')));
}
