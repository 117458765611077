import React, {Component} from 'react';
import {withStyles} from '@material-ui/core';
import Header from 'components/header/Header';
import Flex from 'components/grid/Flex';
import {withRouter} from 'react-router-dom';
import {content} from 'components/zindex/zIndex';

const styles = theme => ({
    appFrame: {
        height: '100%',
        position: 'relative',
        fontFamily: 'Arial',
    },
    toolbar: theme.mixins.toolbar,
    content: {
        zIndex: content,
        height: '50%',
        width: '100%',
    },
    contentContainer: {
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
    },
    image: {
        objectFit: 'cover',
        height: 300,
        '@media (min-width: 1300px)': {
            height: 350,
        },
    }
});

class LandingHeaderLayout extends Component {

    render() {
        const {classes} = this.props;
        return (
            <Flex container className={classes.appFrame} direction={'column'}>
                <Flex container item basis={'100%'} justifyContent={'center'} alignItems={'center'} className={classes.contentContainer}>
                    <main className={classes.content}>
                        {this.props.children}
                    </main>
                </Flex>
                <Header {...{
                    isMobile: false,
                    isTitleVisible: false
                }}/>
            </Flex>
        );
    }
}

export default withStyles(styles, {withTheme: true})(withRouter(LandingHeaderLayout));
