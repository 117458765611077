import * as actionTypes from './Schedules.action.types';
import _ from 'lodash';
import {mapSchedulesForListing, EXPIRED, DURATION_UNITS, SCHEDULE_STATUSES} from "domain/audit/Schedule.model";

const initialState = {
    schedules: [],
    auditors: [],
    questionnaires: []
};

export default function (state = initialState, action) {
    switch (action.type) {
        case actionTypes.FETCH_SCHEDULES_SUCCESS: {
            const newState = _.cloneDeep(state);
            if (_.get(action, 'payload.data.data.listAuditSchedules')) {
                const schedules = mapSchedulesForListing(action.payload.data.data.listAuditSchedules);
                newState.schedules = schedules
                    .sort(sortByName)
                    .sort(sortByFrequencyAmount)
                    .sort(sortByDuration)
                    .sort(sortByStatus)
                    .sort(sortExpiredOneTimeOnlyLast)
                ;
                newState.auditors = getAuditors(schedules);
            }
            return newState;
        }
        case actionTypes.FETCH_QUESTIONNAIRES_SUCCESS: {
            const newState = _.cloneDeep(state);
            newState.questionnaires = _.get(action, 'payload.data.data.listQuestionnairesForOrganizationLevel', []);
            return newState;
        }
        default:
            return state;
    }
}

function getAuditors(schedules) {
    const auditors = schedules.filter(schedule => !_.isEmpty(schedule.auditor)).map(schedule => schedule.auditor);
    return _.uniqBy(auditors, 'businessId');
}

export function sortByStatus(a, b) {
    if (SCHEDULE_STATUSES.indexOf(a.status) > SCHEDULE_STATUSES.indexOf(b.status)) {
        return 1;
    } else if (SCHEDULE_STATUSES.indexOf(a.status) < SCHEDULE_STATUSES.indexOf(b.status)) {
        return -1;
    } else {
        return 0;
    }
}

export function sortExpiredOneTimeOnlyLast(a, b) {
    const expiredOnetimeOnly = x => x.status === EXPIRED && _.isEmpty(x.frequency);
    if (expiredOnetimeOnly(a) || expiredOnetimeOnly(b)) {
        return expiredOnetimeOnly(a) ? 1 : -1;
    }
    return 0;
}

export function sortByName(a, b) {
    if (a.name.toLowerCase() < b.name.toLowerCase()) {
        return -1;
    } else if (a.name.toLowerCase() > b.name.toLowerCase()) {
        return 1;
    }
    return 0;
}

export function sortByDuration(a, b) {
    if (DURATION_UNITS.indexOf(a.duration) > DURATION_UNITS.indexOf(b.duration)) {
        return -1;
    } else if (DURATION_UNITS.indexOf(a.duration) < DURATION_UNITS.indexOf(b.duration)) {
        return 1;
    } else {
        return 0;
    }
}

export function sortByFrequencyAmount(a, b) {
    if (a.frequency.amount > b.frequency.amount) {
        return 1;
    } else if (a.frequency.amount === undefined && b.frequency.amount !== undefined) {
        return 1
    } else if (a.frequency.amount < b.frequency.amount) {
        return -1;
    } else if (a.frequency.amount !== undefined && b.frequency.amount === undefined) {
        return -1;
    } else {
        return 0;
    }
}

