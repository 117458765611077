import React, {Component} from 'react';
import {IconButton, CircularProgress} from "@material-ui/core";
import AttachmentIcon from '@material-ui/icons/AttachFile';
import Attachment from './Attachment';
import UnseenNumber from 'components/unseen/UnseenNumber';
import _ from 'lodash';

export class AttachmentIndicator extends Component {

    state = {
        isIndicator: true
    };

    loadAttachmentComponent = () => {
        this.setState({isIndicator: false});
        _.get(this.props, 'onOpen') && this.props.onOpen();
    };

    render() {
        const {
            onOpen, classes, referenceType, id, numberOfAttachments, files, isUnseen, iconColor, isLoading, refresh
        } = this.props;

        return (
            this.state.isIndicator
                ?
                <IconButton style={{padding: 2, fontSize: 12, color: iconColor}} onClick={this.loadAttachmentComponent}>
                    <AttachmentIcon/>
                    <UnseenNumber {...{isUnseen, number: numberOfAttachments, seenColor: iconColor}}/>
                </IconButton>
                :
                isLoading
                    ?
                    <CircularProgress size={18} style={{margin: 5}}/>
                    :
                    <Attachment
                        {...{
                            id,
                            files,
                            referenceType,
                            classes,
                            uploadEnabled: true,
                            onOpen,
                            forceOpen: true,
                            iconColor,
                            onSuccessfulFileUpload: refresh,
                            onSuccessfulFileDelete: refresh
                        }}
                    />
        );
    }
}

export default AttachmentIndicator;
