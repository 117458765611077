import {
    getTemporaryActionReferenceId,
    createAction,
    editAction,
    fetchAction,
    fetchProject
} from './ActionEdit.action'

import {
    removeUnseenContentTag,
    archiveAction
} from 'scenes/tasks/actions/action-list/ActionList.action'

export default {
    mapStateToProps: (state) => {
        return {
            action: state.actionEditReducer.action,
            comments: state.actionEditReducer.action.comments,
            task: state.taskEditReducer.task,
            users: state.tasksReducer.configuration.users,
            user: state.mainReducer.user,
            newActionRefId: state.actionEditReducer.newActionRefId,
            applicationOnline: state.mainReducer.online,
            project: state.actionEditReducer.project,
        };
    },
    mapDispatchToProps: (dispatch) => {
        return {
            getTemporaryActionReferenceId: ()=> dispatch(getTemporaryActionReferenceId()),
            fetchAction: (actionBusinessId, taskBusinessId) => dispatch(fetchAction(actionBusinessId, taskBusinessId)),
            createAction: (taskId, action) => dispatch(createAction(taskId, action)),
            editAction: (action, forceTranslate) => dispatch(editAction(action, forceTranslate)),
            removeUnseenContentTag: action => dispatch(removeUnseenContentTag(action)),
            archiveAction: action => dispatch(archiveAction(action)),
            fetchProject: projectBusinessId => dispatch(fetchProject(projectBusinessId))
        };
    }
};
