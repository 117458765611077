import React, {Component, Fragment} from 'react';
import {
    withStyles,
    IconButton,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Typography,
} from "@material-ui/core";
import AttachmentIcon from '@material-ui/icons/AttachFile';
import SearchIcon from '@material-ui/icons/Search';
import Dropzone from 'react-dropzone';
import DropAreaImage from 'assets/img/drop-area.png';
import {withTranslation} from "react-i18next";
import FileGallery from 'components/gallery/FileGallery';
import _ from 'lodash';
import H4InputWithAdornment from 'components/input/H4InputWithAdornment';
import Flex from 'components/grid/Flex';
import UnseenNumber from 'components/unseen/UnseenNumber';
import {htmlGrey, htmlRed} from 'components/colors/Colors';
import configs from "configs/Configs";

const styles = theme => ({
    iconButtonRoot: {
        padding: 2,
        fontSize: 12,
    },
    dialogContainer: {
        minHeight: 300,
        minWidth: 600
    },
    mobileDialogContainer: {
        minHeight: 300,
        minWidth: '95%'
    },
    dialogActionsRoot: {
        justifyContent: 'center',
        flexDirection: 'column',
        marginTop: 10
    },
    dropDownImage: {},
    filename: {
        marginTop: 10
    },
    dragAndDropText: {
        color: htmlGrey,
        fontStyle: 'italic'
    },
    warningText: {
        color: htmlRed,
        fontStyle: 'italic'
    },
    contentRoot: {
        padding: 0,
        margin: 5
    }
});

export class AttachmentDialog extends Component {

    render() {
        const {
            classes, t: translate, isMobile, isAttachmentOpen, allFiles, filterText, isInvalidFile, openAttachment,
            closeAttachment, preventEventPropagation, handleChange, deleteAttachment, onDrop, uploadEnabled, isUnseen,
            iconColor
        } = this.props;
        const filteredFiles = _.sortBy(
            allFiles.filter(file => file.filename.toUpperCase().includes(filterText.toUpperCase())),
            'creationDate'
        );
        const numberOfFiles = _.isEmpty(allFiles) ? 0 : allFiles.length;
        return (
            <Fragment>
                <IconButton classes={{root: classes.iconButtonRoot}} onClick={openAttachment}
                            style={{color: iconColor}}>
                    <AttachmentIcon/>
                    <UnseenNumber {...{isUnseen, number: numberOfFiles, seenColor: iconColor}}/>
                </IconButton>
                <Dialog
                    open={isAttachmentOpen}
                    onClose={closeAttachment}
                    aria-labelledby="form-dialog-title-attachment"
                    classes={{
                        paper: isMobile ? classes.mobileDialogContainer : classes.dialogContainer
                    }}
                    maxWidth={numberOfFiles < 6 ? 'sm' : 'lg'}
                    onClick={preventEventPropagation}
                >
                    <DialogTitle id="form-dialog-title-attachment">{translate("attachments.title")}</DialogTitle>
                    <DialogContent classes={{root: classes.contentRoot}}>
                        <Flex item={'1 1 auto'} style={{padding: '0 15px'}}>
                            <H4InputWithAdornment
                                value={filterText}
                                placeholder={translate('global.search')}
                                onChange={handleChange}
                                name={'filterText'}
                                id="filterText-input"
                                fullWidth
                                startAdornment={<SearchIcon/>}
                            />
                        </Flex>
                        <FileGallery
                            sources={filteredFiles}
                            deleteSource={deleteAttachment}
                        />
                    </DialogContent>
                    <DialogActions classes={{
                        root: classes.dialogActionsRoot
                    }}> {
                        uploadEnabled &&
                        <Fragment>
                            <Dropzone onDrop={onDrop}>
                                {({getRootProps, getInputProps, isDragActive}) => {
                                    return (
                                        <div {...getRootProps()}>
                                            <input {...getInputProps()} />
                                            {
                                                <img className={classes.dropDownImage} src={DropAreaImage}
                                                     alt={''}/>
                                            }
                                        </div>
                                    )
                                }}
                            </Dropzone>
                            <Typography
                                className={classes.dragAndDropText}>{translate("attachments.dragAndDrop")}</Typography>
                            {
                                isInvalidFile &&
                                <Typography
                                    className={classes.warningText}>
                                    {translate("attachments.uploadWarning", {
                                        limit: (configs.getMaxFileUploadSizeInBytes() / 1024 / 1024)
                                    })}
                                </Typography>
                            }
                        </Fragment>
                    }
                    </DialogActions>
                </Dialog>
            </Fragment>
        );
    }
}

export default withStyles(styles)(withTranslation()(AttachmentDialog));
