import React, {Component, PureComponent, Fragment} from 'react';
import {
    withStyles,
    Checkbox,
    Typography,
} from "@material-ui/core";
import Flex from 'components/grid/Flex';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import {Trans} from "react-i18next";
import TreeFilterLeaf from './TreeFilterLeaf';
import _ from 'lodash';
import {
    christiGreen,
    transparentDownRiver025,
    htmlWhite,
    htmlGrey,
    nodeUnselectedColor,
    regentGrey
} from 'components/colors/Colors';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ExpandLess from '@material-ui/icons/ExpandLess';
import {treeIndex} from 'components/zindex/zIndex'

export const TREE_FILTER_WIDTH = 255;

const styles = theme => ({
    container: {
        overflow: 'auto',
        overflowScrolling: 'touch',
        boxShadow: `1px 0px 10px 0px ${transparentDownRiver025}`,
        position: 'fixed',
        top: 0,
        bottom: 0,
        minWidth: TREE_FILTER_WIDTH,
        width: TREE_FILTER_WIDTH,
        backgroundColor: nodeUnselectedColor,
        zIndex: treeIndex,
    },
    checkBoxIcon: {
        fill: christiGreen
    },
    treeIndicatorBox: {
        position: 'fixed',
        top: 0,
        bottom: 0,
        width: 60,
        minWidth: 25,
        backgroundColor: htmlWhite,
        boxShadow: `1px 0px 10px 0px ${transparentDownRiver025}`,
    },
    expandContainer: {
        minWidth: 14,
        position: 'fixed',
        top: 0,
        bottom: 0,
        zIndex: treeIndex,
    },
    expandIcon: {
        fill: htmlGrey,
        transform: 'rotate(90deg)',
        backgroundColor: htmlWhite,
        borderRadius: '50%',
        boxShadow: `1px 0px 10px 0px ${transparentDownRiver025}`,
        border: `1px solid ${transparentDownRiver025}`,
        zIndex: treeIndex,
        transition: '0.1s',
        fontSize: 28,
        '&:hover': {
            cursor: 'pointer',
            boxShadow: `0px 0px 3px 1px ${transparentDownRiver025}`,
        }
    },
    nodeTitle: {
        transform: 'rotate(-90deg)',
        transformOrigin: 'top left',
        marginLeft: -65,
        marginTop: 55,
        fontWeight: 'bold',
        fontSize: 26,
        color: regentGrey,
        zIndex: treeIndex,
    }
});

export class TreeFilterNode extends PureComponent {

    render() {
        const {
            node, nodes, toggleSelection, toggleOpen, editNode, RootLeafComponent, multiSelect, applicationOnline,
            noEdit, noFirstChildMargin, isDroppable
        } = this.props;
        const childNodes = nodes.filter(nodeInFilter => _.get(nodeInFilter, 'parent.businessId') === node.businessId);
        return (

            <Flex item={'0 0 auto'} container direction={'column'}
                  style={{backgroundColor: htmlWhite}}>
                {
                    RootLeafComponent
                        ? <RootLeafComponent {...{node}}/>
                        : <TreeFilterLeaf {...{
                            node,
                            isOpenAble: childNodes.length !== 0,
                            toggleOpen,
                            toggleSelection,
                            editNode,
                            multiSelect,
                            applicationOnline,
                            noEdit,
                            isDroppable
                        }}/>
                }
                {
                    node.open &&
                    <Flex item container direction={'column'}
                          style={{marginLeft: RootLeafComponent && noFirstChildMargin ? 0 : 23}}>
                        {
                            childNodes.map(nodeInArray =>
                                <TreeFilterNode {...{
                                    key: nodeInArray.businessId,
                                    node: nodeInArray,
                                    nodes,
                                    toggleSelection,
                                    toggleOpen,
                                    editNode,
                                    multiSelect,
                                    applicationOnline,
                                    noEdit,
                                    isDroppable
                                }}/>
                            )
                        }
                    </Flex>
                }
            </Flex>

        );
    }
}

const TreeContent = props => {
    const {
        classes, nodes, isAllSelected, toggleSelectAll, toggleSelection, toggleOpen, editNode, multiSelect, isDroppable,
        RootLeafComponent, applicationOnline, noEdit, customSelectedAll, noFirstChildMargin, containerStyle, style
    } = props;
    const rootNodes = nodes.filter(node => !node.parent);
    return (
        <Flex item={'0 0 255px'} style={{position: 'relative', ...containerStyle}}>
            <Flex container direction={'column'} className={classes.container} style={{...style}}>
                {
                    multiSelect && !customSelectedAll &&
                    <Flex item grow={0} container alignItems={'center'}>
                        <Checkbox
                            checked={isAllSelected}
                            style={{padding: 5, marginRight: 5}}
                            onChange={toggleSelectAll}
                            icon={<CheckBoxOutlineBlankIcon style={{fontSize: 20}}/>}
                            checkedIcon={<CheckBoxIcon style={{fontSize: 20}} className={classes.checkBoxIcon}/>}
                        />
                        <Typography><Trans i18nKey={"global.selectAll"}/></Typography>
                    </Flex>
                }
                {
                    rootNodes && rootNodes.map(rootNode =>
                        <TreeFilterNode {...{
                            key: rootNode.businessId,
                            node: rootNode,
                            nodes,
                            toggleSelection,
                            toggleOpen,
                            editNode,
                            RootLeafComponent,
                            multiSelect,
                            applicationOnline,
                            noEdit,
                            noFirstChildMargin,
                            isDroppable
                        }}/>
                    )
                }
            </Flex>
        </Flex>
    )
};

export class TreeFilter extends Component {

    render() {
        const {
            classes, nodes, isAllSelected, toggleSelectAll, toggleSelection, toggleOpen, editNode, multiSelect,
            RootLeafComponent, applicationOnline, containerStyle, style, noEdit, customSelectedAll, open, toggleTree, forceOpen,
            noFirstChildMargin, selectedNode, showNodeTitle, isDroppable
        } = this.props;
        const nodeTitle = _.get(selectedNode, 'title', "");
        const nodeTitleOffset = nodeTitle.length * 17;
        return (
            <Fragment>
                {

                    (open || forceOpen)
                        ?
                        <TreeContent {...{
                            classes,
                            nodes,
                            isAllSelected,
                            toggleSelectAll,
                            toggleSelection,
                            toggleOpen,
                            editNode,
                            multiSelect,
                            RootLeafComponent,
                            applicationOnline,
                            containerStyle,
                            style,
                            noEdit,
                            customSelectedAll,
                            open,
                            toggleTree,
                            forceOpen,
                            noFirstChildMargin,
                            isDroppable
                        }}/>
                        :
                        <div className={classes.treeIndicatorBox}/>
                }
                {
                    !forceOpen &&
                    <Flex item={'0 0 auto'} container style={{position: 'relative', width: open ? 14 : 60}}>
                        <Flex item={'0 0 auto'} container alignItems={'center'} className={classes.expandContainer}
                              onClick={toggleTree}>
                            {
                                open
                                    ?
                                    <ExpandMore className={classes.expandIcon} style={{marginLeft: -14}}/>
                                    :
                                    <ExpandLess className={classes.expandIcon} style={{marginLeft: 46}}/>
                            }
                            {
                                !open && showNodeTitle &&
                                <Typography className={classes.nodeTitle}
                                            style={{marginTop: nodeTitleOffset}}>{nodeTitle}</Typography>
                            }
                        </Flex>
                    </Flex>
                }
            </Fragment>
        );
    }
}

export default withStyles(styles)(TreeFilter);
