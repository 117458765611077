import React, {Component} from 'react';
import {
    Typography,
    withStyles,
    IconButton,
    Tabs,
    Tab,
    InputLabel,
    TextField,
    InputAdornment,
    ListItemText,
} from "@material-ui/core";
import _ from 'lodash';
import moment from 'moment';
import ids from 'components/ids/ids';
import {Autocomplete} from '@material-ui/lab';
import IosSwitch from 'components/switch/IOSSwitch';
import utils from 'utils/Utils';
import {
    silver,
    htmlOrange,
    guardsmanRed,
    textInputFieldBorder,
    htmlWhite,
    blackPearl,
    textField,
    htmlGrey
} from 'components/colors/Colors';
import Flex from 'components/grid/Flex';
import {Link, Route} from 'react-router-dom';
import {Trans, withTranslation} from "react-i18next";
import TranslationChangedDialog from 'scenes/tasks/components/TranslationChangedDialog';
import {taskEditDescription} from 'components/zindex/zIndex';
import {projectBreadCrumbs} from 'routes/routes';
import {
    Done as DoneIcon,
    Delete as DeleteIcon,
    ArrowBack as BackIcon,
    ArrowDropDown as OpenIcon
} from '@material-ui/icons';
import ConfirmationDialog from "components/confirmation-dialog/ConfirmationDialog";
import Title from 'components/title/Title';
import DesktopActionEditComments from './DesktopActionEditComments';
import H4Avatar from "components/avatar/H4Avatar";
import QuillEditor from 'components/quill-editor/QuillEditor';
import taskUtils from 'scenes/tasks/Task.utils';
import {LinearProgress} from "@material-ui/core";
import CalendarPage from "components/calendar/CalendarPage";
import DueDateProgressBar from "components/progress-bar/DueDateProgressBar";

const styles = theme => ({
    inline: {
        display: 'inline',
        whiteSpace: 'nowrap',
        marginLeft: 5,
        color: silver,
    },
    overFlowDays: {
        display: 'inline',
        color: guardsmanRed,
        padding: '0px 0px 0px 5px'
    },
    leftColumn: {
        display: 'flex',
        flex: '4 4 0px',
        paddingRight: 15,
        '@media (min-width: 1900px)': {
            flex: '5 5 0px',
        },
        flexDirection: 'column',
    },
    rightColumn: {
        display: 'flex',
        flex: '2 2 0px',
        '@media (min-width: 1000px)': {
            flex: '1 1 0px',
        },
        flexDirection: 'column',
    },
    inputItem: {
        padding: '15px 7px',
    },
    smallInputItem: {
        padding: 5,
    },
    fieldTitle: {
        color: htmlGrey,
        textTransform: 'capitalized',
        fontSize: 16
    },
    titleBarRoot: {
        zIndex: 6,
        marginLeft: 60,
        position: 'fixed',
        left: 0,
        right: 0,
        marginTop: 0
    },
    titleBarContainer: {
        minHeight: 45,
        color: blackPearl,
    },
    tabIndicator: {
        backgroundColor: blackPearl,
    },
    noBorderInput: {
        border: 'unset',
        fontWeight: 'bold',
        cursor: 'pointer'
    },
    noBorder: {
        border: 'unset'
    },
    tabSelected: {
        fontSize: 18,
        fontWeight: 'bold'
    },
    tabRoot: {
        textTransform: 'capitalize'
    }
});

const TitleBar = props => {
    const {
        classes, translate, actionBusinessId, taskBusinessId, isSelected, breadCrumbs, routeProps, save,
        isActionEditable, isSaveDisabled, saveWithOptions, cancelDelete, deleteAction
    } = props;
    const breadCrumbsCopy = breadCrumbs ? _.cloneDeep(breadCrumbs) : [];
    const breadCrumbTail = breadCrumbsCopy.pop();
    const actionTitlePart = isSelected
        ? `${translate('global.action')}-${actionBusinessId.split('-')[1]}`
        : `${translate('global.new-with-type', {type: translate('global.action')})}`;
    const title = `${breadCrumbTail} #${taskBusinessId} - ${actionTitlePart}`;

    return (
        <Flex container item className={classes.titleBarRoot}
              style={{backgroundColor: textField}}>
            <Flex item container justifyContent={'space-between'} alignItems={'center'} padding={5}
                  className={classes.titleBarContainer}>
                <Flex item={'0 1 auto'} container alignItems={'center'}>
                    <Flex item={'0 1 auto'} style={{marginLeft: 5, marginRight: 15}}>
                        <IconButton
                            onClick={()=>{save(true)}}
                            id={ids.cancelButton}
                            style={{padding: 3}}
                        >
                            <BackIcon style={{color: "inherit"}}/>
                        </IconButton>
                    </Flex>
                    <Flex item={'0 0 auto'}>
                        <Typography variant="h6" id={ids.editId} style={{color: blackPearl}}
                                    onClick={isSelected ? () => {
                                        utils.copyToClipboard(actionBusinessId)
                                    } : () => {
                                    }}
                        >
                            {title}
                        </Typography>
                    </Flex>
                </Flex>
                <Flex item={'1 1 auto'} container justifyContent={'flex-start'}
                      style={{paddingLeft: 10, marginLeft: 10}}>
                </Flex>

                <Flex item={'0 1 auto'} container style={{paddingRight: 14}}>
                    {
                        isSelected &&
                        <IconButton
                            component={Link}
                            to={utils.joinUrl(routeProps.match.url, '/delete')}
                            disabled={!isActionEditable || !isSelected}
                            style={{
                                padding: 3, marginRight: 30
                            }}
                        >
                            <DeleteIcon style={{color: "inherit"}}/>
                        </IconButton>
                    }
                    <IconButton
                        onClick={save}
                        disabled={isSaveDisabled}
                        style={{padding: 3}}
                    >
                        <DoneIcon style={{color: "inherit"}}/>
                    </IconButton>
                </Flex>

            </Flex>
            <Route path={routeProps.match.url + '/overwrite'} render={

                function renderTranslationOverWriteDialog(props) {
                    return <TranslationChangedDialog
                        {...{
                            originUrl: routeProps.match.url,
                            saveWithOptions,
                        }}
                    />
                }
            }/>
            <Route path={routeProps.match.url + '/delete'} render={

                function renderDeleteTaskDialog(props) {
                    return <ConfirmationDialog {...{
                        dialogOpen: true,
                        onDialogClose: cancelDelete,
                        onConfirm: deleteAction,
                        confirmationTextKey: "task-management.deleteActionConfirmation"
                    }}/>
                }
            }/>
        </Flex>
    );
};

const Description = props => {
    const {
        classes, isEditable, matchingTranslation, handleDescriptionChange, translate
    } = props;
    return (
        <Flex item container direction={'column'} className={classes.inputItem}
              style={{minHeight: 300, paddingTop: 'unset'}}>
            <Flex item container grow={0} alignItems={'center'} style={{marginBottom: 5}}>
                <InputLabel shrink>{translate("task-management.action-edit.description")}</InputLabel>
            </Flex>
            <QuillEditor {...{
                text: matchingTranslation.description,
                isEditable,
                handleTextChange: handleDescriptionChange,
                containerStyle:{
                             border: `1px solid ${textInputFieldBorder}`,
                             borderRadius: 4,
                             zIndex: taskEditDescription,
                             backgroundColor: htmlWhite
                         }
            }}/>
        </Flex>
    )
};

const Urgent = (props) => {
    const {classes, priority, handlePriorityChange, isEditable} = props;
    return (
        <Flex item container className={classes.smallInputItem} justifyContent={'space-between'}>
            <Flex item container grow={0} alignItems={'center'}>
                <Typography className={classes.fieldTitle}>
                    <Trans i18nKey={"task-management.action-edit.urgent"}/>
                </Typography>
            </Flex>
            <IosSwitch
                onChange={handlePriorityChange}
                checked={priority === 'URGENT'}
                disabled={!isEditable}
            />
        </Flex>
    );
};

const Done = (props) => {
    const {classes, status, handleStatusChange, isEditable} = props;
    return (
        <Flex item container className={classes.smallInputItem} justifyContent={'space-between'}>
            <Flex item container grow={0} alignItems={'center'}>
                <Typography className={classes.fieldTitle}>
                    <Trans i18nKey={"task-management.action-edit.done"}/>
                </Typography>
            </Flex>
            <IosSwitch
                onChange={handleStatusChange}
                checked={status === 'DONE'}
                disabled={!isEditable}
            />
        </Flex>
    );
};

const SolveUntil = props => {
    const {classes, action, handleDueDateChange, isEditable, maxDueDate, translate} = props;
    return (
        <Flex container justifyContent={'space-between'} alignItems={'flex-end'} className={classes.inputItem}>
            <CalendarPage {...{
                title: translate("task-management.task-edit.created"),
                date: action.creationDate,
                name: 'creationDate',
                disabled: true
            }}/>
            <DueDateProgressBar value={action.dueDateProgress} style={{width: '45%', height: 8}}/>
            <CalendarPage {...{
                title: translate("task-management.task-edit.solveUntil"),
                date: action.dueDate,
                name: 'dueDate',
                onChange: handleDueDateChange,
                disabled: !isEditable,
                maxDate: maxDueDate
            }}/>
        </Flex>
    )
};

const ResponsibleField = props => {
    const {classes, users, handleResponsibleChange, isEditable, assignee, isPersonalAction, translate} = props;
    return (
        <div className={classes.inputItem}>
            <Autocomplete
                options={users}
                onChange={handleResponsibleChange}
                value={assignee}
                disableClearable
                disabled={isPersonalAction || !isEditable}
                getOptionSelected={(option, value) => {
                    return option.businessId === value.businessId
                }}
                getOptionLabel={user => utils.formattedUserName(user)}
                renderInput={params =>
                    <TextField {...params}
                               label={translate('task-management.action-edit.responsible')}
                               fullWidth
                               InputProps={{
                                   ...params.InputProps,
                                   endAdornment: <InputAdornment position={'end'}
                                                                 style={{position: 'absolute', right: -4, top: 14}}>
                                       <H4Avatar {...{user: assignee}}/>
                                       <IconButton style={{padding: 4}}>
                                           <OpenIcon/>
                                       </IconButton>
                                   </InputAdornment>
                               }}
                    />}
                renderOption={user =>
                    <Flex item container key={user.businessId}>
                        <ListItemText primary={utils.formattedUserName(user)}/>
                        <H4Avatar {...{user}}/>
                    </Flex>
                }
            />
        </div>
    )
};

const PreviousAssignee = props => {
    const {
        classes, translate, previousAssignee
    } = props;
    return (
        <div className={classes.inputItem}>
            <TextField
                value={utils.formattedUserName(previousAssignee)}
                label={translate('task-management.task-edit.previousAssignee')}
                disabled
                fullWidth
                InputProps={{
                    endAdornment: <InputAdornment position={'end'} style={{position: 'absolute', right: 28, top: 14}}>
                        <H4Avatar {...{user: previousAssignee}}/>
                    </InputAdornment>
                }}
            />
        </div>
    )
};

const EstimatedEffort = (props) => {
    const {classes, estimatedEffort, handleNumberChangeOnAction, isEditable, translate} = props;
    return (
        <Flex item container direction={'column'} className={classes.inputItem}>
            <TextField
                label={translate('task-management.action-edit.estimatedEffort')}
                value={estimatedEffort || ''}
                onChange={handleNumberChangeOnAction}
                name={'estimatedEffort'}
                type={'number'}
                InputProps={{
                    endAdornment: <InputAdornment position={'end'}>
                        <Typography
                            className={classes.inline}>
                            <Trans i18nKey={"task-management.action-edit.hours"}/>
                        </Typography>
                    </InputAdornment>
                }}
                disabled={!isEditable}
                fullWidth
            />
        </Flex>
    );
};

const TimeSpent = (props) => {
    const {classes, timeSpent, handleNumberChangeOnAction, isEditable, progress, progressRatio, translate} = props;
    return (
        <div className={classes.inputItem}>
            <TextField
                label={translate('task-management.action-edit.timeSpent')}
                value={timeSpent || ''}
                onChange={handleNumberChangeOnAction}
                name={'timeSpent'}
                type={'number'}
                disabled={!isEditable}
                fullWidth
                InputProps={{
                    endAdornment: <InputAdornment position={'end'}>
                        <Typography
                            className={classes.inline}
                            style={{color: progressRatio > progress ? htmlOrange : silver}}>
                            <Trans i18nKey={"task-management.action-edit.progressRatio"}
                                   values={{progressRatio}}/>
                        </Typography>
                    </InputAdornment>
                }}
            />
        </div>
    );
};

const FinishedAt = props => {
    const {classes, finishedDate, overFlowDays, translate} = props;
    return (
        <div className={classes.inputItem}>
            <TextField
                label={translate('task-management.action-edit.finishedAt')}
                disabled={true}
                fullWidth
                value={(moment(finishedDate).format(utils.DATE_FORMAT)) || ''}
                onChange={() => {
                }}
                InputProps={{
                    endAdornment: overFlowDays > 0 &&
                        <InputAdornment position={'end'}>
                            <Typography className={classes.overFlowDays}>
                                <Trans i18nKey={"task-management.task-edit.overflowDay"}
                                       values={{days: overFlowDays}}/>
                            </Typography>
                        </InputAdornment>
                }}
            />
        </div>
    )
};

const Progress = (props) => {
    const {classes, progress, handleProgressChange, isEditable, translate} = props;
    return (
        <Flex container direction={'column'} className={classes.inputItem}>
            <TextField
                label={translate('task-management.action-edit.progress')}
                value={progress}
                onChange={handleProgressChange}
                name={'progress'}
                type={'number'}
                disabled={!isEditable}
                fullWidth
                InputProps={{
                    endAdornment: <InputAdornment position={'end'}>
                        <Typography className={classes.inline}>%</Typography>
                    </InputAdornment>
                }}
            />
            <LinearProgress
                variant="determinate"
                style={{marginTop: 7, height: 18}}
                value={progress || 0}/>
        </Flex>
    );
};

export class DesktopActionEdit extends Component {

    state = {
        activityTab: 'Comments',
        goBackAfterSave: false
    };

    save = goBack => {
        taskUtils.unBoundSaveLogic.bind(this)(goBack);
    };

    saveWithOptions = forceTranslate => {
        taskUtils.unBoundSaveWithOptionsLogic.bind(this)(forceTranslate);
    };

    cancelDelete = () => {
        this.props.routeProps.history.goBack();
    };

    deleteAction = () => {
        this.props.archiveAction(this.props.action).then(() => {
            this.props.goBack();
        });
    };

    handleResponsibleChange = (event, value) => {
        this.props.handleResponsibleChange(value);
    };

    render() {
        const {
            classes, action, users, maxDueDate, projectBreadCrumb, isSelected, routeProps,
            hasEditRight, applicationOnline, handleNumberChangeOnAction, handleDueDateChange, handlePriorityChange,
            handleProgressChange, handleStatusChange, handleDescriptionChange, matchingTranslation, isSaveDisabled,
            handleTitleChange, comments, isPersonalAction, t: translate, goBack, fetchAction
        } = this.props;
        const {save, saveWithOptions, cancelDelete, deleteAction, handleResponsibleChange} = this;
        const breadCrumbs = projectBreadCrumbs(projectBreadCrumb);
        const isActionEditable = hasEditRight;
        const progressRatio = action.timeSpent && action.estimatedEffort
            ? parseInt(action.timeSpent / action.estimatedEffort * 100)
            : 0;
        const overFlowDays = action.dueDate && action.finishedDate
            ? moment(action.finishedDate).diff(moment(action.dueDate), 'days')
            : 0;
        const previousAssignee = _.get(action, 'delta.user', undefined);

        return action && (
            <Flex item container direction={'column'}>
                <TitleBar
                    {...{
                        classes, translate, goBack, breadCrumbs, routeProps, save, isActionEditable, saveWithOptions,
                        cancelDelete, deleteAction,
                        actionBusinessId: action.businessId,
                        taskBusinessId: action.taskBusinessId,
                        isSelected,
                        isSaveDisabled
                    }}
                />

                <Flex container item={'1 0 auto'} padding={10} direction={'column'} style={{marginTop: 45}}>
                    <Flex container item={'0 1 auto'}>
                        <div className={classes.leftColumn}>

                            <Title {...{
                                translate,
                                title: matchingTranslation.title,
                                isTranslated: matchingTranslation.origin === 'GOOGLE_TRANSLATE',
                                autofocus: !isSelected,
                                disabled: !isActionEditable,
                                handleTitleChange
                            }}/>

                            <Description {...{
                                classes,
                                translate,
                                isEditable: isActionEditable,
                                matchingTranslation,
                                handleDescriptionChange,
                            }}/>

                            {
                                isSelected &&
                                <Flex item container direction={'column'} padding={7}>
                                    <Tabs value={'Comments'} onChange={() => {
                                    }}
                                          classes={{indicator: classes.tabIndicator}}>
                                        {
                                            applicationOnline &&
                                            <Tab value={'Comments'}
                                                 classes={{
                                                     selected: classes.tabSelected,
                                                     root: classes.tabRoot
                                                 }}
                                                 label={`${translate("comment.title")}${comments.length ? ` (${comments.length})` : ''}`}/>
                                        }
                                    </Tabs>
                                    {
                                        this.state.activityTab === 'Comments' &&
                                        <DesktopActionEditComments fetchAction={fetchAction}/>
                                    }
                                </Flex>
                            }
                        </div>
                        <div className={classes.rightColumn}>
                            <Flex item={'0 1 auto'} container direction={'column'}>

                                <Urgent {...{
                                    classes,
                                    priority: action.priority.type,
                                    handlePriorityChange,
                                    isEditable: isActionEditable
                                }}
                                />

                                <Done {...{
                                    classes,
                                    status: action.status,
                                    handleStatusChange,
                                    isEditable: isActionEditable
                                }}
                                />

                                <SolveUntil {...{
                                    classes,
                                    translate,
                                    action,
                                    maxDueDate,
                                    handleDueDateChange,
                                    isEditable: isActionEditable,
                                }}/>

                                <ResponsibleField {...{
                                    classes,
                                    users,
                                    translate,
                                    handleResponsibleChange,
                                    isPersonalAction,
                                    isEditable: isActionEditable,
                                    assignee: action.assignee,
                                }}/>

                                {
                                    previousAssignee &&
                                    (previousAssignee.businessId !== _.get(action, 'assignee.businessId')) &&
                                    <PreviousAssignee {...{
                                        classes,
                                        translate,
                                        previousAssignee,
                                    }}/>
                                }

                                <EstimatedEffort {...{
                                    classes,
                                    translate,
                                    estimatedEffort: action.estimatedEffort || '',
                                    handleNumberChangeOnAction,
                                    isEditable: isActionEditable
                                }}/>

                                <TimeSpent {...{
                                    classes,
                                    translate,
                                    timeSpent: action.timeSpent || '',
                                    handleNumberChangeOnAction,
                                    isEditable: isActionEditable,
                                    progress: action.progress,
                                    progressRatio
                                }}/>

                                <Progress {...{
                                    classes,
                                    translate,
                                    progress: action.progress,
                                    handleProgressChange,
                                    isEditable: isActionEditable
                                }}/>

                                {
                                    action.finishedDate &&
                                    <FinishedAt {...{
                                        classes,
                                        translate,
                                        finishedDate: action.finishedDate,
                                        overFlowDays
                                    }}/>
                                }

                            </Flex>

                        </div>
                    </Flex>
                </Flex>
            </Flex>
        );
    }
}

export default withStyles(styles)(withTranslation()(DesktopActionEdit));
