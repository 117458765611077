import React, {Component} from 'react';
import GoogleIcon from 'components/google-icon/GoogleIcon';
import H4TextField from 'components/h4-textfield/H4TextField';
import Flex from 'components/grid/Flex';
import ids from 'components/ids/ids';

export default class Title extends Component {
    state = {
        isInput: false
    };

    render() {
        const {
            style, disabled, autofocus, handleTitleChange, title, isTranslated, translate
        } = this.props;
        return (
            <div style={{...style, margin: '10px 0px', padding: 7}}>
                <H4TextField
                    placeholder={translate("task-management.task-edit.writeTitle")}
                    id={ids.editTitle}
                    disabled={disabled}
                    multiline
                    fullWidth
                    autoFocus={autofocus}
                    InputProps={{
                        style: {fontSize: 20, fontWeight: 'bold', cursor: 'text'},
                        disableUnderline: !!title,
                        startAdornment: isTranslated &&
                            <Flex container alignItems={'center'} style={{paddingRight: 10}}>
                                <GoogleIcon/>
                            </Flex>
                    }}
                    value={title}
                    name={'title'}
                    onChange={handleTitleChange}
                />
            </div>
        )
    }
}
