import React, {Component} from 'react';
import {withStyles, Snackbar, SnackbarContent, Typography, IconButton} from "@material-ui/core";
import {Trans} from "react-i18next";
import {medium, htmlWhite} from "components/colors/Colors";
import CloseIcon from '@material-ui/icons/Close';

const styles = ({
    snackbar: {
        paddingBottom: 10,
    },
    snackbarContent: {
        backgroundColor: medium,
        justifyContent: 'center'
    },
    snackbarText: {
        fontSize: 13,
        color: htmlWhite,
    },
    action: {
        margin: 0,
    },
    answer: {
        '&:hover': {
            cursor: 'pointer'
        }
    }
});

export class ConnectionLostMessage extends Component {
    render() {
        const {classes, open, onClose} = this.props;

        return (
            <Snackbar
                className={classes.snackbar}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                open={open}
                autoHideDuration={5000}
                onClose={onClose}
            >
                <SnackbarContent
                    className={classes.snackbarContent}
                    classes={{action: classes.action}}
                    message={
                        <div>
                            <Typography className={classes.snackbarText}>
                                <Trans i18nKey={"global.bad-internet-connection"}/>
                            </Typography>
                            <Typography className={classes.snackbarText}>
                                <Trans i18nKey={"global.go-offline"}/>
                            </Typography>
                        </div>
                    }
                    action={[
                        <IconButton key="close" aria-label="close" color="inherit" onClick={onClose}
                                    className={classes.snackbarText}
                                    style={{marginLeft: 15, padding: 7, marginRight: -10}}>
                            <CloseIcon style={{fontSize: 'inherit'}}/>
                        </IconButton>,
                    ]}
                />
            </Snackbar>
        )
    }
}

export default withStyles(styles)(ConnectionLostMessage)