import utils from 'utils/Utils';

export const TOPIC_GRAPHQL = `
    businessId
    name
    ordinal
    questionIds
    children {
        businessId
        name
        ordinal
        questionIds
    }
`;

export function newTopic(){
    return {
        businessId: utils.uuid(),
        name: '',
        questionIds: [],
        children: []
    }
}
