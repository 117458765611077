export const LIST_TASK_MANAGEMENT_TAGS = '@tasks/LIST_TASK_MANAGEMENT_TAGS';
export const LIST_TASK_MANAGEMENT_TAGS_SUCCESS = '@tasks/LIST_TASK_MANAGEMENT_TAGS_SUCCESS';
export const LIST_TASK_MANAGEMENT_TAGS_FAIL = '@tasks/LIST_TASK_MANAGEMENT_TAGS_FAIL';
export const LIST_TASK_MANAGEMENT_TAGS_FROM_CACHE = '@tasks/LIST_TASK_MANAGEMENT_TAGS_FROM_CACHE';

export const START_FETCHING_TASK_MANAGEMENT_DATA = '@tasks/START_FETCHING_TASK_MANAGEMENT_DATA';
export const DONE_FETCHING_TASK_MANAGEMENT_DATA = '@tasks/DONE_FETCHING_TASK_MANAGEMENT_DATA';

export const FETCH_TASK_CONFIGURATION = '@tasks/FETCH_TASK_CONFIGURATION';
export const FETCH_TASK_CONFIGURATION_SUCCESS = '@tasks/FETCH_TASK_CONFIGURATION_SUCCESS';
export const FETCH_TASK_CONFIGURATION_FAIL = '@tasks/FETCH_TASK_CONFIGURATION_FAIL';
export const FETCH_TASK_CONFIGURATION_FROM_CACHE = '@tasks/FETCH_TASK_CONFIGURATION_FROM_CACHE';

export const LIST_TASKS = '@tasks/LIST_TASKS';
export const LIST_TASKS_SUCCESS = '@tasks/LIST_TASKS_SUCCESS';
export const LIST_TASKS_FAIL = '@tasks/LIST_TASKS_FAIL';
export const LIST_TASKS_FROM_CACHE = '@tasks/LIST_TASKS_FROM_CACHE';

export const LIST_HISTORY_TASKS = '@tasks/LIST_HISTORY_TASKS';
export const LIST_HISTORY_TASKS_SUCCESS = '@tasks/LIST_HISTORY_TASKS_SUCCESS';
export const LIST_HISTORY_TASKS_FAIL = '@tasks/LIST_HISTORY_TASKS_FAIL';

export const SAVE_NEW_TASK_ORDER = '@tasks/SAVE_NEW_TASK_ORDER';
export const SAVE_NEW_TASK_COLUMN_ORDER = '@tasks/SAVE_NEW_TASK_COLUMN_ORDER';

export const FETCH_USERS = '@tasks/FETCH_USERS';
export const FETCH_USERS_SUCCESS = '@tasks/FETCH_USERS_SUCCESS';
export const FETCH_USERS_FAIL = '@tasks/FETCH_USERS_FAIL';
export const FETCH_USERS_FROM_CACHE = '@tasks/FETCH_USERS_FROM_CACHE';

export const REMOVE_UNSEEN_ATTACHMENT_TAG_TO_TASK = '@task_edit/REMOVE_UNSEEN_ATTACHMENT_TAG_TO_TASK';
export const REMOVE_UNSEEN_ATTACHMENT_TAG_TO_TASK_SUCCESS = '@task_edit/REMOVE_UNSEEN_ATTACHMENT_TAG_TO_TASK_SUCCESS';
export const REMOVE_UNSEEN_ATTACHMENT_TAG_TO_TASK_FAIL = '@task_edit/REMOVE_UNSEEN_ATTACHMENT_TAG_TO_TASK_FAIL';
export const REMOVE_UNSEEN_CHECKLIST_ITEM_TAG_TO_TASK = '@task_edit/REMOVE_UNSEEN_CHECKLIST_ITEM_TAG_TO_TASK';
export const REMOVE_UNSEEN_CHECKLIST_ITEM_TAG_TO_TASK_SUCCESS = '@task_edit/REMOVE_UNSEEN_CHECKLIST_ITEM_TAG_TO_TASK_SUCCESS';
export const REMOVE_UNSEEN_CHECKLIST_ITEM_TAG_TO_TASK_FAIL = '@task_edit/REMOVE_UNSEEN_CHECKLIST_ITEM_TAG_TO_TASK_FAIL';
export const REMOVE_UNSEEN_CONTENT_TAG_TO_TASK = '@task_edit/REMOVE_UNSEEN_CONTENT_TAG_TO_TASK';
export const REMOVE_UNSEEN_CONTENT_TAG_TO_TASK_SUCCESS = '@task_edit/REMOVE_UNSEEN_CONTENT_TAG_TO_TASK_SUCCESS';
export const REMOVE_UNSEEN_CONTENT_TAG_TO_TASK_FAIL = '@task_edit/REMOVE_UNSEEN_CONTENT_TAG_TO_TASK_FAIL';
export const REMOVE_UNSEEN_ACTION_TAG_TO_TASK = '@task_edit/REMOVE_UNSEEN_ACTION_TAG_TO_TASK';
export const REMOVE_UNSEEN_ACTION_TAG_TO_TASK_SUCCESS = '@task_edit/REMOVE_UNSEEN_ACTION_TAG_TO_TASK_SUCCESS';
export const REMOVE_UNSEEN_ACTION_TAG_TO_TASK_FAIL = '@task_edit/REMOVE_UNSEEN_ACTION_TAG_TO_TASK_FAIL';
export const REMOVE_UNSEEN_COMMENT_TAG_TO_TASK = '@task_edit/REMOVE_UNSEEN_COMMENT_TAG_TO_TASK';
export const REMOVE_UNSEEN_COMMENT_TAG_TO_TASK_SUCCESS = '@task_edit/REMOVE_UNSEEN_COMMENT_TAG_TO_TASK_SUCCESS';
export const REMOVE_UNSEEN_COMMENT_TAG_TO_TASK_FAIL = '@task_edit/REMOVE_UNSEEN_COMMENT_TAG_TO_TASK_FAIL';
