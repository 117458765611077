import * as _ from "lodash";

function getBackendServiceUrl() {
    try {
        return process.env.REACT_APP_BACKEND_URL ? process.env.REACT_APP_BACKEND_URL : window.appConfig.backendServiceUrl
    } catch (e) {
        console.log('window.appConfig.backendServiceUrl is not given');
        throw e;
    }
}

function getWebsocketBrokerUrl() {
    try {
        return process.env.REACT_APP_WEBSOCKET_URL ? process.env.REACT_APP_WEBSOCKET_URL : window.appConfig.websocketUrl
    } catch (e) {
        console.log('window.appConfig.websocketUrl is not given');
        throw e;
    }
}

function getAllowedLanguages() {
    return ['en', 'de', 'hu']
}

function getCustomLogo() {
    return _.get(window.appConfig, 'customLogo', {enabled: false});
}

function getCustomLogoText() {
    return _.get(window.appConfig, 'customLogoText', {enabled: false});
}

function getCustomBackgroundImagePath() {
    return _.get(window.appConfig, 'customBackgroundImagePath', '');
}

function getNamingConventions() {
    return _.get(window.appConfig, 'namingConventions', {});

}

function getTaskPrefix() {
    return _.get(getNamingConventions(), 'taskPrefix', 'Story');
}

function getActionPrefix() {
    return _.get(getNamingConventions(), 'actionPrefix', 'Task');
}

function getDeviationPrefix() {
    return _.get(getNamingConventions(), 'deviationPrefix', 'Dev');
}

function getMaxFileUploadSizeInBytes() {
    return _.get(window.appConfig, 'maxFileUploadSize', 50 * 1024 * 1024); // 50 MB
}

const configs = {
    getTaskPrefix,
    getActionPrefix,
    getBackendServiceUrl,
    getWebsocketBrokerUrl,
    getAllowedLanguages,
    getCustomLogo,
    getDeviationPrefix,
    getMaxFileUploadSizeInBytes,
    getCustomLogoText,
    getCustomBackgroundImagePath
};

export default configs;