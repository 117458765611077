import React, {Component} from "react";
import {Button, Dialog, DialogActions, DialogTitle, withStyles} from "@material-ui/core";
import {Trans, withTranslation} from "react-i18next";
import DialogContent from "@material-ui/core/DialogContent";
import Flex from "components/grid/Flex";
import MaterialDatePicker from "components/material-datepicker/MaterialDatePicker";
import utils from 'utils/Utils';
import moment from 'moment';
import Typography from "@material-ui/core/Typography";
import {guardsmanRed} from "components/colors/Colors";


export const styles = theme => ({
    dialogContainer: {
        height: 50
    },

    inputContainerMargins: {
        marginRight: 10
    },
    warningMessage: {
        marginLeft: 10,
        color: guardsmanRed

    }
});

class DocumentMachineStatusExcelExportPopup extends Component {

    onFromDateChange = (date) => {
        if (date > this.state.toDate) {
            this.setState({toDate: date});
        }
        this.setState({fromDate: date});
    };

    onToDateChange = (date) => {
        if (date < this.state.fromDate) {
            this.setState({fromDate: date});
        }
        this.setState({toDate: date});
    };

    is30DayInterval = () => {
        return moment(this.state.fromDate).add("30", "day") > moment(this.state.toDate);
    };

    constructor(props) {
        super(props);
        const date = new Date();
        this.state = {
            fromDate: moment(date).add(-1, "day"),
            toDate: moment(date)
        };
    }

    render() {
        const {excelExportDialogOpen, onExcelExportDialogClose, classes, t: translate} = this.props;
        const {fromDate, toDate} = this.state;
        const {onToDateChange, onFromDateChange, is30DayInterval} = this;
        return (
            <Dialog
                open={excelExportDialogOpen}
            >
                <DialogTitle classes={{
                    root: classes.dialogTitleRoot
                }}>
                    <Flex item container>
                        <Flex item>
                            <span style={{
                                fontWeight: 'bold'
                            }}>
                                <Trans i18nKey={"kpi-management.document-machine-status.excelExport"}/>
                            </span>

                        </Flex>

                    </Flex>
                </DialogTitle>
                <DialogContent classes={{
                    root: classes.dialogContentRoot
                }}>
                    <Flex container item grow={1} shrink={0} className={classes.dialogContainer}>
                        <Flex item={'0 1 200px'} container alignItems={'center'}
                              className={classes.inputContainerMargins}>
                            <MaterialDatePicker
                                label={translate('global.from')}
                                value={fromDate}
                                format={utils.DATE_FORMAT}
                                onChange={onFromDateChange}

                            />
                        </Flex>
                        <Flex item={'0 1 200px'} container alignItems={'center'}
                              className={classes.inputContainerMargins}>
                            <MaterialDatePicker
                                label={translate('global.to')}
                                value={toDate}
                                format={utils.DATE_FORMAT}
                                onChange={onToDateChange}
                            />
                        </Flex>
                    </Flex>
                    <Flex item> {
                        !is30DayInterval() && <Typography className={classes.warningMessage}>
                            <Trans i18nKey={"kpi-management.document-machine-status.excelExportWarning"}/>
                        </Typography>
                    }
                    </Flex>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => onExcelExportDialogClose()}
                            color="primary"
                            variant="contained">
                        <Trans i18nKey={"global.cancel"}/>
                    </Button>
                    <Button onClick={() => onExcelExportDialogClose({
                        fromDate: this.state.fromDate,
                        toDate: this.state.toDate
                    })
                    }
                            disabled={!is30DayInterval()}
                            color="primary"
                            variant="contained"
                            autoFocus>
                        <Trans i18nKey={"global.export"}/>
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

export default withStyles(styles)(withTranslation()(DocumentMachineStatusExcelExportPopup));
