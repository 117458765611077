import React, {Component} from 'react';
import {withStyles, Typography} from "@material-ui/core";
import Flex from "components/grid/Flex";
import {connect} from "react-redux";
import connector from "./AuditReportingPareto.connect";
import _ from "lodash"
import moment from "moment"
import StatisticCircles from "scenes/audit-manager/reporting/components/StatisticCircles"
import ReportingUtils from "scenes/audit-manager/reporting/AuditReportingUtils";
import {Trans, withTranslation} from "react-i18next";
import {
    dustyGrey,
    htmlBlack,
    htmlWhite,
    materialUIBorder,
    questionsTableHeader,
    transparentBlack087,
    tallPoppyRed,
} from "components/colors/Colors";
import {questionnaireHeaderIndex} from 'components/zindex/zIndex';
import classNames from 'classnames';
import ConfiguredBootstrapSelect from 'components/configured-bootstrap-select/ConfiguredBootstrapSelect';

const styles = theme => ({
    tableWrapper: {
        maxHeight: '50vh',
        overflow: 'auto',
        backgroundColor: htmlWhite,
        border: `1px solid ${materialUIBorder}`,
    },
    headerRow: {
        backgroundColor: questionsTableHeader,
        color: htmlBlack,
        position: 'sticky',
        top: 0,
        height: 45,
        zIndex: questionnaireHeaderIndex
    },
    cell: {
        color: transparentBlack087,
        fontSize: '0.9vw',
        fontWeight: 400,
        padding: '4px 10px',
    },
    header: {
        color: htmlBlack,
        fontWeight: 800,
        textTransform: "capitalize"
    },
    fieldTitle: {
        color: dustyGrey,
        textTransform: 'uppercase',
        fontSize: '0.9vw',
        '@media (min-width: 1600px)': {
            fontSize: '0.65vw',
        }
    },
});

const filterCategoryTypes = ["QUESTIONS", "CATALOGUES"];

const NoData = props => {
    return (
        <Flex container justifyContent={"center"} alignItems={"center"}
              style={{width: '85%', padding: 50, paddingLeft: 30, color: dustyGrey}}>
            <Trans i18nKey={"audit-management.reporting.pareto.no-data"}/>
        </Flex>
    )
};

const QuestionsTable = props => {
    const {classes, questionStatistics, i18n, translate} = props;
    return (
        <div className={classes.tableWrapper}>
            <Flex container
                  justifyContent={'space-between'}
                  alignItems={'center'}
                  className={classes.headerRow}
            >
                <Flex item container justifyContent={'center'} basis={'5%'} className={classNames(classes.cell, classes.header)}
                      style={{textTransform: "uppercase"}}>
                    <Trans i18nKey={"audit-management.reporting.pareto.questions.header.id"}/>
                </Flex>
                <Flex item basis={'50%'} className={classNames(classes.cell, classes.header)}>
                    <Trans i18nKey={"audit-management.reporting.pareto.questions.header.question"}/>
                </Flex>
                <Flex item basis={'15%'} className={classNames(classes.cell, classes.header)}>
                    <Trans i18nKey={"audit-management.reporting.pareto.questions.header.catalogue"}/>
                </Flex>
                <Flex item container basis={'10%'} justifyContent={'center'}
                      className={classNames(classes.cell, classes.header)}>
                    <Trans i18nKey={"audit-management.reporting.pareto.questions.header.failed"}/>
                </Flex>
                <Flex item basis={'20%'} className={classNames(classes.cell, classes.header)}/>
            </Flex>

            {
                questionStatistics.map((questionStatistic, index) => {
                    const translation = questionStatistic.question.translations
                        .find(translation => translation.language.code === i18n.language);
                    const englishTranslation = questionStatistic.question.translations
                        .find(translation => translation.language.code === "en");
                    const question = _.get(translation, 'question');
                    const englishQuestion = _.get(englishTranslation, 'question');
                    return (
                        <Flex container key={index}
                              justifyContent={'space-between'}
                              alignItems={'center'}
                              style={{
                                  lineHeight: 1.5,
                                  borderTop: index === 0 ? `0px` : `1px solid ${materialUIBorder}`,
                              }}
                        >
                            <Flex item container basis={'5%'} justifyContent={'center'} className={classes.cell} style={{color: dustyGrey}}>
                                {questionStatistic.question.businessId}
                            </Flex>
                            {
                                _.isEmpty(question) ?
                                    <Flex item container basis={'50%'} className={classes.cell} style={{color: dustyGrey}} alignItems={'center'}>
                                        [EN] {englishQuestion}
                                    </Flex>
                                    :
                                    <Flex item basis={'50%'} className={classes.cell}>
                                        {question}
                                    </Flex>

                            }
                            <Flex item basis={'15%'} className={classes.cell} style={{color: dustyGrey}}>
                                {translate("audit-administration.catalogue." + questionStatistic.question.catalogue)}
                            </Flex>
                            <Flex item container basis={'10%'} justifyContent={'center'} className={classes.cell}>
                                {questionStatistic.count}
                            </Flex>
                            <Flex item container basis={'20%'} className={classes.cell}>
                                <div style={{
                                    backgroundColor: tallPoppyRed,
                                    height: 20,
                                    width: questionStatistic.relativeFailurePercentage + "%",
                                    border: `1px solid ${htmlBlack}`,
                                }}/>
                            </Flex>
                        </Flex>
                    )
                })
            }
        </div>
    )
};

const CataloguesTable = props => {
    const {classes, catalogueStatistics, translate} = props;
    return (
        <div className={classes.tableWrapper}>
            <Flex container
                  justifyContent={'space-between'}
                  alignItems={'center'}
                  className={classes.headerRow}
            >
                <Flex item basis={'40%'} className={classNames(classes.cell, classes.header)}>
                    <Trans i18nKey={"audit-management.reporting.pareto.questions.header.catalogue"}/>
                </Flex>
                <Flex item container basis={'10%'} justifyContent={'center'}
                      className={classNames(classes.cell, classes.header)}>
                    <Trans i18nKey={"audit-management.reporting.pareto.questions.header.failed"}/>
                </Flex>
                <Flex item basis={'50%'} className={classNames(classes.cell, classes.header)}/>
            </Flex>
            {
                catalogueStatistics.map((catalogueStatistic, index) => {
                    return (
                        <Flex container key={index}
                              justifyContent={'space-between'}
                              alignItems={'center'}
                              style={{
                                  lineHeight: 1.5,
                                  borderTop: index === 0 ? `0px` : `1px solid ${materialUIBorder}`,
                              }}
                        >
                            <Flex item basis={'40%'} className={classes.cell} style={{color: dustyGrey}}>
                                {translate("audit-administration.catalogue." + catalogueStatistic.catalogue)}
                            </Flex>
                            <Flex item container basis={'10%'} justifyContent={'center'} className={classes.cell}>
                                {catalogueStatistic.count}
                            </Flex>
                            <Flex item container basis={'50%'} className={classes.cell}>
                                <div style={{
                                    backgroundColor: tallPoppyRed,
                                    height: 20,
                                    width: catalogueStatistic.relativeFailurePercentage + "%",
                                    border: `1px solid ${htmlBlack}`,
                                }}/>
                            </Flex>
                        </Flex>
                    )
                })
            }
        </div>
    )
};

export class AuditReportingPareto extends Component {
    state = {
        filterCategory: "QUESTIONS"
    };

    componentDidMount() {
        if (!_.isEmpty(this.props.selectedOrganizationLevel)) {
            this.fetchAuditQuestionStatistics();
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (
            prevProps.selectedOrganizationLevel.businessId !== this.props.selectedOrganizationLevel.businessId ||
            prevProps.periodStartDate !== this.props.periodStartDate ||
            prevProps.periodEndDate !== this.props.periodEndDate ||
            prevProps.auditType !== this.props.auditType ||
            prevProps.timePeriodValue !== this.props.timePeriodValue
        ) {
            this.fetchAuditQuestionStatistics();
        }
    }

    fetchAuditQuestionStatistics = () => {
        if (this.props.timePeriodValue === "MONTH") {
            this.fetchCurrentMonthAuditQuestionStatistics();
        } else if (this.props.timePeriodValue === "PERIOD") {
            this.fetchAuditQuestionStatisticsForPeriod();
        } else {
            this.fetchInitialAuditQuestionStatistics();
        }
    };

    fetchInitialAuditQuestionStatistics = () => {
        this.props.fetchAuditQuestionStatistics({
            selectedOrganizationLevel: this.props.selectedOrganizationLevel,
            periodStartDate: moment().year(2018),
            periodEndDate: moment(),
            durationType: this.props.durationType,
            auditType: this.props.auditType,
        });
    };

    fetchCurrentMonthAuditQuestionStatistics = () => {
        this.props.fetchAuditQuestionStatistics({
            selectedOrganizationLevel: this.props.selectedOrganizationLevel,
            periodStartDate: moment().startOf('month'),
            periodEndDate: moment().endOf('month'),
            durationType: this.props.durationType,
            auditType: this.props.auditType,
        });
    };

    fetchAuditQuestionStatisticsForPeriod = () => {
        this.props.fetchAuditQuestionStatistics({
            selectedOrganizationLevel: this.props.selectedOrganizationLevel,
            periodStartDate: this.props.periodStartDate,
            periodEndDate: this.props.periodEndDate,
            durationType: this.props.durationType,
            auditType: this.props.auditType,
        });
    };

    handleFilterCategoryChange = event => {
        this.setState({filterCategory: event.target.value})
    };

    render() {
        const {
            classes, statistics, questionStatistics, catalogueStatistics, selectedOrganizationLevel, timePeriodValue,
            i18n, t: translate
        } = this.props;
        const {filterCategory} = this.state;
        const {handleFilterCategoryChange} = this;
        return (
            <Flex container>
                <Flex item container direction={'column'} alignItems={'center'}
                      style={{width: '15%'}}>
                    <StatisticCircles {...{
                        selectedOrganizationLevel,
                        timePeriodValue,
                        successfulAudits: ReportingUtils.successfulAudits(statistics),
                        failedCriticalAudits: ReportingUtils.failedCriticalAudits(statistics),
                        failedMinorAudits: ReportingUtils.failedMinorAudits(statistics),
                        missedAudits: ReportingUtils.missedAudits(statistics)
                    }}/>
                </Flex>
                {
                    _.isEmpty(statistics) ?
                        <NoData/>
                        :
                        <Flex item container direction={'column'} style={{width: '85%', paddingRight: 10, paddingLeft: 30}}>
                            <div className={classes.inputItem} style={{width: 200, marginBottom: 20}}>
                                <Typography className={classes.fieldTitle}>
                                    <Trans i18nKey={"audit-management.reporting.pareto.view.title"}/>
                                </Typography>
                                <ConfiguredBootstrapSelect
                                    value={filterCategory}
                                    onChange={handleFilterCategoryChange}
                                    name={'filterCategoryType'}
                                    items={filterCategoryTypes}
                                    translationPath={'audit-management.reporting.pareto.view.values'}
                                />
                            </div>
                            {
                                filterCategory === "QUESTIONS" &&
                                <QuestionsTable {...{classes, questionStatistics, i18n, translate}}/>
                            }
                            {
                                filterCategory === "CATALOGUES" &&
                                <CataloguesTable {...{classes, catalogueStatistics, translate}}/>
                            }
                        </Flex>
                }
            </Flex>
        );
    }
}

export default withStyles(styles)(withTranslation()(connect(connector.mapStateToProps, connector.mapDispatchToProps)(AuditReportingPareto)));
