import React, {PureComponent} from "react";
import {withStyles} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Flex from "components/grid/Flex";
import {audit as auditColor, ghostGrey, grey, htmlWhite} from "components/colors/Colors";
import {getColor} from "components/colors/Result";
import _ from "lodash";
import {withTranslation} from "react-i18next";
import StopSignIcon from 'assets/img/stop-sign.svg';

const styles = theme => ({
    circle: {
        width: 30,
        height: 30,
        borderRadius: '50%',
        border: `2px solid ${htmlWhite}`,
    },
    text: {
        color: htmlWhite,
        fontWeight: 'bold',
        fontSize: 14
    },
    stopText: {
        color: htmlWhite,
        fontWeight: 'bold',
        fontSize: 7
    },
    pointer: {
        '&:hover': {
            cursor: 'pointer'
        }
    }
});

const DurationCircleIcon = (props) => {
    const {classes, audit, circeClass, textClass, label, getDurationCircleColor} = props;
    return (
        <Flex
            container
            justifyContent={'center'}
            alignItems={'center'}
            className={circeClass || classes.circle}
            style={{
                backgroundColor: getDurationCircleColor(audit)
            }}
        >
            <Typography
                className={textClass || classes.text}>
                {label}
            </Typography>
        </Flex>
    )
};

const StopSign = (props) => {
    const {classes, circeClass} = props;
    return (
        <Flex
            container
            justifyContent={'center'}
            alignItems={'center'}
            className={circeClass || classes.circle}
        >
            <img src={StopSignIcon} alt={''} className={circeClass || classes.circle}/>
        </Flex>
    )
};


export class DurationCircle extends PureComponent {

    getDurationCircleColor = (audit) => {
        if (audit.missed) {
            return grey
        } else if (audit.done) {
            return getColor(audit.result);
        } else if (audit.open) {
            return auditColor;
        } else {
            return ghostGrey;
        }
    };

    isUserAdmin = (audit, user) => {
        return !_.isEmpty(audit.organizationLevel.members
            .find(member => _.get(member, 'user.businessId') === user.businessId
                && member.memberships.includes('ADMIN'))
        );
    };

    render() {
        const {classes, audit, t: translate, circeClass, textClass, cancelable, user, toggleCancelAuditScheduleDialog, hovered} = this.props;
        const scheduledAudit = _.isEmpty(audit.businessId);
        const {getDurationCircleColor, isUserAdmin} = this;
        const label = !_.isEmpty(audit.duration) ? translate('audit-administration.duration.' + audit.duration).charAt(0) : '?';
        const iconSwitched = scheduledAudit && cancelable && (hovered) && isUserAdmin(audit, user);
        return (
            <div
                onClick={() => {
                    if (iconSwitched) {
                        toggleCancelAuditScheduleDialog(audit);
                    }
                }}
                className={iconSwitched ? classes.pointer : ''}
            >
                {
                    iconSwitched ?
                        <StopSign
                            {...{
                                classes, translate, circeClass
                            }}
                        />
                        :
                        <DurationCircleIcon
                            {...{
                                classes, audit, circeClass, textClass, label, getDurationCircleColor
                            }}
                        />

                }
            </div>
        );
    }
}

export default withStyles(styles)(withTranslation()(DurationCircle));
