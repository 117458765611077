import React, {Component} from 'react';
import {Button, withStyles,Tooltip} from "@material-ui/core";
import Flex from "components/grid/Flex";
import {connect} from "react-redux";
import connector from "./QuestionnaireEdit.connect";
import _ from "lodash";
import {Trans, withTranslation} from "react-i18next";
import {Link, withRouter} from "react-router-dom";
import {AUDIT_ADMINISTRATION_QUESTIONNAIRES} from "routes/routes";
import update from "immutability-helper";
import {APPROVED, isActive, PENDING, emptyQuestionnaire} from "domain/audit/Questionnaire.model";
import ConfirmationDialog from "components/confirmation-dialog/ConfirmationDialog";
import {routeTo, prevPath} from 'routes/routes';
import AssignedSchedules from "scenes/audit-manager/administration/questionnaires/components/AssignedSchedules";
import SelectedOrganizationLevels
    from "scenes/audit-manager/administration/questionnaires/components/SelectedOrganizationLevels";
import DeactivateScheduleDialog
    from "scenes/audit-manager/administration/questionnaires/components/DeactivateScheduleDialog";
import QuestionnaireHeader from './components/QuestionnaireHeader';
import QuestionnaireQuestionPicker from './components/QuestionnaireQuestionPicker';
import QuestionnaireQuestionPickerButtons from './components/QuestionnaireQuestionPickerButtons';
import QuestionnaireSelectedQuestions from './components/QuestionnaireSelectedQuestions';

export function isVdaAudit(label) {
    return label === 'VDA 6.3';
}

const styles = theme => ({
    mainArea: {
        marginTop: 20,
        marginBottom: 20,
        margin: '40px 20px',
        padding: 7,
        height: '100%'
    },
    controlButtons: {
        minWidth: 150,
        marginTop: 15,
        marginLeft: 10
    },
});

const FootControlButtons = (props) => {
    const {
        classes, questionnaire, onDelete, onCancel, onSave, user, location, isSaveEnabled, isDeactivated,
        toggleDeactivateDialogOpen, noAdminRights, toggleAuthorizationDialogOpen, translate, showVdaSaveTooltip
    } = props;
    const saveDisabled = !isSaveEnabled();

    return (
        <Flex container item={'0 0 50px'} justifyContent={'space-between'}>
            <Flex item={'1 0 auto'} container>
                <Button variant="contained"
                        color="primary"
                        onClick={noAdminRights ? toggleAuthorizationDialogOpen : onDelete}
                        disabled={!user.isAdmin || _.isEmpty(questionnaire.businessId)}
                        className={classes.controlButtons}
                        style={{marginLeft: 0}}
                >
                    <Trans i18nKey="global.delete"/>
                </Button>
            </Flex>
            <Tooltip placement={'top-end'}
                title={showVdaSaveTooltip ? translate('audit-administration.questionnaireEdit.name-all-topics'): ''}>
                <Flex item={'1 0 auto'} container justifyContent={'flex-end'}>
                    <Button variant="contained"
                            color="primary"
                            component={Link}
                            to={{
                                pathname: AUDIT_ADMINISTRATION_QUESTIONNAIRES.path,
                                state: {prevPath: prevPath({location})}
                            }}
                            className={classes.controlButtons}
                            onClick={onCancel}
                    >
                        <Trans i18nKey="global.cancel"/>
                    </Button>
                    <Button variant="contained"
                            color="primary"
                            className={classes.controlButtons}
                            disabled={saveDisabled}
                            onClick={noAdminRights ? toggleAuthorizationDialogOpen : isDeactivated ? toggleDeactivateDialogOpen : onSave}
                    >
                        <Trans i18nKey="global.save"/>
                    </Button>
                </Flex>

            </Tooltip>

        </Flex>
    );
};

export class QuestionnaireEdit extends Component {

    state = {
        questionnaire: emptyQuestionnaire(),
        allQuestions: [],
        catalogue: '',
        selectableCatalogueQuestions: [],
        selectedQuestions: [],
        markedQuestion: null,
        allowedRandomSelections: 0,
        selectedRandomNumber: '0',
        randomQuestionsByCatalogue: {},
        deleteDialogOpen: false,
        deactivateDialogOpen: false,
        markedCatalogue: null,
        authorizationDialogOpen: false,
        selectedTopicId: ''
    };

    componentDidMount() {
        const questionnaireBusinessId = this.props.questionnaireBusinessId;
        if (questionnaireBusinessId) {
            this.props.fetchQuestionnaire(questionnaireBusinessId)
                .then(() => {
                        const questionnaire = _.cloneDeep(this.props.questionnaire);

                        this.setState({
                            questionnaire: questionnaire,
                            selectedQuestions: questionnaire.questions,
                            randomQuestionsByCatalogue: questionnaire.randomQuestionsByCatalogue
                        }, this.toggleFilterMultiSelections)
                    }
                )
                .catch((error) => {
                    console.log('error: ', error);
                    routeTo(AUDIT_ADMINISTRATION_QUESTIONNAIRES.path, this.props);
                });
        } else {
            this.props.fetchEmptyQuestionnaire().then(() =>
                this.setState({
                    questionnaire: _.cloneDeep(this.props.questionnaire)
                }));
        }
        this.props.fetchQuestions().then(() => {
            this.setState({
                allQuestions: _.cloneDeep(this.props.questions),
            });
        });
    }

    reset = callback => {
        this.setState({
            questionnaire: emptyQuestionnaire(),
            selectableCatalogueQuestions: [],
            selectedQuestions: [],
            markedQuestion: null,
            selectedRandomNumber: '0',
            deleteDialogOpen: false,
            deactivateDialogOpen: false,
            authorizationDialogOpen: false,
        }, callback)
    }

    changeState = event => {
        this.setState({
            [event.target.name]: event.target.value
        })
    };

    goBack = () => {
        this.props.registerQuestionnaireEditCallback(Promise.resolve('Fetch questionnaires needed'));
        routeTo(AUDIT_ADMINISTRATION_QUESTIONNAIRES.path, this.props.routeProps);
    };

    toggleDeleteDialogOpen = () => {
        this.setState({deleteDialogOpen: !this.state.deleteDialogOpen})
    };

    toggleDeactivateDialogOpen = () => {
        this.setState({deactivateDialogOpen: !this.state.deactivateDialogOpen})
    };

    toggleAuthorizationDialogOpen = () => {
        this.setState({authorizationDialogOpen: !this.state.authorizationDialogOpen})
    };

    deleteQuestionnaire = () => {
        this.props.deleteQuestionnaire(this.state.questionnaire.businessId).then(() => {
            this.toggleDeleteDialogOpen();
            this.goBack();
        });
    };

    saveQuestionnaire = () => {
        const data = {
            name: this.state.questionnaire.name,
            cycle: this.state.questionnaire.cycle,
            questionIds: this.state.selectedQuestions.map(question => question.businessId),
            organizationLevelIds: this.props.multiSelectedOrganizationLevels.map(organizationLevel => organizationLevel.businessId),
            status: this.state.questionnaire.status,
            randomQuestionsByCatalogue: this.state.randomQuestionsByCatalogue,
            topics: this.state.questionnaire.topics,
            auditType: this.state.questionnaire.auditType
        };
        if (this.props.questionnaireBusinessId) {
            this.props.registerQuestionnaireEditCallback(this.props.editQuestionnaire({
                businessId: this.state.questionnaire.businessId,
                ...data
            }).then(() => {
                this.goBack();
            }));
        } else {
            this.props.registerQuestionnaireEditCallback(this.props.createQuestionnaire(data).then(() => {
                this.goBack();
            }));
        }
    };

    handleQuestionnaireChange = (event) => {
        const target = event.target;
        this.setState(oldState => {
            return {
                questionnaire: update(oldState.questionnaire, {
                    [target.name]: {$set: target.value}
                }),
            };
        });
    };

    handleAuditTypeChange = event => {
        const auditType = this.props.auditTypes.find(type => type.label === event.target.value);
        this.reset(() => this.setState(oldState => {
            return {
                questionnaire: update(oldState.questionnaire, {
                    auditType: {$set: auditType}
                })
            }
        }));
    }

    handleCatalogueChange = (event) => {
        const catalogue = event.target.value;
        const selectedQuestionIds = this.state.selectedQuestions.map(question => question.businessId);
        const selectableCatalogueQuestions = this.getSelectableCatalogueQuestions(selectedQuestionIds, catalogue);
        this.setState({
            catalogue: catalogue,
            selectableCatalogueQuestions: selectableCatalogueQuestions,
            allowedRandomSelections: selectableCatalogueQuestions.filter(q => q.status === APPROVED).length,
            selectedRandomNumber: _.get(this.state.randomQuestionsByCatalogue, catalogue, '0')
        });
    };

    getSelectableCatalogueQuestions = (selectedQuestionIds, catalogue) => {
        return this.state.allQuestions
            .filter(question => !selectedQuestionIds.includes(question.businessId))
            .filter(question => question.catalogue === catalogue)
            .map(question => {
                return {
                    ...question,
                    selected: false
                }
            });
    };

    handleQuestionCheck = (checkedQuestion) => () => {
        this.setState({
            selectableCatalogueQuestions: this.state.selectableCatalogueQuestions.map(question => {
                if (question.businessId === checkedQuestion.businessId) {
                    question.selected = question.status === PENDING ? false : !question.selected;
                }
                return question;
            })
        });
    };

    handleAllQuestionCheck = (allQuestionSelected) => () => {
        this.setState({
            selectableCatalogueQuestions: this.state.selectableCatalogueQuestions.map(question => {
                question.selected = question.status === PENDING ? false : !allQuestionSelected;
                return question;
            })
        });
    };

    changeTopic = (callback) => {
        const topics = [...this.state.questionnaire.topics];
        const allTopics = topics.reduce((accumulator, item) => {
            accumulator = [...accumulator, item, ...item.children];
            return accumulator;
        }, []);
        const topic = allTopics.find(item => item.businessId === this.state.selectedTopicId);
        if (topic) {
            callback(topic);
        }

        this.handleQuestionnaireChange({target: {name: 'topics', value: topics}});
    }

    allTopics = () => {
        const topics = [...this.state.questionnaire.topics];
        return topics.reduce((accumulator, item) => {
            accumulator = [...accumulator, item, ...item.children];
            return accumulator;
        }, []);
    }

    handleAddQuestions = () => {
        const selectedQuestions = this.state.selectableCatalogueQuestions.filter(q => q.selected);
        const notSelectedQuestions = this.state.selectableCatalogueQuestions.filter(q => !q.selected);

        this.setState({
            selectableCatalogueQuestions: notSelectedQuestions,
            selectedQuestions: this.state.selectedQuestions.concat(selectedQuestions)
        });

        if (isVdaAudit(_.get(this.state, 'questionnaire.auditType.label'))) {
            if (selectedQuestions.length > 0 && this.state.selectedTopicId) {
                this.changeTopic(topic => {
                    topic.questionIds = [...topic.questionIds, ...selectedQuestions.map(question => question.businessId)];
                })
            }
        } else {
            const allowedRandomSelections = notSelectedQuestions.filter(q => q.status === APPROVED).length;
            const randomQuestionsByCatalogue = this.state.randomQuestionsByCatalogue;
            const numberOfRandomQuestion = randomQuestionsByCatalogue[this.state.catalogue];
            if (numberOfRandomQuestion > allowedRandomSelections - 1) {
                randomQuestionsByCatalogue[this.state.catalogue] = (allowedRandomSelections - 1)
            }
            if (allowedRandomSelections - 1 <= 0) {
                delete randomQuestionsByCatalogue[this.state.catalogue];
            }
            const selectedRandomNumber = this.state.selectedRandomNumber > allowedRandomSelections - 1 ? String(allowedRandomSelections - 1) : this.state.selectedRandomNumber;
            this.setState({
                allowedRandomSelections: allowedRandomSelections,
                selectedRandomNumber: selectedRandomNumber,
                randomQuestionsByCatalogue: randomQuestionsByCatalogue
            });
        }
    };

    handleMarkQuestion = (question, callback) => () => {
        this.setState({
            markedQuestion: this.state.markedQuestion === question ? null : question,
            markedCatalogue: null
        }, () => callback && callback())
    };

    handleRemoveQuestion = () => {
        if (!_.isEmpty(this.state.markedQuestion)) {
            if (isVdaAudit(_.get(this.state, 'questionnaire.auditType.label'))) {
                this.changeTopic(topic => {
                    const index = topic.questionIds
                        .findIndex(questionId => this.state.markedQuestion.businessId === questionId);
                    if (index > -1) {
                        topic.questionIds.splice(index, 1);
                    }
                })
            }
            const selectedQuestionIds = this.state.selectedQuestions.map(question => question.businessId);
            const selectableCatalogueQuestions = this.getSelectableCatalogueQuestions(selectedQuestionIds, this.state.markedQuestion.catalogue);

            this.setState({
                catalogue: this.state.markedQuestion.catalogue,
                selectableCatalogueQuestions: selectableCatalogueQuestions,
                allowedRandomSelections: selectableCatalogueQuestions.filter(q => q.status === APPROVED).length,
                selectedRandomNumber: _.get(this.state.randomQuestionsByCatalogue, this.state.markedQuestion.catalogue, '0')
            }, this.removeQuestion);
        } else if (!_.isEmpty(this.state.markedCatalogue)) {
            const selectedQuestionIds = this.state.selectedQuestions.map(question => question.businessId);
            const selectableCatalogueQuestions = this.getSelectableCatalogueQuestions(selectedQuestionIds, this.state.markedCatalogue);

            const randomQuestionsByCatalogue = this.state.randomQuestionsByCatalogue;
            delete randomQuestionsByCatalogue[this.state.markedCatalogue];

            this.setState({
                catalogue: this.state.markedCatalogue,
                selectableCatalogueQuestions: selectableCatalogueQuestions,
                allowedRandomSelections: selectableCatalogueQuestions.filter(q => q.status === APPROVED).length,
                selectedRandomNumber: _.get(this.state.randomQuestionsByCatalogue, this.state.markedCatalogue, '0'),
                randomQuestionsByCatalogue: randomQuestionsByCatalogue,
                markedCatalogue: {}
            });
        }
    };

    removeQuestion() {
        const selectableCatalogueQuestions = this.state.selectableCatalogueQuestions;
        const markedQuestion = {...this.state.markedQuestion};
        markedQuestion.selected = false;
        selectableCatalogueQuestions.push(markedQuestion);
        const selectedQuestions = this.state.selectedQuestions.filter(selected => selected.businessId !== markedQuestion.businessId);

        this.setState({
            catalogue: markedQuestion.catalogue,
            selectedQuestions: selectedQuestions,
            selectableCatalogueQuestions: selectableCatalogueQuestions,
            markedQuestion: {},
            allowedRandomSelections: this.state.allowedRandomSelections + 1
        })
    };

    isSaveEnabled = () => {
        const randomQuestions = Object.keys(this.state.randomQuestionsByCatalogue).map(key => this.state.randomQuestionsByCatalogue[key]).filter(value => value > 0);
        const selectedOrganizationLevels = this.props.multiSelectedOrganizationLevels;
        const isVda = isVdaAudit(_.get(this.state, 'questionnaire.auditType.label'))
        const topics = this.state.questionnaire.topics;
        return !_.isEmpty(selectedOrganizationLevels)
            && !_.isEmpty(this.state.questionnaire.name)
            && !_.isEmpty(this.state.questionnaire.cycle)
            && (isVda || (!isVda && (!_.isEmpty(this.state.selectedQuestions) || !_.isEmpty(randomQuestions))))
            && (!isVda || (isVda && !_.isEmpty(topics)))
            && (!isVda || (topics.every(topic => topic.name.length > 0)))
    };

    handleActiveCheck = () => {
        const newStatus = this.state.questionnaire.status === PENDING ? APPROVED : PENDING;
        this.setState({
            questionnaire: {
                ...this.state.questionnaire,
                status: newStatus,
                active: isActive(newStatus)
            }
        });
    };

    handleAddRandomQuestions = () => {
        const catalogue = this.state.catalogue;
        const randomQuestionsByCatalogue = this.state.randomQuestionsByCatalogue;
        if (this.state.selectedRandomNumber === '0') {
            delete randomQuestionsByCatalogue[catalogue];
        } else {
            randomQuestionsByCatalogue[catalogue] = this.state.selectedRandomNumber;
        }
        this.setState({
            randomQuestionsByCatalogue: randomQuestionsByCatalogue
        });
    };

    handleRandomNumberSelect = (event) => {
        this.setState({
            selectedRandomNumber: event.target.value
        })
    };

    handleMarkRandomQuestions = (catalogue) => () => {
        this.setState({
            markedQuestion: null,
            markedCatalogue: this.state.markedCatalogue === catalogue ? null : catalogue
        })
    };

    toggleFilterMultiSelections = () => {
        if (!_.isEmpty(this.state.questionnaire.organizationLevels)) {
            this.props.toggleFilterMultiSelections(this.state.questionnaire.organizationLevels.map(org => org.businessId));
        }
    };

    selectTopic = topic => event => {
        event.preventDefault();
        event.stopPropagation();
        this.setState({
            selectedTopicId: this.state.selectedTopicId === topic.businessId ? '' : topic.businessId
        })
    }

    isSelectedTopic = topic => {
        return this.state.selectedTopicId === topic.businessId;
    }

    componentWillUnmount() {
        this.props.clearFilterMultiSelection();
    }

    render() {
        const {
            classes, user, location, cycles, auditTypes, catalogues, supportedLanguages, multiSelectedOrganizationLevels,
            t: translate
        } = this.props;
        const {
            goBack, saveQuestionnaire, handleQuestionnaireChange, handleCatalogueChange,
            handleQuestionCheck, handleAllQuestionCheck, handleAddQuestions, handleMarkQuestion, handleRemoveQuestion,
            isSaveEnabled, handleActiveCheck, handleAddRandomQuestions, handleRandomNumberSelect, deleteQuestionnaire,
            toggleDeleteDialogOpen, handleMarkRandomQuestions, toggleDeactivateDialogOpen, toggleAuthorizationDialogOpen,
            handleAuditTypeChange, selectTopic, isSelectedTopic, changeState
        } = this;
        const {
            questionnaire, catalogue, selectableCatalogueQuestions, selectedQuestions, markedQuestion, allowedRandomSelections,
            selectedRandomNumber, randomQuestionsByCatalogue, deleteDialogOpen, markedCatalogue, deactivateDialogOpen,
            authorizationDialogOpen, selectedTopicId
        } = this.state;
        const isDeactivated = isActive(this.props.questionnaire.status) && !isActive(questionnaire.status);
        const selectedNotEditableNodes = multiSelectedOrganizationLevels
            .filter(org => !org.isUserAdmin).map(org => org.title);
        const notVisibleNodes = _.get(questionnaire, 'organizationLevels', [])
            .filter(org => !org.isUserAdmin).map(org => org.title);
        const notEditableOrganizationLevels = Array.from(new Set([...selectedNotEditableNodes, ...notVisibleNodes]));
        const selectedTopic = selectedTopicId === '' ? {}
        : this.allTopics().find(topic => topic.businessId === selectedTopicId);
        const showVdaSaveTooltip = (!isVdaAudit(_.get(questionnaire, 'auditType.label'))
            || (_.get(questionnaire, 'topics', []).some(topic => !topic.name)));
        return (
            <Flex item container direction={'column'} style={{margin: 20}}>
                {
                    !_.isEmpty(questionnaire) &&
                    <Flex item={'1 0 auto'} container column>
                        <QuestionnaireHeader {...{
                            isVdaAudit: isVdaAudit(_.get(questionnaire, 'auditType.label')),
                            questionnaire,
                            cycles,
                            auditTypes,
                            selectedQuestions,
                            handleQuestionnaireChange,
                            handleActiveCheck,
                            handleAuditTypeChange
                        }}/>
                        <Flex container className={classes.mainArea}>
                            <QuestionnaireSelectedQuestions {...{
                                isVdaAudit: isVdaAudit(_.get(questionnaire, 'auditType.label')),
                                selectedQuestions,
                                questionnaire,
                                handleMarkQuestion,
                                handleRemoveQuestion,
                                markedQuestion,
                                handleCatalogueChange,
                                multiSelectedOrganizationLevels,
                                randomQuestionsByCatalogue,
                                markedCatalogue,
                                handleMarkRandomQuestions,
                                handleQuestionnaireChange,
                                selectTopic,
                                isSelectedTopic,
                                changeState
                            }}/>
                            <QuestionnaireQuestionPickerButtons {...{
                                handleAddQuestions,
                                handleRemoveQuestion,
                                markedQuestion,
                                selectableCatalogueQuestions,
                                handleAddRandomQuestions,
                                allowedRandomSelections,
                                selectedRandomNumber,
                                handleRandomNumberSelect,
                                markedCatalogue,
                                selectedTopic,
                                isVdaAudit: isVdaAudit(_.get(this.state, 'questionnaire.auditType.label'))
                            }}/>
                            <QuestionnaireQuestionPicker {...{
                                catalogue,
                                catalogues,
                                handleCatalogueChange,
                                selectableCatalogueQuestions,
                                handleQuestionCheck,
                                handleAllQuestionCheck,
                                supportedLanguages,
                                randomQuestionsByCatalogue,
                                isVdaAudit: isVdaAudit(_.get(this.state, 'questionnaire.auditType.label'))
                            }}/>
                        </Flex>
                        <ConfirmationDialog dialogOpen={deleteDialogOpen}
                                            singleConfirmationButton={questionnaire.isAssignedToAuditSchedule}
                                            onDialogClose={toggleDeleteDialogOpen}
                                            onConfirm={questionnaire.isAssignedToAuditSchedule ? toggleDeleteDialogOpen : deleteQuestionnaire}
                                            confirmationTextKey={questionnaire.isAssignedToAuditSchedule ? "audit-administration.questionnaireEdit.deleteQuestionnaireRejection" : "audit-administration.questionnaireEdit.deleteQuestionnaireConfirmation"}
                                            children={
                                                <AssignedSchedules {...{questionnaire}}/>
                                            }
                        />
                        <DeactivateScheduleDialog dialogOpen={deactivateDialogOpen}
                                                  onDialogClose={toggleDeactivateDialogOpen}
                                                  onConfirm={saveQuestionnaire}
                                                  confirmationTextKey={"audit-administration.questionnaireEdit.deactivateQuestionnaireConfirmation"}
                                                  questionnaire={questionnaire}

                        />
                        <ConfirmationDialog dialogOpen={authorizationDialogOpen}
                                            singleConfirmationButton={true}
                                            onDialogClose={toggleAuthorizationDialogOpen}
                                            onConfirm={toggleAuthorizationDialogOpen}
                                            confirmationTextKey={"audit-administration.questionnaireEdit.noAdminRightOnOrganizationLevels"}
                                            children={
                                                <SelectedOrganizationLevels {...{organizationLevels: notEditableOrganizationLevels}}/>
                                            }
                        />
                    </Flex>
                }
                <FootControlButtons {...{
                    classes,
                    questionnaire,
                    location,
                    onDelete: toggleDeleteDialogOpen,
                    onCancel: goBack,
                    onSave: saveQuestionnaire,
                    user,
                    isSaveEnabled,
                    toggleDeactivateDialogOpen,
                    isDeactivated,
                    multiSelectedOrganizationLevels,
                    toggleAuthorizationDialogOpen,
                    translate,
                    noAdminRights: notEditableOrganizationLevels.length > 0,
                    showVdaSaveTooltip
                }}/>
            </Flex>
        );
    }
}

export default withTranslation()(withStyles(styles)(connect(connector.mapStateToProps, connector.mapDispatchToProps)(withRouter(QuestionnaireEdit))));
