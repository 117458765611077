import React, {PureComponent} from 'react';
import {withStyles, Dialog, DialogContent, Typography, DialogActions, Button} from "@material-ui/core";
import {Trans, withTranslation} from "react-i18next";
import Flex from "components/grid/Flex";
import {Link} from 'react-router-dom';
import {htmlOrange} from 'components/colors/Colors';

const styles = theme => ({
    controlButtons: {
        minWidth: 150,
        '@media (min-width: 300px)': {
            margin: 5
        },
    },
    root: {
        maxWidth: 'unset'
    },
    buttons: {
        justifyContent: 'flex-end',
        '@media (min-width: 300px)': {
            flexDirection: 'column'
        },
        '@media (min-width: 1500px)': {
            flexDirection: 'row'
        },
    }
});

export class TranslationChangedDialog extends PureComponent {
    render() {
        const {classes, saveWithOptions, originUrl} = this.props;

        return (
            <Dialog
                open={true}
                classes={{root: classes.root}}
                maxWidth={'lg'}
            >
                <DialogContent>
                    <Flex container item grow={1} shrink={0} style={{marginBottom: 15}}>
                        <Typography>
                            <Trans i18nKey={"task-management.overwrite-translation"}/>
                        </Typography>
                    </Flex>
                    <Flex container item grow={1} shrink={0}>
                        <Typography style={{color: htmlOrange}}>
                            <Trans i18nKey={"task-management.overwrite-translation-warning"}/>
                        </Typography>
                    </Flex>
                </DialogContent>
                <DialogActions>
                    <Flex container item className={classes.buttons}>
                        <Button onClick={() => {
                        }}
                                className={classes.controlButtons}
                                component={Link}
                                to={originUrl}
                                color="primary"
                                variant="contained">
                            <Trans i18nKey={"global.cancel"}/>
                        </Button>
                        <Button onClick={() => saveWithOptions(false)}
                                color="primary"
                                className={classes.controlButtons}
                                variant="contained">
                            <Trans i18nKey={"task-management.overwrite-no"}/>
                        </Button>
                        <Button onClick={() => saveWithOptions(true)}
                                color="primary"
                                className={classes.controlButtons}
                                variant="contained"
                                autoFocus>
                            <Trans i18nKey={"task-management.overwrite-yes"}/>
                        </Button>
                    </Flex>
                </DialogActions>
            </Dialog>
        );
    }
}

export default withStyles(styles)(withTranslation()(TranslationChangedDialog));
