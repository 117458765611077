import React, {PureComponent} from 'react';
import {Typography} from "@material-ui/core";
import Flex from 'components/grid/Flex';

export class LanguageCircle extends PureComponent {
    render() {
        const {label, color, textColor, style, labelStyle} = this.props;

        return (
            <Flex
                container
                justifyContent={'center'}
                alignItems={'center'}
                style={{
                    width: 25,
                    height: 25,
                    borderRadius: '50%',
                    backgroundColor: color,
                    ...style
                }}>
                <Typography style={{
                    color: textColor,
                    fontWeight: 'bold',
                    fontSize: 11,
                    ...labelStyle
                }}>{label}</Typography>
            </Flex>
        );
    }
}

export default LanguageCircle;
