import * as actionTypes from './Project.action.types';
import _ from 'lodash';
import {apiProjectMember} from 'domain/task/Project.model'
import taskUtils from "scenes/tasks/Task.utils";
import * as taskActionTypes from "scenes/tasks/task-edit/TaskEdit.action.types";
import update from 'immutability-helper';

//TODO LM: This should be supplied from the backend
export const PROJECT_MEMBERSHIP_ROLES = ['OBSERVER', 'MEMBER', 'ADMIN'];

const initialState = {
    selectedProject: {
        businessId: '',
        name: '',
        members: [],
        breadCrumb: []
    },
    projectsFromBackend: [],
    projectTree: [],
    isProjectTreeOpen: false,
    selectedUserNames: [],
    personalProject: {},
    previouslySelectedProject: undefined
};

export default function (state = initialState, action) {
    switch (action.type) {
        case actionTypes.LIST_PROJECTS_FROM_CACHE:
        case actionTypes.LIST_PROJECTS_SUCCESS: {
            const newState = {...state};
            const user = _.get(action, 'data.user') || _.get(action, 'meta.previousAction.data.user');
            const projectsFromBackend = _.get(action, 'payload.data.data.listProjects') || _.get(action, 'data.cache.projects');
            newState.projectsFromBackend = mapProjects(projectsFromBackend);
            newState.projectTree = mapProjectTree(newState.projectsFromBackend, newState.projectTree, user);

            const isSelectedProjectAvailable = newState.projectTree
                .some(project => project.businessId === newState.selectedProject.businessId);
            if (!isSelectedProjectAvailable) {
                const autoSelectedProject = firstChildOfFirstRoot(newState.projectTree);
                if (autoSelectedProject) {
                    newState.selectedProject = autoSelectedProject;
                }
                toggleOpenInTree(newState.projectTree, newState.projectTree.find(project => !project.parent).businessId);
            }
            toggleSelectedInTree(newState, newState.selectedProject.businessId);
            updateSelectedProjectDetails(newState);
            newState.personalProject = newState.projectTree.find(project => isTodoProject(project));
            return newState;
        }
        case actionTypes.TOGGLE_PROJECT_FILTER_OPEN: {
            const newState = _.cloneDeep(state);
            toggleOpenInTree(newState.projectTree, action.data.businessId);
            const firstSubProject = newState.projectTree
                .find(project => {
                    return _.get(project, "root.businessId") === action.data.businessId && action.data.businessId === _.get(project, "parent.businessId")
                });
            if (firstSubProject) {
                toggleSelectedInTree(newState, firstSubProject.businessId);
                updateSelectedProjectDetails(newState, firstSubProject.businessId);
            } else {
                updateSelectedProjectDetails(newState);
            }
            return newState;
        }
        case actionTypes.TOGGLE_PROJECT_FILTER_SELECTION: {
            const newState = _.cloneDeep(state);
            newState.previouslySelectedProject = _.cloneDeep(newState.selectedProject);
            toggleSelectedInTree(newState, action.data.businessId);
            updateSelectedProjectDetails(newState, action.data.businessId);
            return newState;
        }
        case actionTypes.TOGGLE_PROJECT_FILTER_SELECTION_BY_ORG_LEVEL_ID: {
            const newState = _.cloneDeep(state);
            const projectToFilter = newState.projectTree.find(project => project.organizationLevelId === action.data.organizationLevelId);
            if (projectToFilter) {
                toggleSelectedInTree(newState, projectToFilter.businessId);
                updateSelectedProjectDetails(newState, projectToFilter.businessId);
            }
            return newState;
        }
        case actionTypes.DELETE_PROJECT_SUCCESS: {
            const newState = _.cloneDeep(state);
            const deletedProject = newState.projectTree.find(project => project.businessId === action.payload.data.data.deleteProject);
            const deletedProjectIndex = newState.projectTree.findIndex(project => project.businessId === action.payload.data.data.deleteProject);
            const nearestSibling = newState.projectTree
                .map((project, i) => {
                    return {...project, index: i}
                })
                .filter(project => project.businessId !== action.payload.data.data.deleteProject)
                .filter(project => _.get(project, 'root.businessId') === deletedProject.root.businessId)
                .sort(function (a, b) {
                    return Math.abs(a.index - deletedProjectIndex) - Math.abs(b.index - deletedProjectIndex)
                });
            if (newState.selectedProject.businessId === action.payload.data.data.deleteProject) {
                const newSelectedProjectBusinessId = ((nearestSibling.length > 0 && nearestSibling[0]) || {}).businessId;
                toggleSelectedInTree(newState, newSelectedProjectBusinessId);
                updateSelectedProjectDetails(newState, newSelectedProjectBusinessId);
            }
            return newState;
        }
        case actionTypes.OPEN_PROJECT_TREE: {
            const newState = {...state};
            newState.isProjectTreeOpen = true;
            return newState;
        }
        case actionTypes.CLOSE_PROJECT_TREE: {
            const newState = {...state};
            newState.isProjectTreeOpen = false;
            return newState;
        }
        case actionTypes.TOGGLE_SELECTED_USERS: {
            const newState = _.cloneDeep(state);
            const indexOfUserName = newState.selectedUserNames.indexOf(action.data.username);
            if (indexOfUserName !== -1) {
                newState.selectedUserNames.splice(indexOfUserName, 1);
            } else {
                newState.selectedUserNames.push(action.data.username);
            }
            return newState;
        }
        case taskActionTypes.CREATE_TASK_TO_CACHE:
        case taskActionTypes.EDIT_TASK_TO_CACHE: {
            const newState = _.cloneDeep(state);
            const newAssignee = _.get(action, 'data.task.assignee');
            return updateMembership(newState, newAssignee);
        }
        case taskActionTypes.CREATE_TASK_SUCCESS: {
            const newState = _.cloneDeep(state);
            const newAssignee = _.get(action, 'payload.data.data.createTask.assignee');
            return updateMembership(newState, newAssignee);
        }
        case taskActionTypes.EDIT_TASK_SUCCESS: {
            const newState = _.cloneDeep(state);
            const newAssignee = _.get(action, 'payload.data.data.editTask.assignee') || _.get(action, 'data.editTask.assignee');
            return updateMembership(newState, newAssignee);
        }
        case actionTypes.LOAD_PREVIOUS_PROJECT_SELECTION: {
            const newState = _.cloneDeep(state);
            if (newState.previouslySelectedProject) {
                newState.selectedProject = _.cloneDeep(newState.previouslySelectedProject);
            } else {
                newState.selectedProject = firstChildOfFirstRoot(newState.projectTree);
            }
            toggleSelectedInTree(newState, newState.selectedProject.businessId);
            updateSelectedProjectDetails(newState);
            return newState;
        }
        case actionTypes.UPDATE_PROJECT: {
            const newState = {...state};
            const {businessId, taskBusinessIds, activeTaskColumnOrders} = _.get(action, 'payload.request.data.variables.updateProjectInput', {});
            const index = state.projectsFromBackend.findIndex(p => p.businessId === businessId);
            update(newState.projectsFromBackend, {
                [index]: {
                    ...(activeTaskColumnOrders && {activeTaskColumnOrders: {$set: activeTaskColumnOrders}}),
                    ...(taskBusinessIds && {taskBusinessIds: {$set: taskBusinessIds}})
                }
            })
            return newState;
        }
        default:
            return state;
    }
}

function updateMembership(newState, newAssignee) {
    const memberToPromote = newState.selectedProject.members.find(member => _.get(member, 'user.businessId') === newAssignee.businessId);
    if (memberToPromote && memberToPromote.membership === 'OBSERVER') {
        memberToPromote.membership = 'MEMBER'
    }
    return newState;
}

export function firstChildOfFirstRoot(projectTree) {
    const firstRoot = projectTree.find(project => !project.parent);
    return projectTree.find(project => _.get(project, 'parent.businessId') === firstRoot.businessId);
}

//TODO LM: Mapping for project and tree should go to domain file
export function mapProjects(projectsFromBackend) {
    return projectsFromBackend.map(project => {
        return {
            businessId: project.businessId,
            name: project.name,
            description: project.description,
            parentProject: project.parentProject,
            parentHierarchy: project.parentHierarchy || [],
            activeTaskColumnOrders: project.activeTaskColumnOrders,
            taskBusinessIds: project.taskBusinessIds,
            members: project.members.map(m => apiProjectMember(m)),
            ordinal: project.ordinal,
            type: project.type,
            organizationLevelId: project.organizationLevelId,
        }
    })
}

export function mapProjectTree(projectsFromBackend, projectTree, user) {
    const newProjectTree = _.sortBy(projectsFromBackend, 'ordinal').map(project => {
        const oldTreeNode = projectTree.find(projectInOldTree => projectInOldTree.businessId === project.businessId);
        const parentProject = _.get(project, 'parentProject');
        const parent = parentProject && {
            ...parentProject,
            editable: canEditProject(user, projectsFromBackend
                .find(projectInArray => projectInArray.businessId === parentProject.businessId))
        };
        const rootProject = projectsFromBackend
            .find(projectInArray => projectInArray.businessId === _.get(project, 'parentHierarchy[0].projectBusinessId'));
        const root = rootProject && {
            businessId: rootProject.businessId,
            type: rootProject.type
        };
        const userMembership = taskUtils.getUserMembership(user, project.members);
        return {
            parent,
            root,
            rootMenuVisible: !isOrganizationLevelProject(project),
            businessId: project.businessId,
            name: project.name,
            members: project.members,
            users: project.members.map(member => (member.user)),
            title: project.name,
            activeTaskColumnOrders: project.activeTaskColumnOrders,
            taskBusinessIds: project.taskBusinessIds,
            ordinal: project.ordinal,
            type: project.type,
            organizationLevelId: project.organizationLevelId,
            open: oldTreeNode ? oldTreeNode.open : parent !== null,
            selected: oldTreeNode ? oldTreeNode.selected : false,
            editable: canEditProject(user, project),
            currentUser: {
                hasEditRight: taskUtils.hasEditRight(userMembership),
                hasAccess: taskUtils.hasAccess(userMembership)
            },
            breadCrumb: (project.parentHierarchy || []).concat([project])
        }
    });
    if (_.isEmpty(projectTree)) {
        newProjectTree[0].selected = true;
    }
    return newProjectTree;
}

export function toggleOpenInTree(projectTree, businessId) {
    const changedProject = projectTree.find(project => project.businessId === businessId);
    if (!changedProject.parent) {
        const otherRootProjects = projectTree.filter(project => !project.parent && (project.businessId !== businessId));
        otherRootProjects.forEach(otherRootProject => otherRootProject.open = false);
    }
    changedProject.open = !changedProject.open;
}

export function toggleSelectedInTree(newState, newlySelectedProjectBusinessId) {
    const newlySelectedProject = newState.projectTree
        .find(node => node.businessId === newlySelectedProjectBusinessId) || {};
    if (newState.selectedProject.businessId !== '') {
        const currentlySelectedProject = newState.projectTree
            .find(node => node.businessId === newState.selectedProject.businessId) || {};
        currentlySelectedProject.selected = false;
    }
    newlySelectedProject.selected = true;
}

export function updateSelectedProjectDetails(newState, businessId) {
    const selectedBusinessId = businessId || newState.selectedProject.businessId;
    newState.selectedProject = newState.projectTree.find(project => project.businessId === selectedBusinessId);
    const availableUsers = _.flatMap(newState.selectedProject.members, member => _.get(member, 'user.username'));
    const availableSelectedUserNames = _.intersection(newState.selectedUsernames, availableUsers);
    newState.selectedUserNames = availableSelectedUserNames;
}

export function canEditProject(user, project) {
    const membership = taskUtils.getUserMembership(user, _.get(project, 'members'));
    return taskUtils.isAdmin(membership) && !isTodoProject(project) && !isOrganizationLevelProject(project);
}

export function isTodoProject(project) {
    const type = _.get(project, 'type', '');
    const name = _.get(project, 'name', '');
    return type === 'PERSONAL' && name === 'Miscellaneous topics';
}

export function isOrganizationLevelProject(project) {
    const type = _.get(project, 'type', '');
    const name = _.get(project, 'name', '');
    return (type === 'SYSTEM' && name === 'Areas') || (type === 'ORGANIZATION_LEVEL');
}
