import * as actionTypes from './Questionnaires.action.types'
import {mapQuestionnaireForCreate} from "domain/audit/Questionnaire.model";
import {USER_GRAPHQL} from "domain/User.model";

export function fetchQuestionnaires() {
    return (dispatch, getState) => {
        return dispatch({
            type: actionTypes.FETCH_QUESTIONNAIRES,
            data: {
                user: getState().mainReducer.user,
            },
            payload: {
                client: 'graphql',
                request: {
                    method: 'post',
                    data: {
                        query: `
                    {
                        listQuestionnaires {
                            businessId
                            name
                            status
                            tags {
                                businessId
                                category
                                label
                            }
                            numberOfQuestions
                            numberOfRandomizedQuestions                            
                            catalogue 
                            cycle {                                                            
                                label
                            }
                            organizationLevels {
                                businessId
                                shortName
                                members {
                                    memberships
                                    user {
                                        ${USER_GRAPHQL} 
                                    }
                                }
                            }
                            questions {
                                businessId
                            }
                            randomizedQuestions {
                                numberOfQuestions
                                catalogue
                            } 
                            assignedAuditSchedules {
                                name
                                status
                            }
                        }
                    }
                    `
                    }
                }
            }
        })
    };
}

export function updateQuestionnaireStatus(questionnaireId, status) {
    return {
        type: actionTypes.UPDATE_QUESTIONNAIRE,
        payload: {
            client: 'graphql',
            request: {
                method: 'post',
                data: {
                    query: `mutation updateQuestionnaireStatus($questionnaireId: String!, $status: String!){
    	                        updateQuestionnaireStatus(questionnaireId: $questionnaireId, status: $status)
                            }
                           `,
                    variables: {
                        questionnaireId,
                        status

                    }

                }

            }

        }

    }

}

export function deleteQuestionnaire(questionnaireId) {
    return {
        type: actionTypes.DELETE_QUESTIONNAIRE,
        payload: {
            client: 'graphql',
            request: {
                method: 'post',
                data: {
                    query: `mutation removeQuestionnaire($questionnaireId: String){
    	                        removeQuestionnaire(questionnaireId: $questionnaireId)
                            }
                           `,
                    variables: {
                        questionnaireId
                    }
                }
            }
        }
    }
}

export function cloneQuestionnaire(data) {
    return (dispatch) => {
        const input = mapQuestionnaireForCreate(data);
        return dispatch({
            type: actionTypes.CLONE_QUESTIONNAIRE,
            payload: {
                client: 'graphql',
                request: {
                    method: 'post',
                    data: {
                        query: `mutation createQuestionnaire($createQuestionnaireInput: CreateQuestionnaireInput){
    	                        createQuestionnaire(createQuestionnaireInput: $createQuestionnaireInput) {   
    	                            businessId   	                            
    	                        }
                            }
                           `,
                        variables: {
                            createQuestionnaireInput: input
                        }
                    }
                }
            }
        });
    }
}
