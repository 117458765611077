import React, {Component} from 'react';
import {
    withStyles,
} from '@material-ui/core';
import {withTranslation} from 'react-i18next';
import FileGalleryItem from './FileGalleryItem';
import Flex from 'components/grid/Flex';

const styles = theme => ({
});

class FileGallery extends Component {
    render() {
        const {sources, deleteSource, imageSize,readOnly} = this.props;
        return (
            <Flex container item style={{flexWrap: 'wrap', padding: 5}}>
                {
                    sources.map((source, index) => (
                        <FileGalleryItem
                            key={index}
                            {...{
                                filename: source.filename,
                                index,
                                readOnly,
                                downloadUrl: source.downloadUrl,
                                deleteFile: deleteSource(source),
                                imageSize: imageSize
                            }}
                        />
                    ))
                }
            </Flex>
        );
    }
}

export default withStyles(styles)(withTranslation()(FileGallery));
