import {
    toggleProjectFilterSelection,
    toggleProjectFilterOpen,
    createProject,
    editProject,
    deleteProject,
    listProjects,
    openProjectTree,
    closeProjectTree
} from 'scenes/tasks/Project.action'

export default {
    mapStateToProps: (state) => {
        return {
            projectTree: state.projectReducer.projectTree,
            isProjectTreeOpen: state.projectReducer.isProjectTreeOpen,
            selectedProject: state.projectReducer.selectedProject,
            user: state.mainReducer.user,
            applicationOnline: state.mainReducer.online,
        };
    },
    mapDispatchToProps: (dispatch) => {
        return {
            listProjects:() => dispatch(listProjects()),
            toggleProjectFilterSelection: businessId => () =>  dispatch(toggleProjectFilterSelection(businessId)),
            toggleProjectFilterOpen: businessId => () => dispatch(toggleProjectFilterOpen(businessId)),
            createProject: (parentBusinessId, name) => dispatch(createProject(parentBusinessId, name)),
            editProject: (businessId, name) => dispatch(editProject(businessId, name)),
            deleteProject: (businessId) => dispatch(deleteProject(businessId)),
            openProjectTree: () => dispatch(openProjectTree()),
            closeProjectTree: () => dispatch(closeProjectTree()),
        };
    }
};
