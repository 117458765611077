export const LOAD_ACCESS_TOKEN = '@main/LOAD_ACCESS_TOKEN';

export const LOGIN = '@main/LOGIN';
export const LOGIN_SUCCESS = '@main/LOGIN_SUCCESS';
export const LOGIN_FAIL = '@main/LOGIN_FAIL';

export const LOGOUT = '@main/LOGOUT';

export const SESSION_TIMEOUT = '@main/SESSION_TIMEOUT';

export const FETCH_USER = '@main/FETCH_USER';
export const FETCH_USER_SUCCESS = '@main/FETCH_USER_SUCCESS';
export const FETCH_USER_FAIL = '@main/FETCH_USER_FAIL';

export const UPDATE_APPLICATION_INFORMATION = '@main/UPDATE_APPLICATION_INFORMATION';

export const SYNCHRONIZATION_STARTED = '@main/SYNCHRONIZATION_STARTED';
export const SYNCHRONIZATION_FINISHED = '@main/SYNCHRONIZATION_FINISHED';
export const TOGGLE_ONLINE = '@main/TOGGLE_ONLINE';

export const BAD_SERVER_CONNECTION_DETECTED = '@main/BAD_SERVER_CONNECTION_DETECTED';
export const FAST_SERVER_CONNECTION_DETECTED = '@main/FAST_SERVER_CONNECTION_DETECTED';
export const SERVER_CONNECTION_LOST = '@main/SERVER_CONNECTION_LOST';

export const UNSUBSCRIBE = '@main/UNSUBSCRIBE';
export const UNSUBSCRIBE_SUCCESS = '@main/UNSUBSCRIBE_SUCCESS';
export const UNSUBSCRIBE_FAIL = '@main/UNSUBSCRIBE_FAIL';