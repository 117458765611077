import React, {Component} from 'react';
import _ from 'lodash';
import moment from 'moment';
import Flex from 'components/grid/Flex';
import {connect} from 'react-redux';
import connector from './TaskEdit.connect';
import MobileTaskEdit from './components/mobile/MobileTaskEdit';
import DesktopTaskEdit from './components/DesktopTaskEdit';
import {Route, Switch} from 'react-router-dom';
import {routeTo, TASK, TASK_CREATE, TASK_EDIT} from 'routes/routes';
import utils from 'utils/Utils';
import {withTranslation} from "react-i18next";
import update from 'immutability-helper';
import taskUtils from "scenes/tasks/Task.utils.js";
import ProjectAuthorizationErrorLabel from "scenes/tasks/components/ProjectAuthorizationErrorLabel";
import {Helmet} from "react-helmet";
import EditorUtils, {NEW} from 'components/quill-editor/EditorUtils';
import SaveIndicatorMessage from "components/snack-messages/SaveIndicatorMessage";

export class TaskEdit extends Component {
    constructor(props) {
        super(props);
        this.leaveOnPurpose = false;
        this.mounted = false;
        this.state = {
            task: undefined,
            isTranslationChanged: false,
            isTaskSetupChanged: false,
            isDueDateEdited: !!this.props.taskBusinessId,
            showSaveIndicator: false,
            language: props.i18n.language
        }
    }

    unloadHandler = event => {
        //LM NOTE: This message is ignored by all modern browsers in favor of their own.
        //LM NOTE: Browser pop-ups can be turned off by plugins and browser settings.
        const message = 'You are leaving the site, changes might not have been saved';
        if (this.state.isTranslationChanged || this.state.isTaskSetupChanged) {
            event.returnValue = message;
            return message;
        }
    };

    //TODO LM: The premise that the Task edit can handle a task object - in isolation - until save is no longer true!
    //TODO LM: We have too much logic in DidUpdate and in the DidMount.
    //TODO LM: We have to move all the task mutation/ update into actions and the reducer!
    componentDidMount() {
        this.mounted = true;
        window.addEventListener("beforeunload", this.unloadHandler);
        const prevPath = _.get(this.props, 'routeProps.location.state.prevPath');
        this.props.arrivedFrom(prevPath);
        if (this.props.taskBusinessId) {
            this.props.fetchTask(this.props.taskBusinessId)
                .then(() => {
                        if (prevPath === TASK_CREATE.path) {
                            this.showSaveIndicator();
                        }
                        this.setState({
                            task: _.cloneDeep(this.props.task)
                        });
                        if (this.props.selectedProject.businessId !== this.props.task.projectBusinessId) {
                            this.props.toggleProjectFilterSelection(this.props.task.projectBusinessId);
                        }
                        const task = _.get(this.props, 'task');
                        if (task && task.isUnseen) {
                            this.props.removeUnseenContentTag(task);
                        }
                    }
                )
        } else {
            this.props.getTemporaryTaskReferenceId().then(() => {
                    this.setState(
                        {
                            task: _.cloneDeep(this.props.task),
                        },
                        () => {
                            const taskTitle = _.get(this.props, 'routeProps.location.state.issueTitle');
                            const status = _.get(this.props, 'routeProps.location.state.workflowStatus');
                            if (taskTitle && status) {
                                this.setState({
                                    task: {
                                        ...this.state.task,
                                        status
                                    }
                                }, () => this.handleTitleChange({target: {value: taskTitle}}))
                            } else if (status) {
                                this.setState({
                                    task: {
                                        ...this.state.task,
                                        status
                                    }
                                })
                            } else if (taskTitle) {
                                this.handleTitleChange({target: {value: taskTitle}})
                            }
                        });
                }
            );
        }
    }

    componentWillUnmount() {
        this.mounted = false;
        window.removeEventListener("beforeunload", this.unloadHandler);
        try {
            if (!this.leaveOnPurpose) {
                this.saveTask(false);
            }
        } catch (e) {
            console.log('Failed to save task when user left edit page: ', e);
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {task} = this.props;
        const isPreviousBusinessIdOffline = _.get(prevProps, 'task.businessId', '').startsWith('OFFLINE');
        const isCurrentBusinessIdOnline = _.isFinite(Number(task.businessId));
        const isStateAlreadyChanged = _.isFinite(Number(_.get(this.state, 'task.businessId')));

        if (this.state.language !== this.props.i18n.language) {
            this.setState({
                language: this.props.i18n.language,
                task: {
                    ...this.props.task
                },
            })
        }

        if (isPreviousBusinessIdOffline && isCurrentBusinessIdOnline && !isStateAlreadyChanged) {
            this.setState({
                    task: {
                        ...this.state.task,
                        businessId: task.businessId
                    }
                },
                () => {
                    routeTo(TASK_EDIT.pathFromOfflineToId(this.props.routeProps.location.pathname, task.businessId), this.props.routeProps);
                }
            )
        }
    }

    handleChangeOnTask = event => {
        this.setState({
            task: {
                ...this.state.task,
                [event.target.name]: event.target.value,
            },
            isTaskSetupChanged: this.props.task[event.target.name] !== event.target.value
        });
    };

    handleDescriptionChange = newEditorState => {
        const changes = EditorUtils.calculateTranslationsChange(
            newEditorState,
            this.state.task.translations,
            this.props.task.translations,
            this.props.i18n.language
        );

        if (changes.isContentDifferent) {
            const isContentStateEverChanged = changes.isContentDifferent || this.state.isTranslationChanged;
            this.setState(oldState => {
                return {
                    task: update(oldState.task, {
                        translations: {$set: changes.translations}
                    }),
                    isTranslationChanged: isContentStateEverChanged,
                };
            });
        }
    };

    handleTitleChange = event => {
        const target = event.target;
        const matchingTranslationIndex = this.state.task.translations
            .findIndex(translation => translation.language.code === this.props.i18n.language);
        if (this.state.task.translations[matchingTranslationIndex].title !== target.value) {
            const translations = this.state.task.translations.map((translation, index) => {
                if (index === matchingTranslationIndex) {
                    return {
                        ...translation,
                        title: target.value,
                        origin: NEW
                    }
                } else {
                    return this.props.task.translations[index]
                }
            });
            this.setState(oldState => {
                return {
                    task: update(oldState.task, {
                        translations: {$set: translations}
                    }),
                    isTranslationChanged: true,
                };
            });
        }
    };

    handleDueDateChange = date => {
        this.setState({
            task: {
                ...this.state.task,
                dueDate: date,
                dueDateProgress: utils.dueDateProgress(this.state.task.creationDate, date, new Date()),
            },
            isTaskSetupChanged: this.props.task.dueDate !== date,
            isDueDateEdited: true
        });
    };

    handlePriorityChange = event => {
        const priority = event.target.value;
        const numberOfDaysToAdd = this.numberOfDaysToAddToInitialDueDate(this.props.deadlines, priority);
        this.setState({
            task: {
                ...this.state.task,
                priority: {type: priority},
                dueDate: !this.state.isDueDateEdited
                    ? moment().add(numberOfDaysToAdd, 'days').toDate()
                    : this.state.task.dueDate
            },
            isTaskSetupChanged: this.props.task.priority.type !== priority,
        });
    };

    handleResponsibleChange = user => {
        this.setState({
            task: {
                ...this.state.task,
                assignee: user
            },
            isTaskSetupChanged: _.get(this.props, 'task.assignee.businessId') !== user.businessId
        });
    };

    numberOfDaysToAddToInitialDueDate = (solveUntilConfiguration, priority) => {
        const matchingSolveUntil = (solveUntilConfiguration || [])
            .find(solveUntilInArray => solveUntilInArray.priority.type === priority);
        return _.get(matchingSolveUntil, 'quantity', 0);
    };

    saveTask = (forceTranslate) => {
        if (!this.isSaveDisabled()) {
            if (this.props.taskBusinessId) {
                this.props.editTask(this.state.task, forceTranslate).then(() => {
                    this.showSaveIndicator();
                    this.setState({
                        isTranslationChanged: false,
                        isTaskSetupChanged: false
                    })
                });
            } else {
                this.props.createTask(this.state.task).then(response => {
                    if (this.mounted) {
                        const taskBusinessId = _.get(response, 'payload.data.data.createTask.businessId') || _.get(response, 'data.task.businessId');
                        if (taskBusinessId) {
                            this.leaveEdit();
                            routeTo(TASK_EDIT.pathWithId(taskBusinessId), this.props.routeProps)
                        }
                    }
                })
            }
        }
    };

    leaveEdit = () => {
        this.leaveOnPurpose = true;
    };

    onSuccessfulFileUpload = attachment => {
        this.fetchStateTask();
    };

    onSuccessfulFileDelete = attachment => {
        this.fetchStateTask();
    };

    handleTagDelete = index => {
        this.setState(oldState => {
            return {
                task: {
                    ...oldState.task,
                    freeTags: update(oldState.task.freeTags, {
                        $splice: [[index, 1]]
                    })
                },
                isTaskSetupChanged: true
            };
        })
    };

    handleTagAddition = tag => {
        this.setState(oldState => {
            return {
                task: {
                    ...oldState.task,
                    freeTags: update(oldState.task.freeTags || [], {
                        $push: [tag]
                    })
                },
                isTaskSetupChanged: true
            }
        })
    };

    fetchStateTask = () => {
        this.props.fetchTask(this.state.task.businessId);
    };

    removeUnseenActionTag = () => {
        const task = this.state.task;
        if (task && task.hasUnseenAction) {
            this.props.removeUnseenActionTag(task)
        }
    };

    removeUnseenCommentTag = () => {
        const task = this.state.task;
        if (task && task.hasUnseenComment) {
            this.props.removeUnseenCommentTag(task)
        }
    };

    removeUnseenChecklistTag = () => {
        const task = this.state.task;
        if (task && task.hasUnseenCriteria) {
            this.props.removeUnseenChecklistTag(task)
        }
    };

    removeUnseenAttachmentTag = () => {
        const task = this.state.task;
        if (task && task.hasUnseenAttachment) {
            this.props.removeUnseenAttachmentTag(task)
        }
    };

    showSaveIndicator = () => {
        this.setState({showSaveIndicator: true});
    };

    closeSaveIndicator = () => {
        this.setState({showSaveIndicator: false});
    };

    shouldForceTranslateAutomatically = () => {
        return EditorUtils.shouldForceTranslateAutomatically(this.state.task.translations, this.props.i18n.language);
    };

    isSaveDisabled = () => {
        const matchingTranslation = this.matchingTranslation();
        return !matchingTranslation.description
            || !matchingTranslation.title
            || !this.state.task.priority.type
            || !this.state.task.status
            || !this.state.task.dueDate
            || !this.props.selectedProject.currentUser.hasEditRight
            || (!this.state.isTranslationChanged && !this.state.isTaskSetupChanged)
    };

    matchingTranslation = () => {
        return this.state.task.translations.find(translation => translation.language.code === this.props.i18n.language)
            || this.state.task.translations[0];
    };

    goBack = () => {
        this.leaveEdit();
        routeTo(this.props.leavePath || TASK.path, this.props.routeProps);
    };

    render() {
        const {task, isTranslationChanged, showSaveIndicator, language} = this.state;
        const {
            newTaskRefId, user, t: translate, archiveTask, cloneTask, applicationOnline, selectedProject, removeUnseenContentTag,
            filters, freeTags, deviationSeverities, taskSeverities, activeWorkflowStatuses, comments, attachments,
            checklist, actions, selectableStatuses, todoTaskBusinessId
        } = this.props;
        const {
            handleChangeOnTask, handlePriorityChange, handleDueDateChange, handleResponsibleChange,
            handleDescriptionChange, saveTask, leaveEdit, handleTitleChange, closeSaveIndicator,
            onSuccessfulFileUpload, onSuccessfulFileDelete, handleTagAddition, handleTagDelete, fetchStateTask,
            removeUnseenActionTag, removeUnseenCommentTag, removeUnseenChecklistTag, removeUnseenAttachmentTag,
            shouldForceTranslateAutomatically, goBack
        } = this;
        const isMobile = utils.checkBrowser();
        const taskBusinessId = this.props.taskBusinessId;
        const isTaskSelected = !!taskBusinessId;
        const idPrefix = taskUtils.idPrefix(task, translate);
        const isTaskEditable = selectedProject.currentUser.hasEditRight && todoTaskBusinessId !== taskBusinessId;
        const projectBreadCrumb = isTaskSelected
            ? _.get(task, 'projectBreadCrumb')
            : selectedProject.breadCrumb;
        const tabLabelSuffix = taskBusinessId
            ? idPrefix + taskBusinessId
            : `${translate('global.new')} ${translate('global.task')}`;
        const tabLabel = `${_.get(window, 'appConfig.environment')} - ${tabLabelSuffix}`;
        let matchingTranslation;
        let isSaveDisabled;
        if (!_.isEmpty(task)) {
            matchingTranslation = this.matchingTranslation();
            isSaveDisabled = this.isSaveDisabled();
        }
        return (
            selectedProject.currentUser.hasAccess ?
                <Flex item container column>
                    <Helmet>
                        <title>{tabLabel}</title>
                    </Helmet>
                    {
                        !_.isEmpty(task) &&
                        <Switch>
                            <Route path={[TASK_CREATE.path, TASK_EDIT.path]}
                                   render={function renderTaskCreateOrEdit(props) {
                                       return (
                                           isMobile
                                               ?
                                               <MobileTaskEdit
                                                   {...{
                                                       task, comments, attachments, checklist, actions, newTaskRefId,
                                                       freeTags, deviationSeverities, taskSeverities, user,
                                                       activeWorkflowStatuses, taskBusinessId, isTaskEditable,
                                                       applicationOnline, isTranslationChanged, projectBreadCrumb,
                                                       selectedProject, isSaveDisabled, matchingTranslation,
                                                       statuses: selectableStatuses,
                                                       isSelected: isTaskSelected,
                                                       routeProps: props,
                                                       view: filters.view,
                                                       save: saveTask,
                                                       leaveEdit,
                                                       fetchStateTask,
                                                       archiveTask,
                                                       goBack,
                                                       shouldForceTranslateAutomatically,
                                                       handleChangeOnTask,
                                                       handlePriorityChange,
                                                       handleDueDateChange,
                                                       handleResponsibleChange,
                                                       handleDescriptionChange,
                                                       handleTitleChange,
                                                       handleTagAddition,
                                                       handleTagDelete,
                                                       removeUnseenContentTag,
                                                       removeUnseenActionTag,
                                                       removeUnseenCommentTag,
                                                       removeUnseenChecklistTag,
                                                       removeUnseenAttachmentTag,
                                                       onSuccessfulFileUpload,
                                                       onSuccessfulFileDelete,
                                                       language
                                                   }}
                                               />
                                               :
                                               <DesktopTaskEdit
                                                   {...{
                                                       task, comments, attachments, checklist, actions, idPrefix,
                                                       user, newTaskRefId, freeTags, deviationSeverities,
                                                       taskSeverities, isTaskEditable, applicationOnline,
                                                       projectBreadCrumb, isTranslationChanged, isSaveDisabled,
                                                       matchingTranslation,
                                                       isSelected: isTaskSelected,
                                                       statuses: selectableStatuses,
                                                       users: selectedProject.users,
                                                       routeProps: props,
                                                       attachmentUploadEnabled: isTaskEditable,
                                                       save: saveTask,
                                                       leaveEdit,
                                                       archiveTask,
                                                       cloneTask,
                                                       handleTitleChange,
                                                       fetchStateTask,
                                                       goBack,
                                                       shouldForceTranslateAutomatically,
                                                       handleChangeOnTask,
                                                       handlePriorityChange,
                                                       handleDueDateChange,
                                                       handleResponsibleChange,
                                                       handleDescriptionChange,
                                                       handleTagAddition,
                                                       handleTagDelete,
                                                       removeUnseenContentTag,
                                                       removeUnseenActionTag,
                                                       removeUnseenCommentTag,
                                                       removeUnseenChecklistTag,
                                                       removeUnseenAttachmentTag,
                                                       onSuccessfulFileUpload,
                                                       onSuccessfulFileDelete,
                                                       language
                                                   }}
                                               />
                                       )
                                   }}
                            />
                        </Switch>
                    }
                    <SaveIndicatorMessage {...{open: showSaveIndicator, onClose: closeSaveIndicator}}/>
                </Flex>
                :
                <ProjectAuthorizationErrorLabel/>
        );
    }
}

export default connect(connector.mapStateToProps, connector.mapDispatchToProps)(withTranslation()(TaskEdit));
