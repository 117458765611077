import React, {Component} from 'react';
import {withStyles, IconButton} from "@material-ui/core";
import Flex from 'components/grid/Flex';
import ActionTile from './ActionTile';
import AddIcon from '@material-ui/icons/Add';
import {TASK_ACTION_CREATE} from 'routes/routes';
import MobileTaskHeader from "scenes/tasks/components/task-header/MobileTaskHeader";
import {htmlGrey} from 'components/colors/Colors';
import {routeTo} from 'routes/routes';
import _ from 'lodash';

const styles = theme => ({
    actionContainer: {
        width: '100%',
        borderTopWidth: 1,
        borderTopStyle: 'solid',
        borderTopColor: htmlGrey,
        borderRadius: 'unset',
        paddingTop: 6,
        paddingBottom: 6,
    },
});

export class MobileActionList extends Component {

    toActionCreate = () => {
        routeTo(TASK_ACTION_CREATE.pathWithId(this.props.taskBusinessId), this.props);
    };

    render() {
        const {classes, task, actions, url, applicationOnline} = this.props;
        return !_.isEmpty(task) && (
            <Flex item container direction={'column'}>

                <MobileTaskHeader
                    {...{
                        task,
                        actions,
                        url,
                        applicationOnline,
                    }}
                />

                <Flex container item direction={'column'} style={{overflow: 'auto'}}>
                    {
                        task.actions.map((action, index) => (
                            <Flex item grow={0} key={index} className={classes.actionContainer}>
                                <ActionTile {...{action, taskBusinessId: task.businessId, applicationOnline}}/>
                            </Flex>
                        ))
                    }
                    <Flex item grow={0} container justifyContent={'center'}>
                        <IconButton style={{padding: 0}} onClick={this.toActionCreate}>
                            <AddIcon style={{fontSize: 45}}/>
                        </IconButton>
                    </Flex>
                </Flex>
            </Flex>
        );
    }
}

export default withStyles(styles)(MobileActionList);
