import * as actionTypes from './GeneralConfiguration.action.types';
import _ from 'lodash';
import {mapConfigurations, mapCatalogue, mapAuditType} from "domain/audit/AuditConfiguration.model";

const initialState = {
    auditConfiguration: {
        ready: false,
        answerTypes: [],
        catalogues: [],
        cataloguesForAdministration: [],
        cycles: [],
        supportedLanguages: [],
        masterLanguage: {},
        auditTypes: [],
        auditTypesHistory: [],
        durationTypes: [],
        durationUnits: [],
        auditResultDefinitions: [],
        supportedWeekdays: []
    },
    assignedQuestionnaires: {
        catalogue: {},
        questionnaires: []
    }
};

export default function (state = initialState, action) {
    switch (action.type) {
        case actionTypes.FETCH_AUDIT_MANAGEMENT_CONFIGURATION_DATA_SUCCESS: {
            const newState = _.cloneDeep(state);
            if (_.get(action, 'payload.data.data.fetchAuditManagementConfigurationData')) {
                newState.auditConfiguration = mapConfigurations(action.payload.data.data.fetchAuditManagementConfigurationData);
            }
            return newState;
        }
        case actionTypes.ADD_QUESTION_CATEGORY_DATA_SUCCESS: {
            const newState = _.cloneDeep(state);
            const createCatalogue = _.get(action, 'payload.data.data.createQuestionCatalogue');
            if (createCatalogue) {
                newState.auditConfiguration.cataloguesForAdministration.push(mapCatalogue(createCatalogue));
                newState.auditConfiguration.cataloguesForAdministration =
                    _.sortBy(newState.auditConfiguration.cataloguesForAdministration, catalogue => catalogue.label.toLowerCase());
            }
            return newState;
        }
        case actionTypes.REMOVE_QUESTION_CATEGORY_DATA_SUCCESS: {
            const newState = _.cloneDeep(state);
            const removedCatalogueId = _.get(action, 'payload.data.data.removeQuestionCatalogue.success.businessId');
            if (removedCatalogueId) {
                _.remove(newState.auditConfiguration.cataloguesForAdministration, item => item.businessId === removedCatalogueId);
                newState.assignedQuestionnaires = {};
            } else {
                const catalogueBusinessId = _.get(action, 'meta.previousAction.data.businessId');
                newState.assignedQuestionnaires = {
                    catalogue: _.find(state.auditConfiguration.cataloguesForAdministration, ['businessId',catalogueBusinessId]),
                    questionnaires: _.get(action, 'payload.data.data.removeQuestionCatalogue.error.assignedQuestionnaires')
                };
            }
            return newState;
        }
        case actionTypes.RESET_TO_BE_CONFIRMED_QUESTIONNAIRES: {
            const newState = _.cloneDeep(state);
            newState.assignedQuestionnaires = {};
            return newState;
        }
        case actionTypes.ADD_AUDIT_TYPE_DATA_SUCCESS: {
            const newState = _.cloneDeep(state);
            const createdAuditType = _.get(action, 'payload.data.data.createAuditType');
            if (createdAuditType) {
                newState.auditConfiguration.auditTypes.push(mapAuditType(createdAuditType));
                newState.auditConfiguration.auditTypes =
                    _.sortBy(newState.auditConfiguration.auditTypes, auditType => auditType.label.toLowerCase());
            }
            return newState;
        }
        case actionTypes.REMOVE_AUDIT_TYPE_DATA_SUCCESS: {
            const newState = _.cloneDeep(state);
            const removedAuditTypeId = _.get(action, 'payload.data.data.removeAuditType.success.businessId');
            if (removedAuditTypeId) {
                _.remove(newState.auditConfiguration.auditTypes, item => item.businessId === removedAuditTypeId);
            }
            return newState;
        }
        default:
            return state;
    }
}