import React, {Component} from 'react';
import {withStyles} from "@material-ui/core";
import RedFlash from 'assets/img/lightning-icon-full-red.svg';
import OrangeFlash from 'assets/img/lightning-icon-full-orange.svg';


const styles = theme => ({
    priorityFlash: {
        height: 16,
        width: 16
    }
});

export class PriorityFlash extends Component {
    render() {
        const {classes, priority} = this.props;

        return (
            <span>
                {
                    priority === 'CRITICAL'
                        ?
                        <img src={RedFlash} alt={''} className={classes.priorityFlash}/>
                        :
                        <img src={OrangeFlash} alt={''} className={classes.priorityFlash}/>
                }
            </span>
        );
    }
}

export default withStyles(styles)(PriorityFlash);
