import React, { Component } from 'react';
import { withStyles, Typography } from "@material-ui/core";

const styles = theme => ({
    root: {
        marginTop: 20,
    },
});

export class Knowledge extends Component {
    render() {
        const {classes} = this.props;

        return (
            <Typography className={classes.root}>
                Knowledge
            </Typography>
        );
    }
}

export default withStyles(styles)(Knowledge);
