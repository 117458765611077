import {deleteQuestion, fetchQuestions} from './Questions.action'

export default {
    mapStateToProps: (state) => {
        return {
            questions: state.questionsReducer.questions,
            answerTypes: state.generalConfigurationReducer.auditConfiguration.answerTypes,
            catalogues: state.generalConfigurationReducer.auditConfiguration.catalogues,
            cycles: state.generalConfigurationReducer.auditConfiguration.cycles,
            supportedLanguages: state.generalConfigurationReducer.auditConfiguration.supportedLanguages,
            masterLanguage: state.generalConfigurationReducer.auditConfiguration.masterLanguage,
            user: state.mainReducer.user,
            applicationOnline: state.mainReducer.online
        };
    },
    mapDispatchToProps: (dispatch) => {
        return {
            fetchQuestions: () => dispatch(fetchQuestions()),
            deleteQuestion: (questionId) => dispatch(deleteQuestion(questionId))
        };
    }
};
