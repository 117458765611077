import React, {Component} from 'react';
import {withStyles} from '@material-ui/core';
import Sidebar from 'components/sidebar/Sidebar';
import Flex from 'components/grid/Flex';
import {withRouter} from 'react-router-dom';
import {content} from 'components/zindex/zIndex';

const styles = theme => ({
    appFrame: {
        height: '100%',
        position: 'relative',
        fontFamily: 'Arial'
    },
    content: {
        width: '100%',
        zIndex: content,
    }
});

class SidebarLayout extends Component {

    render() {
        const {classes, location} = this.props;
        return (
            <Flex container className={classes.appFrame}>
                <Sidebar {...{location}}/>
                <Flex container item justifyContent={'center'}>
                    <main className={classes.content}>
                        {this.props.children}
                    </main>
                </Flex>
            </Flex>
        );
    }

}

export default withStyles(styles, {withTheme: true})(withRouter(SidebarLayout));
