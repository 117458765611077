import React, {Component, Fragment} from 'react';
import {Typography, withStyles,} from "@material-ui/core";
import Flex from 'components/grid/Flex';
import utils from 'utils/Utils';
import Select, {components} from 'react-select';
import H4Avatar from "components/avatar/H4Avatar";
import {htmlWhite} from 'components/colors/Colors.js'
import _ from 'lodash';

const styles = theme => ({
    userNameInSelector: {
        flex: '1 0 auto',
        width: 100,
    },
    userSelect: {
        paddingTop: 5
    }
});

const UserSelectorOption = props => {
    const {data, classes} = props;
    return (
        <components.Option {...props} >
            <Flex container alignItems={'center'}>
                <Typography className={classes.userNameInSelector}> {utils.formattedUserName(data)}</Typography>
                <H4Avatar {...{user: data}}/>
            </Flex>
        </components.Option>
    );
};

const SingleValueForSelect = props => {
    return (
        <Typography {...props.innerProps}>
            {props.children}
        </Typography>
    );
};

export class DocumentMachineStatusResponsible extends Component {
    render() {
        const {classes, theme} = this.props;
        const {users, responsible} = this.props.data;
        const {handleResponsibleChange} = this.props.actions;
        const selectStyles = {
            control: base => ({
                ...base,
                backgroundColor: htmlWhite,
                borderWidth: 2,
                height: 40,
                width: 205,
            }),
            input: base => ({
                ...base,
                '& input': {
                    font: theme.typography.fontFamily
                },
            }),
            container: base => ({
                ...base,
                display: "inline-block",
            }),
            menuList: base => (
                {
                    ...base,
                    height: 300
                }
            )
        };
        return (
            <Fragment>
                <H4Avatar {...{user: responsible}} style={{width: 35, height: 35, marginRight: 10}}/>
                <Select
                    classes={classes}
                    value={responsible}
                    styles={selectStyles}
                    options={users}
                    onChange={handleResponsibleChange}
                    getOptionValue={option => option.businessId}
                    getOptionLabel={option => !_.isEmpty(option) ? utils.formattedUserName(option) : ''}
                    menuPlacement={'auto'}
                    components={{
                        Option: props => <UserSelectorOption classes={classes} {...props}/>,
                        SingleValue: SingleValueForSelect
                    }}
                />
            </Fragment>
        );
    }
}

export default withStyles(styles, {withTheme: true})(DocumentMachineStatusResponsible);
