export default function Observable() {
    this.callbacks = [];

    this.subscribe = function (callback) {
        return this.callbacks.push(callback) - 1;
    };

    this.unSubscribe = function (index) {
        return this.callbacks.splice(index, 1).length === 1;
    };

    this.notify = function(payload){
        this.callbacks.forEach(callback => callback(payload));
    }
}
