import React, {PureComponent} from "react";
import {
    withStyles,
    IconButton,
} from "@material-ui/core";
import {CheckCircle as CheckIcon} from "@material-ui/icons";
import Flex from 'components/grid/Flex';
import BootstrapInput from 'components/bootstrap-input/BootstrapInput';
import {greyBackground, controlButtonBlue} from 'components/colors/Colors';
import ids from 'components/ids/ids';

const styles = theme => ({
    quickIssueContainer: {
        backgroundColor: greyBackground,
        borderRadius: 10,
    },
    quickIssueInput: {
        lineHeight: 1,
        fontSize: '0.875rem',
        border: 'unset',
        '&:focus': {
            borderRadius: 10,
            boxShadow: 'unset',
        },
        borderRadius: 10,
        marginLeft: 10
    },
    button: {
        padding: 7,
    },
});

export class ItemInputField extends PureComponent {

    render() {
        const {
            classes, onChange, onEnter, reference, name, newItemLabel, placeHolder
        } = this.props;
        return (
            <Flex container item={'0 0 40px'} alignItems={'center'} padding={6} className={classes.quickIssueContainer}>
                <BootstrapInput
                    value={newItemLabel}
                    placeholder={placeHolder}
                    onChange={onChange}
                    onEnterKey={onEnter}
                    name={name}
                    fullWidth
                    classes={{input: classes.quickIssueInput}}
                    inputRef={reference}
                    autoComplete={'off'}
                />
                <Flex container style={{minWidth: 80}} justifyContent={'center'}>
                    <IconButton
                        id={ids.listItemCreateButton}
                        className={classes.button}
                        style={{marginRight: 5}}
                        onClick={onEnter}
                    >
                        <CheckIcon style={{color: controlButtonBlue}}/>
                    </IconButton>
                </Flex>
            </Flex>
        )
    }
}

export default withStyles(styles)(ItemInputField);
