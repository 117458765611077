import React, {Component} from 'react';
import {IconButton, Tooltip, withStyles,} from "@material-ui/core";
import utils from 'utils/Utils';
import Flex from 'components/grid/Flex';
import {controlButtonBlue, htmlBlue, htmlWhite} from 'components/colors/Colors';
import H4Avatar from 'components/avatar/H4Avatar';
import {Create as CreateIcon, Visibility as VisibilityIcon} from "@material-ui/icons";
import _ from "lodash";
import ProjectMemberAssignmentPopup from "./ProjectMemberAssignmentPopup";
import {connect} from 'react-redux';
import connector from './ProjectUserManagement.connect';
import ids from 'components/ids/ids';

export const styles = theme => ({
    avatarTooltipLabel: {
        fontSize: 14
    },
    createIcon: {
        margin: -7,
        color: controlButtonBlue
    }
});

export class ProjectUserManagement extends Component {
    state = {dialogOpen: false};

    componentDidMount() {
        if(!_.get(this.props,'selectedProject.businessId')){
            this.props.listProjects();
        }
    }

    toggleProjectAssignmentPopup = () => {
        this.setState({dialogOpen: !this.state.dialogOpen});
    };

    render() {
        const {classes, selectedProject, selectedUserNames, toggleSelectedUserNames, user} = this.props;
        const selectedUser = selectedProject.members.find(member => _.get(member, 'user.businessId') === user.businessId);
        const userMembership = _.get(selectedUser, 'membership');
        const isMobile = utils.checkBrowser();
        return (
            <Flex container item={'1 0 auto'} justifyContent={'flex-end'} style={{flexWrap: isMobile ? 'wrap' : 'nowrap'}}>
                {
                    selectedProject.members.length !== 0 &&
                    selectedProject.members.map((projectMember, index) => (
                        <Flex item={'0 0 auto'} key={index} id={ids.usernameId(projectMember.user)} style={{
                            position: 'relative'
                        }}>
                            <Flex container item style={{
                                position: 'absolute',
                                top: -35,
                                left: -14
                            }}>
                                <Flex item={'0 0 auto'}>
                                    {
                                        projectMember.membership === 'OBSERVER' &&
                                        <IconButton disabled={true}>
                                            <VisibilityIcon/>
                                        </IconButton>

                                    }
                                </Flex>
                            </Flex>
                            <Flex container item style={{marginLeft: -7}}>
                                <Tooltip
                                    title={utils.formattedUserName(projectMember.user)}
                                    placement={'bottom-start'}
                                    classes={{
                                        tooltip: classes.avatarTooltipLabel
                                    }}
                                >
                                    <H4Avatar
                                        {...{user: projectMember.user}}

                                        style={{
                                            margin: 0,
                                            border: selectedUserNames.includes(_.get(projectMember, 'user.username'))
                                                ? `2px solid ${htmlBlue}`
                                                : `2px solid ${htmlWhite}`,
                                            zIndex: selectedProject.members.length - index,
                                            width: 36,
                                            height: 36
                                        }}
                                        onClick={() => toggleSelectedUserNames(_.get(projectMember, 'user.username'))}
                                    />
                                </Tooltip>
                            </Flex>
                        </Flex>
                    ))
                }
                <Flex item={'0 0 auto'}>
                    {
                        userMembership === 'ADMIN' && _.get(selectedProject,'root.type') !== 'PERSONAL' &&
                        <IconButton onClick={this.toggleProjectAssignmentPopup}>
                            <CreateIcon className={classes.createIcon}/>
                        </IconButton>
                    }
                    {
                        this.state.dialogOpen &&
                        <ProjectMemberAssignmentPopup {...{
                            dialogOpen: this.state.dialogOpen,
                            toggleProjectAssignmentPopup: this.toggleProjectAssignmentPopup
                        }} />
                    }
                </Flex>
            </Flex>
        );
    }
}

export default withStyles(styles)(connect(connector.mapStateToProps, connector.mapDispatchToProps)(ProjectUserManagement));
