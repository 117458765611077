import React, {Component} from 'react';
import {connect} from 'react-redux';
import connector from './KanbanBoard.connector';
import {Typography, withStyles} from "@material-ui/core";
import {DragDropContext, Draggable, Droppable} from 'react-beautiful-dnd';
import Flex from 'components/grid/Flex';
import {greyHighlight, htmlWhite} from 'components/colors/Colors';
import DraggableTask from './DraggableTask';
import update from 'immutability-helper';
import BacklogFilters from 'scenes/tasks/backlog/components/BacklogFilters';
import {TASK_KANBAN} from 'routes/routes';
import {parse} from 'query-string';
import {withRouter} from 'react-router-dom';
import {withTranslation} from 'react-i18next';
import _ from 'lodash';
import ProjectTree from 'scenes/tasks/components/project-tree/ProjectTree';
import ProjectAuthorizationErrorLabel from "scenes/tasks/components/ProjectAuthorizationErrorLabel";
import ProjectNotLoadedLabel from "scenes/tasks/components/ProjectNotLoadedLabel";
import {routeTo} from 'routes/routes';
import ids from 'components/ids/ids';
import configs from "configs/Configs";
import PageIsLoading from 'components/page-is-loading-progress/PageIsLoading';
import {treeIndex} from 'components/zindex/zIndex'

const styles = theme => ({
    columnContainer: {
        backgroundColor: greyHighlight,
        marginBottom: 'auto',
        width: '25%'
    },
    columnTitleContainer: {
        backgroundColor: greyHighlight,
        padding: 8,
        marginBottom: 5,
    },
    columnTitle: {
        textAlign: 'center',
        fontWeight: 'bold'
    },
    paper: {
        paddingBottom: 20,
        marginLeft: 10,
        marginTop: 20,
        backgroundColor: htmlWhite,
    },
    fromTo: {
        marginRight: 10,
        marginLeft: 10,
    },
    topLineFilters: {
        padding: 10
    },
    avatarRoot: {
        marginLeft: 5,
        width: 35,
        height: 35,
        display: 'inline-flex'
    },
    avatarTooltipLabel: {
        fontSize: 14
    },
    absoluteContainer: {
        overflow: 'auto',
        overflowScrolling: 'touch',
        position: 'absolute',
        bottom: 0,
        right: 0,
        left: 0,
        top: 0
    },
    workspace: {
        marginLeft: 30,
        marginRight: 20,
        marginTop: 30,
        zIndex: treeIndex + 1,
    }
});

const Column = (props) => {
    const {
        classes, status, tasks, applicationOnline, maxHeightOfTasks, closeTask, layout, closableWorkflowStatus,
        startingWorkflowStatus, removeTaskFromKanbanBoard, smallTaskId
    } = props;
    return (
        <Flex item={'1 1 auto'} container direction={'column'} className={classes.columnContainer}
              style={{
                  marginRight: status !== closableWorkflowStatus.label ? 10 : 0,
                  minHeight: Math.max(maxHeightOfTasks, 800)
              }}
        >
            <Flex item={'0 0 0'} className={classes.columnTitleContainer}>
                <Typography className={classes.columnTitle}>{status}</Typography>
            </Flex>
            <Droppable droppableId={status}>
                {(provided) => (
                    <div
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            flex: '1 1 auto',
                            padding: 8,
                        }}
                    >
                        {tasks.map((task, index) => (
                            <Draggable draggableId={task.businessId}
                                       index={index}
                                       key={task.businessId}>
                                {(provided) => (
                                    <div
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                    >
                                        <DraggableTask {...{
                                            id: index === 0 ? ids.firstListItemOnPage : task.businessId,
                                            task,
                                            applicationOnline,
                                            closeTask,
                                            removeTaskFromKanbanBoard,
                                            isCloseable: status === closableWorkflowStatus.label,
                                            isStarting: status === startingWorkflowStatus.label,
                                            layout,
                                            shrink: smallTaskId === task.businessId,
                                            dragHandleProps: provided.dragHandleProps
                                        }}/>
                                    </div>
                                )}
                            </Draggable>
                        ))}
                        {provided.placeholder}
                    </div>
                )}
            </Droppable>
        </Flex>
    );
};

export class KanbanBoard extends Component {

    constructor(props) {
        super(props);
        let params = parse(props.location.search);
        this.state = {
            ...this.mapFilterParamsToState(params),
            tagFilterRelation: '',
            smallTaskId: ''
        };
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (_.get(prevProps, 'selectedProject.businessId') !== _.get(this, 'props.selectedProject.businessId')) {
            this.props.listProjectTasks();
        }
    }

    handleChange = event => {
        this.setState({
            [event.target.name]: event.target.value,
        });
    };

    mapFilterParamsToState = (params) => {
        const {searchText, selectedTags, tagFilterRelation, layout} = params;
        const selectedTagsInArray = selectedTags ? selectedTags.split(',').map(tag => ({name: tag})) : [];
        return {
            selectedTags: selectedTagsInArray,
            tagFilterRelation: tagFilterRelation || 'OR',
            searchText: searchText ? searchText : '',
            layout
        };
    };

    updateUrlWithNewFilters = () => {
        routeTo(TASK_KANBAN.pathWithFilterParams({
            selectedTags: this.state.selectedTags.map(tag => tag.name),
            tagFilterRelation: _.isEmpty(this.state.selectedTags) ? '' : this.state.tagFilterRelation,
            searchText: this.state.searchText,
            layout: this.state.layout
        }), this.props);
    };

    resetFilters = () => {
        this.setState(
            {...this.mapFilterParamsToState({})},
            () => routeTo(TASK_KANBAN.path, this.props)
        );
    };

    resetSearchText = event => {
        this.setState({searchText: ''}, this.updateUrlWithNewFilters);
    };

    resetSelectedTags = event => {
        this.setState({selectedTags: []}, this.updateUrlWithNewFilters);
    };

    handleNamedStateChange = event => {
        this.setState({[event.target.name]: event.target.value}, this.updateUrlWithNewFilters);
    };

    handleDragEnd = filteredTaskColumns => result => {
        this.setState({smallTaskId: ''});
        const projectBusinessId = _.get(result, 'destination.droppableId', '').includes('treeNode-')
            ? _.get(result, 'destination.droppableId', '').replace('treeNode-', '')
            : '';

        if (result.destination && projectBusinessId) {
            this.dropIntoOtherProject(result, filteredTaskColumns, projectBusinessId);
        } else if (result.destination) {
            this.dropWithinTheSameProject(result, filteredTaskColumns);
        }
    };

    dropWithinTheSameProject = (result, filteredTaskColumns) =>{
        const draggedTaskStatus = result.source.droppableId;
        const draggedTaskId = result.draggableId;
        const destinationTaskStatus = result.destination.droppableId;
        const destinationColumn = filteredTaskColumns.find(taskColumn => taskColumn.label === destinationTaskStatus);
        const destinationTask = destinationColumn && destinationColumn.tasks[result.destination.index];
        const destinationTaskBusinessId = destinationTask
            ? destinationTask.businessId
            : destinationTask;
        this.props.sortTasksAndEditStatus(draggedTaskStatus, draggedTaskId, destinationTaskStatus, destinationTaskBusinessId);
    }

    dropIntoOtherProject = (result, filteredTaskColumns, projectBusinessId) => {
        const draggedTaskStatus = result.source.droppableId;
        const draggedTaskId = result.draggableId;
        const draggedTaskColumn = filteredTaskColumns.find(taskColumn => taskColumn.label === draggedTaskStatus);
        const draggedTask = draggedTaskColumn && draggedTaskColumn.tasks
            .find(task => task.businessId === draggedTaskId);
        if (draggedTask) {
            this.props.editTask({...draggedTask, projectBusinessId});
        }
    }

    userFilter = task => {
        return this.props.selectedUserNames.length === 0 || this.props.selectedUserNames.includes(_.get(task, 'assignee.username'));
    };

    freeTextFilter = task => {
        const matchingTranslation = task.translations
            .find(translation => translation.language.code === this.props.i18n.language) || task.translations[0];
        const taskPrefix = configs.getTaskPrefix().toLowerCase();
        return task.businessId.includes(this.state.searchText)
            || `${taskPrefix}-${task.businessId}`.includes(this.state.searchText.toLowerCase())
            || `dev-${task.businessId}`.includes(this.state.searchText.toLowerCase())
            || (matchingTranslation.title && matchingTranslation.title.toLowerCase().includes(this.state.searchText.toLowerCase()));
    };

    tagFilter = task => {
        if (this.state.tagFilterRelation === 'AND') {
            return this.state.selectedTags.length === 0
                || _.every(this.state.selectedTags, tag => _.some(task.freeTags, ['name', tag.name]));
        } else {
            return this.state.selectedTags.length === 0
                || _.some(this.state.selectedTags, tag => _.some(task.freeTags, ['name', tag.name]));
        }
    };

    projectMemberFilter = task => {
        return _.some(this.props.selectedProject.members, ['user.businessId', this.props.user.businessId]);
    };

    calculateMaxHeight = (taskColumns) => {
        let result = 0;
        taskColumns.forEach(column => {
            if (column.tasks.length > result) {
                result = column.tasks.length;
            }
        });
        result = result * 80;
        return result;
    };

    handleTagDelete = index => {
        this.setState(oldState => {
            return {
                selectedTags: update(oldState.selectedTags, {
                    $splice: [[index, 1]]
                })
            };
        }, this.updateUrlWithNewFilters)
    };

    handleTagAddition = tag => {
        this.setState(oldState => {
            return {
                selectedTags: update(oldState.selectedTags || [], {
                    $push: [tag]
                })
            }
        }, this.updateUrlWithNewFilters)
    };

    closeTask = task => {
        this.props.closeTask(task);
    };

    removeTaskFromKanbanBoard = (task) => {
        this.props.removeTaskFromKanbanBoard(task);
    };

    toggleTagSelection = tag => {
        const index = _.findIndex(this.state.selectedTags, selectTag => selectTag.name === tag.name);
        const selected = index >= 0;
        this.setState(oldState => {
            return {
                selectedTags: selected
                    ?
                    update(oldState.selectedTags, {$splice: [[index, 1]]})
                    :
                    update(oldState.selectedTags || [], {$push: [tag]})
            };
        }, this.updateUrlWithNewFilters)
    };

    render() {
        const {
            classes, taskColumns, applicationOnline, tagSuggestions, closableWorkflowStatus, selectedProject,
            startingWorkflowStatus, isTaskLoadingReady, isProjectLoaded
        } = this.props;
        const {searchText, selectedTags, layout, tagFilterRelation, smallTaskId} = this.state;
        const filteredTaskColumns = taskColumns
            .map(taskByStatus => ({
                ...taskByStatus,
                tasks: taskByStatus.tasks
                    .filter(this.userFilter)
                    .filter(this.freeTextFilter)
                    .filter(this.tagFilter)
                    .filter(this.projectMemberFilter)
            }));
        const maxHeightOfTasks = this.calculateMaxHeight(taskColumns);
        const {removeTaskFromKanbanBoard, toggleTagSelection} = this;
        return (
            <Flex container item id={ids.taskKanbanBoard}>
                <DragDropContext onDragEnd={this.handleDragEnd(filteredTaskColumns)}
                                 onBeforeCapture={response => {
                                     this.setState({
                                         smallTaskId: response.draggableId
                                     })
                                 }}>
                    <ProjectTree isDroppable={true}/>
                    {
                        selectedProject.currentUser.hasAccess ?
                            isProjectLoaded ?
                                <Flex item container direction={'column'} className={classes.workspace}>
                                    <Flex item={'0 1 0'}>
                                        <BacklogFilters
                                            {...{
                                                filterState: {searchText},
                                                tagSuggestions,
                                                selectedTags,
                                                tagFilterRelation,
                                                toggleTagSelection,
                                                handleChange: this.handleChange,
                                                handleNamedStateChange: this.handleNamedStateChange,
                                                handleTagAddition: this.handleTagAddition,
                                                handleTagDelete: this.handleTagDelete,
                                                resetSelectedTags: this.resetSelectedTags,
                                                resetSearchText: this.resetSearchText,
                                            }}
                                        />
                                    </Flex>
                                    {
                                        isTaskLoadingReady ?
                                            <Flex item container style={{position: 'relative'}}>

                                                <Flex item container className={classes.absoluteContainer}>
                                                    {filteredTaskColumns.map(taskColumn => (
                                                        <Column
                                                            {...{
                                                                classes,
                                                                tasks: taskColumn.tasks,
                                                                key: taskColumn.label,
                                                                status: taskColumn.label,
                                                                applicationOnline,
                                                                maxHeightOfTasks,
                                                                closeTask: this.closeTask,
                                                                removeTaskFromKanbanBoard,
                                                                closableWorkflowStatus,
                                                                startingWorkflowStatus,
                                                                layout,
                                                                smallTaskId
                                                            }}
                                                        />
                                                    ))}
                                                </Flex>

                                            </Flex>
                                            :
                                            <PageIsLoading/>
                                    }
                                </Flex>
                                :
                                <ProjectNotLoadedLabel/>
                            :
                            <ProjectAuthorizationErrorLabel/>
                    }
                </DragDropContext>
            </Flex>
        );
    }
}

export default withStyles(styles)(connect(connector.mapStateToProps, connector.mapDispatchToProps)(withRouter(withTranslation()(KanbanBoard))));
