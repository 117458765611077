import React, {
    PureComponent,
} from 'react';
import {
    Typography,
    withStyles,
} from "@material-ui/core";
import Flex from 'components/grid/Flex';
import {greyHighlight, blueHighlight, christiGreen, low, htmlWhite} from 'components/colors/Colors';
import {getStatusColor, getStatusTextColor} from 'components/colors/Status';
import LanguageCircle from './LanguageCircle';
import {withTranslation} from "react-i18next";
import _ from 'lodash';

export const styles = theme => ({
    titleBarItem: {
        lineHeight: 2,
        textTransform: 'capitalize'
    },
    hoverBackground: {
        backgroundColor: htmlWhite,
        transition: 'background-color 0.1s linear',
        '&:hover': {
            backgroundColor: greyHighlight
        }
    }
});

export class QuestionTile extends PureComponent {

    render() {
        const {
            classes, question, isSelected, handleDoubleClickOnQuestion, toggleQuestionSelection, supportedLanguages, i18n, t: translate
        } = this.props;
        const matchingTranslation = question.translations.find(
            translation => translation.language.code === (i18n.language || '').toLowerCase() && translation.comment
        );
        const title = matchingTranslation
            ? matchingTranslation.question
            : question.translations[0].question;
        return (
            <Flex item container className={classes.hoverBackground} grow={0} padding={1} alignItems={'center'}
                  onDoubleClick={handleDoubleClickOnQuestion(question.businessId)}
                  onClick={toggleQuestionSelection(question)}
                  style={{
                      minHeight: 40,
                      backgroundColor: isSelected ? blueHighlight : '',
                  }}
            >
                <Flex container item alignItems={'center'}
                      style={{
                          borderLeft: `5px solid ${question.status === 'APPROVED' ? christiGreen : low}`,
                          paddingLeft: 15
                      }}>
                    <Typography className={classes.titleBarItem}
                                style={{fontWeight: 'bold'}}>
                        {question.businessId}
                    </Typography>
                    <Typography style={{
                                    lineHeight: 2,
                                    marginRight: 10,
                                    marginLeft: 15,
                                    marginTop: 10,
                                    marginBottom: 10
                                }}>
                        {title}
                    </Typography>
                </Flex>
                <Flex item={'0 0 10%'}>
                    <Typography
                        className={classes.titleBarItem}>{question.catalogue}</Typography>
                </Flex>
                <Flex item={'0 0 10%'}>
                    <Typography
                        className={classes.titleBarItem}>{translate("audit-administration.answerType." + question.answerType.label)}</Typography>
                </Flex>
                <Flex item={'0 0 10%'}>
                    <Typography
                        className={classes.titleBarItem}>{translate("audit-administration.cycle." + question.cycle)}</Typography>
                </Flex>
                <Flex item={'0 0 10%'} container>
                    {
                        supportedLanguages.map(languageInArray => {
                            const translation = question.translations.find(translationInArray => translationInArray.language.code === languageInArray.code);
                            return <LanguageCircle
                                key={languageInArray.code}
                                label={languageInArray.label}
                                color={getStatusColor(_.get(translation, 'status'))}
                                textColor={getStatusTextColor(_.get(translation, 'status'))}
                                style={{marginLeft: 10}}
                            />
                        })
                    }
                </Flex>
            </Flex>
        );
    }
}

export default withStyles(styles)(withTranslation()(QuestionTile));
