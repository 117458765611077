import React, {Component, Fragment} from 'react';
import Flex from "components/grid/Flex";
import {withStyles, Divider, Button, DialogContent, DialogContentText} from "@material-ui/core";
import {connect} from "react-redux";
import connector from "./Questions.connect";
import {Trans, withTranslation} from "react-i18next";
import {withRouter, Link} from 'react-router-dom';
import QuestionsFilters from './components/QuestionsFilters';
import QuestionTile from './components/QuestionTile';
import DeleteDialog from 'components/confirmation-dialog/ConfirmationDialog';
import {ALL_VALUE} from "domain/task/Tasks.model";
import {gossipGreen, htmlWhite, htmlBlue} from 'components/colors/Colors';
import _ from 'lodash';
import {parse} from 'query-string';
import {
    AUDIT_ADMINISTRATION_QUESTIONS,
    AUDIT_ADMINISTRATION_QUESTION_CREATE,
    AUDIT_ADMINISTRATION_QUESTION_EDIT
} from 'routes/routes';
import {routeTo, prevPath} from 'routes/routes';

const styles = theme => ({
    paper: {
        marginTop: 20,
        backgroundColor: htmlWhite,
    },
    controlButtons: {
        minWidth: 150,
        marginTop: 15,
        marginLeft: 10
    },
    absoluteContainer: {
        overflow: 'auto',
        overflowScrolling: 'touch',
        position: 'absolute',
        top: 0,
        bottom: 0,
        right: 0,
        left: 0,
    },
    hoverBackground: {
        backgroundColor: htmlBlue,
        '&:hover': {
            backgroundColor: gossipGreen
        }
    }
});

const QuestionsList = (props) => {
    const {
        classes, questions, selectedQuestion, handleDoubleClickOnQuestion, toggleQuestionSelection,
        supportedLanguages, masterLanguage, bottom
    } = props;
    return (
        <Flex container item direction={'column'} style={{position: 'relative'}}>
            <Flex container item direction={'column'} className={classes.absoluteContainer}>
                <Divider/>
                {
                    questions.map(question => (
                        <div key={question.businessId}>
                            <QuestionTile {...{
                                question, isSelected: selectedQuestion.businessId === question.businessId,
                                handleDoubleClickOnQuestion, toggleQuestionSelection, masterLanguage, supportedLanguages
                            }}/>
                            <Divider/>
                        </div>
                    ))
                }
                <div id={'bottom-question-for-scroll'} ref={bottom}/>
            </Flex>
        </Flex>
    );
};

const FootControlButtons = (props) => {
    const {classes, selectedQuestion, openDeleteQuestionDialog, user, location} = props;
    return (
        <Flex container item={'0 0 50px'} justifyContent={'space-between'}>
            <Flex item={'1 0 auto'} container>
                <Button variant="contained"
                        color="primary"
                        onClick={openDeleteQuestionDialog}
                        disabled={!user.isAdmin || _.isEmpty(selectedQuestion)}
                        className={classes.controlButtons}
                        style={{marginLeft: 0}}
                >
                    <Trans i18nKey="global.delete"/>
                </Button>
                <Button variant="contained"
                        color="primary"
                        component={Link}
                        to={{
                            pathname: AUDIT_ADMINISTRATION_QUESTION_CREATE.path,
                            state: {
                                prevPath: prevPath({location}),
                                questionBusinessId: _.get(selectedQuestion, 'businessId')
                            }
                        }}
                        disabled={_.isEmpty(selectedQuestion)}
                        className={classes.controlButtons}
                >
                    <Trans i18nKey="global.clone"/>
                </Button>
            </Flex>
            <Flex item={'1 0 auto'} container justifyContent={'flex-end'}>
                <Button variant="contained"
                        color="primary"
                        component={Link}
                        to={{
                            pathname: AUDIT_ADMINISTRATION_QUESTION_EDIT.pathWithId(_.get(selectedQuestion, 'businessId')),
                            state: {prevPath: prevPath({location})}
                        }}
                        disabled={_.isEmpty(selectedQuestion)}
                        className={classes.controlButtons}
                >
                    <Trans i18nKey="global.open"/>
                </Button>
                <Button variant="contained"
                        component={Link}
                        to={{
                            pathname: AUDIT_ADMINISTRATION_QUESTION_CREATE.path,
                            state: {prevPath: prevPath({location})}
                        }}
                        color="primary"
                        className={classes.controlButtons}
                >
                    <Trans i18nKey="global.new"/>
                </Button>
            </Flex>
        </Flex>
    );
};

export class Questions extends Component {
    bottom = React.createRef();

    constructor(props) {
        super(props);
        let params = parse(props.location.search);
        this.state = {
            ...this.mapFilterParamsToState(params),
            selectedQuestion: '',
            deleteDialogOpen: false
        };
    }

    mapFilterParamsToState = (params) => {
        const {catalogue, answerType, cycle, searchText} = params;
        return {
            catalogue: catalogue ? catalogue : ALL_VALUE,
            answerType: answerType ? answerType : ALL_VALUE,
            cycle: cycle ? cycle : ALL_VALUE,
            searchText: searchText ? searchText : '',
        };
    };

    updateUrlWithNewFilters = () => {
        routeTo(AUDIT_ADMINISTRATION_QUESTIONS.pathWithFilterParams({
            catalogue: this.state.catalogue,
            answerType: this.state.answerType,
            cycle: this.state.cycle,
            searchText: this.state.searchText,
        }), this.props);
    };

    componentDidMount() {
        if (this.props.questionEditCallback) {
            this.props.questionEditCallback.then((data) => {
                this.props.fetchQuestions().then(() => {
                    if (_.get(data, 'type', '').includes('CREATE_QUESTION')) {
                        this.scrollToBottom();
                    }
                })
            })
        } else {
            this.props.fetchQuestions();
        }
    }

    scrollToBottom = () => {
        this.bottom.current && this.bottom.current.scrollIntoView({
            block: "end",
            inline: "nearest",
            behavior: "auto"
        });
    };

    catalogueFilter = question => {
        return this.state.catalogue === ALL_VALUE || this.state.catalogue.toLowerCase() === _.get(question, 'catalogue', '').toLowerCase();
    };

    answerTypeFilter = question => {
        return this.state.answerType === ALL_VALUE || this.state.answerType.toLowerCase() === question.answerType.label.toLowerCase();
    };

    cycleFilter = question => {
        return this.state.cycle === ALL_VALUE || this.state.cycle.toLowerCase() === _.get(question, 'cycle', '').toLowerCase();
    };

    freeTextFilter = question => {
        const matchingTranslation = (question.translations || []).find(
            translation => translation.language.code === (this.props.i18n.language || '').toLowerCase()
        );
        const searchText = this.state.searchText.toLowerCase();
        return question.businessId.includes(this.state.searchText)
            || (matchingTranslation && (_.get(matchingTranslation, 'comment', '') || '').toLowerCase().includes(searchText))
            || (question.catalogue && _.get(question, 'catalogue', '').toLowerCase().includes(searchText))
            || (question.answerType && _.get(question, 'answerType.label', '').toLowerCase().includes(searchText));
    };

    resetFilters = () => {
        this.setState(
            {...this.mapFilterParamsToState({})},
            () => routeTo(AUDIT_ADMINISTRATION_QUESTIONS.path, this.props)
        );
    };

    handleDoubleClickOnQuestion = questionBusinessId => () => {
        routeTo(AUDIT_ADMINISTRATION_QUESTION_EDIT.pathWithId(questionBusinessId), this.props);
    };

    toggleQuestionSelection = question => () => {
        this.setState({
            selectedQuestion: this.state.selectedQuestion.businessId === question.businessId ? {} : question
        })
    };

    handleNamedStateChange = event => {
        this.setState({[event.target.name]: event.target.value}, this.updateUrlWithNewFilters);
    };

    openDeleteQuestionDialog = () => {
        this.setState({deleteDialogOpen: true});
    };

    closeDeleteQuestionDialog = () => {
        this.setState({deleteDialogOpen: false});
    };

    deleteSelectedQuestion = () => {
        this.props.deleteQuestion(this.state.selectedQuestion.businessId).then(() => {
            this.setState({deleteDialogOpen: false}, this.props.fetchQuestions);
        })
    };

    render() {
        const {
            classes, questions, catalogues, answerTypes, cycles, user, location, match, supportedLanguages,
            masterLanguage, t: translate
        } = this.props;
        const {
            catalogue, answerType, searchText, selectedQuestion, cycle, deleteDialogOpen
        } = this.state;
        const {bottom, closeDeleteQuestionDialog, deleteSelectedQuestion, openDeleteQuestionDialog} = this;
        const filterOptions = {
            catalogues,
            answerTypes,
            cycles,
        };
        const filterState = {catalogue, answerType, cycle, searchText};
        const filteredQuestions = questions
            .filter(this.catalogueFilter)
            .filter(this.answerTypeFilter)
            .filter(this.cycleFilter)
            .filter(this.freeTextFilter);

        return (
            <Flex item container direction={'column'} style={{marginLeft: 20, marginRight: 20, paddingBottom: 20}}>
                <Flex item container direction={'column'} className={classes.paper}>
                    <QuestionsFilters
                        {...{
                            filterOptions,
                            filterState,
                            handleNamedStateChange: this.handleNamedStateChange,
                            resetFilters: this.resetFilters
                        }}
                    />
                    <QuestionsList
                        {...{
                            classes,
                            questions: filteredQuestions,
                            selectedQuestion,
                            supportedLanguages,
                            masterLanguage,
                            bottom,
                            handleDoubleClickOnQuestion: this.handleDoubleClickOnQuestion,
                            toggleQuestionSelection: this.toggleQuestionSelection,
                        }}
                    />
                </Flex>
                <Divider/>
                <FootControlButtons {...{
                    classes, selectedQuestion, location,
                    openDeleteQuestionDialog,
                    user: user,
                    match,

                }}/>
                <DeleteDialog dialogOpen={deleteDialogOpen}
                              onDialogClose={closeDeleteQuestionDialog}
                              onConfirm={deleteSelectedQuestion}
                              confirmationTextKey={"audit-administration.questionList.deleteQuestionConfirmation"}
                              children={
                                  selectedQuestion.assignedToQuestionnaire &&
                                  <DialogContent>
                                      <DialogContentText style={{paddingBottom: 24, fontSize: 14}}>
                                          {

                                              <Fragment>
                                                  <Fragment>
                                                      {translate('audit-administration.questionList.assignedQuestionnaire')}
                                                  </Fragment>
                                                  <ul>
                                                      {
                                                          selectedQuestion.assignedQuestionnaires.map((assignedQuestionnaire, index) => {
                                                                  return (
                                                                      <li key={index}>
                                                                          {assignedQuestionnaire}
                                                                      </li>
                                                                  )
                                                              }
                                                          )
                                                      }
                                                  </ul>
                                              </Fragment>

                                          }
                                      </DialogContentText>
                                  </DialogContent>
                              }
                />
            </Flex>
        );
    }
}

export default withStyles(styles)(connect(connector.mapStateToProps, connector.mapDispatchToProps)(withTranslation()(withRouter(Questions))));
