import React, {Component} from 'react';
import {withStyles, MenuItem} from "@material-ui/core";
import BootstrapSelect from 'components/bootstrap-select/BootstrapSelect';
import _ from 'lodash';
import Flex from 'components/grid/Flex';
import {withTranslation} from "react-i18next";
import ids from 'components/ids/ids';

export const styles = theme => ({
    ellipsis: {
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        wordWrap: 'break-word'
    },
    menuItem: {
        textTransform: 'capitalize'
    }
});

const StartAdornment = (props) => {
    const {startAdornment, value, bold, objectWithLabel, originalValue, classes, selected} = props;
    return (
        <Flex container style={{width: '100%'}}>
            {
                startAdornment &&
                <Flex item={'1 1 auto'}>
                    <span
                    >{startAdornment && startAdornment(originalValue)}</span>
                </Flex>
            }
            <Flex container item={startAdornment ? '8 1 auto' : '0 0 auto'}
                  style={{
                      maxWidth: selected ? '90%' : '100%'
                  }}>
                <span style={{fontWeight: bold ? 'bold' : 'normal', textTransform: 'capitalize'}}
                      className={selected && classes.ellipsis}
                >
                    {_.get(value, objectWithLabel, value)}
                </span>
            </Flex>
        </Flex>
    );
};

export class ConfiguredBootstrapSelect extends Component {
    state = {
        isOpen: false
    };

    onOpen = () => {
        this.setState({
            isOpen: true
        })
    };

    onClose = () => {
        this.setState({
            isOpen: false
        })
    };

    render() {
        const {
            classes, value, name, onChange, items, startAdornment, renderValue, inputProps, bold, t: translate, translationPath,
            objectWithLabel, tReady, bootstrapInputClasses, id, ...other
        } = this.props;
        const {isOpen} = this.state;
        if (renderValue) {
            console.warn('ConfiguredBootstrapSelect has its own render value defined, supplied one will be ignored');
        }

        const translatedValue = translationPath && value
            ? translate(translationPath + "." + value)
            : _.isString(value) && value.toLowerCase() === 'all'
                ? translate('global.all')
                : value;
        return (
            <BootstrapSelect value={value}
                             onChange={onChange}
                             MenuProps={{
                                 disableScrollLock: true,
                             }}
                             renderValue={value =>
                                 <StartAdornment objectWithLabel={objectWithLabel}
                                                 bold={bold}
                                                 value={translatedValue}
                                                 originalValue={value}
                                                 isOpen={isOpen}
                                                 startAdornment={startAdornment}
                                                 classes={classes}
                                                 selected={true}
                                 />
                             }
                             inputProps={{
                                 name,
                                 id: name + '-selector',
                                 ...inputProps
                             }}
                             SelectDisplayProps={{
                                 id
                             }}
                             onOpen={this.onOpen}
                             onClose={this.onClose}
                             open={isOpen}
                             bold={bold}
                             bootstrapInputClasses={bootstrapInputClasses}
                             {...other}
            >
                {
                    (items || []).map((item, index) => {
                            const translatedItem = translationPath ? translate(translationPath + "." + item) : _.isString(value) && item.toLowerCase() === 'all' ? translate('global.all') : item;
                            return (
                                <MenuItem value={item || ''}
                                          id={ids.list(name, index)}
                                          key={index}
                                          className={classes.menuItem}
                                >
                                    <StartAdornment objectWithLabel={objectWithLabel}
                                                    bold={bold}
                                                    isOpen={isOpen}
                                                    value={translatedItem}
                                                    originalValue={item}
                                                    startAdornment={startAdornment}
                                                    classes={classes}/>
                                </MenuItem>
                            )
                        }
                    )
                }
            </BootstrapSelect>
        );
    }
}


export default withStyles(styles)(withTranslation()(ConfiguredBootstrapSelect));
