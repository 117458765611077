import * as actionTypes from './TaskEdit.action.types';
import _ from 'lodash';
import moment from 'moment';
import {editableTask, editableQuickTask} from 'domain/task/Tasks.model';
import {ACTION_ARCHIVED_STATUS} from 'domain/task/Action.model';

const initialState = {
    task: {},
    newTaskRefId: '',
    leavePath: ''
};

export default function (state = initialState, action) {
    switch (action.type) {
        case actionTypes.RESET:{
            const newState = _.cloneDeep(state);
            newState.task = {};
            return newState;
        }
        case actionTypes.FETCH_TASK_FROM_CACHE:
        case actionTypes.FETCH_TASK_SUCCESS: {
            const newState = _.cloneDeep(state);
            const task = _.get(action, 'payload.data.data.fetchTask') || _.get(action, 'data.cache.task');
            const supportedLanguages =  _.get(action, 'meta.previousAction.data.supportedLanguages') || _.get(action, 'data.supportedLanguages');
            if (task) {
                const mappedTask = editableTask(task, supportedLanguages);
                const activeActions = mappedTask.actions
                    .filter(mappedAction => mappedAction.status !== ACTION_ARCHIVED_STATUS);
                activeActions
                    .sort(sortByDuedate)
                    .sort(sortByPriority)
                    .sort(sortByDone);
                mappedTask.actions = activeActions;
                newState.task = mappedTask;
            }
            return newState;
        }
        case actionTypes.EDIT_TASK_TO_CACHE: {
            const newState = _.cloneDeep(state);
            if (_.get(action, 'data.task')) {
                newState.task = editableTask(action.data.task, action.data.supportedLanguages);
            }
            return newState;
        }
        case actionTypes.CREATE_TASK_TO_CACHE:
        case actionTypes.CREATE_TASK_SUCCESS:
        case actionTypes.CREATE_TASK_FAIL: {
            const newState = _.cloneDeep(state);
            newState.newTaskRefId = '';
            return newState;
        }
        case actionTypes.FETCH_TEMPORARY_TASK_REFERENCE_ID: {
            const newState = _.cloneDeep(state);
            newState.task = emptyTaskForQuickCreate(
                action.data.user,
                action.data.supportedLanguages,
                action.data.deadlines,
                action.data.startingWorkflowStatus
            );
            return newState;
        }
        case actionTypes.FETCH_TEMPORARY_TASK_REFERENCE_ID_SUCCESS: {
            const newState = _.cloneDeep(state);
            newState.newTaskRefId = action.payload.data.referenceId;
            return newState;
        }
        case actionTypes.FETCH_TEMPORARY_TASK_REFERENCE_ID_OFFLINE: {
            const newState = _.cloneDeep(state);
            newState.newTaskRefId = action.data.referenceId;
            return newState;
        }
        case actionTypes.ARRIVED_FROM: {
            const newState = _.cloneDeep(state);
            newState.leavePath = action.data.path;
            return newState;
        }
        default:
            return state;
    }
}

export function emptyTaskForQuickCreate(user, languages, solveUntilConfiguration, startingWorkflowStatus) {
    const matchingSolveUntil = (solveUntilConfiguration || [])
        .find(solveUntilInArray => solveUntilInArray.priority.type === 'LOW');
    const numberOfDaysToAdd = _.get(matchingSolveUntil, 'quantity', 0);
    const dueDate = moment().add(numberOfDaysToAdd, 'days').toDate();

    return editableQuickTask({dueDate, assignee: user}, languages, startingWorkflowStatus)
}

export function sortByPriority(a, b) {
    return a.priority.ordinal - b.priority.ordinal;
}

export function sortByDuedate(a, b) {
    return new Date(a.dueDate).getTime() - new Date(b.dueDate).getTime();
}

export function sortByDone(a, b) {
    if (a.status === 'DONE' && b.status === 'IN_PROGRESS') {
        return 1;
    } else if (a.status === 'IN_PROGRESS' && b.status === 'DONE') {
        return -1;
    } else {
        return 0;
    }
}
