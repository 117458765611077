import * as actionTypes from './DeviationRedirect.action.types';
import query from 'store/GraphqlQueries.js';

export function findDeviationByAnswerId(answerId){
    return dispatch => {
        return dispatch(
            query.graphql({
                type: actionTypes.FIND_DEVIATION,
                caller: 'findTaskIdByAnswerId',
                query: `
                        query
                        ($answerId: String!){
                            findTaskIdByAnswerId(answerId: $answerId)
                        }
                       `,
                variables: {
                    answerId
                }
            })
        );
    }
}