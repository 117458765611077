import React, {PureComponent} from "react";
import {withStyles, Checkbox} from "@material-ui/core";
import {Trans, withTranslation} from "react-i18next";
import Flex from "components/grid/Flex";
import {dustyGrey, christiGreen} from "components/colors/Colors";
import _ from 'lodash';
import BootstrapInput from "components/bootstrap-input/BootstrapInput";
import Typography from "@material-ui/core/Typography";
import ConfiguredBootstrapSelect from "components/configured-bootstrap-select/ConfiguredBootstrapSelect";
import {CheckBox as CheckBoxIcon} from "@material-ui/icons";

const styles = theme => ({
    basicContainer: {
        margin: '5px 20px 15px 20px'
    },
    fieldTitle: {
        color: dustyGrey,
        textTransform: 'uppercase',
        fontSize: 12,
    },
    inputItem: {
        padding: 7,
    },
    inputField: {
        padding: 0,
    },
    inputContainerMargins: {
        marginRight: 10
    },
    nameLabel: {},
    recommendedCycleLabel: {},
    checkBoxIcon: {
        fill: christiGreen
    },
    approveQuestionCheckBoxRoot: {
        padding: 0,
        marginLeft: -3
    },
});

export class QuestionnaireSelectors extends PureComponent {
    render() {
        const {
            classes, handleQuestionnaireChange, cycles, questionnaire, handleActiveCheck, auditTypes,
            handleAuditTypeChange, isVdaAudit,selectedQuestions
        } = this.props;

        return (
            <Flex container justifyContent={'space-between'} alignItems={'center'} className={classes.basicContainer}>
                <div className={classes.inputItem} style={{width: '20%', height: 70}}>
                    <Typography className={classes.fieldTitle}><Trans
                        i18nKey={"audit-administration.questionnaireEdit.auditType"}/>
                    </Typography>
                    <ConfiguredBootstrapSelect
                        value={_.get(questionnaire, 'auditType.label', '')}
                        onChange={handleAuditTypeChange}
                        name={'auditType'}
                        items={auditTypes.map(type => type.label) || []}
                    />
                </div>

                <div className={classes.inputItem} style={{width: '30%', height: 70}}>
                    <Typography className={classes.fieldTitle}>
                        <Trans i18nKey={"audit-administration.questionnaireEdit.name"}/>
                    </Typography>
                    <BootstrapInput className={classes.inputField}
                                    value={_.get(questionnaire, 'name', '')}
                                    onChange={handleQuestionnaireChange}
                                    autoComplete={'off'}
                                    name={'name'}
                    />
                </div>

                <div className={classes.inputItem} style={{width: '20%', height: 70}}>
                    <Typography className={classes.fieldTitle}><Trans
                        i18nKey={"audit-administration.questionnaireEdit.recommendedCycle"}/>
                    </Typography>
                    <ConfiguredBootstrapSelect
                        value={_.get(questionnaire, 'cycle', '')}
                        translationPath={"audit-administration.cycle"}
                        onChange={handleQuestionnaireChange}
                        name={'cycle'}
                        items={cycles}
                    />
                </div>
                <Flex container item className={classes.inputItem} justifyContent={'flex-end'} style={{height: 70}}>
                    <Flex container item={'0 0 0'} alignItems={'flex-end'} style={{paddingBottom: 6}}>
                        <Checkbox
                            classes={{root: classes.approveQuestionCheckBoxRoot}}
                            checked={questionnaire.status === 'APPROVED'}
                            onChange={handleActiveCheck}
                            disabled={isVdaAudit && (questionnaire.topics.length === 0  || selectedQuestions.length ===   0)}
                            checkedIcon={<CheckBoxIcon className={classes.checkBoxIcon}/>}
                        />
                        <Typography style={{
                            fontWeight: 'bold',
                            marginLeft: 5
                        }}>
                            <Trans i18nKey={"audit-administration.questionnaireEdit.active"}/>
                        </Typography>
                    </Flex>
                </Flex>
            </Flex>
        );
    }
}

export default withStyles(styles)(withTranslation()(QuestionnaireSelectors));
