import React, {Component} from 'react';
import {
    withStyles,
    Typography,
} from "@material-ui/core";
import Flex from 'components/grid/Flex';
import utils from 'utils/Utils';
import Select, {components} from 'react-select';
import Forward from 'components/forward/Forward';
import H4Avatar from 'components/avatar/H4Avatar'
import {selectMenu} from 'components/zindex/zIndex';
import ids from 'components/ids/ids';

const styles = theme => ({
    avatarBesidesSelect: {
        marginLeft: 5,
        width: 35,
        height: 35,
        display: 'inline-flex'
    },
    avatarInSelect: {
        width: 40,
        height: 40,
    },
    userNameInSelector: {
        flex: '1 0 auto',
    },
    userSelect: {
        paddingTop: 5
    }
});

const UserSelectorOption = props => {
    const {data, classes} = props;
    return (
        <components.Option {...props} >
            <Flex container alignItems={'center'}>
                <Typography id={ids.usernameId(data)} className={classes.userNameInSelector}> {utils.formattedUserName(data)}</Typography>
                <H4Avatar {...{user: data}} classes={{avatarRoot: classes.avatarInSelect}}/>
            </Flex>
        </components.Option>
    );
};

export class Responsible extends Component {
    render() {
        const {
            classes, theme, backgroundColor, previousAssignee, dateTime, users, assignee, isDisabled, menuWidth,
            handleResponsibleChange, bold
        } = this.props;
        const selectStyles = {
            control: base => ({
                ...base,
                backgroundColor: backgroundColor,
                border: 'unset',
            }),
            menu: base => ({
                ...base,
                zIndex: selectMenu,
                width: menuWidth || '125%'
            }),
            valueContainer: base => ({
                ...base,
                '& div': {
                    fontFamily: theme.typography.fontFamily,
                    ...(bold ? {fontWeight: 'bold', fontSize: 16} : {})
                }
            })
        };
        return (
            <Flex container>
                {
                    previousAssignee && previousAssignee.businessId !== assignee.businessId &&
                    <Flex item={'1 1 0'}>
                        <Forward data={{user: previousAssignee, dateTime}}/>
                    </Flex>
                }
                <Flex item={'6 0 auto'} className={classes.select}>
                    <Select
                        id={ids.responsibleSelector}
                        classes={classes}
                        value={assignee}
                        styles={selectStyles}
                        options={users}
                        isDisabled={isDisabled}
                        onChange={handleResponsibleChange}
                        name={'assignee'}
                        getOptionValue={option => option.businessId}
                        getOptionLabel={option => utils.formattedUserName(option)}
                        menuPlacement={'auto'}
                        components={{
                            Option: props => <UserSelectorOption classes={classes} {...props}/>,
                        }}
                    />
                </Flex>
                <Flex item={'1 1 0'}>
                    <H4Avatar {...{user: assignee}} classes={{avatarRoot: classes.avatarBesidesSelect}}/>
                </Flex>
            </Flex>
        );
    }
}

export default withStyles(styles, {withTheme: true})(Responsible);
