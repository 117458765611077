import React, {PureComponent} from "react";
import {Typography, withStyles} from "@material-ui/core";
import {withTranslation} from "react-i18next";
import Flex from "components/grid/Flex";
import {header} from 'components/zindex/zIndex';
import {htmlWhite, transparentBlack02} from "components/colors/Colors";

export const styles = theme => ({
    container: {
        position: 'sticky',
        top: 0,
        paddingBottom: 15,
        zIndex: header,
        backgroundColor: htmlWhite,
        boxShadow: `0px 0px 4px 0px ${transparentBlack02}`
    },
    titleBarItem: {
        lineHeight: 1,
        fontWeight: 'bold'
    }
});

export class QuestionnaireTileHeader extends PureComponent {

    render() {
        const {classes, t: translate, rootClasses} = this.props;
        return (
            <Flex item container grow={0} alignItems={'center'} className={classes.container}>
                <div className={rootClasses.id}>
                    <Typography className={classes.titleBarItem} style={{
                        fontWeight: 'bold',
                        marginLeft: 20
                    }}>{translate('audit-administration.questionnaireList.id')}
                    </Typography>
                </div>
                <div className={rootClasses.name}>
                    <Typography className={classes.titleBarItem}
                                style={{marginLeft: 15}}>{translate('audit-administration.questionnaireList.name')}</Typography>
                </div>
                <div className={rootClasses.catalogue}>
                    <Typography className={classes.titleBarItem}
                                style={{marginLeft: -5}}>{translate('audit-administration.questionnaireList.catalogue')}</Typography>
                </div>
                <div className={rootClasses.numberOfQuestions}>
                    <Typography className={classes.titleBarItem}
                                style={{marginLeft: -5}}>{translate('audit-administration.questionnaireList.numberOfQuestions')}</Typography>
                </div>
                <div className={rootClasses.cycle}>
                    <Typography className={classes.titleBarItem}
                                style={{marginLeft: -15}}>{translate('audit-administration.questionnaireList.cycle')}</Typography>
                </div>
                <div className={rootClasses.active}>
                    <Typography className={classes.titleBarItem}
                                style={{marginLeft: -5}}>{translate('audit-administration.questionnaireList.active')}</Typography>
                </div>
            </Flex>
        );
    }
}

export default withStyles(styles)(withTranslation()(withTranslation()(QuestionnaireTileHeader)));