import _ from "lodash";
import * as actionTypes from './QuestionEdit.action.types';
import {mapCatalogueLabel} from "domain/audit/AuditConfiguration.model";

const initialState = {
    question: {}
};

export default function (state = initialState, action) {
    switch (action.type) {
        case actionTypes.FETCH_EMPTY_QUESTION: {
            const newState = _.cloneDeep(state);
            newState.question = emptyQuestion(emptyTranslations(action.data.supportedLanguages));
            return newState;
        }
        case actionTypes.FETCH_QUESTION_SUCCESS: {
            const newState = _.cloneDeep(state);
            newState.question = mapQuestion(action.payload.data.data.fetchQuestion);
            return newState;
        }
        default:
            return state;
    }
}

export function emptyTranslations(supportedLanguages) {
    return supportedLanguages
        .map(language => {
            return emptyTranslation(language)
        });
}

export function emptyTranslation(language) {
    return {
        language: {
            code: language.code,
            label: language.label,
            ordinal: language.ordinal
        },
        question: '',
        comment: '',
        quickFix: '',
        status: 'PENDING',
        origin: 'UNKNOWN',
        isChanged: false,
        pictureReferenceId: '',
        pictureDownloadUrl: '',
    }
}

export function emptyQuestion(translations) {
    return {
        translations,
        status: 'PENDING',
        answerType: {
            label: ''
        },
        assignedToQuestionnaire: false
    }
}

export function mapQuestion(question) {
    const assignedQuestionnaires = question.assignedQuestionnaires.map(assignedQuestionnaire => assignedQuestionnaire.name);
    return {
        businessId: question.businessId,
        translations: mapTranslations(question.translations),
        status: question.status,
        answerType: {
            label: question.answerType.label
        },
        catalogue: mapCatalogueLabel(question.catalogue),
        cycle: _.get(_.find(question.tags, ['category', 'CYCLE']), 'label'),
        assignedToQuestionnaire: assignedQuestionnaires.length > 0,
        assignedQuestionnaires: assignedQuestionnaires
    }
}

export function mapTranslations(translations) {
    return translations.map(translation => ({
        language: {
            code: translation.language.code,
            label: translation.language.label,
            ordinal: translation.language.ordinal
        },
        question: translation.question || '',
        comment: translation.comment || '',
        quickFix: translation.quickFix || '',
        status: translation.status,
        origin: translation.origin,
        isChanged: false,
        pictureReferenceId: _.get(translation, 'picture.referenceId', ''),
        pictureDownloadUrl: _.get(translation, 'picture.downloadUrl', ''),
    }));
}
