import React, {Component} from 'react';
import {withStyles, Typography, Button, IconButton, InputBase} from "@material-ui/core";
import {withTranslation, Trans} from "react-i18next";
import Flex from 'components/grid/Flex';
import {isActive} from "domain/audit/Questionnaire.model";
import classNames from 'classnames';
import {
    dustyGrey,
    jungleGreen,
    htmlWhite,
    questionnaireTitleActive,
    transparentCodGrey20,
    transparentBlack087,
    paleSky,
    athensGrey,
    athensGreyDark,
    transparentMidnight40,
    paleSkyDark,
    fafaWhite,
    midnight40
} from "components/colors/Colors";
import {questionnaireHeaderIndex} from 'components/zindex/zIndex';
import PlusIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';
import CheckIcon from '@material-ui/icons/Check';
import {DragDropContext, Draggable, Droppable} from 'react-beautiful-dnd';
import {newTopic} from 'domain/audit/Topic.model';
import _ from 'lodash';

function reorder(list, startIndex, endIndex) {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
}

const styles = theme => ({
    cell: {
        color: transparentBlack087,
        fontSize: '0.8125rem',
        fontWeight: 400,
        padding: '4px 10px',
        textTransform: 'capitalize'
    },
    titleCell: {
        color: htmlWhite,
        fontWeight: 800,
    },
    titleCellLimit: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        width: '0.25vw'
    },
    questionsArea: {
        display: 'flex',
        flex: '1 1 auto',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        width: '45%',
        height: '64vh'
    },
    tableWrapper: {
        maxHeight: '100%',
        width: '100%',
        height: '100%',
        overflow: 'auto',
        backgroundColor: fafaWhite,
        border: `1px solid ${transparentCodGrey20}`,
        borderRadius: 4
    },
    fieldTitle: {
        color: dustyGrey,
        textTransform: 'uppercase',
        fontSize: 12,
        minWidth: 250
    },
    tableTitle: {
        fontWeight: 600
    },
    questionnaireTitle: {
        backgroundColor: jungleGreen,
        color: htmlWhite,
        position: 'sticky',
        top: 0,
        height: 56,
        zIndex: questionnaireHeaderIndex
    },
    input: {
        textTransform: 'uppercase'
    },
    subTopicHeader: {
        height: 50,
        marginLeft: 15,
        backgroundColor: athensGrey,
        boxShadow: '0px 0px 8px #0000001A',
        borderRadius: 4,
        '&:hover': {
            opacity: 0.9
        }
    },
    topicHeader: {
        height: 53,
        backgroundColor: paleSky,
        borderRadius: 4,
        marginTop: 5,
        '&:hover': {
            opacity: 0.9
        }
    },
    questionHeader: {
        minHeight: 50,
        backgroundColor: htmlWhite,
        boxShadow: '0px 0px 8px #0000001A',
        borderRadius: 4,
        margin: '7px -1px 7px 30px',
        paddingLeft: 15,
        '&:hover': {
            opacity: 0.9
        }
    }
});

const QuestionTile = props => {
    const {
        classes, i18n, question, handleMarkQuestion, handleRemoveQuestion, topicIndex, subTopicIndex, questionIndex
    } = props;
    const translation = question.translations
        .find(translation => translation.language.code === i18n.language).question;
    return (
        <Flex item container
              className={classes.questionHeader}
              justifyContent={'space-between'}
              alignItems={'center'}>
            <Flex item container column>
                <Typography style={{
                    color: transparentMidnight40,
                    fontWeight: 'bold',
                    textTransform: 'uppercase'
                }}>
                    <Trans i18nKey={"audit-administration.questionEdit.question"}/>
                    {` ${topicIndex + 1}`}
                    {subTopicIndex !== undefined && `.${subTopicIndex + 1}`}
                    {`.${questionIndex + 1}`}
                </Typography>
                <Typography>{translation}</Typography>
            </Flex>
            <Flex item={'0 0 0'} container alignItems={'center'}>
                <IconButton onClick={handleMarkQuestion(question, handleRemoveQuestion)}>
                    <DeleteIcon style={{fill: paleSky}}/>
                </IconButton>
            </Flex>
        </Flex>
    );
}

const TopicHeader = props => {
    const {
        classes, translate, topicProvided, topic, selectTopic, isSelectedTopic, renameTopic, deleteTopic, topicIndex
    } = props;
    return (
        <Flex item container onClick={selectTopic(topic)}
              className={classes.topicHeader}
              style={{
                  ...(isSelectedTopic(topic) && {backgroundColor: paleSkyDark})
              }}
              alignItems={'center'}>
              <span {...topicProvided.dragHandleProps}>
                  <DragIndicatorIcon style={{fill: htmlWhite}}/>
              </span>
            <Flex item container justifyContent={'space-between'} style={{marginLeft: 10}}>
                <Flex item container column justifyContent={'center'}>
                    <Typography style={{
                        color: midnight40,
                        fontWeight: 'bold',
                        textTransform: 'uppercase'
                    }}>{topicIndex + 1}</Typography>
                    <InputBase
                        value={topic.name}
                        onChange={renameTopic(topic)}
                        inputProps={{
                            style: {
                                padding: 0
                            }
                        }}
                        style={{color: htmlWhite}}
                        placeholder={translate('audit-administration.questionnaireEdit.enter-topic-name')}
                    />

                </Flex>
                <Flex item={'0 0 0'} container alignItems={'center'}>
                    {
                        isSelectedTopic(topic) &&
                        <CheckIcon style={{fill: htmlWhite}}/>
                    }
                    <IconButton onClick={deleteTopic(topic)}>
                        <DeleteIcon style={{fill: htmlWhite}}/>
                    </IconButton>
                </Flex>
            </Flex>
        </Flex>
    );
}

const SubTopicHeader = props => {
    const {
        classes, translate, topic, selectTopic, isSelectedTopic, provided, subTopic, renameSubTopic, deleteSubTopic,
        topicIndex, subTopicIndex
    } = props;
    return (
        <Flex item container
              onClick={selectTopic(subTopic)}
              className={classes.subTopicHeader}
              style={{
                  ...(isSelectedTopic(subTopic) && {backgroundColor: athensGreyDark})
              }}
              alignItems={'center'}
        >
                <span {...provided.dragHandleProps}>
                    <DragIndicatorIcon style={{fill: transparentMidnight40}}/>
                </span>
            <Flex item container justifyContent={'space-between'} style={{marginLeft: 10}}>
                <Flex item container column justifyContent={'center'}>
                    <Typography style={{
                        color: midnight40,
                        fontWeight: 'bold',
                        textTransform: 'uppercase'
                    }}>{`${topicIndex + 1}.${subTopicIndex + 1}`}</Typography>
                    <InputBase
                        value={subTopic.name}
                        style={{
                            color: midnight40,
                            fontSize: 15,
                            textTransform: 'uppercase',
                        }}
                        inputProps={{
                            style: {
                                padding: 0
                            }
                        }}
                        onChange={renameSubTopic(topic, subTopic)}
                        placeholder={translate('audit-administration.questionnaireEdit.enter-sub-topic-name')}
                    />
                </Flex>
                <Flex item={'0 0 0'} container alignItems={'center'}>
                    {
                        isSelectedTopic(subTopic) &&
                        <CheckIcon/>
                    }
                    <IconButton onClick={deleteSubTopic(topic, subTopic)}>
                        <DeleteIcon/>
                    </IconButton>
                </Flex>
            </Flex>
        </Flex>
    );
}

const SubTopicTile = (props) => {
    const {
        classes, translate, provided, subTopic, topic, deleteSubTopic, renameSubTopic, selectTopic, isSelectedTopic,
        selectedQuestions, handleMarkQuestion, handleRemoveQuestion, i18n, topicIndex, subTopicIndex
    } = props;
    const questions = selectedQuestions.filter(question => subTopic.questionIds.includes(question.businessId));
    return (
        <Flex item container column>
            <SubTopicHeader {...{
                classes, translate, topic, selectTopic, isSelectedTopic, provided, subTopic,
                renameSubTopic, deleteSubTopic, topicIndex, subTopicIndex
            }}/>
            {
                questions.map((question, questionIndex) => (
                    <QuestionTile
                        key={question.businessId}
                        {...{
                            classes, i18n, question, handleMarkQuestion, handleRemoveQuestion, questionIndex,
                            topicIndex, subTopicIndex
                        }}
                    />
                ))
            }
        </Flex>
    );
};

const TopicTile = (props) => {
    const {
        classes, i18n, topicProvided, translate, topic, deleteTopic, createSubTopic, deleteSubTopic, renameTopic,
        renameSubTopic, selectTopic, isSelectedTopic, selectedQuestions, handleRemoveQuestion, handleMarkQuestion,
        topicIndex
    } = props;
    const questions = selectedQuestions.filter(question => topic.questionIds.includes(question.businessId));
    return (
        <Flex item container column style={{marginLeft: 10, marginRight: 5}}>
            <TopicHeader {...{
                classes, translate, topicProvided, topic, selectTopic, isSelectedTopic, renameTopic,
                deleteTopic, topicIndex
            }}/>
            <Flex item container column>
                {
                    _.isEmpty(questions) &&
                    <Flex item={'0 0 0'} container>
                        <Button onClick={createSubTopic(topic)}>
                            <PlusIcon style={{fill: paleSky}}/>
                            <Typography
                                style={{color: paleSky, textTransform: 'uppercase', fontSize: 12, fontWeight: 'bold'}}>
                                {translate('audit-administration.questionnaireEdit.new-sub-topic')}
                            </Typography>
                        </Button>
                    </Flex>
                }
                {
                    questions.map((question, questionIndex) => (
                        <QuestionTile
                            key={question.businessId}
                            {...{
                                classes, i18n, question, handleMarkQuestion, handleRemoveQuestion, topicIndex,
                                questionIndex
                            }}
                        />
                    ))
                }
                <Droppable droppableId={topic.businessId} type={'droppableSubTopics'}>
                    {(provided) => (
                        <div
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                        >
                            {
                                topic.children.map((subTopic, index) => (
                                    <Draggable draggableId={subTopic.businessId}
                                               index={index}
                                               key={subTopic.businessId}>
                                        {(provided) => (
                                            <div
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                                style={{
                                                    margin: '5px 0px',
                                                    ...provided.draggableProps.style,
                                                }}
                                            >
                                                <SubTopicTile {...{
                                                    classes, translate, provided, subTopic, topic, deleteSubTopic,
                                                    renameSubTopic, selectTopic, isSelectedTopic, selectedQuestions,
                                                    handleMarkQuestion, handleRemoveQuestion, i18n, topicIndex,
                                                    subTopicIndex: index
                                                }}/>
                                            </div>
                                        )}
                                    </Draggable>
                                ))
                            }
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </Flex>
        </Flex>
    );
};

export class VDASelectedQuestions extends Component {

    state = {
        selectedTopicId: ''
    }

    createTopic = () => {
        const topics = [...this.props.questionnaire.topics, newTopic()];
        this.setTopics(topics);
    }

    createSubTopic = topic => event => {
        const index = this.props.questionnaire.topics
            .findIndex(topicInArray => topicInArray.businessId === topic.businessId);
        const topics = [...this.props.questionnaire.topics];
        topics[index].children = [...topics[index].children, newTopic()];
        this.setTopics(topics);
    }

    deleteTopic = topic => event => {
        event.preventDefault();
        event.stopPropagation();
        const index = this.props.questionnaire.topics
            .findIndex(topicInArray => topicInArray.businessId === topic.businessId);
        const topics = [...this.props.questionnaire.topics];
        this.clearQuestions(topics[index]);
        this.clearSelectedTopicId();
        topics.splice(index, 1);
        this.setTopics(topics);
    }

    deleteSubTopic = (topic, subTopic) => event => {
        event.preventDefault();
        event.stopPropagation();
        const index = this.props.questionnaire.topics
            .findIndex(topicInArray => topicInArray.businessId === topic.businessId);
        const topics = [...this.props.questionnaire.topics];
        const subIndex = topics[index].children
            .findIndex(subTopicInArray => subTopicInArray.businessId === subTopic.businessId);
        this.clearQuestions(topics[index].children[subIndex]);
        this.clearSelectedTopicId();
        topics[index].children.splice(subIndex, 1);
        this.setTopics(topics);
    }

    clearSelectedTopicId = () =>{
        this.props.changeState({
            target: {
                name: 'selectedTopicId',
                value: ''
            }
        });
    }

    clearQuestions = topic => {
        const questions = this.props.selectedQuestions.filter(question => topic.questionIds.includes(question.businessId));
        questions.forEach(question => {
            this.props.handleMarkQuestion(question, this.props.handleRemoveQuestion)();
        });
    };

    renameTopic = topic => event => {
        const index = this.props.questionnaire.topics
            .findIndex(topicInArray => topicInArray.businessId === topic.businessId);
        const topics = [...this.props.questionnaire.topics];
        topics[index].name = event.target.value;
        this.setTopics(topics);
    }

    renameSubTopic = (topic, subTopic) => event => {
        const index = this.props.questionnaire.topics
            .findIndex(topicInArray => topicInArray.businessId === topic.businessId);
        const topics = [...this.props.questionnaire.topics];
        const subIndex = topics[index].children
            .findIndex(subTopicInArray => subTopicInArray.businessId === subTopic.businessId);
        topics[index].children[subIndex].name = event.target.value;
        this.setTopics(topics);
    }

    setTopics = topics => {
        this.props.handleQuestionnaireChange({
            target: {
                name: 'topics',
                value: topics
            }
        })
    }

    handleDragEnd = result => {
        if (!result.destination) {
            return;
        }
        const sourceIndex = result.source.index;
        const destinationIndex = result.destination.index;
        if (result.type === "droppableTopics") {
            const topics = reorder(this.props.questionnaire.topics, sourceIndex, destinationIndex);
            this.setTopics(topics);
        } else if (result.type === "droppableSubTopics") {
            const topicSubTopicMap = this.props.questionnaire.topics.reduce((acc, item) => {
                acc[item.businessId] = item.children;
                return acc;
            }, {});

            const sourceParentId = result.source.droppableId;
            const destParentId = result.destination.droppableId;

            const sourceSubItems = topicSubTopicMap[sourceParentId];
            const destSubItems = topicSubTopicMap[destParentId];

            let newTopics = [...this.props.questionnaire.topics];

            /** In this case subItems are reOrdered inside same Parent */
            if (sourceParentId === destParentId) {
                const reorderedSubItems = reorder(
                    sourceSubItems,
                    sourceIndex,
                    destinationIndex
                );
                newTopics = newTopics.map(topic => {
                    if (topic.businessId === sourceParentId) {
                        topic.children = reorderedSubItems;
                    }
                    return topic;
                });

            } else {
                let newSourceSubItems = [...sourceSubItems];
                const [draggedItem] = newSourceSubItems.splice(sourceIndex, 1);

                let newDestSubItems = [...destSubItems];
                newDestSubItems.splice(destinationIndex, 0, draggedItem);
                newTopics = newTopics.map(topic => {
                    if (topic.businessId === sourceParentId) {
                        topic.children = newSourceSubItems;
                    } else if (topic.businessId === destParentId) {
                        topic.children = newDestSubItems;
                    }
                    return topic;
                });
            }

            this.setTopics(newTopics);
        }
    };

    render() {
        const {
            classes, i18n, questionnaire, multiSelectedOrganizationLevels, t: translate, selectTopic, isSelectedTopic,
            selectedQuestions, handleRemoveQuestion, handleMarkQuestion
        } = this.props;
        const {
            createTopic, createSubTopic, deleteTopic, deleteSubTopic, renameTopic, renameSubTopic
        } = this;
        const title = multiSelectedOrganizationLevels.map(level => level.title).join(' / ');
        const topics = questionnaire.topics || [];
        return (
            <div className={classes.questionsArea}>
                <Typography classes={{root: classNames(classes.fieldTitle, classes.tableTitle)}}>
                    <Trans i18nKey={"audit-administration.questionnaireEdit.questionnaire"}/>
                </Typography>
                <div className={classes.tableWrapper}>
                    <Flex container
                          justifyContent={'space-between'}
                          alignItems={'center'}
                          className={classes.questionnaireTitle}
                          grow={0}
                          style={{backgroundColor: isActive(questionnaire.status) && questionnaireTitleActive}}
                    >

                        <Flex item basis={'80%'}
                              className={classNames(classes.cell, classes.titleCell, classes.titleCellLimit)}>
                            {title}
                        </Flex>
                        <Flex item basis={'20%'} className={classNames(classes.cell, classes.titleCell)}>
                            {questionnaire.cycle &&
                            <Trans i18nKey={"audit-administration.cycle." + questionnaire.cycle}/>}
                        </Flex>
                    </Flex>

                    <Button onClick={createTopic}>
                        <PlusIcon style={{fill: paleSky}}/>
                        <Typography style={{
                            color: paleSky,
                            textTransform: 'uppercase',
                            fontSize: 12,
                            fontWeight: 'bold'
                        }}>
                            {translate('audit-administration.questionnaireEdit.new-topic')}
                        </Typography>
                    </Button>

                    <Flex container item direction={'column'}>
                        <DragDropContext onDragEnd={this.handleDragEnd}>
                            <Droppable droppableId={'topics'} type={'droppableTopics'}>
                                {(provided) => (
                                    <div
                                        {...provided.droppableProps}
                                        ref={provided.innerRef}>
                                        {
                                            topics.map((topic, index) => (
                                                <Draggable draggableId={topic.businessId}
                                                           index={index}
                                                           key={topic.businessId}>
                                                    {(provided) => (
                                                        <div>
                                                            <div
                                                                ref={provided.innerRef}
                                                                {...provided.draggableProps}
                                                                {...provided.dragHandleProps}
                                                                style={{
                                                                    outline: 'none',
                                                                    ...provided.draggableProps.style,
                                                                }}
                                                            >
                                                                <TopicTile
                                                                    key={`topic-tile-${topic.businessId}`}
                                                                    {...{
                                                                        classes,
                                                                        i18n,
                                                                        translate,
                                                                        topic,
                                                                        topicProvided: provided,
                                                                        createSubTopic,
                                                                        deleteTopic,
                                                                        deleteSubTopic,
                                                                        renameTopic,
                                                                        renameSubTopic,
                                                                        selectTopic,
                                                                        isSelectedTopic,
                                                                        selectedQuestions,
                                                                        handleRemoveQuestion,
                                                                        handleMarkQuestion,
                                                                        topicIndex: index
                                                                    }}/>
                                                            </div>
                                                            {provided.placeholder}
                                                        </div>
                                                    )}
                                                </Draggable>
                                            ))
                                        }
                                        {provided.placeholder}
                                    </div>
                                )}
                            </Droppable>
                        </DragDropContext>
                    </Flex>

                </div>
            </div>
        );
    }
}

export default withStyles(styles)(withTranslation()(VDASelectedQuestions));
