import {
    fetchActions,
    fetchActionFromServer,
} from './MyActionList.action';

export default {
    mapStateToProps: (state) => {
        return {
            actions: state.myActionListReducer.actions,
        };
    },
    mapDispatchToProps: (dispatch) => {
        return {
            fetchActions:() => dispatch(fetchActions()),
            fetchActionFromServer: businessId => dispatch(fetchActionFromServer(businessId)),
        };
    }
};
