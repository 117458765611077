import React, {Component, Fragment} from 'react';
import {Tooltip, Typography, withStyles, IconButton} from '@material-ui/core';
import H4Logo from 'assets/img/H4Logo.png';
import H4LogoText from 'assets/img/Software.png';
import MobileBurgerMenu from 'components/mobile-burger/MobileBurgerMenu';
import MobileProjectFilter from 'components/mobile-burger/MobileProjectFilter';
import Flex from 'components/grid/Flex';
import Profile from 'components/profile/Profile';
import {Trans, withTranslation} from 'react-i18next';
import LanguageSelector from 'components/language-selector/LanguageSelector';
import _ from 'lodash';
import {headerIcon} from 'components/zindex/zIndex';
import {withRouter} from "react-router";
import {routeTo, pathToRouteMap, TASK_BACKLOG, GENERAL_CONFIGURATION} from 'routes/routes';
import {connect} from 'react-redux';
import connector from './Header.connect';
import qs from 'qs';
import configs from "configs/Configs";
import WifiOffIcon from '@material-ui/icons/WifiOff';
import SettingsIcon from '@material-ui/icons/Settings';
import {htmlBlack} from 'components/colors/Colors';

const styles = theme => ({
    toolbarRoot: {
        minHeight: 0
    },
    title: {
        fontWeight: 'bold',
        [theme.breakpoints.down('md')]: {
            fontSize: 12
        }
    },
    subtitle: {
        [theme.breakpoints.down('md')]: {
            fontSize: 12
        }
    },
    icon: {
        fontSize: 'inherit',
        fill: htmlBlack,
    },
});

export class Header extends Component {

    handleLogoClick = () => {
        this.props.history.push('/')
    };

    render() {
        const {
            classes, location, user, isMobile, isTitleVisible, projectBreadCrumb, logout, synchronize, online,
            toggleOnline
        } = this.props;
        const currentRoute = pathToRouteMap[location.pathname] || {};
        const pathParams = qs.parse(_.get(location, 'search'), {ignoreQueryPrefix: true});
        const isViewMyActions = currentRoute.path === TASK_BACKLOG.path && _.get(pathParams, 'view') === 'My actions';
        const breadCrumbsCopy = !isViewMyActions && currentRoute.breadCrumb && user ? _.cloneDeep(currentRoute.breadCrumb(projectBreadCrumb)) : [];
        const breadCrumbTail = breadCrumbsCopy.pop();
        const breadCrumbHead = breadCrumbsCopy.join(' > ');
        const versionToolTip = process.env.REACT_APP_VERSION;

        return (
            <Flex
                item
                grow={0}
                container
                justifyContent={isMobile ? 'space-between' : "flex-start"}
                alignItems="center"
                style={{
                    padding: isMobile ? 0 : 15,
                    paddingLeft: isMobile ? 5 : 0,
                    zIndex: headerIcon
                }}
            >
                {
                    isMobile &&
                    <Fragment>
                        {
                            user
                                ?
                                <MobileBurgerMenu style={{zIndex: headerIcon}}/>
                                :
                                <Fragment>
                                    {
                                        !configs.getCustomLogo().enabled ?
                                            <img
                                                style={{
                                                    width: '11%',
                                                    minWidth: 37,
                                                    maxWidth: 67,
                                                    zIndex: headerIcon
                                                }}
                                                src={H4Logo}
                                                alt="H4"
                                                onClick={this.handleLogoClick}
                                            />
                                            :
                                            <img
                                                style={{
                                                    ...configs.getCustomLogo().mobile.style,
                                                    zIndex: headerIcon
                                                }}
                                                src={window.location.origin + '/logo.png'}
                                                alt={configs.getCustomLogo().mobile.alt}
                                                onClick={this.handleLogoClick}
                                            />
                                    }
                                </Fragment>
                        }
                        {
                            user &&
                            <MobileProjectFilter style={{zIndex: headerIcon}}/>
                        }

                    </Fragment>
                }


                {
                    !isMobile &&
                    <Fragment>
                        <Flex item={'0 1 0'} container alignItems={'center'}
                              style={{
                                  marginRight: isMobile ? 20 : 0,
                                  paddingLeft: isMobile ? 0 : 10
                              }}
                              justifyContent={isMobile ? 'center' : 'flex-start'}>
                            {
                                configs.getCustomLogo().enabled ?
                                    <Fragment>
                                        <Tooltip title={versionToolTip}
                                                 placement={"bottom"}>
                                            <img
                                                style={{...configs.getCustomLogo().desktop.style}}
                                                src={window.location.origin + '/logo.png'}
                                                alt={configs.getCustomLogo().desktop.alt}
                                            />
                                        </Tooltip>
                                        {
                                            configs.getCustomLogoText().enabled &&
                                            <img
                                                style={{...configs.getCustomLogoText().desktop.style}}
                                                src={window.location.origin + '/logoText.png'}
                                                alt={configs.getCustomLogoText().desktop.alt}
                                            />
                                        }
                                    </Fragment>
                                    :
                                    <Fragment>
                                        <Tooltip title={versionToolTip}
                                                 placement={"bottom"}>
                                            <img
                                                style={{
                                                    width: '11%',
                                                    minWidth: 37,
                                                    maxWidth: 67
                                                }}
                                                src={H4Logo}
                                                alt="H4"
                                            />
                                        </Tooltip>
                                        < img
                                            style={{
                                                width: '20%',
                                                minWidth: 88,
                                                maxWidth: 118
                                            }}
                                            src={H4LogoText}
                                            alt="Software"
                                        />
                                    </Fragment>
                            }
                        </Flex>
                        <Flex item>
                            {
                                isTitleVisible && currentRoute.title &&
                                <Typography className={classes.title} align="center" variant="h6" color="inherit">
                                    <Trans i18nKey={currentRoute.title}/>
                                </Typography>
                            }
                            {
                                breadCrumbTail &&
                                <Flex item container justifyContent={'center'}>
                                    {
                                        !_.isEmpty(breadCrumbHead) &&
                                        <Typography className={classes.subtitle} variant="body2"
                                                    color="inherit">{breadCrumbHead} > &nbsp;</Typography>
                                    }
                                    <Typography style={{fontWeight: 'bold'}} className={classes.subtitle}
                                                variant="body2" color="inherit">{breadCrumbTail}</Typography>
                                </Flex>
                            }
                            {
                                isTitleVisible && currentRoute.subtitle && !breadCrumbTail && !isViewMyActions &&
                                <Typography className={classes.subtitle} align="center" variant="body2"
                                            color="inherit">
                                    <Trans i18nKey={currentRoute.subtitle}/>
                                </Typography>
                            }
                            {
                                isTitleVisible && isViewMyActions &&
                                <Typography className={classes.subtitle} align="center" variant="body2"
                                            color="inherit">
                                    <Trans i18nKey="global.my-actions"/>
                                </Typography>
                            }
                        </Flex>
                        <Flex item grow={0} container justifyContent={"flex-end"} alignItems={"center"}>
                            {
                                !online && !isMobile &&
                                <IconButton onClick={toggleOnline}>
                                    <WifiOffIcon className={classes.icon}/>
                                </IconButton>
                            }
                            {
                                user.isAdmin &&
                                <IconButton onClick={() => routeTo(GENERAL_CONFIGURATION.path, this.props)}>
                                    <SettingsIcon className={classes.icon}/>
                                </IconButton>
                            }
                            <LanguageSelector/>
                            <Flex item={'0 0 0'} container justifyContent={"flex-end"} alignItems={"center"}>
                                {user && <Profile data={{user}} actions={{logout, synchronize}} positionStyle={{
                                    top: 50,
                                    right: 32,
                                    bottom: 'unset',
                                    left: 'unset'
                                }}/>}
                            </Flex>
                        </Flex>
                    </Fragment>
                }
            </Flex>
        );
    }
}

export default withRouter(withStyles(styles)(connect(connector.mapStateToProps, connector.mapDispatchToProps)(withTranslation()(Header))));
