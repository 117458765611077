export const CREATE_QUESTIONNAIRE = '@questionnaire_edit/CREATE_QUESTIONNAIRE';
export const CREATE_QUESTIONNAIRE_SUCCESS = '@questionnaire_edit/CREATE_QUESTIONNAIRE_SUCCESS';
export const CREATE_QUESTIONNAIRE_FAIL = '@questionnaire_edit/CREATE_QUESTIONNAIRE_FAIL';

export const EDIT_QUESTIONNAIRE = '@questionnaire_edit/EDIT_QUESTIONNAIRE';
export const EDIT_QUESTIONNAIRE_SUCCESS = '@questionnaire_edit/EDIT_QUESTIONNAIRE_SUCCESS';
export const EDIT_QUESTIONNAIRE_FAIL = '@questionnaire_edit/EDIT_QUESTIONNAIRE_FAIL';

export const FETCH_QUESTIONNAIRE = '@questionnaire_edit/FETCH_QUESTIONNAIRE';
export const FETCH_QUESTIONNAIRE_SUCCESS = '@questionnaire_edit/FETCH_QUESTIONNAIRE_SUCCESS';
export const FETCH_QUESTIONNAIRE_FAIL = '@questionnaire_edit/FETCH_QUESTIONNAIRE_FAIL';

export const FETCH_EMPTY_QUESTIONNAIRE = '@questionnaire_edit/FETCH_EMPTY_QUESTIONNAIRE';

export const FETCH_QUESTIONS = '@questionnaire_edit/FETCH_QUESTIONS';
export const FETCH_QUESTIONS_SUCCESS = '@questionnaire_edit/FETCH_QUESTIONS_SUCCESS';
export const FETCH_QUESTIONS_FAIL = '@questionnaire_edit/FETCH_QUESTIONS_FAIL';

export const DELETE_QUESTIONNAIRE = '@questionnaire_edit/DELETE_QUESTIONNAIRE';
export const DELETE_QUESTIONNAIRE_SUCCESS = '@questionnaire_edit/DELETE_QUESTIONNAIRE_SUCCESS';
export const DELETE_QUESTIONNAIRE_FAIL = '@questionnaire_edit/DELETE_QUESTIONNAIRE_FAIL';