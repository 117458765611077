import React, {Component} from 'react';
import {Redirect, Route, Switch} from 'react-router-dom';
import TaskList from 'scenes/tasks/task-list/TaskList';
import TaskEdit from 'scenes/tasks/task-edit/TaskEdit';
import KanbanBoard from 'scenes/tasks/kanban/KanbanBoard';
import {
    TASK_ADMINISTRATION,
    TASK_BACKLOG,
    TASK_CREATE,
    TASK_EDIT,
    TASK_KANBAN,
    TASK_REPORTING,
    TASK_ACTION,
    DEVIATION_REDIRECT
} from 'routes/routes';
import Flex from 'components/grid/Flex';
import Backlog from 'scenes/tasks/backlog/Backlog';
import Actions from 'scenes/tasks/actions/Actions';
import _ from 'lodash';
import {connect} from 'react-redux';
import connector from './Tasks.connect';
import {withTranslation} from "react-i18next";
import utils from 'utils/Utils';
import TaskReporting from 'scenes/tasks/reporting/TaskReporting';
import TaskUpdateEventBus from './TaskUpdateEventBus';
import SynchronizationEventBus from 'scenes/SynchronizationEventBus';
import PageIsLoading from 'components/page-is-loading-progress/PageIsLoading';
import DeviationRedirect from "./deviations/DeviationRedirect";

export class Tasks extends Component {

    componentDidMount() {
        this.initializeData();
        //TODO LM: Oh my god wtf... There is some serious shitfuckery going on here. We have built a separate event bus
        // to trigger various data re-fetches all over the application. I have no idea why. My only sane (ass)amption
        // that Roy might wanted to reload data on already open pages? But this is the most (ass)inine way to do it...
        // An other possibility is that we didn't wanted to bother finding all the fucked up places where we have the
        // task stored in the reducer and this is the way we force a refresh.
        this.updateSubscription = TaskUpdateEventBus.update.subscribe(payload => {
            this.props.fetchTask(payload.taskBusinessId);
            this.props.fetchTaskConfiguration();
        });
        this.synchronizationSubscription = SynchronizationEventBus.update.subscribe(() => this.initializeData());
    }

    componentWillUnmount() {
        TaskUpdateEventBus.update.unSubscribe(this.updateSubscription);
        SynchronizationEventBus.update.unSubscribe(this.synchronizationSubscription);
    }

    initializeData = () => {
        this.props.fetchTaskManagementData();
        this.props.fetchActions();
    };

    render() {
        const {isTaskManagementReady, isTaskConfigurationReady, isSelectedProjectLoaded} = this.props;
        const isMobile = utils.checkBrowser();
        return (
            <Flex container style={{height: '100%'}}>
                <Switch>
                    <Route path={TASK_ADMINISTRATION.path}
                           render={function renderTaskList(props) {
                               return isTaskManagementReady ? <TaskList/> : <PageIsLoading/>
                           }}
                    />
                    <Route path={TASK_KANBAN.path}
                           render={function renderKanbanBoard(props) {
                               return isTaskManagementReady ? <KanbanBoard/> : <PageIsLoading/>
                           }}
                    />
                    <Route path={TASK_REPORTING.path}
                           render={function renderReportingBoard(props) {
                               return isTaskManagementReady ? <TaskReporting/> : <PageIsLoading/>
                           }}
                    />
                    <Route path={TASK_BACKLOG.path}
                           render={function renderTaskBacklog(props) {
                               return isTaskManagementReady ? <Backlog/> : <PageIsLoading/>
                           }}
                    />
                    <Route path={DEVIATION_REDIRECT.path}
                           render={function renderDeviationEdit(props) {
                               return <DeviationRedirect
                                   {...{
                                       routeProps: props,
                                       answerId: _.get(props.match, 'params.answerId'),
                                   }}/>
                           }}
                    />
                    <Route path={TASK_ACTION.path}
                           render={function renderActions(props) {
                               return isTaskConfigurationReady && isSelectedProjectLoaded && <Actions {...{
                                   ...props,
                                   taskBusinessId: _.get(props.match, 'params.taskBusinessId'),
                               }}/>
                           }}
                    />
                    <Route path={TASK_CREATE.path}
                           render={function renderTaskEdit(props) {
                               return isTaskConfigurationReady && isSelectedProjectLoaded &&
                                   <TaskEdit key={'create'} {...{routeProps: props}}/>
                           }}
                    />
                    <Route path={TASK_EDIT.path}
                           render={function renderTaskEdit(props) {
                               return isTaskConfigurationReady && isSelectedProjectLoaded && <TaskEdit
                                   {...{
                                       routeProps: props,
                                       key: _.get(props.match, 'params.taskBusinessId'),
                                       taskBusinessId: _.get(props.match, 'params.taskBusinessId'),
                                   }}/>
                           }}
                    />
                    <Redirect to={isMobile ? TASK_ADMINISTRATION.path : TASK_BACKLOG.path}/>
                </Switch>
            </Flex>
        );
    }
}

export default connect(connector.mapStateToProps, connector.mapDispatchToProps)(withTranslation()(Tasks));

