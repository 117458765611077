import React, {Component, Fragment} from 'react';
import {
    FormControl,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Tooltip,
    Typography,
    withStyles
} from "@material-ui/core";
import Flex from 'components/grid/Flex';
import * as colors from 'components/colors/Colors';
import RatingSelector010, {getRatingColor} from 'components/rating-selector/RatingSelector0-10';
import {withTranslation} from 'react-i18next';
import _ from 'lodash';
import CloseIcon from '@material-ui/icons/Close';
import QuestionsMarkIcon from '@material-ui/icons/HelpOutline';
import PhotoCamera from '@material-ui/icons/PhotoCamera';
import PhotoOutlined from '@material-ui/icons/PhotoOutlined';
import AuditExecutionListView from './AuditExecutionListView';

const styles = theme => ({
    topicSelector: {
        minWidth: 120,
        '@media (min-width: 800px)': {
            marginTop: 50,
        },
    },
    subTopicSelector: {
        minWidth: 120,
    },
    numberCircle: {
        width: 24,
        height: 24,
        borderRadius: '50%',
    },
    additionalInformationContainer: {
        flex: '1 1 auto',
        '@media (min-width: 900px)': {
            flex: '0 1 auto',
            margin: 20,
        },
    },
    picture: {
        width: '100%',
        height: '100%',
        maxHeight: 350,
        maxWidth: 350,
        objectFit: 'contain',
        backgroundColor: colors.htmlWhite,
        '@media (min-width: 1000px)': {
            maxHeight: 500,
            maxWidth: 500,
        },
    },
    questionContainerPicture: {
        marginTop: 20,
        minHeight: 300,
        '@media (min-width: 900px)': {
            margin: 20,
        },
    },
    textField: {
        minWidth: 200,
        marginTop: 10,
        borderRadius: 4,
        '@media (min-width: 800px)': {
            fontSize: '1.5em',
        }
    },
    defaultIcon: {
        color: colors.htmlBlack,
        fontSize: 44
    },
    tooltip: {
        marginTop: 5,
        fontSize: 15,
        backgroundColor: colors.dustyGrey
    }
});

const Comment = (props) => {
    const {classes, currentQuestion, handleComment, handleFile, openGallery, translate, editDisabled} = props;
    const showWarning = currentQuestion.answer.point === 0 && _.isEmpty(currentQuestion.answer.comment);

    return (
        <Flex item container fullWidth direction={'column'} padding={10}>
            {
                currentQuestion.answer.point !== 10 && currentQuestion.answer.point !== '' &&
                <>
                    <Tooltip open={showWarning}
                             classes={{tooltip: classes.tooltip}}
                             placement="bottom"
                             title={translate('audit-management.execute-audit.no-vda-audit-comment-warning')}>
                        <Flex item grow={0}>
                            <TextField
                                placeholder={translate('audit-management.execute-audit.comment-placeholder')}
                                variant="outlined"
                                value={_.get(currentQuestion, 'answer.comment', '')}
                                onChange={handleComment}
                                multiline
                                rows="3"
                                disabled={editDisabled}
                                classes={{
                                    root: classes.textField,
                                }}
                                inputProps={{
                                    input: classes.textField,
                                }}
                                margin="none"
                                fullWidth
                            />
                        </Flex>
                    </Tooltip>
                    <Flex container item fullWidth alignItems={'center'} grow={0} style={{paddingLeft: 2}}>
                        <Flex item container justifyContent={'flex-end'} alignItems={'center'}>
                            <Flex item grow={0}>
                                {currentQuestion.answer.point !== 10 &&
                                <Fragment>
                                    <input
                                        accept="image/*"
                                        style={{display: 'none'}}
                                        id="contained-button-file"
                                        capture="camera"
                                        type="file"
                                        disabled={editDisabled}
                                        onChange={editDisabled ? ()=>{} : handleFile}
                                    />
                                    <label htmlFor="contained-button-file">
                                        <IconButton component={'span'}
                                                    disabled={editDisabled}
                                                    style={{padding: 0, marginTop: 4}}>
                                            <PhotoCamera className={classes.defaultIcon} style={{color: colors.audit}}/>
                                        </IconButton>
                                    </label>
                                </Fragment>
                                }
                            </Flex>
                            <Flex item grow={0}>
                                <IconButton style={{padding: 0}}
                                            onClick={openGallery}
                                            disabled={currentQuestion.answer.attachments.length < 1}>
                                    <PhotoOutlined style={{fontSize: 45}}/>
                                    {
                                        currentQuestion.answer.attachments.length > 0 &&
                                        <Typography>({currentQuestion.answer.attachments.length})</Typography>
                                    }
                                </IconButton>
                            </Flex>
                        </Flex>

                    </Flex>
                </>
            }
        </Flex>
    );
};

export const QuestionCircle = (props) => {
    const {classes, number, question, jumpToQuestion, selected, disabled} = props;
    const color = getRatingColor(_.get(question, 'answer.point'));
    return (
        <Flex item={'0 0 50px'}

              onClick={() => {
                  if (!disabled)
                      jumpToQuestion(question.businessId)
              }}>
            <Flex container center className={classes.numberCircle} style={{
                border: `2px solid ${disabled && !selected ? colors.grey : selected ? colors.htmlWhite : color || colors.jungleGreen}`,
                fontWeight: 'bold',
                color: disabled && !selected ? colors.grey : selected ? colors.htmlWhite : color || colors.jungleGreen,
                backgroundColor: selected ? color || colors.blackPearl2 : colors.htmlWhite,
            }}>
                {number}
            </Flex>
        </Flex>
    );
};

export class VdaAuditExecutor extends Component {

    state = {
        topic: {},
        subtopic: {},
        showAdditionalInformation: false
    }

    switchAdditionalInformation = () => {
        this.setState({
            showAdditionalInformation: !this.state.showAdditionalInformation
        })
    }

    changeSubTopic = event => {
        const subTopic = event.target.value;
        const questions = this.props.questions
            .filter(question => subTopic.questionIds.includes(question.businessId));
        const firstQuestion = questions[0];
        if (questions) {
            this.props.jumpToQuestion(firstQuestion.businessId);
        } else {
            console.error('There should be no subTopic without a question');
        }
    }

    changeTopic = event => {
        const topic = event.target.value;
        const firstSubtopic = topic.children[0];
        if (firstSubtopic) {
            this.changeSubTopic({target: {value: firstSubtopic}});
        } else {
            const questions = this.props.questions
                .filter(question => topic.questionIds.includes(question.businessId));
            const firstQuestion = questions[0];
            if (questions) {
                this.props.jumpToQuestion(firstQuestion.businessId);
            } else {
                console.error('Topic has no question or subtopic');
            }
        }
    }

    handleAnswer = event => {
        this.props.handleAnswer(event.target.value)();
    }

    render() {
        const {
            i18n, classes, audit, questions, currentQuestion, jumpToQuestion, intermediateResult, editDisabled,
            handleComment, handleFile, openGallery, t: translate, view, setView, exportExcelAndCloseAudit
        } = this.props;

        const {showAdditionalInformation} = this.state;

        const {changeSubTopic, changeTopic, handleAnswer, switchAdditionalInformation} = this;

        const topic = audit.topics.find(topicInArray => topicInArray.businessId === currentQuestion.topicId);
        const subTopic = topic.children.find(subTopicInArray => subTopicInArray.businessId === currentQuestion.subTopicId);
        const currentlySeenQuestions = questions.filter(question => (subTopic || topic).questionIds.includes(question.businessId));
        const currentlySeenQuestionNumber = currentlySeenQuestions.findIndex(question => question.businessId === currentQuestion.businessId);
        const currentTranslation = currentQuestion.translations.find(translation => translation.languageCode === i18n.language) || {};
        const currentPictureUrl = _.get(currentTranslation, 'picture.downloadUrl');

        const fallbackDescription = currentQuestion.translations[0].question || '';
        const fallBackPictureUrl = _.get(currentQuestion.translations[0], 'picture.downloadUrl');
        const disabled = currentQuestion.answer.point === 0 && _.isEmpty(currentQuestion.answer.comment);

        return view === 'LIST'
            ? <AuditExecutionListView {...{
                topics: audit.topics,
                questions,
                jumpToQuestion,
                setView,
                intermediateResult,
                auditId: audit.businessId,
                exportExcelAndCloseAudit,
                editDisabled
            }}/>
            : <Flex item container column>
                <FormControl className={classes.topicSelector} variant={'filled'}>
                    <InputLabel shrink htmlFor="topic-selector" style={{textTransform: 'uppercase'}}>
                        Topic {topic.ordinal}
                    </InputLabel>
                    <Select
                        value={topic}
                        disabled={disabled}
                        renderValue={value => value.name}
                        onChange={changeTopic}
                        SelectDisplayProps={{
                            style: {
                                textTransform: 'capitalize',
                                fontWeight: 'bold'
                            }
                        }}
                        inputProps={{
                            name: 'topic',
                            id: 'topic-selector',
                        }}
                    >
                        {
                            audit.topics.map(topicToMap => (
                                <MenuItem value={topicToMap} key={topicToMap.businessId}
                                          style={{textTransform: 'capitalize'}}
                                >{topicToMap.name}</MenuItem>
                            ))
                        }
                    </Select>
                </FormControl>
                {
                    !_.isEmpty(topic.children) &&
                    <FormControl className={classes.subTopicSelector} variant={'filled'}>
                        <InputLabel shrink htmlFor="subtopic-selector"
                                    style={{textTransform: 'uppercase', paddingLeft: 30}}>
                            Subtopic {topic.ordinal}.{_.get(subTopic, 'ordinal')}
                        </InputLabel>
                        <Select
                            value={subTopic}
                            renderValue={value => value.name}
                            style={{paddingLeft: 23}}
                            disabled={disabled}
                            SelectDisplayProps={{
                                style: {
                                    textTransform: 'capitalize',
                                    fontWeight: 'bold'
                                }
                            }}
                            onChange={changeSubTopic}
                            inputProps={{
                                name: 'subtopic',
                                id: 'subtopic-selector',
                            }}
                        >
                            {
                                topic.children.map(subTopicToMap => (
                                    <MenuItem value={subTopicToMap}
                                              style={{textTransform: 'capitalize'}}
                                              key={subTopicToMap.businessId}>{subTopicToMap.name}</MenuItem>
                                ))
                            }
                        </Select>
                    </FormControl>
                }
                <Flex item={'0 1 auto'} container column
                      style={{borderBottom: `1px solid ${colors.silver}`, padding: 10}}>
                    <Typography style={{
                        textTransform: 'uppercase', color: colors.paleSky, fontSize: 12, marginBottom: 10,
                        fontWeight: 'bold'
                    }}>
                        Questions</Typography>
                    <Flex item container>
                        {
                            currentlySeenQuestions.map((question, index) => {
                                return (
                                    <QuestionCircle key={question.businessId} {...{
                                        classes, question, jumpToQuestion,
                                        number: index + 1,
                                        selected: question.businessId === currentQuestion.businessId,
                                        disabled: disabled
                                    }}/>
                                );
                            })
                        }
                    </Flex>
                </Flex>
                <Flex item={'0 1 auto'} container padding={10}>
                    <Typography style={{fontWeight: 'bold',}}>
                        {topic.ordinal}{_.get(subTopic, 'ordinal') ? `.${subTopic.ordinal}` : ''}.{currentlySeenQuestionNumber + 1}{' '}
                        {_.get(currentTranslation, 'question') || fallbackDescription}
                    </Typography>
                </Flex>
                <Flex item={'0 1 auto'} container column padding={10}>
                    <Typography style={{
                        textTransform: 'uppercase', color: colors.paleSky, fontSize: 12, marginBottom: 10,
                        fontWeight: 'bold'
                    }}>
                        Rating</Typography>
                    <RatingSelector010 style={{minWidth: 350}}
                                       disabled={editDisabled}
                                       value={currentQuestion.answer}
                                       options={currentQuestion.possibleAnswers}
                                       renderValue={value => value.point}
                                       onChange={handleAnswer}
                    />
                </Flex>
                {
                    currentTranslation.comment &&
                    <Flex container item={'0 1 auto'} center style={{margin: 10}}
                          onClick={switchAdditionalInformation}>
                        {
                            showAdditionalInformation &&
                            <Flex container column padding={15} className={classes.additionalInformationContainer}
                                  style={{
                                      boxShadow: `0 0 8px ${colors.transparentBlack02}`,
                                  }}>
                                <Flex item container alignItems={'center'}
                                      style={{
                                          marginBottom: 15
                                      }}>
                                    <Flex item container center>
                                        <Typography style={{
                                            color: colors.blackPearl2,
                                            textTransform: 'uppercase',
                                            fontWeight: 'bold',
                                            fontSize: 16
                                        }}>
                                            {translate('audit-administration.questionnaireEdit.additional-information')}
                                        </Typography>
                                    </Flex>
                                    <IconButton size={'small'} onClick={switchAdditionalInformation}>
                                        <CloseIcon/>
                                    </IconButton>
                                </Flex>

                                <Typography style={{
                                    color: colors.blackPearl2,
                                    fontWeight: 'bold'
                                }}>
                                    {currentTranslation.comment}
                                </Typography>
                            </Flex>
                        }
                        {
                            !showAdditionalInformation &&
                            <>
                                <QuestionsMarkIcon style={{color: colors.sanMarinoBlue, marginRight: 5}}/>
                                <Typography style={{
                                    color: colors.sanMarinoBlue,
                                    textTransform: 'uppercase',
                                    fontWeight: 'bold'
                                }}>
                                    {translate('audit-administration.questionnaireEdit.additional-information')}
                                </Typography>
                            </>
                        }
                    </Flex>

                }
                <Comment {...{
                    classes, currentQuestion, handleComment, handleFile, openGallery, translate, editDisabled
                }}
                />
                {
                    (!_.isEmpty(currentPictureUrl) || !_.isEmpty(fallBackPictureUrl)) &&
                    <Flex container justifyContent={'center'} className={classes.questionContainerPicture}>
                        <img
                            src={!_.isEmpty(currentPictureUrl) ? currentPictureUrl : fallBackPictureUrl}
                            alt={''}
                            className={classes.picture}
                        />
                    </Flex>
                }
            </Flex>

    }
}

export default withStyles(styles)(withTranslation()(VdaAuditExecutor));
