import {
    string,
    shape,
} from "prop-types"
import {USER, USER_GRAPHQL, mapUser} from './User.model';

export const COMMENT = {
    businessId: string.isRequired,
    description: string.isRequired,
    creationDate: string.isRequired,
    user: shape(USER),
};

export const COMMENT_GRAPHQL = `
    businessId
    description
    creationDate
    user {
        ${USER_GRAPHQL}
    }
`;

export function mapComment(comment) {
    return {
        businessId: comment.businessId,
        description: comment.description,
        creationDate: comment.creationDate,
        user: mapUser(comment.user)
    }
}
