const loginUsername = 'h4-login-username';
const loginPassword = 'h4-login-password';
const loginButton = 'h4-login-button';

const landingAuditTile = 'h4-audit-landing-tile';
const landingAuditMyAudits = 'h4-audit-landing-my-audits-button';
const landingAuditAdministration = 'h4-audit-landing-administration-button';
const landingAuditReporting = 'h4-audit-landing-reporting-button';

const landingTaskTile = 'h4-task-landing-tile';
const landingTaskStatistics = 'h4-task-statistics-button';
const landingTaskStoryList = 'h4-task-story-list-button';
const landingTaskStoryKanban = 'h4-task-story-kanban-button';

const landingKpiTile = 'h4-kpi-landing-tile';
const landingKpiMachineStatusButton = 'h4-kpi-machine-status-button';

const taskTabKanban = 'h4-task-tab-kanban';
const taskTabBacklog = 'h4-task-tab-backlog';
const taskTabHistory = 'h4-task-tab-history';
const taskSwitchMyTasks = 'h4-task-switch-my-tasks';

const issueCreateButton = 'h4-issue-create-button';
const issueQuickCreateButton = 'h4-issue-quick-create-button';
const issueTitle = 'h4-issue-title';

const firstListItemOnPage = 'h4-first-list-item-on-page';

const taskBacklogOpenButton = 'h4-task-backlog-open-button';
const taskBacklogDeleteButton = 'h4-task-backlog-delete-button';
const taskBacklogCloneButton = 'h4-task-backlog-clone-button';

const taskKanbanBoard = 'h4-kanban-board';

const editId = 'h4-edit-id';
const editTitle = 'h4-edit-title';
const responsibleSelector = 'h4-responsible-selector';
const severitySelector = 'h4-severity-selector';
const statusSelector = 'h4-status-selector';
const list = (name, index) => `h4-${name}-${index}`;

const cancelButton = 'h4-cancel-button';
const cloneButton = 'h4-cancel-button';

const freeTextSearchInput = 'h4-free-text-search-input';
const usernameId = user => `h4-username-${user ? user.username : ''}`;

const listItem= 'h4-list-item';
const listItemCreateButton = 'h4-list-item-create-button';

export default {
    loginUsername,
    loginPassword,
    loginButton,
    landingAuditTile,
    landingAuditMyAudits,
    landingAuditAdministration,
    landingAuditReporting,
    landingTaskTile,
    landingTaskStatistics,
    landingTaskStoryList,
    landingTaskStoryKanban,
    landingKpiTile,
    landingKpiMachineStatusButton,
    taskTabKanban,
    taskTabBacklog,
    taskTabHistory,
    taskSwitchMyTasks,
    taskBacklogOpenButton,
    taskBacklogDeleteButton,
    taskBacklogCloneButton,
    editId,
    issueCreateButton,
    issueQuickCreateButton,
    issueTitle,
    freeTextSearchInput,
    usernameId,
    taskKanbanBoard,
    firstListItemOnPage,
    editTitle,
    responsibleSelector,
    severitySelector,
    statusSelector,
    cancelButton,
    cloneButton,
    list,
    listItem,
    listItemCreateButton
}
