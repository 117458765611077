import {
    string,
} from "prop-types"

export const TAG = {
    businessId: string,
    label: string,
    category: string
};

export const TAG_GRAPHQL = `
    businessId
    label
    category
`;

export const UNSEEN_CONTENT_TAG = 'UNSEEN_CONTENT';
export const UNSEEN_ACTION_TAG = 'UNSEEN_ACTION';
export const UNSEEN_COMMENT_TAG = 'UNSEEN_COMMENT';
export const UNSEEN_ATTACHMENT_TAG = 'UNSEEN_ATTACHMENT';
export const UNSEEN_CHECKLIST_ITEM_TAG = 'UNSEEN_CHECKLIST_ITEM';

export function mapTag(tag){
    return {
        businessId: tag.businessId,
        label: tag.label,
        category: tag.category,
    }
}

export function tagInput(tag) {
    return {
        label: tag.label || tag.name
    }
}

export function mapFreeTag(tag) {
    return {
        ...tag,
        //TODO LM: react-tag-autocomplete cannot handle custom object, so we have this mapping.
        //Since its going to be in all the handlers even if I push the mapping up to the component, it might as well
        //remain here, to avoid further confusion. BUT do not use it!
        name: tag.name || (tag.label && tag.label.toLowerCase())
    }
}
