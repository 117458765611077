import * as actionTypes from "./AuditReportingHistory.action.types";
import utils from "utils/Utils"
import {AUDIT_GRAPHQL} from "domain/audit/Audit.model";
import query from 'store/GraphqlQueries.js';

export function fetchAuditHistory(filterData) {
    const filter = mapAuditHistoryFilter(filterData);

    return query.graphql({
        type: actionTypes.FETCH_AUDIT_REPORTING_HISTORY_DATA,
        caller: 'fetchAuditHistory',
        query: `
            query ($filter: AuditHistoryFilter!){
                fetchAuditHistory(filter: $filter) {
                    statistics {
                        count
                        outcome 
                    }
                    auditSummaryBreakdown {
                        auditId
                        outcome
                        interval {
                            start
                            end
                            days
                        }
                    }
                }
            }
           `,
        variables: {
            filter: filter
        }
    });
}

export function fetchAudit(auditId) {
    return query.graphql({
        type: actionTypes.FETCH_AUDIT,
        caller: 'fetchAudit',
        query: `
            query ($auditId: String!) {
                fetchAudit(auditId: $auditId) {
                    ${AUDIT_GRAPHQL} 	
                }  
            }
           `,
        variables: {
            auditId: auditId
        }
    });
}

function mapAuditHistoryFilter(filterData) {
    return {
        organizationLevelId: filterData.selectedOrganizationLevel.businessId,
        from: filterData.periodStartDate.format(utils.API_LOCAL_DATE_FORMAT),
        to: filterData.periodEndDate.format(utils.API_LOCAL_DATE_FORMAT),
        durationType: filterData.durationType,
        auditType: filterData.auditType,
    };
}
