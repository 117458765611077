import React, {PureComponent} from "react";
import {Tooltip, withStyles} from "@material-ui/core";
import {withTranslation} from "react-i18next";
import Flex from "components/grid/Flex";
import MaterialSelect from "components/material-select/MaterialSelect";
import {dustyGrey, medium} from "components/colors/Colors";

const styles = theme => ({
    basicContainer: {
        paddingTop: 25
    },
    fieldTitle: {
        color: dustyGrey,
        textTransform: 'uppercase',
        fontSize: 12,
        minWidth: 250
    },
    inputItem: {
        padding: 0,
    },
    inputContainerMargins: {
        marginRight: 10
    },
    tooltip: {
        fontSize: 14,
        backgroundColor: medium
    },
});

export class QuestionSelectors extends PureComponent {
    render() {
        const {
            classes, handleQuestionChange,  catalogues, cycles, question,
            t: translate
        } = this.props;
        const questionCatalogueCanBeChanged = !question.assignedToQuestionnaire || !question.catalogue;
        return (
            <Flex container justifyContent={'flex-start'} className={classes.basicContainer}>
                <Tooltip
                    title={translate('audit-administration.questionEdit.catalogueChangeWarning')}
                    placement={"right-end"}
                    classes={{
                        tooltip: classes.tooltip
                    }}
                    disableFocusListener={questionCatalogueCanBeChanged}
                    disableHoverListener={questionCatalogueCanBeChanged}
                    disableTouchListener={questionCatalogueCanBeChanged}
                >
                    <Flex item={'0 1 200px'} container alignItems={'center'}
                          className={classes.inputContainerMargins}>
                        <MaterialSelect
                            options={catalogues}
                            value={question.catalogue || ' '}
                            name={'catalogue'}
                            label={translate('audit-administration.questionEdit.catalogue')}
                            handleChange={handleQuestionChange}
                            disabled={!questionCatalogueCanBeChanged}
                        />
                    </Flex>
                </Tooltip>
                <Flex item={'0 1 200px'} container alignItems={'center'}
                      className={classes.inputContainerMargins}>
                    <MaterialSelect
                        options={cycles}
                        value={question.cycle || ' '}
                        name={'cycle'}
                        label={translate('audit-administration.questionEdit.recommendedCycle')}
                        handleChange={handleQuestionChange}
                        translationPath={"audit-administration.cycle."}
                    />
                </Flex>

            </Flex>
        );
    }
}

export default withStyles(styles)(withTranslation()(QuestionSelectors));
