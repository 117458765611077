import React, {Component} from 'react';
import {Button, Divider, Paper, withStyles} from "@material-ui/core";
import {connect} from "react-redux";
import connector from "./ScheduleEdit.connect";
import Flex from "components/grid/Flex";
import {Trans, withTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import {AUDIT_ADMINISTRATION_SCHEDULE_CREATE, AUDIT_ADMINISTRATION_SCHEDULES, prevPath, routeTo} from 'routes/routes';
import {auditPaperGrey, doveGrey, guardsmanRed, htmlBlack, materialUIBorder, random} from "components/colors/Colors";
import _ from "lodash";
import Typography from "@material-ui/core/Typography";
import ConfirmationDialog from "components/confirmation-dialog/ConfirmationDialog";
import update from "immutability-helper";
import {ACTIVE, getMinDate, INACTIVE, mapDuration, RANDOM} from "domain/audit/Schedule.model";
import AuditorSelector from "./components/AuditorSelector";
import ScheduleDetailsSelectors from "./components/ScheduleDetailsSelectors";
import DurationCircle from "./components/DurationCircle";
import ScheduleTimeSelectors from "./components/ScheduleTimeSelectors";
import CalendarPreview from "./components/CalendarPreview";
import moment from "moment";
import H4Tooltip from 'components/h4-tooltip/H4Tooltip';

const styles = theme => ({
    paper: {
        backgroundColor: auditPaperGrey,
        borderRadius: 7,
        boxShadow: 'none',
        border: `1px solid ${materialUIBorder}`,
        display: 'flex',
        flexDirection: 'column',
        flex: '1 0 auto'
    },
    controlButtons: {
        minWidth: 150,
        marginTop: 15,
        marginLeft: 10
    },
    headerContainer: {
        paddingTop: 25,
    },
    title: {
        fontSize: 20,
        fontWeight: 'bold',
        color: htmlBlack
    },
    titlePrefix: {
        fontSize: 20,
        fontWeight: 'bold',
        color: doveGrey
    },
    durationCircleContainer: {
        position: 'relative',
        right: 30,
        top: 70,
        zIndex: 100,
        marginBottom: -40,
        width: 60
    },
    copied: {
        fontSize: 20,
        fontWeight: 'bold',
        color: random,
        textTransform: 'capitalize'
    }
});

const NUMBER_OF_MONTHS = 4;
const Header = (props) => {
    const {classes, selectedOrganizationLevel, copied, translate} = props;
    return (
        <Flex container item={'0 0 0'} direction={"row"} className={classes.headerContainer}
              justifyContent={"space-between"}>
            <Flex container item justifyContent={'flex-start'} basis={"50%"}>
                <Typography className={classes.titlePrefix} style={{marginLeft: 20}}>
                    <Trans i18nKey={"audit-administration.scheduleEdit.scheduledAuditFor"}/>
                </Typography>
                <Typography className={classes.title} style={{paddingLeft: 5}}>
                    {selectedOrganizationLevel.title}
                </Typography>
            </Flex>
            {
                copied &&
                <Flex container item justifyContent={'flex-start'} basis={"50%"}>
                    <Typography className={classes.copied} style={{paddingLeft: 5}}>
                        {translate('global.copied')}
                    </Typography>
                </Flex>

            }
        </Flex>
    )
};

const FootControlButtons = (props) => {
    const {
        classes, schedule, onDelete, onCancel, onSave, location, isSaveEnabled,
        noAdminRights, isEditDisabled, onClone, questionnaireNeedToBeReactivated, toggleInfoDialogOpen
    } = props;
    const saveDisabled = !isSaveEnabled();
    return (
        <Flex container item={'0 0 50px'} justifyContent={'space-between'}>
            <Flex item={'1 0 auto'} container>
                {
                    !_.isEmpty(schedule.businessId) &&
                    <H4Tooltip
                        title={noAdminRights ?
                            <Trans i18nKey={"audit-administration.noAdminRightOnOrganization"}/> : ""}
                        placement={"left"}
                    >
                        <Button variant="contained"
                                color="primary"
                                onClick={onDelete}
                                disabled={noAdminRights || _.isEmpty(schedule.businessId)}
                                className={classes.controlButtons}
                                style={{marginLeft: 0}}
                        >
                            <Trans i18nKey="global.delete"/>
                        </Button>
                    </H4Tooltip>
                }
                {
                    !isEditDisabled && !_.isEmpty(schedule.businessId) &&
                    <H4Tooltip
                        title={noAdminRights ?
                            <Trans i18nKey={"audit-administration.noAdminRightOnOrganization"}/> : ""}
                        placement={"left"}
                    >
                        <Button variant="contained"
                                color="primary"
                                className={classes.controlButtons}
                                disabled={noAdminRights}
                                onClick={onClone}
                        >
                            <Trans i18nKey="global.clone"/>
                        </Button>
                    </H4Tooltip>
                }
            </Flex>
            <Flex item={'1 0 auto'} container justifyContent={'flex-end'}>
                <Button variant="contained"
                        color="primary"
                        component={Link}
                        to={{
                            pathname: AUDIT_ADMINISTRATION_SCHEDULES.path,
                            state: {prevPath: prevPath({location})}
                        }}
                        className={classes.controlButtons}
                        onClick={onCancel}
                >
                    <Trans i18nKey="global.cancel"/>
                </Button>
                <H4Tooltip
                    title={noAdminRights ?
                        <Trans i18nKey={"audit-administration.noAdminRightOnOrganization"}/> : ""}
                    placement={"left"}
                >
                    {
                        isEditDisabled
                            ? <Button variant="contained"
                                      color="primary"
                                      className={classes.controlButtons}
                                      disabled={noAdminRights}
                                      onClick={onClone}
                            >
                                <Trans i18nKey="global.clone"/>
                            </Button>
                            : < Button variant="contained"
                                       color="primary"
                                       className={classes.controlButtons}
                                       disabled={saveDisabled || noAdminRights}
                                       onClick={questionnaireNeedToBeReactivated ? toggleInfoDialogOpen : onSave(schedule)}
                            >
                                <Trans i18nKey="global.save"/>
                            </Button>
                    }
                </H4Tooltip>

            </Flex>
        </Flex>
    );
};

export class ScheduleEdit extends Component {

    state = {
        schedule: {},
        deleteDialogOpen: false,
        infoDialogOpen: false,
        selectedDate: null,
        oneTimeOnly: false,
        selectedDays: [],
        copied: false,
        calendarPreviewDates: [],
        questionnaireNeedToBeReactivated: false,
        filteredQuestionnaires : []
    };

    componentDidMount() {
        const scheduleBusinessId = this.props.scheduleBusinessId;
        if (scheduleBusinessId) {
            this.props.fetchScheduleWithQuestionnaires(scheduleBusinessId)
                .then(() => {
                        const schedule = _.cloneDeep(this.props.schedule);
                        this.setState({
                            schedule: schedule,
                            selectedDate: moment(schedule.nextSchedule),
                            oneTimeOnly: _.isEmpty(schedule.frequency),
                        }, this.loadSelectedDays);
                    }
                )
                .catch((error) => {
                    console.log('error: ', error);
                    routeTo(AUDIT_ADMINISTRATION_SCHEDULES.path, this.props);
                });
        } else {
            this.props.fetchEmptySchedule().then(() => {
                this.setState({
                    schedule: _.cloneDeep(this.props.schedule),
                    selectedDate: moment(this.props.schedule.nextSchedule),
                    oneTimeOnly: _.isEmpty(this.props.schedule.frequency)
                }, this.loadSelectedDays);
            });
        }
    }

    toggleDeleteDialogOpen = () => {
        this.setState({deleteDialogOpen: !this.state.deleteDialogOpen})
    };

    toggleInfoDialogOpen = () => {
        this.setState({infoDialogOpen: !this.state.infoDialogOpen})
    };

    deleteSchedule = () => {
        this.props.deleteSchedule(this.state.schedule.businessId).then(() => {
            this.toggleDeleteDialogOpen();
            this.props.registerScheduleEditCallback(Promise.resolve('Fetch schedules needed'));
            this.goBack();
        });
    };

    goBack = () => {
        routeTo(AUDIT_ADMINISTRATION_SCHEDULES.path, this.props.routeProps);
    };

    isSaveEnabled = () => {
        return !_.isEmpty(this.props.organizationFilterBusinessId)
            && !_.isEmpty(this.state.schedule.type)
            && !_.isEmpty(this.state.schedule.questionnaireName)
            && !_.isEmpty(this.state.schedule.resultDefinitionId)
            && !_.isEmpty(this.state.schedule.name)
            && !_.isEmpty(this.state.schedule.auditorId)
            && !_.isEmpty(this.state.selectedDate)
            && !_.isEmpty(this.state.schedule.duration)
            && ((this.state.schedule.duration === 'SHIFT' && !_.isEmpty(this.state.schedule.shift)) || this.state.schedule.duration !== 'SHIFT')
            && (this.state.oneTimeOnly || (this.state.schedule.frequency && this.state.schedule.frequency.unit && this.state.schedule.frequency.amount > 0));
    };

    saveSchedule = (schedule) => event => {
        const data = {
            type: this.state.schedule.type,
            name: this.state.schedule.name,
            questionnaireId: this.props.questionnaires.find(q11 => q11.name === this.state.schedule.questionnaireName).businessId,
            duration: this.state.schedule.duration,
            resultDefinitionId: this.state.schedule.resultDefinitionId,
            auditorId: this.state.schedule.auditorId === RANDOM ? null : this.state.schedule.auditorId,
            nextSchedule: this.state.selectedDate,
            organizationLevelId: this.props.organizationFilterBusinessId,
            status: this.state.oneTimeOnly ? ACTIVE : schedule.status,
            frequency: this.state.oneTimeOnly ? null : this.state.schedule.frequency,
            shift: this.state.schedule.shift
        };
        if (this.state.schedule.businessId) {
            this.props.registerScheduleEditCallback(this.props.editSchedule({
                businessId: this.state.schedule.businessId,
                ...data
            }));
        } else {
            this.props.registerScheduleEditCallback(this.props.createSchedule(data));
        }
        routeTo(AUDIT_ADMINISTRATION_SCHEDULES.path, this.props.routeProps);
    };

    handleScheduleChange = (event) => {
        const target = event.target;
        const frequency = {
            amount: 1,
            unit: mapDuration(target.value)
        };


        if (target.name === 'type') {
            this.setState(oldState => {
                return {
                    filteredQuestionnaires: this.filterQuestionnaires(target.value),
                    schedule: update(oldState.schedule, {
                        questionnaireName: {$set: undefined}
                    }),
                };
            });
        }

        if (target.name === 'duration')
            this.setState(oldState => {
                return {
                    selectedDate: getMinDate(target.value),
                    schedule: update(oldState.schedule, {
                        frequency: {$set: frequency},
                        [target.name]: {$set: target.value}
                    }),
                };
            }, this.loadSelectedDays);
        else
            this.setState(oldState => {
                return {
                    schedule: update(oldState.schedule, {
                        [target.name]: {$set: target.value}
                    }),
                };
            });
    };

    handleAuditorChange = (auditorId) => {
        this.setState(oldState => {
            return {
                schedule: update(oldState.schedule, {
                    auditorId: {$set: auditorId}
                }),
            };
        });
    };

    handleShiftChange = event => {
        this.setState(oldState => {
            return {
                schedule: update(oldState.schedule, {
                    shift: {$set: event.target.value}
                }),
            };
        });
    };

    handleDateChange = (date) => {
        this.setState({
                selectedDate: moment(date)
            }, this.loadSelectedDays
        );
    };

    handleFrequencyCheck = () => {
        const oneTimeOnly = this.state.oneTimeOnly;
        const frequency = {
            amount: 1,
            unit: this.state.schedule.duration
        };
        this.setState(oldState => {
            return {
                oneTimeOnly: !oneTimeOnly,
                schedule: update(oldState.schedule, {
                    frequency: {$set: frequency}
                }),
            };
        }, this.loadSelectedDays);
    };

    handleActiveCheck = () => {
        const newStatus = this.state.schedule.status === ACTIVE ? INACTIVE : ACTIVE;
        this.setState({
            schedule: {
                ...this.state.schedule,
                status: newStatus,
            },
            questionnaireNeedToBeReactivated: newStatus === ACTIVE && !this.state.schedule.haveActiveQuestionnaire
        });
    };

    handleFrequencyChange = (event) => {
        const target = event.target;
        if (target.name === 'amount' && !_.isNumber(target.value) && target.value <= 0) {
            return;
        }

        this.setState(oldState => {
            return {
                schedule: update(oldState.schedule, {
                    frequency: {
                        [target.name]: {$set: target.value}
                    }
                }),
            };
        }, this.loadSelectedDays);

    };

    loadSelectedDays = () => {
        const duration = this.state.schedule.duration;
        const frequency = this.state.schedule.frequency;
        const startDay = this.state.selectedDate;
        const endDay = moment().startOf('month').add(NUMBER_OF_MONTHS, 'months');
        const oneTimeOnly = this.state.oneTimeOnly;

        if (!_.isEmpty(duration) && (oneTimeOnly || !_.isEmpty(frequency)) && !_.isEmpty(startDay) && !_.isEmpty(endDay)) {
            this.props.loadCalendar(duration, frequency, startDay, endDay, oneTimeOnly).then(() => {
                this.setState({
                    calendarPreviewDates: this.props.calendarPreviewDates
                })
            });
        }
    };

    isScheduleUpdated = () => {
        return !_.isEqual(this.props.schedule, this.state.schedule);
    };

    onClone = () => {
        Promise.resolve(this.props.cloneSchedule(this.props.schedule)).then(() => {
            routeTo(AUDIT_ADMINISTRATION_SCHEDULE_CREATE.path, this.props.routeProps);
            this.setState({
                schedule: _.cloneDeep(this.props.schedule),
                selectedDate: moment(this.props.schedule.nextSchedule),
                oneTimeOnly: _.isEmpty(this.props.schedule.frequency)
            }, this.loadSelectedDays)
        });
    };

    filterQuestionnaires= (type) => {
        if (type === "VDA 6.3") {
            return this.props.questionnaires.filter(q11 => q11.auditType === "VDA 6.3");
        } else {
            return this.props.questionnaires.filter(q11 => q11.auditType !== "VDA 6.3");
        }
    }

    render() {
        const {
            classes, selectedOrganizationLevel, auditTypes, t: translate, auditResultDefinitions,
            durationUnits
        } = this.props;
        const {
            goBack, isSaveEnabled, deleteSchedule, toggleDeleteDialogOpen, saveSchedule, handleScheduleChange,
            handleAuditorChange, handleDateChange, handleActiveCheck, handleFrequencyCheck, handleFrequencyChange,
            isScheduleUpdated, onClone, toggleInfoDialogOpen, handleShiftChange, filterQuestionnaires
        } = this;
        const {
            schedule, deleteDialogOpen, selectedDate, oneTimeOnly, copied, calendarPreviewDates, questionnaireNeedToBeReactivated, infoDialogOpen, filteredQuestionnaires
        } = this.state;
        const isEditDisabled = _.isEmpty(this.props.schedule.frequency) && !this.props.schedule.active;
        return (
            <Flex item container>
                <Flex item container direction={'column'}
                      style={{marginBottom: 20, marginRight: 20, marginLeft: 20, paddingLeft: 20}}>
                    <Header {...{
                        classes, selectedOrganizationLevel, copied, translate
                    }}/>
                    <div className={classes.durationCircleContainer}>
                        <DurationCircle
                            {...{
                                label: !_.isEmpty(schedule.duration) ? translate('audit-administration.duration.' + schedule.duration).charAt(0) : '?'
                            }}/>
                    </div>
                    <Paper className={classes.paper}>
                        <ScheduleDetailsSelectors {...{
                            schedule,
                            auditTypes,
                            handleScheduleChange,
                            questionnaires: filteredQuestionnaires,
                            auditResultDefinitions,
                            handleActiveCheck,
                            isScheduleUpdated,
                            oneTimeOnly,
                            isEditDisabled
                        }}/>
                        <Divider/>
                        <Flex container direction={"row"}>
                            <AuditorSelector {...{
                                selectedOrganizationLevel,
                                schedule,
                                handleAuditorChange,
                                isEditDisabled
                            }}/>

                            <ScheduleTimeSelectors {...{
                                schedule,
                                handleScheduleChange,
                                durationUnits,
                                selectedDate,
                                handleDateChange,
                                handleFrequencyCheck,
                                oneTimeOnly,
                                originalOneTimeOnly: _.isEmpty(this.props.schedule.frequency),
                                handleFrequencyChange,
                                isEditDisabled,
                                shifts: _.get(selectedOrganizationLevel, 'shiftSchedule.shifts', []),
                                handleShiftChange
                            }}/>
                        </Flex>
                        <Divider/>
                        <CalendarPreview {...{
                            selectedDays: calendarPreviewDates,
                            duration: schedule.duration,
                            numberOfMonths: NUMBER_OF_MONTHS,
                            modifiersStyles: {
                                today: {
                                    color: guardsmanRed
                                },
                                selected: {
                                    borderRadius: '0%',
                                    borderBottom: (schedule.duration === 'WEEK' || schedule.duration === 'DAY') && ('2px solid ' + auditPaperGrey),
                                    borderRight: (schedule.duration === 'DAY') && ('2px solid ' + auditPaperGrey)
                                }
                            }
                        }}/>
                        <ConfirmationDialog dialogOpen={deleteDialogOpen}
                                            onDialogClose={toggleDeleteDialogOpen}
                                            onConfirm={deleteSchedule}
                                            confirmationTextKey={"audit-administration.scheduleEdit.deleteScheduleConfirmation"}
                        />
                        <ConfirmationDialog
                            singleConfirmationButton
                            dialogOpen={infoDialogOpen}
                            onDialogClose={toggleInfoDialogOpen}
                            onConfirm={saveSchedule(schedule)}
                            confirmationTextKey={"audit-administration.scheduleList.questionnaireReactivated"}
                            confirmationTexValues={{
                                questionnaire: schedule.questionnaireName
                            }}
                        />
                    </Paper>
                    <FootControlButtons {...{
                        classes,
                        location: this.props.routeProps,
                        onDelete: toggleDeleteDialogOpen,
                        onCancel: goBack,
                        onSave: saveSchedule,
                        schedule,
                        isSaveEnabled,
                        noAdminRights: !selectedOrganizationLevel.isUserAdmin,
                        isEditDisabled,
                        onClone,
                        questionnaireNeedToBeReactivated,
                        toggleInfoDialogOpen
                    }}/>
                </Flex>
            </Flex>
        );
    }
}

export default withTranslation()(withStyles(styles)(connect(connector.mapStateToProps, connector.mapDispatchToProps)(ScheduleEdit)));
