import React, {PureComponent,} from 'react';
import {DialogContent, Typography, withStyles} from "@material-ui/core";
import {withTranslation} from "react-i18next";
import {htmlGrey} from "components/colors/Colors";

export const styles = theme => ({
    text: {
        fontSize: 14,
        color: htmlGrey
    }
});

export class AssignedSchedules extends PureComponent {

    render() {
        const {classes, questionnaire, t: translate} = this.props;
        return (
            questionnaire.isAssignedToAuditSchedule ?
                <DialogContent>
                    <div style={{paddingBottom: 24}}>
                        <Typography className={classes.text}>
                            {translate('audit-administration.questionnaireEdit.assignedAuditSchedule')}
                        </Typography>
                        <ul>
                            {
                                questionnaire.assignedAuditSchedules.map((assignedAuditSchedule, index) => {
                                        return (
                                            <li key={index} className={classes.text}>
                                                <Typography className={classes.text}>
                                                    {assignedAuditSchedule}
                                                </Typography>
                                            </li>
                                        )
                                    }
                                )
                            }
                        </ul>
                    </div>
                </DialogContent>
                :
                <div/>
        );
    }
}

export default withStyles(styles)(withTranslation()(AssignedSchedules));
