import React, {Component} from 'react';
import {
    IconButton,
} from "@material-ui/core";
import Flex from 'components/grid/Flex';
import BootstrapInput from 'components/bootstrap-input/BootstrapInput';
import {
    Search as SearchIcon,
    Done as DoneIcon,
} from '@material-ui/icons';
import {
    greyBackground,
    controlButtonBlue
} from 'components/colors/Colors';
import {withTranslation} from "react-i18next";

export class QuickTaskCreate extends Component {
    render() {
        const {createQuickTask, handleChange, t: translate, searchText} = this.props;

        return (
            <Flex item grow={0} container style={{paddingBottom: 10}}>
                <IconButton disabled style={{padding: 0}}>
                    <SearchIcon style={{fontSize: 30, marginRight: 5}}/>
                </IconButton>
                <BootstrapInput
                    value={searchText}
                    tabIndex="-1"
                    placeholder={translate('task-management.task-list.quickTaskPlaceholder')}
                    onChange={handleChange}
                    onEnterKey={createQuickTask}
                    name={'searchText'}
                    fullWidth
                    inputProps={{
                        style: {
                            backgroundColor: greyBackground,
                            borderRadius: 10,
                            border: 'unset'
                        }
                    }}
                />
                <IconButton onClick={createQuickTask} style={{marginLeft: 5, padding: 0}}>
                    <DoneIcon style={{color: controlButtonBlue}}/>
                </IconButton>
            </Flex>
        );
    }
}

export default withTranslation()(QuickTaskCreate);
