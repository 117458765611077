import React, {Fragment, PureComponent} from 'react';
import {CircularProgress, Tooltip, Typography, withStyles} from "@material-ui/core";
import Flex from 'components/grid/Flex';
import {blueHighlight, greyHighlight, htmlWhite, low, normal} from 'components/colors/Colors';
import moment from 'moment';
import utils from 'utils/Utils';
import H4Avatar from 'components/avatar/H4Avatar';
import BlueGreenProgressBar from 'components/progress-bar/BlueGreenProgressBar';
import _ from 'lodash';
import {withTranslation} from 'react-i18next';
import configs from "configs/Configs";
import UnseenCircle from 'components/unseen/UnseenCircle';

export const styles = theme => ({
    titleBarItem: {
        lineHeight: 1,
    },
    hoverBackground: {
        backgroundColor: htmlWhite,
        transition: 'background-color 0.1s linear',
        '&:hover': {
            backgroundColor: greyHighlight
        }
    },
    iconContainer: {
        display: 'flex',
        justifyContent: 'flex-start',
        flex: '0 0 50px',
        position: 'relative'
    }
});

export class ActionListTile extends PureComponent {

    render() {
        const {
            classes, action, isSelected, handleDoubleClickOnAction, handleClickOnAction, applicationOnline,
            i18n, user
        } = this.props;
        const presentationId = _.get(action, 'businessId', '').replace('ACTION-', configs.getActionPrefix() + '-');
        const matchingTranslation = action.translations.find(translation => translation.language.code === i18n.language) || action.translations[0];
        const unseenByMe = action.isUnseen && user.businessId === _.get(action, 'assignee.businessId');
        return (
            <Flex item container className={classes.hoverBackground} grow={0} alignItems={'center'}
                  onDoubleClick={handleDoubleClickOnAction(action)}
                  onClick={handleClickOnAction(action)}
                  style={{
                      minHeight: 40,
                      backgroundColor: isSelected ? blueHighlight : '',
                      position: 'relative',
                  }}
            >
                <UnseenCircle {...{visibilityCondition: unseenByMe}}/>
                <Flex item={'0 0 60px'} style={{
                    borderLeft: `5px solid ${action.priority.type === 'URGENT' ? normal : low}`,
                    paddingLeft: 15,
                }}>
                    <Tooltip
                        title={utils.formattedUserName(action.assignee)}
                        placement={'bottom-start'}
                        classes={{
                            tooltip: classes.avatarTooltipLabel
                        }}
                    >
                        <H4Avatar {...{user: action.assignee}} classes={{avatarRoot: classes.avatarRoot}}/>
                    </Tooltip>
                </Flex>
                <Flex item>
                    <Typography className={classes.titleBarItem}
                                style={{marginRight: 5, marginLeft: 5}}>{matchingTranslation.title}</Typography>
                </Flex>
                <Flex item={'0 0 90px'} container alignItems={'center'}>
                    {
                        _.get(action, 'businessId', '').startsWith('OFFLINE')
                            ?
                            applicationOnline
                                ?
                                <CircularProgress classes={{root: classes.circularProgress}} size={20}/>
                                :
                                <Fragment/>
                            :
                            <Typography className={classes.titleBarItem}
                            >{presentationId}</Typography>
                    }
                </Flex>
                <Flex item={'0 0 100px'}>
                    <Typography className={classes.titleBarItem}>
                        {moment(action.dueDate).format(utils.DATE_FORMAT)}
                    </Typography>
                </Flex>
                <Flex item={'0 0 10%'}>
                    <BlueGreenProgressBar style={{height: 20}} value={action.progress}/>
                </Flex>
                {/* AK - "Accidentally" deleted. We put back if someone is missing this feature, but I won't believe it will ever happen.*/}
                {/*<Flex item={'0 0 120px'} container justifyContent={'flex-start'} style={{marginLeft: 20}}>*/}
                {/*    {*/}
                {/*        !isTaskOfflineCreated && applicationOnline &&*/}
                {/*        <div className={classes.iconContainer}>*/}
                {/*            <CommentIndicator*/}
                {/*                {...{*/}
                {/*                    classes: {iconButtonRoot: classes.iconColor},*/}
                {/*                    entityId: action.businessId,*/}
                {/*                    numberOfComments: action.comments.length,*/}
                {/*                    comments: action.comments,*/}
                {/*                    type: 'Action',*/}
                {/*                    onOpen: removeUnseenCommentTag(action),*/}
                {/*                    isUnseen: action.hasUnseenComment && user.businessId === action.assignee.businessId,*/}
                {/*                    iconColor: seenIconGrey,*/}
                {/*                    refresh*/}
                {/*                }}*/}
                {/*            />*/}
                {/*        </div>*/}
                {/*    }*/}
                {/*    {*/}
                {/*        previousAssignee && (previousAssignee.businessId !== action.assignee.businessId) &&*/}
                {/*        <Forward data={{user: previousAssignee, iconColor: seenIconGrey}}/>*/}
                {/*    }*/}
                {/*</Flex>*/}
            </Flex>
        );
    }
}

export default withStyles(styles)(withTranslation()(ActionListTile));
