import React, {Fragment, PureComponent} from "react";
import {withStyles} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Flex from "components/grid/Flex";
import {doveGrey, htmlWhite} from "components/colors/Colors";

const styles = theme => ({
    durationCircle: {
        width: 60,
        height: 60,
        borderRadius: '50%',
        backgroundColor: doveGrey
    },
    durationCircleText: {
        color: htmlWhite,
        fontWeight: 'bold',
        fontSize: 27
    },
});

export class DurationCircle extends PureComponent {
    render() {
        const {label, classes} = this.props;

        return (
            <Fragment>
                <Flex
                    container
                    justifyContent={'center'}
                    alignItems={'center'}
                    className={classes.durationCircle}>
                    <Typography className={classes.durationCircleText}>
                        {label}
                    </Typography>
                </Flex>
            </Fragment>
        );
    }
}

export default withStyles(styles)(DurationCircle);
