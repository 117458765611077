import React, {Component} from 'react';
import {withStyles, MenuItem} from "@material-ui/core";
import BootstrapSelect from 'components/bootstrap-select/BootstrapSelect';
import Flex from 'components/grid/Flex';
import {withTranslation} from "react-i18next";
import ids from 'components/ids/ids';
import moment from "moment";
import utils from 'utils/Utils';
import {dustyGrey} from "components/colors/Colors";

export const styles = theme => ({
    ellipsis: {
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        wordWrap: 'break-word'
    },
    menuItem: {
        textTransform: 'capitalize'
    },
    shiftTime: {
        color: dustyGrey,
        fontSize: 15,
        marginLeft: 7
    }
});

const ShiftItem = (props) => {
    const {classes, shift} = props;
    const startTime = moment(shift.startTime, utils.API_TIME_FORMAT).format(utils.TIME_FORMAT);
    const endTime = moment(shift.endTime, utils.API_TIME_FORMAT).format(utils.TIME_FORMAT);
    return (
        <Flex container style={{width: '100%'}}>
            <span>{shift.label}</span>
            <span className={classes.shiftTime}>({startTime} - {endTime})</span>
        </Flex>
    );
};

export class ConfiguredBootstrapSelect extends Component {
    state = {
        isOpen: false
    };

    onOpen = () => {
        this.setState({
            isOpen: true
        })
    };

    onClose = () => {
        this.setState({
            isOpen: false
        })
    };

    render() {
        const {
            classes, value, name, onChange, shifts, startAdornment, renderValue, inputProps, bold, t: translate, translationPath,
            objectWithLabel, tReady, bootstrapInputClasses, id, ...other
        } = this.props;
        const {isOpen} = this.state;
        if (renderValue) {
            console.warn('ConfiguredBootstrapSelect has its own render value defined, supplied one will be ignored');
        }
        return (
            <BootstrapSelect value={value}
                             onChange={onChange}
                             MenuProps={{
                                 disableScrollLock: true,
                             }}
                             renderValue={value =>
                                 <ShiftItem shift={value} classes={classes}/>
                             }
                             inputProps={{
                                 name,
                                 id: name + '-selector',
                                 ...inputProps
                             }}
                             SelectDisplayProps={{
                                 id
                             }}
                             onOpen={this.onOpen}
                             onClose={this.onClose}
                             open={isOpen}
                             bold={bold}
                             bootstrapInputClasses={bootstrapInputClasses}
                             {...other}
            >
                {
                    (shifts || []).map((item, index) => {
                            return (
                                <MenuItem value={item || ''}
                                          id={ids.list(name, index)}
                                          key={index}
                                          className={classes.menuItem}
                                >
                                    <ShiftItem shift={item} classes={classes}/>
                                </MenuItem>
                            )
                        }
                    )
                }
            </BootstrapSelect>
        );
    }
}


export default withStyles(styles)(withTranslation()(ConfiguredBootstrapSelect));
