export const FETCH_AUDITS = '@audit/FETCH_AUDITS';
export const FETCH_AUDITS_SUCCESS = '@audit/FETCH_AUDITS_SUCCESS';
export const FETCH_AUDITS_FAIL = '@audit/FETCH_AUDITS_FAIL';
export const FETCH_AUDITS_FROM_CACHE = '@audit/FETCH_AUDITS_FROM_CACHE';
export const FETCH_AUDIT = '@audit/FETCH_AUDIT';
export const FETCH_AUDIT_SUCCESS = '@audit/FETCH_AUDIT_SUCCESS';
export const FETCH_AUDIT_FAIL = '@audit/FETCH_AUDIT_FAIL';
export const GET_ANSWER_REFERENCE_ID = '@audit/ET_ANSWER_REFERENCE_ID';
export const GET_ANSWER_REFERENCE_ID_SUCCESS = '@audit/ET_ANSWER_REFERENCE_ID_SUCCESS';
export const GET_ANSWER_REFERENCE_ID_FAIL = '@audit/ET_ANSWER_REFERENCE_ID_FAIL';
export const FETCH_INIT_DATA_FOR_AD_HOC_AUDIT = '@audit/FETCH_INIT_DATA_FOR_AD_HOC_AUDIT';
export const FETCH_INIT_DATA_FOR_AD_HOC_AUDIT_SUCCESS = '@audit/FETCH_INIT_DATA_FOR_AD_HOC_AUDIT_SUCCESS';
export const FETCH_INIT_DATA_FOR_AD_HOC_AUDIT_FAIL = '@audit/FETCH_INIT_DATA_FOR_AD_HOC_AUDIT_FAIL';
export const SUBMIT_AD_HOC_AUDIT_FORM = '@audit/SUBMIT_SETUP_QUICK_AUDIT_FORM';
export const UPLOAD_FINISHED_AUDIT = '@audit/UPLOAD_FINISHED_AUDIT';
export const UPLOAD_FINISHED_AUDIT_SUCCESS = '@audit/UPLOAD_FINISHED_AUDIT_SUCCESS';
export const UPLOAD_FINISHED_AUDIT_FAIL = '@audit/UPLOAD_FINISHED_AUDIT_FAIL';
export const UPLOAD_FINISHED_SCHEDULED_AUDIT = '@audit/UPLOAD_FINISHED_SCHEDULED_AUDIT';
export const UPLOAD_FINISHED_SCHEDULED_AUDIT_SUCCESS = '@audit/UPLOAD_FINISHED_SCHEDULED_AUDIT_SUCCESS';
export const UPLOAD_FINISHED_SCHEDULED_AUDIT_FAIL = '@audit/UPLOAD_FINISHED_SCHEDULED_AUDIT_FAIL';
export const UPLOAD_FINISHED_SCHEDULED_AUDIT_TO_CACHE = '@audit/UPLOAD_FINISHED_SCHEDULED_AUDIT_TO_CACHE';
export const EXECUTE_SCHEDULED_AUDIT = '@audit/EXECUTE_SCHEDULED_AUDIT';
export const REVIEW_AUDIT = '@audit/REVIEW_AUDIT';
export const FINISH_REVIEW = '@audit/FINISH_REVIEW';
export const SET_EMPTY_INIT_DATA_FOR_AD_HOC_AUDIT = '@audit/SET_EMPTY_INIT_DATA_FOR_AD_HOC_AUDIT';
