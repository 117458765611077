import React, {Component} from 'react';
import {IconButton, CircularProgress} from "@material-ui/core";
import CheckListIcon from '@material-ui/icons/PlaylistAddCheck';
import Checklist from './Checklist';
import UnseenNumber from 'components/unseen/UnseenNumber';
import _ from 'lodash';

export class ChecklistIndicator extends Component {

    state = {
        isIndicator: true
    };

    loadChecklistComponent = () => {
        this.setState({isIndicator: false});
        _.get(this.props, 'onOpen') && this.props.onOpen();
    };

    render() {
        const {
            classes, numberOfChecklistItems, checklist, entityId, onOpen, isUnseen, iconColor, isLoading, refresh
        } = this.props;
        return (
            this.state.isIndicator
                ?
                <IconButton style={{padding: 2, fontSize: 12, color: iconColor}} onClick={this.loadChecklistComponent}>
                    <CheckListIcon style={{fill: iconColor}}/>
                    <UnseenNumber {...{isUnseen, number: numberOfChecklistItems, seenColor: iconColor}}/>
                </IconButton>
                :
                isLoading
                    ?
                    <CircularProgress size={18} style={{margin: 5}}/>
                    :
                    <Checklist
                        {...{
                            classes,
                            entityId,
                            checklist,
                            onOpen,
                            iconColor,
                            forceOpen: true,
                            onClose: refresh
                        }}
                    />
        );
    }
}

export default ChecklistIndicator;
