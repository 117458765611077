import React, {PureComponent} from 'react';
import {IconButton} from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";
import InfoIcon from '@material-ui/icons/InfoOutlined';
import {htmlBlack} from 'components/colors/Colors';

class ToggleAndPeakTooltip extends PureComponent {
    render() {
        const {classes, title, show, name, showTooltip, peakTooltip, toggleShowTooltip, togglePeakTooltip} = this.props;
        return (
            show &&
            <Tooltip
                placement="right-end"
                open={showTooltip === name || peakTooltip === name}
                title={title}
                classes={{
                    tooltip: classes ? classes.tooltip : ''
                }}
            >
                <IconButton
                    style={{padding: 5}}
                    onClick={toggleShowTooltip(name)}
                    onMouseEnter={togglePeakTooltip(name)}
                    onMouseLeave={togglePeakTooltip(name)}>
                    <InfoIcon style={{color: showTooltip === name ? htmlBlack : '', fontSize: 21}}/>
                </IconButton>
            </Tooltip>
        );
    }
}

export default ToggleAndPeakTooltip;
