import React, {Component, PureComponent} from 'react';
import {Button, Divider, withStyles} from "@material-ui/core";
import {
    ACTIVE_VALUE,
    ALL_VALUE,
    apiTaskForListing,
    BACKLOG_VALUE,
    HISTORY_VALUE,
    MY_ACTIONS_VALUE,
} from 'domain/task/Tasks.model';
import Flex from 'components/grid/Flex';
import {Link, Redirect, withRouter} from 'react-router-dom';
import {prevPath, routeTo, TASK_ADMINISTRATION, TASK_BACKLOG, TASK_CREATE, TASK_EDIT} from 'routes/routes';
import {connect} from 'react-redux';
import connector from './Backlog.connect';
import {Trans, withTranslation} from "react-i18next";
import {DragDropContext, Draggable, Droppable} from 'react-beautiful-dnd';
import update from 'immutability-helper';
import SortableTask from './components/SortableTask';
import BacklogFilters from './components/BacklogFilters';
import {htmlWhite, logoBlueDark} from 'components/colors/Colors';
import _ from 'lodash';
import {parse} from 'query-string';
import ConfirmationDialog from "components/confirmation-dialog/ConfirmationDialog";
import {emptyTaskForQuickCreate} from 'scenes/tasks/task-edit/TaskEdit.reducer';
import QuickIssueInput from 'scenes/tasks/components/QuickIssueInputField';
import ProjectTree from 'scenes/tasks/components/project-tree/ProjectTree';
import ProjectAuthorizationErrorLabel from "scenes/tasks/components/ProjectAuthorizationErrorLabel";
import ProjectNotLoadedLabel from "scenes/tasks/components/ProjectNotLoadedLabel";
import configs from "configs/Configs";
import MyActionList from 'scenes/tasks/actions/my-action-list/MyActionList';
import GreySwitch from 'components/switch/GreySwitch';
import {NEW} from 'components/quill-editor/EditorUtils';
import TaskUpdateEventBus from 'scenes/tasks/TaskUpdateEventBus';
import {CREATE_TASK, CREATE_TASK_TO_CACHE} from 'scenes/tasks/task-edit/TaskEdit.action.types';
import H4Tabs from 'components/h4-tabs/H4Tabs';
import H4Tab from 'components/h4-tabs/H4Tab';
import {haveMatchingStatus} from 'scenes/tasks/Task.utils';
import ids from 'components/ids/ids';
import utils from 'utils/Utils';
import PageIsLoading from 'components/page-is-loading-progress/PageIsLoading';
import {treeIndex} from 'components/zindex/zIndex'

export const styles = theme => ({
    rightAlignedButton: {
        display: 'flex',
        flex: '1 0 auto',
        flexDirection: 'row-reverse'
    },
    footerButtonContainer: {
        marginTop: 8,
    },
    controlButtons: {
        minWidth: 150,
    },
    buttonWithMargin: {
        minWidth: 150,
        marginLeft: 10
    },
    absoluteContainer: {
        overflow: 'auto',
        overflowScrolling: 'touch',
        position: 'absolute',
        bottom: 0,
        right: 0,
        left: 0,
        top: 0,
        webkitUserSelect: 'none', /* Safari */
        msUserSelect: 'none', /* IE 10+ and Edge */
        userSelect: 'none', /* Standard syntax */
    },
    titleBarItem: {
        lineHeight: 1,
    },
    workspace: {
        marginLeft: 30,
        marginRight: 20,
        marginBottom: 10,
        marginTop: 10,
        zIndex: treeIndex + 1,
    }
});

const ListTabs = (props) => {
    const {view, handleTabChange, translate, switchTabToMyActions} = props;
    return (

        <Flex container item={'0 0 0'} justifyContent={'space-between'} alignItems={'center'}
              style={{paddingBottom: 10}}>
            <H4Tabs value={view} onChange={handleTabChange}>
                <H4Tab label={translate('global.active')} value={ACTIVE_VALUE} id={ids.taskTabKanban}/>
                <H4Tab label={translate('global.backlog')} value={BACKLOG_VALUE} id={ids.taskTabBacklog}/>
                <H4Tab label={translate('global.history')} value={HISTORY_VALUE} id={ids.taskTabHistory}/>
            </H4Tabs>
            <Flex item container justifyContent={'flex-end'} alignItems={'center'}>
                <span style={{color: logoBlueDark, fontWeight: 'bold'}}><Trans i18nKey="global.tasks"/></span>
                <GreySwitch
                    checked={false}
                    onChange={switchTabToMyActions}
                    id={ids.taskSwitchMyTasks}
                />
                <Trans i18nKey="global.my-actions"/>
            </Flex>
        </Flex>

    )
};

const FootControlButtons = (props) => {
    const {
        classes, selectedTasks, openDeleteTaskDialog, cloneTask, location, user, moveTask, view,
        todoTaskBusinessId, taskTitle, isTaskEditable, isApplicationOnline
    } = props;
    const todoTaskSelected = selectedTasks.some(task => task.businessId === todoTaskBusinessId);
    const firstSelectedTaskId = (selectedTasks[0] && selectedTasks[0].businessId) || "";
    const multipleTasksSelected = selectedTasks.length && selectedTasks.length > 1;
    const isDeviation = _.get(selectedTasks[0], 'type') === 'DEVIATION';
    return (
        <Flex container item={'0 0 50px'} alignItems={'center'} justifyContent={'space-between'}
              style={{backgroundColor: htmlWhite}} className={classes.footerButtonContainer}>
            <Button variant="contained"
                    color="primary"
                    id={ids.taskBacklogDeleteButton}
                    onClick={openDeleteTaskDialog}
                    disabled={
                        !user.isAdmin || _.isEmpty(selectedTasks) || !isTaskEditable || todoTaskSelected || !_.isEmpty(taskTitle)
                    }
                    className={classes.controlButtons}
            >
                <Trans i18nKey="global.delete"/>
            </Button>
            <Button variant="contained"
                    color="primary"
                    onClick={moveTask}
                    disabled={_.isEmpty(selectedTasks) || !_.isEmpty(taskTitle) || todoTaskSelected}
                    className={classes.buttonWithMargin}
                    style={{marginLeft: 10}}
            >
                {
                    view === ACTIVE_VALUE &&
                    <Trans i18nKey="task-management.remove-from-kanban"/>
                }
                {
                    view === BACKLOG_VALUE &&
                    <Trans i18nKey="task-management.add-to-kanban"/>
                }
                {
                    view === HISTORY_VALUE &&
                    <Trans i18nKey="task-management.reOpen"/>
                }
            </Button>
            {
                isApplicationOnline &&
                <Button variant="contained"
                        color="primary"
                        id={ids.taskBacklogCloneButton}
                        onClick={cloneTask}
                        disabled={
                            !user.isAdmin || _.isEmpty(selectedTasks) || !isTaskEditable || todoTaskSelected || !_.isEmpty(taskTitle) || multipleTasksSelected || isDeviation
                        }
                        className={classes.buttonWithMargin}
                >
                    <Trans i18nKey="global.clone"/>
                </Button>
            }
            <Flex item={'1 0 auto'} container justifyContent={'flex-end'}>
                <Button variant="contained"
                        color="primary"
                        component={Link}
                        id={ids.taskBacklogOpenButton}
                        to={{
                            pathname: TASK_EDIT.pathWithId(firstSelectedTaskId),
                            state: {prevPath: prevPath({location})}
                        }}
                        disabled={_.isEmpty(selectedTasks) || !_.isEmpty(taskTitle) || selectedTasks.length > 1}
                        className={classes.controlButtons}
                >
                    <Trans i18nKey="global.open"/>
                </Button>
            </Flex>
        </Flex>
    );
};

export class SortableList extends PureComponent {

    render() {
        const {
            classes, smallTaskId, handleDoubleClickOnTask, toggleTaskSelection, bottom, view,
            user, applicationOnline, removeUnseenAttachmentTag, fetchTask, removeUnseenActionTag, removeUnseenCommentTag,
            removeUnseenChecklistTag, setSelectedRef, handleTagAddition, handleTagDelete, selectedTags,
            toggleTagSelection, selectedTasks, filteredTasks, isTaskLoadingReady
        } = this.props;
        const tasks = filteredTasks;
        return (
            isTaskLoadingReady ?
                <Flex container item direction={'column'} style={{position: 'relative'}}>
                    <Droppable droppableId={'sortableTaskList'}>
                        {(provided) => (
                            <div
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                                className={classes.absoluteContainer}
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    flex: '1 1 auto',
                                }}
                            >
                                {tasks.map((task, index) => {
                                    return (
                                        <Draggable draggableId={task.businessId}
                                                   index={index}
                                                   canDragInteractiveElements={true}
                                                   key={task.businessId}>
                                            {(provided, snapshot) => (
                                                <div
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    style={{
                                                        ...provided.draggableProps.style,
                                                        outline: 'none',
                                                        maxWidth: snapshot.isDragging ? 100 : 'none'
                                                    }}
                                                >

                                                    {
                                                        !snapshot.isDragging &&
                                                        <Divider style={{marginLeft: 5, marginRight: 5}}/>
                                                    }

                                                    <SortableTask {...{
                                                        id: index === 0 ? ids.firstListItemOnPage : undefined,
                                                        task,
                                                        user,
                                                        isSelected: selectedTasks.some(selectedTask => selectedTask.businessId === task.businessId),
                                                        view,
                                                        applicationOnline,
                                                        handleDoubleClickOnTask,
                                                        removeUnseenCommentTag,
                                                        toggleTaskSelection,
                                                        removeUnseenAttachmentTag,
                                                        removeUnseenActionTag,
                                                        removeUnseenChecklistTag,
                                                        fetchTask,
                                                        handleTagAddition,
                                                        handleTagDelete,
                                                        selectedTags,
                                                        toggleTagSelection,
                                                        tasks,
                                                        shrink: smallTaskId === task.businessId,
                                                        dragHandleProps: provided.dragHandleProps
                                                    }}
                                                    />


                                                    <div id={'selected-task-for-scroll'}
                                                         ref={!_.isEmpty(selectedTasks) && selectedTasks[0].businessId === task.businessId ? setSelectedRef : null}/>
                                                </div>
                                            )}
                                        </Draggable>
                                    )
                                })}
                                {provided.placeholder}
                                <div id={'bottom-task-for-scroll'} ref={bottom}/>
                                <Divider style={{marginLeft: 5, marginRight: 5}}/>
                            </div>
                        )}
                    </Droppable>
                </Flex>
                :
                <PageIsLoading/>
        );
    }
}

export class Backlog extends Component {

    bottom = React.createRef();
    selectedRef = null;
    quickTaskInput = React.createRef();

    constructor(props) {
        super(props);
        let params = parse(props.location.search);
        this.state = {
            ...this.mapFilterParamsToState(params),
            deleteTaskDialogOpen: false,
            taskTitle: '',
            tagFilterRelation: 'OR',
            selectedTasks: [],
            filteredTasks: [],
            smallTaskId: ''
        };
    }

    keyHandler = event => {
        if (event.defaultPrevented) {
            return; // Do nothing if the event was already processed
        }
        if (event.shiftKey) {
            switch (event.key) {
                case 'Down':
                case 'ArrowDown':
                    this.nextSelect();
                    break;
                case 'Up':
                case 'ArrowUp':
                    this.previousSelect();
                    break;
                default:
                    return;
            }
        }
    };

    componentDidMount() {
        this.setState({filteredTasks: this.getFilteredTasks()});
        if (!_.isEmpty(this.props.selectedTask)) {
            this.scrollToSelectedRef();
        }
        if (this.state.view === HISTORY_VALUE && this.props.applicationOnline) {
            this.props.listHistoryTasksFromServer();
        }
        this.updateSubscription = TaskUpdateEventBus.update.subscribe(payload => {
            if (payload.type === CREATE_TASK || payload.type === CREATE_TASK_TO_CACHE) {
                this.scrollToBottom();
            }
        });
        window.addEventListener("keydown", this.keyHandler);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.selectedProject.businessId !== this.props.selectedProject.businessId) {
            this.clearTaskSelection();
            if (this.state.view === HISTORY_VALUE && this.props.applicationOnline) {
                this.props.listHistoryTasksFromServer();
                this.props.listProjectTasks();
            } else {
                this.props.listProjectTasks();
            }
        }
        if (
            prevProps.selectedUserNames !== this.props.selectedUserNames ||
            prevProps.historyTasks !== this.props.historyTasks ||
            prevProps.activeTasks !== this.props.activeTasks ||
            prevProps.backlogTasks !== this.props.backlogTasks ||
            prevState.searchText !== this.state.searchText ||
            prevState.selectedTags !== this.state.selectedTags ||
            prevState.tagFilterRelation !== this.state.tagFilterRelation ||
            prevState.view !== this.state.view
        ) {
            this.setState({filteredTasks: this.getFilteredTasks()});
        }
    }

    componentWillUnmount() {
        TaskUpdateEventBus.update.unSubscribe(this.updateSubscription);
        window.removeEventListener("keydown", this.keyHandler);
    }

    mapFilterParamsToState = (params) => {
        const {selectedTags, tagFilterRelation, view, searchText, organizationLevelId} = params;
        this.props.changeFilter({view: view ? view : ACTIVE_VALUE});
        if (organizationLevelId) {
            this.props.toggleProjectFilterSelectionByOrganizationLevelId(organizationLevelId);
        }
        const selectedTagsInArray = selectedTags ? selectedTags.split(',').map(tag => ({name: tag})) : [];
        return {
            selectedTags: selectedTagsInArray,
            view: view ? view : ACTIVE_VALUE,
            searchText: searchText ? searchText : '',
            tagFilterRelation: tagFilterRelation || ''
        };
    };

    updateUrlWithNewFilters = () => {
        this.props.changeFilter({view: this.state.view});
        routeTo(TASK_BACKLOG.pathWithFilterParams({
            selectedTags: this.state.selectedTags.map(tag => tag.name),
            tagFilterRelation: _.isEmpty(this.state.selectedTags) ? '' : this.state.tagFilterRelation,
            view: this.state.view,
            searchText: this.state.searchText
        }), this.props);
    };

    setSelectedRef = element => {
        this.selectedRef = element;
    };

    scrollToSelectedRef = () => {
        this.selectedRef && this.selectedRef.scrollIntoView({
            block: "end",
            inline: "nearest",
            behavior: "auto"
        });
    };

    deleteSelectedTask = () => {
        this.props.archiveTasks(this.state.selectedTasks).then(() => {
            this.closeDeleteTaskDialog();
            this.updateUrlWithNewFilters();
            this.setState({selectedTasks: []})
        });
    };

    openDeleteTaskDialog = () => {
        this.setState({deleteTaskDialogOpen: true});
    };

    closeDeleteTaskDialog = () => {
        this.setState({deleteTaskDialogOpen: false});
    };

    handleChange = event => {
        this.setState({
            [event.target.name]: event.target.value,
        });
    };

    handleNamedStateChange = event => {
        this.setState({[event.target.name]: event.target.value}, this.updateUrlWithNewFilters);
    };

    resetSearchText = event => {
        this.setState({searchText: ''}, this.updateUrlWithNewFilters);
    };

    handleTabChange = (event, value) => {
        this.clearTaskSelection();
        if (value === HISTORY_VALUE && this.props.applicationOnline) {
            this.props.listHistoryTasksFromServer();
        }
        this.setState({
            view: value,
        }, this.updateUrlWithNewFilters);
    };

    switchTabToMyActions = () => {
        this.clearTaskSelection();
        this.setState({
            view: MY_ACTIONS_VALUE,
        }, this.updateUrlWithNewFilters)
    };

    switchTabToStories = () => {
        this.props.loadPreviousProjectSelection();
        this.setState({
            view: ACTIVE_VALUE,
        }, this.updateUrlWithNewFilters)
    };

    handleKanbanChange = () => {
        const selectedTasks = this.state.selectedTasks;
        if (haveMatchingStatus(this.props.activeWorkflowStatuses, selectedTasks[0])) {
            this.props.removeTasksFromKanbanBoard(selectedTasks);
        } else {
            this.props.moveTasksToKanbanBoard(selectedTasks);
        }
    };

    userFilter = task => {
        return this.props.selectedUserNames.length === 0 || this.props.selectedUserNames.includes(_.get(task, 'assignee.username'));
    };

    selectedProjectFilter = task => {
        return this.props.selectedProject.businessId === task.projectBusinessId
    }

    viewFilter = task => {
        switch (this.state.view) {
            case ALL_VALUE:
                return true;
            case ACTIVE_VALUE:
                return haveMatchingStatus(this.props.activeWorkflowStatuses, task);
            case BACKLOG_VALUE:
                return haveMatchingStatus(this.props.backlogWorkflowStatuses, task);
            case HISTORY_VALUE:
                return haveMatchingStatus(this.props.historyWorkflowStatuses, task);
            default:
                return false;
        }
    };

    freeTextFilter = task => {
        const matchingTranslation = task.translations
            .find(translation => translation.language.code === this.props.i18n.language) || task.translations[0];
        const taskPrefix = configs.getTaskPrefix().toLowerCase();
        return task.businessId.includes(this.state.searchText)
            || `${taskPrefix}-${task.businessId}`.includes(this.state.searchText.toLowerCase())
            || `dev-${task.businessId}`.includes(this.state.searchText.toLowerCase())
            || (matchingTranslation.title && matchingTranslation.title.toLowerCase().includes(this.state.searchText.toLowerCase()));
    };

    tagFilter = task => {
        if (this.state.tagFilterRelation === 'AND') {
            return this.state.selectedTags.length === 0
                || _.every(this.state.selectedTags, tag => _.some(task.freeTags, ['name', tag.name]));
        } else {
            return this.state.selectedTags.length === 0
                || _.some(this.state.selectedTags, tag => _.some(task.freeTags, ['name', tag.name]));
        }
    };

    projectMemberFilter = task => {
        return _.some(this.props.selectedProject.members, ['user.businessId', this.props.user.businessId]);
    };

    handleDragEnd = tasks => result => {
        this.setState({smallTaskId: ''});
        const projectBusinessId = _.get(result, 'destination.droppableId', '').includes('treeNode-')
            ? _.get(result, 'destination.droppableId', '').replace('treeNode-', '')
            : '';
        if (result.destination && projectBusinessId) {
            const task = tasks.find(taskInArray => taskInArray.businessId === result.draggableId);
            if (task) {
                this.props.editTask({...task, projectBusinessId});
            }
        } else if (result.destination) {
            const draggedTaskId = result.draggableId;
            const task = tasks[_.get(result, 'destination.index')];
            if (task) {
                const destinationTaskId = task && task.businessId;
                this.props.sortTasks(draggedTaskId, destinationTaskId);
            }
        }
    };

    handleDoubleClickOnTask = taskBusinessId => event => {
        routeTo(TASK_EDIT.pathWithId(taskBusinessId), this.props);
    };

    scrollToBottom = () => {
        this.bottom.current && this.bottom.current.scrollIntoView({
            block: "end",
            inline: "nearest",
            behavior: "auto"
        });
    };

    createQuickTask = () => {
        const freeTags = _.cloneDeep(this.state.selectedTags);

        let task = {
            ...emptyTaskForQuickCreate(this.props.user,
                this.props.configurationFromBackend.supportedLanguages,
                this.props.configurationFromBackend.deadlines,
                this.state.view === ACTIVE_VALUE
                    ? this.props.startingWorkflowStatus
                    : this.props.backlogWorkflowStatus),
            freeTags
        };
        const matchingTranslation = task.translations.find(translation => translation.language.code === this.props.i18n.language);
        matchingTranslation.title = this.state.taskTitle;
        matchingTranslation.origin = NEW;
        this.setState({
            taskTitle: ''
        });
        if (matchingTranslation.title !== '') {
            this.props.createTask(task).then(response => {
                this.scrollToBottom();
                if (response && (response.data || response.payload)) {
                    const createdTask = apiTaskForListing(
                        _.get(response, 'payload.data.data.createTask')
                        || _.get(response, 'data.task')
                    );
                    this.toggleTaskSelection([], createdTask)({});
                }
            });
        }
    };

    cloneTask = () => {
        const selectedTasks = this.state.selectedTasks;
        if (selectedTasks && selectedTasks.length === 1) {
            this.props.cloneTask(selectedTasks[0]).then(response => {
                if (response && response.payload) {
                    routeTo(TASK_EDIT.pathWithId(response.payload.data.data.cloneTask.businessId), this.props);
                }
            });
        }
    };

    resetSelectedTags = event => {
        this.setState({selectedTags: []}, this.updateUrlWithNewFilters);
    };

    toggleTagSelection = tag => {
        const index = _.findIndex(this.state.selectedTags, selectTag => selectTag.name === tag.name);
        const selected = index >= 0;
        this.setState(oldState => {
            return {
                selectedTags: selected
                    ?
                    update(oldState.selectedTags, {$splice: [[index, 1]]})
                    :
                    update(oldState.selectedTags || [], {$push: [tag]})
            };
        }, this.updateUrlWithNewFilters)
    };

    moveTask = () => {
        switch (this.state.view) {
            case ACTIVE_VALUE:
            case BACKLOG_VALUE:
            case HISTORY_VALUE:
                this.handleKanbanChange();
                break;
            default:
                return;
        }
        this.clearTaskSelection();
    };

    removeUnseenActionTag = task => () => {
        if (task.hasUnseenAction) {
            this.props.removeUnseenActionTag(task);
        }
    };

    removeUnseenAttachmentTag = task => () => {
        if (task.hasUnseenAttachment) {
            this.props.removeUnseenAttachmentTag(task);
        }
    };

    removeUnseenCommentTag = task => () => {
        if (task.hasUnseenComment) {
            this.props.removeUnseenCommentTag(task);
        }
    };

    removeUnseenChecklistTag = task => () => {
        if (task.hasUnseenCriteria) {
            this.props.removeUnseenChecklistTag(task);
        }
    };

    getFilteredTasks = () => {
        switch (this.state.view) {
            case ACTIVE_VALUE:
                return this.props.activeTasks
                    .filter(this.userFilter)
                    .filter(this.selectedProjectFilter)
                    .filter(this.freeTextFilter)
                    .filter(this.projectMemberFilter)
                    .filter(this.tagFilter);
            case BACKLOG_VALUE:
                return this.props.backlogTasks
                    .filter(this.userFilter)
                    .filter(this.selectedProjectFilter)
                    .filter(this.freeTextFilter)
                    .filter(this.projectMemberFilter)
                    .filter(this.tagFilter);
            case HISTORY_VALUE:
                console.log('this.props.historyTasks', this.props.historyTasks);
                return this.props.historyTasks
                    .filter(this.userFilter)
                    .filter(this.selectedProjectFilter)
                    .filter(this.freeTextFilter)
                    .filter(this.projectMemberFilter)
                    .filter(this.tagFilter);
            default:
                return [];
        }
    };

    toggleTaskSelection = (tasks, selectedTask) => event => {
        if (event.shiftKey && this.state.selectedTasks.length) {
            this.rangeSelect(tasks, selectedTask);

        } else if (event.ctrlKey || event.metaKey) {
            this.multiSelect(selectedTask);
        } else {
            this.singleSelect(selectedTask);
        }
    };

    rangeSelect(tasks, selectedTask) {
        const alreadySelectedTasks = this.state.selectedTasks;
        const lastSelectedIndex = tasks.findIndex(task => task.businessId === alreadySelectedTasks[alreadySelectedTasks.length - 1].businessId);
        const currentSelectedIndex = tasks.findIndex(task => task.businessId === selectedTask.businessId);

        if (currentSelectedIndex < lastSelectedIndex) {
            this.setState({
                selectedTasks: Backlog.mergeSelections(alreadySelectedTasks, tasks.slice(currentSelectedIndex, lastSelectedIndex + 1))
            })
        } else {
            this.setState({
                selectedTasks: Backlog.mergeSelections(alreadySelectedTasks, tasks.slice(lastSelectedIndex, currentSelectedIndex + 1))
            })
        }
    }

    multiSelect(selectedTask) {
        const indexOfSelectedTask = this.state.selectedTasks.findIndex(task => task.businessId === selectedTask.businessId);
        const alreadySelected = indexOfSelectedTask >= 0;

        this.setState(oldState => {
            return {
                selectedTasks: alreadySelected
                    ?
                    update(oldState.selectedTasks, {$splice: [[indexOfSelectedTask, 1]]})
                    :
                    update(oldState.selectedTasks || [], {$push: [selectedTask]})
            };
        })
    }

    nextSelect = () => {
        const alreadySelectedTasks = this.state.selectedTasks;
        if (alreadySelectedTasks.length) {
            const filteredTasks = this.state.filteredTasks;
            const lastSelectedIndex = filteredTasks.findIndex(task => task.businessId === alreadySelectedTasks[alreadySelectedTasks.length - 1].businessId);

            const isMoreItemAfterSelectedTask = filteredTasks.length - 1 > lastSelectedIndex;
            if (lastSelectedIndex > -1 && isMoreItemAfterSelectedTask) {
                const indexOfNextIfAlreadySelected = alreadySelectedTasks.findIndex(task => task.businessId === filteredTasks[lastSelectedIndex + 1].businessId);

                this.setState(oldState => {
                    return {
                        selectedTasks: indexOfNextIfAlreadySelected > -1
                            ?
                            update(oldState.selectedTasks, {$splice: [[alreadySelectedTasks.length - 1, 1]]})
                            :
                            update(oldState.selectedTasks || [], {$push: [filteredTasks[lastSelectedIndex + 1]]})
                    };
                })
            }
        }

    };

    previousSelect = () => {
        const alreadySelectedTasks = this.state.selectedTasks;
        if (alreadySelectedTasks.length) {
            const filteredTasks = this.state.filteredTasks;
            const lastSelectedIndex = filteredTasks.findIndex(task => task.businessId === alreadySelectedTasks[alreadySelectedTasks.length - 1].businessId);

            const isMoreItemBeforeSelectedTask = lastSelectedIndex > 0;
            if (isMoreItemBeforeSelectedTask) {
                const indexOfPreviousIfAlreadySelected = alreadySelectedTasks.findIndex(task => task.businessId === filteredTasks[lastSelectedIndex - 1].businessId);

                this.setState(oldState => {
                    return {
                        selectedTasks: indexOfPreviousIfAlreadySelected > -1
                            ?
                            update(oldState.selectedTasks, {$splice: [[alreadySelectedTasks.length - 1, 1]]})
                            :
                            update(oldState.selectedTasks || [], {$push: [filteredTasks[lastSelectedIndex - 1]]})
                    };
                })
            }
        }
    };

    singleSelect(selectedTask) {
        const alreadySelected = this.state.selectedTasks.find(element => element.businessId === selectedTask.businessId);
        this.setState({
            selectedTasks: alreadySelected ? [] : [selectedTask]
        })
    }

    static mergeSelections(existingSelection, newSelection) {
        return _.unionWith(existingSelection, newSelection, (first, second) => first.businessId === second.businessId);
    }

    clearTaskSelection = () => {
        this.setState({selectedTasks: []})
    };

    render() {
        const {
            classes, user, t: translate, location, tagSuggestions, applicationOnline, isTaskLoadingReady,
            selectedProject, todoTaskBusinessId, fetchTask, startingWorkflowStatus, backlogWorkflowStatus,
            isProjectLoaded, forceRerender
        } = this.props;
        const {
            status, type, searchText, view, deleteTaskDialogOpen, taskTitle, selectedTags, tagFilterRelation,
            selectedTasks, filteredTasks, smallTaskId
        } = this.state;
        const {
            handleNamedStateChange, handleDragEnd, handleDoubleClickOnTask, toggleTaskSelection, createQuickTask,
            quickTaskInput, bottom, scrollToBottom, openDeleteTaskDialog, closeDeleteTaskDialog, cloneTask, moveTask,
            deleteSelectedTask, handleTabChange, removeUnseenAttachmentTag,
            removeUnseenActionTag, removeUnseenCommentTag, removeUnseenChecklistTag, setSelectedRef,
            switchTabToMyActions, switchTabToStories, handleChange, getFilteredTasks, resetSelectedTags, resetSearchText,
            toggleTagSelection
        } = this;
        const filterState = {status, type, searchText, view};
        const tasksHash = this.getFilteredTasks().map(task => JSON.stringify(task)).toString();
        const isMobile = utils.checkBrowser();
        return (
            isMobile
                ?
                <Redirect to={TASK_ADMINISTRATION.path + this.props.location.search}/>
                :
                view === MY_ACTIONS_VALUE
                    ?
                    <MyActionList {...{view, handleTabChange, switchTabToStories}}/>
                    :
                    <>
                        <DragDropContext onDragEnd={handleDragEnd(filteredTasks)}
                                         onBeforeCapture={response => {
                                             this.setState({
                                                 smallTaskId: response.draggableId
                                             })
                                         }}>
                            <ProjectTree isDroppable={true}/>
                            {
                                selectedProject.currentUser.hasAccess ?
                                    isProjectLoaded ?
                                        <Flex item container direction={'column'} className={classes.workspace}>

                                            <ListTabs {...{view, translate, handleTabChange, switchTabToMyActions}}/>
                                            <BacklogFilters
                                                {...{
                                                    filterState,
                                                    tagSuggestions,
                                                    selectedTags,
                                                    handleNamedStateChange,
                                                    tagFilterRelation,
                                                    resetSelectedTags,
                                                    resetSearchText,
                                                    toggleTagSelection
                                                }}
                                            />
                                            <SortableList
                                                {...{
                                                    classes,
                                                    smallTaskId,
                                                    forceRerender: tasksHash,
                                                    user,
                                                    handleDragEnd,
                                                    handleDoubleClickOnTask,
                                                    toggleTaskSelection,
                                                    bottom,
                                                    quickTaskInput,
                                                    createQuickTask,
                                                    location,
                                                    view,
                                                    applicationOnline,
                                                    removeUnseenAttachmentTag,
                                                    removeUnseenActionTag,
                                                    removeUnseenCommentTag,
                                                    removeUnseenChecklistTag,
                                                    setSelectedRef,
                                                    fetchTask,
                                                    getFilteredTasks,
                                                    selectedTags,
                                                    toggleTagSelection,
                                                    selectedTasks,
                                                    filteredTasks,
                                                    isTaskLoadingReady
                                                }}
                                            />

                                            {
                                                selectedProject.currentUser.hasEditRight && view !== HISTORY_VALUE &&
                                                <QuickIssueInput {...{
                                                    user,
                                                    location,
                                                    name: 'taskTitle',
                                                    issueTitle: taskTitle,
                                                    workflowStatus: view === ACTIVE_VALUE
                                                        ? startingWorkflowStatus
                                                        : backlogWorkflowStatus,
                                                    onChange: handleChange,
                                                    onEnter: createQuickTask,
                                                    reference: quickTaskInput,
                                                    placeHolder: translate('task-management.task-list.addNew'),
                                                    pathname: TASK_CREATE.path
                                                }}/>
                                            }
                                            <FootControlButtons {...{
                                                classes,
                                                selectedTasks,
                                                scrollToBottom,
                                                location,
                                                openDeleteTaskDialog,
                                                cloneTask,
                                                user,
                                                moveTask,
                                                view,
                                                todoTaskBusinessId,
                                                taskTitle,
                                                isTaskEditable: selectedProject.currentUser.hasEditRight,
                                                isApplicationOnline: applicationOnline
                                            }}/>
                                            <ConfirmationDialog {...{
                                                dialogOpen: deleteTaskDialogOpen,
                                                onDialogClose: closeDeleteTaskDialog,
                                                onConfirm: deleteSelectedTask,
                                                confirmationTextKey: "task-management.deleteTaskConfirmation"
                                            }}/>
                                        </Flex>
                                        :
                                        <ProjectNotLoadedLabel/>
                                    :
                                    <ProjectAuthorizationErrorLabel/>
                            }
                        </DragDropContext>
                    </>

        );
    }
}

export default withStyles(styles)(connect(connector.mapStateToProps, connector.mapDispatchToProps)(withTranslation()(withRouter(Backlog))));
