import React, {Component} from "react";
import {Button, Dialog, DialogActions, withStyles} from "@material-ui/core";
import {Trans, withTranslation} from "react-i18next";
import DialogContent from "@material-ui/core/DialogContent";
import Flex from "components/grid/Flex";
import Typography from "@material-ui/core/Typography";
import {jaffaOrange, grey, warningButton, warningButtonOnHover} from 'components/colors/Colors';
import {WarningRounded as WarningIcon} from "@material-ui/icons";

export const styles = theme => ({
    icon: {
        color: jaffaOrange,
        fontSize: '3rem',
        marginRight: 15,
    },
    header: {
        color: jaffaOrange,
        fontSize: 18
    },
    text: {
        marginTop: 20
    },
    child: {
        fontSize: 14,
        color: grey,
        margin: 1
    },
    childrenOuterContainer: {
        marginTop: 10,
        minHeight: 100,
        overflow: 'hidden',
        maxHeight: 400
    },
    childrenInnerContainer: {
        overflow: 'auto',
        overflowScrolling: 'touch',
    },
    button: {
        backgroundColor: warningButton,
        '&:hover': {
            backgroundColor: warningButtonOnHover,
        },
    }
});

class ConfirmationDialogWarning extends Component {

    render() {
        const {
            dialogOpen, onDialogClose, onConfirm, confirmationHeaderKey, confirmationHeaderValues, confirmationTextKey,
            confirmationTexValues, confirmationQuestionKey, confirmationQuestionValues, children, classes,
            confirmI18NKey, cancelI18NKey, titleStyles, confirmationDisabled
        } = this.props;
        return (
            <Dialog
                open={dialogOpen}
            >
                <DialogContent>
                    <Flex container item grow={1} shrink={0} alignItems={'flex-end'}>
                        <WarningIcon className={classes.icon}/>
                        <Typography className={classes.header} style={titleStyles}>
                            <Trans i18nKey={confirmationHeaderKey} values={confirmationHeaderValues}/>
                        </Typography>
                    </Flex>
                    <Typography className={classes.text} style={titleStyles}>
                        <Trans i18nKey={confirmationTextKey} values={confirmationTexValues}/>
                    </Typography>
                    <Flex container className={classes.childrenOuterContainer}>
                        <Flex container direction={'column'} className={classes.childrenInnerContainer}>
                            {
                                children &&
                                children.map((child, index) => {
                                    return (
                                        <div key={index} className={classes.child}>{child.name}</div>
                                    )
                                })
                            }
                        </Flex>
                    </Flex>
                    <Typography className={classes.text} style={titleStyles}>
                        <Trans i18nKey={confirmationQuestionKey} values={confirmationQuestionValues}/>
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => onConfirm()}
                            variant="contained"
                            autoFocus
                            color="primary"
                            disabled={confirmationDisabled}
                            classes={{
                                root: classes.button
                            }}
                    >
                        <Trans i18nKey={confirmI18NKey || "global.yes-continue"}/>
                    </Button>
                    <Button onClick={() => onDialogClose()}
                            color="primary"
                            variant="contained">
                        <Trans i18nKey={cancelI18NKey || "global.no-cancel"}/>
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

export default withStyles(styles)(withTranslation()(ConfirmationDialogWarning));
