import {fileDatabase} from 'components/file/File.database'

export function cacheFile(recordId, file, referenceId, referenceType) {
    return dispatch => {
        const recordDetails = {
            referenceId: referenceId,
            referenceType: referenceType,
            file: file
        };
        return fileDatabase.files.put({id: recordId, details: recordDetails})
            .catch(error => {
                console.log('Could not write to the local cache. Error: ', error);
            });
    };
}

export function getFile(recordId) {
    return fileDatabase.files.get(recordId)
        .then(record => {
            return record.details;
        })
        .catch(error => {
            console.log('Could not read from the local cache. Error: ', error);
        });
}

export function deleteFile(recordId) {
    return dispatch => {
        return fileDatabase.files.delete(recordId)
            .catch(error => {
                console.log('Could not delete from the local cache. Error: ', error);
            });
    }
}

const fileCache = {
    cacheFile,
    getFile,
    deleteFile
};

export default fileCache;
