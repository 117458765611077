import {cacheAndLoad} from "cache/Cache.utils";
import _ from "lodash";
import {taskDatabase} from 'scenes/tasks/Tasks.database'
import {updateOrPushByBusinessId} from 'utils/Utils';

export function cacheOrServeTasks(originalReduxCall, cacheReduxCall) {
    return (dispatch, getState) => {
        const selectedProjectBusinessId = getState().projectReducer.selectedProject.businessId;
        return dispatch(originalReduxCall())
            .then(updateTaskCache)
            .catch(() => {
                if (cacheReduxCall) {
                    provideTasksFromCache(cacheReduxCall, dispatch, selectedProjectBusinessId);
                }
            });
    };
}

export function updateTaskCache(response) {
    const dataWrapper = _.get(response, 'payload.data.data');
    const actualPayload = dataWrapper[Object.keys(dataWrapper)[0]];
    const results = [];
    actualPayload.forEach(tasksByProject => {
        results.push(updateTasks(tasksByProject.projectBusinessId, tasksByProject.tasks))
    });
    return Promise.all(results);
}

export function updateOrCreateActionInCache(action) {
    return taskDatabase.tasks.get(action.projectBusinessId)
        .then(record => {
            const indexOfTaskToUpdate = record.response.findIndex(backupTask => backupTask.businessId === action.taskBusinessId);
            if (indexOfTaskToUpdate !== -1) {
                updateOrPushByBusinessId(record.response[indexOfTaskToUpdate].actions, action);
            } else {
                console.log('taskBusinessId: ', action.taskBusinessId, 'does not exists in backup db, this is a no op');
            }
            return updateTasks(action.projectBusinessId, record.response);
        })
        .catch(error => {
            console.log(`Cannot get tasks. Error:`, error);
        });
}

export function updateOrCreateTaskInCache(task) {
    return taskDatabase.tasks.get(task.projectBusinessId)
        .then(record => {
            updateOrPushByBusinessId(record.response, task);
            return updateTasks(task.projectBusinessId, record.response);
        })
        .catch(error => {
            console.log(`Cannot get tasks. Error:`, error);
        });
}

function updateTasks(projectBusinessId, tasks) {
    return taskDatabase.tasks.put({id: projectBusinessId, response: tasks})
        .then(() => {
            console.log('tasks', ' [', taskDatabase.name, '][', taskDatabase.verno, '] project [', projectBusinessId, '] cache updated');
        })
        .catch(error => {
            console.log('Could not write to the local cache. Error: ', error);
        });
}

export function provideTasksFromCache(cacheReduxCall, dispatch, selectedProjectBusinessId) {
    taskDatabase.tasks.get(selectedProjectBusinessId)
        .then(backupTasks => {
            taskDatabase.editedTasks
                .toCollection()
                .toArray()
                .then(editedTasks => {
                    const editedTasksOnProject = editedTasks.filter(editedTask => editedTask.projectBusinessId === selectedProjectBusinessId);
                    dispatch(cacheReduxCall(mergeBackupTasksWithEditedTasks(_.get(backupTasks, "response"), editedTasksOnProject)));
                });
        })
        .catch(error => {
            console.log('Cannot get cache from local database', error);
        })
}

export function cacheTags(originalReduxCall, cacheReduxCall) {
    return cacheAndLoad(taskDatabase, 'tags', originalReduxCall, cacheReduxCall);
}

export function cacheProjects(originalReduxCall, cacheReduxCall) {
    return cacheAndLoad(taskDatabase, 'projects', originalReduxCall, cacheReduxCall);
}

export function getTaskFromServerOrCache(taskBusinessId, originalServerCall, handleTaskFromCache) {
    return (dispatch, getState) => {
        const selectedProjectBusinessId = getState().projectReducer.selectedProject.businessId;
        return dispatch(originalServerCall)
            .catch(() => {
                return taskDatabase.tasks.get(selectedProjectBusinessId)
                    .then(backupTasks => {
                        return provideLatestTaskToCallerWithAllActions(taskBusinessId, backupTasks, handleTaskFromCache, dispatch);
                    })
                    .catch(error => {
                        console.log(`Cannot get task with id: ${taskBusinessId}. Error:`, error);
                    });
            });
    };
}

export function getActionFromServerOrCache(actionBusinessId, taskBusinessId, originalServerCall, handleActionFromCache) {
    return (dispatch, getState) => {
        const selectedProjectBusinessId = getState().projectReducer.selectedProject.businessId;
        return dispatch(originalServerCall)
            .catch(() => {
                return taskDatabase.tasks.get(selectedProjectBusinessId)
                    .then(backupTasks => {
                        const backupTask = _.get(backupTasks, "response", [])
                            .find(task => task.businessId === taskBusinessId);
                        return taskDatabase.editedTasks.get(backupTask ? backupTask.businessId : taskBusinessId)
                            .then(editedTask => {
                                const task = editedTask
                                    ? mergeActionsOffline(undefined, editedTask, backupTask)
                                    : backupTask;
                                return dispatch(handleActionFromCache(
                                    task.actions.find(action => action.businessId === actionBusinessId)
                                ));
                            })
                            .catch((error) => {
                                console.log(`Cannot get task with id: ${taskBusinessId}. Error:`, error);
                            });
                    })
                    .catch(error => {
                        console.log(`Cannot get task with id: ${taskBusinessId}. Error:`, error);
                    });
            })
    }
}

export function cacheTaskConfiguration(originalReduxCall, cacheReduxCall) {
    return cacheAndLoad(taskDatabase, 'taskConfiguration', originalReduxCall, cacheReduxCall);
}

export function cacheUsers(originalReduxCall, cacheReduxCall) {
    return cacheAndLoad(taskDatabase, 'users', originalReduxCall, cacheReduxCall);
}

export function cacheEditedProject(editedProject) {
    return dispatch => {
        return taskDatabase.editedProjects.put(editedProject)
            .then(() => {
                console.log("editedProjects", '[', taskDatabase.name, '][', taskDatabase.verno, '] cache updated');
            })
            .catch(error => {
                console.log('Cannot get cache from local database', error);
            });
    }
}

export function cacheTaskTagAssignment(editedAssignment) {
    return dispatch => {
        return taskDatabase.editedTagAssignments.put(editedAssignment)
            .then(() => {
                console.log("editedTagAssignments", '[', taskDatabase.name, '][', taskDatabase.verno, '] cache updated');
            })
            .catch(error => {
                console.log('Cannot get cache from local database', error);
            });
    }
}

//TODO LM: Task-733
//TODO LM: Tag assignment (removal, sync, etc...) should be handled on the same table, a type field could be a deciding factor
export function cacheTagAssignment(tagAssignment) {
    return dispatch => {
        return taskDatabase.tagAssignments.put(tagAssignment)
            .then(() => {
                console.log("tagAssignments", '[', taskDatabase.name, '][', taskDatabase.verno, '] cache updated');
            })
            .catch(error => {
                console.log('Cannot get cache from local database', error);
            });
    }
}

export function cacheTaskTagAssignmentRemoval(removedAssignment) {
    return dispatch => {
        return taskDatabase.editedTagAssignments.where('taskBusinessId').equals(removedAssignment.taskBusinessId)
            .toArray(assignments => {
                if (_.isEmpty(assignments)) {
                    taskDatabase.removedTagAssignments.put(removedAssignment)
                        .then(() => {
                            console.log("removedTagAssignments", '[', taskDatabase.name, '][', taskDatabase.verno, '] cache updated');
                        })
                        .catch(error => {
                            console.log('Cannot get cache from local database', error);
                        });
                } else {
                    taskDatabase.editedTagAssignments.where('taskBusinessId').equals(removedAssignment.taskBusinessId)
                        .delete()
                        .catch(error => {
                            console.log('Cannot get cache from local database', error);
                        })
                }
            })
            .catch(error => {
                console.log('Cannot get cache from local database', error);
            })
    }
}

export function cacheEditedTask(task) {
    return dispatch => {
        return provideBackupTaskAndItsSavedChanges(task.businessId, task.projectBusinessId, saveEditedTaskKeepingOfflineCreatedActions(task));
    }
}

export function cacheCreatedTask(task) {
    return cacheEditedTask(task);
}

export function cacheEditedAction(actionToCache, taskBusinessId) {
    return (dispatch, getState) => {
        const selectedProjectBusinessId = getState().projectReducer.selectedProject.businessId;
        return provideBackupTaskAndItsSavedChanges(taskBusinessId, selectedProjectBusinessId, saveEditedTaskWithNewAction(actionToCache));
    }
}

export function cacheCreatedAction(actionToCache, taskBusinessId) {
    return (dispatch, getState) => {
        const selectedProjectBusinessId = getState().projectReducer.selectedProject.businessId;
        return provideBackupTaskAndItsSavedChanges(taskBusinessId, selectedProjectBusinessId, saveEditedTaskWithNewAction(actionToCache));
    }
}

export function provideLatestTaskToCallerWithAllActions(taskBusinessId, backupTasks, handleTaskFromCache, dispatch) {
    const backupTask = _.get(backupTasks, "response", [])
        .find(task => task.businessId === taskBusinessId);
    return taskDatabase.editedTasks.get(backupTask ? backupTask.businessId : taskBusinessId)
        .then(editedTask => {
            return dispatch(handleTaskFromCache(
                editedTask ? mergeActionsOffline(undefined, editedTask, backupTask) : backupTask
            ));
        })
        .catch((error) => {
            console.log(`Cannot get task with id: ${taskBusinessId}. Error:`, error);
        });
}

export function mergeBackupTasksWithEditedTasks(backupTasks, editedTasks) {
    if (backupTasks) {
        editedTasks.forEach(editedTask => {
            const matchingTask = backupTasks
                .find(backupTask => backupTask.businessId === editedTask.businessId);
            if (matchingTask) {
                backupTasks[backupTasks.indexOf(matchingTask)] = editedTask;
            } else {
                backupTasks.push(editedTask);
            }
        });
    }
    return backupTasks;
}

export function saveEditedTaskKeepingOfflineCreatedActions(task) {
    return (onlineBackupTask, offlineEditedTask) => {
        const mergedTask = mergeOfflineSavedActionsWithNewTaskChanges(task, offlineEditedTask, onlineBackupTask);
        return taskDatabase.editedTasks
            .put(mergedTask)
            .catch(error => {
                console.log('Cannot write cache from local database', error);
            });
    }
}

export function saveEditedTaskWithNewAction(actionToCache) {
    return (onlineBackupTask, offlineEditedTask) => {
        const mergedTask = mergeActionsOffline(actionToCache, offlineEditedTask, onlineBackupTask);
        return taskDatabase.editedTasks
            .put(mergedTask)
            .catch(error => {
                console.log('Cannot write cache from local database', error);
            })
    }
}

export function mergeActionsOffline(actionToCache, offlineEditedTask, onlineBackupTask) {
    const actions = _.unionBy(
        actionToCache ? [actionToCache] : [],
        _.get(offlineEditedTask, 'actions', []),
        _.get(onlineBackupTask, 'actions', []),
        'businessId');
    return {
        ...onlineBackupTask,
        ...offlineEditedTask,
        numberOfActions: actions.length,
        actions
    };
}

export function mergeOfflineSavedActionsWithNewTaskChanges(task, offlineBackupTask) {
    const actions = _.get(offlineBackupTask, 'actions', []);
    return {
        ...offlineBackupTask,
        ...task,
        numberOfActions: actions.length,
        actions
    };
}

export function provideBackupTaskAndItsSavedChanges(taskBusinessId, projectBusinessId, callback) {
    return taskDatabase.tasks.get(projectBusinessId)
        .then(onlineBackupTasks => {
            const onlineBackupTask = _.get(onlineBackupTasks, "response", [])
                .find(taskFromBackup => taskBusinessId === taskFromBackup.businessId) || {};
            return taskDatabase.editedTasks.get(taskBusinessId).then(
                offlineEditedTask => {
                    return callback(onlineBackupTask, offlineEditedTask);
                }
            ).catch(error => {
                console.log('Cannot read cache from local database: ', error);
            });
        })
        .catch(error => {
            console.log('Cannot get cache from local database', error);
        });
}

const tasksCache = {
    cacheOrServeTasks,
    cacheTags,
    cacheProjects,
    cacheTagAssignment,
    getTaskFromServerOrCache,
    cacheTaskConfiguration,
    cacheUsers,
    cacheEditedProject,
    cacheTaskTagAssignment,
    cacheTaskTagAssignmentRemoval,
    cacheEditedTask,
    cacheCreatedTask,
    cacheEditedAction,
    cacheCreatedAction,
    updateOrCreateTaskInCache,
    updateOrCreateActionInCache,
    getActionFromServerOrCache
};

export default tasksCache;
