import * as actionTypes from './MobileLanding.action.types';
import _ from 'lodash';

const initialState = {
    openAuditsCounter: 0,
    unreadTasksCounter: 0,
    user: ''
};

export default function (state = initialState, action) {
    switch (action.type) {
        case actionTypes.FETCH_AUDITS_SUCCESS: {
            const newState = {...state};
            newState.openAuditsCounter = action.payload.data.data.listCurrentAuditsForAuditor
                .filter(audit => audit.status === 'OPEN').length || 0;
            return newState;
        }
        case actionTypes.LIST_TASKS_FOR_COUNTER: {
            const newState = {...state};
            newState.user = action.data.user;
            return newState;
        }
        case actionTypes.LIST_TASKS_FOR_COUNTER_SUCCESS: {
            const newState = {...state};
            newState.unreadTasksCounter = getUnseenTaskNumber(action.payload.data.data.listTasks, newState.user);
            return newState;
        }
        case actionTypes.UPDATE_COUNTERS: {
            const newState = {...state};
            newState.unreadTasksCounter = action.data.numberOfUnseenTasks;
            return newState;
        }
        default:
            return state;
    }
}

export function getUnseenTaskNumber(tasks, user) {
    const tasksOfUser = tasks
        .filter(task => _.get(task, 'assignee.businessId') === user.businessId);
    return _.flatMap(tasksOfUser, task => task.tags)
        .filter(tag => tag && tag.label.toLowerCase() === "unseen")
        .length;
}
