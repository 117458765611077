import React, {Component} from 'react';
import {
    withStyles,
    Typography
} from "@material-ui/core";
import Flex from 'components/grid/Flex';
import moment from 'moment';
import {navyBlue, htmlWhite, dustyGrey, transparentBlack054} from 'components/colors/Colors'
import {withTranslation} from "react-i18next";
import MomentUtils from '@date-io/moment';
import {DatePicker, MuiPickersUtilsProvider} from '@material-ui/pickers';
import utils from 'utils/Utils';

const styles = theme => ({
    page: {
        border: `1px solid ${navyBlue}`,
        borderRadius: 6,
        width: 67,
        color: navyBlue,
    },
    title: {
        fontSize: 12,
        color: transparentBlack054,
        marginBottom: 4
    },
    header: {
        width: '100%',
        backgroundColor: navyBlue,
        color: htmlWhite,
        textAlign: 'center',
        borderRadius: 6,
        fontSize: 12,
        textTransform: 'capitalize'
    },
    dayNumber: {
        fontWeight: 'bold',
        fontSize: 20,
        lineHeight: 'normal'
    },
    dayName: {
        fontSize: 10,
        textTransform: 'capitalize'
    },
    datePicker: {
        display: 'none'
    }
});

export class CalendarPage extends Component {

    state = {
        open: false
    };

    toggleOpen = () => {
        if (!this.props.disabled) {
            this.setState({open: !this.state.open})
        }
    };

    render() {
        const {classes, title, date, name, disabled, i18n, onChange, maxDate} = this.props;
        const {open} = this.state;

        const momentDate = moment(date).locale(i18n.language);
        const header = momentDate.format("MMM YYYY");
        const dayNumber = momentDate.format("D");
        const dayName = momentDate.format("dddd");
        return (
            <MuiPickersUtilsProvider utils={MomentUtils}>
                <Flex container direction={"column"} onClick={this.toggleOpen}>
                    <Typography className={classes.title}>{title}</Typography>
                    <Flex container direction={"column"} alignItems={'center'} className={classes.page}
                          style={{color: disabled && dustyGrey, borderColor: disabled && dustyGrey}}
                    >
                        <Typography className={classes.header} style={{backgroundColor: disabled && dustyGrey}}>
                            {header}
                        </Typography>
                        <Typography className={classes.dayNumber}>{dayNumber}</Typography>
                        <Typography className={classes.dayName}>{dayName}</Typography>
                    </Flex>
                </Flex>
                <DatePicker
                    label={title}
                    fullWidth
                    variant="outlined"
                    value={date}
                    name={name}
                    disablePast
                    maxDate={maxDate}
                    minDateMessage={''}
                    format={utils.DATE_FORMAT}
                    onChange={onChange}
                    disabled={disabled}
                    open={open}
                    onClose={this.toggleOpen}
                    classes={{root: classes.datePicker}}
                />
            </MuiPickersUtilsProvider>
        )
    }
}

export default withStyles(styles)(withTranslation()(CalendarPage));
