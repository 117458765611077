import * as actionTypes from './Questions.action.types';
import _ from 'lodash';
import {mapCatalogueLabel} from "domain/audit/AuditConfiguration.model";

const initialState = {
    questions: [],
};

export default function (state = initialState, action) {
    switch (action.type) {
        case actionTypes.FETCH_QUESTIONS_SUCCESS: {
            const newState = _.cloneDeep(state);
            if (_.get(action, 'payload.data.data.listQuestions')) {
                newState.questions = sortByBusinessId(mapQuestionsRows(action.payload.data.data.listQuestions));
            }
            return newState;
        }
        default:
            return state;
    }
}

export function mapQuestionsRows(questions) {
    return questions.map(question => {
        const assignedQuestionnaires = question.assignedQuestionnaires.map(questionnaire => questionnaire.name);
        return {
            businessId: question.businessId,
            translations: question.translations,
            status: question.status,
            answerType: question.answerType,
            catalogue: mapCatalogueLabel(question.catalogue),
            cycle: _.get(_.find(question.tags, ['category', 'CYCLE']), 'label'),
            assignedToQuestionnaire: assignedQuestionnaires.length > 0,
            assignedQuestionnaires: assignedQuestionnaires
        }
    })
}

export function sortByBusinessId(array) {
    return _.sortBy(array, item => parseInt(item.businessId.replace('Q', '0')));
}
