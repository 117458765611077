import React, {Component} from 'react';
import {FormControl, FormControlLabel, Radio, RadioGroup, Typography, withStyles} from "@material-ui/core";
import {
    AUDIT_REPORTING_DEVIATIONS,
    AUDIT_REPORTING_HISTORY,
    AUDIT_REPORTING_MAP,
    AUDIT_REPORTING_PARETO,
} from "routes/routes";
import Flex from 'components/grid/Flex';
import {Redirect, Route, Switch} from "react-router";
import {Link} from 'react-router-dom';
import {Trans, withTranslation} from "react-i18next";
import AuditReportingHistory from "./history/AuditReportingHistory";
import AuditReportingPareto from "./pareto/AuditReportingPareto";
import AuditReportingMap from "./map/AuditReportingMap";
import AuditReportingDeviations from "./deviations/AuditReportingDeviations";
import {auditPaperGrey, dustyGrey, materialUIBorder} from "components/colors/Colors";
import moment from "moment";
import MaterialDatePicker from 'components/material-datepicker/MaterialDatePicker';
import utils from 'utils/Utils';
import OrganizationTree from "scenes/audit-manager/administration/organization-tree/OrganizationTree";
import ConfiguredBootstrapSelect from 'components/configured-bootstrap-select/ConfiguredBootstrapSelect';
import {connect} from "react-redux";
import connector from "./AuditReporting.connect";
import H4Tabs from 'components/h4-tabs/H4Tabs';
import H4Tab from 'components/h4-tabs/H4Tab';
import _ from "lodash";
import AuditAuthorizationErrorLabel from "../components/AuditAuthorizationErrorLabel";
import {treeIndex} from 'components/zindex/zIndex'

const styles = theme => ({
    workspace: {
        padding: 40,
        paddingTop: 10,
        zIndex: treeIndex + 1,
    },
    paper: {
        backgroundColor: auditPaperGrey,
        borderRadius: 8,
        boxShadow: 'none',
        border: `1px solid ${materialUIBorder}`,
        display: 'flex',
        flexDirection: 'column',
        flex: '0 0 auto',
        marginTop: 10,
        minWidth: '85vw',
    },
    contentContainer: {
        padding: 20,
    },
    filtersContainer: {
        padding: 20,
        borderBottom: `1px solid ${materialUIBorder}`,
    },
    fieldTitle: {
        color: dustyGrey,
        textTransform: 'uppercase',
        fontSize: '0.9vw',
        '@media (min-width: 1600px)': {
            fontSize: '0.65vw',
        }
    },
    inputItem: {
        width: '10vw',
    },
    radioLabel: {
        fontSize: '1vw',
        '@media (min-width: 1600px)': {
            fontSize: '0.8vw',
        }
    }
});

const Filters = (props) => {
    const {
        classes, timePeriodValue, handleFilterChange, periodStartDate, periodEndDate, handlePeriodStartDateChange,
        handlePeriodEndDateChange, auditType, handleAuditTypeChange, durationType, handleDurationTypeChange,
        translate, auditTypes, durationTypes,

    } = props;
    return (
        <Flex item grow={0} container className={classes.filtersContainer}>
            <Flex item container alignItems={'flex-end'}>
                <FormControl component="fieldset">
                    <RadioGroup aria-label="position" name="position" value={timePeriodValue}
                                onChange={handleFilterChange}
                                row>
                        <FormControlLabel
                            value="ALL"
                            control={<Radio color="primary"/>}
                            label={translate('global.allTime')}
                            labelPlacement="end"
                            classes={{
                                label: classes.radioLabel
                            }}
                        />
                        <FormControlLabel
                            value="MONTH"
                            control={<Radio color="primary"/>}
                            label={translate('global.currentMonth')}
                            labelPlacement="end"
                            classes={{
                                label: classes.radioLabel
                            }}
                        />
                        <FormControlLabel
                            value="PERIOD"
                            control={<Radio color="primary"/>}
                            label={translate('global.period')}
                            labelPlacement="end"
                            classes={{
                                label: classes.radioLabel
                            }}
                        />
                    </RadioGroup>
                </FormControl>
                <Flex item container>
                    <div className={classes.inputItem} style={{minWidth: 140}}>
                        <Typography className={classes.fieldTitle}><Trans i18nKey={"global.from"}/></Typography>
                        <MaterialDatePicker
                            disabled={timePeriodValue !== 'PERIOD'}
                            value={periodStartDate}
                            format={utils.API_LOCAL_DATE_FORMAT}
                            onChange={handlePeriodStartDateChange}
                        />
                    </div>
                    <div style={{marginRight: 10}}/>
                    <div className={classes.inputItem} style={{minWidth: 140}}>
                        <Typography className={classes.fieldTitle}><Trans i18nKey={"global.to"}/></Typography>
                        <MaterialDatePicker
                            disabled={timePeriodValue !== 'PERIOD'}
                            value={periodEndDate}
                            format={utils.API_LOCAL_DATE_FORMAT}
                            onChange={handlePeriodEndDateChange}
                        />
                    </div>
                </Flex>
            </Flex>
            <Flex item container alignItems={'center'} justifyContent={'flex-end'}>
                {
                    durationType &&
                    <div className={classes.inputItem} style={{marginRight: 10}}>
                        <Typography className={classes.fieldTitle}>
                            <Trans i18nKey={"audit-management.reporting.durationType"}/>
                        </Typography>
                        <ConfiguredBootstrapSelect
                            value={durationType}
                            onChange={handleDurationTypeChange}
                            name={'duration-type'}
                            items={durationTypes}
                            translationPath={'audit-administration.duration'}
                        />
                    </div>
                }
                <div className={classes.inputItem}>
                    <Typography className={classes.fieldTitle}>
                        <Trans i18nKey={"audit-management.reporting.auditType"}/>
                    </Typography>
                    <ConfiguredBootstrapSelect
                        value={auditType}
                        onChange={handleAuditTypeChange}
                        name={'audit-type'}
                        items={auditTypes}
                    />
                </div>
            </Flex>
        </Flex>
    )
};

export class AuditReporting extends Component {

    state = {
        timePeriodValue: "ALL",
        periodStartDate: moment(),
        periodEndDate: moment(),
        auditType: 'ALL',
        durationType: "DAY"
    };

    handleFilterChange = event => {
        this.setState({timePeriodValue: event.target.value});
    };

    handleDurationTypeChange = event => {
        this.setState({durationType: event.target.value});
    };

    handleAuditTypeChange = event => {
        this.setState({auditType: event.target.value});
    };

    handlePeriodStartDateChange = (date) => {
        const periodStartDate = moment(date);
        this.setState({periodStartDate: periodStartDate});
    };

    handlePeriodEndDateChange = (date) => {
        this.setState({periodEndDate: moment(date)});
    };

    componentDidMount() {
        this.props.closeOrganizationLevelTree();
    }

    componentWillUnmount() {
        this.props.resetOrganizationLevelTree();
    }

    render() {
        const {classes, t: translate, auditTypes, durationTypes, selectedOrganizationLevel} = this.props;
        const {timePeriodValue, periodStartDate, periodEndDate, auditType, durationType} = this.state;
        const {
            handleFilterChange, handlePeriodStartDateChange, handlePeriodEndDateChange, handleAuditTypeChange,
            handleDurationTypeChange
        } = this;
        const extendedAuditTypes = ["ALL", ...auditTypes];
        const accessible = selectedOrganizationLevel.accessible;
        return (
            <Flex container style={{height: '100%'}}>
                <OrganizationTree {...{showOrganizationTitle: true}}/>
                {
                    !_.isEmpty(selectedOrganizationLevel) &&
                    <Flex item container direction={'column'} className={classes.workspace}>
                        <Route
                            path={[
                                AUDIT_REPORTING_HISTORY.path,
                                AUDIT_REPORTING_PARETO.path,
                                AUDIT_REPORTING_MAP.path,
                                AUDIT_REPORTING_DEVIATIONS.path,
                            ]}
                            render={function renderTabs(props) {
                                return (
                                    <H4Tabs
                                        value={props.location.pathname}
                                    >
                                        <H4Tab
                                            value={AUDIT_REPORTING_HISTORY.path}
                                            label={<Trans i18nKey="audit-management.reporting.history.title"/>}
                                            component={Link}
                                            to={AUDIT_REPORTING_HISTORY.path}
                                        />
                                        <H4Tab
                                            value={AUDIT_REPORTING_PARETO.path}
                                            label={<Trans i18nKey="audit-management.reporting.pareto.title"/>}
                                            component={Link}
                                            to={AUDIT_REPORTING_PARETO.path}
                                        />
                                        <H4Tab
                                            value={AUDIT_REPORTING_MAP.path}
                                            label={<Trans i18nKey="audit-management.reporting.map.title"/>}
                                            component={Link}
                                            to={AUDIT_REPORTING_MAP.path}
                                        />
                                    </H4Tabs>
                                )
                            }}
                        />
                        <Switch>
                            <Route path={AUDIT_REPORTING_HISTORY.path}
                                   render={function renderHistory(props) {
                                       return (
                                           accessible
                                               ?
                                               <div className={classes.paper}>
                                                   <Filters {...{
                                                       classes,
                                                       timePeriodValue,
                                                       periodStartDate,
                                                       periodEndDate,
                                                       handleFilterChange,
                                                       handlePeriodStartDateChange,
                                                       handlePeriodEndDateChange,
                                                       handleAuditTypeChange,
                                                       auditTypes: extendedAuditTypes,
                                                       durationTypes,
                                                       auditType,
                                                       durationType,
                                                       handleDurationTypeChange,
                                                       translate
                                                   }}/>
                                                   <div className={classes.contentContainer}>
                                                       <AuditReportingHistory {...{
                                                           timePeriodValue,
                                                           periodStartDate,
                                                           periodEndDate,
                                                           auditType,
                                                           durationType
                                                       }}/>
                                                   </div>
                                               </div>
                                               :
                                               <AuditAuthorizationErrorLabel/>
                                       )
                                   }}
                            />
                            <Route path={AUDIT_REPORTING_PARETO.path}
                                   render={function renderPareto(props) {
                                       return (
                                           accessible
                                               ?
                                               <div className={classes.paper}>
                                                   <Filters {...{
                                                       classes,
                                                       timePeriodValue,
                                                       periodStartDate,
                                                       periodEndDate,
                                                       handleFilterChange,
                                                       handlePeriodStartDateChange,
                                                       handlePeriodEndDateChange,
                                                       handleAuditTypeChange,
                                                       auditTypes: extendedAuditTypes,
                                                       durationTypes,
                                                       auditType,
                                                       translate
                                                   }}/>
                                                   <div className={classes.contentContainer}>
                                                       <AuditReportingPareto{...{
                                                           timePeriodValue, periodStartDate, periodEndDate, auditType
                                                       }}/>
                                                   </div>
                                               </div>
                                               :
                                               <AuditAuthorizationErrorLabel/>
                                       )
                                   }}
                            />
                            <Route path={AUDIT_REPORTING_MAP.path}
                                   render={function renderMap(props) {
                                       return (
                                           accessible
                                               ?
                                               <div className={classes.paper}>
                                                   <Filters {...{
                                                       classes,
                                                       timePeriodValue,
                                                       periodStartDate,
                                                       periodEndDate,
                                                       handleFilterChange,
                                                       handlePeriodStartDateChange,
                                                       handlePeriodEndDateChange,
                                                       handleAuditTypeChange,
                                                       auditTypes: extendedAuditTypes,
                                                       durationTypes,
                                                       auditType,
                                                       translate
                                                   }}/>
                                                   <div className={classes.contentContainer}>
                                                       <AuditReportingMap {...{
                                                           timePeriodValue,
                                                           periodStartDate,
                                                           periodEndDate,
                                                           auditType
                                                       }}/>
                                                   </div>
                                               </div>
                                               :
                                               <AuditAuthorizationErrorLabel/>
                                       )
                                   }}
                            />
                            <Route path={AUDIT_REPORTING_DEVIATIONS.path}
                                   render={function renderDeviations(props) {
                                       return (
                                           accessible
                                               ?
                                               <div className={classes.paper}>
                                                   <Filters {...{
                                                       classes,
                                                       timePeriodValue,
                                                       periodStartDate,
                                                       periodEndDate,
                                                       handleFilterChange,
                                                       handlePeriodStartDateChange,
                                                       handlePeriodEndDateChange,
                                                       handleAuditTypeChange,
                                                       auditTypes: extendedAuditTypes,
                                                       durationTypes,
                                                       auditType,
                                                       translate
                                                   }}/>
                                                   <AuditReportingDeviations/>
                                               </div>
                                               :
                                               <AuditAuthorizationErrorLabel/>
                                       )
                                   }}
                            />
                            <Redirect to={AUDIT_REPORTING_HISTORY.path}/>
                        </Switch>
                    </Flex>
                }
            </Flex>
        );
    }
}

export default withTranslation()(withStyles(styles)(connect(connector.mapStateToProps, connector.mapDispatchToProps)(AuditReporting)));
