import * as actionTypes from "./AuditReportingPareto.action.types";
import utils from "utils/Utils"
import query from 'store/GraphqlQueries.js';

export function fetchAuditQuestionStatistics(filterData) {
    const filter = mapAuditQuestionStatisticsFilter(filterData);

    return query.graphql({
        type: actionTypes.FETCH_AUDIT_QUESTION_STATISTICS,
        caller: 'fetchAuditQuestionStatistics',
        query: `
            query ($filter: AuditQuestionStatisticsFilter!){
                fetchAuditQuestionStatistics(filter: $filter) {
                    statistics {
                        count
                        outcome 
                    }
                    questionStatistics {
                        question {
                            businessId
                            status
                            translations {
                                language {
                                    code
                                    label
                                }
                                question
                                comment
                            }
                            tags {
                                label
                                category
                            }
                        }
                        outcome
                        count
                    }
                    catalogueStatistics {
                        catalogue
                        outcome
                        count
                    }
                }
            }
           `,
        variables: {
            filter: filter
        }
    });
}

function mapAuditQuestionStatisticsFilter(filterData) {
    return {
        organizationLevelId: filterData.selectedOrganizationLevel.businessId,
        from: filterData.periodStartDate.format(utils.API_LOCAL_DATE_FORMAT),
        to: filterData.periodEndDate.format(utils.API_LOCAL_DATE_FORMAT),
        auditType: filterData.auditType,
    };
}
