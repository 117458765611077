import React, {Component} from 'react';
import {withStyles, Typography} from "@material-ui/core";
import _ from 'lodash';
import {connect} from 'react-redux';
import connector from './DeviationRedirect.connect';
import {routeTo, TASK_EDIT} from 'routes/routes';
import {Trans} from 'react-i18next';
import PageIsLoading from 'components/page-is-loading-progress/PageIsLoading';
import {doveGrey} from 'components/colors/Colors';
import {withRouter} from 'react-router-dom';

const styles = theme => ({
    centered: {
        textAlign: 'center',
        margin: 'auto',
        color: doveGrey
    }
});

export class DeviationRedirect extends Component {

    state = {
        showError: false
    };

    componentDidMount() {
        const answerId = _.get(this.props, 'answerId');
        if (answerId) {
            this.props.findDeviationByAnswerId(answerId)
                .then(response => {
                    routeTo(TASK_EDIT.pathWithId(response.payload.data.data.findTaskIdByAnswerId), this.props);
                })
                .catch(() => this.showError());

        } else {
            this.showError();
        }
    }

    showError = () => {
        this.setState({showError: true})
    };

    render() {
        const {showError} = this.state;
        const {classes} = this.props;
        return (
            showError ?
                <Typography className={classes.centered}><Trans i18nKey={'task-management.deviation-edit.error'}/></Typography>
                :
                <PageIsLoading/>
        );
    }
}

export default withStyles(styles)(connect(connector.mapStateToProps, connector.mapDispatchToProps)(withRouter(DeviationRedirect)));
