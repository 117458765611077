import * as actionTypes from "./AuditReportingHistory.action.types";
import _ from 'lodash';
import moment from "moment";
import {mapApiAudit} from "domain/audit/Audit.model";

const initialState = {
    statistics: [],
    historyData: [],
    selectedAudit: {}
};

export default function (state = initialState, action) {
    switch (action.type) {
        case actionTypes.FETCH_AUDIT_REPORTING_HISTORY_DATA_SUCCESS: {
            const newState = _.cloneDeep(state);
            const fetchAuditHistoryData = _.get(action, 'payload.data.data.fetchAuditHistory');
            if (fetchAuditHistoryData) {
                newState.statistics = mapStatistics(fetchAuditHistoryData);
                newState.historyData = mapHistoryData(fetchAuditHistoryData);
            }
            return newState;
        }
        case actionTypes.FETCH_AUDIT_SUCCESS: {
            const newState = {...state};
            const fetchAuditData = _.get(action, 'payload.data.data.fetchAudit');
            if (fetchAuditData) {
                newState.selectedAudit = mapApiAudit(fetchAuditData);
            }
            return newState;
        }
        default:
            return state;
    }
}

export function mapStatistics(fetchAuditHistoryData) {
    let statistics = [];
    if (!_.isEmpty(fetchAuditHistoryData.statistics)) {
        const numberOfAudits = fetchAuditHistoryData.statistics.map(statistic => statistic.count).reduce((total, num) => total + num);
        statistics = fetchAuditHistoryData.statistics.map(statistic => mapAuditStatistic(statistic, numberOfAudits));
    }
    return statistics;
}

export function mapHistoryData(fetchAuditHistoryData) {
    let historyData = [];
    if (!_.isEmpty(fetchAuditHistoryData.auditSummaryBreakdown)) {
        historyData.firstAvailableDate = moment(fetchAuditHistoryData.auditSummaryBreakdown[0].interval.start);
        historyData.successfulAuditDays = fetchAuditHistoryData.auditSummaryBreakdown.filter(summary => summary.outcome === "SUCCESSFUL").map(summary => summary.interval.days).flat().map(day => moment(day).toDate());
        historyData.failedMinorAuditDays = fetchAuditHistoryData.auditSummaryBreakdown.filter(summary => summary.outcome === "FAILED_MINOR").map(summary => summary.interval.days).flat().map(day => moment(day).toDate());
        historyData.failedCriticalAuditDays = fetchAuditHistoryData.auditSummaryBreakdown.filter(summary => summary.outcome === "FAILED_CRITICAL").map(summary => summary.interval.days).flat().map(day => moment(day).toDate());
        historyData.missedAuditDays = fetchAuditHistoryData.auditSummaryBreakdown.filter(summary => summary.outcome === "MISSED").map(summary => summary.interval.days).flat().map(day => moment(day).toDate());
        historyData.audits = fetchAuditHistoryData.auditSummaryBreakdown;
    }
    return historyData;
}

export function mapAuditStatistic(auditStatistic, numberOfAudits) {
    return {
        count: auditStatistic.count,
        outcome: auditStatistic.outcome,
        percentage: Math.round(auditStatistic.count * 100 / numberOfAudits),
        all: numberOfAudits,
    }
}