import React, {Component, Fragment} from 'react';
import {Button, CircularProgress, withStyles,} from "@material-ui/core";
import {routeTo, SCHEDULED_AUDITS} from 'routes/routes';
import {
    christiGreen,
    doveGrey,
    guardsmanRed,
    htmlGrey,
    htmlLightGrey,
    htmlWhite,
    medium,
    silver
} from 'components/colors/Colors';
import {Trans, withTranslation} from 'react-i18next';
import Flex from 'components/grid/Flex';
import {Circle} from "rc-progress";
import _ from "lodash";
import utils from 'utils/Utils';
import EmailSentMessage from "components/snack-messages/EmailSentMessage";
import EmailErrorMessage from "components/snack-messages/EmailErrorMessage";
import {connect} from 'react-redux';
import connector from './AuditResult.connect';

const sizeOfCircle = 220;
const circleWidth = 30;
const sizeOfCircleProgressBar = sizeOfCircle + circleWidth;
const mobileRatio = 0.5;

export function resultTranslationKey(resultLabel){
    switch (resultLabel) {
        case "happy":
        case "accepted":
            return 'global.success';
        case "neutral":
        case "partially accepted":
            return 'global.partial-success';
        case "sad":
        case "not accepted":
            return 'global.failed';
        default:
            return undefined;
    }
}

const styles = theme => ({
    container: {
        width: '100%',
        height: '100%',
    },
    title: {
        textAlign: 'center',
        fontSize: 32,
        '@media (max-width: 600px)': {
            fontSize: 20
        },
        marginBottom: 50,
    },
    button: {
        marginTop: 60,
        width: 300,
        textTransform: 'capitalize',
    },
    circleContainer: {
        position: 'relative',
        marginBottom: 30,

    },
    progressBar: {
        width: sizeOfCircleProgressBar,
        height: sizeOfCircleProgressBar,
        '@media (max-width: 600px)': {
            width: sizeOfCircleProgressBar * mobileRatio,
            height: sizeOfCircleProgressBar * mobileRatio,
        }
    },
    circle: {
        width: sizeOfCircle,
        height: sizeOfCircle,
        position: 'absolute',
        padding: sizeOfCircle / 5,
        borderRadius: '50%',
        border: `1px solid ${htmlGrey}`,
        textAlign: 'center',
        top: circleWidth / 2,
        left: circleWidth / 2,
        color: htmlWhite,
        fontSize: sizeOfCircle / 2,
        lineHeight: 'normal',
        '@media (max-width: 600px)': {
            width: sizeOfCircle * mobileRatio,
            height: sizeOfCircle * mobileRatio,
            padding: sizeOfCircle / 5 * mobileRatio,
            top: circleWidth / 2 * mobileRatio,
            left: circleWidth / 2 * mobileRatio,
            fontSize: sizeOfCircle / 2 * mobileRatio,
        }
    },
    percentSign: {
        color: htmlLightGrey,
        fontSize: sizeOfCircle / 6,
        '@media (max-width: 600px)': {
            fontSize: sizeOfCircle / 6 * mobileRatio,
        }
    },
    thankYouText: {
        fontSize: 72,
        '@media (max-width: 600px)': {
            fontSize: 36
        }
    },
    thankYouForText: {
        fontSize: 36,
        '@media (max-width: 600px)': {
            fontSize: 18
        }
    }
});

const ProgressCircle = (props) => {
    const {classes, percentage, color, offset} = props;
    return (
        <div className={classes.circleContainer}>
            <Circle percent={percentage}
                    strokeWidth={10}
                    strokeColor={doveGrey}
                    trailWidth={10}
                    trailColor={htmlLightGrey}
                    className={classes.progressBar}
                    strokeLinecap={'butt'}
            />
            <div className={classes.circle} style={{background: color}}>
                <div style={{
                    position: 'relative',
                    transform: percentage === 100 ? 'scale(0.8)' : 'none',
                    left: offset
                }}>
                    {percentage}<span className={classes.percentSign}>%</span>
                </div>
            </div>
        </div>
    );
};

export class AuditResult extends Component {

    state = {
        subscribedToWebSocket: false,
        showEmailSentIndicator: false,
        showEmailErrorIndicator: false,
        emailTo: null,
    };

    componentDidMount() {
        this.subscribeToWebsocket();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.websocketClientConnected && !this.props.websocketClientConnected) {
            this.setState({subscribedToWebSocket: false});
        }
        if (!prevProps.websocketClientConnected && this.props.websocketClientConnected) {
            this.subscribeToWebsocket();
        }
    }

    componentWillUnmount() {
        this.unsubscribeFromWebsocket();
    }

    onMessage = (messageEvent) => {
        const message = JSON.parse(messageEvent.body);
        if (message.emailDeliveryResult === 'SUCCESSFUL') {
            this.setState({showEmailSentIndicator: true, emailTo: message.emailRecipient});
        } else {
            this.setState({showEmailErrorIndicator: true});
            console.error('Failed to send email, reason:', message.failureReason);
        }
    };

    subscribeToWebsocket() {
        if (!this.state.subscribedToWebSocket && this.props.websocketClientConnected) {
            this.setState({subscribedToWebSocket: true});
            const topic = this.getTopic();
            this.props.websocketClient.subscribe(topic, this.onMessage);
        }
    }

    unsubscribeFromWebsocket() {
        if (this.state.subscribedToWebSocket && this.props.websocketClientConnected) {
            const topic = this.getTopic();
            this.setState({subscribedToWebSocket: false});
            this.props.websocketClient.unsubscribe(topic);
        }
    }

    getTopic = () => {
        return "/user/" + this.props.user.businessId + "/topic/email-notification";
    };

    fetchAudits = () => {
        if (!this.props.data.readOnly) {
            this.props.actions.fetchAudits();
        }
        routeTo(SCHEDULED_AUDITS.path, this.props);
    };

    getMarkerColor = (status) => {
        switch (status) {
            case 'sad':
            case 'FAILED_CRITICAL':
                return guardsmanRed;
            case 'neutral':
            case 'FAILED_MINOR':
                return medium;
            case 'happy':
            case 'SUCCESSFUL':
                return christiGreen;
            default:
                return silver;
        }
    };

    calculatePercentageOffset = percentage => {
        let offset = 0;
        if (percentage === 100) {
            offset = -30;
        } else if (percentage < 10) {
            offset = 15;
        }
        return utils.checkMobileBrowser() ? offset * mobileRatio : offset
    };

    closeEmailIndicator = () => {
        this.setState({showEmailSentIndicator: false, showEmailErrorIndicator: false, emailRecipient: null});
    };

    render() {
        const {classes, t: translate} = this.props;
        const {isLoading, result} = this.props.data;
        const {showEmailSentIndicator, showEmailErrorIndicator, emailTo} = this.state;
        const {fetchAudits, getMarkerColor, calculatePercentageOffset, closeEmailIndicator} = this;
        const percentage = _.get(result, "percentage", 0);
        const color = getMarkerColor(_.get(result, "label", ""));
        const offset = calculatePercentageOffset(percentage);
        return (
            <Fragment>
                {
                    isLoading
                        ?
                        <div style={{textAlign: 'center'}}>
                            <CircularProgress/>
                        </div>
                        :
                        <Flex container className={classes.container} direction="column" alignItems={'center'}
                              justifyContent={'center'}>
                            <div className={classes.title}>
                                <Trans i18nKey={"audit-management.review-audit.result"}/>
                            </div>
                            <ProgressCircle classes={classes} {...{percentage, color, offset}}/>
                            <div className={classes.thankYouText}>
                                <Trans i18nKey={"audit-management.execute-audit.thank-you"}/>
                            </div>
                            <div className={classes.thankYouForText}>
                                <Trans i18nKey={"audit-management.execute-audit.thank-you-for"}/>
                            </div>
                            <Button onClick={fetchAudits}
                                    variant="contained"
                                    color="primary"
                                    className={classes.button}
                            >
                                <span>{translate("global.back")} {translate("global.to")} {translate("global.my-audits")}</span>
                            </Button>
                            <EmailSentMessage {...{
                                open: showEmailSentIndicator,
                                onClose: closeEmailIndicator,
                                recipient: emailTo
                            }}/>
                            <EmailErrorMessage {...{
                                open: showEmailErrorIndicator,
                                onClose: closeEmailIndicator
                            }}/>
                        </Flex>
                }
            </Fragment>
        );
    }
}

export default withStyles(styles)(connect(connector.mapStateToProps, connector.mapDispatchToProps)(withTranslation()(AuditResult)));
