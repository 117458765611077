import React, {PureComponent} from 'react';
import {
    withStyles,
    IconButton,
    Typography,
} from "@material-ui/core";
import Flex from 'components/grid/Flex';
import {Trans} from "react-i18next";
import TaskTile from 'scenes/tasks/components/TaskTile';
import {
    DeleteOutlined as DeleteIcon,
    PlayArrow as PlayIcon,
    Pause as PauseIcon,
} from '@material-ui/icons';
import {
    htmlGrey,
    silver,
    swipeActionRed,
    swipeActionGreen,
    swipeActionOrange,
    htmlWhite
} from 'components/colors/Colors';
import SwipeableViews from 'react-swipeable-views';
import {BACKLOG_VALUE} from 'domain/task/Tasks.model';
import {getColorbyPriority} from 'components/colors/Priority';

const styles = theme => ({
    swipeActionButton: {
        paddingLeft: 15,
        paddingRight: 15,
        width: 60,
    },
    swipeActionLabel: {
        fontSize: 13
    },
});

class SwipeActions extends PureComponent {
    render() {
        const {classes, task, applicationOnline, archive, toggleKanban, view, user} = this.props;
        return (
            <Flex item container style={{
                borderBottom: `1px solid ${htmlGrey}`,
                borderLeft: `5px solid ${getColorbyPriority(task.priority.type)}`,
                marginBottom: 5
            }}>
                <TaskTile {...{
                    task,
                    applicationOnline,
                    isSwiped: true,
                    user,
                    styleChangeOnUnseen: false,
                }}/>
                <Flex item container justifyContent={'flex-end'} style={{paddingLeft: 0, marginRight: 6}}>
                    <Flex item grow={0} container direction={'column'} alignItems={'center'} justifyContent={'center'}
                          className={classes.swipeActionButton}>
                        <IconButton
                            style={{
                                color: htmlWhite,
                                backgroundColor: !user.isAdmin ? silver : swipeActionRed,
                                padding: 5
                            }}
                            onClick={archive(task)}
                            disabled={!applicationOnline || !user.isAdmin}>
                            <DeleteIcon/>
                        </IconButton>
                        <Typography className={classes.swipeActionLabel}>
                            <Trans i18nKey="global.delete"/>
                        </Typography>
                    </Flex>
                    <Flex item grow={0} container direction={'column'} alignItems={'center'} justifyContent={'center'}
                          className={classes.swipeActionButton}>
                        <IconButton
                            style={{
                                color: htmlWhite,
                                backgroundColor: view === BACKLOG_VALUE ? swipeActionGreen : swipeActionOrange,
                                padding: 5
                            }}
                            onClick={toggleKanban(task)}
                            disabled={!applicationOnline}>
                            {
                                view === BACKLOG_VALUE ? <PlayIcon/> : <PauseIcon/>
                            }
                        </IconButton>
                        <Typography className={classes.swipeActionLabel}>
                            <Trans
                                i18nKey={view === BACKLOG_VALUE ? "task-management.add-to-kanban" : "task-management.remove-from-kanban"}/>
                        </Typography>
                    </Flex>
                </Flex>
            </Flex>
        );
    }
}

export class SwipeAbleTask extends PureComponent {
    render() {
        const {classes, index, task, handleTaskSwipe, archive, toggleKanban, view, applicationOnline, user} = this.props;
        return <SwipeableViews
            index={index}
            onChangeIndex={handleTaskSwipe(task)}
            slideStyle={{display: 'flex'}}
        >
            <Flex item container style={{
                borderBottom: `1px solid ${htmlGrey}`,
                borderLeft: `5px solid ${getColorbyPriority(task.priority.type)}`,
                marginBottom: 5,
            }}>

                <TaskTile {...{
                    task,
                    applicationOnline,
                    user,
                    styleChangeOnUnseen: false,
                }}/>
            </Flex>

            <SwipeActions {...{
                task,
                applicationOnline,
                classes,
                archive,
                toggleKanban,
                view,
                user
            }}/>
        </SwipeableViews>
    }
}

export default withStyles(styles)(SwipeAbleTask);
