import {
    resetOrganizationLevelTree,
    closeOrganizationLevelTree
} from "scenes/audit-manager/administration/organization-tree/OrganizationTree.action";

export default {
    mapStateToProps: (state) => {
        return {
            auditTypes: state.generalConfigurationReducer.auditConfiguration.auditTypesHistory,
            durationTypes: state.generalConfigurationReducer.auditConfiguration.durationTypes,
            selectedOrganizationLevel: state.organizationTreeReducer.selectedOrganizationLevel,
        };
    },
    mapDispatchToProps: (dispatch) => {
        return {
            resetOrganizationLevelTree: () => dispatch(resetOrganizationLevelTree()),
            closeOrganizationLevelTree: () => dispatch(closeOrganizationLevelTree()),
        };
    }
};
