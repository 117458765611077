import {
    createChecklistItem,
    updateChecklistItem,
    deleteChecklistItem
} from './Checklist.action'

export default {
    mapStateToProps: () => {
        return {};
    },
    mapDispatchToProps: (dispatch) => {
        return {
            createChecklistItem: (entityId, text, languageCode) =>
                dispatch(createChecklistItem(entityId, text, languageCode)),
            updateChecklistItem: (checklistItem, isChecked, text, languageCode) =>
                dispatch(updateChecklistItem(checklistItem, isChecked, text, languageCode)),
            deleteChecklistItem: (checklistItemBusinessId) => dispatch(deleteChecklistItem(checklistItemBusinessId)),
        };
    }
};
