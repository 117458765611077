export const FETCH_TASK = '@task_edit/FETCH_TASK';
export const FETCH_TASK_SUCCESS = '@task_edit/FETCH_TASK_SUCCESS';
export const FETCH_TASK_FAIL = '@task_edit/FETCH_TASK_FAIL';
export const FETCH_TASK_FROM_CACHE = '@task_edit/FETCH_TASK_FROM_CACHE';
export const FETCH_TASK_CONFIGURATION = '@task_edit/FETCH_TASK_CONFIGURATION';
export const FETCH_TASK_CONFIGURATION_SUCCESS = '@task_edit/FETCH_TASK_CONFIGURATION_SUCCESS';
export const FETCH_TASK_CONFIGURATION_FAIL = '@task_edit/FETCH_TASK_CONFIGURATION_FAIL';
export const FETCH_TASK_CONFIGURATION_FROM_CACHE = '@task_edit/FETCH_TASK_CONFIGURATION_FROM_CACHE';
export const FETCH_TEMPORARY_TASK_REFERENCE_ID = '@task_edit/FETCH_TEMPORARY_TASK_REFERENCE_ID';
export const FETCH_TEMPORARY_TASK_REFERENCE_ID_SUCCESS = '@task_edit/FETCH_TEMPORARY_TASK_REFERENCE_ID_SUCCESS';
export const FETCH_TEMPORARY_TASK_REFERENCE_ID_FAIL = '@task_edit/FETCH_TEMPORARY_TASK_REFERENCE_ID_FAIL';
export const FETCH_TEMPORARY_TASK_REFERENCE_ID_OFFLINE = '@task_edit/FETCH_TEMPORARY_TASK_REFERENCE_ID_OFFLINE';
export const SYNCHRONIZE_TASK = '@task_edit/SYNCHRONIZE_TASK';
export const SYNCHRONIZE_TASK_SUCCESS = '@task_edit/SYNCHRONIZE_TASK_SUCCESS';
export const SYNCHRONIZE_TASK_FAIL = '@task_edit/SYNCHRONIZE_TASK_FAIL';
export const CURRENT_ACTION_SYNCHRONIZED = '@task_edit/CURRENT_ACTION_SYNCHRONIZED';
export const CREATE_TASK = '@task_edit/CREATE_TASK';
export const CREATE_TASK_SUCCESS = '@task_edit/CREATE_TASK_SUCCESS';
export const CREATE_TASK_FAIL = '@task_edit/CREATE_TASK_FAIL';
export const CREATE_TASK_TO_CACHE = '@task_edit/CREATE_TASK_TO_CACHE';
export const EDIT_TASK = '@task_edit/EDIT_TASK';
export const EDIT_TASK_SUCCESS = '@task_edit/EDIT_TASK_SUCCESS';
export const EDIT_TASK_FAIL = '@task_edit/EDIT_TASK_FAIL';
export const EDIT_TASK_TO_CACHE = '@task_edit/EDIT_TASK_TO_CACHE';
export const CLONE_TASK = '@task_edit/CLONE_TASK';
export const CLONE_TASK_SUCCESS = '@task_edit/CLONE_TASK_SUCCESS';
export const CLONE_TASK_FAIL = '@task_edit/CLONE_TASK_FAIL';
export const RESET = '@task_edit/RESET';
export const ARRIVED_FROM = '@task_edit/ARRIVED_FROM';
