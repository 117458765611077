import React, {Component} from 'react';
import {Divider, withStyles, IconButton, Typography} from "@material-ui/core";
import {withTranslation} from "react-i18next";
import {withRouter} from "react-router";
import Flex from "components/grid/Flex";
import {htmlWhite, silver, jaffaOrange, greyBackground} from "components/colors/Colors";
import ItemInputField from "./ItemInputField";
import H4InputWithAdornment from 'components/input/H4InputWithAdornment';
import ids from 'components/ids/ids';
import {Search, CloseOutlined as CloseIcon, Delete as DeleteIcon} from "@material-ui/icons";
import _ from 'lodash';
import ConfirmationDialog from "components/confirmation-dialog/ConfirmationDialogWarning";

const styles = theme => ({
    page: {
        width: '100%',
        backgroundColor: htmlWhite,
        maxWidth: 1500,
        padding: '20px 40px',
    },
    title: {
        fontSize: 25,
        color: jaffaOrange,
        marginBottom: 20
    },
    itemsContainer: {
        height: '100%',
        overflow: 'auto',
        overflowScrolling: 'touch',
        position: 'absolute',
        bottom: 0,
        right: 0,
        left: 0,
        top: 0,
    },
    itemContainer: {
        width: '100%',
    },
    header: {
        marginTop: 30,
        paddingLeft: 20,
        color: silver,
        fontWeight: 'bold',
        fontSize: 12,
        textTransform: 'uppercase'
    },
    item: {
        paddingLeft: 20,
    },
});

const ListOfItems = props => {
    const {classes, items, onDelete} = props;
    return (
        <div style={{position: 'relative', height: '100%'}}>
            <Flex container direction={'column'} className={classes.itemsContainer}>
                {
                    items.map((item, index) => {
                        return (
                            <div key={index} className={classes.itemContainer}>
                                <Divider style={{marginLeft: 5, marginRight: 5}}/>
                                <Flex container
                                      alignItems={'center'}
                                      justifyContent={'space-between'}>
                                    <Typography className={classes.item}>{item.label}</Typography>
                                    <IconButton onClick={() => onDelete(item.businessId)}>
                                        <DeleteIcon/>
                                    </IconButton>
                                </Flex>
                            </div>
                        )
                    })
                }
                <Divider style={{marginLeft: 5, marginRight: 5}}/>
            </Flex>
        </div>
    )
};

export class ListPage extends Component {

    state = {
        searchText: '',
        newItemLabel: '',
    };

    handleNamedStateChange = event => {
        this.setState({[event.target.name]: event.target.value});
    };

    resetSearchText = () => {
        this.setState({searchText: ''});
    };

    addNewItem = () => {
        this.props.onAddition(this.state.newItemLabel).then(this.setState({newItemLabel: ''}))
    };

    render() {
        const {
            classes, items, itemsToConfirm, titleSingular, titlePlural, t: translate, onSoftDeletion, onHardDeletion,
            resetItemsToConfirm, confirmationHeaderKey, confirmationTextKey, confirmationTexValues,
            confirmationQuestionKey, confirmationQuestionValues
        } = this.props;
        const {searchText, newItemLabel} = this.state;
        const {resetSearchText, handleNamedStateChange, addNewItem} = this;

        const filteredItems = items.filter(item => item.label.toLowerCase().includes(searchText.toLowerCase()));

        return (
            <Flex container direction={'column'} className={classes.page}>
                <span className={classes.title}>
                    {translate('global.list-of', {name: titlePlural})}
                </span>
                <H4InputWithAdornment
                    id={ids.freeTextSearchInput}
                    value={searchText}
                    placeholder={translate('global.search')}
                    onChange={handleNamedStateChange}
                    name={'searchText'}
                    rootStyle={{padding: 0, paddingLeft: 10, width: 420}}
                    startAdornment={<Search/>}
                    endAdornment={
                        !_.isEmpty(searchText) &&
                        <IconButton style={{padding: 3, backgroundColor: silver, fontSize: 13, marginRight: 3}}
                                    onClick={resetSearchText}>
                            <CloseIcon style={{color: greyBackground, fontSize: 'inherit'}}/>
                        </IconButton>
                    }
                />
                <span className={classes.header}>
                    {translate('global.name-of', {name: titleSingular})}
                </span>
                <ListOfItems {...{classes, items: filteredItems, onDelete: onSoftDeletion}}/>
                <ItemInputField {...{
                    name: 'newItemLabel',
                    placeHolder: translate('global.addNew', {value: titleSingular}),
                    onEnter: addNewItem,
                    onChange: handleNamedStateChange,
                    newItemLabel
                }}/>
                <ConfirmationDialog
                    dialogOpen={!_.isEmpty(itemsToConfirm)}
                    onDialogClose={resetItemsToConfirm}
                    onConfirm={onHardDeletion}
                    confirmationHeaderKey={confirmationHeaderKey}
                    confirmationTextKey={confirmationTextKey}
                    confirmationTexValues={confirmationTexValues}
                    confirmationQuestionKey={confirmationQuestionKey}
                    confirmationQuestionValues={confirmationQuestionValues}
                    children={itemsToConfirm}
                />
            </Flex>
        );
    }
}

export default withStyles(styles)(withTranslation()(withRouter(ListPage)));
