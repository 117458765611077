import React, {Component} from 'react';
import {withStyles, TextField} from "@material-ui/core";

const styles = theme => ({
    input: {
        padding: '9px 14px',
        '&:after': {}
    },
    root: {}
});

export class BootstrapTextField extends Component {

    selectAllOnFocus = event => {
        event.target.select();
    };

    render() {
        const {classes, onFocus, InputProps, ...otherProps} = this.props;
        return (
            <TextField InputProps={{classes, ...InputProps}} {...otherProps}
                       onFocus={onFocus ? onFocus : this.selectAllOnFocus}/>
        )
    }
}

export default withStyles(styles)(BootstrapTextField);
