import React, {Component} from 'react';
import Flex from "components/grid/Flex";
import AuditTile from "./AuditTile";

function getExtraContainerWidth(row) {
    if (row.endOverFlow && row.startOverFlow) {
        return 20
    } else {
        return 0
    }
}

function getExtraMarginLeft(row) {
    if (row.startOverFlow) {
        return -10;
    } else {
        if (row.endOverFlow) {
            return 10;
        } else {
            return 0;
        }
    }
}

function getWidth(row, containerWidth) {
    if (row.endOverFlow && row.startOverFlow) {
        return containerWidth + 10
    } else if (row.endOverFlow) {
        return containerWidth;
    } else if (row.startOverFlow) {
        return containerWidth
    } else {
        return containerWidth - 25
    }
}

export class LongAudit extends Component {

    render() {
        const {
            columnWidth, row, getTileColor, openAuditorUpdateDialog, openAuditDetailsDialog,
            isAuditorChangeAllowed, sendAuditReminder, user, toggleCancelAuditScheduleDialog,
            onAuditHovered, hoveredAudit
        } = this.props;
        const marginLeft = (columnWidth * row.start) + row.start * 10 + getExtraMarginLeft(row);
        const containerWidth = (columnWidth * row.length) + ((row.length - 1) * 10) + getExtraContainerWidth(row);
        const width = getWidth(row, containerWidth);
        return (
            <Flex container alignItems={'center'} justifyContent={'center'} style={{
                marginLeft: marginLeft,
                marginTop: 10,
                width: containerWidth,
                height: 75
            }}>
                <AuditTile {...{
                    audit: row.audit,
                    getTileColor,
                    width,
                    openAuditorUpdateDialog,
                    openAuditDetailsDialog,
                    isAuditorChangeAllowed,
                    sendAuditReminder,
                    length: row.length,
                    user,
                    toggleCancelAuditScheduleDialog,
                    onAuditHovered,
                    hoveredAudit
                }}/>
            </Flex>
        );
    }
}

export default LongAudit;
