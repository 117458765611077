import React, {PureComponent} from 'react';
import {
    withStyles,
} from "@material-ui/core";
import {unseenCircle, htmlWhite} from 'components/colors/Colors';

export const styles = theme => ({
    unseenNumber: {
        backgroundColor: unseenCircle,
        color: htmlWhite,
        borderRadius: '50%',
        width: '1.1rem',
        lineHeight: '1.1rem'
    }
});

export class UnseenNumber extends PureComponent {
    render() {
        const {
            classes, isUnseen, number, seenColor
        } = this.props;
        return (
            <span
                className={isUnseen ? classes.unseenNumber : ''}
                style={{color: !isUnseen && seenColor}}
            >{number}</span>
        )
    }
}

export default withStyles(styles)(UnseenNumber);
