import React, {Component, Fragment} from 'react';
import {IconButton, withStyles} from "@material-ui/core";
import _ from "lodash";
import Flex from "components/grid/Flex";
import {connect} from 'react-redux';
import connector from './AuditReportingMap.connect';
import {Trans, withTranslation} from "react-i18next";
import ImageMarker from "components/image-marker/ImageMarker";
import {
    christiGreen,
    doveGrey,
    guardsmanRed,
    htmlGrey,
    htmlLightGrey,
    htmlWhite,
    medium,
    random,
    silver
} from "components/colors/Colors";
import AuditReportingMapMarker from "./components/AuditReportingMapMarker";
import ReactResizeDetector from "react-resize-detector";
import {MoreVert as MoreVertIcon} from "@material-ui/icons";
import DrillUpIcon from 'assets/img/drill-up-icon.png';
import Typography from "@material-ui/core/Typography";
import moment from "moment";
import StatisticCircles from "scenes/audit-manager/reporting/components/StatisticCircles.jsx";
import ReportingUtils from "scenes/audit-manager/reporting/AuditReportingUtils";
import {Circle} from "rc-progress";
import {auditReportingMap} from "components/zindex/zIndex";

const sizeOfCircle = 90;
const sizeOfCircleProgressBar = sizeOfCircle + 10;

export const styles = theme => ({
    mapContainer: {
        position: 'absolute',
        backgroundColor: htmlWhite,
        display: 'flex',
        flexDirection: 'column',
        flex: '1 1 auto'
    },
    mainContainer: {
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center'
    },
    map: {
        minWidth: '80vw',
    },
    drillUpIcon: {
        width: 40,
        height: 40
    },
    moreIcon: {
        width: 25,
        height: 25,
    },
    title: {
        fontWeight: 'bold',
        fontSize: 25
    },
    auditMarker: {
        color: random
    },
    sideBar: {
        width: '15%',
        minWidth: 150
    },
    center: {
        width: '70%',
        minWidth: 700
    },
    latestAuditCircle: {
        width: sizeOfCircle,
        height: sizeOfCircle,
        position: 'absolute',
        padding: sizeOfCircle / 5,
        transform: 'translate3d(-50%, -50%, 0)',
        borderRadius: '50%',
        border: `1px solid ${htmlGrey}`,
        textAlign: 'center',
        zIndex: auditReportingMap
    },
    progressBar: {
        width: sizeOfCircleProgressBar,
        height: sizeOfCircleProgressBar,
        position: 'absolute',
        zIndex: auditReportingMap,
        top: -sizeOfCircleProgressBar / 2,
        left: -sizeOfCircleProgressBar / 2
    },
    auditTitleText: {
        width: 2 * sizeOfCircleProgressBar,
        display: 'inline-block',
        position: 'absolute',
        top: -32.5,
        left: -sizeOfCircleProgressBar / 2,
        fontWeight: 'bold',
        fontSize: 17.5
    },
    timeText: {
        width: 2 * sizeOfCircleProgressBar,
        display: 'inline-block',
        position: 'absolute',
        fontWeight: 'bold',
        bottom: -40,
        left: -sizeOfCircleProgressBar / 2,
        fontSize: 22.5
    },
    auditTypeText: {
        width: 2 * sizeOfCircleProgressBar,
        display: 'inline-block',
        position: 'absolute',
        fontWeight: 'bold',
        bottom: -55,
        left: -sizeOfCircleProgressBar / 2,
        fontSize: 15,
        color: htmlGrey
    },
    number: {
        fontWeight: 'bold'
    }
});

const ExpandIcon = (props) => {
    const {classes, organizationLevel, showParent} = props;
    return (
        <IconButton
            onClick={showParent(organizationLevel)}
            style={{color: 'inherit', fontSize: 'inherit', padding: 1}}>
            <img src={DrillUpIcon} alt={'Drill down'} className={classes.drillUpIcon}/>
        </IconButton>
    )
};

const Title = (props) => {
    const {classes, translate, organizationLevel, markersVisible, auditType} = props;
    return (
        <Fragment>
            {
                !_.isEmpty(organizationLevel.parentLevel) && markersVisible ?
                    auditType === 'ALL' ?
                        <Typography
                            className={classes.title}>{translate('audit-management.reporting.map.lastAuditResultsWithChildren')}</Typography>
                        :
                        <Typography
                            className={classes.title}>
                            <Trans i18nKey={'audit-management.reporting.map.lastAuditWithChildren'} values={{
                                auditType
                            }}>
                                Last <span className={classes.auditMarker}>{auditType}</span> Audit (children)
                            </Trans>
                        </Typography>
                    :
                    auditType === 'ALL' ?
                        <Typography
                            className={classes.title}>{translate('audit-management.reporting.map.lastAuditResults')}</Typography>
                        :
                        <Typography
                            className={classes.title}>
                            <Trans i18nKey={'audit-management.reporting.map.lastAudit'} values={{
                                auditType
                            }}>
                                Last <span className={classes.auditMarker}>{auditType}</span> Audit
                            </Trans>
                        </Typography>
            }
        </Fragment>
    )
};

export class AuditReportingMap extends Component {

    state = {
        markersVisible: false,
        markers: [],
        dimensions: {
            width: 0,
            height: 0
        },
        allDataFetched: false
    };

    componentDidMount() {
        this.loadOrganizationLevel(this.props.organizationFilterBusinessId);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (_.isEmpty(prevProps.organizationFilterBusinessId)) {
            this.loadOrganizationLevel(this.props.organizationFilterBusinessId);
        } else if (prevProps.organizationFilterBusinessId !== this.props.organizationFilterBusinessId) {
            this.setState({
                markers: [],
                markersVisible: false,
            }, function () {
                this.loadOrganizationLevel(this.props.organizationFilterBusinessId);
            });
        }

        if (prevProps.periodStartDate !== this.props.periodStartDate ||
            prevProps.periodEndDate !== this.props.periodEndDate ||
            prevProps.auditType !== this.props.auditType ||
            prevProps.timePeriodValue !== this.props.timePeriodValue) {
            this.fetchAuditHistory();
        }
    }

    loadOrganizationLevel = (businessId) => {
        if (businessId) {
            this.props.fetchOrganizationLevel(businessId).then(() => {
                this.updateDimensions();
                this.setState({
                    allDataFetched: false
                }, this.fetchAuditHistory)
            });
        }
    };

    fetchAuditHistory = () => {
        let promise;
        if (this.props.timePeriodValue === "MONTH") {
            promise = this.fetchCurrentMonthAuditHistory();
        } else if (this.props.timePeriodValue === "PERIOD") {
            promise = this.fetchAuditHistoryForPeriod();
        } else {
            promise = this.fetchInitialAuditHistory();
        }
        promise.then(() => {
            this.setAllDataFetched()
        })
    };

    fetchInitialAuditHistory = () => {
        return this.props.fetchAuditMapStatistics({
            selectedOrganizationLevel: this.props.selectedOrganizationLevel,
            periodStartDate: moment().year(2018),
            periodEndDate: moment(),
            auditType: this.props.auditType,
        });
    };

    fetchCurrentMonthAuditHistory = () => {
        return this.props.fetchAuditMapStatistics({
            selectedOrganizationLevel: this.props.selectedOrganizationLevel,
            periodStartDate: moment().startOf('month'),
            periodEndDate: moment().endOf('month'),
            auditType: this.props.auditType,
        });
    };

    fetchAuditHistoryForPeriod = () => {
        return this.props.fetchAuditMapStatistics({
            selectedOrganizationLevel: this.props.selectedOrganizationLevel,
            periodStartDate: this.props.periodStartDate,
            periodEndDate: this.props.periodEndDate,
            auditType: this.props.auditType,
        });
    };

    setAllDataFetched = () => {
        this.setState({
            allDataFetched: true
        })
    };

    updateDimensions = () => {
        this.setState({
            dimensions: {
                width: _.get(this.container, "clientWidth", 0),
                height: _.get(this.container, "clientHeight", 0),
            },
        });
    };

    setMarkers = (markers) => {
        this.setState({markers: markers});
    };

    onMarkerClick = (level) => {
        this.setState({
            markers: [],
            markersVisible: false,
        }, function () {
            this.props.toggleFilterSelection(level.businessId);
            this.loadOrganizationLevel(level.businessId);
        });
    };

    calculateMarkerPositions = (width, height) => {
        return this.props.markers.map(marker => {
            const originalWidth = this.props.organizationLevel.map.width;
            const originalHeight = this.props.organizationLevel.map.height;
            const adjustedXCoordinate = (width / originalWidth) * marker.x;
            const adjustedYCoordinate = (height / originalHeight) * marker.y;
            return {
                ...marker,
                shape: "circle",
                coords: [adjustedXCoordinate, adjustedYCoordinate]
            }
        });
    };

    showParent = () => () => {
        this.setState({markersVisible: !this.state.markersVisible});
    };

    showChildren = () => {
        this.setState({markersVisible: !this.state.markersVisible});
    };

    getMarkerColor = (status) => {
        switch (status) {
            case 'FAILED_CRITICAL':
                return guardsmanRed;
            case 'FAILED_MINOR':
                return medium;
            case 'SUCCESSFUL':
                return christiGreen;
            case 'MISSED':
                return silver;
            default:
                return htmlWhite;
        }
    };

    render() {
        const {classes, organizationLevel, auditType, t: translate, statistics, childrenStatistics, selectedOrganizationLevel, timePeriodValue, childrenSummaryBreakdown, latestAudit} = this.props;
        const {dimensions, markersVisible, allDataFetched} = this.state;
        const {onMarkerClick, calculateMarkerPositions, setMarkers, updateDimensions, showParent, showChildren} = this;

        const width = dimensions.width;
        const height = dimensions.height;

        const isLeaf = organizationLevel.isLeaf;
        const pictureUrl = _.get(organizationLevel.picture, 'url');
        const filename = _.get(organizationLevel.picture, 'filename');

        const status = _.get(latestAudit, 'outcome');
        const numberOfQuestions = _.get(latestAudit, 'numberOfQuestions', 0);
        const numberOfFailedQuestions = _.get(latestAudit, 'numberOfFailedQuestions', 0);
        const percent = numberOfQuestions === 0 ? 0 : (numberOfFailedQuestions / numberOfQuestions) * 100;
        const latestAuditDate = _.get(latestAudit, 'date');

        return (
            <Fragment>

                <Flex container>
                    <Flex item container direction={'column'} justifyContent={'center'} alignItems={'center'}
                          className={classes.sideBar}>
                        <StatisticCircles {...{
                            selectedOrganizationLevel,
                            timePeriodValue,
                            successfulAudits: ReportingUtils.successfulAudits(statistics),
                            failedCriticalAudits: ReportingUtils.failedCriticalAudits(statistics),
                            failedMinorAudits: ReportingUtils.failedMinorAudits(statistics),
                            missedAudits: ReportingUtils.missedAudits(statistics)
                        }}/>
                    </Flex>
                    <Flex item container className={classes.center}>
                        <ReactResizeDetector handleWidth handleHeight onResize={updateDimensions}>
                            <Flex item container direction={'row'} style={{margin: 30}}>
                                <Flex item container direction={'column'} style={{}}>
                                    <Flex container item grow={0} justifyContent={'center'} style={{
                                        position: 'relative',
                                        height: 40,
                                        paddingBottom: 45
                                    }}>
                                        {
                                            markersVisible &&
                                            <Flex item grow={0} style={{
                                                position: 'absolute',
                                                top: 0,
                                                left: 0
                                            }}>
                                                <ExpandIcon {...{classes, organizationLevel, showParent}}/>
                                            </Flex>
                                        }
                                        {
                                            (!_.isEmpty(organizationLevel.map)) &&
                                            <Flex container item grow={0} alignItems={'center'} style={{
                                                position: 'absolute'
                                            }}>
                                                <Title {...{
                                                    classes,
                                                    translate,
                                                    organizationLevel,
                                                    markersVisible,
                                                    auditType
                                                }}/>
                                            </Flex>
                                        }
                                    </Flex>
                                    <div ref={el => (this.container = el)} className={classes.mainContainer}>
                                        {
                                            (isLeaf || _.isEmpty(organizationLevel.map)) && !_.isEmpty(pictureUrl) ?
                                                <Flex container item className={classes.mapContainer}>
                                                    <img src={pictureUrl} alt={filename} style={
                                                        {
                                                            maxWidth: width,
                                                            maxHeight: height,
                                                        }
                                                    }/>
                                                </Flex>
                                                :
                                                !_.isEmpty(organizationLevel.map) &&
                                                <Flex container item className={classes.mapContainer}>
                                                    {
                                                        !_.isEmpty(organizationLevel) &&
                                                        <ImageMarker src={organizationLevel.map.url}
                                                                     map={{areas: calculateMarkerPositions(width, height)}}
                                                                     width={width}
                                                                     height={height}
                                                                     opacity={'0.3'}
                                                                     onLoad={(markers) => setMarkers(markers)}
                                                        />
                                                    }
                                                    {
                                                        markersVisible &&
                                                        this.state.markers.map(marker => {
                                                            return (
                                                                <AuditReportingMapMarker
                                                                    key={marker.businessId} {...{
                                                                    marker,
                                                                    statistics: childrenSummaryBreakdown.get(marker.businessId),
                                                                    onMarkerClick,
                                                                    auditType
                                                                }} />
                                                            );
                                                        })
                                                    }
                                                </Flex>
                                        }
                                    </div>
                                </Flex>
                            </Flex>
                        </ReactResizeDetector>
                    </Flex>
                    <Flex item container direction={'column'} justifyContent={'center'} alignItems={'center'}
                          className={classes.sideBar}>
                        {
                            allDataFetched &&
                            <Fragment>
                                {
                                    !markersVisible ?
                                        <Flex container item alignItems={'center'}>
                                            <div>
                                                <Flex container item style={{
                                                    position: 'relative'
                                                }}>
                                                    <Circle percent={percent}
                                                            strokeWidth={5}
                                                            strokeColor={doveGrey}
                                                            trailWidth={5}
                                                            trailColor={htmlLightGrey}
                                                            className={classes.progressBar}
                                                    />
                                                    <span
                                                        className={classes.latestAuditCircle}
                                                        style={{
                                                            background: this.getMarkerColor(status)
                                                        }}>
                                                        <span
                                                            className={classes.number}
                                                            style={{
                                                                color: _.isEmpty(status) ? htmlGrey : htmlWhite,
                                                                fontSize: _.isEmpty(status) || numberOfFailedQuestions > 9 ? sizeOfCircle / 2.5 : sizeOfCircle / 2
                                                            }}>
                                                        {_.isEmpty(status) ? translate('global.na') : numberOfFailedQuestions === 0 ? '' : numberOfFailedQuestions}
                                                        </span>
                                                        <Typography className={classes.auditTitleText}>
                                                            <Trans
                                                                i18nKey={'audit-management.reporting.map.lastAudit'}
                                                                values={{
                                                                    auditType: auditType === 'ALL' ? '' : auditType
                                                                }}>
                                                                Last <span
                                                                className={classes.auditMarker}>{auditType}</span> Audit
                                                            </Trans>
                                                        </Typography>

                                                        {
                                                            !_.isEmpty(latestAuditDate) &&
                                                            <Typography className={classes.timeText}>
                                                                {translate('global.dateTimeFormats.month', {
                                                                    year: moment(latestAuditDate).format('YYYY'),
                                                                    month: translate('global.month.' + moment(latestAuditDate).format('MMMM')).substr(0, 3)
                                                                })}
                                                            </Typography>
                                                        }
                                                        {
                                                            auditType === 'ALL' && !_.isEmpty(latestAudit) &&
                                                            <Typography className={classes.auditTypeText}>
                                                                {latestAudit.auditType}
                                                            </Typography>
                                                        }
                                                    </span>
                                                </Flex>
                                            </div>
                                            {
                                                !_.isEmpty(organizationLevel.map) &&
                                                <Flex container item grow={0} style={{
                                                    paddingLeft: sizeOfCircle / 2
                                                }}>
                                                    <IconButton
                                                        onClick={showChildren}
                                                        style={{color: 'inherit', fontSize: 'inherit', padding: 1}}>
                                                        <MoreVertIcon className={classes.moreIcon}/>
                                                    </IconButton>
                                                </Flex>
                                            }
                                        </Flex>
                                        :
                                        <StatisticCircles {...{
                                            timePeriodValue,
                                            successfulAudits: ReportingUtils.successfulAudits(childrenStatistics),
                                            failedCriticalAudits: ReportingUtils.failedCriticalAudits(childrenStatistics),
                                            failedMinorAudits: ReportingUtils.failedMinorAudits(childrenStatistics),
                                            missedAudits: ReportingUtils.missedAudits(childrenStatistics)
                                        }}/>
                                }
                            </Fragment>
                        }
                    </Flex>
                </Flex>
            </Fragment>
        );
    }
}

export default withStyles(styles)(connect(connector.mapStateToProps, connector.mapDispatchToProps)(withTranslation()(AuditReportingMap)));
