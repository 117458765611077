import React, {Component} from 'react';
import {withStyles} from "@material-ui/core";
import {logoBlueLight, htmlWhite, htmlGrey} from 'components/colors/Colors';

const styles = theme => ({
    unSelected: {
        fontSize: 13,
        color: htmlGrey,
        textTransform: 'capitalize',
        border: `1px dashed ${logoBlueLight}`,
        margin: 3,
        padding: 3,
        borderRadius: 7,
        whiteSpace: 'nowrap',
        '&:hover': {
            cursor: 'pointer'
        }
    },
    selected: {
        backgroundColor: logoBlueLight,
        borderRadius: 7,
        color: htmlWhite,
        margin: 2,
        fontSize: 12,
        height: 25,
        fontWeight: 'bold',
        textTransform: 'capitalize',
        display: 'flex',
        alignItems: 'center',
        padding: '1px 7px 2px',
        '&:hover': {
            cursor: 'pointer'
        }
    },
});

export class Tag extends Component {
    render() {
        const {classes, toggleSelection, tag, selected, selectedColor, unSelectedColor} = this.props;
        const colorSelected = selectedColor ? selectedColor : htmlWhite;
        const colorUnSelected = unSelectedColor ? unSelectedColor : htmlGrey;
        return (
            <span className={selected ? classes.selected : classes.unSelected}
                  style={{color: selected ? colorSelected : colorUnSelected}}
                  onClick={() => toggleSelection(tag)}>
                    {tag.name}
                </span>
        )
    }
}

export default withStyles(styles)(Tag);
