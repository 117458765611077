import {fetchTaskConfiguration} from 'scenes/tasks/Tasks.action'
import {listProjects} from 'scenes/tasks/Project.action';
import {fetchActionFromServer,fetchActions} from 'scenes/tasks/actions/my-action-list/MyActionList.action';
import {createAction} from 'scenes/tasks/actions/action-edit/ActionEdit.action'
import {archiveAction, toggleDoneAction} from 'scenes/tasks/actions/action-list/ActionList.action'
import {fetchTask} from 'scenes/tasks/task-edit/TaskEdit.action'
import {removeUnseenAttachmentTag, removeUnseenCommentTag} from 'scenes/tasks/Tags.action';

export default {
    mapStateToProps: (state) => {
        return {
            actions: state.myActionListReducer.actions,
            user: state.mainReducer.user,
            forceRerender: state.tasksReducer.forceRerender,
            applicationOnline: state.mainReducer.online,
            configurationFromBackend: state.tasksReducer.configurationFromBackend,
            tagSuggestions: state.tasksReducer.configuration.freeTags,
            selectedProject: state.projectReducer.selectedProject,
            selectedUserNames: state.projectReducer.selectedUserNames,
            personalProject: state.projectReducer.personalProject,
            todoTaskBusinessId: state.tasksReducer.todoTaskBusinessId,
        };
    },
    mapDispatchToProps: (dispatch) => {
        return {
            archiveAction: action => dispatch(archiveAction(action)),
            toggleDoneAction: action => dispatch(toggleDoneAction(action)),
            createAction: (taskId, action) => dispatch(createAction(taskId, action)),
            fetchTaskConfiguration:() => dispatch(fetchTaskConfiguration()),
            listProjects: () => dispatch(listProjects()),
            removeUnseenCommentTag: task => dispatch(removeUnseenCommentTag(task)),
            removeUnseenAttachmentTag: task => dispatch(removeUnseenAttachmentTag(task)),
            fetchTask: (taskId) => dispatch(fetchTask(taskId)),
            fetchActionFromServer: (actionId) => dispatch(fetchActionFromServer(actionId)),
            fetchActions:() => dispatch(fetchActions()),
        };
    }
};
