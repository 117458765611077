import React, {Component} from 'react';
import {
    IconButton,
    Tooltip,
    Typography,
    withStyles,
} from "@material-ui/core";
import {withTranslation} from 'react-i18next';
import {
    htmlBlack,
} from 'components/colors/Colors';
import Flex from 'components/grid/Flex';
import {Link} from 'react-router-dom';
import {AUDIT_RESULT, SCHEDULED_AUDITS} from 'routes/routes';
import FinishIcon from 'assets/img/audit-finish-arrow.png';
import {CloseOutlined as CloseIcon} from '@material-ui/icons';
import {
    ArrowBackIos
} from '@material-ui/icons';

const styles = theme => ({
    counterHeader: {
        fontWeight: 'bold',
        textAlign: 'center',
        fontSize: '1.2em',
        color: 'inherit',
        '@media (min-width: 800px)': {
            fontSize: '1.8em',
        }
    },
    defaultIcon: {
        color: htmlBlack,
        fontSize: 44
    },
    finishIcon: {
        width: 40,
        height: 40,
        borderRadius: '50%',
        marginTop: 3
    },
    questionHeaderContainer: {
        height: 50,
        marginTop: -1,
        '@media (min-width: 900px)': {
            marginTop: 65,
        },
    },
    iconButtonClasses: {
        height: 30,
        width: 30,
        padding: 6,
        margin: 10,
    }
});

export class RegularQuestionHeader extends Component {
    render() {
        const {
            classes, t: translate, counter, numberOfQuestions, numberOfNegativeAnswers, previousQuestion,
            nextQuestion, isFirstQuestion, isLastQuestion, areQuestionsAnswered, showTooltip, readOnly,
            backgroundColor, textColor
        } = this.props;

        return (
            <Flex item container grow={0} alignItems={'center'} style={{backgroundColor}}
                  className={classes.questionHeaderContainer}>
                <IconButton onClick={previousQuestion} disabled={isFirstQuestion} classes={{
                    root: classes.iconButtonClasses
                }}>
                    <ArrowBackIos/>
                </IconButton>

                <Flex container item center style={{color: textColor}}>
                    <Typography className={classes.counterHeader}>
                        {counter}
                    </Typography>
                    <Typography className={classes.counterHeader} style={{marginLeft: 5, marginRight: 5}}>
                        /
                    </Typography>
                    <Typography className={classes.counterHeader} style={{marginRight: 5}}>
                        {numberOfQuestions}
                    </Typography>
                    {
                        numberOfNegativeAnswers > 0 &&
                        <Typography className={classes.counterHeader}>
                            ({numberOfNegativeAnswers})
                        </Typography>
                    }
                </Flex>

                <Tooltip open={showTooltip}
                         placement="top"
                         title={translate('audit-management.execute-audit.no-comment-warning')}>
                    <IconButton onClick={nextQuestion}
                                classes={{
                                    root: classes.iconButtonClasses
                                }}
                                component={isLastQuestion && areQuestionsAnswered() ? Link : 'button'}
                                to={readOnly ? SCHEDULED_AUDITS.path : AUDIT_RESULT.path}>
                        {isLastQuestion
                            ?
                            (
                                readOnly ?
                                    <CloseIcon className={classes.defaultIcon}/>
                                    :
                                    <img src={FinishIcon} alt={''} className={classes.finishIcon}/>
                            )
                            :
                            <ArrowBackIos style={{transform: 'rotate(180deg)'}}/>}
                    </IconButton>
                </Tooltip>

            </Flex>

        );
    }
}

export default withStyles(styles)(withTranslation()(RegularQuestionHeader));
