import React, {Component} from 'react';
import {Divider, IconButton, SwipeableDrawer, withStyles, Button} from "@material-ui/core";
import Flex from 'components/grid/Flex';
import Burger from '@material-ui/icons/Menu';
import HomeIcon from '@material-ui/icons/Home';
import {Link, withRouter} from 'react-router-dom';
import {MOBILE_LANDING, SCHEDULED_AUDITS, TASK_ADMINISTRATION} from 'routes/routes';
import Typography from "@material-ui/core/Typography";
import {Trans, withTranslation} from 'react-i18next';
import {connect} from 'react-redux';
import connector from './MobileBurgerMenu.connect';
import {Done as DoneIcon} from '@material-ui/icons';
import {sideBarColor, htmlWhite} from 'components/colors/Colors';
import {ACTIVE_VALUE, BACKLOG_VALUE, MY_ACTIONS_VALUE} from "domain/task/Tasks.model";
import DownloadIcon from "@material-ui/icons/SystemUpdate";
import Logger from 'utils/Logger';

const styles = theme => ({
    assetIcon: {
        width: 23,
        opacity: '0.54'
    },
    drawerRoot: {
        position: 'absolute',
    },
    paperRoot: {
        position: 'absolute',
        backgroundColor: sideBarColor,
        padding: 5,
        minWidth: '60%'
    },
    sectionTitle: {
        fontWeight: 'bold',
        color: htmlWhite,
        fontSize: 20,
        marginTop: 15
    },
    sectionItem: {
        marginLeft: 10,
        fontSize: 18,
        lineHeight: 2,
        color: htmlWhite,
        textDecoration: 'none'
    }
});

const TaskBurgerItem = props => {
    const {classes, i18nKey, view, location, changeFilter, filter} = props;
    const isSelected = location.pathname.includes(TASK_ADMINISTRATION.path) && filter === view;
    return <Flex container padding={5} alignItems={'center'}>
        <Typography component={Link} to={TASK_ADMINISTRATION.path} className={classes.sectionItem}
                    onClick={() => changeFilter({
                        view
                    })}
                    style={{fontWeight: isSelected ? 'bold' : ''}}>
            <Trans i18nKey={i18nKey}/>
        </Typography>
        {
            isSelected &&
            <DoneIcon style={{marginLeft: 20, fill: htmlWhite, fontSize: 18}}/>
        }
    </Flex>
};

const AuditBurgerItem = props => {
    const {classes, i18nKey, location} = props;
    const isSelected = location.pathname.includes(SCHEDULED_AUDITS.path);
    return <Flex container padding={5} alignItems={'center'}>
        <Typography component={Link} to={SCHEDULED_AUDITS.path} className={classes.sectionItem}
                    style={{fontWeight: isSelected ? 'bold' : ''}}>
            <Trans i18nKey={i18nKey}/>
        </Typography>
        {
            isSelected &&
            <DoneIcon style={{marginLeft: 20, fill: htmlWhite, fontSize: 18}}/>
        }
    </Flex>
};

export class MobileBurgerMenu extends Component {
    state = {
        isMenuOpen: false
    };

    toggleMenu = () => {
        this.setState({isMenuOpen: !this.state.isMenuOpen});
    };

    hideMenu = () => {
        this.setState({isMenuOpen: false});
    };

    openMenu = () => {
        this.setState({isMenuOpen: true});
    };

    render() {
        const {isMenuOpen} = this.state;
        const {classes, style, filters, changeFilter, location} = this.props;
        const version = process.env.REACT_APP_VERSION;
        return (
            <Flex item={'0 0 0'} container justifyContent={"flex-end"} alignItems={"center"} style={style}>
                <IconButton onClick={this.toggleMenu}>
                    <Burger/>
                </IconButton>
                <SwipeableDrawer
                    open={isMenuOpen}
                    onOpen={this.openMenu}
                    onClose={this.hideMenu}
                    anchor={'left'}
                    classes={{
                        root: classes.drawerRoot,
                        paper: classes.paperRoot
                    }}
                >
                    <div
                        tabIndex={0}
                        role="button"
                        style={{marginLeft: 10, marginRight: 30}}
                        onClick={this.hideMenu}
                        onKeyDown={this.hideMenu}
                    >
                        <IconButton component={Link} to={MOBILE_LANDING.path} style={{padding: 0, paddingTop: 12}}>
                            <HomeIcon style={{fill: htmlWhite, fontSize: '2rem'}}/>
                        </IconButton>

                        <Typography className={classes.sectionTitle}>
                            <Trans i18nKey={"routes.task.administration.title"}/>
                        </Typography>
                        <Divider style={{backgroundColor: htmlWhite}}/>

                        <TaskBurgerItem
                            {...{
                                classes,
                                view: BACKLOG_VALUE,
                                i18nKey: "task-management.tasks-backlog-tab-mobile",
                                location,
                                changeFilter,
                                filter: filters.view
                            }}
                        />

                        <TaskBurgerItem
                            {...{
                                classes,
                                view: ACTIVE_VALUE,
                                i18nKey: "task-management.tasks-kanban-tab",
                                location,
                                changeFilter,
                                filter: filters.view
                            }}
                        />

                        <TaskBurgerItem
                            {...{
                                classes,
                                view: MY_ACTIONS_VALUE,
                                i18nKey: "global.my-actions",
                                location,
                                changeFilter,
                                filter: filters.view
                            }}
                        />

                        <Typography className={classes.sectionTitle}>
                            <Trans i18nKey={"routes.audit-management.administration.title"}/>
                        </Typography>
                        <Divider style={{backgroundColor: htmlWhite}}/>

                        <AuditBurgerItem
                            {...{
                                classes,
                                i18nKey: 'global.my-audits',
                                location
                            }}
                        />
                        <Flex item container column style={{
                            position: 'absolute',
                            padding: 3,
                            bottom: 0,
                            right: 0,
                            left: 0,
                            textAlign: 'center'
                        }}>
                            <Button size={'small'} onClick={() => Logger.dumpToFile()} style={{color: htmlWhite}}>
                                    Stack trace
                            </Button>
                            <Typography variant="button"
                                        color="inherit"
                                        style={{
                                            color: htmlWhite,
                                            fontSize: 10,
                                            textAlign: 'center'
                                        }}
                            >{version}</Typography>
                        </Flex>

                    </div>
                </SwipeableDrawer>
            </Flex>
        );
    }

}

export default withStyles(styles)(connect(connector.mapStateToProps, connector.mapDispatchToProps)(withTranslation()(withRouter(MobileBurgerMenu))));
