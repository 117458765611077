import * as actionTypes from './DocumentMachineStatus.action.types'
import {
    cacheEditedDetails,
    cacheMachineDetails,
    cacheMachineStatusConfigurationData,
    cacheOrganizationLevel,
    cacheUsers
} from "./DocumentMachineStatus.cache";
import moment from 'moment';
import utils from 'utils/Utils';
import * as _ from "lodash";
import {validateApplicationStatusForCache} from "cache/Cache.utils";
import {USER_GRAPHQL} from 'domain/User.model';

export function fetchMachineStatusConfigurationData() {
    return cacheMachineStatusConfigurationData(fetchMachineStatusConfigurationDataFromServer, fetchMachineStatusConfigurationDataFromCache);
}

export function fetchMachineStatusConfigurationDataFromCache(config) {
    return {
        type: actionTypes.FETCH_MACHINE_STATUS_CONFIGURATION_DATA_FROM_CACHE,
        data: {
            cache: {
                config
            }
        }
    }
}

export function fetchMachineStatusConfigurationDataFromServer() {
    return {
        type: actionTypes.FETCH_MACHINE_STATUS_CONFIGURATION_DATA,
        payload: {
            client: 'graphql',
            request: {
                method: 'post',
                data: {
                    query: `
                            {
                                fetchMachineStatusConfigurationData {
                                    statuses {
                                        label
                                        ordinal
                                        shortLabel
                                        type
                                    }
                                }
                            }
                           `
                }
            }
        }
    };
}

export function fetchAllMachineDetails(machineIds) {
    return {
        type: actionTypes.FETCH_ALL_MACHINE_DETAILS,
        payload: {
            client: 'graphql',
            request: {
                method: 'post',
                data: {
                    query: `
                            
                                query
                                ($machineIds: [String!]!){
                                    listMachineDetails(machineIds: $machineIds) {
                                        businessId
                                        manufacturer
                                        severity {
                                            type
                                        }
                                        productOrderNumber
                                        productDueDate
                                    }
                                }
                                                       
                           `,
                    variables: {
                        "machineIds": machineIds
                    }
                }
            }
        }
    };
}

export function fetchMachineDetails(machine) {
    return cacheMachineDetails(machine, fetchMachineDetailsFromServer(machine), fetchMachineDetailsFromCache);
}

export function fetchMachineDetailsFromCache(details) {
    return {
        type: actionTypes.FETCH_MACHINE_DETAILS_FROM_CACHE,
        data: {
            cache: {
                details
            }
        }
    }
}

export function fetchMachineDetailsFromServer(machine) {
    return {
        type: actionTypes.FETCH_MACHINE_DETAILS,
        payload: {
            client: 'graphql',
            request: {
                method: 'post',
                data: {
                    query: `
                            
                                query
                                ($machineId: String!){
                                    fetchMachineDetails(machineId: $machineId) {
                                        businessId
                                        manufacturer
                                        severity {
                                            type
                                        }
                                        productOrderNumber
                                        productDueDate
                                    }
                                }
                                                       
                           `,
                    variables: {
                        "machineId": machine.businessId
                    }
                }
            }
        }
    };
}


export function fetchOrganizationLevel(levelBusinessId) {
    return cacheOrganizationLevel(levelBusinessId, fetchOrganizationLevelFromServer(levelBusinessId), fetchOrganizationLevelFromCache);
}

export function fetchOrganizationLevelFromCache(organizationLevel) {
    return {
        type: actionTypes.FETCH_ORGANIZATION_LEVEL_FROM_CACHE,
        data: {
            cache: {
                organizationLevel
            }
        }
    }

}

export function fetchOrganizationLevelFromServer(levelBusinessId) {
    return {
        type: actionTypes.FETCH_ORGANIZATION_LEVEL,
        payload: {
            client: 'graphql',
            request: {
                method: 'post',
                data: {
                    query: `
                            
                                query
                                ($levelBusinessId: String!){
                                    fetchOrganizationLevel(levelBusinessId: $levelBusinessId) {
                                        businessId
                                        shortName
                                        longName
                                        members {
                                            memberships
                                            user {
                                                businessId
                                            }
                                        }
                                        type
                                        description
                                        view {
                                            map {
                                                source {
                                                    downloadUrl
                                                }
                                                resolution {
                                                    height
                                                    width
                                                }
                                            }
                                            childViews {
                                                businessId
                                                ordinal
                                                x
                                                y
                                                statusReport {
                                                    lastKnownStatus {
                                                        reportedDate
                                                        status {
                                                            type
                                                        }
                                                    }
                                                    history {
                                                        status {
                                                            type
                                                        }
                                                    }
                                                }                                                
                                            }
                                        }
                                    }
                                }
                                                       
                           `,
                    variables: {
                        "levelBusinessId": levelBusinessId
                    }
                }
            }
        }
    };
}


export function updateMachineStatus(machineStatus) {
    return (dispatch) => {
        return dispatch({
            type: actionTypes.UPDATE_MACHINE_STATUS,
            payload: {
                client: 'graphql',
                request: {
                    method: 'post',
                    data: {
                        query: `mutation updateMachineStatus($updateMachineStatusInput: UpdateMachineStatusInput){
    	                        updateMachineStatus(updateMachineStatusInput: $updateMachineStatusInput){
      	                            machineId
    	                        }
                            }
                           `,
                        variables: {
                            updateMachineStatusInput: mapMachineForEdit(machineStatus)
                        }
                    }
                }
            }
        }).catch(response => {
            return dispatch(validateApplicationStatusForCache(cacheEditedDetails(mapMachineForEdit(machineStatus)), response));
        });
    }
}

export function synchronizeMachineStatus(machineStatus) {
    return (dispatch) => {
        return dispatch({
            type: actionTypes.UPDATE_MACHINE_STATUS,
            payload: {
                client: 'graphql',
                request: {
                    method: 'post',
                    data: {
                        query: `mutation syncMachineStatus($syncMachineStatusInput: SyncMachineStatusInput){
    	                        syncMachineStatus(syncMachineStatusInput: $syncMachineStatusInput){
      	                            machineId
    	                        }
                            }
                           `,
                        variables: {
                            syncMachineStatusInput: machineStatus
                        }
                    }
                }
            }
        }).catch(() => {
            return dispatch(cacheEditedDetails(machineStatus));
        });
    }
}

export function fetchUsers() {
    return cacheUsers(fetchUsersFromServer, fetchUsersFromCache);
}

export function fetchUsersFromCache(users) {
    return {
        type: actionTypes.FETCH_USERS_FROM_CACHE,
        data: {
            cache: {
                users
            }
        }
    }
}

export function fetchUsersFromServer() {
    return {
        type: actionTypes.FETCH_USERS,
        payload: {
            client: 'graphql',
            request: {
                method: 'post',
                data: {
                    query: `
                            {
                                listUsers {
                                     ${USER_GRAPHQL}
                                }
                             }
                           `
                }
            }
        }
    };
}

function mapMachineForEdit(machineStatus) {
    return {
        machineId: machineStatus.machineBusinessId,
        status: machineStatus.status,
        reportedDate: moment.utc(new Date()).format(utils.API_DATE_FORMAT),
        closeTasks: machineStatus.closeTasks,
        task: machineStatus.status === 'WORKING' || _.isEmpty(machineStatus.title) ? null : {
            title: machineStatus.title,
            language: {
                code: machineStatus.language
            },
            assigneeId: machineStatus.responsible.businessId,
            actions: machineStatus.actions.map(action => {
                return {
                    title: action.title,
                    language: {
                        code: machineStatus.language
                    },
                    assigneeId: action.responsible.businessId
                }
            })
        }
    };
}
