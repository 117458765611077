import {logout} from 'scenes/Main.action';

export default {
    mapStateToProps: (state) => {
        return {
        };
    },
    mapDispatchToProps: (dispatch) => {
        return {
            logout:() => dispatch(logout()),
        };
    }
};
