import * as actionTypes from './ActionList.action.types';
import taskCache from "scenes/tasks/Tasks.cache";
import {
    UNSEEN_ATTACHMENT_TAG,
    UNSEEN_COMMENT_TAG,
    UNSEEN_CONTENT_TAG
} from 'domain/Tag.model';
import _ from 'lodash';
import TaskUpdateEventBus from 'scenes/tasks/TaskUpdateEventBus';
import {editAction} from 'scenes/tasks/actions/action-edit/ActionEdit.action';

export function removeUnseenContentTag(action) {
    return dispatch => {
        return dispatch(findAndRemoveTag(action, UNSEEN_CONTENT_TAG, actionTypes.REMOVE_UNSEEN_CONTENT_TAG_TO_ACTION));
    }
}

export function removeUnseenAttachmentTag(action) {
    return dispatch => {
        return dispatch(findAndRemoveTag(action, UNSEEN_ATTACHMENT_TAG, actionTypes.REMOVE_UNSEEN_ATTACHMENT_TAG_TO_ACTION))
    }
}

export function removeUnseenCommentTag(action) {
    return dispatch => {
        return dispatch(findAndRemoveTag(action, UNSEEN_COMMENT_TAG, actionTypes.REMOVE_UNSEEN_COMMENT_TAG_TO_ACTION))
    }
}

function findAndRemoveTag(action, tagLabel, dispatchType) {
    return dispatch => {
        const unseenTagId = _.get(_.find(action.tags, ['label', tagLabel]), 'businessId');
        if (unseenTagId) {
            return dispatch(removeTag(action.businessId, unseenTagId, dispatchType))
                .then(() => {
                    TaskUpdateEventBus.update.notify({
                        taskBusinessId: action.taskBusinessId,
                        actionBusinessId: action.businessId,
                        type: dispatchType
                    })
                });
        }
    }
}

function removeTag(actionBusinessId, tagBusinessId, dispatchType) {
    return {
        type: dispatchType,
        payload: {
            client: 'graphql',
            request: {
                method: 'post',
                data: {
                    query: `mutation removeActionTag($actionBusinessId: String!, $tagBusinessId: String!){
    	                        removeActionTag(actionBusinessId: $actionBusinessId, tagBusinessId: $tagBusinessId)
                            }
                           `,
                    variables: {
                        actionBusinessId,
                        tagBusinessId
                    }
                }
            }
        }
    }
}

export function archiveAction(action) {
    return dispatch => {
        const archivedAction = {
            ...action,
            status: 'ARCHIVED',
        };
        return dispatch(editAction(archivedAction, false));
    }
}

export function toggleDoneAction(action) {
    return dispatch => {
        const doneAction = {
            ...action,
            progress: 100,
            status: 'DONE',
            finishedDate: new Date()
        };
        return dispatch(editAction(doneAction, false))
    }
}

export function synchronizeActionTagAssignment(input) {
    return (dispatch) => dispatch(
        handleActionTagAssignment(input)
    ).catch(() => {
        return dispatch(taskCache.cacheTagAssignment(input));
    });
}

export function handleActionTagAssignment(input) {
    return (dispatch) => dispatch({
        type: input.type,
        data: {
            actionBusinessId: input.actionBusinessId,
            fromSynchronization: input.fromSynchronization
        },
        payload: {
            client: 'graphql',
            request: {
                method: 'post',
                data: {
                    query: `mutation assignActionTag($actionBusinessId: String!, $tagBusinessId: String!){
    	                        assignActionTag(actionBusinessId: $actionBusinessId, tagBusinessId: $tagBusinessId)
                            }
                           `,
                    variables: {
                        actionBusinessId: input.actionBusinessId,
                        tagBusinessId: input.tagBusinessId
                    }
                }
            }
        }
    }).catch(response => {
        //TODO LM: Task-733
        console.log('Unsupported operation, todo tag addition does not work offline');
    });
}
