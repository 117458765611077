import React, {Component} from 'react';
import {Button, GridList, GridListTile, withStyles} from '@material-ui/core';
import {Link, Redirect,} from 'react-router-dom';
import {Trans, withTranslation} from 'react-i18next';
import FileGallery from "./FileGallery";
import utils from 'utils/Utils';
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from '@material-ui/icons/Close';
import {routeTo} from "routes/routes";
import Flex from "components/grid/Flex";

const styles = theme => ({});

class Gallery extends Component {
    render() {
        const {sources, parentPath, readOnly, deleteSource} = this.props;
        const isMobile = utils.checkBrowser();
        return (
            <GridList style={{padding: 5}}>
                {
                    sources.length < 1 &&
                    <Redirect to={parentPath}/>
                }
                {

                    <GridListTile key="Subheader" cols={2} style={{height: 'auto'}}>
                        {
                            isMobile ? <Button variant={'outlined'} component={Link} to={parentPath} fullWidth>
                                    <Trans i18nKey="global.back"/>
                                </Button>
                                :
                                <Flex container justifyContent={'flex-end'}>
                                    <IconButton
                                        onClick={() => {
                                            routeTo(parentPath, this.props);
                                        }}>
                                        <CloseIcon/>
                                    </IconButton>
                                </Flex>
                        }
                    </GridListTile>
                }
                <GridListTile>
                    <FileGallery
                        {...{
                            sources: sources,
                            readOnly,
                            deleteSource: deleteSource
                        }}
                    />
                </GridListTile>
            </GridList>
        );
    }
}

export default withStyles(styles)(withTranslation()(Gallery));
