import React, {Component,} from 'react';
import {Checkbox, Typography, withStyles, Tooltip} from "@material-ui/core";
import Flex from 'components/grid/Flex';
import {blueHighlight, christiGreen, greyHighlight, low, htmlWhite} from 'components/colors/Colors';
import {withTranslation} from "react-i18next";
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import classNames from 'classnames';
import {isActive} from "domain/audit/Questionnaire.model";
import {random} from "components/colors/Colors";

export const styles = theme => ({
    titleBarItem: {
        lineHeight: 2
    },
    hoverBackground: {
        backgroundColor: htmlWhite,
        transition: 'background-color 0.1s linear',
        '&:hover': {
            backgroundColor: greyHighlight
        }
    },
    checkBoxRoot: {
        padding: 0
    },
    id: {
        alignItems: 'center',
        display: 'flex'
    },
    randomizedQuestions: {
        color: random,
        paddingLeft: 3
    },
    cycle: {
        textTransform: 'uppercase'
    },
    nameContainer: {
        width: '60%'
    },
    longText: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    }
});

export class QuestionnaireTile extends Component {

    toggleActive = questionnaire => event => {
        event.preventDefault();
        event.stopPropagation();
        if (questionnaire.active && questionnaire.isAssignedToAuditSchedule) {
            this.props.openDeactivateDialog(questionnaire);
        } else {
            this.props.toggleActive(questionnaire);
        }
    };

    render() {
        const {
            classes, rootClasses, questionnaire, isSelected, handleDoubleClickOnQuestionnaire, toggleQuestionnaireSelection, t: translate, toggleAuthorizationDialogOpen,
            noAdminRights
        } = this.props;
        const {toggleActive} = this;
        return (
            <Flex item container className={classes.hoverBackground} grow={0} padding={1} alignItems={'center'}
                  onDoubleClick={handleDoubleClickOnQuestionnaire(questionnaire.businessId)}
                  onClick={toggleQuestionnaireSelection(questionnaire)}
                  style={{
                      minHeight: 40,
                      backgroundColor: isSelected ? blueHighlight : '',
                  }}
            >
                <div className={classNames(rootClasses.id, classes.id)}
                     style={{
                         borderLeft: `5px solid ${isActive(questionnaire.status) ? christiGreen : low}`,
                         paddingLeft: 15,
                         minHeight: 32
                     }}>
                    <Typography className={classes.titleBarItem}
                                style={{fontWeight: 'bold'}}
                    >{questionnaire.businessId}</Typography>
                </div>
                <div className={classNames(rootClasses.name, classes.nameContainer)}>
                    <Typography className={classNames(classes.titleBarItem, classes.longText)}
                                style={{marginRight: 5, marginLeft: 15}}>{questionnaire.name}</Typography>
                </div>
                <div className={rootClasses.catalogue}>
                    <Typography
                        className={classes.titleBarItem}>{questionnaire.catalogue}</Typography>
                </div>
                <div className={rootClasses.numberOfQuestions}>
                    <Typography className={classes.titleBarItem} style={{
                        marginLeft: 50
                    }}>{questionnaire.numberOfQuestions}
                        {
                            questionnaire.numberOfRandomizedQuestions >= 1 &&
                            <span
                                className={classes.randomizedQuestions}>({questionnaire.numberOfRandomizedQuestions})</span>
                        }
                    </Typography>
                </div>
                <div className={rootClasses.cycle}>
                    <Typography
                        className={classNames(classes.titleBarItem, classes.cycle)}>
                        {translate("audit-administration.cycle." + questionnaire.cycle).charAt(0)}</Typography>
                </div>

                <Tooltip
                    placement={"left"}
                    title={
                        questionnaire.haveQuestions
                        ? ''
                        : translate('audit-administration.questionnaireList.noQuestionsForQuestionnaire')
                    }
                >
                    <div className={rootClasses.active}>
                        <Checkbox
                            color={'primary'}
                            onChange={noAdminRights ? toggleAuthorizationDialogOpen(questionnaire) : toggleActive(questionnaire)}
                            checked={questionnaire.active}
                            disabled={!questionnaire.haveQuestions}
                            classes={{root: classes.checkBoxRoot}}
                            icon={<CheckBoxOutlineBlankIcon style={{fontSize: 25}}/>}
                            checkedIcon={<CheckBoxIcon style={{fontSize: 25}}/>}
                        />
                    </div>
                </Tooltip>

            </Flex>
        );
    }
}

export default withStyles(styles)(withTranslation()(QuestionnaireTile));
