import * as actionTypes from './Attachment.action.types';
import {validateApplicationStatusForCache} from "cache/Cache.utils";
import fileCache from 'components/file/File.cache';
import utils from 'utils/Utils';

export function uploadFile(file, id, progressEvent, referenceType) {
    return dispatch => {
        const uploadUrl = createUploadUrl(id, referenceType);
        return dispatch(upload(file, uploadUrl, progressEvent))
            .catch(originalError => {
                const offlineId = 'OFFLINE-FILE-' + utils.uuid();
                return dispatch(validateApplicationStatusForCache(fileCache.cacheFile(offlineId, file, id, referenceType), originalError))
                    .then(dispatch => {
                        return {
                            type: actionTypes.UPLOAD_FILE_TO_CACHE,
                            payload: {
                                data: {
                                    offlineId: offlineId
                                }
                            }
                        }
                    })
                    .catch((error) => {
                        console.error(error, originalError)
                    })
        });
    };
}

export function deleteFileOnUrl(url, offlineId) {
    return dispatch => {
        return dispatch({
            type: actionTypes.DELETE_FILE,
            payload: {
                client: 'empty',
                request: {
                    method: 'delete',
                    url: url,
                    contentType: 'application/json',
                }
            }
        }).catch(originalError => {
            if (offlineId) {
                return dispatch(validateApplicationStatusForCache(fileCache.deleteFile(offlineId), originalError))
                    .catch((error) => {
                        console.error(error, originalError)
                    })
            }
        })
    };
}

export function uploadFileRecord(fileRecord) {
    return dispatch => {
        const uploadUrl = createUploadUrl(fileRecord.details.referenceId, fileRecord.details.referenceType);
        return dispatch(upload(fileRecord.details.file, uploadUrl))
            .catch(originalError => {
                return dispatch(fileCache.cacheFile(fileRecord.id, fileRecord.details.file, fileRecord.details.referenceId, fileRecord.details.referenceType))
                    .catch((error) => {
                        console.error(error, originalError)
                    })
            });
    };
}

function createUploadUrl(id, referenceType) {
    return `/files?referenceId=${id}&referenceType=${referenceType}`;
}

function upload(file, url, progressEvent) {
    const formData = new FormData();
    formData.append('file', file);
    return {
        type: actionTypes.UPLOAD_FILE,
        payload: {
            client: 'api',
            request: {
                method: 'post',
                url: url,
                data: formData,
                onUploadProgress: progressEvent,
            }
        }
    };
}
