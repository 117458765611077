import React, {Component} from 'react';
import {Typography, withStyles} from "@material-ui/core";
import Flex from "components/grid/Flex";
import _ from "lodash";
import {Trans, withTranslation} from "react-i18next";
import {IndeterminateCheckBox} from '@material-ui/icons';
import ConfiguredBootstrapSelect from "components/configured-bootstrap-select/ConfiguredBootstrapSelect";
import Checkbox from "@material-ui/core/Checkbox";
import LanguageCircle from "scenes/audit-manager/administration/questions/components/LanguageCircle";
import {getStatusColor, getStatusTextColor} from "components/colors/Status";
import {
    doveGrey,
    dustyGrey,
    htmlBlack,
    htmlLightGrey,
    htmlWhite,
    materialUIBorder,
    athensGrey,
    random,
    transparentBlack004,
    transparentBlack087,
} from "components/colors/Colors";
import {PENDING} from "domain/audit/Questionnaire.model";
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import classNames from 'classnames';
import {questionnaireHeaderIndex} from 'components/zindex/zIndex';

const styles = theme => ({
    questionsHeader: {
        backgroundColor: athensGrey,
        color: htmlBlack,
        position: 'sticky',
        top: 0,
        height: 45,
        paddingRight: 20,
        zIndex: questionnaireHeaderIndex
    },
    questionsArea: {
        display: 'flex',
        flex: '1 1 auto',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        width: '45%',
        height: '64vh'
    },
    tableWrapper: {
        maxHeight: '100%',
        width: '100%',
        height: '100%',
        overflow: 'auto',
        backgroundColor: htmlWhite,
        border: `1px solid ${materialUIBorder}`,
        borderRadius: 4,
    },
    fieldTitle: {
        color: dustyGrey,
        textTransform: 'uppercase',
        fontSize: 12,
        minWidth: 250
    },
    tableTitle: {
        fontWeight: 600
    },
    checkBoxIcon: {
        fill: doveGrey
    },
    headerCheckBoxIcon: {
        fill: htmlBlack
    },
    row: {
        paddingRight: 10,
        borderBottom: `1px solid ${htmlWhite}`,
        outline: 'none',
        minHeight: 45,
        height: 'auto',
        '&:hover': {
            backgroundColor: transparentBlack004,
        }
    },
    selectedRow: {
        backgroundColor: transparentBlack004,
    },
    cell: {
        color: transparentBlack087,
        fontSize: '0.8125rem',
        fontWeight: 400,
        padding: '4px 10px',
        textTransform: 'capitalize'
    },
    header: {
        color: htmlBlack,
        fontWeight: 800,
        textTransform: 'uppercase'
    },
});

export class QuestionnaireQuestionPicker extends Component {
    render() {
        const {
            classes, catalogue, catalogues, handleCatalogueChange, selectableCatalogueQuestions, handleQuestionCheck,
            handleAllQuestionCheck, i18n, supportedLanguages, randomQuestionsByCatalogue, isVdaAudit
        } = this.props;
        const numberOfSelectedQuestions = selectableCatalogueQuestions
            .filter(question => question.status !== 'PENDING')
            .filter(question => question.selected)
            .length;

        const notPendinqSelectableCatalogueQuestions = selectableCatalogueQuestions
            .filter(question => question.status !== 'PENDING');
        const allQuestionSelected = notPendinqSelectableCatalogueQuestions
            .length !== 0 && numberOfSelectedQuestions === notPendinqSelectableCatalogueQuestions
            .length;
        const randomIsSelected = randomQuestionsByCatalogue[catalogue];
        const sortedQuestions = _.sortBy(selectableCatalogueQuestions, question => Number(question.businessId));
        return (
            <div className={classes.questionsArea}>
                <Typography className={classNames(classes.fieldTitle, classes.tableTitle)}>
                    <Trans i18nKey={"audit-administration.questionnaireEdit.questionCatalogue"}/>
                </Typography>
                <ConfiguredBootstrapSelect
                    value={catalogue}
                    onChange={handleCatalogueChange}
                    name={'catalogue'}
                    items={catalogues}
                    style={{marginBottom: 20}}
                />
                {
                    selectableCatalogueQuestions.length > 0 &&
                    <div className={classes.tableWrapper}>
                        <Flex container
                              justifyContent={'space-between'}
                              alignItems={'center'}
                              className={classes.questionsHeader}
                        >
                            <Flex item basis={'6%'} className={classNames(classes.cell, classes.header)}>
                                <Checkbox
                                    indeterminate={numberOfSelectedQuestions > 0
                                    && numberOfSelectedQuestions < notPendinqSelectableCatalogueQuestions.length}
                                    checked={allQuestionSelected}
                                    disabled={selectableCatalogueQuestions.length === 0}
                                    onChange={handleAllQuestionCheck(allQuestionSelected)}
                                    icon={<CheckBoxOutlineBlankIcon className={classes.headerCheckBoxIcon}/>}
                                    checkedIcon={<CheckBoxIcon className={classes.headerCheckBoxIcon}/>}
                                    indeterminateIcon={<IndeterminateCheckBox
                                        className={classes.headerCheckBoxIcon}/>}
                                    style={{padding: 0, paddingLeft: 6}}
                                />
                            </Flex>
                            <Flex item basis={'6%'} className={classNames(classes.cell, classes.header)}>
                                <Trans i18nKey={"audit-administration.questionnaireEdit.questions.id"}/>
                            </Flex>
                            <Flex item basis={'70%'} className={classNames(classes.cell, classes.header)}>
                                <Trans i18nKey={"audit-administration.questionnaireEdit.questions.question"}/>
                            </Flex>
                            <Flex item basis={'18%'} container alignItems={'center'}
                                  className={classNames(classes.cell, classes.header)}
                                  style={{minWidth: 120}}>
                                <Trans i18nKey={"global.translation"}/>
                            </Flex>
                        </Flex>
                        {
                            sortedQuestions.map(question => {
                                    const isItemSelected = question.selected;
                                    const translation = question.translations
                                        .find(translation => translation.language.code === i18n.language).question;
                                    const disabled = (question.status === PENDING)
                                        || (isVdaAudit && (question.answerType.label !== '0-10'));
                                    return (
                                        <Flex container
                                              key={question.businessId}
                                              onClick={disabled ? ()=>{} : handleQuestionCheck(question)}
                                              tabIndex={-1} alignItems={'center'}
                                              className={isItemSelected ? classNames(classes.row, classes.selectedRow) : classes.row}
                                              justifyContent={'space-between'}
                                        >


                                            <Flex item basis={'6%'} className={classes.cell}>
                                                <Checkbox
                                                    disabled={disabled}
                                                    checked={question.status === PENDING ? false : isItemSelected}
                                                    icon={<CheckBoxOutlineBlankIcon/>}
                                                    checkedIcon={<CheckBoxIcon className={classes.checkBoxIcon}/>}
                                                    style={{padding: 0, paddingLeft: 6}}
                                                />
                                            </Flex>

                                            <Flex item basis={'6%'} className={classes.cell}
                                                  style={{
                                                      fontWeight: 'bold',
                                                      fontSize: 14,
                                                      color: disabled
                                                          ? htmlLightGrey
                                                          : randomIsSelected && random
                                                  }}>
                                                {question.businessId}
                                            </Flex>
                                            <Flex item basis={'70%'} className={classes.cell}
                                                  style={{
                                                      color: disabled
                                                          ? htmlLightGrey
                                                          : randomIsSelected && random
                                                  }}>
                                                {translation}
                                            </Flex>
                                            <Flex item basis={'18%'} container alignItems={'center'}>
                                                {
                                                    supportedLanguages.map(languageInArray => {
                                                        const translation = question.translations
                                                            .find(translationInArray => translationInArray.language.code === languageInArray.code);
                                                        return <div key={languageInArray.code} className={classes.cell}>
                                                            <LanguageCircle
                                                                label={languageInArray.code}
                                                                color={getStatusColor(_.get(translation, 'status'))}
                                                                textColor={getStatusTextColor(_.get(translation, 'status'))}
                                                                labelStyle={{textTransform: 'uppercase'}}
                                                            />
                                                        </div>
                                                    })
                                                }
                                            </Flex>
                                        </Flex>
                                    )
                                }
                            )
                        }
                    </div>
                }
            </div>
        )
    }
}

export default withStyles(styles)(withTranslation()(QuestionnaireQuestionPicker));
