import {
    createQuestionnaire, deleteQuestionnaire,
    editQuestionnaire,
    fetchEmptyQuestionnaire,
    fetchQuestionnaire, fetchQuestions
} from "./QuestionnaireEdit.action";
import {clearFilterMultiSelection, toggleFilterMultiSelections} from "../organization-tree/OrganizationTree.action";

export default {
    mapStateToProps: (state) => {
        return {
            user: state.mainReducer.user,
            applicationOnline: state.mainReducer.online,
            multiSelectedOrganizationLevels: state.organizationTreeReducer.multiSelectedOrganizationLevels,
            cycles: state.generalConfigurationReducer.auditConfiguration.cycles,
            auditTypes: state.generalConfigurationReducer.auditConfiguration.auditTypes,
            catalogues: state.generalConfigurationReducer.auditConfiguration.catalogues,
            questionnaire: state.questionnaireEditReducer.questionnaire,
            questions: state.questionnaireEditReducer.questions,
            supportedLanguages: state.generalConfigurationReducer.auditConfiguration.supportedLanguages,
        };
    },
    mapDispatchToProps: (dispatch) => {
        return {
            createQuestionnaire: (questionnaire) => dispatch(createQuestionnaire(questionnaire)),
            editQuestionnaire: (questionnaire) => dispatch(editQuestionnaire(questionnaire)),
            fetchQuestionnaire: (questionBusinessId) => dispatch(fetchQuestionnaire(questionBusinessId)),
            fetchEmptyQuestionnaire: () => dispatch(fetchEmptyQuestionnaire()),
            fetchQuestions: () => dispatch(fetchQuestions()),
            deleteQuestionnaire: (questionnaireId) => dispatch(deleteQuestionnaire(questionnaireId)),
            toggleFilterMultiSelections: (businessIds) => dispatch(toggleFilterMultiSelections(businessIds)),
            clearFilterMultiSelection: () => dispatch(clearFilterMultiSelection()),
        };
    }
};
