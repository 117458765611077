import _ from "lodash";
import utils from 'utils/Utils';

export function getUserMembership(user, members) {
    if (_.isEmpty(members)) {
        return undefined;
    } else if(_.isEmpty(user)){
        return 'NONE';
    }
    return _.get(members.find(member => _.get(member, 'user.businessId') === user.businessId), 'membership', 'NONE');
}

export function hasAccess(membership) {
    return membership && membership !== 'NONE';
}

export function isAdmin(membership) {
    return membership === 'ADMIN';
}

export function hasEditRight(membership) {
    return isAdmin(membership) || membership === 'MEMBER';
}

export function idPrefix(task, translate) {
    return _.get(task, 'isDeviation')
        ? `${translate('global.deviation')}-`
        : `${translate('global.task')}-`;
}

export function haveMatchingStatus(statuses, itemWithStatus) {
    return statuses.findIndex(status => status.label === itemWithStatus.status.label) !== -1;
}

export function unBoundSaveLogic(goBack) {
    if (this.props && this.state) {
        const goBackBoolean = goBack === true;
        if (this.props.isTranslationChanged && this.props.shouldForceTranslateAutomatically()) {
            this.props.save(true);
            if (goBackBoolean) {
                this.props.goBack();
            }
        } else if (this.props.isTranslationChanged && this.props.isSelected) {
            this.props.routeProps.history.push(utils.joinUrl(this.props.routeProps.match.url, '/overwrite'));
            this.setState({
                goBackAfterSave: goBackBoolean
            });
        } else {
            this.props.save();
            if (goBackBoolean) {
                this.props.goBack();
            }
        }
    }
}

export function unBoundSaveWithOptionsLogic(forceTranslate) {
    if (this.props && this.state) {
        this.props.save(forceTranslate);
        this.props.routeProps.history.push(this.props.routeProps.match.url);
        if (this.state.goBackAfterSave) {
            this.props.goBack();
        }
    }
}

export default {
    getUserMembership,
    hasAccess,
    isAdmin,
    hasEditRight,
    idPrefix,
    haveMatchingStatus,
    unBoundSaveLogic,
    unBoundSaveWithOptionsLogic
}
