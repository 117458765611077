import {
    fetchTask, reset
} from 'scenes/tasks/task-edit/TaskEdit.action'

export default {
    mapStateToProps: (state) => {
        return {
            task: state.taskEditReducer.task
        };
    },
    mapDispatchToProps: (dispatch) => {
        return {
            fetchTask: (taskId) => dispatch(fetchTask(taskId)),
            reset: () => dispatch(reset()),
        };
    }
};
