import React, { Component } from 'react';
import { withStyles } from "@material-ui/core";
import {Switch, Route, Redirect} from 'react-router-dom';
import {AUDIT_CONFIGURATION_ORGANIZATION_LEVEL} from "routes/routes";
import OrganizationLevelConfiguration from "./organization-level/OrganizationLevelConfiguration";
import _ from 'lodash';

const styles = theme => ({
});

export class AuditConfiguration extends Component {
    render() {
        // const {classes} = this.props;

        return (
            <Switch>
                <Route path={AUDIT_CONFIGURATION_ORGANIZATION_LEVEL.path}
                       render={function renderOrganizationLevelConfiguration(props) {
                           return (
                                   <OrganizationLevelConfiguration
                                       {...{
                                           routeProps: props,
                                           organizationLevelBusinessId: _.get(props.match, 'params.organizationLevelBusinessId')
                                       }}
                                   />
                           )
                       }}
                />
                <Redirect to={AUDIT_CONFIGURATION_ORGANIZATION_LEVEL.path}/>
            </Switch>
        );
    }
}

export default withStyles(styles)(AuditConfiguration);
