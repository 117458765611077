import React, {Component} from 'react';
import {withStyles} from "@material-ui/core";
import Flex from 'components/grid/Flex';
import {Redirect, Route, Switch} from "react-router";
import {KPI_DOCUMENT_MACHINE_STATUS} from 'routes/routes';
import DocumentMachineStatus from "./document-machine-status/DocumentMachineStatus";

const styles = theme => ({
});

export class KpiMonitoring extends Component {
    render() {
        return (
            <Flex container direction={'column'} style={{height: '100%'}}>
                <Switch>
                    <Route path={KPI_DOCUMENT_MACHINE_STATUS.path}
                           render={function renderDocumentMachineStatus(props) {
                               return (
                                   <DocumentMachineStatus {...{routeProps: props}}/>
                               )
                           }}
                    />
                    <Redirect to={KPI_DOCUMENT_MACHINE_STATUS.path}/>
                </Switch>
            </Flex>
        );
    }
}

export default withStyles(styles)(KpiMonitoring);
