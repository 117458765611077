import unknown from 'pretty-file-icons/svg/unknown.svg';
import audio from 'pretty-file-icons/svg/audio.svg';
import video from 'pretty-file-icons/svg/video.svg';
import jpg from 'pretty-file-icons/svg/jpg.svg';
import png from 'pretty-file-icons/svg/png.svg';
import gif from 'pretty-file-icons/svg/gif.svg';
import tiff from 'pretty-file-icons/svg/tiff.svg';
import svg from 'pretty-file-icons/svg/svg.svg';
import psd from 'pretty-file-icons/svg/psd.svg';
import ai from 'pretty-file-icons/svg/ai.svg';
import dwg from 'pretty-file-icons/svg/dwg.svg';
import iso from 'pretty-file-icons/svg/iso.svg';
import mdf from 'pretty-file-icons/svg/mdf.svg';
import nrg from 'pretty-file-icons/svg/nrg.svg';
import zip from 'pretty-file-icons/svg/zip.svg';
import arj from 'pretty-file-icons/svg/arj.svg';
import rar from 'pretty-file-icons/svg/rar.svg';
import archive from 'pretty-file-icons/svg/archive.svg';
import xls from 'pretty-file-icons/svg/xls.svg';
import doc from 'pretty-file-icons/svg/doc.svg';
import pdf from 'pretty-file-icons/svg/pdf.svg';
import ppt from 'pretty-file-icons/svg/ppt.svg';
import rtf from 'pretty-file-icons/svg/rtf.svg';
import txt from 'pretty-file-icons/svg/txt.svg';
import text from 'pretty-file-icons/svg/text.svg';
import avi from 'pretty-file-icons/svg/avi.svg';
import mp2 from 'pretty-file-icons/svg/mp2.svg';
import mp3 from 'pretty-file-icons/svg/mp3.svg';
import mp4 from 'pretty-file-icons/svg/mp4.svg';
import fla from 'pretty-file-icons/svg/fla.svg';
import mxf from 'pretty-file-icons/svg/mxf.svg';
import wav from 'pretty-file-icons/svg/wav.svg';
import wma from 'pretty-file-icons/svg/wma.svg';
import aac from 'pretty-file-icons/svg/aac.svg';
import flac from 'pretty-file-icons/svg/flac.svg';
import css from 'pretty-file-icons/svg/css.svg';
import csv from 'pretty-file-icons/svg/csv.svg';
import html from 'pretty-file-icons/svg/html.svg';
import json from 'pretty-file-icons/svg/json.svg';
import js from 'pretty-file-icons/svg/js.svg';
import xml from 'pretty-file-icons/svg/xml.svg';
import dbf from 'pretty-file-icons/svg/dbf.svg';
import exe from 'pretty-file-icons/svg/exe.svg';
import prettyFileIcons from 'pretty-file-icons';
import path from 'path';

const icons = {
    unknown: unknown,
    audio: audio,
    video: video,
    jpg: jpg,
    png: png,
    gif: gif,
    tiff: tiff,
    svg: svg,
    psd: psd,
    ai: ai,
    dwg: dwg,
    iso: iso,
    mdf: mdf,
    nrg: nrg,
    zip: zip,
    arj: arj,
    rar: rar,
    archive: archive,
    xls: xls,
    xlsx: xls,
    doc: doc,
    docx: doc,
    pdf: pdf,
    ppt: ppt,
    pptx: ppt,
    rtf: rtf,
    txt: txt,
    text: text,
    avi: avi,
    mp2: mp2,
    mp3: mp3,
    mp4: mp4,
    fla: fla,
    mxf: mxf,
    wav: wav,
    wma: wma,
    aac: aac,
    flac: flac,
    css: css,
    csv: csv,
    html: html,
    json: json,
    js: js,
    xml: xml,
    dbf: dbf,
    exe: exe,
};

export const imageExtensions = [
    'png',
    'jpg',
    'bmp',
    'gif',
];

export function getIcon(fileName) {
    const iconName = getIconName(fileName);
    return icons[iconName] || unknown;
}

export function isImage(fileName) {
    const iconName = getIconName(fileName);
    return imageExtensions.some(extension => extension.includes(iconName));
}

function getIconName(fileName) {
    return prettyFileIcons.getIcon(fileName) !== 'unknown'
        ? prettyFileIcons.getIcon(fileName)
        : path.extname(fileName).toLowerCase().replace('.', '');
}
