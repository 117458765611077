import React, { Component } from 'react';
import { withStyles, LinearProgress } from "@material-ui/core";
import {christiGreen} from 'components/colors/Colors';

const styles = theme => ({
    greenProgress: {
        backgroundColor: christiGreen
    },
});

export class BlueGreenProgressBar extends Component {
    render() {
        const {classes, value, style} = this.props;

        return (
            <LinearProgress variant="determinate"
                            classes={{
                                barColorPrimary: value >= 100 && classes.greenProgress
                            }}
                            style={style}
                            value={value || 0}/>
        );
    }
}

export default withStyles(styles)(BlueGreenProgressBar);
