import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import {IconButton, Typography, withStyles} from "@material-ui/core";
import {SCHEDULED_AUDITS, TASK_ADMINISTRATION} from 'routes/routes';
import {ReactComponent as AuditManagementIcon} from 'assets/img/audit-management.svg';
import {ReactComponent as TaskManagementIcon} from 'assets/img/task-management.svg';
import {audit, auditDark, task, taskDark, htmlWhite, transparentBlack04} from 'components/colors/Colors';
import Flex from 'components/grid/Flex';
import {connect} from 'react-redux';
import connector from './MobileLanding.connect';
import {Trans, withTranslation} from 'react-i18next';

const styles = theme => ({
    gradientCircle: {
        border: `solid 6px ${htmlWhite}`,
        borderRadius: '50%',
        width: 78,
        height: 78,
        boxShadow: `0px 0px 8px 0px ${transparentBlack04}`,
    },
    counterCircle: {
        backgroundColor: htmlWhite,
        borderRadius: '50%',
        width: 25,
        height: 25,
        top: -6,
        right: -8,
        position: 'absolute'
    },
    counterText: {
        fontSize: 15,
        fontWeight: 'bold',
        marginTop: -1
    },
    title: {
        fontSize: 13,
        fontWeight: 'bold',
        display: 'inline',
        textTransform: 'uppercase'
    }
});

const MobileLandingComponent = (props) => {
    const {classes, Icon, path, colorLight, colorDark, counter, title} = props;
    return (
        <Flex container direction={'column'} item grow={0} style={{marginTop: 50}}>
            <Flex item style={{textAlign: 'center'}}>
                <IconWithCounter
                    classes={classes}
                    Icon={Icon}
                    path={path}
                    colorLight={colorLight}
                    colorDark={colorDark}
                    counter={counter}
                />
            </Flex>
            <Flex item style={{marginTop: 10}}>
                <Typography className={classes.title} style={{color: colorLight}}>{title}</Typography>
                <Typography className={classes.title}> <Trans i18nKey="landing.management.title"/></Typography>
            </Flex>
        </Flex>
    );
};

const IconWithCounter = (props) => {
    const {classes, Icon, path, colorLight, colorDark, counter} = props;
    return (
        <IconButton
            className={classes.gradientCircle}
            component={Link}
            to={path}
            style={{background: `linear-gradient(to right, ${colorLight} 1%,${colorDark} 50%,${colorLight} 50%,${colorDark} 100%)`}}
        >
            <Icon style={{fill: htmlWhite, height: 35}}/>
            {
                counter > 0 &&
                <div className={classes.counterCircle} style={{border: `solid 3px ${colorLight}`}}>
                    <Typography className={classes.counterText} style={{color: colorLight}}>{counter || 0}</Typography>
                </div>
            }
        </IconButton>
    );
};

export class MobileLanding extends Component {

    constructor(props) {
        super(props);
        this.state = {
            subscribedToWebSocket: false
        };
    }

    componentDidMount() {
        this.props.fetchCounters();
        this.subscribeToWebsocket();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.websocketClientConnected && !this.props.websocketClientConnected) {
            this.setState({subscribedToWebSocket: false});
        }
        if (!prevProps.websocketClientConnected && this.props.websocketClientConnected) {
            this.subscribeToWebsocket();
        }
    }

    componentWillUnmount() {
        this.unsubscribeFromWebsocket();
    }

    subscribeToWebsocket() {
        if (!this.state.subscribedToWebSocket && this.props.websocketClientConnected) {
            this.setState({subscribedToWebSocket: true});
            const topic = this.getTopic();
            this.props.websocketClient.subscribe(topic, (fragment) => {
                const numberOfUnseenTasks = JSON.parse(fragment.body)["unseenTasks"];
                this.props.updateCounters(numberOfUnseenTasks);
            });
        }
    }

    unsubscribeFromWebsocket() {
        if (this.state.subscribedToWebSocket && this.props.websocketClientConnected) {
            const topic = this.getTopic();
            this.setState({subscribedToWebSocket: false});
            this.props.websocketClient.unsubscribe(topic);
        }
    }

    getTopic() {
        return "/user/" + this.props.user.businessId + "/topic/task-management";
    }

    render() {
        const {classes, openAuditsCounter, unreadTasksCounter} = this.props;
        return (
            <Flex container item alignItems={'center'} direction={'column'}>
                <MobileLandingComponent
                    classes={classes}
                    Icon={AuditManagementIcon}
                    path={SCHEDULED_AUDITS.path}
                    colorLight={audit}
                    colorDark={auditDark}
                    title={<Trans i18nKey="landing.audit-management.title"/>}
                    counter={openAuditsCounter}
                />
                <MobileLandingComponent
                    classes={classes}
                    Icon={TaskManagementIcon}
                    path={TASK_ADMINISTRATION.path}
                    colorLight={task}
                    colorDark={taskDark}
                    title={<Trans i18nKey="landing.task-management.title"/>}
                    counter={unreadTasksCounter}
                />
            </Flex>
        );
    }
}

export default withStyles(styles)(connect(connector.mapStateToProps, connector.mapDispatchToProps)(withTranslation()(MobileLanding)));
