import {fetchAuditMapStatistics, fetchOrganizationLevel} from "./AuditReportingMap.actions";
import {toggleFilterSelection} from "scenes/audit-manager/administration/organization-tree/OrganizationTree.action.js";

export default {
    mapStateToProps: (state) => {
        return {
            organizationLevel: state.auditReportingMapReducer.organizationLevel,
            markers: state.auditReportingMapReducer.markers,
            statistics: state.auditReportingMapReducer.statistics,
            childrenStatistics: state.auditReportingMapReducer.childrenStatistics,
            selectedOrganizationLevel: state.organizationTreeReducer.selectedOrganizationLevel,
            organizationFilterBusinessId: state.organizationTreeReducer.organizationFilterBusinessId,
            childrenSummaryBreakdown: state.auditReportingMapReducer.childrenSummaryBreakdown,
            latestAudit:  state.auditReportingMapReducer.latestAudit,
        };
    },
    mapDispatchToProps: (dispatch) => {
        return {
            fetchOrganizationLevel: (levelBusinessId) => dispatch(fetchOrganizationLevel(levelBusinessId)),
            toggleFilterSelection: (levelBusinessId) => dispatch(toggleFilterSelection(levelBusinessId)),
            fetchAuditMapStatistics: (data) => dispatch(fetchAuditMapStatistics(data))
        };
    }
};
