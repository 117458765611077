import {
    fetchCounters, updateCounters
} from './MobileLanding.action'

export default {
    mapStateToProps: (state) => {
        return {
            openAuditsCounter: state.mobileLandingReducer.openAuditsCounter,
            unreadTasksCounter: state.mobileLandingReducer.unreadTasksCounter,
            websocketClient: state.socketReducer.client,
            websocketClientConnected: state.socketReducer.connected,
            user: state.mainReducer.user
        };
    },
    mapDispatchToProps: (dispatch) => {
        return {
            fetchCounters:() => dispatch(fetchCounters()),
            updateCounters:(numberOfUnseenTasks) => dispatch(updateCounters(numberOfUnseenTasks))
        };
    }
};
