import {christiGreen, htmlOrange, greyHighlight, gossipGreen, silver, htmlWhite} from 'components/colors/Colors';

export function getStatusColor(status) {
    if(!status){
        return gossipGreen;
    }
    switch (status) {
        case "APPROVED":
            return christiGreen;
        case "PENDING":
            return htmlOrange;
        default:
            return greyHighlight;
    }
}

export function getStatusTextColor(status) {
    if(!status){
        return silver;
    }
    switch (status) {
        case "APPROVED":
            return htmlWhite;
        case "PENDING":
            return htmlWhite;
        default:
            return silver;
    }
}
