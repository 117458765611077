import {
    string,
    arrayOf,
    shape,
} from "prop-types"
import {CHECKLIST_TRANSLATION_BACKEND, CHECKLIST_TRANSLATION_GRAPHQL} from 'domain/task/Translation.model';

export const CHECKLIST_BACKEND_PROPTYPE = {
    businessId: string,
    status: string,
    translations: arrayOf(shape(CHECKLIST_TRANSLATION_BACKEND))
};

export const CHECKLIST_GRAPHQL = `
    businessId
    status
    translations {
        ${CHECKLIST_TRANSLATION_GRAPHQL}
    }
`;

export function mapChecklist(checklistItem) {
    return {
        businessId: checklistItem.businessId,
        status: checklistItem.status,
        translations: checklistItem.translations
    }
}

export function createChecklistItemInput(entityId, text, languageCode) {
    return {
        taskBusinessId: entityId,
        status: 'NEW',
        translation: {
            text,
            language: {
                code: languageCode
            }
        }
    }
}

export function updateChecklistItemInput(checklistItem, isChecked, text, languageCode) {
    return {
        checklistItemBusinessId: checklistItem.businessId,
        status: isChecked ? 'DONE' : 'NEW',
        translation: {
            text: text,
            language: {
                code: languageCode
            }
        }
    }
}
