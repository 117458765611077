import React, {Component} from 'react';
import {Checkbox, withStyles} from "@material-ui/core";
import Flex from "components/grid/Flex";
import {Trans} from "react-i18next";
import _ from "lodash";
import Typography from "@material-ui/core/Typography";
import ConfiguredBootstrapSelect from "components/configured-bootstrap-select/ConfiguredBootstrapSelect";
import BootstrapInput from "components/bootstrap-input/BootstrapInput";
import {Loop as LoopIcon} from "@material-ui/icons";
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import NextScheduleDatePicker from "./NextScheduleDatePicker";
import {dustyGrey, christiGreen, htmlLightGrey} from "components/colors/Colors";
import moment from 'moment';
import ShiftSelect from "./ShiftSelect";
import {mapDuration} from "domain/audit/Schedule.model";

const styles = theme => ({
    inputItem: {
        padding: 7,
    },
    label: {
        color: dustyGrey,
        textTransform: 'uppercase',
        fontSize: 12,
    },
    checkBoxIcon: {
        fill: christiGreen
    },
});

export class ScheduleTimeSelectors extends Component {

    state = {
        durationUnits: []
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        const duration = this.props.schedule.duration;
        if (prevProps.schedule.duration !== duration) {
            const durationUnits = this.props.durationUnits;
            const durationToFind = mapDuration(duration);
            this.setState({
                durationUnits: durationUnits.slice(durationUnits.indexOf(durationToFind), durationUnits.length)
            })
        }
    }

    render() {
        const {
            classes, schedule, handleScheduleChange, selectedDate, handleDateChange, handleFrequencyCheck,
            oneTimeOnly, handleFrequencyChange, isEditDisabled, originalOneTimeOnly, shifts, handleShiftChange
        } = this.props;
        const {durationUnits} = this.state;
        const isToday = moment(selectedDate).isSame(new Date(), 'day');
        return (
            <Flex container item direction={'column'} basis={'50%'}>
                <Flex container>
                    <div className={classes.inputItem} style={{width: 325}}>
                        <Typography className={classes.label}><Trans
                            i18nKey={"audit-administration.scheduleEdit.duration"}/>
                        </Typography>
                        <ConfiguredBootstrapSelect
                            value={_.get(schedule, 'duration', '')}
                            onChange={handleScheduleChange}
                            disabled={isEditDisabled}
                            name={'duration'}
                            items={['DAY', 'WEEK', 'MONTH', 'SHIFT']}
                            translationPath={'audit-administration.duration'}
                        />
                    </div>
                    {
                        'SHIFT' === _.get(schedule, 'duration', '') &&
                        <div className={classes.inputItem} style={{width: 325}}>
                            <Typography className={classes.label} style={{opacity: 0}}><Trans
                                i18nKey={"audit-administration.scheduleEdit.duration"}/>
                            </Typography>
                            <ShiftSelect
                                value={_.get(schedule, 'shift', '')}
                                onChange={handleShiftChange}
                                disabled={isEditDisabled}
                                name={'shift'}
                                shifts={shifts}
                            />
                        </div>
                    }
                </Flex>
                <div className={classes.inputItem} style={{width: 325}}>
                    <Typography className={classes.label}><Trans
                        i18nKey={"audit-administration.scheduleEdit.nextTime"}/>
                    </Typography>
                    <NextScheduleDatePicker {...{
                        frequency: _.get(schedule.frequency, 'unit'),
                        duration: schedule.duration,
                        selectedDate,
                        handleDateChange,
                        isEditDisabled
                    }}/>
                </div>
                {
                    <div className={classes.inputItem} style={{width: 325}}>
                        <Typography className={classes.label}><Trans
                            i18nKey={"audit-administration.scheduleEdit.frequency"}/>
                        </Typography>
                        {
                            (originalOneTimeOnly || (!schedule.businessId && 'SHIFT' !== _.get(schedule, 'duration', ''))) &&
                            <Flex container alignItems={'center'}>
                                <Checkbox
                                    checked={oneTimeOnly}
                                    disabled={isEditDisabled}
                                    onChange={handleFrequencyCheck}
                                    icon={<CheckBoxOutlineBlankIcon style={{fontSize: 30}}/>}
                                    checkedIcon={<CheckBoxIcon style={{fontSize: 30}} className={classes.checkBoxIcon}/>}
                                />
                                <Typography><Trans i18nKey={"audit-administration.scheduleEdit.oneTimeOnly"}/></Typography>
                            </Flex>
                        }
                    </div>
                }
                {
                    !oneTimeOnly &&
                    <div className={classes.inputItem} style={{width: 325, marginTop: -10}}>
                        <Flex container alignItems={'center'}>
                            <Flex container grow={0} shrink={0} basis={'5%'} style={{paddingLeft: 15}}>
                                <LoopIcon
                                    style={{
                                        fontSize: 25,
                                        color: htmlLightGrey,
                                    }}/>
                            </Flex>
                            <Flex item basis={'25%'} style={{paddingLeft: 15}}>
                                <BootstrapInput
                                    value={_.get(schedule.frequency, 'amount', '')}
                                    disabled={isEditDisabled}
                                    onChange={handleFrequencyChange}
                                    name={'amount'}
                                    variant="outlined"
                                    type={'number'}
                                />
                            </Flex>
                            <Flex item basis={'60%'} style={{paddingLeft: 15}}>
                                <ConfiguredBootstrapSelect
                                    value={_.get(schedule.frequency, 'unit', '')}
                                    disabled={isEditDisabled}
                                    onChange={handleFrequencyChange}
                                    name={'unit'}
                                    items={durationUnits}
                                    translationPath={'audit-administration.duration'}
                                />
                            </Flex>
                        </Flex>
                    </div>
                }
                {
                    oneTimeOnly && isToday &&
                    <div className={classes.inputItem} style={{width: 325, marginTop: -10}}>
                        <Flex container alignItems={'center'}>
                            <Typography>
                                <Trans i18nKey={"audit-administration.scheduleEdit.instantScheduleWarning"}/>
                            </Typography>
                        </Flex>
                    </div>
                }
            </Flex>
        );
    }
}

export default withStyles(styles)(ScheduleTimeSelectors);
