import React, {Component, Fragment} from 'react';
import Questions from './questions/Questions'
import Flex from 'components/grid/Flex';
import {Redirect, Route, Switch, withRouter} from "react-router";
import {
    AUDIT_ADMINISTRATION_MONITORING,
    AUDIT_ADMINISTRATION_QUESTION_CREATE,
    AUDIT_ADMINISTRATION_QUESTION_EDIT,
    AUDIT_ADMINISTRATION_QUESTIONNAIRE_CREATE,
    AUDIT_ADMINISTRATION_QUESTIONNAIRE_EDIT,
    AUDIT_ADMINISTRATION_QUESTIONNAIRES,
    AUDIT_ADMINISTRATION_QUESTIONS,
    AUDIT_ADMINISTRATION_SCHEDULES,
    AUDIT_ADMINISTRATION_SCHEDULE_CREATE,
    AUDIT_ADMINISTRATION_SCHEDULE_EDIT,
} from "routes/routes";
import {Trans, withTranslation} from "react-i18next";
import {Link} from 'react-router-dom';
import Questionnaires from "./questionnaires/Questionnaires";
import QuestionEdit from "./question-edit/QuestionEdit";
import QuestionnaireEdit from "./questionnaire-edit/QuestionnaireEdit";
import _ from "lodash";
import Schedules from "./schedules/Schedules";
import Monitoring from "./monitoring/Monitoring";
import ScheduleEdit from "./schedule-edit/ScheduleEdit";
import {connect} from "react-redux";
import connector from "./AuditAdministration.connect";
import H4Tabs from 'components/h4-tabs/H4Tabs';
import H4Tab from 'components/h4-tabs/H4Tab';
import OrganizationTree from "./organization-tree/OrganizationTree";
import {withStyles} from "@material-ui/core";
import {treeIndex} from 'components/zindex/zIndex'

const styles = theme => ({
    workspace: {
        width: '100%',
        height: '100%',
        position: 'relative',
        zIndex: treeIndex + 1,
        marginLeft: 10
    }
});

const OrganizationTreeSection = (props) => {
    const {location, questionnaireEditCallback} = props;

    return (
        <Fragment>
            {
                (() => {
                    switch (location.pathname) {
                        case AUDIT_ADMINISTRATION_QUESTIONNAIRES.path:
                            return (
                                <OrganizationTree {...{
                                    multiSelect: false,
                                    showOrganizationTitle: true,
                                    questionnaireEditCallback
                                }}
                                />);
                        case AUDIT_ADMINISTRATION_SCHEDULES.path:
                            return (
                                <OrganizationTree {...{
                                    multiSelect: false,
                                    showOrganizationTitle: true
                                }}
                                />);
                        case AUDIT_ADMINISTRATION_MONITORING.path:
                            return (
                                <OrganizationTree {...{
                                    multiSelect: true,
                                    showOrganizationTitle: false
                                }}
                                />);
                        default :
                            return (
                                <Fragment/>
                            )
                    }
                })()
            }
        </Fragment>
    )
};


export class AuditAdministration extends Component {

    state = {
        questionEditCallback: undefined,
        questionnaireEditCallback: undefined,
        scheduleEditCallBack: undefined
    };

    registerQuestionEditCallback = (callback) => {
        this.setState({questionEditCallback: callback});
        callback.then().catch().then(() => {
            this.setState({questionnaireEditCallback: undefined});
        })
    };

    registerQuestionnaireEditCallback = (callback) => {
        this.setState({questionnaireEditCallback: callback});
        callback.then().catch().then(() => {
            this.setState({questionnaireEditCallback: undefined});
        })
    };

    registerScheduleEditCallback = (callback) => {
        this.setState({scheduleEditCallBack: callback});
        callback.then().catch().then(() => {
            this.setState({scheduleEditCallBack: undefined});
        })
    };

    render() {
        const {isAuditConfigLoaded, classes, location, organizationTreeInitialized} = this.props;
        const {registerQuestionEditCallback, registerQuestionnaireEditCallback, registerScheduleEditCallback} = this;
        const {questionEditCallback, questionnaireEditCallback, scheduleEditCallBack} = this.state;
        return (
            <Flex container style={{height: '100%'}}>
                <OrganizationTreeSection {...{location, questionnaireEditCallback}}/>
                <Flex item grow={0} container direction={'column'} className={classes.workspace}>
                    <Route
                        path={[
                            AUDIT_ADMINISTRATION_QUESTIONS.path,
                            AUDIT_ADMINISTRATION_QUESTIONNAIRES.path,
                            AUDIT_ADMINISTRATION_SCHEDULES.path,
                            AUDIT_ADMINISTRATION_MONITORING.path,
                        ]}
                        render={function renderTabs(props) {
                            return (
                                <Flex item={'0 0 0'} style={{marginLeft: 20, marginRight: 20, marginTop: 10}}>
                                    <H4Tabs
                                        value={props.location.pathname}
                                    >
                                        <H4Tab
                                            value={AUDIT_ADMINISTRATION_QUESTIONS.path}
                                            label={<Trans i18nKey="audit-administration.questions"/>}
                                            component={Link}
                                            to={AUDIT_ADMINISTRATION_QUESTIONS.path}
                                        />
                                        <H4Tab
                                            value={AUDIT_ADMINISTRATION_QUESTIONNAIRES.path}
                                            label={<Trans i18nKey="audit-administration.questionnaires"/>}
                                            component={Link}
                                            to={AUDIT_ADMINISTRATION_QUESTIONNAIRES.path}
                                        />
                                        <H4Tab
                                            value={AUDIT_ADMINISTRATION_SCHEDULES.path}
                                            label={<Trans i18nKey="audit-administration.schedule"/>}
                                            component={Link}
                                            to={AUDIT_ADMINISTRATION_SCHEDULES.path}
                                        />
                                        <H4Tab
                                            value={AUDIT_ADMINISTRATION_MONITORING.path}
                                            label={<Trans i18nKey="audit-administration.monitoring"/>}
                                            component={Link}
                                            to={AUDIT_ADMINISTRATION_MONITORING.path}
                                        />
                                    </H4Tabs>
                                </Flex>
                            )
                        }}
                    />
                    <Switch>
                        <Route path={AUDIT_ADMINISTRATION_QUESTIONS.path}
                               render={function renderQuestions(props) {
                                   return (<Questions {...{questionEditCallback}}/>)
                               }}
                        />
                        <Route path={AUDIT_ADMINISTRATION_QUESTIONNAIRES.path}
                               render={function renderQuestionnaires(props) {
                                   return (<Questionnaires {...{questionnaireEditCallback}}/>)
                               }}
                        />
                        <Route path={AUDIT_ADMINISTRATION_SCHEDULES.path}
                               render={function renderSchedule(props) {
                                   return (<Schedules {...{scheduleEditCallBack}}/>)
                               }}
                        />
                        <Route path={AUDIT_ADMINISTRATION_MONITORING.path}
                               render={function renderMonitoring(props) {
                                   return (<Monitoring/>)
                               }}
                        />
                        <Route path={AUDIT_ADMINISTRATION_QUESTION_CREATE.path}
                               render={function renderQuestionCreate(props) {
                                   return (isAuditConfigLoaded &&
                                           <QuestionEdit
                                               {...{
                                                   routeProps: props,
                                                   registerQuestionEditCallback
                                               }}
                                           />
                                   )
                               }}
                        />
                        <Route path={AUDIT_ADMINISTRATION_QUESTION_EDIT.path}
                               render={function renderQuestionEdit(props) {
                                   return (
                                           <QuestionEdit
                                               {...{
                                                   routeProps: props,
                                                   questionBusinessId: _.get(props.match, 'params.questionBusinessId'),
                                                   registerQuestionEditCallback
                                               }}
                                           />
                                   )
                               }}
                        />
                        <Route path={AUDIT_ADMINISTRATION_QUESTIONNAIRE_CREATE.path}
                               render={function renderQuestionnaireCreate(props) {
                                   return (
                                       <Flex item container>
                                           <OrganizationTree {...{multiSelect: true}}/>
                                           <QuestionnaireEdit
                                               {...{
                                                   routeProps: props,
                                                   registerQuestionnaireEditCallback
                                               }}
                                           />
                                       </Flex>
                                   )
                               }}
                        />
                        <Route path={AUDIT_ADMINISTRATION_QUESTIONNAIRE_EDIT.path}
                               render={function renderQuestionnaireEdit(props) {
                                   return (
                                       <Flex item container>
                                           <OrganizationTree {...{multiSelect: true}}/>
                                           {
                                               organizationTreeInitialized &&
                                               <QuestionnaireEdit
                                                   {...{
                                                       routeProps: props,
                                                       questionnaireBusinessId: _.get(props.match, 'params.questionnaireBusinessId'),
                                                       registerQuestionnaireEditCallback
                                                   }}
                                               />
                                           }
                                       </Flex>
                                   )
                               }}
                        />
                        <Route path={AUDIT_ADMINISTRATION_SCHEDULE_CREATE.path}
                               render={function renderScheduleCreate(props) {
                                   return (<ScheduleEdit
                                               {...{
                                                   routeProps: props,
                                                   registerScheduleEditCallback
                                               }}
                                           />
                                   )
                               }}
                        />
                        <Route path={AUDIT_ADMINISTRATION_SCHEDULE_EDIT.path}
                               render={function renderScheduleEdit(props) {
                                   return (<ScheduleEdit
                                               {...{
                                                   routeProps: props,
                                                   scheduleBusinessId: _.get(props.match, 'params.scheduleBusinessId'),
                                                   registerScheduleEditCallback
                                               }}
                                           />
                                   )
                               }}
                        />
                        <Redirect to={AUDIT_ADMINISTRATION_QUESTIONS.path}/>
                    </Switch>
                </Flex>
            </Flex>
        )
    }
}

export default withTranslation()(connect(connector.mapStateToProps, connector.mapDispatchToProps)(withRouter(withStyles(styles)(AuditAdministration))));
