import {
    removeUnseenCommentTag,
    removeUnseenAttachmentTag,
    archiveAction
} from 'scenes/tasks/actions/action-list/ActionList.action';
import {createAction} from 'scenes/tasks/actions/action-edit/ActionEdit.action';
import {fetchTask} from 'scenes/tasks/task-edit/TaskEdit.action';

export default {
    mapStateToProps: (state) => {
        return {
            dueDate: state.taskEditReducer.task.dueDate,
            taskBusinessId: state.taskEditReducer.task.businessId,
            actions: state.taskEditReducer.task.actions,
            applicationOnline: state.mainReducer.online,
            user: state.mainReducer.user,
            supportedLanguages: state.tasksReducer.configurationFromBackend.supportedLanguages
        };
    },
    mapDispatchToProps: (dispatch) => {
        return {
            createAction: (taskId, action) => dispatch(createAction(taskId, action)),
            removeUnseenCommentTag: action => dispatch(removeUnseenCommentTag(action)),
            removeUnseenAttachmentTag: action => dispatch(removeUnseenAttachmentTag(action)),
            fetchTask: (taskId) => dispatch(fetchTask(taskId)),
            archiveAction: action => dispatch(archiveAction(action)),
        };
    }
};
