import React, {Component, Fragment} from 'react';
import Flex from 'components/grid/Flex';
import {
    greyHighlight,
    transparentBlack03,
    transparentBlack087,
    htmlWhite,
    logoBlueLight,
    dustyGrey
} from 'components/colors/Colors';
import utils from 'utils/Utils';
import LogoutIcon from '@material-ui/icons/ExitToApp';
import BackupIcon from '@material-ui/icons/Backup';
import WifiIcon from '@material-ui/icons/Wifi';
import WifiOffIcon from '@material-ui/icons/WifiOff';
import {
    ClickAwayListener,
    Typography,
    Divider,
    Tooltip,
    withStyles,
} from '@material-ui/core';
import {profileContainer} from 'components/zindex/zIndex';
import {withTranslation} from "react-i18next";
import H4Avatar from 'components/avatar/H4Avatar'
import RefreshIcon from "@material-ui/icons/Refresh";
import DownloadIcon from "@material-ui/icons/SystemUpdate";
import FeatureSwitch from 'utils/FeatureSwitch';
import LanguageSelector from 'components/language-selector/LanguageSelector';
import {connect} from 'react-redux';
import connector from './Profile.connect';
import Logger from 'utils/Logger';

const styles = theme => ({
    profile: {
        position: 'absolute',
        boxShadow: `0 0 5px 0 ${transparentBlack03}`,
        left: 50,
        bottom: 0,
        color: transparentBlack087,
        backgroundColor: htmlWhite,
        fontSize: 13,
        fontFamily: 'Arial',
        fontWeight: 400,
        lineHeight: 1.5,
        letterSpacing: 0.01071,
        zIndex: profileContainer,
        width: 'auto',
        paddingTop: 5
    },
    profileHeader: {
        padding: 12,
        borderBottom: '1.5px solid',
        borderBottomColor: greyHighlight
    },
    profileContent: {
        paddingTop: 5,
        paddingBottom: 5,
        paddingRight: 9,
        backgroundColor: htmlWhite,
        '&:hover': {
            backgroundColor: greyHighlight,
            cursor: 'pointer'
        },
        whiteSpace: 'nowrap'
    },
    inactiveProfileContent: {
        paddingTop: 5,
        paddingBottom: 5,
        paddingRight: 9,
        backgroundColor: htmlWhite,
        color: dustyGrey,
        whiteSpace: 'nowrap'
    },
    avatar: {
        width: 30,
        height: 30,
        margin: 10,
        '&:hover': {
            border: `2px solid ${logoBlueLight}`,
            cursor: 'pointer'
        }
    },
    profileAvatar: {
        width: 50,
        height: 50,
        marginBottom: 5
    },
    icon: {
        width: 29,
        height: 18,
        margin: 4
    },
    username: {
        display: 'inline',
        textTransform: 'capitalize',
        fontWeight: 'bold'
    },
    email: {
        display: 'inline',
        whiteSpace: 'nowrap'
    },
    tooltip: {
        fontSize: 16,
        maxWidth: 400
    },
    version: {
        color: dustyGrey,
        fontSize: 12,
        textAlign: 'center'
    },
    buttonText: {
        textTransform: 'none',
        '&:hover': {
            cursor: 'pointer'
        },
    }
});


export class Profile extends Component {

    state = {showProfile: false};

    toggleProfile = () => {
        this.setState({showProfile: !this.state.showProfile})
    };
    hideProfile = () => {
        this.setState({showProfile: false})
    };

    render() {
        const {
            classes, t: translate, user, logout, synchronize, positionStyle, online, toggleOnline,
            stableServerConnection
        } = this.props;
        const {showProfile} = this.state;

        return (
            <ClickAwayListener onClickAway={this.hideProfile}>
                <div>

                    <H4Avatar {...{user: user}} classes={{avatarRoot: classes.avatar}} onClick={this.toggleProfile}/>
                    {
                        showProfile &&
                        <Flex container direction={'column'} className={classes.profile} style={positionStyle}>
                            <Flex item container
                                  justifyContent={"center"}
                                  alignItems={"center"}
                                  direction={'column'}
                                  style={{padding: 12}}
                            >
                                <H4Avatar {...{user: user}} classes={{avatarRoot: classes.profileAvatar}}/>
                                <span className={classes.username}>{user.name}</span>
                                <span className={classes.email}>{user.email}</span>
                            </Flex>
                            {
                                FeatureSwitch.versionInProfile.active &&
                                <span className={classes.version}>v.{process.env.REACT_APP_VERSION}</span>
                            }
                            <Divider/>
                            <Flex item container
                                  alignItems={"center"}
                                  className={classes.profileContent}
                                  style={{paddingLeft: 9}}
                            >
                                <LanguageSelector/>
                            </Flex>
                            <Divider/>
                            <Tooltip title={translate("login.refreshWarning")}
                                     classes={{tooltip: classes.tooltip}}
                                     placement={"left"}>
                                <Flex item container
                                      justifyContent={"flex-start"}
                                      alignItems={"center"}
                                      onClick={utils.refreshApplication}
                                      className={classes.profileContent}>
                                    <RefreshIcon className={classes.icon}/>
                                    <Typography className={classes.buttonText}>
                                        {translate("login.refresh")}
                                    </Typography>
                                </Flex>
                            </Tooltip>
                            <Divider/>
                            <Flex item container
                                  justifyContent={"flex-start"}
                                  alignItems={"center"}
                                  onClick={() => Logger.dumpToFile()}
                                  className={classes.profileContent}>
                                <DownloadIcon className={classes.icon}/>
                                <Typography className={classes.buttonText}>
                                    Stack trace
                                </Typography>
                            </Flex>
                            {
                                FeatureSwitch.manualSync.active &&
                                <Fragment>
                                    <Divider/>
                                    <Flex item container
                                          justifyContent={"flex-start"}
                                          alignItems={"center"}
                                          onClick={() => synchronize()}
                                          className={classes.profileContent}>
                                        <BackupIcon className={classes.icon}/>
                                        <Typography className={classes.buttonText}>
                                            {translate("global.synchronize")}
                                        </Typography>

                                    </Flex>
                                </Fragment>
                            }
                            <>
                                <Divider/>
                                {
                                    !online && !stableServerConnection
                                        ?
                                        <Flex item container
                                              justifyContent={"flex-start"}
                                              alignItems={"center"}
                                              className={classes.inactiveProfileContent}
                                        >
                                            <WifiIcon className={classes.icon}/>
                                            <Typography style={{textTransform: 'none'}}>
                                                {translate("global.goOnline")} ({translate("global.no-server-connection")})
                                            </Typography>
                                        </Flex>
                                        :
                                        <Flex item container
                                              justifyContent={"flex-start"}
                                              alignItems={"center"}
                                              onClick={() => toggleOnline()}
                                              className={classes.profileContent}>
                                            {
                                                online
                                                    ? <WifiOffIcon className={classes.icon}/>
                                                    : <WifiIcon className={classes.icon}/>
                                            }
                                            <Typography className={classes.buttonText}>
                                                {
                                                    online
                                                        ? translate("global.goOffline")
                                                        : translate("global.goOnline")
                                                }
                                            </Typography>
                                        </Flex>
                                }
                            </>
                            <Divider/>
                            <Flex item container
                                  justifyContent={"flex-start"}
                                  alignItems={"center"}
                                  onClick={logout}
                                  className={classes.profileContent}>
                                <LogoutIcon className={classes.icon}/>
                                <Typography className={classes.buttonText}>
                                    {translate("login.logout")}
                                </Typography>
                            </Flex>
                        </Flex>

                    }
                </div>
            </ClickAwayListener>
        )
    }
}

export default withStyles(styles, {withTheme: true})(connect(connector.mapStateToProps, connector.mapDispatchToProps)(withTranslation()(Profile)));
