import '@babel/polyfill';
import React, {Suspense} from 'react';
import ReactDOM from 'react-dom';
import {Router, Route} from 'react-router';
import history from 'utils/history';
import {Provider} from 'react-redux';
import store from 'store/Store';
import Main from 'scenes/Main';
import * as serviceWorker from './serviceWorker';
import Immutable from 'immutable';
import 'i18n/i18n';
import H4Theme from "components/h4-theme/H4Theme";
import CssBaseline from '@material-ui/core/CssBaseline';
import utils from 'utils/Utils.js';
import Logger from 'utils/Logger';

Logger.initialize();

// See more: https://github.com/facebook/draft-js/issues/950
Immutable.Iterable.noLengthWarning = true;

const Loader = () => (
    <div>Loading...</div>
);

ReactDOM.render(
    <Provider store={store}>
        <Suspense fallback={<Loader/>}>
            <H4Theme>
                <CssBaseline/>
                <Router history={history}>
                    <Route to={'/'} component={Main}/>
                </Router>
            </H4Theme>
        </Suspense>
    </Provider>
    ,
    document.getElementById('h4-frontend-root')
);

utils.registerPageReloadOnServiceWorkerControllerChange();
serviceWorker.register();
