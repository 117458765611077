import React, {Component} from 'react';
import {Divider, withStyles} from "@material-ui/core";
import {connect} from "react-redux";
import connector from "./OrganizationLevelConfiguration.connect";
import {Trans, withTranslation} from "react-i18next";
import {withRouter} from "react-router";
import _ from "lodash";
import Flex from "components/grid/Flex";
import {htmlWhite, alabasterWhite, htmlGrey} from "components/colors/Colors";
import Typography from "@material-ui/core/Typography";
import {
    routeTo,
    AUDIT_ADMINISTRATION,
    AUDIT_CONFIGURATION_ORGANIZATION_LEVEL_USERS,
    AUDIT_CONFIGURATION_ORGANIZATION_LEVEL_TIMEZONE,
    AUDIT_CONFIGURATION_ORGANIZATION_LEVEL_IMAGE,
    AUDIT_CONFIGURATION_ORGANIZATION_LEVEL_MAP

} from "routes/routes";
import {Switch, Route, Redirect} from 'react-router-dom';
import UserAssignment from './users/UserAssignment';
import Timezone from './timezone/Timezone';
import Map from './map/Map';
import Image from "./image/Image";
import classNames from 'classnames';

const styles = theme => ({
    paper: {
        backgroundColor: htmlWhite,
        height: '100%'
    },
    title: {
        padding: 30,
        fontSize: 25,
        fontWeight: 'bold'
    },
    controlButtons: {
        minWidth: 150,
        marginTop: 15,
        marginLeft: 10
    },
    sidebar: {
        backgroundColor: alabasterWhite,
        width: 220
    },
    menuHeader: {
        fontWeight: 800,
        padding: 30,
        marginBottom: 30
    },
    menuItem: {
        padding: 10,
        paddingLeft: 30,
        paddingRight: 30,
        cursor: 'pointer',
        '&:hover': {
            background: `linear-gradient(90deg, ${htmlGrey} 3%, transparent 0%)`,
        }
    },
    active: {
        background: `linear-gradient(90deg, ${htmlGrey} 3%, transparent 0%)`,
        fontWeight: 600,
    }
});


export class OrganizationLevelConfiguration extends Component {

    componentDidMount() {
        if (!_.isEmpty(this.props.organizationLevelBusinessId)) {
            this.props.fetchOrganizationLevel(this.props.organizationLevelBusinessId);
        }
    }

    goBack = () => {
        routeTo(this.props.prevPath || AUDIT_ADMINISTRATION.path, this.props.routeProps);
    };

    goToSubPage = path => {
        const route = path.pathWithId(this.props.organizationLevel.businessId);
        routeTo(route, this.props);
    };

    isMenuItemSelected = path => {
        return path.pathWithId(this.props.organizationLevel.businessId) === this.props.location.pathname;
    };

    render() {
        const {classes, organizationLevel} = this.props;
        const {isMenuItemSelected} = this;
        return (
            <>
                {
                    !_.isEmpty(organizationLevel) &&
                    <Flex item container className={classes.paper} direction={'column'}>
                        <Typography className={classes.title}>{organizationLevel.shortName}</Typography>
                        <Divider/>
                        <Flex item container>
                            <Flex item grow={0} container direction={'column'} className={classes.sidebar}>
                                <div className={classes.menuHeader}>
                                    <Trans i18nKey={"audit-management.node-configuration.menuHeader"}/>
                                </div>
                                <div className={
                                    isMenuItemSelected(AUDIT_CONFIGURATION_ORGANIZATION_LEVEL_USERS)
                                        ? classNames(classes.menuItem, classes.active)
                                        : classes.menuItem
                                }
                                     onClick={() => this.goToSubPage(AUDIT_CONFIGURATION_ORGANIZATION_LEVEL_USERS)}>
                                    <Trans i18nKey={"audit-management.node-configuration.menuItems.members"}/>
                                </div>
                                <div className={
                                    isMenuItemSelected(AUDIT_CONFIGURATION_ORGANIZATION_LEVEL_TIMEZONE)
                                        ? classNames(classes.menuItem, classes.active)
                                        : classes.menuItem
                                }
                                     onClick={() => this.goToSubPage(AUDIT_CONFIGURATION_ORGANIZATION_LEVEL_TIMEZONE)}>
                                    <Trans i18nKey={"audit-management.node-configuration.menuItems.timezone"}/>
                                </div>
                                <div className={
                                    isMenuItemSelected(AUDIT_CONFIGURATION_ORGANIZATION_LEVEL_IMAGE)
                                        ? classNames(classes.menuItem, classes.active)
                                        : classes.menuItem
                                }
                                     onClick={() => this.goToSubPage(AUDIT_CONFIGURATION_ORGANIZATION_LEVEL_IMAGE)}>
                                    <Trans i18nKey={"audit-management.node-configuration.menuItems.image"}/>
                                </div>
                                <div className={
                                    isMenuItemSelected(AUDIT_CONFIGURATION_ORGANIZATION_LEVEL_MAP)
                                        ? classNames(classes.menuItem, classes.active)
                                        : classes.menuItem
                                }
                                     onClick={() => this.goToSubPage(AUDIT_CONFIGURATION_ORGANIZATION_LEVEL_MAP)}>
                                    <Trans i18nKey={"audit-management.node-configuration.menuItems.map"}/>
                                </div>
                            </Flex>
                            <Flex item container style={{position: 'relative'}}>
                                {
                                    <Switch>
                                        <Route path={AUDIT_CONFIGURATION_ORGANIZATION_LEVEL_USERS.path}
                                               render={function renderUserAssignment(props) {
                                                   return (<UserAssignment/>)
                                               }}
                                        />
                                        <Route path={AUDIT_CONFIGURATION_ORGANIZATION_LEVEL_TIMEZONE.path}
                                               render={function renderTimezone(props) {
                                                   return (<Timezone/>)
                                               }}
                                        />
                                        <Route path={AUDIT_CONFIGURATION_ORGANIZATION_LEVEL_MAP.path}
                                               render={function renderMap(props) {
                                                   return (<Map/>)
                                               }}
                                        />
                                        <Route path={AUDIT_CONFIGURATION_ORGANIZATION_LEVEL_IMAGE.path}
                                               render={function renderImage(props) {
                                                   return (<Image/>)
                                               }}
                                        />
                                        <Redirect to={AUDIT_CONFIGURATION_ORGANIZATION_LEVEL_USERS.path}/>
                                    </Switch>
                                }
                            </Flex>
                        </Flex>
                    </Flex>
                }
            </>
        );
    }
}

export default withStyles(styles)(connect(connector.mapStateToProps, connector.mapDispatchToProps)(withTranslation()(withRouter(OrganizationLevelConfiguration))));

