import {auditDatabase} from 'scenes/audit/Audit.database'
import _ from "lodash";

export function cacheOrLoadPersonalAudits(originalReduxCall, cacheReduxCall) {
    return (dispatch, getState) => {
        const auditorBusinessId = getState().mainReducer.user.businessId;
        return dispatch(originalReduxCall(auditorBusinessId))
            .then(response => {
                const personalAudits = _.get(response, 'payload.data.data.listCurrentAuditsForAuditor');

                return auditDatabase.personalAudits.put({id: auditorBusinessId, audits: personalAudits})
                    .then(() => {
                        console.log(personalAudits,' [', auditDatabase.name, '][', auditDatabase.verno, '] cache updated');
                    })
                    .catch(error => {
                        console.log('Could not write to the local cache. Error: ', error);
                        throw error;
                    });
            })
            .catch(() => {
                if (cacheReduxCall) {
                    auditDatabase.personalAudits.get(auditorBusinessId)
                        .then(personalAuditsRecord => {
                            dispatch(cacheReduxCall(personalAuditsRecord.audits));
                        })
                        .catch(error => {
                            console.log('Cannot get cache from local database', error);
                        })
                }
            });
    };
}

export function cacheExecutedScheduledAudit(executeAuditInput) {
    return dispatch => {
        return auditDatabase.executedPersonalAudits.put({id: executeAuditInput.auditId, input: executeAuditInput})
            .catch(error => {
                console.log('Could not write to the local cache. Error: ', error);
            });
    }
}

export function updateAuditWithAnswers(auditId, executeAuditInput, answeredQuestions) {
    return (dispatch, getState) => {
        const auditorBusinessId = getState().mainReducer.user.businessId;
        return auditDatabase.personalAudits.get(auditorBusinessId).then(
            record => {
                const audits = record.audits;
                const index = audits.findIndex(audit => audit.businessId === auditId);
                if (index !== -1) {
                    const auditToUpdate = audits[index];
                    const answers = createAnswersFromInput(auditToUpdate, executeAuditInput, answeredQuestions);
                    const result = calculateAuditResult(auditToUpdate, executeAuditInput);
                    const updatedAudit = {
                        ...auditToUpdate,
                        answers: answers,
                        status: "CLOSED",
                        finishedDate: executeAuditInput.finishedDate,
                        result: result
                    };
                    audits[index] = updatedAudit;
                    return auditDatabase.personalAudits.put({id: auditorBusinessId, audits: audits})
                        .then(() => {
                            console.log(audits,' [', auditDatabase.name, '][', auditDatabase.verno, '] cache updated');
                            return updatedAudit;
                        })
                        .catch(error => {
                            console.log('Could not write to the local cache. Error: ', error);
                            throw error;
                        });
                }
            }
        );
    }
}

function createAnswersFromInput(auditToUpdate, executeAuditInput, answeredQuestions) {
    return executeAuditInput.answers.map(answerInput => {
        const relatedQuestion = auditToUpdate.questions.find(question => question.businessId === answerInput.questionId);
        const relatedValue = relatedQuestion.answerType.values.find(value => value.point === answerInput.point);
        const relatedAnsweredQuestion = answeredQuestions.find(question => question.businessId === answerInput.questionId);
        return {
            question: relatedQuestion,
            value: relatedValue,
            comment: answerInput.translation.comment,
            severity: answerInput.severity,
            attachments: relatedAnsweredQuestion.answer.attachments.map(attachment => ({offlineId: attachment.offlineId}))
        }
    });
}

function calculateAuditResult(auditToUpdate, executeAuditInput) {
    const sumOfPoints = executeAuditInput.answers.reduce((acc, answer) => acc + answer.point, 0);
    const label = sumOfPoints === executeAuditInput.answers.length ? "happy" : "neutral";
    return {
        label: label,
        percentage: _.round(sumOfPoints / executeAuditInput.answers.length * 100)
    }
}

const auditCache = {
    cacheOrLoadPersonalAudits,
    cacheExecutedScheduledAudit,
    updateAuditWithAnswers
};

export default auditCache;
