import React, {Fragment, PureComponent} from 'react';
import {
    withStyles,
    Typography,
    IconButton,
    Dialog,
    DialogTitle,
    DialogContent,
    Button,
    DialogActions,
    ExpansionPanel,
    ExpansionPanelSummary,
    ExpansionPanelDetails,
} from "@material-ui/core";
import Flex from 'components/grid/Flex';
import Add from '@material-ui/icons/Add';
import AddToPhotos from '@material-ui/icons/AddToPhotos';
import Delete from '@material-ui/icons/Delete';
import {Trans, withTranslation} from "react-i18next";
import {htmlWhite, nodeSelectedColor, nodeUnselectedColor, concreteWhite} from 'components/colors/Colors';
import BootstrapInput from 'components/bootstrap-input/BootstrapInput';
import _ from 'lodash';
import Tooltip from "@material-ui/core/Tooltip";

const styles = theme => ({
    input: {
        padding: '5px 10px',
        fontSize: '0.875rem'
    },
    expansionPanelContainer: {
        backgroundColor: 'unset',
        boxShadow: 'unset',
        border: 'unset',
        borderRadius: 0
    },
    noPaddingContainer: {
        padding: 0,
        margin: 0,
        minHeight: 0,
        '&$expanded': {
            padding: 0,
            margin: 0,
            minHeight: 0,
        },
        borderRadius: 0
    },
    expanded: {},
    iconButtonRoot: {
        padding: 5
    },
    container: {
        borderBottom: `1px solid ${concreteWhite}`,
    }
});

const PROJECT_ROOT_LEAF_TRANSLATIONS = ['projects', 'areas', 'personal'];

const AddDialog = (props) => {
    const {
        isAddDialogOpen, reset, addType, translate, title, handleTitleChange, finishAdd, finishAddChild,
        stopEvent
    } = props;
    return (
        <Dialog open={isAddDialogOpen} onClose={reset} onClick={stopEvent}>
            <DialogTitle id="alert-dialog-title">
                {
                    addType === 'ADD'
                        ?
                        `${translate("global.new")} ${translate("global.sibling")}`
                        :
                        <Trans i18nKey={"global.new-child"}/>
                }
            </DialogTitle>
            <DialogContent>
                <BootstrapInput
                    style={{marginTop: 5}}
                    value={title}
                    autoFocus
                    onClick={stopEvent}
                    onChange={handleTitleChange}
                    onEnterKey={addType === 'ADD' ? finishAdd : finishAddChild}
                    name={'title'}
                />
            </DialogContent>
            <DialogActions>
                <Button variant="contained"
                        color="primary"
                        onClick={addType === 'ADD' ? finishAdd : finishAddChild}
                ><Trans i18nKey={"global.save"}/></Button>
            </DialogActions>
        </Dialog>
    );
};

const Menu = (props) => {
    const {classes, add, addChild, deleteProject, selectedProject, isSelectedProjectVisible, stopEvent, canEditRoot, applicationOnline, translate} = props;
    const isDisabledDelete = selectedProject ? !selectedProject.editable : true;
    const isDisabledAddChild = selectedProject && (isSelectedProjectVisible && !selectedProject.editable);
    const isParentEditable = _.get(selectedProject, 'parent.editable', undefined);
    const isDisabledAdd = (!isSelectedProjectVisible) || (isParentEditable !== undefined ? !isParentEditable : !canEditRoot);
    return (
        (selectedProject.rootMenuVisible && applicationOnline) ?
            <Flex item container style={{backgroundColor: htmlWhite, paddingTop: 5, paddingBottom: 5}}
                  onClick={stopEvent}>
                <Tooltip title={`${translate("global.new")} ${translate("global.sibling")}`}>
                    <div>
                        <IconButton className={classes.iconButtonRoot} onClick={add} disabled={isDisabledAdd}>
                            <Add/>
                        </IconButton>
                    </div>
                </Tooltip>
                <Tooltip title={translate("global.new-child")}>
                    <div>
                        <IconButton className={classes.iconButtonRoot} onClick={addChild} disabled={isDisabledAddChild}>
                            <AddToPhotos/>
                        </IconButton>
                    </div>
                </Tooltip>
                <Tooltip title={translate("global.delete")}>
                    <div>
                        <IconButton className={classes.iconButtonRoot} onClick={deleteProject}
                                    disabled={isDisabledDelete || !isSelectedProjectVisible}>
                            <Delete/>
                        </IconButton>
                    </div>
                </Tooltip>
            </Flex>
            :
            <Fragment/>
    );
};

export class ProjectRootLeaf extends PureComponent {

    state = {
        isTitleEditable: false,
        isTitleSelected: false,
        isAddDialogOpen: false,
        title: '',
        addType: ''
    };

    reset = event => {
        if (event) {
            event.preventDefault();
            event.stopPropagation();
        }
        this.setState({
            isAddDialogOpen: false,
            title: '',
            addType: ''
        });
    };

    edit = () => {
        this.setState({
            title: this.props.node.title
        })
    };

    add = event => {
        event.preventDefault();
        event.stopPropagation();
        this.setState({
            addType: 'ADD',
            isAddDialogOpen: true,
        })
    };

    addChild = event => {
        event.preventDefault();
        event.stopPropagation();
        this.setState({
            addType: 'ADD_CHILD',
            isAddDialogOpen: true,
        })
    };

    handleTitleChange = event => {
        this.setState({
            title: event.target.value
        })
    };

    finishAdd = event => {
        event.preventDefault();
        event.stopPropagation();
        this.props.addNode(
            _.get(this.props, 'selectedProject.parent.businessId', this.props.node.businessId),
            this.state.title
        ).then().catch().then(() => {
            this.reset();
        });
    };

    finishAddChild = event => {
        event.preventDefault();
        event.stopPropagation();
        const isSelectedProjectVisible = _.get(this.props.selectedProject, 'root.businessId') === this.props.node.businessId;
        const addChildTo = isSelectedProjectVisible ? this.props.selectedProject.businessId : this.props.node.businessId;
        this.props.addChildNode(addChildTo, this.state.title).then().catch().then(() => {
            this.reset();
        });
    };

    deleteProject = event => {
        event.preventDefault();
        event.stopPropagation();
        this.props.deleteSelection(this.props.selectedProject.businessId);
    };

    stopEvent = event => {
        event.stopPropagation();
    };

    render() {
        const {classes, node, toggleOpen, selectedProject, t: translate, canEditRoot, applicationOnline} = this.props;
        const {title, isAddDialogOpen, addType} = this.state;
        const {add, addChild, handleTitleChange, finishAdd, finishAddChild, reset, deleteProject, stopEvent} = this;
        const isSelectedProjectVisible = _.get(selectedProject, 'root.businessId') === node.businessId;
        return (

            <Flex item
                  container
                  alignItems={'center'}
                  className={classes.container}
                  onClick={toggleOpen(node.businessId)}
            >
                <Flex container item direction={'column'}>
                    <ExpansionPanel expanded={node.open} square
                                    className={classes.expansionPanelContainer}>
                        <ExpansionPanelSummary classes={{
                            root: classes.noPaddingContainer,
                            content: classes.noPaddingContainer,
                            expanded: classes.expanded,
                        }}>
                            <Flex container item justifyContent={'center'} padding={5}
                                  style={{backgroundColor: node.open ? nodeSelectedColor : nodeUnselectedColor}}>
                                <Typography style={{fontWeight: 'bold', color: htmlWhite, fontSize: 16}}>
                                    {
                                        PROJECT_ROOT_LEAF_TRANSLATIONS.includes((node.title || '').toLowerCase())
                                            ? translate(`global.${(node.title || '').toLowerCase()}`)
                                            : node.title
                                    }
                                </Typography>
                            </Flex>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails className={classes.noPaddingContainer}>
                            <Menu
                                {...{
                                    classes,
                                    selectedProject,
                                    add,
                                    addChild,
                                    deleteProject,
                                    isSelectedProjectVisible,
                                    stopEvent,
                                    canEditRoot,
                                    applicationOnline,
                                    translate
                                }}/>
                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                </Flex>
                <AddDialog
                    {...{
                        isAddDialogOpen,
                        reset,
                        addType,
                        translate,
                        title,
                        handleTitleChange,
                        finishAdd,
                        finishAddChild,
                        stopEvent
                    }}
                />
            </Flex>

        );
    }
}

export default withStyles(styles)(withTranslation()(ProjectRootLeaf));
