import React, {Component} from 'react';
import {withStyles, Typography, LinearProgress, Divider, IconButton} from "@material-ui/core";
import DownloadIcon from '@material-ui/icons/SystemUpdateAlt';
import Flex from 'components/grid/Flex';
import {withTranslation} from 'react-i18next';
import {QuestionCircle} from './VdaAuditExecutor';
import _ from 'lodash';
import * as colors from 'components/colors/Colors';
import {getColor} from 'components/colors/Result';
import utils from 'utils/Utils';
import moment from 'moment';
import configs from 'configs/Configs';
import InlineConfirmation from 'components/confirmation-dialog/InlineConfirmation';
import {withRouter} from 'react-router';
import {SCHEDULED_AUDITS} from 'routes/routes';
import {resultTranslationKey} from 'scenes/audit/result/AuditResult';

const styles = theme => ({
    numberCircle: {
        width: 24,
        height: 24,
        borderRadius: '50%',
    },
    container: {
        '@media (min-width: 800px)': {
            marginTop: 50,
        },
    },
});

function answered(accumulator, current) {
    return accumulator + (current.answer.point !== '' ? 1 : 0)
}

const Topic = props => {
    const {classes, topic, questions, topicIndex, jumpToQuestion} = props;
    const topicQuestions = questions.filter(question => question.topicId === topic.businessId);
    const questionsAnsweredInTopic = _.isEmpty(topic.children)
        ? topicQuestions.reduce(answered, 0)
        : questions.filter(question => topic.children.flatMap(subtopic => subtopic.questionIds).includes(question.businessId))
            .reduce(answered, 0)
    const progress = (questionsAnsweredInTopic / topicQuestions.length) * 100;
    return <Flex item container>
        <Flex container>
            <Typography style={{fontSize: 82, opacity: 0.1, lineHeight: '82px'}}>
                {topicIndex + 1}
            </Typography>
        </Flex>
        <Flex item container column>
            <Typography style={{textTransform: 'uppercase', fontSize: 12, fontWeight: 'bold', color: colors.paleSky}}>
                Topic {topicIndex + 1}
            </Typography>
            <Typography style={{textTransform: 'capitalize', fontWeight: 'bold', fontSize: 14}}>
                {topic.name}
            </Typography>
            <LinearProgress value={progress} variant={'determinate'} style={{
                marginBottom: 10,
                marginTop: 5,
                marginRight: 5,
                height: 5
            }}/>
            <Flex item container>
                {
                    _.isEmpty(topic.children) && topicQuestions.map((question, index) => (
                        <QuestionCircle key={question.businessId} {...{
                            classes, question, jumpToQuestion,
                            number: index + 1,
                            selected: false
                        }}/>
                    ))
                }
            </Flex>
            {
                topic.children.map((subTopic, subTopicIndex, subTopicArray) => (
                    <SubTopic key={subTopic.businessId} {...{
                        classes, subTopic, subTopicIndex, questions,
                        jumpToQuestion, lastSubtopic: subTopicArray.length === subTopicIndex + 1
                    }}/>
                ))
            }
        </Flex>
    </Flex>
}

const SubTopic = (props) => {
    const {classes, subTopic, subTopicIndex, questions, jumpToQuestion, lastSubtopic, exportExcelAndCloseAudit} = props;
    const subTopicQuestions = questions.filter(question => question.subTopicId === subTopic.businessId);
    return (
        <Flex item container column style={{paddingLeft: 30}}>
            <Typography style={{textTransform: 'uppercase', fontSize: 12, fontWeight: 'bold', color: colors.paleSky}}>
                SubTopic {subTopicIndex + 1}
            </Typography>
            <Typography
                style={{
                    textTransform: 'capitalize',
                    fontWeight: 'bold',
                    fontSize: 14
                }}>
                {subTopic.name}
            </Typography>
            <Flex item={'0 1 35px'} container style={{marginTop: 7, ...(lastSubtopic && {marginBottom: 15})}}>
                {
                    subTopicQuestions.map((question, index) => (
                        <QuestionCircle key={question.businessId} {...{
                            classes, question, jumpToQuestion,
                            number: index + 1,
                            selected: false
                        }}/>
                    ))
                }
            </Flex>
            {
                !lastSubtopic &&
                <Divider style={{backgroundColor: colors.transparentTundora}}/>
            }
        </Flex>
    )
};

export class AuditExecutionListView extends Component {

    componentWillUnmount() {
        if(this.url){
            window.URL.revokeObjectURL(this.url);
        }
    }

    jumpToQuestion = (questionId) => {
        this.props.jumpToQuestion(questionId);
        this.props.setView('QUESTION');
    }

    downloadReport = () => {
        this.props.exportExcelAndCloseAudit(this.props.auditId).then(response => {
            const blob = response.payload.data;
            this.url = window.URL.createObjectURL(new Blob([blob], {type: "application/octet-stream"}));
            utils.triggerNativeDownload(
                this.url,
                `${moment().format('YYYY-MM-DD')}-report.xlsx`
            )
            if (!this.props.editDisabled){
                this.props.history.push(SCHEDULED_AUDITS.path);
            }
        })

    }

    render() {
        const {classes, t: translate, questions, topics, intermediateResult, editDisabled} = this.props;
        const {jumpToQuestion} = this;
        const backgroundColor = getColor(intermediateResult);

        return (
            <Flex item container column className={classes.container}>
                <Flex item={'0 1 80px'} container center column style={{backgroundColor, marginBottom: 10}}>
                    <Typography style={{
                        textTransform: 'uppercase', fontSize: 12,
                        ...(backgroundColor && {color: colors.htmlWhite})
                    }}>{
                        translate("audit-management.execute-audit.results")
                    }</Typography>
                    <Typography style={{
                        fontWeight: 'bold', fontSize: 24,
                        ...(backgroundColor && {color: colors.htmlWhite})
                    }}>{translate(resultTranslationKey(intermediateResult))}</Typography>
                </Flex>
                <Flex item container column>
                    {
                        topics.map((topic, topicIndex) => (
                            <Topic key={topic.businessId} {...{
                                classes, topic, questions, topicIndex,
                                jumpToQuestion
                            }}/>
                        ))
                    }
                </Flex>
                <Divider style={{backgroundColor: colors.transparentTundora}}/>
                <Flex item={'0 1 auto'} container center column style={{marginTop: 20}}>
                    {
                        editDisabled
                            ? <IconButton onClick={() => {
                                this.downloadReport()
                            }} style={{paddingBottom: 6}}>
                                <DownloadIcon/>
                            </IconButton>
                            : <InlineConfirmation {...{
                                onConfirm: () => {
                                    this.downloadReport()
                                },
                            }}>
                                <IconButton className={classes.iconButton}
                                            classes={{root: classes.iconButtonRoot}}>
                                    <DownloadIcon/>
                                </IconButton>
                            </InlineConfirmation>
                    }

                    <Typography>{translate(`audit-management.execute-audit.${editDisabled ? 'report' : 'close-report'}`)}</Typography>
                </Flex>

            </Flex>
        );
    }
}

export default withStyles(styles)(withTranslation()(withRouter(AuditExecutionListView)));
