import React, {Component, Fragment} from 'react';
import {Dialog, DialogContent, DialogTitle, withStyles,} from "@material-ui/core";
import {withTranslation} from "react-i18next";
import FileGallery from 'components/gallery/FileGallery';
import _ from 'lodash';

const styles = theme => ({
    dialogContainer: {
        minHeight: 300,
        minWidth: 600
    },
    mobileDialogContainer: {
        minHeight: 300,
        minWidth: '95%'
    },
    contentRoot: {
        padding: 0,
        margin: 5
    }
});

export class DeviationAttachmentsDialog extends Component {

    render() {
        const {
            classes, t: translate, isMobile, isAttachmentOpen, allFiles,
            closeAttachment, preventEventPropagation

        } = this.props;
        const filteredFiles = allFiles;
        const numberOfFiles = _.isEmpty(allFiles) ? 0 : allFiles.length;
        return (
            <Fragment>
                <Dialog
                    open={isAttachmentOpen}
                    onClose={closeAttachment}
                    aria-labelledby="form-dialog-title-attachment"
                    classes={{
                        paper: isMobile ? classes.mobileDialogContainer : classes.dialogContainer
                    }}
                    maxWidth={numberOfFiles < 6 ? 'sm' : 'lg'}
                    onClick={preventEventPropagation}
                >
                    <DialogTitle id="form-dialog-title-attachment">{translate("attachments.title")}</DialogTitle>
                    <DialogContent classes={{root: classes.contentRoot}}>
                        <FileGallery
                            sources={filteredFiles}
                            deleteSource={() => {
                            }}
                            readOnly={true}
                        />
                    </DialogContent>
                </Dialog>
            </Fragment>
        );
    }
}

export default withStyles(styles)(withTranslation()(DeviationAttachmentsDialog));
