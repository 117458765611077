import React, {Component} from 'react';
import {
    withStyles,
    Typography,
} from "@material-ui/core";
import SearchIcon from '@material-ui/icons/Search';
import Dropzone from 'react-dropzone';
import DropAreaImage from 'assets/img/drop-area.png';
import {withTranslation} from "react-i18next";
import FileGallery from 'components/gallery/FileGallery';
import _ from 'lodash';
import H4InputWithAdornment from 'components/input/H4InputWithAdornment';
import Flex from 'components/grid/Flex';
import {htmlGrey, htmlRed} from 'components/colors/Colors';
import configs from "configs/Configs";

const styles = theme => ({
    dropDownImage: {},
    filename: {
        marginTop: 10
    },
    dragAndDropText: {
        color: htmlGrey,
        fontStyle: 'italic'
    },
    warningText: {
        color: htmlRed,
        fontStyle: 'italic'
    },
});

export class AttachmentWindow extends Component {

    componentDidMount() {
        if(this.props.onOpen){
            this.props.onOpen();
        }
    }

    render() {
        const {
            classes, t: translate, allFiles, filterText, isInvalidFile, handleChange, deleteAttachment, onDrop,
            uploadEnabled,
        } = this.props;
        const filteredFiles = _.sortBy(
            allFiles.filter(file => file.filename.toUpperCase().includes(filterText.toUpperCase())),
            'creationDate'
        );
        return (
            <Flex item container direction={'column'} style={{marginTop: 10}}>
                <Flex item={'1 1 auto'} style={{padding: '0 15px'}}>
                    <H4InputWithAdornment
                        value={filterText}
                        placeholder={translate('global.search')}
                        onChange={handleChange}
                        name={'filterText'}
                        id="filterText-input"
                        fullWidth
                        startAdornment={<SearchIcon/>}
                    />
                </Flex>
                <FileGallery
                    sources={filteredFiles}
                    deleteSource={deleteAttachment}
                />
                    {
                    uploadEnabled &&
                    <Flex item container direction={'column'} alignItems={'center'}>
                        <Dropzone onDrop={onDrop}>
                            {({getRootProps, getInputProps, isDragActive}) => {
                                return (
                                    <div {...getRootProps()}>
                                        <input {...getInputProps()} />
                                        {
                                            <img className={classes.dropDownImage} src={DropAreaImage}
                                                 alt={''}/>
                                        }
                                    </div>
                                )
                            }}
                        </Dropzone>
                        <Typography
                            className={classes.dragAndDropText}>{translate("attachments.dragAndDrop")}</Typography>
                        {
                            isInvalidFile &&
                            <Typography
                                className={classes.warningText}>
                                {translate("attachments.uploadWarning", {
                                    limit: (configs.getMaxFileUploadSizeInBytes() / 1024 / 1024)
                                })}
                            </Typography>
                        }
                    </Flex>
                }
            </Flex>
        );
    }
}

export default withStyles(styles)(withTranslation()(AttachmentWindow));
