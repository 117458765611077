
export default {
    mapStateToProps: (state) => {
        return {
        };
    },
    mapDispatchToProps: (dispatch) => {
        return {
        };
    }
};
