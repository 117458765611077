import Dexie from 'dexie';

export const kpiMonitoringDatabase = new Dexie('kpi-monitoring');

kpiMonitoringDatabase.version(1).stores({
    users: "++id",
    machineStatusConfigurationData: "++id",
    machineDetails: "businessId",
    organizationLevel: "businessId",
    editedDetails: "machineId"
});

