export const htmlBlack = 'black'; //rgb(0, 0, 0)

export const htmlWhite = '#ffffff'; //rgb(255, 255, 255)
export const alabasterWhite = '#f7f7f7'; //rgb(247, 247, 247) //Task edit background
export const concreteWhite = '#f2f2f2'; //rgb(242, 242, 242) //Kanban background
export const porcelainWhite = '#ECF0F1'; //rgb(236, 240, 241) //File dropdown
export const fafaWhite = '#FAFAFA';

export const mercuryGrey = '#e5e5e5'; //rgb(229, 229, 229) //Task low prio light indicator
export const mischka = '#E0E2E7'; //rgb(224, 226, 231) //Action header background This has a blue tint
export const htmlLightGrey = '#D3D3D3'; //rgb(211, 211, 211)
export const ghostGrey = '#CED4DA'; //rgb(206, 212, 218)
export const silver2 = '#bfbfbf'; //rgb(191, 191, 191) //Task low prio dark indicator
export const silver = '#bdbdbd'; //rgb(189, 189, 189) //Task content title text color
export const heatheredGray = '#B9AD92'; //rgb(185, 173, 146) Avatar background
export const dustyGrey = '#9b9b9b'; //rgb(155, 155, 155) //Task header title text color, grey buttons
export const grey = '#8E8E8E'; //rgb(142, 142, 142)
export const regentGrey = '#7F899B'; //rgb(127, 137, 155)
export const htmlGrey = '#808080'; //rgb(128, 128, 128)
export const doveGrey = '#717171'; //rgb(113, 113, 113)
export const athensGrey = '#DFE2E7'; //rgb(223, 226, 231)
export const athensGreyDark = '#c5cad3';

export const htmlGreen = '#008000'; //rgb(0, 128, 0)
export const christiGreen = '#689713'; //rgb(104, 151, 19) //Done/success audit
export const laPalmaGreen = '#16aa16'; //rgb(22, 170, 22)
export const emeraldGreen = '#50C878'; //rgb(216, 244, 164)
export const gossipGreen = '#d8f4a4'; //rgb(216, 244, 164)
export const berylGreen = '#D1E0B8'; //rgb(209, 224, 184) //Done/success audit light
export const jungleGreen = '#1F9F73'; //rgb(31, 159, 115)
export const jungleGreen50 = '#8FCFB9'; //rgb(31, 159, 115)

export const htmlOrange = '#FFA500'; //rgb(255,165,0)
export const jaffaOrange = '#ed7d31'; //rgb(237, 125, 49) //Task medium prio dark indicator, mixed result audit
export const tacao = '#F4B183'; //rgb(244, 177, 131) //transparent red over white
export const zestOrange = '#e87d2d'; //rgb(232, 125, 45) //Quality management main color
export const dixieOrange = '#e3a616'; //rgb(227, 166, 22)
export const dairyCream = '#FAD8C1'; //rgb(250, 216, 193) audit mixed result light

export const htmlRed = '#ff0000'; //rgb(255, 0, 0)
export const guardsmanRed = '#cc0000'; //rgb(204, 0, 0) //task main color, bad audit dark,
export const tallPoppyRed = '#bc2c28'; //rgb(188, 44, 40) //Task critical prio dark, progress bar
export const crimsonRed = '#de1616'; //rgb(222, 22, 22) //Task main color lighter
export const red50 = '#FF7F7F';
export const red = "#DE0000";

//TODO LM: we did not changed the color of the material buttons to our pallet, we should!
export const blackPearl = '#001337'; //rgb(0, 19,55) //Sidebar color
export const blackPearl2 = '#001437';
export const navyBlue = '#060066'; //rgb(0, 19,55) CalendarPage
export const htmlBlue = '#0000FF'; //rgb(0, 0, 255)
export const persianBlue = '#1F51C1'; //rgb(31, 81, 193) //stories/mytask switch text
export const scienceBlue = '#0151c0'; //rgb(1, 81, 192) //Audit main color, sidebar color
export const sanMarinoBlue = '#4050B5'; //rgb(62, 81, 180) //Actions button, edit button
export const scienceBlue2 = '#015ad5'; //rgb(1, 90, 213) //Audit main light color
export const marinerBlue = '#1e6ee2'; //rgb(30, 110, 226) //action urgent prio
export const dodgerBlue = '#4c9aff'; //rgb(76, 154, 255)
export const malibuBlue = '#80bdff'; //rgb(128, 189, 255)
export const linkWaterBlue = '#EFF5FB'; //rgb(239, 245, 251)
export const linkWaterBlue2 = '#E0EAF7'; //rgb(224, 234, 247)
export const pattensBlue = '#DEEBFF'; //rgb(222, 235, 255)
export const onahauBlue = '#cce1ff'; //rgb(204, 225, 255) //audit shedule light
export const easternBlue = '#13afae'; //rgb(19, 175, 174) //Kpi management dark main color
export const pictonBlue = '#3CAFEF'; //rgb(60, 175, 239) //tag color, avatar
export const moduleIconColor = '#65B3E3'; //rgb(229, 236, 242) //Application module icon color
export const spindleBlue = '#B5CCEC'; //rgb(181, 204, 236)
export const catskillBlue = '#e5ecf2'; //rgb(229, 236, 242) //Action header background This has a blue tint
export const paleSky = '#667287'; //rgb(102, 114, 135)
export const paleSkyDark = '#586274';

export const surfCrestPastel = '#cee2cc'; //rgb(206, 226, 204)
export const cinderellaPastel = '#fee4e5'; //rgb(254, 228, 229) //Task critical prio light
export const champagnePastel2 = '#fbe5d6'; //rgb(251, 229, 214) //Task medium prio light indicator
export const olivinePastel = '#8cb77a'; //rgb(140, 183, 122)

export const superNovaYellow = '#FFCE00'; //rgb(255, 206, 0)
export const frolyPink = '#f77c74'; //rgb(247, 124, 116)
export const periwinklePink = '#CFD3EC'; //rgb(207, 211, 236)
export const mandysPink = '#F0B2B2'; //rgb(240, 178, 178) //Bad audit light
export const purpleHeart = '#9518cf'; //rgb(149, 24, 207) //Knowledge management main color
export const violetEggplant = '#b109b1'; //rgb(177, 9, 177)

//TODO LM: Are these transparent for the sake of transparency or for a change in color?
export const transparentWhite05 = 'rgba(255, 255, 255, 0.5)';
export const transparentWhite08 = 'rgba(255, 255, 255, 0.8)';
export const transparentDownRiver025 = 'rgba(9, 30, 66, 0.25)';
export const transparentAzureBlue025 = 'rgba(0, 123, 255, 0.25)';
export const transparentBlack004 = 'rgba(0, 0, 0, 0.04)';
export const transparentBlack02 = 'rgba(0, 0, 0, 0.2)';
export const transparentBlack03 = 'rgba(0, 0, 0, 0.3)';
export const transparentBlack04 = 'rgba(0, 0, 0, 0.4)';
export const transparentBlack05 = 'rgba(0, 0, 0, 0.5)';
export const transparentBlack054 = 'rgba(0, 0, 0, 0.54)';
export const transparentBlack087 = 'rgba(0, 0, 0, 0.87)';
export const transparentBlack08 = 'rgba(0, 0, 0, 0.8)';
export const transparentCodGrey20 = 'rgba(23, 23, 23, 0.2)';
export const transparentCodGrey30 = 'rgba(23, 23, 23, 0.3)';
export const transparentAthensGrey30 = 'rgba(223,226,231,0.3)';
export const transparentMidnight40 = 'rgba(0, 20, 55, 0.4)'; //rgb(153,161,175) on white
export const midnight40 = '#99a1af';
export const transparentTundora = '#4A4A4A4D';

export const audit = scienceBlue;
export const auditDark = scienceBlue2;
export const auditHighlight = onahauBlue;
export const kpi = easternBlue;
export const quality = zestOrange;
export const knowledge = purpleHeart;
export const task = guardsmanRed;
export const taskDark = crimsonRed;

export const greyHighlight = concreteWhite;
export const blueHighlight = pattensBlue;
export const greyBackground = concreteWhite;
export const treeFilterNodeSelectorHighlight= linkWaterBlue2;
export const normal = marinerBlue;
export const critical = tallPoppyRed;
export const medium = jaffaOrange;
export const mediumHighlight = dairyCream;
export const criticalHighlight = mandysPink;
export const low = silver2;
export const textField = catskillBlue;
export const criticalTextField = cinderellaPastel;
export const mediumTextField = champagnePastel2;
export const darkGreyHighlight = mercuryGrey;
export const lowTextField = mercuryGrey;
export const inactiveTabGrey = mercuryGrey;
export const logoBlueLight = pictonBlue;
export const logoBlueDark = persianBlue;
export const lightBlue = spindleBlue;
export const swipeActionRed = crimsonRed;
export const swipeActionGreen = laPalmaGreen;
export const swipeActionOrange = dixieOrange;
export const auditPaperGrey = alabasterWhite;
export const fileDropdownArea = porcelainWhite;
export const materialUIBorder = ghostGrey;
export const controlButtonBlue = sanMarinoBlue;
export const textInputFieldBorder = ghostGrey;
export const unseenCircle = violetEggplant;
export const adornmentIconGrey = grey;
export const seenIconGrey = silver;
export const questionnaireTitleActive = olivinePastel;
export const questionsTableHeader = htmlLightGrey;
export const mobileStatusHeader = periwinklePink;

export const random = htmlOrange;
export const auditNotificationSent =  htmlOrange;

export const auditManagementColor = audit;
export const taskManagementColor = guardsmanRed;
export const kpiManagementColor = easternBlue;

export const sideBarColor = blackPearl;

export const nodeUnselectedColor = mischka;
export const nodeSelectedColor = regentGrey;

export const warningButton = 'rgba(237, 125, 49)';
export const warningButtonOnHover = 'rgba(220, 110, 49)';
