import React, {Fragment, Component} from "react";
import {
    Divider, Dialog, DialogActions, DialogTitle, Typography, IconButton, withStyles,
    FormControl,
    Select,
    MenuItem,
    Avatar
} from "@material-ui/core";
import DialogContent from "@material-ui/core/DialogContent";
import {Trans, withTranslation} from "react-i18next";
import {connect} from "react-redux";
import connector from "./ProjectMemberAssignmentPopup.connect";
import Flex from 'components/grid/Flex';
import {RemoveCircle as DeleteIcon, ExpandMore, AddCircle, Block} from "@material-ui/icons";
import {christiGreen, silver, dustyGrey, guardsmanRed} from 'components/colors/Colors';
import H4Avatar from "components/avatar/H4Avatar";
import utils from 'utils/Utils';
import BlankAvatar from 'assets/img/blank_avatar.png';
import _ from 'lodash';
import ConfirmationDialog from 'components/confirmation-dialog/ConfirmationDialog';
import {PROJECT_MEMBERSHIP_ROLES} from 'scenes/tasks/Project.reducer';

export const styles = theme => ({
    dialogContainer: {
        minHeight: 500,
        width: 540,
        height: '70%',
        padding: 24
    },
    dialogTitleRoot: {
        padding: "0px 0px 20px",
        marginRight: 4
    },
    dialogContentRoot: {
        padding: "0px 0px 0px"
    },
    dialogActionsRoot: {
        padding: "0px 0px 0px",
        margin: '0px 0px'
    },
    contentContainer: {
        display: 'flex',
        flex: '1 1 auto',
        justifyContent: 'space-between',
        flexWrap: 'wrap',
        width: '100%'
    },
    dialogLabel: {
        marginBottom: 10
    },
    userName: {
        marginLeft: 15,
        marginRight: 15,
        width: 180,
        fontSize: '1rem'
    },
    membership: {
        width: 140,
        marginRight: 10
    },
    avatar: {
        width: 30,
        height: 30,
    },

});

const NewMemberAssignment = (props) => {
    const {
        classes, user, nonProjectMembers, membership, translatedMemberships, assigningEnabled, onChange,
        addProjectMember, translate
    } = props;

    return (
        <Flex item container style={{marginBottom: 30}} alignItems={'center'}>
            {
                user ?
                    <H4Avatar {...{user}}/>
                    :
                    <Avatar src={BlankAvatar} className={classes.avatar}/>
            }
            <FormControl>
                <Select
                    className={classes.userName}
                    value={user || ""}
                    name={'user'}
                    onChange={onChange}
                    IconComponent={ExpandMore}
                    disableUnderline
                    renderValue={user => user ? utils.formattedUserName(user) :
                        <em>{translate("task-management.project.member-list.select-user")}</em>}
                    displayEmpty
                >
                    {
                        nonProjectMembers.map((user, index) => {
                            return (
                                <MenuItem key={index} value={user}>
                                    <H4Avatar {...{user, style: {marginRight: 10}}}/>
                                    <Typography
                                        className={classes.userNameInSelector}> {utils.formattedUserName(user)}</Typography>
                                </MenuItem>
                            )
                        })
                    }
                </Select>
            </FormControl>
            <FormControl>
                <Select
                    className={classes.membership}
                    value={membership}
                    onChange={onChange}
                    name={'membership'}
                    IconComponent={ExpandMore}
                    disableUnderline
                >
                    {
                        translatedMemberships.map((membership, index) => {
                            return (
                                <MenuItem key={index} value={membership.name}>
                                    {membership.label}
                                </MenuItem>
                            )
                        })
                    }
                </Select>
            </FormControl>
            <IconButton
                disabled={!assigningEnabled}
                onClick={addProjectMember(user, membership)}
            >
                <AddCircle style={{color: assigningEnabled ? christiGreen : silver}}/>
            </IconButton>

        </Flex>
    );
};

const EditMemberList = (props) => {
    const {
        classes, projectMembers, translatedMemberships, updateProjectMember, openDeleteUserDialog, loggedInUser
    } = props;

    return (
        <Fragment>
            {
                projectMembers.map((projectMember, index) => (
                    <Fragment key={index}>

                        <Flex item container grow={0} alignItems={'center'}>
                            <H4Avatar {...{user: projectMember.user}}/>
                            <Typography
                                className={classes.userName}
                                style={{color: _.get(projectMember, 'user.businessId') === loggedInUser.businessId && dustyGrey}}>
                                {utils.formattedUserName(projectMember.user)}
                            </Typography>
                            <FormControl>
                                <Select
                                    className={classes.membership}
                                    value={projectMember.membership}
                                    onChange={updateProjectMember(projectMember)}
                                    name={'projectMembership'}
                                    IconComponent={ExpandMore}
                                    disableUnderline
                                    disabled={_.get(projectMember, 'user.businessId') === loggedInUser.businessId}
                                >
                                    {
                                        translatedMemberships.map((membership, index) => {
                                            return (
                                                <MenuItem key={index} value={membership.name}>
                                                    {membership.label}
                                                </MenuItem>
                                            )
                                        })
                                    }
                                </Select>
                            </FormControl>
                            {
                                _.get(projectMember, 'user.businessId') === loggedInUser.businessId ?
                                    <IconButton>
                                        <Block style={{color: silver}}/>
                                    </IconButton>
                                    :
                                    <IconButton onClick={openDeleteUserDialog(projectMember)}>
                                        <DeleteIcon style={{color: guardsmanRed}}/>
                                    </IconButton>
                            }
                        </Flex>
                        <Divider style={{width: '100%'}}/>

                    </Fragment>
                ))
            }
        </Fragment>
    );
};

class ProjectMemberAssignmentPopup extends Component {

    state = {
        user: undefined,
        membership: 'MEMBER',
        deleteUserDialogOpen: false,
        substitute: undefined,
        memberToDelete: undefined,
    };

    handleChange = event => {
        this.setState({
            [event.target.name]: event.target.value,
        });
    };

    addProjectMember = (user, membership) => () => {
        this.props.assignProjectMember(this.props.selectedProject.businessId, user.businessId, membership).then(() => {
            this.props.listProjects().then(() => {
                this.setState({
                    user: undefined,
                    selectedMembership: 'MEMBER'
                });
            });
        })
    };

    updateProjectMember = member => event => {
        const newMembership = event.target.value;
        this.props.updateProjectMembership(this.props.selectedProject.businessId, _.get(member, 'user.businessId'), newMembership).then(() => {
            this.props.listProjects();
        })
    };

    removeProjectMember = () => {
        this.props.removeProjectMember(
            this.props.selectedProject.businessId,
            _.get(this.state, 'memberToDelete.user.businessId'),
            this.state.substitute.businessId
        ).then(() => {
            this.props.listProjects();
            this.props.listAllOnlineTasks();
        });
        this.closeDeleteUserDialog();
    };

    translateMemberShips = () => {
        return PROJECT_MEMBERSHIP_ROLES.map(
            membership => ({
                name: membership,
                label: this.props.t(("task-management.project.member-list.membership." + membership))
            }));
    };

    closeDeleteUserDialog = () => {
        this.setState({
            substitute: undefined,
            memberToDelete: undefined,
            deleteUserDialogOpen: false
        })
    };

    openDeleteUserDialog = memberToDelete => () => {
        const tasksOfMemberToDelete = this.props.tasks
            .filter(task => task.projectBusinessId === this.props.selectedProject.businessId)
            .filter(task => _.get(task, 'assignee.businessId') === _.get(memberToDelete, 'user.businessId'));

        const actionsOfMemberToDelete = this.props.tasks.flatMap(task => task.actions)
            .filter(action => action.projectBusinessId === this.props.selectedProject.businessId)
            .filter(action => _.get(action, 'assignee.businessId') === _.get(memberToDelete, 'user.businessId'));

        if ((tasksOfMemberToDelete.length + actionsOfMemberToDelete.length) > 0) {
            this.setState({
                deleteUserDialogOpen: true,
                memberToDelete,
            })
        } else {
            this.props.removeProjectMember(
                this.props.selectedProject.businessId,
                _.get(memberToDelete, 'user.businessId')
            ).then(() => {
                this.props.listProjects();
                this.props.listAllOnlineTasks();
            });
        }
    };

    render() {
        const {dialogOpen, classes, toggleProjectAssignmentPopup, allUsers, loggedInUser, selectedProject, t: translate} = this.props;
        const {user, membership, deleteUserDialogOpen, substitute, memberToDelete} = this.state;
        const {closeDeleteUserDialog, handleChange, removeProjectMember, updateProjectMember, addProjectMember, openDeleteUserDialog} = this;
        const possibleSubstitutes = memberToDelete ? _.differenceBy(allUsers, [memberToDelete], 'businessId') : [];
        const nonProjectMembers = _.differenceBy(allUsers, (selectedProject.members || []).map(member => member.user), 'businessId');
        const translatedMemberships = this.translateMemberShips();
        const assigningEnabled = user && membership;

        return (
            <Dialog
                open={dialogOpen}
                classes={{paper: classes.dialogContainer}}
                fullWidth
                fullScreen
                onClose={toggleProjectAssignmentPopup}
            >
                <DialogTitle classes={{root: classes.dialogTitleRoot}}>
                    <Trans i18nKey={"task-management.project.member-list.dialog-title"}/>
                </DialogTitle>
                <DialogContent classes={{root: classes.dialogContentRoot}}>
                    <div className={classes.contentContainer}>

                        <Flex item container grow={0} direction={'column'} alignItems={'flex-start'}
                              justifyContent={'center'}>
                            <Typography classes={{root: classes.dialogLabel}}>
                                <Trans i18nKey={"task-management.project.member-list.new-member-assigment"}/>
                            </Typography>
                            {
                                nonProjectMembers.length === 0
                                    ?
                                    <Typography classes={{root: classes.userName}} style={{marginBottom: 30}}>
                                        <Trans i18nKey={"task-management.project.member-list.all-members-assigned"}/>
                                    </Typography>
                                    :
                                    <NewMemberAssignment {...{
                                        classes,
                                        user,
                                        membership,
                                        nonProjectMembers,
                                        translatedMemberships,
                                        assigningEnabled,
                                        onChange: handleChange,
                                        addProjectMember,
                                        translate
                                    }}
                                    />
                            }
                            <Typography classes={{root: classes.dialogLabel}}>
                                <Trans i18nKey={"task-management.project.member-list.edit-member"}/>
                            </Typography>
                            <EditMemberList {...{
                                classes,
                                projectMembers: selectedProject.members,
                                translatedMemberships,
                                loggedInUser,
                                updateProjectMember,
                                openDeleteUserDialog
                            }}
                            />
                        </Flex>


                    </div>
                </DialogContent>
                <DialogActions classes={{root: classes.dialogActionsRoot}}>
                </DialogActions>
                <ConfirmationDialog {...{
                    dialogOpen: deleteUserDialogOpen,
                    onDialogClose: closeDeleteUserDialog,
                    confirmationDisabled: _.isEmpty(this.state.substitute),
                    onConfirm: removeProjectMember,
                    cancelI18NKey: "global.cancel",
                    confirmI18NKey: "global.proceed",
                    confirmationTextKey: "task-management.project.member-list.remove-member-warning"
                }}>
                    <Select
                        className={classes.userName}
                        value={substitute || ""}
                        name={'substitute'}
                        onChange={handleChange}
                        IconComponent={ExpandMore}
                        disableUnderline
                        renderValue={substitute => substitute ? utils.formattedUserName(substitute) :
                            <em>{translate("task-management.project.member-list.select-user")}</em>}
                        displayEmpty
                    >
                        {
                            possibleSubstitutes.map((possibleSubstitute, index) => {
                                return (
                                    <MenuItem key={index} value={possibleSubstitute}>
                                        <H4Avatar {...{user: possibleSubstitute, style: {marginRight: 10}}}/>
                                        <Typography
                                            className={classes.userNameInSelector}> {utils.formattedUserName(possibleSubstitute)}</Typography>
                                    </MenuItem>
                                )
                            })
                        }
                    </Select>
                </ConfirmationDialog>
            </Dialog>
        );
    }
}

export default withStyles(styles, {withTheme: true})(connect(connector.mapStateToProps, connector.mapDispatchToProps)(withTranslation()(ProjectMemberAssignmentPopup)));
