import React, {Component} from 'react';
import {Button, Typography, withStyles,} from "@material-ui/core";
import Flex from 'components/grid/Flex';
import {darkGreyHighlight, guardsmanRed, htmlGrey} from 'components/colors/Colors';
import moment from 'moment';
import utils from 'utils/Utils';
import {routeTo, TASK_ACTION_EDIT} from 'routes/routes';
import BlueGreenProgressBar from 'components/progress-bar/BlueGreenProgressBar';
import {withTranslation} from "react-i18next";
import {getActionBusinessId} from 'domain/task/Action.model';
import {withRouter} from 'react-router-dom';
import H4Avatar from 'components/avatar/H4Avatar'

const styles = theme => ({
    avatarRoot: {
        width: 30,
        height: 30,
        margin: 8
    },
    taskButton: {
        padding: 0,
        width: '100%',
    },
    container: {
        position: 'relative',
        '&:active': {
            backgroundColor: darkGreyHighlight
        }
    },
    titleBarItem: {
        fontWeight: 'bold',
        lineHeight: 1,
        color: htmlGrey
    },
    dueDate: {
        fontSize: 13,
        color: htmlGrey
    },
    statusLabel: {
        fontSize: 16,
        fontWeight: 'bold',
        lineHeight: 1
    },
    titleContainer: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: 'flex',
        lineClamp: 2,
        lineHeight: '1em',
        wordWrap: 'break-word'
    },
    title: {
        fontWeight: 'normal',
        lineHeight: '1.15em',
        fontSize: 13,
        color: htmlGrey,
        textTransform: 'initial',
        textAlign: 'left'
    },
});

const BusinessId = props => {
    const {classes, actionBusinessId, action} = props;
    return (
        <Flex item container style={{marginBottom: 3}}>
            <Typography className={classes.titleBarItem}>
                {actionBusinessId}
            </Typography>
            {action.priority.type.toLowerCase() === 'urgent' &&
            <Typography className={classes.titleBarItem} style={{color: guardsmanRed, paddingLeft: 6}}>
                !
            </Typography>
            }
        </Flex>
    );
};

export class ActionTile extends Component {

    toEdit = () => {
        routeTo(TASK_ACTION_EDIT.pathWithIds(this.props.taskBusinessId, this.props.action.businessId), this.props);
    };

    render() {
        const {classes, t: translate, i18n, action, isSwiped} = this.props;
        const actionBusinessId = getActionBusinessId(action, translate);
        const matchingTranslation = action.translations.find(translation => translation.language.code === i18n.language) || action.translations[0];
        return (
            <Button onClick={this.toEdit}
                    className={classes.taskButton}
            >
                <Flex item container className={classes.container} justify-content={'flex-start'}>

                    <Flex item={'1 1 1'} container alignItems={'center'}>
                        <H4Avatar {...{user: action.assignee}} style={{margin: 8}}/>
                    </Flex>

                    <Flex item container alignItems={'center'}>
                        {
                            !isSwiped &&
                            <Flex container item padding={4} className={classes.titleBarContainer}
                                  alignItems={'center'}>

                                <Flex item grow={5} className={classes.titleContainer} padding={0}>
                                    <Typography className={classes.title}>{matchingTranslation.title}</Typography>
                                </Flex>

                            </Flex>
                        }
                        <Flex container item={'0 0 100px'} padding={4} direction={'column'} alignItems={'center'}
                              justifyContent={'center'}>
                            <BusinessId {...{classes, actionBusinessId, action}}/>
                            <BlueGreenProgressBar value={action.progress}
                                                  style={{width: '100%', height: 8, marginBottom: 3}}/>
                            <Flex item container direction={'column'} alignItems={'center'}>
                                <Flex item>
                                    <Typography className={classes.dueDate}>
                                        {moment(action.dueDate).format(utils.DATE_FORMAT)}
                                    </Typography>
                                </Flex>
                            </Flex>
                        </Flex>
                    </Flex>
                </Flex>
            </Button>
        );
    }
}

export default withStyles(styles)(withTranslation()(withRouter(ActionTile)));
