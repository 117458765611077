import React, {Component} from 'react';
import {Tooltip, withStyles} from "@material-ui/core";
import ReplyIcon from '@material-ui/icons/Reply';
import {transparentBlack054} from 'components/colors/Colors';
import {withTranslation} from "react-i18next";

import _ from 'lodash';
import dateTimeUtils from "utils/DateTime.utils";
import moment from "moment";


const styles = theme => ({
    iconRoot: {
        padding: 2,
        fontSize: 26,
        transform: 'scaleX(-1)',
        color: transparentBlack054,
        verticalAlign: 'middle'
    },
    avatarTooltipLabel: {
        fontSize: 14
    }
});

export class Forward extends Component {
    render() {
        const {classes, t: translate} = this.props;
        const {user, iconColor, dateTime} = this.props.data;
        return (
            <Tooltip
                title={translate('task-management.forwardedAt', {
                    givenName: _.get(user, 'givenName'),
                    familyName: _.get(user, 'familyName'),
                    time: dateTimeUtils.translateDateTime(translate, moment(dateTime))
                })}
                placement={'bottom-start'}
                classes={{
                    tooltip: classes.avatarTooltipLabel
                }}
            >
                <ReplyIcon className={classes.iconRoot} style={{color: iconColor}}/>
            </Tooltip>
        );
    }
}

export default withStyles(styles)(withTranslation()(Forward));
