import React, {Component, Fragment} from 'react';
import _ from 'lodash';
import QuestionnaireSelection from './QuestionnaireSelection';
import {Route, Switch, Link, Redirect, withRouter} from 'react-router-dom';
import {
    SETUP_AUDIT_MODE_SELECTION, SETUP_AUDIT_SCANNING, SETUP_AUDIT_ORGANIZATION_TREE,
    SETUP_AUDIT_QUESTIONNAIRE_SELECTION
} from "routes/routes";
import {withTranslation, Trans} from 'react-i18next';
import OrganizationTree from 'scenes/audit-manager/administration/organization-tree/OrganizationTree';
import {Button, withStyles, CircularProgress} from "@material-ui/core";
import QrReader from "react-qr-reader";
import SnackBarSimple from 'components/snackbar-simple/SnackBarSimple';
import Flex from 'components/grid/Flex';
import utils from 'utils/Utils';

const styles = theme => ({
    qrReader: {
        minHeight: 400,
        minWidth: 200,
    },
    button: {
        margin: 20,
        border: '1px solid grey',
        width: 250
    }
});

class ModeSelector extends Component {

    componentWillUnmount() {
        this.props.resetErrorCode();
    }

    render() {
        const {classes, errorCode} = this.props;

        return (
            <Flex item container justifyContent={'center'} alignItems={'center'} direction={'column'}
                  style={{height: '100%'}}>
                <Button className={classes.button} component={Link}
                        to={SETUP_AUDIT_SCANNING.path}>
                    <Trans i18nKey="audit-management.setup-audit.scan"/>
                </Button>
                <Button className={classes.button} component={Link}
                        to={SETUP_AUDIT_ORGANIZATION_TREE.path}>
                    <Trans i18nKey="audit-management.setup-audit.list"/>
                </Button>
                {
                    errorCode &&
                    <SnackBarSimple message={<Trans i18nKey={"audit-management.setup-audit.error." + errorCode}/>}/>
                }
            </Flex>
        );
    }
}

const OrganizationLevelSelectorTree = (props) => {
    const {selectOrganizationLevel, classes, isLoading} = props;
    const isMobile = utils.checkMobileBrowser();
    return (
        <Flex container justifyContent={'center'} alignItems={'center'} direction={'column'}
              style={{minHeight: 450, height: '100%', width: '100%'}}>
            <OrganizationTree multiSelect={false} alwaysOpen={true} noEdit={true}
                              containerStyle={{width: isMobile? '100%' : 255, flex: '1 1 auto'}}
                              style={{position: 'absolute', width: '100%', boxShadow: isMobile && 'none'}}
            />
            <Button className={classes.button} onClick={() => selectOrganizationLevel()} style={{position: 'relative'}}>
                Select
                {
                    isLoading &&
                    <CircularProgress style={{width: 24, height: 24, position: 'absolute', top: 6, right: 10}}/>
                }
            </Button>
        </Flex>
    );
};

const QrScanner = props => {
    const {qrReaderRef, handleQrError, handleQrScan, classes, isLoading} = props;
    return (
        <Fragment>
            {
                isLoading ?
                    <div style={{textAlign: 'center'}}>
                        <CircularProgress/>
                    </div>
                    :
                    <QrReader
                        ref={qrReaderRef}
                        delay={100}
                        onError={handleQrError}
                        onScan={handleQrScan}
                        className={classes.qrReader}
                    />
            }
        </Fragment>
    )
};

export class SetupQuickAudit extends Component {
    constructor(props) {
        super(props);
        this.qrReaderRef = React.createRef();
    }

    state = {
        areaId: '',
        questionnaire: '',
        randomizedNumber: '',
        isRandomizedNumberInvalid: false,
        errorCode: '',
        isLoading: false
    };

    handleQrScan = (data) => {
        let result = '';
        try {
            result = JSON.parse(data);
        } catch (e) {
            this.setState({errorCode: 'QR_CODE_WITH_INVALID_DATA_FORMAT'},
                () => this.props.history.push(SETUP_AUDIT_MODE_SELECTION.path));
        }

        if (result && result.levelId) {
            this.selectOrganizationLevel(result.levelId);
        }
    };

    handleQrError = (error) => {
        this.setState({errorCode: 'QR_SCAN_FAILED'},
            () => this.props.history.push(SETUP_AUDIT_MODE_SELECTION.path));
    };

    handleQuestionnaireChange = event => {
        this.setState({[event.target.name]: event.target.value});
        this.props.submitAdHocAuditForm(event.target.value.businessId, event.target.value.questions.length);
        this.setState({
            randomizedNumber: event.target.value.questions.length
        })
    };

    randomizedNumberChange = number => {
        const isNumberBetweenOneAndNumberOfQuestions = (number <= _.get(this.state, 'questionnaire.questions.length')) && number >= 1;
        const isEmptyInput = number === '';
        if (isNumberBetweenOneAndNumberOfQuestions) {
            this.props.submitAdHocAuditForm(this.state.questionnaire.businessId, number);
            this.setState({
                isRandomizedNumberInvalid: false,
                randomizedNumber: number
            });
        } else if (isEmptyInput) {
            this.setState({
                isRandomizedNumberInvalid: true,
                randomizedNumber: number
            });
        } else {
            this.setState({isRandomizedNumberInvalid: true});
        }
    };

    handleRandomizedNumberChange = event => {
        this.randomizedNumberChange(event.target.value);
    };

    addToRandomizedNumber = number => event => {
        this.randomizedNumberChange(number + parseInt(this.state.randomizedNumber || 0));
    };

    selectOrganizationLevel = (levelId) => {
        const id = _.isEmpty(levelId) ? this.props.selectedOrganizationLevel.businessId : levelId;
        this.setState({isLoading: true});
        this.props.fetchInitDataForAdHocAudit(id)
            .then(() => {
                if (_.isEmpty(this.props.questionnaires)) {
                    this.setState({errorCode: 'NO_QUESTIONNAIRES_IN_ORGANIZATION_LEVEL'},
                        () => this.props.history.push(SETUP_AUDIT_MODE_SELECTION.path));
                } else {
                    this.props.history.push(SETUP_AUDIT_QUESTIONNAIRE_SELECTION.path);
                }
            })
            .catch(() => this.setState({errorCode: 'QR_CODE_NOT_REGISTERED'},
                () => this.props.history.push(SETUP_AUDIT_MODE_SELECTION.path)))
            .then(() => this.setState({isLoading: false}));
    };

    componentDidMount() {
        this.props.setEmptyInitDataForAdHocAudit();
    }

    resetErrorCode = () => {
        this.setState({errorCode: ''});
    };

    render() {
        const {classes, levelTitle, questionnaires, levelId} = this.props;
        const {questionnaire, randomizedNumber, isRandomizedNumberInvalid, errorCode, isLoading} = this.state;
        const {
            handleQrError, handleQrScan, handleQuestionnaireChange, addToRandomizedNumber, resetErrorCode,
            handleRandomizedNumberChange, selectOrganizationLevel, qrReaderRef
        } = this;
        return (
            <Fragment>
                <Switch>
                    <Route path={SETUP_AUDIT_SCANNING.path}
                           render={function renderQrReader(props) {
                               return <QrScanner
                                   {...{
                                       qrReaderRef,
                                       handleQrError,
                                       handleQrScan,
                                       classes,
                                       isLoading
                                   }}
                               />
                           }}/>
                    <Route path={SETUP_AUDIT_ORGANIZATION_TREE.path}
                           render={function renderOrganizationLevelSelectorTree() {
                               return <OrganizationLevelSelectorTree {...{
                                   classes,
                                   isLoading,
                                   selectOrganizationLevel
                               }}/>
                           }}/>
                    <Route path={SETUP_AUDIT_MODE_SELECTION.path}
                           render={function renderModeSelector() {
                               return <ModeSelector {...{classes, errorCode, resetErrorCode}}/>
                           }}/>
                    <Route path={SETUP_AUDIT_QUESTIONNAIRE_SELECTION.path}
                           render={function renderQuestionnaireSelection() {
                               return _.isEmpty(questionnaires)
                                   ? <Redirect to={SETUP_AUDIT_MODE_SELECTION.path}/>
                                   : <QuestionnaireSelection {...{
                                       levelTitle,
                                       questionnaires,
                                       levelId,
                                       questionnaire,
                                       randomizedNumber,
                                       handleRandomizedNumberChange,
                                       isRandomizedNumberInvalid,
                                       handleQuestionnaireChange,
                                       addToRandomizedNumber,
                                   }}/>
                           }}/>
                    <Redirect to={SETUP_AUDIT_MODE_SELECTION.path}/>
                </Switch>
            </Fragment>
        );
    }
}

export default withRouter(withStyles(styles)(withTranslation()(SetupQuickAudit)));
