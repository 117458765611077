import React, {Component, Fragment} from 'react';
import {withStyles, IconButton, Button, SwipeableDrawer, Divider} from "@material-ui/core";
import Flex from 'components/grid/Flex';
import LanguageSelector from 'components/language-selector/LanguageSelector';
import FilterListIcon from '@material-ui/icons/FilterList';
import {withRouter} from 'react-router-dom';
import {withTranslation, Trans} from 'react-i18next';
import {connect} from 'react-redux';
import connector from './MobileProjectFilter.connect';
import ProjectTree from 'scenes/tasks/components/project-tree/ProjectTree';
import ProjectUserManagement from 'scenes/tasks/components/project-tree/ProjectUserManagement';
import {MY_ACTIONS_VALUE} from 'domain/task/Tasks.model';
import utils from 'utils/Utils';
import {htmlBlack, audit} from 'components/colors/Colors';
import FeatureSwitch from 'utils/FeatureSwitch';

const styles = theme => ({
    assetIcon: {
        width: 23,
        opacity: '0.54'
    },
    drawerRoot: {
        position: 'absolute',
        height: '100%',
    },
    paperRoot: {
        position: 'fixed',
        top: 0,
        bottom: 0,
        maxWidth: '90vw',
        outline: 'none',
        height: '100%',
        overflow: 'hidden'
    },
    logout: {
        marginLeft: 5,
        borderLeft: `1px solid ${htmlBlack}`,
        borderRadius: 0,
        fontSize: 11,
        color: audit
    }
});

const Logout = (props) => {
    const {logout, classes} = props;
    return (
        <Button onClick={logout} className={classes.logout}>
            <Trans i18nKey={"login.logout"}/>
        </Button>
    );
};

const Refresh = (props) => {
    const {classes} = props;
    return (
        <Button onClick={utils.refreshApplication} className={classes.logout}>
            <Trans i18nKey={"login.refreshShort"}/>
        </Button>
    );
};

const Synchronize = (props) => {
    const {classes, synchronize} = props;
    return (
        <Button onClick={() => synchronize()} className={classes.logout}>
            <Trans i18nKey={"global.synchronize"}/>
        </Button>
    );
};

const ManualOffline = (props) => {
    const {classes, online, toggleOnline} = props;
    return (
        <Button onClick={() => toggleOnline()} className={classes.logout}>
            {
                online
                    ? <Trans i18nKey={"global.goOffline"}/>
                    : <Trans i18nKey={"global.goOnline"}/>
            }
        </Button>
    );
};

export class MobileProjectFilter extends Component {

    state = {
        isMenuOpen: false,
    };

    toggleMenu = () => {
        this.setState({isMenuOpen: !this.state.isMenuOpen});
    };

    hideMenu = () => {
        this.setState({isMenuOpen: false});
    };

    render() {
        const {classes, logout, style, user, location, filters, synchronize, online, toggleOnline} = this.props;
        const isProjectTreeVisible = location && filters && user
            && location.pathname.startsWith('/task') && filters.view !== MY_ACTIONS_VALUE;

        const {isMenuOpen} = this.state;
        return (
            <Flex item container justifyContent={"flex-end"} alignItems={"center"} style={style}>
                <IconButton onClick={this.toggleMenu}>
                    <FilterListIcon/>
                </IconButton>
                {
                    isMenuOpen &&
                    <SwipeableDrawer
                        open={isMenuOpen}
                        onOpen={() => {
                        }}
                        onClose={this.hideMenu}
                        anchor={'right'}
                        classes={{
                            root: classes.drawerRoot,
                            paper: classes.paperRoot
                        }}
                    >
                        <LanguageSelector data={{isMobile: true}}/>
                        <Divider style={{marginBottom: 10, marginTop: 10}}/>
                        <Flex container padding={3} justifyContent={'flex-end'}>
                            <Logout logout={logout} classes={classes}/>
                            <Refresh classes={classes}/>
                            {
                                FeatureSwitch.manualSync.active &&
                                <Synchronize synchronize={synchronize} classes={classes}/>
                            }
                            <ManualOffline {...{
                                classes,
                                online,
                                toggleOnline
                            }}/>
                        </Flex>
                        <Divider style={{marginBottom: 10, marginTop: 10}}/>
                        {
                            isProjectTreeVisible &&
                            <Fragment>
                                <ProjectUserManagement/>
                                <Divider style={{marginTop: 10}}/>
                                <Flex item container style={{position: 'relative', height: '100%'}}>
                                    <ProjectTree forceOpen={true}
                                                 containerStyle={{width: '100%', flex: '1 1 auto'}}
                                                 style={{position: 'absolute', width: '100%', boxShadow: 'none'}}
                                    />
                                </Flex>
                            </Fragment>
                        }
                    </SwipeableDrawer>
                }
            </Flex>
        );
    }
}

export default withStyles(styles)(connect(connector.mapStateToProps, connector.mapDispatchToProps)(withTranslation()(withRouter(MobileProjectFilter))));
