import * as actionTypes from './Main.action.types';
import {cacheUser} from './Main.cache';
import jwt_decode from "jwt-decode";
import hmacSHA256 from 'crypto-js/hmac-sha256';
import {synchronizeData} from "cache/Synchronization.utils";
import configs from "configs/Configs";
import _ from 'lodash';
import SynchronizationEventBus from 'scenes/SynchronizationEventBus';

let logoutTimer = null;

export function loadAccessToken() {
    return dispatch => {
        const token = window.localStorage.getItem("token");
        if (token) {
            dispatch(setupAccessData(token));
        }
    }
}

export function login(username, password) {
    return cacheUser(loginOnline(username, password), loginOffline, username, password)
}

function loginOnline(username, password) {
    return dispatch => {

        return dispatch({
            type: actionTypes.LOGIN,
            payload: {
                client: 'auth',
                request: {
                    method: 'post',
                    data: {
                        "username": username,
                        "password": password
                    }
                }
            }
        }).then((response) => {
            dispatch(setupAccessData(response.payload.data.accessToken));
            window.localStorage.setItem("token", response.payload.data.accessToken);
            return response;
        });
    }
}

function loginOffline(loginData, password) {
    return dispatch => {

        const accessToken = loginData.accessToken;
        const userId = jwt_decode(accessToken).sub;
        if (checkPassword(userId, loginData, password)) {
            dispatch(setupAccessData(accessToken));
            window.localStorage.setItem("token", accessToken);
        } else {
            dispatch({type: actionTypes.LOGIN_FAIL});
            throw new Error('Incorrect username or password');
        }
    }
}

function setupAccessData(accessToken) {
    return dispatch => {
        const parsedToken = jwt_decode(accessToken);
        dispatch(sessionTimeout(parsedToken.exp));
        dispatch(loadAccessTokenEvent(accessToken));
        dispatch(fetchUser(parsedToken.sub));
    }
}

function loadAccessTokenEvent(token) {
    return {
        type: actionTypes.LOAD_ACCESS_TOKEN,
        payload: {
            data: {
                accessToken: token
            }
        }
    };
}

function checkPassword(userId, loginData, password) {
    const generatedSignature = hmacSHA256(userId, password + loginData.salt).toString();
    return generatedSignature === loginData.signature;
}

export function logout() {
    return dispatch => {
        dispatch({type: actionTypes.LOGOUT});
        window.localStorage.removeItem("token");
        window.location.href = "/";
    }
}

function sessionTimeout(accessTokenExpirationTime) {
    return dispatch => {
        const loginValidUntil = accessTokenExpirationTime - Math.floor(Date.now() / 1000);
        clearTimeout(logoutTimer);
        logoutTimer = setTimeout(() => dispatch(logout()), loginValidUntil * 1000);
        dispatch({
            type: actionTypes.SESSION_TIMEOUT,
            timeout: loginValidUntil
        });
    }
}

export function updateApplicationInformation(applicationInformation) {
    const frontendVersion = applicationInformation.find(f => f.key === 'frontend-version');
    return {
        type: actionTypes.UPDATE_APPLICATION_INFORMATION,
        payload: {
            data: {
                frontendVersion: frontendVersion ? frontendVersion.value : '0.0.0'
            }
        }
    };
}

export function synchronize() {
    return dispatch => {
        console.log("Synchronize data from cache");
        dispatch({type: actionTypes.SYNCHRONIZATION_STARTED});
        return dispatch(synchronizeData())
            .then(() => {
                SynchronizationEventBus.update.notify({type: actionTypes.SYNCHRONIZATION_FINISHED});
                return dispatch({type: actionTypes.SYNCHRONIZATION_FINISHED});
            });
    }
}

export function fetchUser(userId) {
    return {
        type: actionTypes.FETCH_USER,
        payload: {
            client: 'graphql',
            request: {
                method: 'post',
                data: {
                    query: `
                            query
                            ($userId: String!){
                                fetchUser(userId: $userId) {
                                    avatar {
                                        downloadUrl
                                    }
                                }
                            }
                       `,
                    variables: {
                        userId: userId
                    }
                }
            }
        }
    };
}

export function toggleOnline() {
    return {
        type: actionTypes.TOGGLE_ONLINE
    }
}

export function checkServerConnection() {
    const url = configs.getBackendServiceUrl() + "/actuator/ping";
    return (dispatch, getState) => {
        setInterval(
            () => {
                fetchWithTimeout(url, 200)
                    .then((response) => {
                        const responseOk = _.get(response, 'ok', false);
                        if (responseOk === true) {
                            return dispatch({type: actionTypes.FAST_SERVER_CONNECTION_DETECTED})
                        } else {
                            return dispatch({type: actionTypes.SERVER_CONNECTION_LOST})
                        }
                    })
                    .catch((response) => {
                        return dispatch({type: actionTypes.BAD_SERVER_CONNECTION_DETECTED})
                    })
            }, 5000);
    }
}

function fetchWithTimeout(url, timeout) {
    return new Promise((resolve, reject) => {
        let timer = setTimeout(
            () => reject(new Error('Request timed out')),
            timeout
        );

        fetch(url).then(
            response => resolve(response),
            response => resolve(response)
        ).finally(() => clearTimeout(timer));
    })
}

export function unsubscribe(id) {
    return {
        type: actionTypes.UNSUBSCRIBE,
        payload: {
            client: 'api',
            request: {
                method: 'delete',
                url: '/subscription/' + id
            }
        }
    };
}