import * as actionTypes from 'scenes/configuration/GeneralConfiguration.action.types';
import _ from 'lodash';

const initialState = {
    assignedAuditSchedules: {
        auditType: {},
        auditSchedules: []
    }
};

export default function (state = initialState, action) {
    switch (action.type) {
        case actionTypes.REMOVE_AUDIT_TYPE_DATA_SUCCESS: {
            const newState = _.cloneDeep(state);
            const auditTypeRemovalError = _.get(action, 'payload.data.data.removeAuditType.error');
            if (auditTypeRemovalError) {
                newState.assignedAuditSchedules = {
                    auditType: auditTypeRemovalError.auditType,
                    auditSchedules: auditTypeRemovalError.assignedAuditSchedules
                };
            }
            return newState;
        }
        case actionTypes.RESET_TO_BE_CONFIRMED_AUDIT_SCHEDULES: {
            const newState = _.cloneDeep(state);
            newState.assignedAuditSchedules = {};
            return newState;
        }
        default:
            return state;
    }
}