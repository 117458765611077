import React, {Component} from 'react';
import {withStyles} from "@material-ui/core";
import Flex from "components/grid/Flex";

const styles = theme => ({
    mainContainer: {
    }
});

export class AuditReportingDeviations extends Component {
    render() {
        const {classes} = this.props;
        return (
            <Flex item container className={classes.mainContainer}>
            </Flex>
        );
    }
}

export default withStyles(styles)(AuditReportingDeviations);
