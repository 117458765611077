import {
    string,
    shape,
    arrayOf,
    bool,
    any
} from "prop-types"
import _ from 'lodash';

export const USER = {
    roles: arrayOf(string),
    businessId: string.isRequired,
    username: string,
    givenName: string,
    familyName: string,
    isAdmin: bool,
    avatar: shape({
        filename: string,
        size: any,
        downloadUrl: string,
        creationDate: string,
    })
};

export const OWNER = {
    businessId: string.isRequired,
    givenName: string,
    familyName: string,
};

export const USER_GRAPHQL = `
    businessId
    username
    givenName
    familyName
    avatar {
        downloadUrl
    }
`;

export const OWNER_GRAPHQL = `
    businessId
    givenName
    familyName
`;

export function mapOwner(owner) {
    return owner && {
        businessId: owner.businessId,
        givenName: owner.givenName,
        familyName: owner.familyName,
    }
}

export function mapUser(user) {
    return user && {
        businessId: user.businessId,
        username: user.username,
        givenName: user.givenName,
        familyName: user.familyName,
        avatar: {
            downloadUrl: user.avatar && user.avatar.downloadUrl
        }
    }
}

export function getNameInitials(user) {
    if(_.isEmpty(user)){
        return "";
    }
    const firstLetter = user.givenName ? user.givenName.charAt(0).toUpperCase() : '';
    const secondLetter = user.familyName ? user.familyName.charAt(0).toUpperCase() : '';
    return (firstLetter || secondLetter) ? firstLetter + secondLetter : '?';
}

export function getName(user) {
    if(_.isEmpty(user)){
        return "";
    }
    const firstName = user.givenName ? user.givenName : '';
    const lastName = user.familyName ? user.familyName : '';
    return (firstName || lastName) ? firstName + ' ' + lastName : '?';
}
