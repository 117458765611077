import * as actionTypes from './QuestionEdit.action.types'

export function createQuestion(questionData) {
    return (dispatch) => {
        const questionInput = mapQuestionForCreate(questionData);
        return dispatch({
            type: actionTypes.CREATE_QUESTION,
            payload: {
                client: 'graphql',
                request: {
                    method: 'post',
                    data: {
                        query: `mutation createQuestion($createQuestionInput: CreateQuestionInput){
    	                        createQuestion(createQuestionInput: $createQuestionInput) {   
    	                            businessId   	                            
    	                        }
                            }
                           `,
                        variables: {
                            createQuestionInput: questionInput
                        }
                    }
                }
            }
        });
    }
}

export function editQuestion(question) {
    return (dispatch) => {
        const questionInput = mapQuestionForEdit(question);
        return dispatch({
            type: actionTypes.EDIT_QUESTION,
            payload: {
                client: 'graphql',
                request: {
                    method: 'post',
                    data: {
                        query: `mutation editQuestion($editQuestionInput: EditQuestionInput){
    	                        editQuestion(editQuestionInput: $editQuestionInput) {   
    	                            businessId   	                            
    	                        }
                            }
                           `,
                        variables: {
                            editQuestionInput: questionInput
                        }
                    }
                }
            }
        });
    }
}

export function fetchQuestion(businessId) {
    return {
        type: actionTypes.FETCH_QUESTION,
        payload: {
            client: 'graphql',
            request: {
                method: 'post',
                data: {
                    query: `
                                query
                                ($questionId: String!){
                                    fetchQuestion(questionId: $questionId) {
                                        businessId
                                        status
                                        translations {
                                            language {
                                                code
                                                label
                                                ordinal
                                            }
                                            question
                                            comment
                                            quickFix
                                            status
                                            origin
                                            picture {
                                                referenceId
                                                downloadUrl
                                            }
                                        }
                                        catalogue {
                                            label
                                        }
                                        tags {
                                            businessId
                                            label
                                            category
                                        }
                                        answerType {
                                            businessId
                                            label
                                            values {
                                                point
                                                label
                                            }
                                        }                                        
                                        assignedQuestionnaires {
                                            name
                                        }
                                    }
                                }
                           `,
                    variables: {
                        questionId: businessId
                    }
                }
            }
        }
    };
}

export function fetchEmptyQuestion() {
    return (dispatch, getState) => {
        return Promise.resolve(dispatch({
            type: actionTypes.FETCH_EMPTY_QUESTION,
            data: {
                supportedLanguages: getState().generalConfigurationReducer.auditConfiguration.supportedLanguages
            }
        }));
    }
}

function mapQuestionForCreate(question) {
    return {
        catalogue: question.catalogue.toUpperCase(),
        answerType: question.answerType.label,
        cycle: question.cycle,
        questionStatus: question.status,
        translations: question.translations
            .filter(translation => translation.isChanged)
            .map(translation => {
                return {
                    language: translation.language.code,
                    status: translation.status,
                    question: translation.question || null,
                    comment: translation.comment || null,
                    quickFix: translation.quickFix || null,
                    pictureReferenceId: translation.pictureReferenceId || null,
                }
            })
    };
}

function mapQuestionForEdit(question) {
    return {
        businessId: question.businessId,
        catalogue: question.catalogue.toUpperCase(),
        answerType: question.answerType.label,
        cycle: question.cycle,
        questionStatus: question.status,
        forceTranslate: question.forceTranslate,
        forcePictureOverride: question.forcePictureOverride,
        translations: question.translations
            .filter(translation => translation.isChanged)
            .map(translation => {
                return {
                    language: translation.language.code,
                    status: translation.status === 'APPROVED' ? translation.status : 'PENDING',
                    question: translation.question || null,
                    comment: translation.comment || null,
                    quickFix: translation.quickFix || null,
                    pictureReferenceId: translation.pictureReferenceId || null,
                }
            })
    };
}
