import React, {Component} from 'react';
import {Typography, withStyles, Checkbox} from "@material-ui/core";
import Flex from "components/grid/Flex";
import _ from "lodash";
import {Trans, withTranslation} from "react-i18next";
import {
    dustyGrey,
    jungleGreen,
    htmlBlack,
    htmlLightGrey,
    htmlWhite,
    questionnaireTitleActive,
    transparentCodGrey20,
    random,
    transparentBlack004,
    transparentBlack087,
    athensGrey
} from "components/colors/Colors";
import {isActive, PENDING} from "domain/audit/Questionnaire.model";
import classNames from 'classnames';
import {questionnaireHeaderIndex} from 'components/zindex/zIndex';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';

const styles = theme => ({
    row: {
        borderBottom: `1px solid ${htmlWhite}`,
        outline: 'none',
        minHeight: 45,
        height: 'auto',
        '&:hover': {
            backgroundColor: transparentBlack004,
        }
    },
    selectedRow: {
        backgroundColor: transparentBlack004,
    },
    cell: {
        color: transparentBlack087,
        fontSize: '0.8125rem',
        fontWeight: 400,
        padding: '4px 10px',
        textTransform: 'capitalize'
    },
    randomCell: {
        color: random,
    },
    header: {
        color: htmlBlack,
        fontWeight: 800,
        textTransform: 'uppercase'
    },
    titleCell: {
        color: htmlWhite,
        fontWeight: 800,
    },
    titleCellLimit: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        width: '0.25vw'
    },
    questionsArea: {
        display: 'flex',
        flex: '1 1 auto',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        width: '45%',
        height: '64vh'
    },
    tableWrapper: {
        maxHeight: '100%',
        width: '100%',
        height: '100%',
        overflow: 'auto',
        backgroundColor: htmlWhite,
        border: `1px solid ${transparentCodGrey20}`,
        borderRadius: 4
    },
    fieldTitle: {
        color: dustyGrey,
        textTransform: 'uppercase',
        fontSize: 12,
        minWidth: 250
    },
    tableTitle: {
        fontWeight: 600
    },
    questionnaireTitle: {
        backgroundColor: jungleGreen,
        color: htmlWhite,
        position: 'sticky',
        top: 0,
        height: 56,
        zIndex: questionnaireHeaderIndex
    },
    questionnaireHeader: {
        backgroundColor: athensGrey,
        color: htmlBlack,
        position: 'sticky',
        top: 56,
        height: 45,
        zIndex: questionnaireHeaderIndex
    },
});

export class StandardSelectedQuestions extends Component {
    render() {
        const {
            classes, selectedQuestions, i18n, questionnaire, handleMarkQuestion, markedQuestion, markedCatalogue,
            multiSelectedOrganizationLevels, randomQuestionsByCatalogue, handleMarkRandomQuestions
        } = this.props;
        const catalogues = Object.keys(randomQuestionsByCatalogue).sort();
        const title = multiSelectedOrganizationLevels.map(level => level.title).join(' / ');
        return (
            <div className={classes.questionsArea}>
                <Typography classes={{root: classNames(classes.fieldTitle, classes.tableTitle)}}>
                    <Trans i18nKey={"audit-administration.questionnaireEdit.questionnaire"}/>
                </Typography>
                <div className={classes.tableWrapper}>
                    <Flex container
                          justifyContent={'space-between'}
                          alignItems={'center'}
                          className={classes.questionnaireTitle}
                          grow={0}
                          style={{backgroundColor: isActive(questionnaire.status) && questionnaireTitleActive}}
                    >

                        <Flex item basis={'80%'}
                              className={classNames(classes.cell, classes.titleCell, classes.titleCellLimit)}>
                            {title}
                        </Flex>
                        <Flex item basis={'20%'} className={classNames(classes.cell, classes.titleCell)}>
                            {questionnaire.cycle &&
                            <Trans i18nKey={"audit-administration.cycle." + questionnaire.cycle}/>}
                        </Flex>
                    </Flex>

                    <Flex container
                          justifyContent={'space-between'}
                          alignItems={'center'}
                          className={classes.questionnaireHeader}
                    >
                        <Flex item basis={'6%'} style={{marginRight: 10}}/>
                        <Flex item basis={'6%'} className={classNames(classes.cell, classes.header)}>
                            <Trans i18nKey={"audit-administration.questionnaireEdit.questions.id"}/>
                        </Flex>
                        <Flex item basis={'74%'} className={classNames(classes.cell, classes.header)}>
                            <Trans i18nKey={"audit-administration.questionnaireEdit.questions.question"}/>
                        </Flex>
                        <Flex item basis={'20%'} className={classNames(classes.cell, classes.header)}>
                            <Trans i18nKey={"audit-administration.questionnaireEdit.questions.catalogue"}/>
                        </Flex>
                    </Flex>
                    {
                        _.sortBy(selectedQuestions, question => parseInt(question.businessId))
                            .map(question => {
                                    const translation = question.translations.find(translation => translation.language.code === i18n.language).question;
                                    const marked = markedQuestion && markedQuestion.businessId === question.businessId;
                                    return (
                                        <Flex container
                                              justifyContent={'space-between'}
                                              alignItems={'center'}
                                              className={marked ? classNames(classes.row, classes.selectedRow) : classes.row}
                                              onClick={handleMarkQuestion(question)}
                                              key={question.businessId}
                                        >
                                            <Flex item basis={'6%'} className={classes.cell}>
                                                <Checkbox
                                                    checked={!!marked}
                                                    icon={<CheckBoxOutlineBlankIcon/>}
                                                    checkedIcon={<CheckBoxIcon className={classes.checkBoxIcon}/>}
                                                    style={{padding: 0, paddingLeft: 6}}
                                                />
                                            </Flex>
                                            <Flex item basis={'6%'} className={classNames(classes.cell)}
                                                  style={{
                                                      color: question.status === PENDING && htmlLightGrey,
                                                      fontWeight: 'bold'
                                                  }}>
                                                {question.businessId}
                                            </Flex>
                                            <Flex item basis={'74%'} className={classNames(classes.cell)}
                                                  style={{color: question.status === PENDING && htmlLightGrey}}>
                                                {translation}
                                            </Flex>
                                            <Flex item basis={'20%'} className={classNames(classes.cell)}
                                                  style={{
                                                      color: question.status === PENDING && htmlLightGrey,
                                                      fontWeight: 'bold'
                                                  }}>
                                                {question.catalogue}
                                            </Flex>
                                        </Flex>
                                    )
                                }
                            )
                    }
                    {
                        catalogues
                            .map(catalogue => {
                                    const numberOfRandomQuestion = randomQuestionsByCatalogue[catalogue];
                                    const marked = markedCatalogue && markedCatalogue === catalogue;
                                    return (
                                        <Flex container
                                              justifyContent={'space-between'}
                                              alignItems={'center'}
                                              className={marked ? classNames(classes.row, classes.selectedRow) : classes.row}
                                              onClick={handleMarkRandomQuestions(catalogue)}
                                              key={catalogue}
                                        >
                                            <Flex item basis={'6%'}
                                                  className={classNames(classes.cell, classes.randomCell)}>
                                                ?
                                            </Flex>
                                            <Flex item basis={'74%'}
                                                  className={classNames(classes.cell, classes.randomCell)}>
                                                {
                                                    numberOfRandomQuestion > 1 ?
                                                        <Trans
                                                            i18nKey={"audit-administration.questionnaireEdit.randomQuestions"}
                                                            values={
                                                                {
                                                                    "number": numberOfRandomQuestion
                                                                }
                                                            }
                                                        />
                                                        : <Trans
                                                            i18nKey={"audit-administration.questionnaireEdit.randomQuestion"}
                                                            values={
                                                                {
                                                                    "number": 1
                                                                }
                                                            }
                                                        />
                                                }
                                            </Flex>
                                            <Flex item basis={'20%'}
                                                  className={classNames(classes.cell, classes.randomCell)}>
                                                {catalogue}
                                            </Flex>
                                        </Flex>
                                    )
                                }
                            )
                    }

                </div>
            </div>
        );
    }
}

export default withStyles(styles)(withTranslation()(StandardSelectedQuestions));
