export const FETCH_AUDITS = '@monitoring/FETCH_AUDITS';
export const FETCH_AUDITS_SUCCESS = '@monitoring/FETCH_AUDITS_SUCCESS';
export const FETCH_AUDITS_FAIL = '@monitoring/FETCH_AUDITS_FAIL';

export const UPDATE_AUDITOR = '@monitoring/UPDATE_AUDITOR';
export const UPDATE_AUDITOR_SUCCESS = '@monitoring/UPDATE_AUDITOR_SUCCESS';
export const UPDATE_AUDITOR_FAIL = '@monitoring/UPDATE_AUDITOR_FAIL';

export const FETCH_DEVIATIONS = '@monitoring/FETCH_DEVIATIONS';
export const FETCH_DEVIATIONS_SUCCESS = '@monitoring/FETCH_DEVIATIONS_SUCCESS';
export const FETCH_DEVIATIONS_FAIL = '@monitoring/FETCH_DEVIATIONS_FAIL';

export const SEND_AUDIT_REMINDER = '@monitoring/SEND_AUDIT_REMINDER';
export const SEND_AUDIT_REMINDER_SUCCESS = '@monitoring/SEND_AUDIT_REMINDER_SUCCESS';
export const SEND_AUDIT_REMINDER_FAIL = '@monitoring/SEND_AUDIT_REMINDER_FAIL';

export const CANCEL_AUDIT_SCHEDULE = '@monitoring/CANCEL_AUDIT_SCHEDULE';
export const CANCEL_AUDIT_SCHEDULE_SUCCESS = '@monitoring/CANCEL_AUDIT_SCHEDULE_SUCCESS';
export const CANCEL_AUDIT_SCHEDULE_FAIL = '@monitoring/CANCEL_AUDIT_SCHEDULE_FAIL';