import React, {Component, Fragment} from 'react';
import {
    withStyles,
    IconButton,
    Typography,
} from "@material-ui/core";
import Flex from 'components/grid/Flex';
import BootstrapInput from 'components/bootstrap-input/BootstrapInput';
import {Trans, withTranslation} from "react-i18next";
import ActionTile from 'scenes/tasks/actions/action-list/mobile/ActionTile';
import {
    Search as SearchIcon,
    Done as DoneIcon,
    DeleteOutlined as DeleteIcon,
} from '@material-ui/icons';
import {mobileTaskListMenu} from 'components/zindex/zIndex';
import {
    htmlGrey,
    greyBackground,
    swipeActionRed,
    swipeActionGreen,
    mobileStatusHeader,
    controlButtonBlue,
    htmlWhite
} from 'components/colors/Colors';
import SwipeableViews from 'react-swipeable-views';
import {withRouter} from 'react-router-dom';
import {emptyActionForQuickCreate} from 'scenes/tasks/actions/my-action-list/MyActionList.reducer';
import {connect} from 'react-redux';
import connector from './MobileMyActionList.connect';
import {NEW} from 'components/quill-editor/EditorUtils';
import TaskUpdateEventBus from 'scenes/tasks/TaskUpdateEventBus';
import {CREATE_ACTION_TO_CACHE, CREATE_ACTION_SUCCESS} from 'scenes/tasks/actions/action-edit/ActionEdit.action.types';
import _ from 'lodash';

const styles = theme => ({
    rootContainer: {
        position: 'absolute',
        top: 0,
        bottom: 0,
        right: 0,
        left: 0,
        marginTop: 54,
        paddingLeft: 10,
        paddingRight: 10
    },
    taskTileAbsoluteContainer: {
        overflow: 'auto',
        overflowScrolling: 'touch',
        position: 'absolute',
        top: 0,
        bottom: 0,
        right: 0,
        left: 0,
    },
    pdcaStatusItem: {
        backgroundColor: mobileStatusHeader,
        padding: 4,
        fontWeight: 'bold',
        position: 'sticky',
        width: '100%',
        top: 0,
        zIndex: mobileTaskListMenu,
    },
    swipeActionButton: {
        paddingLeft: 15,
        paddingRight: 15,
        width: 60,
    },
    swipeActionLabel: {
        fontSize: 13
    }

});

const QuickTaskAction = (props) => {
    const {createQuickAction, handleChange, translate, searchText} = props;
    return (
        <Flex item grow={0} container style={{paddingBottom: 10}}>
            <IconButton disabled style={{padding: 0}}>
                <SearchIcon style={{fontSize: 30, marginRight: 5}}/>
            </IconButton>
            <BootstrapInput
                value={searchText}
                tabIndex="-1"
                placeholder={translate('task-management.task-list.quickTaskPlaceholder')}
                onChange={handleChange}
                onEnterKey={createQuickAction}
                name={'searchText'}
                fullWidth
                inputProps={{
                    style: {
                        backgroundColor: greyBackground,
                        borderRadius: 10,
                        border: 'unset'
                    }
                }}
            />
            <IconButton onClick={createQuickAction} style={{marginLeft: 5, padding: 0}}>
                <DoneIcon style={{color: controlButtonBlue}}/>
            </IconButton>
        </Flex>
    );
};

const Actions = (props) => {
    const {
        classes, actions, pdcaLabel, swipedAction, handleActionSwipe, archive, done, user
    } = props;
    const pdcaFilteredActions = actions.filter(action => action.status === pdcaLabel);
    const label = pdcaLabel && pdcaLabel.replace('_', ' ');
    return (
        <div>
            <Flex container item={'0 1 auto'} className={classes.pdcaStatusItem} justifyContent={'center'}
                  style={{marginBottom: 8}}>
                <Typography style={{fontWeight: 'bold', fontSize: 17}}>{label}</Typography>
            </Flex>
            {
                pdcaFilteredActions.map((action, index) => (
                    <SwipeAbleAction {...{
                        key: action.businessId,
                        classes,
                        index: swipedAction === action.businessId ? 1 : 0,
                        action,
                        handleActionSwipe,
                        archive,
                        taskBusinessId: action.taskBusinessId,
                        done,
                        user
                    }}/>
                ))
            }
        </div>
    )
};

class SwipeActions extends React.PureComponent {
    render() {
        const {classes, action, taskBusinessId, archive, done, user} = this.props;
        return (
            <Flex item container style={{borderBottom: '1px solid grey'}}>
                <Flex item container>
                    <ActionTile {...{
                        action,
                        taskBusinessId,
                        isSwiped: true
                    }}/>
                </Flex>
                <Flex item container justifyContent={'flex-end'} style={{paddingLeft: 0}}>
                    <Flex item grow={0} container direction={'column'} alignItems={'center'} justifyContent={'center'}
                          className={classes.swipeActionButton}>
                        <IconButton style={{backgroundColor: swipeActionRed, color: htmlWhite, padding: 5}}
                                    disabled={!user.isAdmin}
                                    onClick={archive(action)}>
                            <DeleteIcon/>
                        </IconButton>
                        <Typography className={classes.swipeActionLabel}>
                            <Trans i18nKey="global.delete"/>
                        </Typography>
                    </Flex>
                    <Flex item grow={0} container direction={'column'} alignItems={'center'} justifyContent={'center'}
                          className={classes.swipeActionButton}>
                        <IconButton style={{backgroundColor: swipeActionGreen, color: htmlWhite, padding: 5}}
                                    onClick={done(action)}>
                            <DoneIcon/>
                        </IconButton>
                        <Typography className={classes.swipeActionLabel}>
                            <Trans i18nKey="global.done"/>
                        </Typography>
                    </Flex>
                </Flex>
            </Flex>
        );
    }
}

class SwipeAbleAction extends React.PureComponent {

    render() {
        const {classes, index, action, handleActionSwipe, archive, done, taskBusinessId, user} = this.props;
        return <SwipeableViews
            index={index}
            onChangeIndex={handleActionSwipe(action)}
            slideStyle={{display: 'flex'}}
        >
            <Flex item container style={{borderBottom: `1px solid ${htmlGrey}`, marginBottom: 5}}>
                <ActionTile {...{
                    action,
                    taskBusinessId
                }}/>
            </Flex>

            <SwipeActions {...{
                action,
                classes,
                archive,
                done,
                taskBusinessId,
                user
            }}/>
        </SwipeableViews>
    }
}

export class MobileMyActionList extends Component {
    state = {
        searchText: '',
        swipedAction: ''
    };

    bottom = React.createRef();

    componentDidMount() {
        this.updateSubscription = TaskUpdateEventBus.update.subscribe(payload => {
            if (payload.type === CREATE_ACTION_SUCCESS || payload.type === CREATE_ACTION_TO_CACHE) {
                this.scrollToBottom();
            }
        });
    }

    componentWillUnmount() {
        TaskUpdateEventBus.update.unSubscribe(this.updateSubscription);
    }

    scrollToBottom = () => {
        this.bottom.current && this.bottom.current.scrollIntoView({
            block: "end",
            inline: "nearest",
            behavior: "smooth"
        });
    };

    handleChange = event => {
        this.setState({
            [event.target.name]: event.target.value,
        });
    };

    searchTextFilter = action => {
        const matchingTranslation = action.translations
            .find(translation => translation.language.code === this.props.i18n.language) || action.translations[0];
        return this.state.searchText === ''
            ? true
            :
            matchingTranslation.title.toLowerCase().includes(this.state.searchText.toLowerCase()) ||
            action.businessId.includes(this.state.searchText);
    };

    userFilter = action => {
        return this.props.selectedUserNames.length === 0 || this.props.selectedUserNames.includes(_.get(action, 'assignee.username'));
    };

    doneFilter = action => {
        return action.status !== 'DONE'
    };

    createQuickAction = () => {
        let action = {
            ...emptyActionForQuickCreate(
                this.props.todoTaskBusinessId,
                this.props.user,
                this.props.configurationFromBackend.supportedLanguages,
                this.props.configurationFromBackend.deadlines,
            ),
        };
        const matchingTranslation = action.translations.find(translation => translation.language.code === this.props.i18n.language);
        matchingTranslation.title = this.state.searchText;
        matchingTranslation.origin = NEW;
        this.setState({
            searchText: ''
        });
        if (matchingTranslation.title !== '') {
            this.props.createAction(this.props.todoTaskBusinessId, action);
        }
    };

    archive = action => () => {
        this.resetSwipe();
        this.props.archiveAction(action).then(() => {
            this.props.fetchActions()
        });
    };

    done = action => () => {
        this.resetSwipe();
        this.props.toggleDoneAction(action).then(() => {
            this.props.fetchActionFromServer(action.businessId)
        });
    };

    handleActionSwipe = action => index => {
        if (index === 0) {
            this.setState({
                swipedAction: ''
            })
        } else if (index === 1) {
            this.setState({
                swipedAction: action.businessId
            })
        }
    };

    resetSwipe = () => {
        this.setState({
            swipedAction: ''
        })
    };

    sortByMyUnseen = (a, b) => {
        if (a.isUnseen && !b.isUnseen && (_.get(a, 'assignee.businessId') === this.props.user.businessId)) {
            return -1;
        }
        if (!a.isUnseen && b.isUnseen) {
            return 1;
        }
        return 0;
    };

    render() {
        const {classes, actions, t: translate, user} = this.props;
        const {searchText, swipedAction} = this.state;
        const {handleChange, createQuickAction, archive, handleActionSwipe, done} = this;
        const filteredActions = actions
            .filter(this.userFilter)
            .filter(this.doneFilter)
            .filter(this.searchTextFilter)
            .sort(this.sortByMyUnseen);

        return (

            <Fragment>
                <Flex container item direction={'column'} className={classes.rootContainer}>

                    <QuickTaskAction {...{searchText, createQuickAction, handleChange, translate}}/>

                    <Flex container item direction={'column'} style={{position: 'relative', padding: 10}}>
                        <div className={classes.taskTileAbsoluteContainer}>
                            <Actions {...{
                                classes,
                                user,
                                actions: filteredActions,
                                pdcaLabel: 'IN_PROGRESS',
                                swipedAction, handleActionSwipe, archive, done
                            }} />
                            <div id={'bottom-action-for-scroll'} ref={this.bottom}/>
                        </div>
                    </Flex>


                </Flex>
            </Fragment>
        );
    }

}

export default withStyles(styles)(connect(connector.mapStateToProps, connector.mapDispatchToProps)(withTranslation()(withRouter(MobileMyActionList))));
