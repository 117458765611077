import React, {Component} from 'react';
import ListPage from "scenes/configuration/components/ListPage";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import connector from "./AuditTypes.connect";
import _ from 'lodash';

export class AuditTypes extends Component {

    softDeleteItem = itemId => {
        this.props.removeAuditType(itemId, false);
    };

    confirmedDeleteItem = () => {
        const itemToBeDeleted = _.get(this.props.assignedAuditSchedules,'auditType.businessId', '');
        this.props.removeAuditType(itemToBeDeleted, true).then(this.props.resetToBeConfirmedAuditSchedules);
    };

    render() {
        const {t: translate, auditTypes, createAuditType, resetToBeConfirmedAuditSchedules,
            assignedAuditSchedules
        } = this.props;
        const {softDeleteItem, confirmedDeleteItem} = this;

        const domainPath = 'routes.general-configuration.audit-types.';

        return (
            <ListPage {...{
                titleSingular: translate(domainPath + 'title_singular'),
                titlePlural: translate(domainPath + 'title'),
                confirmationHeaderKey: domainPath + "deleteConfirmation.header",
                confirmationTextKey: domainPath + "deleteConfirmation.statement",
                confirmationTexValues: {name: _.get(assignedAuditSchedules,'auditType.label', '')},
                confirmationQuestionKey: domainPath + "deleteConfirmation.question",
                confirmationQuestionValues: {name: _.get(assignedAuditSchedules,'auditType.label', '')},
                items: auditTypes,
                itemsToConfirm: _.get(assignedAuditSchedules,'auditSchedules', []),
                onAddition: createAuditType,
                onSoftDeletion: softDeleteItem,
                onHardDeletion: confirmedDeleteItem,
                resetItemsToConfirm: resetToBeConfirmedAuditSchedules
            }}/>
        );
    }
}

export default connect(connector.mapStateToProps, connector.mapDispatchToProps)(withTranslation()(AuditTypes));
