import React, {Component} from 'react';
import {Divider} from "@material-ui/core";
import {withRouter} from 'react-router-dom';
import Flex from 'components/grid/Flex';
import {withTranslation} from "react-i18next";
import ActionListTile from './ActionListTile';
import _ from 'lodash';
import QuickIssueInput from 'scenes/tasks/components/QuickActionInputField';
import {TASK_ACTION_EDIT, TASK_ACTION_CREATE} from 'routes/routes';
import {routeTo} from 'routes/routes';
import {connect} from 'react-redux';
import connector from './DesktopActionList.connect';
import {emptyAction} from 'domain/task/Action.model';
import {NEW} from 'components/quill-editor/EditorUtils';

const ActionList = (props) => {
    const {
        actions, handleClickOnAction, applicationOnline, isTaskOfflineCreated, user, removeUnseenCommentTag,
        removeUnseenAttachmentTag, refresh
    } = props;
    return (
        <Flex container item grow={0} direction={'column'} style={{marginTop: 20}}>
            <Flex container item direction={'column'}>
                <Divider/>
                {
                    actions.map(action => (
                        <div key={action.businessId}>
                            <ActionListTile {...{
                                action, handleDoubleClickOnAction: () => {}, handleClickOnAction, applicationOnline,
                                isTaskOfflineCreated, user, refresh, removeUnseenCommentTag, removeUnseenAttachmentTag
                            }}/>
                            <Divider/>
                        </div>
                    ))
                }
            </Flex>
        </Flex>
    );
};

export class DesktopActionList extends Component {

    state = {
        actionTitle: '',
    };

    quickActionInput = React.createRef();

    componentDidMount() {
        if (this.props.onOpen) {
            this.props.onOpen();
        }
    }

    handleClickOnAction = selectedAction => () => {
        routeTo(TASK_ACTION_EDIT.pathWithIds(
            this.props.taskBusinessId,
            _.get(selectedAction, 'businessId')), this.props);
    };

    handleChange = event => {
        this.setState({
            [event.target.name]: event.target.value,
        });
    };

    createQuickAction = () => {
        let action = {
            ...emptyAction(
                this.props.taskBusinessId,
                this.props.user,
                this.props.dueDate,
                this.props.supportedLanguages,
            ),
        };
        const matchingTranslation = action.translations
            .find(translation => translation.language.code === this.props.i18n.language);
        matchingTranslation.title = this.state.actionTitle;
        matchingTranslation.origin = NEW;
        this.setState({
            actionTitle: ''
        });
        if (matchingTranslation.title !== '') {
            this.props.createAction(this.props.taskBusinessId, action);
        }
    };

    removeUnseenAttachmentTag = action => () => {
        if (action.hasUnseenAttachment) {
            this.props.removeUnseenAttachmentTag(action);
        }
    };

    removeUnseenCommentTag = action => () => {
        if (action.hasUnseenComment) {
            this.props.removeUnseenCommentTag(action);
        }
    };

    fetchTask = () => {
        return this.props.fetchTask(this.props.taskBusinessId);
    };

    render() {
        const {
            actions, applicationOnline, user, t: translate, location, taskBusinessId
        } = this.props;
        const {
            quickActionInput, handleClickOnAction, handleChange, createQuickAction, removeUnseenCommentTag,
            removeUnseenAttachmentTag, fetchTask
        } = this;
        const {actionTitle} = this.state;
        return (
            <Flex item container direction={'column'}>
                <Flex item container direction={'column'}>
                    <ActionList
                        {...{
                            actions,
                            applicationOnline,
                            isTaskOfflineCreated: taskBusinessId.startsWith('OFFLINE'),
                            handleClickOnAction,
                            user,
                            removeUnseenCommentTag,
                            removeUnseenAttachmentTag,
                            refresh: fetchTask
                        }}
                    />
                    <QuickIssueInput {...{
                        user,
                        location,
                        name: 'actionTitle',
                        issueTitle: actionTitle,
                        onChange: handleChange,
                        onEnter: createQuickAction,
                        reference: quickActionInput,
                        placeHolder: translate('task-management.action-list.quickActionPlaceholder'),
                        pathname: TASK_ACTION_CREATE.pathWithId(taskBusinessId)
                    }}/>
                    <Divider/>
                </Flex>
            </Flex>
        );
    }
}

export default withTranslation()(withRouter(connect(connector.mapStateToProps, connector.mapDispatchToProps)(DesktopActionList)));
