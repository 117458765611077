import {fetchAuditManagementConfigurationData} from "./GeneralConfiguration.action"

export default {
    mapStateToProps: (state) => {
        return {
            auditConfiguration: state.generalConfigurationReducer.auditConfiguration
        };
    },
    mapDispatchToProps: (dispatch) => {
        return {
            fetchAuditManagementConfigurationData: () => dispatch(fetchAuditManagementConfigurationData()),
        };
    }
};
