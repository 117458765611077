import React, {Component, Fragment} from 'react';
import {MenuItem, withStyles} from '@material-ui/core';
import {withTranslation} from 'react-i18next';
import _ from 'lodash';
import MaterialDatePicker from 'components/material-datepicker/MaterialDatePicker';
import moment from 'moment';
import {getMinDate} from "domain/audit/Schedule.model";
import {htmlWhite} from 'components/colors/Colors.js'
import BootstrapSelect from "components/bootstrap-select/BootstrapSelect";

const styles = theme => ({});

const MAX_FUTURE_DATE_IN_MONTHS = 12;

const MonthSelector = (props) => {
    const {selectedDate, handleMonthChange, translate, getMonths, isEditDisabled} = props;
    return (
        <BootstrapSelect
            value={selectedDate}
            disabled={isEditDisabled}
            onChange={handleMonthChange}
            renderValue={function renderValue(value) {
                const year = moment(value).format('YYYY');
                const month = props.translate('global.month.' + moment(value).format('MMMM'));
                return (
                    <span>
                        {
                            translate('global.dateTimeFormats.month', {
                                year: year,
                                month: month
                            })
                        }
                    </span>
                )
            }}
        >
            {
                (getMonths()).map((item, index) => {
                        const year = moment(item).format('YYYY');
                        const month = translate('global.month.' + moment(item).format('MMMM'));
                        return (
                            <MenuItem value={item}
                                      key={index} style={{width: 325}}>
                                {
                                    <span>
                                               {translate('global.dateTimeFormats.month', {
                                                   year: year,
                                                   month: month
                                               })}
                                     </span>
                                }
                            </MenuItem>
                        )
                    }
                )
            }
        </BootstrapSelect>
    )
};

export class NextScheduleDatePicker extends Component {

    getOpenTo = (duration) => {
        switch (duration) {
            case 'YEAR':
                return 'year';
            case 'MONTH':
                return 'month';
            default:
                return undefined;
        }
    };

    shouldDisableDate = (duration) => (date) => {
        if (duration === 'WEEK') {
            return moment(date).isoWeekday() !== 1;
        }
        return false;
    };

    getMaxDate = () => {
        return moment().add(MAX_FUTURE_DATE_IN_MONTHS, 'month').toDate();
    };

    getMonths = () => {
        const months = [];
        for (let i = 0; i < MAX_FUTURE_DATE_IN_MONTHS; i++) {
            months.push(moment().startOf("month").add(i, 'month'))
        }
        return months;
    };

    handleMonthChange = (event) => {
        this.props.handleDateChange(event.target.value);
    };

    render() {
        const {selectedDate, handleDateChange, t: translate, duration, isEditDisabled} = this.props;
        const {getOpenTo, shouldDisableDate, getMaxDate, getMonths, handleMonthChange} = this;
        const openTo = getOpenTo(duration);
        const minDate = getMinDate(duration);
        const maxDate = getMaxDate();

        return (
            <Fragment>
                {
                    (() => {
                        switch (duration) {
                            case 'MONTH': {
                                return (
                                    <MonthSelector {...{
                                        selectedDate,
                                        handleMonthChange,
                                        translate,
                                        getMonths,
                                        isEditDisabled
                                    }}/>
                                )
                            }
                            default: {
                                return (
                                    <MaterialDatePicker
                                        disablePast={true}
                                        disabled={isEditDisabled}
                                        value={selectedDate}
                                        openTo={openTo}
                                        minDate={minDate}
                                        maxDate={maxDate}
                                        shouldDisableDate={shouldDisableDate(duration)}
                                        labelFunc={(date) => {
                                            if (_.isEmpty(date)) return '';
                                            const year = moment(date).format('YYYY');
                                            const month = translate('global.month.' + moment(date).format('MMMM'));
                                            const week = moment(date).format('WW');
                                            const dayOfWeek = translate('global.day.' + moment(date).format('dddd'));
                                            const day = moment(date).format('DD');
                                            const startOfWeek = moment(date).startOf('isoWeek');
                                            const endOfWeek = moment(date).endOf('isoWeek');
                                            const startOfWeekMonth = translate('global.month.' + startOfWeek.format('MMMM')).substring(0, 3);
                                            const startOfWeekDay = startOfWeek.format('DD');
                                            const endOfWeekMonth = translate('global.month.' + endOfWeek.format('MMMM')).substring(0, 3);
                                            const endOfWeekDay = endOfWeek.format('DD');

                                            switch (duration) {
                                                case 'DAY':
                                                    return translate('global.dateTimeFormats.day', {
                                                        year: year,
                                                        month: month,
                                                        day: day,
                                                        dayOfWeek: dayOfWeek
                                                    });
                                                case 'WEEK':
                                                    return translate('global.dateTimeFormats.week', {
                                                        year: year,
                                                        week: week,
                                                        startOfWeekMonth: startOfWeekMonth,
                                                        startOfWeekDay: startOfWeekDay,
                                                        endOfWeekMonth: endOfWeekMonth,
                                                        endOfWeekDay: endOfWeekDay
                                                    });
                                                case 'MONTH':
                                                    return translate('global.dateTimeFormats.month', {
                                                        year: year,
                                                        month: month
                                                    });
                                                case 'YEAR':
                                                    return year;
                                                default:
                                                    return translate('global.dateTimeFormats.day', {
                                                        year: year,
                                                        month: month,
                                                        day: day,
                                                        dayOfWeek: dayOfWeek
                                                    });
                                            }
                                        }}
                                        onChange={handleDateChange}
                                        style={{
                                            width: '100%',
                                            background: htmlWhite
                                        }}
                                    />
                                )
                            }

                        }
                    })()
                }
            </Fragment>
        );
    }

}

export default withStyles(styles)(withTranslation()(NextScheduleDatePicker));
