import React, {
    PureComponent,
} from 'react';
import {
    IconButton,
    withStyles,
} from "@material-ui/core";
import Flex from 'components/grid/Flex';
import {ReactComponent as DeleteFilter} from 'assets/img/delete-filter.svg';
import {withTranslation} from "react-i18next";
import {greyBackground, doveGrey} from 'components/colors/Colors';
import BootstrapInput from 'components/bootstrap-input/BootstrapInput';
import MaterialSelect from 'components/material-select/MaterialSelect';
import _ from 'lodash';

export const styles = theme => ({
    topLineFilters: {
        paddingBottom: 10
    },
    inputContainerMargins: {
        marginRight: 10
    }
});

export class QuestionsFilters extends PureComponent {
    render() {
        const {
            classes, t: translate, filterOptions, filterState, handleNamedStateChange, resetFilters
        } = this.props;
        const extendedCatalogues = [...filterOptions.catalogues, 'unassigned'];
        return (
            <Flex container className={classes.topLineFilters} direction={'column'}>
                <Flex container item>
                    {
                        !_.isEmpty(extendedCatalogues) &&
                        <Flex item={'0 1 200px'} container alignItems={'center'}
                              className={classes.inputContainerMargins}>
                            <MaterialSelect
                                options={extendedCatalogues}
                                value={filterState.catalogue || ''}
                                name={'catalogue'}
                                label={translate('audit-management.questions.header.catalogue')}
                                handleChange={handleNamedStateChange}
                            />
                        </Flex>
                    }
                    {
                        !_.isEmpty(filterOptions.answerTypes) &&
                        <Flex item={'0 1 200px'} container alignItems={'center'}
                              className={classes.inputContainerMargins}>
                            <MaterialSelect
                                options={filterOptions.answerTypes}
                                value={filterState.answerType || ''}
                                name={'answerType'}
                                label={translate('audit-management.questions.header.answerType')}
                                handleChange={handleNamedStateChange}
                                translationPath={"audit-administration.answerType."}
                            />
                        </Flex>
                    }
                    {
                        !_.isEmpty(filterOptions.cycles) &&
                        <Flex item={'0 1 200px'} container alignItems={'center'}
                              className={classes.inputContainerMargins}>
                            <MaterialSelect
                                options={filterOptions.cycles}
                                value={filterState.cycle || ''}
                                name={'cycle'}
                                label={translate('audit-management.questions.header.cycle')}
                                handleChange={handleNamedStateChange}
                                translationPath={"audit-administration.cycle."}
                            />
                        </Flex>
                    }
                    <Flex item={'0 1 0'} container alignItems={'center'} justifyContent={'flex-end'}>
                        <IconButton style={{padding: 0}}
                                    onClick={resetFilters}>
                            <DeleteFilter style={{fontSize: 25, width:40, fill: doveGrey}}/>
                        </IconButton>
                    </Flex>
                </Flex>
                <Flex container item justifyContent={'space-between'} style={{paddingTop: 10}}>
                    <Flex item={'0 1 410px'} className={classes.inputContainerMargins}>
                        <BootstrapInput
                            value={filterState.searchText}
                            placeholder={translate('global.search')}
                            onChange={handleNamedStateChange}
                            name={'searchText'}
                            fullWidth
                            inputProps={{
                                style: {
                                    backgroundColor: greyBackground,
                                    borderRadius: 10,
                                    border: 'unset'
                                },
                                name: 'searchText'
                            }}
                            style={{border: 'unset'}}
                        />
                    </Flex>
                    <Flex container item justifyContent={'flex-end'}>
                        {
                            !_.isEmpty(filterOptions.supportedLanguages) &&
                            <Flex item={'0 1 200px'} container alignItems={'center'}
                                  className={classes.inputContainerMargins}>
                                <MaterialSelect
                                    options={filterOptions.supportedLanguages.map(lang => lang.label)}
                                    value={filterState.language || ''}
                                    name={'language'}
                                    label={translate('audit-management.questions.header.language')}
                                    handleChange={handleNamedStateChange}
                                />
                            </Flex>
                        }
                    </Flex>
                </Flex>

            </Flex>
        );
    }
}

export default withStyles(styles)(withTranslation()(QuestionsFilters));
