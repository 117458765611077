import React, { Component, Fragment } from 'react';
import { withStyles } from "@material-ui/core";

const styles = theme => ({
});

export class Timezone extends Component {
    render() {
        // const {classes} = this.props;

        return (
            <Fragment>
                Timezone
            </Fragment>
        );
    }
}

export default withStyles(styles)(Timezone);
