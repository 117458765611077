import {
    string,
    shape,
    number,
    any,
    arrayOf
} from "prop-types"
import {USER, USER_GRAPHQL, OWNER_GRAPHQL, mapUser, mapOwner} from 'domain/User.model';
import {ATTACHMENT, ATTACHMENT_GRAPHQL, mapAttachment} from 'domain/Attachment.model';
import {COMMENT, COMMENT_GRAPHQL, mapComment} from 'domain/Comment.model';
import {
    TRANSLATION_BACKEND,
    TRANSLATION_FOR_EDIT,
    TRANSLATION_GRAPHQL,
    apiTranslation,
    editableTranslation,
    emptyEditableTranslations,
    manualTranslationInput,
    offlineTranslations
} from './Translation.model';
import {TAG_GRAPHQL, mapTag} from 'domain/Tag.model';
import {PRIORITY_GRAPHQL} from './Priority.model';
import {DELTA_GRAPHQL} from './Delta.model';
import {PRIORITY} from './Priority.model';
import {DELTA} from './Delta.model';
import {OWNER} from 'domain/User.model';
import {TAG} from 'domain/Tag.model';
import utils from 'utils/Utils';
import moment from 'moment';
import _ from 'lodash';
import configs from "configs/Configs";

export const ACTION_BACKEND_PROPTYPE = {
    businessId: string,
    translations: arrayOf(shape(TRANSLATION_BACKEND)),
    assignee: shape(USER),
    status: string,
    comments: arrayOf(shape(COMMENT)),
    priority: shape(PRIORITY),
    progress: number,
    creationDate: any,
    dueDate: any,
    finishedDate: any,
    attachments: arrayOf(shape(ATTACHMENT)),
    owner: shape(OWNER),
    timeSpent: any,
    estimatedEffort: any,
    delta: shape(DELTA),
    tags: arrayOf(shape(TAG)),
};

export const ACTION_FOR_LISTING = {
    businessId: string,
    owner: shape(OWNER),
    assignee: shape(USER),
    priority: shape(PRIORITY),
    translations: arrayOf(shape(TRANSLATION_BACKEND)),
    attachments: arrayOf(shape(ATTACHMENT)),
    comments: arrayOf(shape(COMMENT)),
    progress: number,
    dueDate: any,
    dueDateProgress: number
};

/**
 * As of writing this all props from the backend can be present during edit
 */
export const ACTION_FOR_EDIT = {
    ...ACTION_BACKEND_PROPTYPE,
    translations: arrayOf(shape(TRANSLATION_FOR_EDIT)),
};

export const ACTION_ARCHIVED_STATUS = "ARCHIVED";

export const ACTION_GRAPHQL = `
    businessId
    projectBusinessId
    taskBusinessId
    translations {
        ${TRANSLATION_GRAPHQL}
    }
    assignee {
        ${USER_GRAPHQL}
    }
    status
    comments {
        ${COMMENT_GRAPHQL}
    }
    priority {
        ${PRIORITY_GRAPHQL}
    }
    progress
    creationDate
    dueDate
    finishedDate
    attachments {
        ${ATTACHMENT_GRAPHQL}
    }
    owner {
        ${OWNER_GRAPHQL}
    }
    timeSpent
    estimatedEffort
    delta {
        ${DELTA_GRAPHQL}
    }
    tags {
        ${TAG_GRAPHQL}
    }
`;

export function apiAction(action) {
    return {
        businessId: action.businessId || action.actionBusinessId,
        taskBusinessId: action.taskBusinessId,
        projectBusinessId: action.projectBusinessId,
        translations: action.translations ? action.translations.map(apiTranslation) : [],
        assignee: mapUser(action.assignee),
        status: action.status,
        comments: action.comments ? action.comments.map(mapComment) : [],
        priority: action.priority,
        progress: action.progress,
        creationDate: action.creationDate,
        dueDate: action.dueDate,
        finishedDate: action.finishedDate,
        attachments: action.attachments ? action.attachments.map(mapAttachment) : [],
        owner: mapOwner(action.owner),
        timeSpent: action.timeSpent,
        estimatedEffort: action.estimatedEffort,
        delta: action.delta,
        tags: action.tags && action.tags.map(mapTag),
        dueDateProgress: utils.dueDateProgress(action.creationDate, action.dueDate, new Date()),
        isUnseen: _.some(_.get(action, 'tags', []), ['label', 'UNSEEN_CONTENT']),
        hasUnseenAttachment: _.some(_.get(action, 'tags', []), ['label', 'UNSEEN_ATTACHMENT']),
        hasUnseenComment: _.some(_.get(action, 'tags', []), ['label', 'UNSEEN_COMMENT']),
    }
}

export function editableAction(action, supportedLanguages) {
    const missingTranslations = emptyEditableTranslations(supportedLanguages
        .filter(language =>
            !_.some(action.translations, translation => translation.language.code === language.code)
        ));
    const translations = _.get(action, 'translations', []).map(editableTranslation);
    return {
        ...apiAction(action),
        translations: translations.concat(missingTranslations)
    }
}

export function emptyAction(taskBusinessId, user, dueDate, languages) {
    return {
        translations: emptyEditableTranslations(languages),
        assignee: user,
        status: 'IN_PROGRESS',
        comments: [],
        priority: {
            type: 'NORMAL'
        },
        progress: 0,
        creationDate: new Date(),
        dueDate: mapDueDate(dueDate),
        attachments: [],
        owner: user,
        timeSpent: '',
        estimatedEffort: '',
        tags: [],
        taskBusinessId: taskBusinessId,
    }
}

export function mapActionForUpload(action) {
    return {
        dueDate: action.dueDate ? moment.utc(action.dueDate).format(utils.API_DATE_FORMAT) : null,
        finishedDate: action.finishedDate ? moment.utc(action.finishedDate).format(utils.API_DATE_FORMAT) : null,
        priority: action.priority.type,
        status: action.status || null,
        progress: action.progress || 0,
        estimatedEffort: action.estimatedEffort || null,
        timeSpent: action.timeSpent || null,
    }
}

export function createActionInput(action, referenceId, taskBusinessId) {
    return {
        ...mapActionForUpload(action),
        translation: manualTranslationInput(action.translations, true),
        referenceId: referenceId,
        taskBusinessId: taskBusinessId,
        assigneeId: _.get(action, 'assignee.businessId')
    }
}

export function createActionForProjectInput(action, referenceId, projectBusinessId) {
    return {
        ...mapActionForUpload(action),
        translation: manualTranslationInput(action.translations, true),
        referenceId: referenceId,
        projectBusinessId: projectBusinessId,
        assigneeId: _.get(action, 'assignee.businessId')
    }
}

export function editActionInput(action, forceTranslate) {
    return {
        ...mapActionForUpload(action),
        translation: manualTranslationInput(action.translations, forceTranslate),
        actionBusinessId: action.businessId,
        assigneeId: _.get(action, 'assignee.businessId')
    }
}

export function offlineAction(taskBusinessId, action, forceTranslate) {
    return {
        ...apiAction(action),
        taskBusinessId: taskBusinessId,
        translations: offlineTranslations(action.translations, forceTranslate),
        businessId: action.businessId || ('OFFLINE-' + utils.uuid()),
        dueDate: moment.utc(action.dueDate).format(utils.API_DATE_FORMAT),
        finishedDate: action.finishedDate ? moment.utc(action.finishedDate).format(utils.API_DATE_FORMAT) : null,
        creationDate: moment.utc(action.creationDate || (new Date())).format(utils.API_DATE_FORMAT),
    }
}

export function syncActionInput(action) {
    const isNewAction = action.businessId.startsWith("OFFLINE");
    return {
        ...mapActionForUpload(action),
        translation: manualTranslationInput(action.translations, isNewAction),
        assigneeId: _.get(action, 'assignee.businessId'),
        businessId: action.businessId
    }
}

function mapDueDate(dueDate) {
    return new Date().getTime() < new Date(dueDate).getTime()
        ? new Date(dueDate)
        : moment().add(1, 'days').toDate();
}

export function getActionBusinessId(action, translate) {
    const actionBusinessId = action.businessId.startsWith('OFFLINE')
        ? 'OFFLINE-' + translate('global.action')
        : _.get(action, 'businessId', '').replace('ACTION-', configs.getActionPrefix() + '-');
    return actionBusinessId;
}
