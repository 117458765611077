import React, {PureComponent} from 'react';
import {
    withStyles,
} from "@material-ui/core";
import classNames from 'classnames';
import {unseenCircle} from 'components/colors/Colors';
import Flex from 'components/grid/Flex';

export const styles = theme => ({
    unseenCircle: {
        width: 10,
        height: 10,
        borderRadius: '50%',
        marginRight: 10,
        position: 'absolute',
        left: -15
    },
    unseenCircleIcon: {
        left: -3,
        top: -3,
        position: 'absolute'
    }
});

export class UnseenCircle extends PureComponent {
    render() {
        const {
            classes, visibilityCondition, icon
        } = this.props;
        return (
            <Flex item={'0 0 10px'}
                className={
                    icon ? classNames(classes.unseenCircle, classes.unseenCircleIcon) : classes.unseenCircle
                }
                style={{
                    backgroundColor: visibilityCondition && unseenCircle,
                }}/>
        )
    }
};

export default withStyles(styles)(UnseenCircle);
