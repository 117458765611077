import React, {Component} from 'react';
import {IconButton, CircularProgress} from "@material-ui/core";
import ForumIcon from '@material-ui/icons/Forum';
import Comment from './Comment';
import UnseenNumber from 'components/unseen/UnseenNumber';
import _ from 'lodash';

export class CommentIndicator extends Component {

    state = {
        isIndicator: true
    };

    loadCommentComponent = () => {
        this.setState({isIndicator: false});
        _.get(this.props, 'onOpen') && this.props.onOpen();
    };

    render() {
        const {
            numberOfComments, comments, onOpen, classes, entityId, type, iconColor, isUnseen, isLoading, refresh
        } = this.props;

        return (
            this.state.isIndicator
                ?
                <IconButton style={{padding: 2, fontSize: 12, color: iconColor}} onClick={this.loadCommentComponent}>
                    <ForumIcon/>
                    <UnseenNumber {...{isUnseen, number: numberOfComments, seenColor: iconColor}}/>
                </IconButton>
                :
                isLoading
                    ?
                    <CircularProgress size={18} style={{margin: 5}}/>
                    :
                    <Comment
                        {...{
                            onOpen,
                            classes,
                            entityId,
                            comments,
                            type,
                            forceOpen: true,
                            iconColor,
                            onClose: refresh
                        }}
                    />
        );
    }
}

export default CommentIndicator;
