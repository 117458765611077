import React, {PureComponent} from "react";
import {
    withStyles,
    IconButton,
    Tooltip
} from "@material-ui/core";
import {CheckCircle as CheckIcon, OpenInNew} from "@material-ui/icons";
import Flex from 'components/grid/Flex';
import BootstrapInput from 'components/bootstrap-input/BootstrapInput';
import H4Avatar from 'components/avatar/H4Avatar'
import utils from 'utils/Utils';
import {Link} from 'react-router-dom';
import {greyBackground, controlButtonBlue} from 'components/colors/Colors';
import {prevPath} from 'routes/routes';
import ids from 'components/ids/ids';

const styles = theme => ({
    quickIssueContainer: {
        backgroundColor: greyBackground,
        borderRadius: 10,
    },
    quickIssueInput: {
        lineHeight: 1,
        fontSize: '0.875rem',
        border: 'unset',
        '&:focus': {
            borderRadius: 10,
            boxShadow: 'unset',
        },
        borderRadius: 10,
        marginLeft: 10
    },
    quickControlButtons: {
        padding: 7,
    },
});

export class QuickIssueInputField extends PureComponent {

    render() {
        const {
            classes, user, onChange, onEnter, reference, location, pathname, name, issueTitle, placeHolder, workflowStatus
        } = this.props;
        return (
            <Flex container item={'0 0 40px'} alignItems={'center'} padding={6} className={classes.quickIssueContainer}>
                <Flex container item={'0 0 40px'} alignItems={'center'}>
                    <Tooltip
                        title={utils.formattedUserName(user)}
                        placement={'bottom-start'}
                        classes={{
                            tooltip: classes.avatarTooltipLabel
                        }}
                    >
                        <H4Avatar {...{user: user}} style={{marginLeft: 10}}/>
                    </Tooltip>
                </Flex>
                <BootstrapInput
                    id={ids.issueTitle}
                    value={issueTitle}
                    placeholder={placeHolder}
                    onChange={onChange}
                    onEnterKey={onEnter}
                    name={name}
                    fullWidth
                    classes={{input: classes.quickIssueInput}}
                    inputRef={reference}
                    autoComplete={'off'}
                />
                <Flex container style={{minWidth: 150}} justifyContent={'center'}>
                    <IconButton
                        id={ids.issueQuickCreateButton}
                        className={classes.quickControlButtons}
                        style={{marginRight: 5}}
                        onClick={onEnter}
                    >
                        <CheckIcon style={{color: controlButtonBlue}}/>
                    </IconButton>
                    <IconButton className={classes.quickControlButtons} style={{marginLeft: 5}}
                                id={ids.issueCreateButton}
                                component={Link}
                                to={{
                                    pathname: pathname,
                                    state: {
                                        prevPath: prevPath({location}),
                                        issueTitle,
                                        workflowStatus
                                    }
                                }}
                    >
                        <OpenInNew style={{color: controlButtonBlue}}/>
                    </IconButton>
                </Flex>
            </Flex>
        )
    }
}

export default withStyles(styles)(QuickIssueInputField);
