import {mainDatabase} from 'scenes/Main.database'
import _ from "lodash";

const table = 'users';

export function cacheUser(loginOnline, loginOffline, username, password) {
    return dispatch => {
        return dispatch(loginOnline)
            .then(response => {
                const loginData = _.get(response, 'payload.data');

                return mainDatabase[table].put({id: username, response: loginData})
                    .then(() => {
                        console.log(table,' [', mainDatabase.name, '][', mainDatabase.verno, '] cache updated');
                    })
                    .catch(error => {
                        console.log('Could not write to the local cache. Error: ', error);
                        throw error;
                    });
            })
            .catch(error => {
                if (error.error.status === 0) {
                    console.log('Could not reach the server, serving table from local cache. Error: ', error);
                    return mainDatabase[table].get(username)
                        .then(backup => {
                            if (backup) {
                                console.log('Successfully getting data from cache: ', backup);
                                dispatch(loginOffline(backup.response, password));
                            } else {
                                const onlineLoginNeededError = new Error('Online login needed');
                                onlineLoginNeededError.type = 'ONLINE_LOGIN_NEEDED';
                                throw onlineLoginNeededError;
                            }
                        })
                        .catch(error => {
                            console.log('Cannot get cache from local database', error);
                            throw error;
                        });
                } else {
                    throw error;
                }
            });
    };
}
