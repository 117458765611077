import React, {Component} from 'react';
import {withStyles, Select} from "@material-ui/core";
import BootstrapInput from 'components/bootstrap-input/BootstrapInput'
import ExpandMore from '@material-ui/icons/ExpandMore';

const styles = theme => ({
    icon: {
        right: 5
    },
});

export class BootstrapSelect extends Component {

    render() {
        const {bold, classes, bootstrapInputClasses, ...otherProps} = this.props;
        return (
            <Select {...otherProps}
                    classes={{
                        icon: classes.icon,
                    }}
                    IconComponent={ExpandMore}
                    input={<BootstrapInput classes={bootstrapInputClasses} bold={bold}/>}
            >
                {this.props.children}
            </Select>
        );
    }
}

export default withStyles(styles)(BootstrapSelect);
