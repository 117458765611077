import React, {Component} from 'react';
import {Divider, withStyles, Typography} from "@material-ui/core";
import {connect} from "react-redux";
import connector from "./GeneralConfiguration.connect";
import {Trans, withTranslation} from "react-i18next";
import {withRouter} from "react-router";
import Flex from "components/grid/Flex";
import {htmlWhite, alabasterWhite, htmlGrey} from "components/colors/Colors";
import {
    routeTo,
    GENERAL_CONFIGURATION,
    GENERAL_CONFIGURATION_AUDIT_TYPES,
    GENERAL_CONFIGURATION_QUESTION_CATALOGS,
} from "routes/routes";
import {Switch, Route, Redirect} from 'react-router-dom';
import AuditTypes from './audit-types/AuditTypes';
import QuestionCatalogs from './question-categories/QuestionCategories';
import classNames from 'classnames';

const styles = theme => ({
    paper: {
        backgroundColor: htmlWhite,
        height: '100%'
    },
    title: {
        padding: 30,
        fontSize: 25,
        fontWeight: 'bold'
    },
    controlButtons: {
        minWidth: 150,
        marginTop: 15,
        marginLeft: 10
    },
    sidebar: {
        backgroundColor: alabasterWhite,
        width: 220,
        paddingTop: 30
    },
    menuItem: {
        padding: 10,
        paddingLeft: 30,
        paddingRight: 30,
        cursor: 'pointer',
        '&:hover': {
            background: `linear-gradient(90deg, ${htmlGrey} 3%, transparent 0%)`,
        }
    },
    active: {
        background: `linear-gradient(90deg, ${htmlGrey} 3%, transparent 0%)`,
        fontWeight: 600,
    }
});


export class GeneralConfiguration extends Component {

    componentDidMount() {
        this.props.fetchAuditManagementConfigurationData();
    }

    goBack = () => {
        routeTo(this.props.prevPath || GENERAL_CONFIGURATION.path, this.props.routeProps);
    };

    goToSubPage = route => {
        routeTo(route.path, this.props);
    };

    isMenuItemSelected = route => {
        return route.path === this.props.location.pathname;
    };

    render() {
        const {classes} = this.props;
        const {isMenuItemSelected} = this;
        return (
            <Flex item container className={classes.paper} direction={'column'}>
                <Typography className={classes.title}>
                    <Trans i18nKey={"routes.general-configuration.title"}/>
                </Typography>
                <Divider/>
                <Flex item container>
                    <Flex item grow={0} container direction={'column'} className={classes.sidebar}>
                        {/*<div className={*/}
                        {/*    isMenuItemSelected(GENERAL_CONFIGURATION_USER_MANAGEMENT)*/}
                        {/*        ? classNames(classes.menuItem, classes.active)*/}
                        {/*        : classes.menuItem*/}
                        {/*}*/}
                        {/*     onClick={() => this.goToSubPage(GENERAL_CONFIGURATION_USER_MANAGEMENT)}>*/}
                        {/*    <Trans i18nKey={"routes.general-configuration.user-management.title"}/>*/}
                        {/*</div>*/}
                        <div className={
                            isMenuItemSelected(GENERAL_CONFIGURATION_QUESTION_CATALOGS)
                                ? classNames(classes.menuItem, classes.active)
                                : classes.menuItem
                        }
                             onClick={() => this.goToSubPage(GENERAL_CONFIGURATION_QUESTION_CATALOGS)}>
                            <Trans i18nKey={"routes.general-configuration.question-categories.title"}/>
                        </div>
                        <div className={
                            isMenuItemSelected(GENERAL_CONFIGURATION_AUDIT_TYPES)
                                ? classNames(classes.menuItem, classes.active)
                                : classes.menuItem
                        }
                             onClick={() => this.goToSubPage(GENERAL_CONFIGURATION_AUDIT_TYPES)}>
                            <Trans i18nKey={"routes.general-configuration.audit-types.title"}/>
                        </div>
                    </Flex>
                    <Flex item container style={{position: 'relative'}}>
                        {
                            <Switch>
                                {/*<Route path={GENERAL_CONFIGURATION_USER_MANAGEMENT.path}*/}
                                {/*       render={function renderUserManagement(props) {*/}
                                {/*           return (<UserManagement/>)*/}
                                {/*       }}*/}
                                {/*/>*/}
                                <Route path={GENERAL_CONFIGURATION_AUDIT_TYPES.path}
                                       render={function renderAuditTypes(props) {
                                           return (<AuditTypes/>)
                                       }}
                                />
                                <Route path={GENERAL_CONFIGURATION_QUESTION_CATALOGS.path}
                                       render={function renderQuestionCatalogs(props) {
                                           return (<QuestionCatalogs/>)
                                       }}
                                />
                                <Redirect to={GENERAL_CONFIGURATION_QUESTION_CATALOGS.path}/>
                            </Switch>
                        }
                    </Flex>
                </Flex>
            </Flex>
        );
    }
}

export default withStyles(styles)(connect(connector.mapStateToProps, connector.mapDispatchToProps)(withTranslation()(withRouter(GeneralConfiguration))));

