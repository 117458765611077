import {fetchAuditHistory, fetchAudit} from "./AuditReportingHistory.action";

export default {
    mapStateToProps: (state) => {
        return {
            selectedOrganizationLevel: state.organizationTreeReducer.selectedOrganizationLevel,
            statistics: state.auditReportingHistoryReducer.statistics,
            historyData: state.auditReportingHistoryReducer.historyData,
            selectedAudit: state.auditReportingHistoryReducer.selectedAudit,
        };
    },
    mapDispatchToProps: (dispatch) => {
        return {
            fetchAuditHistory: (filterData) => dispatch(fetchAuditHistory(filterData)),
            fetchAudit: (auditId) => dispatch(fetchAudit(auditId)),
        };
    }
};
