import React, {Component} from 'react';
import ListPage from "scenes/configuration/components/ListPage";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import connector from "./QuestionCategories.connect";
import _ from 'lodash';
export class QuestionCategories extends Component {

    softDeleteItem = itemId => {
        this.props.removeQuestionCatalogue(itemId, false);
    };

    confirmedDeleteItem = () => {
        const itemToBeDeleted = _.get(this.props.assignedQuestionnaires,'catalogue.businessId', '');
        this.props.removeQuestionCatalogue(itemToBeDeleted, true).then(this.props.resetToBeConfirmedAssignedQuestionnaires);
    };

    render() {
        const {
            t: translate, categories, createQuestionCatalogue, assignedQuestionnaires,
            resetToBeConfirmedAssignedQuestionnaires
        } = this.props;
        const {softDeleteItem, confirmedDeleteItem} = this;

        const domainPath = 'routes.general-configuration.question-categories.';

        return (
            <ListPage {...{
                titleSingular: translate(domainPath + 'title_singular'),
                titlePlural: translate(domainPath + 'title'),
                confirmationHeaderKey: domainPath + "deleteConfirmation.header",
                confirmationTextKey: domainPath + "deleteConfirmation.statement",
                confirmationTexValues: {name: _.get(assignedQuestionnaires,'catalogue.label', '')},
                confirmationQuestionKey: domainPath + "deleteConfirmation.question",
                confirmationQuestionValues: {name: _.get(assignedQuestionnaires,'catalogue.label', '')},
                items: categories,
                itemsToConfirm: _.get(assignedQuestionnaires,'questionnaires', []),
                onAddition: createQuestionCatalogue,
                onSoftDeletion: softDeleteItem,
                onHardDeletion: confirmedDeleteItem,
                resetItemsToConfirm: resetToBeConfirmedAssignedQuestionnaires
            }}/>
        );
    }
}

export default connect(connector.mapStateToProps, connector.mapDispatchToProps)(withTranslation()(QuestionCategories));
