import * as actionTypes from './AuditReportingMap.action.types'
import {ORGANIZATION_LEVEL_WITH_MAP_GRAPHQL} from "domain/audit/OrganizationLevel.model";
import utils from "utils/Utils"

export function fetchOrganizationLevel(levelBusinessId) {
    return (dispatch, getState) => {
        return dispatch({
            type: actionTypes.FETCH_ORGANIZATION_LEVEL,
            data: {user: getState().mainReducer.user},
            payload: {
                client: 'graphql',
                request: {
                    method: 'post',
                    data: {
                        query: `                           
                           query
                                ($levelBusinessId: String!){
                                    fetchOrganizationLevel(levelBusinessId: $levelBusinessId) {
                                        ${ORGANIZATION_LEVEL_WITH_MAP_GRAPHQL}
                                    }    
                                }                                                       
                           `,
                        variables: {
                            "levelBusinessId": levelBusinessId
                        }
                    }
                }
            }
        })
    }
}

export function fetchAuditMapStatistics(data) {
    const filter = mapAuditMapFilter(data);
    return {
        type: actionTypes.FETCH_STATISTICS,
        payload: {
            client: 'graphql',
            request: {
                method: 'post',
                data: {
                    query: `                           
                           query
                                ($filter: AuditMapStatisticsFilter!){
                                    fetchAuditMapStatistics(filter: $filter) {
                                        statistics {
                                            outcome
                                            count
                                        }
                                        childrenStatistics {
                                            outcome
                                            count
                                        }
                                        latestAudit {
                                            organizationLevel {
                                                businessId
                                            }
                                            auditType
                                            outcome
                                            answers {
                                                outcome
                                                count
                                            }
                                            interval {
                                                start
                                                end
                                            }
                                        }
                                        childrenSummaryBreakdown {
                                            organizationLevel {
                                                businessId
                                            }
                                            auditType
                                            outcome
                                            answers {
                                                outcome
                                                count
                                            }
                                        }
                                    }    
                                }                                                       
                           `,
                    variables: {
                        "filter": filter
                    }
                }
            }
        }
    };
}

function mapAuditMapFilter(filterData) {
    return {
        organizationLevelId: filterData.selectedOrganizationLevel.businessId,
        from: filterData.periodStartDate.format(utils.API_LOCAL_DATE_FORMAT),
        to: filterData.periodEndDate.format(utils.API_LOCAL_DATE_FORMAT),
        auditType: filterData.auditType,
    };
}

