import React, {PureComponent} from "react";
import {Avatar, Button, Dialog, DialogActions, DialogContent, Tooltip, Typography, withStyles} from "@material-ui/core";
import {Trans, withTranslation} from "react-i18next";
import Flex from "components/grid/Flex";
import DialogTitle from "@material-ui/core/DialogTitle";
import {htmlBlack, htmlGrey, htmlWhite, logoBlueLight, random} from "components/colors/Colors";
import _ from "lodash";
import {getName, getNameInitials} from "domain/User.model";
import utils from 'utils/Utils';
import H4Avatar from "components/avatar/H4Avatar";
import {RANDOM} from "domain/audit/Schedule.model";

const styles = theme => ({
    controlButtons: {
        minWidth: 150,
        '@media (min-width: 300px)': {
            margin: 5
        },
    },
    root: {
        maxWidth: 'unset'
    },
    buttons: {
        justifyContent: 'flex-end',
        '@media (min-width: 300px)': {
            flexDirection: 'column'
        },
        '@media (min-width: 1500px)': {
            flexDirection: 'row'
        },
    },
    mainLabel: {
        color: htmlGrey,
        fontSize: 17.5,
    },
    label: {
        color: htmlGrey,
        fontSize: 15,
    },
    avatarRoot: {
        width: 150,
        height: 150
    },
});

export class UpdateAuditorDialog extends PureComponent {

    state = {
        selectedUser: {}
    };

    getAvailableAuditors = () => {
        return this.props.auditors
            .map(auditor => auditor.user)
            .filter(member => member.businessId !== _.get(this.props.audit.auditor, 'businessId'));
    };

    handleAuditorChange = (auditorId) => {
        const auditors = this.getAvailableAuditors();
        let selectedUser;
        if (auditorId === RANDOM) {
            const number = Math.floor(Math.random() * auditors.length);
            selectedUser = auditors[number];
        } else {
            selectedUser = auditors.find(user => user.businessId === auditorId);
        }
        this.setState({
            selectedUser: selectedUser
        })
    };

    render() {
        const {classes, dialogOpen, closeDialog, audit, updateAuditor,  t: translate, organizationLevel} = this.props;
        const {selectedUser} = this.state;
        const {handleAuditorChange, getAvailableAuditors} = this;
        const translateRandomLabel = translate('audit-administration.scheduleEdit.random');
        const availableAuditors = getAvailableAuditors();
        return (
            <Dialog
                open={dialogOpen}
                classes={{root: classes.root}}
                maxWidth={'lg'}
            >
                <DialogTitle>
                    <Trans i18nKey={"audit-administration.monitoringView.changeAuditorForRunningAudit"}/>
                </DialogTitle>
                <DialogContent>
                    <Flex container style={{
                        padding: 10,
                        border: '1px solid ' + htmlBlack
                    }}>
                        <Flex container item basis={'50%'} direction={'column'} alignItems={'center'} style={{
                            paddingRight: 25
                        }}>
                            <Flex item>
                                <Typography className={classes.mainLabel}>
                                    <Trans i18nKey={"global.current"}/>
                                </Typography>
                            </Flex>
                            <Flex container item style={{paddingTop: 55}}>
                                {
                                    !_.isEmpty(audit.auditor) &&
                                    <Flex container item direction={"column"} alignItems={'center'}>
                                        <Flex item>
                                            {
                                                _.isEmpty(audit.auditor) || !_.get(audit.auditor, 'avatar.downloadUrl') ?
                                                    <Avatar
                                                        classes={{root: classes.avatarRoot}}
                                                        style={{
                                                            backgroundColor: logoBlueLight,
                                                            fontSize: 75,
                                                            color: _.isEmpty(audit.auditor) ? random : htmlWhite
                                                        }}
                                                    >
                                                        {_.isEmpty(audit.auditor) ? '?' : getNameInitials(audit.auditor)}
                                                    </Avatar>
                                                    :
                                                    <Avatar classes={{root: classes.avatarRoot}}
                                                            src={audit.auditor.avatar.downloadUrl}/>
                                            }
                                        </Flex>
                                        <Flex item alignItems={'center'} style={{
                                            paddingTop: 10
                                        }}>
                                            <Typography className={classes.label}
                                                        style={{fontSize: 15}}>
                                                {_.isEmpty(audit.auditor) ? translate('audit-administration.scheduleEdit.random') : getName(audit.auditor)}
                                            </Typography>
                                        </Flex>
                                    </Flex>
                                }
                            </Flex>
                        </Flex>
                        {
                            _.isEmpty(availableAuditors) ?
                                <Flex container item basis={'50%'} direction={'column'} alignItems={'center'}
                                      justifyContent={'center'}>
                                    <Typography className={classes.mainLabel}> <Trans
                                        i18nKey={"audit-administration.monitoringView.noAuditors"} values={{
                                        organizationLevel: organizationLevel.title
                                    }}/>
                                    </Typography>
                                </Flex>
                                :
                                <Flex container item basis={'50%'} direction={'column'} alignItems={'center'}>
                                    <Flex item grow={0}>
                                        <Typography className={classes.mainLabel}>
                                            <Trans i18nKey={"global.new"}/>
                                        </Typography>
                                    </Flex>
                                    <Flex container item justifyContent={'flex-start'} style={{paddingTop: 15}}>
                                        {
                                            !_.isEmpty(availableAuditors) &&
                                            availableAuditors
                                                .map((member, index) => (
                                                    <Flex item={'0 1 0'} key={index} style={{
                                                        position: 'relative'
                                                    }}>

                                                        <Tooltip
                                                            title={utils.formattedUserName(member)}
                                                            placement={'bottom-start'}
                                                            classes={{
                                                                tooltip: classes.avatarTooltipLabel
                                                            }}
                                                        >
                                                            <H4Avatar
                                                                {...{user: member}}

                                                                style={{
                                                                    marginLeft: 3
                                                                }}
                                                                onClick={() => {
                                                                    handleAuditorChange(member.businessId)
                                                                }}
                                                            />
                                                        </Tooltip>
                                                    </Flex>
                                                ))
                                        }
                                        {
                                            !_.isEmpty(availableAuditors) &&
                                            <Flex item={'0 1 0'} style={{
                                                paddingLeft: 20
                                            }}>
                                                <Tooltip
                                                    title={translate('audit-administration.scheduleEdit.random')}
                                                    placement={'bottom-start'}
                                                    classes={{
                                                        tooltip: classes.avatarTooltipLabel
                                                    }}
                                                >
                                                    <H4Avatar
                                                        {...{
                                                            user: {
                                                                businessId: "?",
                                                                givenName: "?"
                                                            }
                                                        }}
                                                        style={{
                                                            color: random,
                                                            marginLeft: 3

                                                        }}
                                                        onClick={() => {
                                                            handleAuditorChange(RANDOM)
                                                        }}
                                                    />
                                                </Tooltip>
                                            </Flex>
                                        }
                                    </Flex>
                                    <Flex container item justifyContent={'center'} alignContent={'flex-start'} grow={0}
                                          shrink={0}>
                                        {
                                            !_.isEmpty(selectedUser) ?
                                                <Flex container item direction={"column"} alignItems={'center'}>
                                                    <Flex item>
                                                        <H4Avatar className={classes.avatarRoot}
                                                                  style={{
                                                                      fontSize: 75

                                                                  }}
                                                                  user={selectedUser}/>
                                                    </Flex>
                                                    <Flex item alignItems={'center'} style={{
                                                        paddingTop: 10
                                                    }}>
                                                        <Typography className={classes.label}
                                                                    style={{fontSize: 15}}>
                                                            {_.isEmpty(selectedUser) ? translateRandomLabel : getName(selectedUser)}
                                                        </Typography>
                                                    </Flex>
                                                </Flex>
                                                :
                                                <Flex container item direction={"column"} alignItems={'center'}>
                                                    <Flex item>
                                                        <Avatar classes={{root: classes.avatarRoot}} style={{
                                                            backgroundColor: htmlWhite,
                                                            border: '2px solid ' + random
                                                        }}/>
                                                    </Flex>
                                                    <Flex item alignItems={'center'} style={{
                                                        paddingTop: 10
                                                    }}>
                                                        <Typography className={classes.label}
                                                                    style={{
                                                                        fontSize: 15,
                                                                        color: htmlWhite
                                                                    }}>
                                                            {translateRandomLabel}
                                                        </Typography>
                                                    </Flex>
                                                </Flex>
                                        }
                                    </Flex>
                                </Flex>
                        }
                    </Flex>
                </DialogContent>
                <DialogActions>
                    <Flex container item className={classes.buttons} justifyContent={"space-between"}>
                        <Button onClick={() => {
                            this.setState({
                                selectedUser: {}
                            });
                            closeDialog();
                        }}
                                className={classes.controlButtons}
                                color="primary"
                                variant="contained">
                            <Trans i18nKey={"global.cancel"}/>
                        </Button>
                        <Button onClick={() => updateAuditor(audit, selectedUser)}
                                color="primary"
                                disabled={_.isEmpty(selectedUser)}
                                className={classes.controlButtons}
                                style={{
                                    backgroundColor: random
                                }}
                                variant="contained"
                                autoFocus>
                            <Trans i18nKey={"audit-administration.monitoringView.changeAuditor"}/>
                        </Button>
                    </Flex>
                </DialogActions>
            </Dialog>
        );
    }

}

export default withStyles(styles)(withTranslation()(UpdateAuditorDialog));
