import React, {Component} from 'react';
import {Radio, Typography, withStyles} from "@material-ui/core";
import Flex from 'components/grid/Flex';
import moment from 'moment';
import utils from 'utils/Utils';
import {Area, AreaChart, CartesianGrid, Legend, Tooltip, XAxis, YAxis} from 'recharts';
import {christiGreen, htmlGreen, doveGrey, guardsmanRed, jaffaOrange, scienceBlue} from 'components/colors/Colors';
import ProjectTree from 'scenes/tasks/components/project-tree/ProjectTree';
import {connect} from 'react-redux';
import connector from './TaskReporting.connect';
import {Trans, withTranslation} from 'react-i18next';
import MaterialDatePicker from 'components/material-datepicker/MaterialDatePicker';
import withWindowDimensions from 'components/with-window-dimensions/withWindowDimensions';
import {treeIndex} from 'components/zindex/zIndex'

const styles = theme => ({
    grey: {
        fill: doveGrey,
        color: `${doveGrey} !important`,
    },
    todayLabel: {
        textTransform: 'uppercase',
        color: doveGrey,
        fontWeight: 'bold'
    },
    workspace: {
        marginLeft: 20,
        zIndex: treeIndex + 1,
    }
});

export class TaskReporting extends Component {

    state = {
        fromDate: moment().startOf('isoweek'),
        toDate: moment().endOf('isoweek'),
        isCurrentWeekChecked: true,
    };

    componentDidMount() {
        this.fetchReport();
    }

    setStateAndFetchReport = (newState) => {
        this.setState(newState, () => {
            this.fetchReport()
        })
    };

    fetchReport = () => {
        this.props.listDailyTaskStatusReportFromServer(
            this.state.fromDate.format(utils.API_DATE_FORMAT),
            this.state.toDate.format(utils.API_DATE_FORMAT)
        );
    };

    renderColorfulLegend = (value, entry) => {
        return <span style={{color: entry.color}}>{value}</span>;
    };

    onFromDateChange = (date) => {
        if (date > this.state.toDate) {
            this.setStateAndFetchReport({toDate: date, isCurrentWeekChecked: false});
        }
        this.setStateAndFetchReport({fromDate: date, isCurrentWeekChecked: false});
    };

    onToDateChange = (date) => {
        if (date < this.state.fromDate) {
            this.setStateAndFetchReport({fromDate: date, isCurrentWeekChecked: false});
        }
        this.setStateAndFetchReport({toDate: date, isCurrentWeekChecked: false});
    };

    toggleCurrentWeek = () => {
        if (!this.state.isCurrentWeekChecked) {
            this.props.listDailyTaskStatusReportFromServer(
                moment().startOf('isoweek').format(utils.API_DATE_FORMAT),
                moment().endOf('isoweek').format(utils.API_DATE_FORMAT)
            );
        } else {
            this.fetchReport();
        }
        this.setState({
            isCurrentWeekChecked: !this.state.isCurrentWeekChecked,
        });
    };

    render() {
        const {
            classes, report, t: translate, windowWidth, windowHeight, isProjectTreeOpen
        } = this.props;
        const {fromDate, toDate, isCurrentWeekChecked} = this.state;

        const {onFromDateChange, onToDateChange, toggleCurrentWeek} = this;
        return (
            <Flex item container>
                <ProjectTree/>
                <Flex item container direction={'column'} className={classes.workspace}>
                    <Flex container item shrink={0} alignItems={'center'}>
                        <Typography><Trans i18nKey={"global.timeRange"}/></Typography>
                        <Flex item={'0 1 248px'} container alignItems={'center'} style={{margin: 5}}>
                            <Radio
                                checked={!isCurrentWeekChecked}
                                onChange={toggleCurrentWeek}
                                classes={{
                                    root: classes.grey,
                                }}
                            />
                            <MaterialDatePicker
                                label={translate('global.from')}
                                value={fromDate}
                                format={utils.DATE_FORMAT}
                                onChange={onFromDateChange}
                            />
                        </Flex>
                        <Flex item={'0 1 200px'} container alignItems={'center'} style={{margin: 5}}>
                            <MaterialDatePicker
                                label={translate('global.to')}
                                value={toDate}
                                format={utils.DATE_FORMAT}
                                onChange={onToDateChange}
                            />
                        </Flex>
                        <Flex item={'0 0 148px'} container alignItems={'center'}>
                            <Radio
                                checked={isCurrentWeekChecked}
                                onChange={toggleCurrentWeek}
                                classes={{
                                    root: classes.grey
                                }}
                            />
                            <Typography><Trans i18nKey={"global.currentWeek"}/></Typography>
                        </Flex>
                    </Flex>
                    <Flex item container justifyContent={'flex-end'} direction={'column'}>
                        <AreaChart
                            width={isProjectTreeOpen ? windowWidth - 366 : windowWidth - 136}
                            height={windowHeight * 0.75}
                            data={report}
                            margin={{right: 75}}
                        >
                            <CartesianGrid strokeDasharray="3 3"/>
                            <XAxis dataKey="date" dy={5}/>
                            <YAxis dx={-5}/>
                            <Tooltip isAnimationActive={false}/>
                            <Legend verticalAlign="bottom" height={36} iconType={'square'}
                                    formatter={this.renderColorfulLegend}/>
                            <Area isAnimationActive={false} dataKey='done' stackId="1" stroke={htmlGreen}
                                  fill={htmlGreen}/>
                            <Area isAnimationActive={false} dataKey='act' stackId="1" stroke={christiGreen}
                                  fill={christiGreen}/>
                            <Area isAnimationActive={false} dataKey='check' stackId="1" stroke={jaffaOrange}
                                  fill={jaffaOrange}/>
                            <Area isAnimationActive={false} dataKey='do' stackId="1" stroke={scienceBlue}
                                  fill={scienceBlue}/>
                            <Area isAnimationActive={false} dataKey='plan' stackId="1" stroke={guardsmanRed}
                                  fill={guardsmanRed}/>
                        </AreaChart>
                        <Flex container item justifyContent={'flex-end'} style={{
                            marginRight: windowHeight > 615 ? 70 : 55,
                            marginTop: -35
                        }}>
                            <Typography className={classes.todayLabel}>{translate('global.today')}</Typography>
                        </Flex>
                    </Flex>
                </Flex>
            </Flex>
        );
    }
}

export default withStyles(styles)(connect(connector.mapStateToProps, connector.mapDispatchToProps)(withTranslation()(withWindowDimensions(TaskReporting))));
