import React, {Component} from 'react';
import {withStyles, IconButton} from "@material-ui/core";
import {connect} from "react-redux";
import connector from "./AuditReportingHistory.connect";
import _ from 'lodash';
import moment from "moment";
import Flex from "components/grid/Flex";
import {
    auditPaperGrey,
    berylGreen,
    criticalHighlight,
    mediumHighlight,
    ghostGrey,
    silver,
} from 'components/colors/Colors'
import CalendarPreview from "scenes/audit-manager/administration/schedule-edit/components/CalendarPreview";
import StatisticCircles from "scenes/audit-manager/reporting/components/StatisticCircles"
import './CalenderPreview.css';
import {ChevronLeft as PageLeft, ChevronRight as PageRight} from '@material-ui/icons';
import AuditDetailsDialog from "scenes/audit-manager/administration/monitoring/components/AuditDetailsDialog";
import {getHighlightColor} from "components/colors/Result";
import utils from "utils/Utils"
import ReportingUtils from "scenes/audit-manager/reporting/AuditReportingUtils";

const styles = theme => ({
    calendarAlone: {
        fontSize: '1.4vw',
    },
    calendarTwoPack: {
        fontSize: '1.4vw',
    },
    calendarThreePack: {
        fontSize: '1.2vw',
    },
    calendarSixPack: {
        fontSize: '1vw',
    },
    arrowIcon: {
        fontSize: '4vw'
    }
});

const maxNumberOfCalendars = 6;

export class AuditReportingHistory extends Component {

    state = {
        calendarOffset: 0,
        auditDetailsDialogOpen: false,
    };

    componentDidMount() {
        if (!_.isEmpty(this.props.selectedOrganizationLevel)) {
            this.fetchAuditHistory();
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (
            prevProps.selectedOrganizationLevel.businessId !== this.props.selectedOrganizationLevel.businessId ||
            prevProps.periodStartDate !== this.props.periodStartDate ||
            prevProps.periodEndDate !== this.props.periodEndDate ||
            prevProps.durationType !== this.props.durationType ||
            prevProps.auditType !== this.props.auditType ||
            prevProps.timePeriodValue !== this.props.timePeriodValue
        ) {
            this.fetchAuditHistory();
        }
    }

    fetchAuditHistory = () => {
        if (this.props.timePeriodValue === "MONTH") {
            this.fetchCurrentMonthAuditHistory();
        } else if (this.props.timePeriodValue === "PERIOD") {
            this.fetchAuditHistoryForPeriod();
        } else {
            this.fetchInitialAuditHistory();
        }
    };

    fetchInitialAuditHistory = () => {
        this.props.fetchAuditHistory({
            selectedOrganizationLevel: this.props.selectedOrganizationLevel,
            periodStartDate: moment().year(2018),
            periodEndDate: moment(),
            durationType: this.props.durationType,
            auditType: this.props.auditType,
        });
    };

    fetchCurrentMonthAuditHistory = () => {
        this.props.fetchAuditHistory({
            selectedOrganizationLevel: this.props.selectedOrganizationLevel,
            periodStartDate: moment().startOf('month'),
            periodEndDate: moment().endOf('month'),
            durationType: this.props.durationType,
            auditType: this.props.auditType,
        });
    };

    fetchAuditHistoryForPeriod = () => {
        this.props.fetchAuditHistory({
            selectedOrganizationLevel: this.props.selectedOrganizationLevel,
            periodStartDate: this.props.periodStartDate,
            periodEndDate: this.props.periodEndDate,
            durationType: this.props.durationType,
            auditType: this.props.auditType,
        });
    };

    openAuditDetailsDialog = date => {
        const dateToSearch = moment(date).format(utils.API_LOCAL_DATE_FORMAT);
        const audit = this.props.historyData.audits.find(audit => audit.interval.days.includes(dateToSearch));
        if (audit && audit.outcome !== 'MISSED') {
            this.props.fetchAudit(audit.auditId).then(() => {
                this.setState({
                    auditDetailsDialogOpen: true,
                })
            });
        }
    };

    closeAuditDetailsDialog = () => {
        this.setState({
            auditDetailsDialogOpen: false,
        })
    };

    calculateNumberOfMonths = (periodStartDate) => {
        let numberOfMonths = maxNumberOfCalendars;
        const timePeriodValue = this.props.timePeriodValue;
        const periodStartDateClone = _.cloneDeep(this.props.periodStartDate).startOf('month');
        const periodEndDateClone = _.cloneDeep(this.props.periodEndDate).endOf('month');

        if (timePeriodValue === "ALL") {
            numberOfMonths = maxNumberOfCalendars
        } else if (timePeriodValue === "MONTH") {
            numberOfMonths = 1
        } else {
            numberOfMonths = Math.ceil(periodEndDateClone.diff(periodStartDateClone, "months", true));
        }
        return numberOfMonths;
    };

    getCalendarStyle = (numberOfMonths, classes) => {
        switch (numberOfMonths) {
            case 1:
                return classes.calendarAlone;
            case 2:
                return classes.calendarTwoPack;
            case 3:
                return classes.calendarThreePack;
            default:
                return classes.calendarSixPack;
        }
    };

    pageLeft = () => {
        this.setState({calendarOffset: this.state.calendarOffset + maxNumberOfCalendars});
    };

    pageRight = () => {
        if (this.state.calendarOffset >= maxNumberOfCalendars) {
            this.setState({calendarOffset: this.state.calendarOffset - maxNumberOfCalendars});
        }
    };

    getTileColor = (audit) => {
        if (audit.missed) {
            return silver;
        } else if (audit.done) {
            return getHighlightColor(audit.result);
        } else {
            return ghostGrey;
        }
    };

    render() {
        const {classes, statistics, historyData, periodStartDate, selectedOrganizationLevel, timePeriodValue, selectedAudit} = this.props;
        const {calendarOffset, auditDetailsDialogOpen} = this.state;
        const {
            pageLeft, pageRight, calculateNumberOfMonths, getCalendarStyle,
            closeAuditDetailsDialog, openAuditDetailsDialog, getTileColor
        } = this;
        const allTimeSelected = timePeriodValue === "ALL";
        const numberOfMonths = calculateNumberOfMonths(periodStartDate);
        const calendarStyle = getCalendarStyle(numberOfMonths, classes);
        const firstCalendarMonth = moment().subtract(numberOfMonths + calendarOffset - 1, "months");
        return (
            <Flex container>
                {
                    auditDetailsDialogOpen &&
                    <AuditDetailsDialog {...{
                        audit: selectedAudit,
                        dialogOpen: auditDetailsDialogOpen,
                        closeDialog: closeAuditDetailsDialog,
                        getTileColor,
                        deviations: selectedAudit.deviations
                    }}/>
                }
                <Flex item container direction={'column'} justifyContent={'center'} alignItems={'center'}
                      style={{width: '15%'}}>
                    <StatisticCircles {...{
                        selectedOrganizationLevel,
                        timePeriodValue,
                        successfulAudits: ReportingUtils.successfulAudits(statistics),
                        failedCriticalAudits: ReportingUtils.failedCriticalAudits(statistics),
                        failedMinorAudits: ReportingUtils.failedMinorAudits(statistics),
                        missedAudits: ReportingUtils.missedAudits(statistics)
                    }}/>
                </Flex>
                <Flex item container justifyContent={'center'} style={{width: '85%'}} alignItems={'center'}>
                    {
                        allTimeSelected &&
                        <IconButton onClick={pageLeft} disabled={historyData.firstAvailableDate >= firstCalendarMonth}>
                            <PageLeft className={classes.arrowIcon}/>
                        </IconButton>
                    }
                    <CalendarPreview {...{
                        numberOfMonths: numberOfMonths,
                        month: firstCalendarMonth.toDate(),
                        modifiers: {
                            successful: historyData.successfulAuditDays,
                            failedCritical: historyData.failedCriticalAuditDays,
                            failedMinor: historyData.failedMinorAuditDays,
                            missed: historyData.missedAuditDays,
                        },
                        modifiersStyles: {
                            successful: {
                                backgroundColor: berylGreen,
                                borderRadius: 0,
                            },
                            failedCritical: {
                                backgroundColor: criticalHighlight,
                                borderRadius: 0
                            },
                            failedMinor: {
                                backgroundColor: mediumHighlight,
                                borderRadius: 0
                            },
                            missed: {
                                backgroundColor: ghostGrey,
                                borderRadius: 0
                            },
                            outside: {
                                backgroundColor: auditPaperGrey,
                                borderRadius: 0
                            },
                        },
                        calendarStyle,
                        handleDayClick: openAuditDetailsDialog
                    }}/>
                    {
                        allTimeSelected &&
                        <IconButton onClick={pageRight} disabled={calendarOffset === 0}>
                            <PageRight className={classes.arrowIcon}/>
                        </IconButton>
                    }
                </Flex>
            </Flex>
        );
    }
}

export default withStyles(styles)(connect(connector.mapStateToProps, connector.mapDispatchToProps)(AuditReportingHistory));
