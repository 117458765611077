import * as actionTypes from './MyActionList.action.types';
import * as actionEditTypes from 'scenes/tasks/actions/action-edit/ActionEdit.action.types';
import _ from 'lodash';
import moment from 'moment';
import {emptyAction, apiAction} from 'domain/task/Action.model';
import {ACTION_ARCHIVED_STATUS} from 'domain/task/Action.model';

const initialState = {
    actions: [],
    selectedAction: {},
    configuration: {},
};

export default function (state = initialState, action) {
    switch (action.type) {
        case actionTypes.FETCH_ACTIONS_SUCCESS: {
            const newState = _.cloneDeep(state);
            const actions = _.get(action, 'payload.data.data.listMyPersonalActions') || _.get(action, 'data.cache.tasks.actions', []);
            newState.actions = actions.map(apiAction)
                .sort(sortByDuedate)
                .sort(sortByPriority)
                .sort(sortByDone);
            return newState;
        }
        case actionEditTypes.CREATE_ACTION_TO_CACHE:
        case actionEditTypes.CREATE_ACTION_SUCCESS: {
            const newState = _.cloneDeep(state);
            const actionFromServer = _.get(action, 'payload.data.data.createAction');
            const actionFromCache = _.get(action, 'data.action');
            const user = _.get(action, 'data.user') || _.get(action, 'meta.previousAction.data.user');
            const createdAction = apiAction(actionFromServer || actionFromCache);
            if (createdAction) {
                if (user.businessId === _.get(createdAction, 'assignee.businessId')) {
                    newState.actions.push(createdAction);
                }
            }
            return newState;
        }
        case actionEditTypes.EDIT_ACTION_SUCCESS:
        case actionEditTypes.EDIT_ACTION_TO_CACHE: {
            const newState = _.cloneDeep(state);
            const actionFromServer = _.get(action, 'payload.data.data.editAction') || _.get(action, 'payload.data.data.fetchAction');
            const actionFromCache = _.get(action, 'data.action');
            const user = _.get(action, 'data.user') || _.get(action, 'meta.previousAction.data.user');
            const editedAction = apiAction(actionFromServer || actionFromCache);
            const userIsAssigned = user.businessId === _.get(editedAction, 'assignee.businessId');
            if (editedAction) {
                const indexOfEditedAction = newState.actions.findIndex(actionItem => actionItem.businessId === editedAction.businessId);
                if (indexOfEditedAction > -1){
                    if (userIsAssigned && editedAction.status !== ACTION_ARCHIVED_STATUS) {
                        newState.actions.splice(indexOfEditedAction, 1, editedAction);
                    } else {
                        newState.actions.splice(indexOfEditedAction, 1);
                    }
                } else {
                    if (userIsAssigned) {
                        newState.actions.push(editedAction);
                    }
                }
            }
            return newState;
        }
        case actionTypes.SELECT_ACTION: {
            const newState = _.cloneDeep(state);
            newState.selectedAction = action.data.action;
            return newState;
        }
        default:
            return state;
    }
}

export function emptyActionForQuickCreate(taskBusinessId, user, languages, solveUntilConfiguration) {
    const matchingSolveUntil = (solveUntilConfiguration || [])
        .find(solveUntilInArray => solveUntilInArray.priority.type === 'LOW');
    const numberOfDaysToAdd = _.get(matchingSolveUntil, 'quantity', 0);
    const dueDate = moment().add(numberOfDaysToAdd, 'days').toDate();

    return emptyAction(taskBusinessId, user, dueDate, languages)
}

export function sortByPriority(a, b) {
    return a.priority.ordinal - b.priority.ordinal;
}

export function sortByDuedate(a, b) {
    return new Date(a.dueDate).getTime() - new Date(b.dueDate).getTime();
}

export function sortByDone(a, b) {
    if (a.status === 'DONE' && b.status === 'IN_PROGRESS') {
        return 1;
    } else if (a.status === 'IN_PROGRESS' && b.status === 'DONE') {
        return -1;
    } else {
        return 0;
    }
}
