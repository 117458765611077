import moment from 'moment';
import utils from 'utils/Utils';

const LOG_STORE = 'h4-log-storage';

export function initialize(){
    initializeLogStorage();
    wireIntoNativeLogging();
    wireIntoNativeError();
}

function initializeLogStorage(){
    let logStore;
    try {
        logStore = JSON.parse(window.localStorage.getItem(LOG_STORE));
    } catch (e) {
        setEmptyLogStore();
    }

    if (!logStore || (moment().format('YYYY-MM-DD') !== logStore.date)){
        setEmptyLogStore();
    }
}

function dumpToFile(){
    let url = window.URL.createObjectURL(new Blob([JSON.stringify(getLogStore(), null, 2)],
        {type: "application/octet-stream"}));
    utils.triggerNativeDownload(
        url,
        `${moment().format('YYYY-MM-DD HH:mm:ss')}-h4-log-dump.txt`
    );
}

function wireIntoNativeLogging(){
    const oldLoggers = ['log', 'error', 'debug', 'info'];
    oldLoggers.forEach(enrich)
}

function wireIntoNativeError(){
    window.addEventListener("error", function (e) {
        addToStorage(e.error);
        return false;
    });
}

function enrich(loggerFunctionString){
    const oldFunction = console[loggerFunctionString];
    console[loggerFunctionString] = function (...args){
        addToStorage(true, ...args);
        oldFunction(...args);
    }
}

function addToStorage(truncate, ...args){
    const logStore = getLogStore();
    try {
        let log = `${moment().format('YYYY-MM-DD HH:mm:ss')} - ${window.appConfig.environment} - ${process.env.REACT_APP_VERSION} - `;
        args.forEach(arg => {
            log += (truncate ? JSON.stringify(arg || '').slice(0, 200) : JSON.stringify(arg)) + ' - '
        });
        logStore.logs.push(log);
        setLogStore(logStore);
    } catch (e){
        // console.log('Cannot store log, because of an exception: ', e);
    }
    return logStore;
}

function setEmptyLogStore() {
    window.localStorage.setItem(LOG_STORE, JSON.stringify({
        date: moment().format('YYYY-MM-DD'),
        logs: []
    }))
}

function getLogStore(){
    let logStore;
    try {
        logStore = JSON.parse(window.localStorage.getItem(LOG_STORE));
    } catch (e) {
        console.error('Cannot get logStore');
    }
    return logStore
}

function setLogStore(logStore){
    try {
        window.localStorage.setItem(LOG_STORE, JSON.stringify(logStore));
    } catch (e) {
        console.error('Cannot set logStore');
    }
}

export default {
    initialize,
    addToStorage,
    dumpToFile
}
