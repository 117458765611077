import {logout, synchronize, toggleOnline} from 'scenes/Main.action';

export default {
    mapStateToProps: (state) => {
        return {
            projectBreadCrumb: state.projectReducer.selectedProject.breadCrumb,
            user: state.mainReducer.user,
            online: state.mainReducer.online,
        };
    },
    mapDispatchToProps: (dispatch) => {
        return {
            logout: () => dispatch(logout()),
            synchronize: () => dispatch(synchronize()),
            toggleOnline: () => dispatch(toggleOnline())
        };
    }
};
