import {
    string,
    shape,
    any,
} from "prop-types"
import {USER, USER_GRAPHQL, mapUser} from 'domain/User.model';

export const ATTACHMENT = {
    filename: string.isRequired,
    downloadUrl: string.isRequired,
    user: shape(USER),
    creationDate: any.isRequired
};

export const ATTACHMENT_GRAPHQL = `
    filename
    downloadUrl
    user {
        ${USER_GRAPHQL}
    }
    creationDate
`;

export function mapAttachment(attachment) {
    return {
        filename: attachment.filename,
        downloadUrl: attachment.downloadUrl,
        user: mapUser(attachment.user),
        creationDate: attachment.creationDate
    }
}
