import React, {Component, Fragment} from 'react';
import {
    Avatar,
    Divider,
    FormControl,
    IconButton,
    MenuItem,
    Select,
    Typography,
    withStyles
} from "@material-ui/core";
import {Trans, withTranslation} from "react-i18next";
import _ from "lodash";
import Flex from "components/grid/Flex";
import {AddCircle, Block, ExpandMore, RemoveCircle as DeleteIcon} from "@material-ui/icons";
import H4Avatar from "components/avatar/H4Avatar";
import connector from "./UserAssignment.connect";
import {dustyGrey, christiGreen, silver, guardsmanRed} from 'components/colors/Colors';
import utils from 'utils/Utils';
import BlankAvatar from 'assets/img/blank_avatar.png';
import {MEMBERSHIPS} from "domain/audit/OrganizationLevel.model";
import {connect} from "react-redux";

export let instances = {};

export const styles = theme => ({
    absoluteContainer: {
        padding: '30px 50px',
        overflow: 'auto',
        overflowScrolling: 'touch',
        position: 'absolute',
        top: 0,
        bottom: 0,
        right: 0,
        left: 0,
    },
    label: {
        marginBottom: 10
    },
    userName: {
        marginLeft: 15,
        marginRight: 15,
        width: 180,
        fontSize: '1rem'
    },
    membership: {
        width: 140,
        marginRight: 10
    },
    avatar: {
        width: 30,
        height: 30,
    },
});

const NewMemberAssignment = (props) => {
    const {
        classes, user, assignableUsers, membership, translatedMemberships, assigningEnabled, onMembershipChange, onUserChange, addMemberShip, members
    } = props;
    const assignedMemberships = _.get(members.find(member => _.get(member, 'user.businessId') === _.get(user, 'businessId')), 'memberships', []);
    const unassignedMemberships = translatedMemberships
        .filter(membership => !assignedMemberships.includes(membership.name));
    return (
        <Flex item container style={{marginBottom: 30}} alignItems={'center'}>
            {
                user ?
                    <H4Avatar {...{user}}/>
                    :
                    <Avatar src={BlankAvatar} className={classes.avatar}/>
            }
            <FormControl>
                <Select
                    className={classes.userName}
                    value={user || ""}
                    name={'user'}
                    onChange={onUserChange}
                    IconComponent={ExpandMore}
                    disableUnderline
                    renderValue={user => user ? utils.formattedUserName(user) : <em>Select user</em>}
                    displayEmpty
                >
                    {
                        assignableUsers.map((user, index) => {
                            return (
                                <MenuItem key={index} value={user}>
                                    <H4Avatar {...{user, style: {marginRight: 10}}}/>
                                    <Typography
                                        className={classes.userNameInSelector}> {utils.formattedUserName(user)}</Typography>
                                </MenuItem>
                            )
                        })
                    }
                </Select>
            </FormControl>
            <FormControl>
                <Select
                    className={classes.membership}
                    value={membership}
                    onChange={onMembershipChange}
                    name={'membership'}
                    IconComponent={ExpandMore}
                    disableUnderline
                >
                    {
                        unassignedMemberships
                            .map((membership, index) => {
                                return (
                                    <MenuItem key={index} value={membership.name}>
                                        {membership.label}
                                    </MenuItem>
                                )
                            })
                    }
                </Select>
            </FormControl>
            <IconButton
                disabled={!assigningEnabled}
                onClick={addMemberShip(user, membership)}
            >
                <AddCircle style={{color: assigningEnabled ? christiGreen : silver}}/>
            </IconButton>

        </Flex>
    );
};

const EditMemberList = (props) => {
    const {
        classes, organizationLevelMembers, translatedMemberships, updateMembership, removeMembership, loggedInUser
    } = props;

    return (
        <Fragment>
            {
                organizationLevelMembers.map((organizationLevelMember, index) => (
                    <Fragment key={index}>
                        {
                            organizationLevelMember.memberships.map((membership) => (
                                    <Fragment key={index + '-' + membership}>

                                        <Flex item container grow={0} alignItems={'center'}>
                                            <H4Avatar {...{user: organizationLevelMember.user}}/>
                                            <Typography
                                                className={classes.userName}
                                                style={{color: _.get(organizationLevelMember, 'user.businessId') === loggedInUser.businessId && dustyGrey}}>
                                                {utils.formattedUserName(organizationLevelMember.user)}
                                            </Typography>
                                            <FormControl>
                                                <Select
                                                    className={classes.membership}
                                                    value={membership}
                                                    onChange={updateMembership(organizationLevelMember, membership)}
                                                    name={'organizationLevelMembership'}
                                                    IconComponent={ExpandMore}
                                                    disableUnderline
                                                    disabled={_.get(organizationLevelMember, 'user.businessId') === loggedInUser.businessId}
                                                >
                                                    {
                                                        translatedMemberships
                                                            .map((membership, index) => {
                                                                return (
                                                                    <MenuItem key={index} value={membership.name}>
                                                                        {membership.label}
                                                                    </MenuItem>
                                                                )
                                                            })
                                                    }
                                                </Select>
                                            </FormControl>
                                            {
                                                _.get(organizationLevelMember, 'user.businessId') === loggedInUser.businessId ?
                                                    <IconButton>
                                                        <Block style={{color: silver}}/>
                                                    </IconButton>
                                                    :
                                                    <IconButton
                                                        onClick={removeMembership(organizationLevelMember, membership)}>
                                                        <DeleteIcon style={{color: guardsmanRed}}/>
                                                    </IconButton>
                                            }
                                        </Flex>
                                        <Divider style={{width: '100%'}}/>

                                    </Fragment>
                                )
                            )
                        }
                    </Fragment>
                ))
            }
        </Fragment>
    );
};

export class UserAssignment extends Component {

    state = {
        user: undefined,
        membership: 'AUDITOR',
    };

    componentDidMount() {
        this.props.fetchUsers();
    }

    setInitialState = () => {
        this.setState({
            user: undefined,
            membership: 'AUDITOR',
        })
    };
    handleMembershipChange = event => {
        this.setState({
            [event.target.name]: event.target.value,
        });
    };

    handleUserChange = event => {
        const assignedMemberships = _.get(this.props.organizationLevel.members
            .find(member => _.get(member, 'user.businessId') === event.target.value.businessId), 'memberships', []);
        const unassignedMemberships = this.translateMemberShips()
            .filter(membership => !assignedMemberships.includes(membership.name));
        this.setState({
            user: event.target.value,
            membership: unassignedMemberships[0].name,
        });
    };

    addMemberShip = (user, membership) => event => {
        this.props.addOrganizationLevelMembership(this.props.organizationLevel.businessId, user.businessId, membership).then(() => {
            this.props.fetchOrganizationLevel(this.props.organizationLevel.businessId);
            this.setInitialState();
        });
    };

    updateMembership = (member, membership) => event => {
        const newMembership = event.target.value;
        this.props.updateOrganizationLevelMembership(this.props.organizationLevel.businessId,
            _.get(member, 'user.businessId'), newMembership, membership).then(() => {
            this.props.fetchOrganizationLevel(this.props.organizationLevel.businessId);
        });
    };

    removeMembership = (member, membership) => event => {
        this.props.removeOrganizationLevelMembership(this.props.organizationLevel.businessId,
            _.get(member, 'user.businessId'), membership).then(() => {
            this.props.fetchOrganizationLevel(this.props.organizationLevel.businessId);
        });
    };

    translateMemberShips = () => {
        return MEMBERSHIPS.map(
            membership => ({
                name: membership,
                label: this.props.t(("audit-administration.membership." + membership))
            }));
    };

    render() {
        const {classes, allUsers, loggedInUser, organizationLevel} = this.props;
        const {user, membership} = this.state;
        const assignableUsers = _.differenceBy(allUsers, (organizationLevel.members || [])
            .filter(member => member.memberships.length === MEMBERSHIPS.length)
            .map(member => member.user), 'businessId');
        const translatedMemberships = this.translateMemberShips();
        const assigningEnabled = user && membership;

        return (
            <div className={classes.absoluteContainer}>
                <Flex item container grow={0}>
                    <Flex item container grow={0} direction={'column'}>
                        <Typography classes={{root: classes.label}}>
                            <Trans i18nKey={"audit-administration.new-membership-assigment"}/>
                        </Typography>
                        {
                            assignableUsers.length === 0
                                ?
                                <Typography classes={{root: classes.userName}} style={{marginBottom: 30}}>
                                    <Trans i18nKey={"audit-administration.all-members-assigned"}/>
                                </Typography>
                                :
                                <NewMemberAssignment {...{
                                    classes,
                                    user,
                                    membership,
                                    assignableUsers,
                                    members: organizationLevel.members,
                                    translatedMemberships,
                                    assigningEnabled,
                                    onMembershipChange: this.handleMembershipChange,
                                    onUserChange: this.handleUserChange,
                                    addMemberShip: this.addMemberShip
                                }}
                                />
                        }
                        <Typography classes={{root: classes.label}}>
                            <Trans i18nKey={"audit-administration.edit-membership"}/>
                        </Typography>
                        <EditMemberList {...{
                            classes,
                            organizationLevelMembers: organizationLevel.members,
                            translatedMemberships,
                            loggedInUser,
                            updateMembership: this.updateMembership,
                            removeMembership: this.removeMembership
                        }}
                        />
                    </Flex>
                </Flex>
            </div>
        );
    }
}

export default withStyles(styles)(connect(connector.mapStateToProps, connector.mapDispatchToProps)(withTranslation()(UserAssignment)));
