import React, {Component} from 'react';
import {
    withStyles,
    Button,
    MenuItem,
    Typography,
    FormHelperText,
} from "@material-ui/core";
import BootstrapInput from 'components/bootstrap-input/BootstrapInput'
import BootstrapSelect from 'components/bootstrap-select/BootstrapSelect'
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import {audit, htmlWhite, doveGrey} from 'components/colors/Colors';
import Flex from 'components/grid/Flex';
import {Trans, withTranslation} from 'react-i18next';
import {Link} from 'react-router-dom';
import {EXECUTE_AUDIT} from "routes/routes";
import {isVdaAudit} from 'scenes/audit-manager/administration/questionnaire-edit/QuestionnaireEdit';
import _ from 'lodash';

const styles = theme => ({
    randomNumberInput: {
        fontSize: '70px',
        color: audit
    },
    inputLabel: {
        marginTop: 20,
        '@media (min-width: 800px)': {
            fontSize: '1.5em',
        }
    },
    q11Button: {
        width: '100%',
        marginTop: 50,
        backgroundColor: audit,
        color: htmlWhite,
        '@media (min-width: 800px)': {
            fontSize: '1.5em',
        },
        '&:hover': {
            backgroundColor: audit
        }
    },
    qrReader: {
        minHeight: 400,
        minWidth: 200,
    },
    qrResultTitle: {
        fontWeight: 'bold',
        fontSize: '1rem',
        color: audit,
        '@media (min-width: 800px)': {
            fontSize: 24,
        }
    },
    qrResultMenuItem: {
        fontWeight: 'bold',
        fontSize: '1.4em',
        color: audit,
        '@media (min-width: 800px)': {
            height: 30,
        }
    },
    qrSelectInput: {
        fontWeight: 'bold',
        fontSize: '1.rem',
        color: audit,
        lineHeight: 'unset',
        height: 'unset',
        '@media (min-width: 800px)': {
            fontSize: 24,
        }
    },
    spinnerButton: {
        flex: 1
    },
    buttonContainer: {
        height: 98,
        display: 'flex',
        flexDirection: 'column',
    },
    adHocContainer: {
        marginTop: 20,
        maxWidth: '100vw',
        height: '100%',
        '@media (min-width: 800px)': {
            paddingLeft: 100,
            paddingRight: 100,
        },
        '@media (min-width: 1000px)': {
            paddingLeft: 200,
            paddingRight: 200,
        },
        '@media (min-width: 1200px)': {
            paddingLeft: 300,
            paddingRight: 300,
        },
        '@media (min-width: 1400px)': {
            paddingLeft: 400,
            paddingRight: 400,
        },
        '@media (min-width: 1600px)': {
            paddingLeft: 500,
            paddingRight: 500,
        },
    },
    pageTitle: {
        fontWeight: 'bold',
        fontSize: '1.25em',
        color: doveGrey,
        '@media (min-width: 800px)': {
            fontSize: '2em',
        },
    },
    pageTitleContainer: {
        position: 'absolute',
        top: 12,
        '@media (min-width: 800px)': {
            top: 22,
            zIndex: -1
        },
        left: 0,
        right: 0,
    },
});

export class QuestionnaireSelection extends Component {

    render() {
        const {
            classes, levelTitle, questionnaires, levelId, questionnaire, randomizedNumber, handleRandomizedNumberChange,
            isRandomizedNumberInvalid, handleQuestionnaireChange, addToRandomizedNumber,
        } = this.props;
        const showNumberSelector = !isVdaAudit(_.get(questionnaire, 'auditType.label'));
        return (
            <Flex container item fullWidth className={classes.adHocContainer} justifyContent={'center'}
                  direction={'column'}>
                <Flex item container justifyContent={'center'} className={classes.pageTitleContainer}>
                    <div className={classes.pageTitle}><Trans i18nKey={"global.my-audits"}/></div>
                </Flex>

                <Flex item container direction={'column'} justifyContent={'center'} style={{marginBottom: 80}}>
                    <Typography className={classes.inputLabel}>
                        <Trans i18nKey="audit-management.create-adhoc-audit.you-are-here"/>
                    </Typography>
                    <Typography className={classes.qrSelectInput} style={{marginLeft: 12}}>
                        {levelTitle}
                    </Typography>

                    <Typography className={classes.inputLabel}>
                        <Trans i18nKey="audit-management.create-adhoc-audit.select-questionnaire"/>
                    </Typography>
                    <BootstrapSelect
                        value={questionnaire}
                        onChange={handleQuestionnaireChange}
                        inputProps={{
                            name: 'questionnaire',
                            id: 'questionnaire-selector',
                        }}
                        bootstrapInputClasses={{input: classes.qrSelectInput, root: classes.qrSelectInput}}
                    >
                        {
                            questionnaires.map(questionnaire =>
                                <MenuItem value={questionnaire || ''}
                                          classes={{root: classes.qrResultMenuItem}}
                                          key={questionnaire.businessId}>{questionnaire.name}</MenuItem>
                            )
                        }
                    </BootstrapSelect>
                    {
                        showNumberSelector &&
                            <>
                                <Typography className={classes.inputLabel}>
                                    <Trans i18nKey="audit-management.create-adhoc-audit.number-of-question"/>
                                </Typography>
                                <BootstrapInput
                                    value={randomizedNumber}
                                    onChange={handleRandomizedNumberChange}
                                    endAdornment={
                                        <div className={classes.buttonContainer}>
                                            <Button onClick={addToRandomizedNumber(1)}
                                                    className={classes.spinnerButton}
                                                    disabled={!questionnaire}
                                                    variant="outlined"
                                                    size="small">
                                                <ExpandLess/>
                                            </Button>
                                            <Button onClick={addToRandomizedNumber(-1)}
                                                    className={classes.spinnerButton}
                                                    disabled={!questionnaire}
                                                    variant="outlined"
                                                    size="small">
                                                <ExpandMore/>
                                            </Button>
                                        </div>
                                    }
                                    inputProps={{
                                        type: 'number',
                                    }}
                                    classes={{
                                        input: classes.randomNumberInput
                                    }}
                                    center
                                    bold
                                    disabled={!questionnaire}
                                    name="randomizedNumber"
                                    id="randomizedNumber-input"
                                />
                            </>
                    }
                    {
                        isRandomizedNumberInvalid &&
                        <FormHelperText>
                            <Trans
                                i18nKey="audit-management.create-adhoc-audit.number-of-question-warning-left"/>
                            {questionnaire.questions.length || 0}
                            <Trans
                                i18nKey="audit-management.create-adhoc-audit.number-of-question-warning-right"/>
                        </FormHelperText>
                    }
                    <Button
                        variant="contained"
                        component={Link}
                        disabled={!randomizedNumber || !(randomizedNumber > 0) || !questionnaire || !levelId}
                        to={EXECUTE_AUDIT.path}
                        className={classes.q11Button}
                    >
                        <Trans i18nKey="audit-management.create-adhoc-audit.create-questionnaire-button"/>
                    </Button>
                </Flex>
            </Flex>
        );
    }
}

export default withStyles(styles)(withTranslation()(QuestionnaireSelection));
