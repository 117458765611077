import React, {Component} from 'react';
import {withStyles, Tab} from '@material-ui/core';

const styles = theme => ({
    tab: {
        textTransform: 'initial',
        fontSize: 16,
        fontWeight: 'bold',
        opacity: 0.3,
        minWidth: 160,
        padding: 0
    },
});

export class H4Tab extends Component {
    render() {
        const {classes, ...other} = this.props;
        return <Tab {...other} classes={{root: classes.tab}}/>;
    }
}

export default withStyles(styles)(H4Tab);
