import React, {Component} from 'react';
import {withStyles,} from "@material-ui/core";
import _ from 'lodash';
import utils from 'utils/Utils';
import {PERSONAL_PROJECT_TYPE} from 'domain/task/Project.model';
import Flex from 'components/grid/Flex';
import {connect} from 'react-redux';
import connector from './ActionEdit.connect';
import MobileActionEdit from 'scenes/tasks/actions/action-edit/components/mobile/MobileActionEdit';
import DesktopActionEdit from 'scenes/tasks/actions/action-edit/components/DesktopActionEdit';
import {routeTo, TASK_ACTION_EDIT} from 'routes/routes';
import {withTranslation} from 'react-i18next';
import update from 'immutability-helper';
import ProjectAuthorizationErrorLabel from "scenes/tasks/components/ProjectAuthorizationErrorLabel";
import {Helmet} from "react-helmet";
import editorUtils, {NEW} from 'components/quill-editor/EditorUtils';
import PageIsLoading from 'components/page-is-loading-progress/PageIsLoading';
import SaveIndicatorMessage from "components/snack-messages/SaveIndicatorMessage";

const styles = theme => ({
    containerDiv: {
        height: '100%',
    },
});

export class ActionEdit extends Component {
    leaveOnPurpose = false;
    mounted = false;

    state = {
        action: undefined,
        isTranslationChanged: false,
        isActionSetupChanged: false,
        savedProgress: 0,
        existingAction: true,
        prevPath: '',
        showSaveIndicator: false,
    };

    unloadHandler = event => {
        //LM NOTE: The message is ignored by all modern browsers in favor of their own.
        //LM NOTE: Browser pop-ups can be turned off by plugins and browser settings.
        const message = 'You are leaving the site, changes might not have been saved';
        if (this.state.isTranslationChanged || this.state.isActionSetupChanged) {
            event.returnValue = message;
            return message;
        }
    };

    componentDidMount() {
        this.mounted = true;
        window.addEventListener("beforeunload", this.unloadHandler);
        this.setState({
            prevPath: _.get(this.props, 'routeProps.location.state.prevPath', '')
        });
        this.props.fetchProject(this.props.task.projectBusinessId);
        const taskBusinessId = _.get(this.props, 'taskBusinessId');
        const actionBusinessId = _.get(this.props, 'actionBusinessId');
        if (actionBusinessId) {
            this.props.fetchAction(actionBusinessId, taskBusinessId).then(() => {
                if (_.get(this.props, 'routeProps.location.state.origin') === 'actionCreate') {
                    this.showSaveIndicator();
                }
                this.setState({
                    action: _.cloneDeep(this.props.action),
                    savedProgress: this.props.action.progress
                });
                const action = _.get(this.props, 'action');
                if (action && action.isUnseen) {
                    this.props.removeUnseenContentTag(action);
                }
            });

        } else {
            this.props.getTemporaryActionReferenceId().then(() =>
                this.setState(
                    {
                        action: _.cloneDeep(this.props.action),
                        savedProgress: this.props.action.progress
                    },
                    () => {
                        const actionTitle = _.get(this.props, 'routeProps.location.state.issueTitle');
                        if (actionTitle) {
                            this.handleTitleChange({target: {value: actionTitle}})
                        }
                    }
                )
            );
        }
    }

    componentWillUnmount() {
        this.mounted = false;
        window.removeEventListener("beforeunload", this.unloadHandler);
        try {
            if (!this.leaveOnPurpose) {
                this.saveAction(false);
            }
        } catch (e) {
            console.log('Failed to save task when user left edit page: ', e);
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {action, task} = this.props;

        const isPreviousTaskBusinessIdOffline = _.get(prevProps, 'task.businessId', '').startsWith('OFFLINE');
        const isCurrentTaskBusinessIdOnline = _.isFinite(Number(task.businessId));

        const isPreviousBusinessIdOffline = _.get(prevProps, 'action.businessId', '').startsWith('OFFLINE');
        const isCurrentBusinessIdOnline = _.get(action, 'businessId', '').includes('ACTION');
        const isStateAlreadyChanged = _.get(this.state, 'action.businessId', '').includes('ACTION');
        const isStateEmpty = _.isEmpty(this.state.action);

        const isActionSynchronized = isPreviousBusinessIdOffline && isCurrentBusinessIdOnline && !isStateAlreadyChanged;
        const isTaskSynchronized = isPreviousTaskBusinessIdOffline && isCurrentTaskBusinessIdOnline;

        if (!isStateEmpty && (isActionSynchronized || isTaskSynchronized)) {
            this.setState({
                    action: {
                        ...this.state.action,
                        businessId: action.businessId
                    }
                },
                () => {
                    this.props.routeProps.history.push(
                        TASK_ACTION_EDIT.pathFromOfflineToId(
                            this.props.routeProps.location.pathname,
                            action.businessId,
                            task.businessId),
                        {prevPath: this.state.prevPath});
                }
            )
        }
    }

    fetchAction = () => {
        const taskBusinessId = _.get(this.props, 'taskBusinessId');
        const actionBusinessId = _.get(this.props, 'actionBusinessId');
        return this.props.fetchAction(actionBusinessId, taskBusinessId);
    };

    handleNumberChangeOnAction = event => {
        let value = event.target.value;
        if (value.match('.')) {
            value = parseInt(value)
        }

        this.setState({
            action: {
                ...this.state.action,
                [event.target.name]: value,
            },
            isActionSetupChanged: this.props.action[event.target.name] !== value
        });
    };

    handleDueDateChange = dueDate => {
        this.setState({
            action: {
                ...this.state.action,
                dueDate,
                dueDateProgress: utils.dueDateProgress(this.state.action.creationDate, dueDate, new Date()),
            },
            isActionSetupChanged: this.props.action.dueDate !== dueDate
        });
    };

    handlePriorityChange = event => {
        const newPriority = event.target.checked ? 'URGENT' : 'NORMAL';
        this.setState({
            action: {
                ...this.state.action,
                priority: {type: newPriority}
            },
            isActionSetupChanged: this.props.action.priority.type !== newPriority
        })
    };

    handleProgressChange = event => {
        if ((event.target.value >= 0) && (event.target.value <= 100)) {
            const progress = parseInt(event.target.value);
            this.setState({
                action: {
                    ...this.state.action,
                    progress: progress ? progress : 0,
                    status: progress === 100 ? 'DONE' : 'IN_PROGRESS',
                    finishedDate: progress === 100 ? new Date() : ''
                },
                isActionSetupChanged: this.props.action.progress !== progress
            })
        }
    };

    handleStatusChange = event => {
        const newStatus = event.target.checked ? 'DONE' : 'IN_PROGRESS';
        this.setState({
            savedProgress: event.target.checked ? this.state.action.progress : this.state.savedProgress,
            action: {
                ...this.state.action,
                progress: event.target.checked ? 100 : parseInt(this.state.savedProgress),
                status: newStatus,
                finishedDate: event.target.checked ? new Date() : ''
            },
            isActionSetupChanged: this.props.action.status !== newStatus
        })
    };

    handleResponsibleChange = user => {
        this.setState({
            action: {
                ...this.state.action,
                assignee: user
            },
            isActionSetupChanged: _.get(this.props, 'action.assignee.businessId') !== user.businessId
        });
    };

    handleDescriptionChange = newEditorState => {
        const changes = editorUtils.calculateTranslationsChange(
            newEditorState,
            this.state.action.translations,
            this.props.action.translations,
            this.props.i18n.language
        );
        if (changes.isContentDifferent) {
            const isContentStateEverChanged = changes.isContentDifferent || this.state.isTranslationChanged;

            this.setState(oldState => {
                return {
                    action: update(oldState.action, {
                        translations: {$set: changes.translations}
                    }),
                    isTranslationChanged: isContentStateEverChanged,
                };
            });
        }
    };

    handleTitleChange = event => {
        const target = event.target;
        const matchingTranslationIndex = this.state.action.translations
            .findIndex(translation => translation.language.code === this.props.i18n.language);
        if (this.state.action.translations[matchingTranslationIndex].title !== target.value) {
            const translations = this.state.action.translations.map((translation, index) => {
                if (index === matchingTranslationIndex) {
                    return {
                        ...translation,
                        title: target.value,
                        origin: NEW
                    }
                } else {
                    return this.props.action.translations[index]
                }
            });
            this.setState(oldState => {
                return {
                    action: update(oldState.action, {
                        translations: {$set: translations}
                    }),
                    isTranslationChanged: true
                };
            });
        }
    };

    saveAction = (forceTranslate) => {
        this.leaveEdit();
        if (!this.isSaveDisabled()) {
            if (_.get(this.props, 'actionBusinessId')) {
                this.props.editAction(this.state.action, forceTranslate).then(() => {
                    this.showSaveIndicator();
                    this.setState({
                        isTranslationChanged: false,
                        isActionSetupChanged: false
                    });
                });
            } else {
                this.props.createAction(this.props.taskBusinessId, this.state.action).then(response => {
                    if (this.mounted) {
                        const actionBusinessId = _.get(response, 'payload.data.data.createAction.businessId')
                            || _.get(response, 'data.action.businessId');
                        if (actionBusinessId) {
                            this.leaveEdit();
                            this.props.routeProps.history.push(TASK_ACTION_EDIT.pathWithIds(this.props.taskBusinessId, actionBusinessId), {
                                origin: 'actionCreate',
                                prevPath: this.state.prevPath
                            });
                        }
                    }
                })
            }
        }
    };

    goBack = () => {
        this.leaveEdit();
        routeTo(this.state.prevPath, this.props.routeProps);
    };

    leaveEdit = () => {
        this.leaveOnPurpose = true;
    };

    onSuccessfulFileUpload = attachment => {
        this.setState(oldState => {
            return {
                action: {
                    ...oldState.action,
                    attachments: update(oldState.action.attachments || [], {
                        $push: [attachment]
                    })
                }
            }
        })
    };

    showSaveIndicator = () => {
        this.setState({showSaveIndicator: true});
    };

    closeSaveIndicator = () => {
        this.setState({showSaveIndicator: false});
    };

    shouldForceTranslateAutomatically = () => {
        return editorUtils.shouldForceTranslateAutomatically(this.state.action.translations, this.props.i18n.language);
    };

    matchingTranslation = () => {
        return this.state.action.translations.find(translation => translation.language.code === this.props.i18n.language)
            || this.state.action.translations[0];
    };

    isSaveDisabled = () => {
        const matchingTranslation = this.matchingTranslation();
        return !matchingTranslation.description
            || !matchingTranslation.title
            || !_.get(this.state.action, 'priority.type')
            || !this.state.action.dueDate
            || !(!_.get(this.state.action, 'businessId') || _.get(this.props.project, 'currentUser.hasEditRight', false))
            || (!this.state.isTranslationChanged && !this.state.isActionSetupChanged);
    };

    render() {
        const {action, isTranslationChanged, showSaveIndicator} = this.state;
        const {
            classes, newActionRefId, user, applicationOnline, t: translate, taskBusinessId, project, archiveAction,
            task, routeProps, comments
        } = this.props;
        const {
            handleNumberChangeOnAction, handleDueDateChange, handlePriorityChange, handleProgressChange,
            handleStatusChange, handleResponsibleChange, handleDescriptionChange, saveAction, leaveEdit,
            handleTitleChange, goBack, fetchAction, closeSaveIndicator, shouldForceTranslateAutomatically
        } = this;
        const projectBreadCrumb = !!task.businessId
            ? _.get(task, 'projectBreadCrumb')
            : _.get(project, 'breadCrumb');
        const isOfflineCreated = taskBusinessId.startsWith("OFFLINE");
        const actionBusinessId = _.get(action, 'businessId');
        const isActionSelected = !!actionBusinessId;
        const hasEditRight = _.get(project, 'currentUser.hasEditRight', false);
        const isMobile = utils.checkBrowser();
        const listUrl = routeProps.match.params.actionBusinessId
            ? routeProps.match.url.replace(routeProps.match.params.actionBusinessId, 'list')
            : routeProps.match.url.replace('create', 'list');
        const maxDueDate = utils.maxDueDate(task.dueDate);
        const prevPath = this.state.prevPath || listUrl;
        const isPersonalAction = _.get(project, 'type') === PERSONAL_PROJECT_TYPE;
        const tabLabelSuffix = actionBusinessId ? actionBusinessId : `${translate('global.new')} ${translate('global.action')}`;
        const tabLabel = `${_.get(window, 'appConfig.environment')} - ${tabLabelSuffix}`;
        let matchingTranslation;
        let isSaveDisabled;
        if (!_.isEmpty(action)) {
            matchingTranslation = this.matchingTranslation();
            isSaveDisabled = this.isSaveDisabled();
        }
        return (
            !_.isEmpty(project) && !_.isEmpty(action) && !_.isEmpty(task) ?
                <>
                    {
                        project.currentUser.hasAccess ?
                            <Flex item container direction={'column'} className={classes.containerDiv}>
                                <Helmet>
                                    <title>{tabLabel}</title>
                                </Helmet>
                                {
                                    isMobile
                                        ?
                                        <MobileActionEdit
                                            {...{
                                                action, newActionRefId, user, prevPath, routeProps, hasEditRight,
                                                maxDueDate, applicationOnline, isOfflineCreated, matchingTranslation,
                                                isTranslationChanged, archiveAction, isPersonalAction, isSaveDisabled,
                                                users: project.users,
                                                isSelected: isActionSelected,
                                                save: saveAction,
                                                handleNumberChangeOnAction,
                                                handleDueDateChange,
                                                handlePriorityChange,
                                                handleProgressChange,
                                                handleStatusChange,
                                                handleResponsibleChange,
                                                handleDescriptionChange,
                                                handleTitleChange,
                                                leaveEdit,
                                                shouldForceTranslateAutomatically,
                                                goBack
                                            }}/>
                                        :
                                        <DesktopActionEdit
                                            {...{
                                                action, comments, hasEditRight, applicationOnline, maxDueDate,
                                                routeProps, isTranslationChanged, isPersonalAction, archiveAction,
                                                projectBreadCrumb, matchingTranslation, isSaveDisabled,
                                                users: project.users,
                                                isSelected: isActionSelected,
                                                handleNumberChangeOnAction,
                                                handleDueDateChange,
                                                handlePriorityChange,
                                                handleProgressChange,
                                                handleStatusChange,
                                                handleResponsibleChange,
                                                handleDescriptionChange,
                                                handleTitleChange,
                                                save: saveAction,
                                                goBack,
                                                fetchAction,
                                                shouldForceTranslateAutomatically
                                            }}
                                        />
                                }
                            </Flex>
                            :
                            <ProjectAuthorizationErrorLabel/>
                    }
                    <SaveIndicatorMessage {...{open: showSaveIndicator, onClose: closeSaveIndicator}}/>
                </>
                :
                <PageIsLoading/>
        );
    }
}

export default withStyles(styles)(connect(connector.mapStateToProps, connector.mapDispatchToProps)(withTranslation()(ActionEdit)));
