import {changeFilter} from 'scenes/tasks/task-list/TaskList.action';

export default {
    mapStateToProps: (state) => {
        return {
            filters: state.taskListReducer.filters,
        };
    },
    mapDispatchToProps: (dispatch) => {
        return {
            changeFilter: filter => dispatch(changeFilter(filter)),
        };
    }
};
