import {
    executeAudit,
    fetchAudits,
    fetchInitDataForAdHocAudit,
    finishAudit,
    finishReview,
    reviewAudit,
    setEmptyInitDataForAdHocAudit,
    submitAdHocAuditForm,
} from './Audit.action'

export default {
    mapStateToProps: (state) => {
        return {
            audits: state.auditReducer.audits,
            auditSetup: state.auditReducer.auditSetup,
            adHocAuditForm: state.auditReducer.adHocAuditForm,
            result: state.auditReducer.result,
            selectedOrganizationLevel: state.organizationTreeReducer.selectedOrganizationLevel,
            pictureUrlsToDownload: state.auditReducer.pictureUrlsToDownload,
            applicationOnline: state.mainReducer.online
        };
    },
    mapDispatchToProps: (dispatch) => {
        return {
            fetchAudits:() => dispatch(fetchAudits()),
            executeAudit:(audit) => dispatch(executeAudit(audit)),
            reviewAudit:(audit) => dispatch(reviewAudit(audit)),
            submitAdHocAuditForm:(questionnaireId, randomizedNumber) => dispatch(submitAdHocAuditForm(questionnaireId, randomizedNumber)),
            fetchInitDataForAdHocAudit:(levelId) => dispatch(fetchInitDataForAdHocAudit(levelId)),
            setEmptyInitDataForAdHocAudit: () => dispatch(setEmptyInitDataForAdHocAudit()),
            finishAudit:(answeredQuestions, isFinished) => dispatch(finishAudit(answeredQuestions, isFinished)),
            finishReview:() => dispatch(finishReview()),
        };
    }
};
