import * as actionTypes from './Tasks.action.types';
import {LIST_TASK_GRAPHQL, EDIT_TASK_GRAPHQL} from 'domain/task/Tasks.model';
import taskCache from "./Tasks.cache";
import {USER_GRAPHQL} from 'domain/User.model';
import {TASK_CONFIGURATION_GRAPHQL} from 'domain/task/TaskConfiguration.model';
import {listProjects, updateProject} from './Project.action';
import {editTask} from 'scenes/tasks/task-edit/TaskEdit.action';
import {setTasksStatus} from 'scenes/tasks/task-list/TaskList.action';
import query from 'store/GraphqlQueries.js';

export function fetchTaskManagementData() {
    return (dispatch) => {

        Promise.all([
            dispatch(listTaskManagementTags()),
            dispatch(fetchUsers()),
            dispatch(fetchTaskConfiguration()),
            dispatch(listProjects())
        ]).then(() => {
            //TODO LM: Somebody end my suffering... This two embedded calls runs into same reducer states to calcualte
            // everything twice... It also was done to load the app faster, while we already store everything in indexeddb
            dispatch(initializeTaskManagementWithoutArrays()).then(() => {
                dispatch({
                    type: actionTypes.DONE_FETCHING_TASK_MANAGEMENT_DATA,
                    data: {
                        isTaskManagementReady: true
                    }
                });
                //TODO LM: Sadly these two calls cannot run parallel yet. I will fix this as part of Story-424.
                // dispatch(initializeTaskManagementWithActiveTasks());
                dispatch(initializeSelectedProjectTasksAndPersonalProject());
            });
        });

        dispatch({
            type: actionTypes.START_FETCHING_TASK_MANAGEMENT_DATA,
            data: {
                isTaskManagementReady: false
            }
        });
    };
}

/**
 * The sole purpose of this function is to initialize the application faster, do no use it to refresh or update
 * @returns {*}
 */
function initializeTaskManagementWithoutArrays() {
    return taskCache.cacheOrServeTasks(listActiveTasksWithoutArrays, listTasksFromCache);
}

function initializeSelectedProjectTasksAndPersonalProject() {
    return taskCache.cacheOrServeTasks(listSelectedProjectTasksAndPersonalProject, listTasksFromCache);
}

function listSelectedProjectTasksAndPersonalProject() {
    return (dispatch, getState) => {
        const filter = {
            projectBusinessIds: [getState().projectReducer.selectedProject.businessId, getState().projectReducer.personalProject.businessId],
            statuses: [
                ...getState().tasksReducer.configuration.activeWorkflowStatuses.map(status => status.businessId),
                ...getState().tasksReducer.configuration.backlogWorkflowStatuses.map(status => status.businessId),
            ]
        };
        return dispatch(listTasksFromServer(filter, 'listSelectedProjectTasksAndPersonalProject'));
    }
}

export function listProjectTasks() {
    return taskCache.cacheOrServeTasks(listProjectTasksForOfflineFromServer, listTasksFromCache);
}

export function listTasksFromCache(tasks) {
    return (dispatch, getState) => {
        return dispatch({
            type: actionTypes.LIST_TASKS_FROM_CACHE,
            data: {
                selectedProject: getState().projectReducer.selectedProject,
                personalProject: getState().projectReducer.personalProject,
                online: false,
                cache: {
                    tasks,
                }
            }
        })
    }
}

function listActiveTasksWithoutArrays() {
    return (dispatch, getState) => {
        const filter = {
            statuses: getState().tasksReducer.configuration.activeWorkflowStatuses.map(status => status.businessId),
            projectBusinessIds: [getState().projectReducer.selectedProject.businessId]
        };
        return dispatch(listTasksWithoutArraysFromServer(filter, 'listActiveTasksWithoutArrays'));
    }
}


function listProjectTasksForOfflineFromServer() {
    return (dispatch, getState) => {
        const filter = {
            projectBusinessIds: [getState().projectReducer.selectedProject.businessId],
            statuses: [
                ...getState().tasksReducer.configuration.activeWorkflowStatuses.map(status => status.businessId),
                ...getState().tasksReducer.configuration.backlogWorkflowStatuses.map(status => status.businessId),
            ]
        };
        return dispatch(listTasksFromServer(filter, 'listProjectTasksForOfflineFromServer'));
    }
}

export function listHistoryTasksFromServer() {
    return (dispatch, getState) => {
        const filter = {
            projectBusinessIds: [getState().projectReducer.selectedProject.businessId],
            statuses: getState().tasksReducer.configuration.historyWorkflowStatuses.map(status => status.businessId),
        };
        return dispatch(listTasksFromServer(filter, 'listHistoryTasksFromServer', actionTypes.LIST_HISTORY_TASKS));
    }
}

function listTasksFromServer(filter, caller, type) {
    return (dispatch, getState) => {
        return dispatch(
            query.graphql({
                type: type || actionTypes.LIST_TASKS,
                caller: caller,
                data: {
                    selectedProject: getState().projectReducer.selectedProject,
                    personalProject: getState().projectReducer.personalProject,
                    online: true
                },
                query: `
                       query
                           ($filter: TaskFilter){
                               listTasksByProjects(filter: $filter) {
                                   projectBusinessId
                                   tasks {
                                       ${EDIT_TASK_GRAPHQL}
                                   }
                               }
                           }
                       `,
                variables: {
                    filter
                }
            })
        );
    }
}

function listTasksWithoutArraysFromServer(filter, caller) {
    return (dispatch, getState) => {
        return dispatch(
            query.graphql({
                type: actionTypes.LIST_TASKS,
                caller: caller,
                data: {
                    selectedProject: getState().projectReducer.selectedProject,
                    online: true
                },
                query: `
                       query
                           ($filter: TaskFilter){
                               listTasksByProjects(filter: $filter) {
                                   projectBusinessId
                                   tasks {
                                        ${LIST_TASK_GRAPHQL}
                                   }
                               }
                           }
                       `,
                variables: {
                    filter
                }
            })
        );
    }
}

export function fetchTaskConfiguration() {
    return taskCache.cacheTaskConfiguration(fetchTaskConfigurationFromServer, fetchTaskConfigurationFromCache);
}

export function fetchTaskConfigurationFromCache(config) {
    return {
        type: actionTypes.FETCH_TASK_CONFIGURATION_FROM_CACHE,
        data: {
            cache: {
                config
            }
        }
    }
}

export function fetchTaskConfigurationFromServer() {
    return query.graphql({
        type: actionTypes.FETCH_TASK_CONFIGURATION,
        query: `
                {
                    fetchTaskConfigurationData {
                        ${TASK_CONFIGURATION_GRAPHQL}
                    }
                }
               `
    });
}

export function listTaskManagementTags() {
    return taskCache.cacheTags(listTaskManagementTagsFromServer, listTaskManagementTagsFromCache);
}

export function listTaskManagementTagsFromCache(tags) {
    return {
        type: actionTypes.LIST_TASK_MANAGEMENT_TAGS_FROM_CACHE,
        data: {
            cache: {
                tags
            }
        }
    }
}

export function listTaskManagementTagsFromServer() {
    return query.graphql({
        type: actionTypes.LIST_TASK_MANAGEMENT_TAGS,
        query: `
                query {
                        listTaskManagementTags {
                          businessId
                          label
                        }
                    }
               `
    });
}

export function fetchUsers() {
    return taskCache.cacheUsers(fetchUsersFromServer, fetchUsersFromCache);
}

export function fetchUsersFromCache(users) {
    return {
        type: actionTypes.FETCH_USERS_FROM_CACHE,
        data: {
            cache: {
                users
            }
        }
    }
}

export function fetchUsersFromServer() {
    return query.graphql({
        type: actionTypes.FETCH_USERS,
        query: `
                {
                    listUsers {
                        ${USER_GRAPHQL}
                    }
                }
               `
    });
}

export function sortTasks(draggedTaskId, destinationTaskId) {
    return (dispatch, getState) => {
        const taskBusinessIds = getState().tasksReducer.activeTasks.concat(getState().tasksReducer.backlogTasks).map(task => task.businessId);
        const indexOfDraggedTaskBusinessIdInTaskList = taskBusinessIds.findIndex(taskBusinessId => taskBusinessId === draggedTaskId);
        const indexOfDestinationTaskBusinessIdInTaskList = taskBusinessIds.findIndex(taskBusinessId => taskBusinessId === destinationTaskId);
        taskBusinessIds.splice(indexOfDraggedTaskBusinessIdInTaskList, 1);
        taskBusinessIds.splice(indexOfDestinationTaskBusinessIdInTaskList, 0, draggedTaskId);
        dispatch(saveNewTaskOrder(taskBusinessIds));
        dispatch(updateProject({taskBusinessIds})).then(() => {
            dispatch(listProjects());
        });
    }
}

function saveNewTaskOrder(taskBusinessIds) {
    return {
        type: actionTypes.SAVE_NEW_TASK_ORDER,
        data: {
            taskBusinessIds
        }
    }
}

export function sortTasksAndEditStatus(draggedTaskStatus, draggedTaskId, destinationTaskStatus, destinationTaskBusinessId) {
    return (dispatch, getState) => {
        if (getState().mainReducer.online) {
            const updatedTaskColumns = Array.from(getState().tasksReducer.kanbanTaskColumns);
            const sourceTaskColumn = updatedTaskColumns.find(taskColumn => taskColumn.label === draggedTaskStatus); //TODO review label based search
            const destinationTaskColumn = updatedTaskColumns.find(taskColumn => taskColumn.label === destinationTaskStatus); //TODO review label based search
            const draggedTaskIndex = sourceTaskColumn && sourceTaskColumn.tasks.findIndex(task => task.businessId === draggedTaskId);
            const draggedTask = sourceTaskColumn && sourceTaskColumn.tasks[draggedTaskIndex];
            if (destinationTaskBusinessId) {
                const destinationTaskIndex = destinationTaskColumn &&
                    destinationTaskColumn.tasks.findIndex(task => task.businessId === destinationTaskBusinessId);
                sourceTaskColumn.tasks.splice(draggedTaskIndex, 1);
                destinationTaskColumn.tasks.splice(destinationTaskIndex, 0, draggedTask);
            } else {
                sourceTaskColumn.tasks.splice(draggedTaskIndex, 1);
                if (destinationTaskColumn) {
                    destinationTaskColumn.tasks.push(draggedTask);
                }
            }
            if (draggedTaskId !== destinationTaskBusinessId) {
                const statuses = getState().tasksReducer.configurationFromBackend.statuses;
                dispatch(updateProject(
                    {activeTaskColumnOrders: mapUpdatedActiveTaskColumnOrders(updatedTaskColumns, statuses)}
                ));
            }
            dispatch(saveNewTaskColumnOrder(updatedTaskColumns));
            if (draggedTaskStatus !== destinationTaskStatus) {
                const statusInputForBackend = getState().tasksReducer.configurationFromBackend.statuses
                    .find(status => status.label === destinationTaskStatus);
                dispatch(editTask({...draggedTask, status: statusInputForBackend}));
            }
        }
    };
}

export function closeTask(task) {
    return (dispatch, getState) => {
        const statusInputForBackend = getState().tasksReducer.configurationFromBackend.statuses
            .find(status => status.label === getState().tasksReducer.configuration.closeWorkflowStatus.label);
        return dispatch(editTask({...task, status: statusInputForBackend}));
    }
}

export function reOpenTask(task) {
    return (dispatch, getState) => {
        const statusInputForBackend = getState().tasksReducer.configurationFromBackend.statuses
            .find(status => status.label === getState().tasksReducer.configuration.startingWorkflowStatus.label);
        return dispatch(editTask({...task, status: statusInputForBackend}));
    }
}

function saveNewTaskColumnOrder(taskColumns) {
    return {
        type: actionTypes.SAVE_NEW_TASK_COLUMN_ORDER,
        data: {
            taskColumns
        }
    }
}

function mapUpdatedActiveTaskColumnOrders(updatedTaskColumns, statuses) {
    return updatedTaskColumns
        .map(taskColumn => {
            const status = statuses.find(status => status.label.toUpperCase() === taskColumn.label.toUpperCase());
            return {
                status: {
                    businessId: status.businessId
                },
                taskBusinessIds: taskColumn.tasks.map(task => task.businessId)
            }
        });
}

export function moveTasksToKanbanBoard(tasks) {
    if (tasks.length === 1) {
        return moveTaskToKanbanBoard(tasks[0])
    } else {
        return moveMultipleTasksToKanbanBoard(tasks)
    }
}

export function moveTaskToKanbanBoard(task) {
    return (dispatch, getState) => {
        return dispatch(editTask({...task, status: getState().tasksReducer.configuration.activeWorkflowStatuses[0]}));
    }
}

export function moveMultipleTasksToKanbanBoard(tasks) {
    return (dispatch, getState) => {
        const activeWorkflowStatus = getState().tasksReducer.configuration.activeWorkflowStatuses[0];
        return dispatch(setTasksStatus(activeWorkflowStatus, tasks));
    }
}

export function removeTasksFromKanbanBoard(tasks) {
    if (tasks.length === 1) {
        return removeTaskFromKanbanBoard(tasks[0])
    } else {
        return removeMultipleTasksFromKanbanBoard(tasks)
    }
}

export function removeTaskFromKanbanBoard(task) {
    return (dispatch, getState) => {
        return dispatch(editTask({...task, status: getState().tasksReducer.configuration.backlogWorkflowStatuses[0]}));
    }
}

export function removeMultipleTasksFromKanbanBoard(tasks) {
    return (dispatch, getState) => {
        const backlogWorkflowStatus = getState().tasksReducer.configuration.backlogWorkflowStatuses[0];
        return dispatch(setTasksStatus(backlogWorkflowStatus, tasks));
    }
}

export function archiveTasks(tasks) {
    if (tasks.length === 1) {
        return archiveTask(tasks[0])
    } else {
        return archiveMultipleTasks(tasks)
    }
}

export function archiveTask(task) {
    return (dispatch, getState) => {
        const statuses = getState().tasksReducer.configurationFromBackend.statuses;
        const lastStatus = statuses[statuses.length - 1];
        return dispatch(editTask({...task, status: lastStatus}));
    }
}

export function archiveMultipleTasks(tasks) {
    return (dispatch, getState) => {
        const statuses = getState().tasksReducer.configurationFromBackend.statuses;
        const lastStatus = statuses[statuses.length - 1];
        return dispatch(setTasksStatus(lastStatus, tasks));
    }
}
