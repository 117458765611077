import * as actionTypes from './AuditReportingMap.action.types';
import _ from 'lodash';
import {mapAttachment} from "domain/Attachment.model";

const initialState = {
    organizationLevel: {},
    markers: [],
    statistics: [],
    childrenStatistics: [],
    childrenSummaryBreakdown: new Map([]),
    latestAudit: {}
};

export default function (state = initialState, action) {

    switch (action.type) {
        case actionTypes.FETCH_ORGANIZATION_LEVEL_SUCCESS: {
            const newState = _.cloneDeep(state);
            if (_.get(action, 'payload.data.data.fetchOrganizationLevel')) {
                const user = _.get(action, 'data.user') || _.get(action, 'meta.previousAction.data.user');
                newState.organizationLevel = mapOrganizationLevel(action.payload.data.data.fetchOrganizationLevel);
                newState.markers = mapMarkers(action.payload.data.data.fetchOrganizationLevel, user);
            }
            return newState;
        }
        case actionTypes.FETCH_STATISTICS_SUCCESS: {
            const newState = _.cloneDeep(state);
            const fetchAuditMapStatistics = _.get(action, 'payload.data.data.fetchAuditMapStatistics');
            if (fetchAuditMapStatistics) {
                newState.statistics = mapStatistics(fetchAuditMapStatistics, 'statistics');
                newState.childrenStatistics = mapStatistics(fetchAuditMapStatistics, 'childrenStatistics');
                newState.childrenSummaryBreakdown = mapChildrenSummaryBreakdown(fetchAuditMapStatistics.childrenSummaryBreakdown)
                newState.latestAudit = mapLatestAudit(fetchAuditMapStatistics.latestAudit)
            }
            return newState;
        }
        default:
            return state;
    }
}

export function mapOrganizationLevel(organizationLevel) {
    return {
        parentLevel: organizationLevel.parentLevel && {
            businessId: organizationLevel.parentLevel.businessId,
            shortName: organizationLevel.parentLevel.shortName
        },
        picture: !_.isEmpty(_.get(organizationLevel, 'attachments')) ? {
            url: organizationLevel.attachments.map(mapAttachment)[0].downloadUrl,
            filename: organizationLevel.attachments.map(mapAttachment)[0].filename
        } : {},
        isLeaf: organizationLevel.childLevels.length === 0,
        map: !_.isEmpty(_.get(organizationLevel.view, 'map')) ? {
            url: organizationLevel.view.map.source.downloadUrl,
            width: organizationLevel.view.map.resolution.width,
            height: organizationLevel.view.map.resolution.height,
        } : {}
    }
}

export function mapMarkers(organizationLevel, user) {
    if (_.isEmpty(_.get(organizationLevel.view, 'childViews'))) {
        return [];
    }
    return organizationLevel.view.childViews
        .filter(childView => {
            const childLevels = _.get(organizationLevel,'childLevels', []);
            const userMemberships = _.get(childLevels.find(view => view.businessId === childView.businessId), 'members', [])
                    .filter(member => _.get(member, 'user.businessId') === user.businessId);
            return userMemberships.length > 0;
            }
        )
        .map(childView => {
            return {
                ordinal: childView.ordinal,
                x: childView.x,
                y: childView.y,
                businessId: childView.businessId
            }
        })
}

export function mapStatistics(fetchAuditHistoryData, field) {
    let statistics = [];
    if (!_.isEmpty(fetchAuditHistoryData[field])) {
        const numberOfAudits = fetchAuditHistoryData[field].map(statistic => statistic.count).reduce((total, num) => total + num);
        statistics = fetchAuditHistoryData[field].map(statistic => mapAuditStatistic(statistic, numberOfAudits));
    }
    return statistics;
}

export function mapAuditStatistic(auditStatistic, numberOfAudits) {
    return {
        count: auditStatistic.count,
        outcome: auditStatistic.outcome,
        percentage: Math.round(auditStatistic.count * 100 / numberOfAudits),
        all: numberOfAudits,
    }
}

function mapChildrenSummaryBreakdown(childrenSummaryBreakdown) {
    return new Map(childrenSummaryBreakdown.map(data => [data.organizationLevel.businessId, {
        outcome: data.outcome,
        auditType: data.auditType,
        numberOfFailedQuestions: _.get((data.answers || []).find(answer => answer.outcome === 'FAILED'), 'count', 0),
        numberOfQuestions: (data.answers || []).map(answer => answer.count).reduce((a, b) => {
            return a + b
        }, 0)
    }]));
}

function mapLatestAudit(latestAudit) {
    return _.isEmpty(latestAudit) ? {} : {
        outcome: latestAudit.outcome,
        auditType: latestAudit.auditType,
        date: latestAudit.interval.end,
        numberOfFailedQuestions: _.get((latestAudit.answers || []).find(answer => answer.outcome === 'FAILED'), 'count', 0),
        numberOfQuestions: (latestAudit.answers || []).map(answer => answer.count).reduce((a, b) => {
            return a + b
        }, 0)
    };
}
