import * as actionTypes from './TaskList.action.types';
import _ from 'lodash';
import {MY_ACTIONS_VALUE} from 'domain/task/Tasks.model';

const initialState = {
    filters: {
        view: MY_ACTIONS_VALUE,
    }
};

export default function (state = initialState, action) {
    switch (action.type) {
        case actionTypes.FILTER_CHANGE: {
            const newState = _.cloneDeep(state);
            newState.filters = {
                ...state.filters,
                ...action.data.filter
            };
            return newState;
        }
        default:
            return state;
    }
}
