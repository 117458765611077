import {logout, synchronize, toggleOnline} from 'scenes/Main.action';

export default {
    mapStateToProps: (state) => {
        return {
            user: state.mainReducer.user,
            filters: state.taskListReducer.filters,
            online: state.mainReducer.online,
        };
    },
    mapDispatchToProps: (dispatch) => {
        return {
            logout: () => dispatch(logout()),
            synchronize: () => dispatch(synchronize()),
            toggleOnline: () => dispatch(toggleOnline()),
        };
    }
};
