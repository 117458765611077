import React, {Component} from 'react';
import {Typography, withStyles, Snackbar, SnackbarContent} from "@material-ui/core";
import Flex from 'components/grid/Flex';
import {blueHighlight, greyHighlight, htmlLightGrey, htmlWhite, random, medium, htmlOrange} from 'components/colors/Colors';
import {Trans, withTranslation} from "react-i18next";
import _ from 'lodash';
import utils from 'utils/Utils';
import H4Avatar from "components/avatar/H4Avatar";
import {Loop as LoopIcon} from "@material-ui/icons";
import moment from "moment";
import Tooltip from "@material-ui/core/Tooltip";
import IosSwitch from 'components/switch/IOSSwitch';
import classNames from "classnames";
import WarningIcon from '@material-ui/icons/Warning';

export const styles = theme => ({
    titleBarItem: {
        lineHeight: 2,
        color: 'inherit'
    },
    hoverBackground: {
        backgroundColor: htmlWhite,
        transition: 'background-color 0.1s linear',
        '&:hover': {
            backgroundColor: greyHighlight
        }
    },
    checkBoxRoot: {
        padding: 0
    },
    snackbar: {
        paddingBottom: 10,
    },
    snackbarContent: {
        backgroundColor: medium,
        justifyContent: 'center'
    },
    nameContainer: {
        width: '15%',
        paddingRight: 10
    },
    dateContainer: {
        maxWidth: '15%',
        paddingRight: 10
    },
    longText: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap'
    }
});

export class ScheduleTile extends Component {

    state = {
        questionnaireHaveBeenReactivated: false
    };

    displayTranslatedFrequency = (schedule, translate) => {
        const data = {
            amount: schedule.frequency.amount
        };
        const moreThanOne = schedule.frequency.amount > 1;
        switch (schedule.frequency.unit) {
            case 'DAY':
                return moreThanOne ? translate("audit-administration.recurrence.days", data) : translate("audit-administration.recurrence.day", data);
            case 'WEEK':
                return moreThanOne ? translate("audit-administration.recurrence.weeks", data) : translate("audit-administration.recurrence.week", data);
            case 'MONTH':
                return moreThanOne ? translate("audit-administration.recurrence.months", data) : translate("audit-administration.recurrence.month", data);
            case 'QUARTER':
                return moreThanOne ? translate("audit-administration.recurrence.quarters", data) : translate("audit-administration.recurrence.quarter", data);
            case 'HALF_YEAR':
                return moreThanOne ? translate("audit-administration.recurrence.halfYears", data) : translate("audit-administration.recurrence.halfYear", data);
            case 'YEAR':
                return moreThanOne ? translate("audit-administration.recurrence.years", data) : translate("audit-administration.recurrence.year", data);
            default:
                throw Error("Unsupported frequency " + schedule.frequency.unit)
        }
    };

    displayNextSchedule = () => {
        const {schedule} = this.props;
        return this.displayDate(schedule.nextSchedule, schedule.duration);
    };

    displayLastOccurrence = () => {
        const {schedule} = this.props;
        const date = schedule.lastOccurrence;
        if (_.isEmpty(date)) {
            return ''
        }
        return this.displayDate(date, schedule.duration);
    };

    displayDate = (date, duration) => {
        const {t: translate} = this.props;

        const year = moment(date).format('YYYY');
        const month = translate('global.month.' + moment(date).format('MMMM'));
        const week = moment(date).format('WW');
        const startOfWeek = moment(date).startOf('isoWeek');
        const endOfWeek = moment(date).endOf('isoWeek');
        const startOfWeekMonth = translate('global.month.' + startOfWeek.format('MMMM')).substring(0, 3);
        const startOfWeekDay = startOfWeek.format('DD');
        const endOfWeekMonth = translate('global.month.' + endOfWeek.format('MMMM')).substring(0, 3);
        const endOfWeekDay = endOfWeek.format('DD');

        switch (duration) {
            case 'DAY':
                return date.format(utils.DATE_FORMAT);
            case 'WEEK':
                return translate('global.dateTimeFormats.week', {
                    year: year,
                    week: week,
                    startOfWeekMonth: startOfWeekMonth,
                    startOfWeekDay: startOfWeekDay,
                    endOfWeekMonth: endOfWeekMonth,
                    endOfWeekDay: endOfWeekDay
                });
            case 'MONTH':
                return translate('global.dateTimeFormats.month', {
                    year: year,
                    month: month
                });
            default:
                return '';
        }
    };

    toggleActive = schedule => event => {
        event.preventDefault();
        event.stopPropagation();
        this.setState({
            questionnaireHaveBeenReactivated: !this.props.schedule.haveActiveQuestionnaire
        });
        this.props.toggleActive(schedule);
    };

    handleQuestionnaireSnackClose = () => {
        this.setState({
            questionnaireHaveBeenReactivated: false
        });
    };

    tooltipTitle = () => {
        if (this.props.schedule.isExpired) {
            return this.props.t("audit-administration.scheduleList.expiredSchedule");
        } else if (!this.props.schedule.haveQuestions) {
            return this.props.t("audit-administration.scheduleList.noQuestionsForSchedule");
        }
        return '';
    };

    render() {
        const {
            classes, scheduleClasses, schedule, isSelected, handleDoubleClickOnSchedule, toggleScheduleSelection,
            t: translate, noAdminRights, toggleAuthorizationDialogOpen
        } = this.props;
        const {displayTranslatedFrequency, displayNextSchedule, displayLastOccurrence, toggleActive, tooltipTitle} = this;
        return (
            <Flex item container className={classes.hoverBackground} grow={0} padding={1} alignItems={'center'}
                  onDoubleClick={handleDoubleClickOnSchedule(schedule.businessId)}
                  onClick={toggleScheduleSelection(schedule)}
                  style={{
                      minHeight: 40,
                      backgroundColor: isSelected ? blueHighlight : '',
                  }}
            >

                <Tooltip
                    placement={"left"}
                    title={tooltipTitle()}
                >
                    <div className={scheduleClasses.active}>
                        {
                            (!_.isEmpty(schedule.frequency) && !schedule.isExpired) && schedule.haveQuestions &&
                            <IosSwitch
                                onChange={noAdminRights ? toggleAuthorizationDialogOpen : toggleActive(schedule)}
                                checked={schedule.active}
                            />
                        }
                        {
                            (!_.isEmpty(schedule.frequency) && (schedule.isExpired || !schedule.haveQuestions)) &&
                            <WarningIcon style={{marginLeft: 8, fill: htmlOrange}}/>
                        }
                    </div>
                </Tooltip>
                <div className={classNames(scheduleClasses.name, classes.nameContainer)}>
                    <Typography
                        className={classNames(classes.titleBarItem, classes.longText)}>{schedule.name}</Typography>
                </div>
                <div className={scheduleClasses.type}>
                    <Typography className={classes.titleBarItem}>{schedule.type}</Typography>
                </div>
                <div className={classNames(scheduleClasses.lastOccurrence, classes.dateContainer)}>
                    <Typography className={classNames(classes.titleBarItem, classes.longText)}>{displayLastOccurrence()}</Typography>
                </div>
                <div className={classNames(scheduleClasses.nextSchedule, classes.dateContainer)}>
                    {
                        schedule.active
                            ? <Typography className={classNames(classes.titleBarItem, classes.longText)}>{displayNextSchedule()}</Typography>
                            : <Typography className={classes.titleBarItem}>-</Typography>
                    }
                </div>
                <div className={scheduleClasses.duration}>
                    <Typography className={classes.titleBarItem}>
                        <Trans i18nKey={'audit-administration.duration.' + schedule.duration}/>
                    </Typography>
                </div>

                <div className={scheduleClasses.frequency}>
                    <div
                        className={classes.titleBarItem}>{
                        _.isEmpty(schedule.frequency) ?
                            <Trans
                                i18nKey={'audit-administration.scheduleList.once'}/>
                            :
                            <Flex item container>
                                <Flex item grow={0} shrink={0}>
                                    <LoopIcon style={{
                                        color: htmlLightGrey
                                    }}/>
                                </Flex>
                                <Flex item style={{
                                    paddingTop: 3
                                }}>
                                    <Typography>
                                        {
                                            displayTranslatedFrequency(schedule, translate)
                                        }
                                    </Typography>
                                </Flex>
                            </Flex>

                    }
                    </div>
                </div>

                <div className={scheduleClasses.auditor}>
                    <div
                        className={classes.titleBarItem}>
                        <Flex container item>
                            <Flex container item grow={0}>
                                <H4Avatar
                                    style={{
                                        height: 20,
                                        width: 20,
                                        fontSize: 10,
                                        color: _.isEmpty(schedule.auditor) && random
                                    }}
                                    {...{
                                        user: _.isEmpty(schedule.auditor) ? {
                                            givenName: '?'
                                        } : schedule.auditor
                                    }}
                                />
                            </Flex>
                            <Flex container item grow={0} alignItems={'center'} style={{
                                paddingLeft: 10
                            }}>
                                <Typography
                                    style={{
                                        color: _.isEmpty(schedule.auditor) && random
                                    }}
                                    className={classes.titleBarItem}>{_.isEmpty(schedule.auditor) ? translate('audit-administration.scheduleList.random') : utils.formattedUserName(schedule.auditor)}</Typography>
                            </Flex>

                        </Flex>

                    </div>
                </div>
                <Snackbar
                    className={classes.snackbar}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    open={this.state.questionnaireHaveBeenReactivated}
                    autoHideDuration={6000}
                    onClose={this.handleQuestionnaireSnackClose}
                >
                    <SnackbarContent
                        className={classes.snackbarContent}
                        classes={{action: classes.action}}
                        message={
                            <Trans
                                i18nKey={"audit-administration.scheduleList.questionnaireReactivated"}
                                values={{questionnaire: schedule.questionnaireName}}
                            />
                        }
                    />
                </Snackbar>
            </Flex>
        );
    }

}

export default withStyles(styles)(withTranslation()(ScheduleTile));
