import React, {Component} from 'react';
import {Avatar, Tooltip, withStyles} from "@material-ui/core";
import Flex from "components/grid/Flex";
import {Trans, withTranslation} from "react-i18next";
import {getName, getNameInitials} from "domain/User.model";
import {logoBlueLight, random, htmlWhite, htmlBlue} from "components/colors/Colors";
import _ from "lodash";
import Typography from "@material-ui/core/Typography";
import utils from 'utils/Utils';
import H4Avatar from "components/avatar/H4Avatar";
import {RANDOM} from "domain/audit/Schedule.model";

const styles = theme => ({
    auditorContainer: {
        paddingLeft: 25,
        paddingTop: 15,
        margin: '5px 5px 15px 20px'
    },
    avatarRoot: {
        width: 150,
        height: 150,
    },
});

export class AuditorSelector extends Component {
    render() {
        const {classes, selectedOrganizationLevel, schedule, handleAuditorChange, t: translate, isEditDisabled} = this.props;
        const members = _.get(selectedOrganizationLevel, 'members', []).map(member => member.user);
        const selectedUser = members.find(member => member.businessId === schedule.auditorId) || {};
        const isRandomAuditor = schedule.auditorId === RANDOM;
        return (
            <Flex container item basis={'50%'} direction={'column'} className={classes.auditorContainer}
                  alignItems={'flex-start'} style={{width: 200}}>
                <Typography className={classes.label}>
                    <Trans i18nKey={"audit-administration.scheduleEdit.auditor"}/>
                </Typography>
                <Flex container item justifyContent={'flex-start'}
                      style={{paddingTop: 10}}>
                    {
                        !_.isEmpty(selectedOrganizationLevel) &&
                        members.map((member, index) => (
                            <Flex item={'0 1 0'} key={index} style={{
                                position: 'relative'
                            }}>
                                <Flex container item>
                                    <Tooltip
                                        title={utils.formattedUserName(member)}
                                        placement={'bottom-start'}
                                        classes={{
                                            tooltip: classes.avatarTooltipLabel
                                        }}
                                    >
                                        <H4Avatar
                                            {...{user: member}}

                                            style={{
                                                marginLeft: 3,
                                                border: member.businessId === schedule.auditorId
                                                    ? `2px solid ${htmlBlue}`
                                                    : 'none'
                                            }}
                                            onClick={() => {
                                                if (!isEditDisabled) {
                                                    handleAuditorChange(member.businessId)
                                                }
                                            }}
                                        />
                                    </Tooltip>
                                </Flex>
                            </Flex>
                        ))
                    }
                    <Flex item={'0 1 0'} style={{
                        position: 'relative'
                    }}>
                        <Flex container item>
                            <Tooltip
                                title={translate('audit-administration.scheduleEdit.random')}
                                placement={'bottom-start'}
                                classes={{
                                    tooltip: classes.avatarTooltipLabel
                                }}
                            >

                                <H4Avatar
                                    {...{
                                        user: {
                                            businessId: "?",
                                            givenName: "?"
                                        }

                                    }}
                                    style={{
                                        color: random,

                                        marginLeft: 3,
                                        border: schedule.auditorId === RANDOM
                                            ? `2px solid ${htmlBlue}`
                                            : 'none'
                                    }}
                                    onClick={() => {
                                        if (!isEditDisabled) {
                                            handleAuditorChange(RANDOM)
                                        }
                                    }}
                                />
                            </Tooltip>
                        </Flex>
                    </Flex>
                </Flex>
                {
                    !_.isEmpty(schedule.auditorId) &&
                    <Flex container direction={"column"} alignItems={'center'} style={{width: 200}}>
                        <Flex item style={{
                            paddingTop: 20
                        }}>
                            {
                                isRandomAuditor || !_.get(selectedUser, 'avatar.downloadUrl') ?
                                    <Avatar
                                        classes={{root: classes.avatarRoot}}
                                        style={{
                                            backgroundColor: logoBlueLight,
                                            fontSize: 75,
                                            color: isRandomAuditor ? random : htmlWhite
                                        }}
                                    >
                                        {isRandomAuditor ? '?' : getNameInitials(selectedUser)}
                                    </Avatar>
                                    :
                                    <Avatar classes={{root: classes.avatarRoot}} src={selectedUser.avatar.downloadUrl}/>
                            }
                        </Flex>
                        <Flex item style={{
                            paddingTop: 10
                        }}>
                            <Typography className={classes.label}
                                        style={{fontSize: 10}}>{isRandomAuditor ? translate('audit-administration.scheduleEdit.random') : getName(selectedUser)}</Typography>
                        </Flex>
                    </Flex>
                }
            </Flex>

        );
    }
}

export default withStyles(styles)(withTranslation()(AuditorSelector));
