import React from "react";
import {withTranslation} from "react-i18next";
import {Typography, withStyles} from "@material-ui/core";
import {doveGrey} from "components/colors/Colors";

const styles = theme => ({
    centered: {
        position: 'fixed',
        top: '50%',
        left: '50%',
        color: doveGrey
    }
});

export class AuditAuthorizationErrorLabel extends React.PureComponent {

    render() {
        const {t: translate, classes} = this.props;

        return (
            <Typography className={classes.centered}>{translate('audit-administration.missingMembership')}</Typography>
        );
    }
}

export default withStyles(styles)(withTranslation()(AuditAuthorizationErrorLabel));
