import _ from "lodash";
import * as actionTypes from './Monitoring.action.types';
import moment from "moment";
import {mapAuditsForMonitoringListing, mapDeviations, mapLastReminder} from "domain/audit/Audit.model";

const initialState = {
    audits: [],
    dailyAuditColumns: [],
    longAuditRows: [],
    auditors: [],
    deviations: []
};

export default function (state = initialState, action) {
    switch (action.type) {
        case actionTypes.FETCH_AUDITS_SUCCESS: {
            const newState = _.cloneDeep(state);
            const supportedWeekdays = _.get(action, 'data.supportedWeekdays') || _.get(action, 'meta.previousAction.data.supportedWeekdays');

            newState.audits = _.sortBy(mapAuditsForMonitoringListing(action.payload.data.data.listAuditsForMonitoring), 'scheduleId');
            return calculateColumnsAndRows(newState, supportedWeekdays);
        }
        case actionTypes.FETCH_DEVIATIONS_SUCCESS: {
            const newState = _.cloneDeep(state);
            newState.deviations = mapDeviations(action.payload.data.data.listTasksForAudit);
            return newState;
        }
        case actionTypes.SEND_AUDIT_REMINDER_SUCCESS: {
            const newState = _.cloneDeep(state);
            const supportedWeekdays = _.get(action, 'data.supportedWeekdays') || _.get(action, 'meta.previousAction.data.supportedWeekdays');
            newState.audits.find(audit => audit.businessId === action.payload.data.data.sendAuditReminder.businessId).lastReminder = mapLastReminder(action.payload.data.data.sendAuditReminder.lastReminder);
            return calculateColumnsAndRows(newState, supportedWeekdays)
        }
        default:
            return state;
    }
}

function calculateColumnsAndRows(newState, week) {
    const auditsByScheduleAndSchedule = getAuditsByScheduleAndSchedule(newState.audits);
    newState.auditors = getAuditors(newState.audits);
    newState.dailyAuditColumns = calculateDailyAuditColumns(week, auditsByScheduleAndSchedule.get("DAILY"));
    newState.longAuditRows = calculateLongAuditRows(week, auditsByScheduleAndSchedule.get("OTHER"));
    return newState
}

function getAuditsByScheduleAndSchedule(audits) {
    const auditsByCycle = new Map();
    const dailyAuditsBySchedule = new Map();
    const otherAuditsBySchedule = new Map();
    auditsByCycle.set("DAILY", dailyAuditsBySchedule);
    auditsByCycle.set("OTHER", otherAuditsBySchedule);
    audits.forEach((audit, index) => {
        if (audit.cycle === 'Daily') {
            const key = _.isEmpty(audit.scheduleId) ? "ADHOC-" + index : audit.scheduleId;
            if (dailyAuditsBySchedule.has(key)) {
                dailyAuditsBySchedule.get(key).push(audit)
            } else {
                dailyAuditsBySchedule.set(key, [audit])
            }
        } else {
            otherAuditsBySchedule.set(index, audit);
        }
    });
    return auditsByCycle;
}

function initDailyAuditColumns(week, numberOfSchedules) {
    const dailyAuditColumns = [];
    week.forEach(() => {
        const emptyAudits = [];
        for (let i = 0; i < numberOfSchedules; i++) {
            emptyAudits[i] = null;
        }
        dailyAuditColumns.push(emptyAudits);
    });
    return dailyAuditColumns;
}

function calculateDailyAuditColumns(week, dailyAuditsBySchedule) {
    const numberOfSchedules = dailyAuditsBySchedule.size;
    const dailyAuditColumns = initDailyAuditColumns(week, numberOfSchedules);
    let scheduleRow = 0;
    dailyAuditsBySchedule.forEach((value) => {
        value.forEach(audit => {
            week.forEach((day) => {
                if (moment(day).date() === moment(audit.interval.start).date()) {
                    dailyAuditColumns[moment(day).isoWeekday() - 1][scheduleRow] = audit;
                }
            });
        });
        scheduleRow = scheduleRow + 1;
    });
    return dailyAuditColumns;
}

function calculateLongAuditRows(week, auditsBySchedule) {
    const longAuditRows = [];
    auditsBySchedule.forEach((audit) => {
        const startOf = moment(audit.interval.start);
        const endOf = moment(audit.interval.end);
        const startOverFlow = startOf.isBefore(week[0]);
        const endOverFlow = endOf.isAfter(week[week.length - 1]);
        const start = startOverFlow ? 0 : startOf.isoWeekday() - 1;
        const length = endOf.diff(moment(week[0]), 'day') + 1;
        const row = {
            audit: audit,
            start: startOverFlow ? 0 : start,
            length: endOverFlow ? week.length - start : length - start,
            startOverFlow: startOverFlow,
            endOverFlow: endOverFlow
        };
        longAuditRows.push(row);
    });
    return longAuditRows;
}

function getAuditors(audits) {
    const auditors = audits.filter(audit => !_.isEmpty(audit.auditor)).map(audit => audit.auditor);
    return _.uniqBy(auditors, 'businessId');
}
