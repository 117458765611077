import _ from "lodash";
import * as actionTypes from './QuestionnaireEdit.action.types';
import {emptyQuestionnaire, mapQuestionnaire, mapQuestions} from "domain/audit/Questionnaire.model";

const initialState = {
    questionnaire: {}
};

export default function (state = initialState, action) {
    switch (action.type) {
        case actionTypes.FETCH_EMPTY_QUESTIONNAIRE: {
            const newState = _.cloneDeep(state);
            newState.questionnaire = emptyQuestionnaire();
            return newState;
        }
        case actionTypes.FETCH_QUESTIONNAIRE_SUCCESS: {
            const newState = _.cloneDeep(state);
            const user = _.get(action, 'data.user') || _.get(action, 'meta.previousAction.data.user');
            newState.questionnaire = mapQuestionnaire(action.payload.data.data.fetchQuestionnaire,  user);
            return newState;
        }
        case actionTypes.FETCH_QUESTIONS_SUCCESS: {
            const newState = _.cloneDeep(state);
            if (_.get(action, 'payload.data.data.listQuestions')) {
                newState.questions = sortByBusinessId(mapQuestions(action.payload.data.data.listQuestions));
            }
            return newState;
        }
        default:
            return state;
    }
}

export function sortByBusinessId(array) {
    return _.sortBy(array, item => parseInt(item.businessId.replace('Q', '0')));
}

