import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import rootReducer from './Root.reducer';
import { multiClientMiddleware } from 'redux-axios-middleware';
import { axiosClients } from './Clients';

import { interceptors as interceptorsConfig } from './interceptors-config';

const axiosOptions = {
    returnRejectedPromiseOnError: true,
    interceptors: interceptorsConfig
};
const middlewares = [thunk, multiClientMiddleware(axiosClients, axiosOptions)];

const initialState = {};

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(rootReducer, initialState, composeEnhancers(applyMiddleware(...middlewares)));

export default store;
