import React, {Fragment, PureComponent} from "react";
import classNames from "classnames";
import {Button, Typography, withStyles} from "@material-ui/core";
import Flex from "components/grid/Flex";
import {Trans, withTranslation} from "react-i18next";
import Divider from "@material-ui/core/Divider";
import {htmlGrey, christiGreen, critical} from "components/colors/Colors";
import {YES_NO_ANSWER_LABEL, OK_NOK_ANSWER_LABEL, ZERO_TEN_ANSWER_LABEL} from "domain/audit/Question.model";
import {LensOutlined as OKIcon, Close as NOKIcon} from '@material-ui/icons';
import RatingSelector010 from 'components/rating-selector/RatingSelector0-10';
import BootstrapInput from 'components/bootstrap-input/BootstrapInput';

const styles = theme => ({
    button: {
        marginLeft: 90,
        marginRight: 90
    },
    divider: {
        marginTop: 5,
        marginBottom: 25,
    },
    label: {
        color: htmlGrey,
        fontSize: 12,
        fontWeight: 'bold',
        paddingTop: 25
    }
});

export class AnswerTypePreview extends PureComponent {
    render() {
        const {classes, answerType} = this.props;
        return (
            <Fragment>
                <Typography className={classNames(classes.label)}>
                    <Trans i18nKey={"global.preview"}/>
                </Typography>
                <Divider classes={{
                    root: classes.divider
                }}/>
                {(() => {
                    switch (answerType) {
                        case YES_NO_ANSWER_LABEL: {
                            return (
                                <Flex container item justifyContent={'center'} grow={0}>
                                    <Button variant="contained" className={classes.button}>
                                        <Trans i18nKey={"global.yes"}/>
                                    </Button>
                                    <Button variant="contained" className={classes.button}>
                                        <Trans i18nKey={"global.no"}/>
                                    </Button>
                                </Flex>
                            );
                        }
                        case OK_NOK_ANSWER_LABEL: {
                            return (
                                <Flex container item justifyContent={'center'} grow={0}>
                                    <Button variant="contained" className={classes.button}>
                                        <OKIcon style={{color: christiGreen}}/>
                                    </Button>
                                    <Button variant="contained" className={classes.button}>
                                        <NOKIcon style={{color: critical}}/>
                                    </Button>
                                </Flex>
                            );
                        }
                        case ZERO_TEN_ANSWER_LABEL: {
                            return (
                                <Flex container item justifyContent={'center'} grow={0}>
                                    <RatingSelector010
                                        style={{width: 350}}
                                    />
                                </Flex>
                            );
                        }
                        default: {
                            return (
                                <span/>
                            );
                        }
                    }
                })()}
            </Fragment>
        );
    }
}

export default withStyles(styles)(withTranslation()(AnswerTypePreview));
