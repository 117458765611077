import React, {Fragment, PureComponent,} from 'react';
import {DialogContent, Typography, withStyles} from "@material-ui/core";
import {withTranslation} from "react-i18next";
import {random} from "components/colors/Colors";
import ConfirmationDialog from "components/confirmation-dialog/ConfirmationDialog";

export const styles = theme => ({
    text: {
        fontSize: 14,
    },
    listItemText: {
        fontSize: 14,
        color: random
    }
});

export class DeactivateScheduleDialog extends PureComponent {

    render() {
        const {classes, questionnaire, t: translate, dialogOpen, singleConfirmationButton, onDialogClose, onConfirm} = this.props;
        return (
            <Fragment>
                {
                    questionnaire.isAssignedToAuditSchedule ?
                        <ConfirmationDialog dialogOpen={dialogOpen}
                                            singleConfirmationButton={singleConfirmationButton}
                                            onDialogClose={onDialogClose}
                                            onConfirm={onConfirm}
                                            warningConfirmation={true}
                                            titleStyles={{fontWeight: 'bold'}}
                                            confirmationTextKey={"audit-administration.questionnaireEdit.deactivateQuestionnaire"}
                                            children={
                                                <DialogContent>
                                                    <div>
                                                        <Typography className={classes.text}
                                                                    style={{
                                                                        paddingBottom: 15
                                                                    }}
                                                        >
                                                            {translate('audit-administration.questionnaireEdit.usedInScheduledAudit')}
                                                        </Typography>
                                                        <Typography className={classes.text}>
                                                            {translate('audit-administration.questionnaireEdit.scheduleDeactivation')}
                                                        </Typography>
                                                        <ul>
                                                            {
                                                                questionnaire.assignedAuditSchedules.map((assignedAuditSchedule, index) => {
                                                                        return (
                                                                            <li key={index}
                                                                                className={classes.listItemText}>
                                                                                <Typography
                                                                                    className={classes.listItemText}>
                                                                                    {assignedAuditSchedule}
                                                                                </Typography>
                                                                            </li>
                                                                        )
                                                                    }
                                                                )
                                                            }
                                                        </ul>
                                                        <Typography className={classes.text} style={{
                                                            paddingTop: 15
                                                        }}>
                                                            {translate('audit-administration.questionnaireEdit.scheduleDeactivationConfirmation')}
                                                        </Typography>
                                                    </div>
                                                </DialogContent>

                                            }
                        />
                        :
                        <ConfirmationDialog dialogOpen={dialogOpen}
                                            singleConfirmationButton={singleConfirmationButton}
                                            onDialogClose={onDialogClose}
                                            onConfirm={onConfirm}
                                            confirmationTextKey={"audit-administration.questionnaireEdit.deactivateQuestionnaireConfirmation"}
                        />
                }
            </Fragment>


        );
    }
}

export default withStyles(styles)(withTranslation()(DeactivateScheduleDialog));
