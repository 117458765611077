import React, {Component, Fragment} from 'react';
import {
    withStyles,
    Typography,
    IconButton
} from "@material-ui/core";
import Flex from 'components/grid/Flex';
import utils from 'utils/Utils';
import {getColorbyPriority} from 'components/colors/Priority';
import {Trans, withTranslation} from "react-i18next";
import BootstrapInput from 'components/bootstrap-input/BootstrapInput';
import BootstrapInputWithStartadornment from 'components/bootstrap-input/BootstrapInputWithStartadornment';
import ConfiguredBootstrapSelect from 'components/configured-bootstrap-select/ConfiguredBootstrapSelect';
import BootstrapDatepicker from 'components/bootstrap-datepicker/BootstrapDatepicker';
import PriorityCircle from 'components/priority/PriorityCircle';
import PriorityFlash from 'components/priority/PriorityFlash';
import Responsible from 'components/responsible/Responsible';
import {Link, Route} from 'react-router-dom';
import MobileTaskHeader from 'scenes/tasks/components/task-header/MobileTaskHeader';
import _ from 'lodash';
import MobileDescription from 'scenes/tasks/components/mobile/MobileDescription';
import ConfirmationDialog from 'components/confirmation-dialog/ConfirmationDialog';
import {CloseOutlined as CloseIcon, Done as DoneIcon, Delete as DeleteIcon} from '@material-ui/icons';
import {
    silver,
    guardsmanRed,
    greyBackground,
    low,
    auditPaperGrey,
    doveGrey,
    swipeActionGreen,
    htmlBlack,
    materialUIBorder,
    htmlWhite
} from 'components/colors/Colors';
import ReadonlySnackbar from './ReadonlySnackbar';
import GoogleIcon from 'components/google-icon/GoogleIcon';
import TranslationChangedDialog from 'scenes/tasks/components/TranslationChangedDialog';
import MobileBreadcrumbHeader from 'scenes/tasks/components/mobile/MobileBreadcrumbHeader';
import {ACTIVE_VALUE, BACKLOG_VALUE} from 'domain/task/Tasks.model';
import moment from 'moment';
import TagInput from 'components/tags/TagInput';
import taskUtils, {haveMatchingStatus} from 'scenes/tasks/Task.utils';

const styles = theme => ({
    descriptionPreviewContainer: {
        overflow: 'auto',
        border: `1px solid ${materialUIBorder}`,
        padding: '8px 10px 8px 10px',
        borderRadius: 6,
        backgroundColor: htmlWhite,
        flex: '1 1 auto',
        display: 'flex',
        color: htmlBlack,
        textDecoration: 'none'
    },
    fieldTitle: {
        color: silver,
        textTransform: 'uppercase',
        marginTop: 12,
        marginBottom: 2,
        fontSize: 12
    },
    inputEnabled: {
        borderRadius: 6,
        transaction: 'unset',
        boxShadow: 'none',
        textTransform: 'capitalize'
    },
    inputDisabled: {
        backgroundColor: greyBackground,
        borderRadius: 6,
        border: 'unset',
        transaction: 'unset',
        boxShadow: 'none',
        textTransform: 'capitalize',
        color: low
    },
    controlButtonBox: {
        backgroundColor: auditPaperGrey,
        borderRadius: 10,
        border: `1px solid ${doveGrey}`,
        padding: 2,
    },
    storyDetailsContainer: {
        minHeight: '70vh',
        paddingLeft: 5,
        paddingRight: 5,
        backgroundColor: auditPaperGrey,
        paddingBottom: 10
    },
    tagsBorder: {
        border: `1px solid ${materialUIBorder}`
    }
});

const Title = props => {
    const {classes, isTaskEditable, task, isSelected, matchingTranslation, handleTitleChange} = props;
    return (
        <Flex item grow={0} container direction={'column'}>
            <Typography className={classes.fieldTitle}><Trans
                i18nKey={"task-management.task-edit.title"}/></Typography>
            <BootstrapInputWithStartadornment
                classes={{input: isTaskEditable ? classes.inputEnabled : classes.inputDisabled}}
                inputProps={{
                    style: {
                        color: getColorbyPriority(task.priority.type),
                        fontWeight: 'bold'
                    },
                }}
                disabled={task.isDeviation || !isTaskEditable}
                fullWidth
                autoFocus={!isSelected}
                value={matchingTranslation.title}
                name={'title'}
                onChange={handleTitleChange}
                border={`2px solid ${getColorbyPriority(task.priority.type)}`}
                startAdornment={matchingTranslation.origin === 'GOOGLE_TRANSLATE' && <GoogleIcon/>}
            />
        </Flex>
    );
};

const ResponsibleLine = props => {
    const {classes, isTaskEditable, task, handleResponsibleChange, users} = props;
    return (
        <Flex item grow={0}>
            <Typography className={classes.fieldTitle}><Trans
                i18nKey={"task-management.task-edit.responsible"}/></Typography>
            <Responsible
                {...{
                    previousAssignee: task.delta ? task.delta.user : task.assignee,
                    dateTime: _.get(task, 'delta.dateTime'),
                    users,
                    assignee: task.assignee,
                    handleResponsibleChange,
                    backgroundColor: isTaskEditable ? htmlWhite : greyBackground,
                    menuWidth: '115%',
                    isDisabled: !isTaskEditable
                }}
            />
        </Flex>
    );
};

const Severity = props => {
    const {classes, isTaskEditable, task, handlePriorityChange, deviationSeverities, taskSeverities} = props;
    return (
        <Flex item basis={0} style={{paddingRight: 5}}>
            <Typography className={classes.fieldTitle}><Trans
                i18nKey={"task-management.task-edit.severity"}/></Typography>
            <ConfiguredBootstrapSelect
                bootstrapInputClasses={{input: isTaskEditable ? classes.inputEnabled : classes.inputDisabled}}
                value={task.priority.type}
                translationPath={"task-management.priority"}
                onChange={handlePriorityChange}
                name={'priority'}
                items={task.isDeviation ? deviationSeverities : taskSeverities}
                startAdornment={priority => {
                    return task.isDeviation ? <PriorityFlash priority={priority}/> :
                        <PriorityCircle priority={priority}/>
                }
                }
                disabled={task.isDeviation || !isTaskEditable}
            />
        </Flex>
    );
};

const SolveUntil = props => {
    const {classes, isTaskEditable, task, handleDueDateChange} = props;
    return (
        <Flex item basis={0} container direction={'column'}>
            <Flex item>
                <Typography className={classes.fieldTitle}><Trans
                    i18nKey={"task-management.task-edit.solveUntil"}/></Typography>
            </Flex>
            <BootstrapDatepicker
                classes={{input: isTaskEditable ? classes.inputEnabled : classes.inputDisabled}}
                fullWidth
                variant="outlined"
                value={task.dueDate}
                name={'dueDate'}
                disablePast
                minDateMessage={''}
                format={utils.DATE_FORMAT}
                onChange={handleDueDateChange}
                disabled={!isTaskEditable}
            />
        </Flex>
    );
};

const WorkflowStatus = props => {
    const {classes, isTaskEditable, task, handleChangeOnTask, statuses} = props;
    return (
        <Flex item basis={0} style={{paddingRight: 5}}>
            <Typography className={classes.fieldTitle}><Trans
                i18nKey={"task-management.task-edit.workflowStatus"}/></Typography>
            <ConfiguredBootstrapSelect
                bootstrapInputClasses={{input: isTaskEditable ? classes.inputEnabled : classes.inputDisabled}}
                value={task.status}
                objectWithLabel={'label'}
                onChange={handleChangeOnTask}
                name={'status'}
                items={statuses}
                disabled={!isTaskEditable}
                fullWidth
            />
        </Flex>
    );
};

const FinishedAt = props => {
    const {classes, task} = props;
    return (
        <Flex item basis={0} container direction={'column'}>
            <Flex item>
                <Typography className={classes.fieldTitle}><Trans
                    i18nKey={"task-management.task-edit.finishedAt"}/></Typography>
            </Flex>
            <BootstrapInput
                disabled={true}
                value={(task.finishedDate && moment(task.finishedDate).format(utils.DATE_FORMAT)) || ''}
                onChange={() => {
                }}
                fullWidth
            />
        </Flex>
    );
};

const RelatedTo = props => {
    const {classes, task, isTaskEditable, tagSuggestions, handleTagAddition, handleTagDelete, translate} = props;
    return (
        <Flex item container direction={'column'}>
            <Typography className={classes.fieldTitle}><Trans
                i18nKey={"task-management.task-edit.relatedTo"}/></Typography>
            <TagInput {...{
                classes: {root: classes.tagsBorder},
                isNewAllowed: true,
                tags: task.freeTags,
                disabled: !isTaskEditable,
                suggestions: tagSuggestions,
                onAddition: handleTagAddition,
                onDelete: handleTagDelete,
                placeholder: isTaskEditable ? translate('task-management.task-edit.tagPlaceholder') : _.isEmpty(task.freeTags) ? translate('task-management.task-edit.noTagsPlaceholder') : null
            }}
            />
        </Flex>
    );
};

const FooterButtons = props => {
    const {
        classes, save, saveWithOptions, isTaskEditable, routeProps, deleteTask, cancelDelete, isSelected,
        isSaveDisabled
    } = props;
    return (
        <Flex item container className={classes.controlButtonBox} style={{marginTop: 10, padding: 5}}>
            <Flex item container justifyContent={'flex-start'}>
                <Flex item>
                    <IconButton
                        component={Link}
                        to={utils.joinUrl(routeProps.match.url, '/delete')}
                        style={{
                            backgroundColor: !isTaskEditable || !isSelected ? silver : guardsmanRed,
                            padding: 8
                        }}
                        disabled={!isTaskEditable || !isSelected}
                    >
                        <DeleteIcon style={{color: htmlWhite}}/>
                    </IconButton>
                </Flex>
            </Flex>
            <Flex item grow={0} container justifyContent={'flex-end'}>
                <Flex item>
                    <IconButton onClick={()=>{save(true)}} style={{backgroundColor: doveGrey, marginRight: 5, padding: 8}}>
                        <CloseIcon style={{color: htmlWhite}}/>
                    </IconButton>
                </Flex>
                <Flex item>
                    <IconButton onClick={save}
                                disabled={isSaveDisabled}
                                style={{backgroundColor: isSaveDisabled ? silver : swipeActionGreen, padding: 8}}
                    >
                        <DoneIcon style={{color: htmlWhite}}/>
                    </IconButton>
                </Flex>
            </Flex>
            <Route path={routeProps.match.url + '/overwrite'} render={

                function renderTranslationOverWriteDialog(props) {
                    return <TranslationChangedDialog
                        {...{
                            originUrl: routeProps.match.url,
                            saveWithOptions,
                        }}
                    />
                }
            }/>
            <Route path={routeProps.match.url + '/delete'} render={

                function renderDeleteTaskDialog(props) {
                    return <ConfirmationDialog {...{
                        dialogOpen: true,
                        onDialogClose: cancelDelete,
                        onConfirm: deleteTask,
                        confirmationTextKey: "task-management.deleteTaskConfirmation"
                    }}/>
                }
            }/>
        </Flex>
    );
};

export class MobileTaskEdit extends Component {

    state = {
        isReadOnlyErrorShowed: false,
        readOnlyDialogOpened: false,
        text: '',
        goBackAfterSave: false
    };

    handleReadOnlyDialogOpen = () => {
        if (!(this.props.isTaskEditable || this.state.readOnlyDialogOpened || this.state.isReadOnlyErrorShowed)) {
            this.setState({
                readOnlyDialogOpened: true
            });
        }
    };

    handleReadOnlyDialogClose = () => {
        this.setState({
            readOnlyDialogOpened: false,
            isReadOnlyErrorShowed: true
        });
    };

    save = goBack => {
        taskUtils.unBoundSaveLogic.bind(this)(goBack);
    };

    saveWithOptions = forceTranslate => {
        taskUtils.unBoundSaveWithOptionsLogic.bind(this)(forceTranslate);
    };

    deleteTask = () => {
        this.props.archiveTask(this.props.task).then(() => {
            this.props.goBack()
        });
    };

    cancelDelete = () => {
        this.props.routeProps.history.goBack();
    };

    render() {
        const {
            classes, task, comments, attachments, checklist, actions, newTaskRefId, freeTags, deviationSeverities,
            taskSeverities, statuses, taskBusinessId, isTaskEditable, user, handleTagAddition, handleTagDelete,
            applicationOnline, routeProps, handleChangeOnTask, handlePriorityChange, handleDueDateChange,
            handleResponsibleChange, selectedProject, handleDescriptionChange, leaveEdit, onSuccessfulFileUpload,
            t: translate, onSuccessfulFileDelete, handleTitleChange, isTranslationChanged, isSelected,
            projectBreadCrumb, fetchStateTask, activeWorkflowStatuses, removeUnseenActionTag, removeUnseenCommentTag,
           removeUnseenChecklistTag, removeUnseenAttachmentTag, matchingTranslation, isSaveDisabled, language
        } = this.props;
        const {readOnlyDialogOpened} = this.state;
        const {handleReadOnlyDialogClose, save, saveWithOptions, deleteTask, cancelDelete} = this;
        const isTaskActive = haveMatchingStatus(activeWorkflowStatuses, task);
        return (
            <Fragment>
                <MobileBreadcrumbHeader {...{
                    breadCrumb: projectBreadCrumb,
                    user,
                    view: isTaskActive ? ACTIVE_VALUE : BACKLOG_VALUE
                }}/>
                <ReadonlySnackbar
                    {...{
                        readOnlyDialogOpened,
                        handleReadOnlyDialogClose
                    }}
                />
                <Flex item container style={{marginTop: 10}}>
                    <Flex item container style={{
                        maxWidth: 5,
                        width: 5,
                        backgroundColor: getColorbyPriority(task.priority.type)
                    }}>
                    </Flex>
                    <Flex container item direction={'column'}>
                        <MobileTaskHeader
                            {...{
                                task,
                                comments,
                                attachments,
                                checklist,
                                actions,
                                url: routeProps.match.url,
                                applicationOnline,
                                onSuccessfulFileUpload,
                                onSuccessfulFileDelete,
                                fetchTask: fetchStateTask,
                                removeUnseenActionTag,
                                removeUnseenCommentTag,
                                removeUnseenChecklistTag,
                                removeUnseenAttachmentTag
                            }}
                        />
                        <Flex item container direction={'column'} onClick={this.handleReadOnlyDialogOpen}
                              className={classes.storyDetailsContainer}>

                            <Title {...{
                                classes,
                                isTaskEditable,
                                task,
                                isSelected,
                                matchingTranslation,
                                handleTitleChange
                            }}/>

                            <RelatedTo {...{
                                classes,
                                task,
                                isTaskEditable,
                                tagSuggestions: freeTags,
                                handleTagAddition,
                                handleTagDelete,
                                translate
                            }}/>

                            <MobileDescription {...{
                                isEditable: isTaskEditable,
                                matchingTranslation,
                                handleDescriptionChange,
                                businessId: taskBusinessId || newTaskRefId,
                                referenceType: 'TASK',
                                borderColor: getColorbyPriority(task.priority.type),
                                language
                            }}/>

                            <ResponsibleLine {...{
                                classes,
                                isTaskEditable,
                                task,
                                handleResponsibleChange,
                                users: selectedProject.users
                            }}/>

                            <Flex item grow={0} container>
                                <Severity {...{
                                    classes,
                                    isTaskEditable,
                                    task,
                                    handlePriorityChange,
                                    deviationSeverities,
                                    taskSeverities
                                }}/>
                                <SolveUntil {...{classes, isTaskEditable, task, handleDueDateChange}}/>
                            </Flex>

                            <Flex item grow={0} container>
                                <WorkflowStatus {...{
                                    classes,
                                    isTaskEditable,
                                    task,
                                    handleChangeOnTask,
                                    statuses
                                }}/>
                                <FinishedAt {...{classes, task}}/>
                            </Flex>

                        </Flex>

                    </Flex>
                </Flex>
                <FooterButtons {...{
                    classes,
                    save,
                    isTaskEditable,
                    isSelected,
                    routeProps,
                    isTranslationChanged,
                    leaveEdit,
                    saveWithOptions,
                    deleteTask,
                    cancelDelete,
                    isSaveDisabled
                }}/>
            </Fragment>
        );
    }
}

export default withStyles(styles)(withTranslation()(MobileTaskEdit));
