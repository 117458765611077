import _ from 'lodash';

const MACHINE_PREFIX = "Machine-";

export function translateLabel(translate, label) {
    if (!_.isEmpty(label)) {
        return translateMachine(translate, label)
    } else {
        return label;
    }
}

export function translateMachine(translate, label) {
    if (label.startsWith(MACHINE_PREFIX)) {
        const ordinal = label.split('-')[1];
        return translate('kpi-management.document-machine-status.machine', {ordinal: ordinal})
    } else {
        return label;
    }
}