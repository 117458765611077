import * as actionTypes from './TaskReporting.action.types';
import _ from 'lodash';
import moment from 'moment';
import utils from 'utils/Utils';

const initialState = {
    report: []
};

export default function (state = initialState, action) {
    switch (action.type) {
        case actionTypes.FETCH_DAILY_TASK_STATUS_REPORT_SUCCESS: {
            const newState = _.cloneDeep(state);
            newState.report = mapReport(action.payload.data.data.listDailyTaskStatusReport);
            return newState;
        }
        default:
            return state;
    }
}

function mapReport(reportFromServer) {
    return reportFromServer.map(report => {
        const statusCounts = report.counts.map(count => {
            return {
                [count.status.label.toLowerCase()]: count.count
            }
        });
        return Object.assign({}, {
                isToday: moment(report.date).isSame(moment(), 'day'),
                date: moment(report.date).format(utils.DATE_FORMAT)
            },
            ...statusCounts
        )
    })
}
