import {
    addActionComment,
    addTaskComment,
    deleteActionComment,
    deleteTaskComment
} from './Comment.action'

export default {
    mapStateToProps: (state) => {
        return {
            user: state.mainReducer.user,
        };
    },
    mapDispatchToProps: (dispatch) => {
        return {
            addTaskComment: (taskId, commentId) => dispatch(addTaskComment(taskId, commentId)),
            deleteTaskComment: commentId => dispatch(deleteTaskComment(commentId)),
            addActionComment: (actionId, commentId) => dispatch(addActionComment(actionId, commentId)),
            deleteActionComment: commentId => dispatch(deleteActionComment(commentId)),
        };
    }
};
