import {
    assignProjectMember,
    updateProjectMembership,
    removeProjectMember,
    listProjects
} from "scenes/tasks/Project.action";

import {listProjectTasks} from 'scenes/tasks/Tasks.action';

export default {
    mapStateToProps: (state) => {
        return {
            selectedProject: state.projectReducer.selectedProject,
            allUsers: state.tasksReducer.configuration.users,
            loggedInUser: state.mainReducer.user,
            tasks: state.tasksReducer.activeTasks.concat(state.tasksReducer.backlogTasks),
        };
    },
    mapDispatchToProps: (dispatch) => {
        return {
            assignProjectMember: (projectBusinessId, userId, membership) => dispatch(assignProjectMember(projectBusinessId, userId, membership)),
            updateProjectMembership: (projectBusinessId, userId, membership) => dispatch(updateProjectMembership(projectBusinessId, userId, membership)),
            removeProjectMember: (projectBusinessId, userId, substituteUserId) => dispatch(removeProjectMember(projectBusinessId, userId, substituteUserId)),
            listProjects: () => dispatch(listProjects()),
            listAllOnlineTasks: () => dispatch(listProjectTasks()),
        };
    }
};
