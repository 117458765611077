export const FETCH_QUESTIONNAIRES = '@questionnaires/FETCH_QUESTIONNAIRES';
export const FETCH_QUESTIONNAIRES_SUCCESS = '@questionnaires/FETCH_QUESTIONNAIRES_SUCCESS';
export const FETCH_QUESTIONNAIRES_FAIL = '@questionnaires/FETCH_QUESTIONNAIRES_FAIL';

export const DELETE_QUESTIONNAIRE = '@questionnaires/DELETE_QUESTIONNAIRE';
export const DELETE_QUESTIONNAIRE_SUCCESS = '@questionnaires/DELETE_QUESTIONNAIRE_SUCCESS';
export const DELETE_QUESTIONNAIRE_FAIL = '@questionnaires/DELETE_QUESTIONNAIRE_FAIL';

export const UPDATE_QUESTIONNAIRE = '@questionnaires/UPDATE_QUESTIONNAIRE';
export const UPDATE_QUESTIONNAIRE_SUCCESS = '@questionnaires/UPDATE_QUESTIONNAIRE_SUCCESS';
export const UPDATE_QUESTIONNAIRE_FAIL = '@questionnaires/UPDATE_QUESTIONNAIRE_FAIL';

export const CLONE_QUESTIONNAIRE = '@questionnaires/CREATE_QUESTIONNAIRE';
export const CLONE_QUESTIONNAIRE_SUCCESS = '@questionnaires/CREATE_QUESTIONNAIRE_SUCCESS';
export const CLONE_QUESTIONNAIRE_FAIL = '@questionnaires/CREATE_QUESTIONNAIRE_FAIL';