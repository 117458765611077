export const FETCH_UPLOAD_URL = '@attachment/FETCH_UPLOAD_URL';
export const FETCH_UPLOAD_URL_SUCCESS = '@attachment/FETCH_UPLOAD_URL_SUCCESS';
export const FETCH_UPLOAD_URL_FAIL = '@attachment/FETCH_UPLOAD_URL_FAIL';
export const UPLOAD_FILE = '@attachment/UPLOAD_FILE';
export const UPLOAD_FILE_SUCCESS = '@attachment/UPLOAD_FILE_SUCCESS';
export const UPLOAD_FILE_FAIL = '@attachment/UPLOAD_FILE_FAIL';
export const UPLOAD_FILE_TO_CACHE = '@attachment/UPLOAD_FILE_TO_CACHE';
export const DELETE_FILE = '@attachment/DELETE_FILE';
export const DELETE_FILE_SUCCESS = '@attachment/DELETE_FILE_SUCCESS';
export const DELETE_FILE_FAIL = '@attachment/DELETE_FILE_FAIL';
