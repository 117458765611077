import React, {Component, Fragment} from "react";
import _ from "lodash";
import {Button, Checkbox, Dialog, DialogActions, DialogTitle, IconButton, withStyles} from "@material-ui/core";
import {Trans, withTranslation} from "react-i18next";
import DialogContent from "@material-ui/core/DialogContent";
import FormControl from "@material-ui/core/FormControl";
import Typography from "@material-ui/core/Typography";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import {christiGreen, silver, normal, guardsmanRed, superNovaYellow, dustyGrey} from 'components/colors/Colors';
import Flex from "components/grid/Flex";
import {BootstrapInput} from "components/bootstrap-input/BootstrapInput";
import {Delete as DeleteIcon} from "@material-ui/icons";
import Switch from "@material-ui/core/Switch";
import classNames from 'classnames';
import DocumentMachineStatusResponsible from "./DocumentMachineStatusResponsible";
import moment from 'moment';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import update from 'immutability-helper';

export const styles = theme => ({
    dialogContainer: {
        minHeight: 500,
        '@media (min-width: 1900px)': {
            width: "60%",
        },
        width: '90%',
        height: '70%',
        padding: 24
    },
    dialogTitleRoot: {
        padding: "0px 0px 20px",
        marginRight: 4
    },
    dialogContentRoot: {
        padding: "0px 0px 0px"
    },
    dialogActionsRoot: {
        padding: "0px 0px 0px",
        margin: '0px 0px'
    },
    formControl: {},
    radioGroup: {
        padding: "0px 0px 0px",
    },
    radio: {
        margin: "0px",
        paddingTop: 2,
        paddingBottom: 2
    },
    radioLabel: {
        paddingTop: 2,
        paddingBottom: 2
    },
    statusLabel: {
        marginTop: 10,
        marginBottom: 10
    },
    reportLabel: {
        marginBottom: 10
    },
    mainStatusRadioGroup: {
        display: "inline-block"
    },
    workingRadioChecked: {},
    workingRadioRoot: {
        color: christiGreen,
        '&$workingRadioChecked': {
            color: christiGreen,
        },
    },
    notWorkingRadioChecked: {},
    notWorkingRadioRoot: {
        color: guardsmanRed,
        '&$notWorkingRadioChecked': {
            color: guardsmanRed,
        },
    },
    unknownRadioChecked: {},
    unknownRadioRoot: {
        color: silver,
        '&$unknownRadioChecked': {
            color: silver,
        },
    },
    severityLabel: {
        fontWeight: 'bold',
        fontStyle: 'italic',
        marginLeft: '10px',
        fontSize: '15px'
    },
    idLabel: {
        marginTop: 0,
        fontStyle: 'italic',
        fontSize: '15px',
        display: "block"
    },
    reportedDateLabel: {
        marginLeft: 15,
        fontSize: '13px',
        display: "block",
        color: dustyGrey,
    },
    workingLabel: {
        display: "inline-block"
    },
    notWorkingLabel: {
        display: "inline-block"
    },
    colorSwitchBase: {
        height: 'unset',
        color: christiGreen,
        '&$colorChecked': {
            color: guardsmanRed,
            '& + $colorBar': {
                backgroundColor: guardsmanRed,
            },
        },
    },
    colorBar: {},
    colorChecked: {},
    flag: {
        width: 5,
        height: 40,
        display: 'inline-block',
        marginRight: 10,
    },
    taskFlag: {
        backgroundColor: guardsmanRed,
        border: '1px solid ' + guardsmanRed,

    },
    actionFlag: {
        backgroundColor: normal,
        border: '1px solid ' + normal,
    },
    actionPanel: {
        height: 100,
        paddingTop: 10
    },
    actionContainer: {
        paddingTop: 10
    },
    actionLabel: {
        marginTop: 10,
    },
    addActionLabel: {
        color: dustyGrey,
        '&:hover': {
            cursor: 'pointer'
        }
    },
    contentContainer: {
        display: 'flex',
        flex: '1 1 auto',
        justifyContent: 'space-between',
        flexWrap: 'wrap',
        width: '100%'
    },
    leftColumn: {
        display: 'flex',
        flex: '1 1 auto',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        width: '100%',
        paddingBottom: 30,
        '@media (min-width: 1000px)': {
            width: '40%',
            paddingBottom: 0
        },
    },
    rightColumn: {
        display: 'flex',
        flex: '1 1 auto',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        '@media (min-width: 1000px)': {
            alignItems: 'flex-end',
        },
        width: '60%'
    },
    inputBox: {
        border: `2px solid ${silver}`,
        width: 340,
        marginLeft: 5,
        marginRight: 5,
        paddingLeft: 5
    },
    closeTasksLabel: {
        paddingTop: 10
    },
    checkBoxRoot: {
        padding: '8px 0px 0px 12px'
    },
});

const TitleRow = (props) => {
    const {classes, machine, getSeverityColor} = props;
    return (
        <Fragment>
            <span style={{fontWeight: 'bold'}}>
                <Trans i18nKey={"kpi-management.document-machine-status.machine"} values={{ordinal: machine.ordinal}}/>
            </span>
            <span style={{color: getSeverityColor(machine.severity.type)}}
                  className={classes.severityLabel}>
                  <Trans i18nKey={"kpi-management.severity." + machine.severity.type}/>
            </span>
            <span className={classes.idLabel}>
                {machine.businessId}
            </span>
        </Fragment>
    )
};

const LeftContentColumn = (props) => {
    const {
        classes, originalMainStatus, status, machine, mainStatus, statuses,
        mainStatusChecked, handleMandatoryStatus, handleMainStatusChange, handleStatusChange,
        closeTasks, handleCloseTasksChange, closeTasksVisible
    } = props;
    const statusNotWorking = status !== 'WORKING' && status !== 'UNKNOWN';

    return (
        <div className={classes.leftColumn}>
            <FormControl component="fieldset" className={classes.formControl}>
                <div>

                    {
                        originalMainStatus !== 'UNKNOWN' &&
                        <Flex item={'0 0 0'} container alignItems={'center'}>
                            <Typography classes={{root: classes.workingLabel}}>
                                <Trans i18nKey={"kpi-management.document-machine-status.working"}/>
                            </Typography>
                            <Switch
                                checked={mainStatusChecked}
                                onChange={handleMandatoryStatus}
                                classes={{
                                    switchBase: classes.colorSwitchBase,
                                    checked: classes.colorChecked,
                                    bar: classes.colorBar,
                                }}/>
                            <Typography classes={{root: classes.notWorkingLabel}}>
                                <Trans
                                    i18nKey={"kpi-management.document-machine-status.notWorking"}/>
                            </Typography>
                            {
                                machine.reportedDate &&
                                <span className={classes.reportedDateLabel}>
                                    (
                                    <Trans i18nKey={"kpi-management.document-machine-status.reportedAt"}/>:
                                    <span style={{marginLeft: 5}}>{moment(machine.reportedDate).format("HH:mm")}</span>
                                    )
                                </span>
                            }

                        </Flex>
                    }
                    {
                        originalMainStatus === 'UNKNOWN' &&
                        <Fragment>
                            <RadioGroup
                                value={mainStatus}
                                className={classes.mainStatusRadioGroup}
                                onChange={handleMainStatusChange}
                            >
                                <FormControlLabel value={"WORKING"} control={
                                    <Radio
                                        classes={{
                                            root: classes.workingRadioRoot,
                                            checked: classes.workingRadioChecked,
                                        }}/>}
                                                  label={<Typography><Trans
                                                      i18nKey={"kpi-management.document-machine-status.working"}/>
                                                  </Typography>}

                                />
                                <FormControlLabel value={"UNKNOWN"} control={
                                    <Radio
                                        classes={{
                                            root: classes.unknownRadioRoot,
                                            checked: classes.unknownRadioChecked,
                                        }}/>}
                                                  label={<Typography><Trans
                                                      i18nKey={"kpi-management.document-machine-status.unknown"}/>
                                                  </Typography>}

                                />
                                <FormControlLabel value={"NOT_WORKING"} control={
                                    <Radio
                                        classes={{
                                            root: classes.notWorkingRadioRoot,
                                            checked: classes.notWorkingRadioChecked,
                                        }}/>}
                                                  label={<Typography><Trans
                                                      i18nKey={"kpi-management.document-machine-status.notWorking"}/>
                                                  </Typography>}

                                />
                            </RadioGroup>

                        </Fragment>
                    }
                    {
                        status === 'WORKING' &&
                        <Fragment>
                            {
                                closeTasksVisible &&
                                <Fragment>
                                    <Flex item container justifyContent={"right"} style={{
                                        paddingTop: 10
                                    }}>
                                        <Flex item justifyContent={"right"} grow={0}>
                                            <Typography className={classes.closeTasksLabel}><Trans
                                                i18nKey={"kpi-management.document-machine-status.closeTasks"}/></Typography>
                                        </Flex>
                                        <Flex item justifyContent={"right"} grow={0}>
                                            < Checkbox
                                                color={"primary"}
                                                checked={closeTasks}
                                                onChange={handleCloseTasksChange}
                                                classes={{root: classes.checkBoxRoot}}
                                                icon={<CheckBoxOutlineBlankIcon style={{fontSize: 25}}/>}
                                                checkedIcon={<CheckBoxIcon style={{fontSize: 25}}/>}
                                            />
                                        </Flex>
                                    </Flex>
                                </Fragment>
                            }
                        </Fragment>
                    }

                </div>
                {
                    statusNotWorking &&
                    <Fragment>
                        <Typography classes={{root: classes.statusLabel}}><Trans
                            i18nKey={"kpi-management.document-machine-status.status"}/></Typography>
                        <RadioGroup
                            className={classes.radioGroup}
                            value={status}
                            onChange={handleStatusChange}
                        >
                            {
                                statuses.map(status => {
                                    return (
                                        <FormControlLabel key={status} value={status} control={
                                            <Radio
                                                color={"default"}
                                                className={classes.radio}/>
                                        }
                                                          label={<Typography><Trans
                                                              i18nKey={"kpi-management.status." + status}/>
                                                          </Typography>}
                                                          className={classes.radioLabel}/>)
                                })

                            }
                        </RadioGroup>
                    </Fragment>
                }
            </FormControl>
        </div>
    )
};

const RightContentColumn = (props) => {
    const {
        classes, users, handleResponsibleForTaskChange, handleReportNameChange,
        status, problemTitle, reportedActions, selectedResponsibleForTask, handleAddActionClick,
        handleRemoveActionClick, handleActionTitleChange, handleActionResponsibleChange, eraseProblemTitle
    } = props;
    const statusNotWorking = status !== 'WORKING' && status !== 'UNKNOWN';

    return (
        <div className={classes.rightColumn}>
            {
                statusNotWorking &&
                <Fragment>

                    <Flex item container grow={0} direction={'column'} alignItems={'flex-start'}>
                        <Typography classes={{root: classes.reportLabel}}>
                            <Trans i18nKey={"kpi-management.document-machine-status.reportProblem"}/>
                        </Typography>
                        <Flex item container grow={0} alignItems={'center'}>
                            <div className={classNames(classes.flag, classes.taskFlag)}/>
                            <DocumentMachineStatusResponsible
                                data={{
                                    users,
                                    responsible: selectedResponsibleForTask,
                                }}
                                actions={{
                                    handleResponsibleChange: handleResponsibleForTaskChange
                                }}
                            />
                            <BootstrapInput className={classes.inputBox}
                                            value={problemTitle}
                                            name={'problemTitle'}
                                            onChange={handleReportNameChange}
                            />
                            <IconButton onClick={eraseProblemTitle}>
                                <DeleteIcon style={{color: guardsmanRed}}/>
                            </IconButton>
                        </Flex>
                        {
                            reportedActions.length > 0 &&
                            <Typography className={classes.actionLabel} style={{width: '100%'}}>
                                <Trans i18nKey={"kpi-management.document-machine-status.assignAction"}/>
                            </Typography>
                        }
                        {
                            reportedActions.map((action, index) => {
                                return (
                                    <Flex key={index} item container grow={0} alignItems={'center'}
                                          className={classes.actionContainer}>
                                        <div className={classNames(classes.flag, classes.actionFlag)}/>
                                        <DocumentMachineStatusResponsible
                                            data={{
                                                users,
                                                responsible: action.responsible,
                                            }}
                                            actions={{
                                                handleResponsibleChange: handleActionResponsibleChange(index)
                                            }}
                                        />
                                        <BootstrapInput
                                            className={classes.inputBox}
                                            value={action.title}
                                            onChange={handleActionTitleChange(index)}
                                        />
                                        <IconButton onClick={handleRemoveActionClick(index)}>
                                            <DeleteIcon style={{color: guardsmanRed}}/>
                                        </IconButton>
                                    </Flex>
                                );
                            })
                        }
                        <Typography className={classNames(classes.actionLabel, classes.addActionLabel)}
                                    onClick={handleAddActionClick}>
                            + <Trans i18nKey={"kpi-management.document-machine-status.addAction"}/>
                        </Typography>
                    </Flex>
                </Fragment>
            }
            <Flex item className={classes.actionPanel}>

            </Flex>
        </div>
    )
};

class DocumentMachineStatusPopup extends Component {

    constructor(props) {
        super(props);
        const mainStatus = props.machine.status !== 'WORKING' && props.machine.status !== 'UNKNOWN' ? 'NOT_WORKING' : props.machine.status;
        const owner = props.users.find(user => user.businessId === props.machine.ownerId) || {};
        this.state = {
            originalStatusUnknown: props.machine.status === 'UNKNOWN',
            originalMainStatus: mainStatus,
            mainStatus: mainStatus,
            mainStatusChecked: mainStatus === 'NOT_WORKING',
            status: props.machine.status,
            problemTitle: "",
            problemReported: false,
            reportedActions: [],
            selectedResponsibleForAction: owner,
            selectedResponsibleForTask: owner,
            closeTasks: false,
            closeTasksVisible: false
        };
    }

    getSeverityColor = (severity) => {
        switch (severity) {
            case 'HIGH':
                return guardsmanRed;
            case 'MEDIUM':
                return superNovaYellow;
            default:
                return 'primary';
        }
    };

    handleMainStatusChange = event => {
        this.setStatusChange(event.target.value);
    };

    setStatusChange = (value) => {
        if (value === "WORKING" || value === "UNKNOWN") {
            const owner = this.props.users.find(user => user.businessId === this.props.machine.ownerId) || {};
            this.setState({
                status: value,
                problemTitle: "",
                reportedActions: [],
                selectedResponsibleForAction: owner,
                selectedResponsibleForTask: owner

            });
        } else {
            this.setState({status: null});
        }
        this.setState({mainStatus: value, mainStatusChecked: value === "NOT_WORKING"});
    };

    handleStatusChange = event => {
        this.setState({status: event.target.value});
    };

    handleAddActionClick = () => {
        const reportedActions = this.state.reportedActions;
        reportedActions.push({
            title: "",
            responsible: this.state.selectedResponsibleForAction
        });
        this.setState({reportedActions: reportedActions});
    };

    handleReportNameChange = (event) => {
        this.setState({problemTitle: event.target.value});
    };

    eraseProblemTitle = () => {
        this.setState({problemTitle: ""});
    };

    handleRemoveActionClick = (index) => () => {
        const reportedActions = this.state.reportedActions;
        const updatedList = reportedActions
            .slice(0, index)
            .concat(reportedActions
                .slice(index + 1, reportedActions.length));
        this.setState({reportedActions: updatedList});

    };

    handleMandatoryStatus = () => {
        if (this.state.mainStatus === 'WORKING') {
            this.setState({mainStatusChecked: true});
            this.setStatusChange("NOT_WORKING");
        } else {
            this.setState({mainStatusChecked: false});
            this.setStatusChange("WORKING");
        }
    };

    saveButtonEnabled = () => {
        const statusIsGiven = !_.isEmpty(this.state.status);
        const statusIsNotUnknown = this.state.status !== 'UNKNOWN';
        const statusIsWorking = this.state.status === 'WORKING';
        const statusIsNotWorking = this.state.mainStatus === 'NOT_WORKING';
        const actionsWithoutResponsible = this.state.reportedActions.find(action => !_.isEmpty(action.title) && _.isEmpty(action.responsible));

        const taskWithoutResponsible = !_.isEmpty(this.state.problemTitle) && _.isEmpty(this.state.selectedResponsibleForTask);
        if (taskWithoutResponsible || !_.isEmpty(actionsWithoutResponsible)) {
            return false
        } else {
            return statusIsGiven && (statusIsNotUnknown && (statusIsWorking || (statusIsNotWorking)));
        }
    };


    handleResponsibleForTaskChange = user => {
        this.setState({
            selectedResponsibleForTask: user
        });
    };

    handleCloseTasksChange = () => {
        if (this.state.closeTasks) {
            this.setState({
                closeTasks: false
            });
        } else {
            this.setState({
                closeTasks: true
            });
        }
    };

    handleActionTitleChange = index => event => {
        const targetValue = event.target.value;
        this.setState(oldState => {
            return {
                reportedActions: update(oldState.reportedActions, {
                    [index]: {
                        title: {$set: targetValue}
                    }
                })
            }
        });
    };

    handleActionResponsibleChange = index => event => {
        const targetUser = event;
        this.setState(oldState => {
            return {
                reportedActions: update(oldState.reportedActions, {
                    [index]: {
                        responsible: {$set: targetUser}
                    }
                })
            }
        });
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevState.originalMainStatus === "NOT_WORKING" && this.state.mainStatus === 'WORKING') {
            if (!this.state.closeTasksVisible) {
                this.setState({
                    closeTasksVisible: true
                });
            }
        }
    }

    render() {
        const {dialogOpen, onDialogClose, machine, classes, statuses, users, t: translate} = this.props;
        const {
            getSeverityColor, handleResponsibleForTaskChange, handleMandatoryStatus,
            handleMainStatusChange, handleStatusChange, handleReportNameChange,
            handleAddActionClick, handleRemoveActionClick,
            handleCloseTasksChange, handleActionTitleChange, handleActionResponsibleChange, eraseProblemTitle
        } = this;

        const saveButtonEnabled = this.saveButtonEnabled();

        return (
            <Dialog
                open={dialogOpen}
                classes={{paper: classes.dialogContainer}}
                fullWidth
                fullScreen
            >
                <DialogTitle classes={{root: classes.dialogTitleRoot}}>
                    <TitleRow {...{classes, machine, getSeverityColor, t: translate}}/>
                </DialogTitle>
                <DialogContent classes={{root: classes.dialogContentRoot}}>
                    <div className={classes.contentContainer}>
                        <LeftContentColumn {...{
                            classes, statuses, machine, t: translate,
                            handleMandatoryStatus, handleMainStatusChange, handleStatusChange, handleCloseTasksChange,
                            status: this.state.status,
                            mainStatus: this.state.mainStatus,
                            mainStatusChecked: this.state.mainStatusChecked,
                            originalMainStatus: this.state.originalMainStatus,
                            closeTasks: this.state.closeTasks,
                            closeTasksVisible: this.state.closeTasksVisible
                        }}/>
                        <RightContentColumn {...{
                            classes, users,
                            handleResponsibleForTaskChange, handleReportNameChange, handleAddActionClick,
                            handleRemoveActionClick, handleActionTitleChange, handleActionResponsibleChange,
                            eraseProblemTitle,
                            status: this.state.status,
                            problemTitle: this.state.problemTitle,
                            reportedActions: this.state.reportedActions,
                            originalMainStatus: this.state.originalMainStatus,
                            selectedResponsibleForAction: this.state.selectedResponsibleForAction,
                            selectedResponsibleForTask: this.state.selectedResponsibleForTask
                        }}/>
                    </div>
                </DialogContent>
                <DialogActions classes={{root: classes.dialogActionsRoot}}>
                    <Button onClick={() => onDialogClose()}
                            color="primary"
                            variant="contained">
                        <Trans i18nKey={"global.cancel"}/>
                    </Button>
                    <Button onClick={() => onDialogClose(
                        {
                            machineBusinessId: machine.businessId,
                            status: this.state.status,
                            title: this.state.problemTitle,
                            responsible: this.state.selectedResponsibleForTask,
                            actions: this.state.reportedActions,
                            closeTasks: this.state.closeTasks,
                            language: this.props.i18n.language
                        })}
                            color="primary"
                            variant="contained"
                            autoFocus
                            disabled={!saveButtonEnabled}>
                        <Trans i18nKey={"global.save"}/>
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

export default withStyles(styles)(withTranslation()(DocumentMachineStatusPopup));
