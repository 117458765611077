import React, {Component} from 'react';
import {
    Button,
    FormControl,
    IconButton,
    InputAdornment,
    Typography,
    withStyles,
} from "@material-ui/core";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Visibility from '@material-ui/icons/Visibility';
import BootstrapTextField from 'components/bootstrap-textfield/BootstrapTextField';
import {Trans, withTranslation} from 'react-i18next';
import {criticalTextField, htmlRed} from 'components/colors/Colors';
import ids from 'components/ids/ids';

const styles = theme => ({
    resetButtonRoot: {
        textTransform: 'none',
        paddingLeft: 0,
        marginTop: 10
    },
    submit: {
        marginTop: 20
    },
    loginLabels: {
        fontSize: 12,
        fontWeight: 'bold',
        paddingBottom: 3
    },
    forgotText: {
        fontSize: 10
    },
    helperTextError: {
        color: htmlRed
    },
    redHighlight: {
        backgroundColor: criticalTextField
    }
});

export class LoginPage extends Component {
    constructor(props) {
        super(props);
        this.passwordInputRef = React.createRef();
    }

    state = {
        isPasswordVisible: false,
    };

    togglePasswordVisibility = () => {
        this.setState(state => ({isPasswordVisible: !state.isPasswordVisible}));
    };

    onEnter = event => {
        if (event.key === 'Enter') {
            event.preventDefault();
            this.passwordInputRef.current.focus();
        }
    };

    render() {
        const {classes, data, actions, className, style} = this.props;
        const {isPasswordVisible} = this.state;

        return (
            <div className={className} style={style}>
                <form>
                    <FormControl margin="normal" required fullWidth>
                        <Typography className={classes.loginLabels}>
                            <Trans i18nKey={'login.username'}/>
                        </Typography>
                        <BootstrapTextField
                            id={ids.loginUsername}
                            autoFocus
                            inputProps={{
                                autoCapitalize: 'off'
                            }}
                            variant="outlined"
                            type="text"
                            classes={{
                                root: data.warning ? classes.redHighlight : ''
                            }}
                            value={data.userName}
                            onKeyPress={this.onEnter}
                            onChange={actions.changeState('userName')}
                        />
                    </FormControl>
                    <FormControl margin="normal" required fullWidth>
                        <Typography className={classes.loginLabels}>
                            <Trans i18nKey={'login.password'}/>
                        </Typography>
                        <BootstrapTextField
                            id={ids.loginPassword}
                            variant="outlined"
                            inputRef={this.passwordInputRef}
                            helperText={data.warning}
                            type={isPasswordVisible ? 'text' : 'password'}
                            value={data.password}
                            onChange={actions.changeState('password')}
                            FormHelperTextProps={{
                                classes: {root: classes.helperTextError}
                            }}
                            classes={{
                                root: data.warning ? classes.redHighlight : ''
                            }}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="Toggle password visibility"
                                            onClick={this.togglePasswordVisibility}
                                        >
                                            {data.isPasswordVisible ? <VisibilityOff/> : <Visibility/>}
                                        </IconButton>
                                    </InputAdornment>)
                            }}
                        />
                    </FormControl>
                    <Button
                        id={ids.loginButton}
                        fullWidth
                        variant="contained"
                        color="primary"
                        type="submit"
                        disabled={!data.userName || !data.password}
                        className={classes.submit}
                        onClick={actions.handleSubmit}
                    >
                        <Trans i18nKey={'login.login-button'}/>
                    </Button>
                </form>
            </div>
        );
    }
}

export default withStyles(styles)(withTranslation()(LoginPage));
