import React, {Component} from 'react';
import {
    withStyles,
} from "@material-ui/core";
import Flex from 'components/grid/Flex';
import RegularQuestionExecutor from './RegularQuestionExecutor';
import RegularQuestionHeader from './RegularQuestionHeader';

const styles = theme => ({
    paper: {
        paddingTop: 0,
        marginBottom: 10,
        height: '100%',
        borderRadius: 4,
    }
});

export class RegularAuditExecutor extends Component {
    render() {
        const {
            classes, previousQuestion, nextQuestion, currentQuestionNumber, areQuestionsAnswered, showTooltip,
            questions, numberOfNegativeAnswers, readOnly, backgroundColor, textColor, currentQuestion, handleAnswer,
            handleComment, handleSeverity, handleFile, openGallery
        } = this.props;

        return (
            <Flex container item={'1 0 auto'} column className={classes.paper}>
                <RegularQuestionHeader {...{
                    currentQuestion,
                    counter: `${currentQuestionNumber + 1}`,
                    previousQuestion,
                    nextQuestion,
                    isFirstQuestion: currentQuestionNumber === 0,
                    isLastQuestion: currentQuestionNumber === (questions.length - 1),
                    areQuestionsAnswered,
                    showTooltip,
                    numberOfQuestions: questions.length,
                    numberOfNegativeAnswers,
                    readOnly,
                    backgroundColor,
                    textColor
                }}/>
                <RegularQuestionExecutor {...{
                    currentQuestion,
                    handleAnswer,
                    readOnly,
                    counter: `${currentQuestionNumber + 1} / ${questions.length}`,
                    handleComment,
                    handleSeverity,
                    handleFile,
                    openGallery,
                    backgroundColor,
                    textColor,
                }}/>
            </Flex>
        );
    }
}

export default withStyles(styles)(RegularAuditExecutor);
