import {taskDatabase} from 'scenes/tasks/Tasks.database';
import {syncTask} from 'scenes/tasks/task-edit/TaskEdit.action';
import {synchronizeTaskTagAssignment, synchronizeTaskTagAssignmentRemoval} from 'scenes/tasks/Tags.action';
import {synchronizeProject} from 'scenes/tasks/Project.action';
import {kpiMonitoringDatabase} from 'scenes/kpi-monitoring/KpiMonitoring.database';
import {synchronizeMachineStatus} from 'scenes/kpi-monitoring/document-machine-status/DocumentMachineStatus.actions';
import {fileDatabase} from 'components/file/File.database';
import {auditDatabase} from 'scenes/audit/Audit.database';
import {uploadFileRecord} from 'components/attachment/Attachment.action'
import {syncScheduledAudit} from 'scenes/audit/Audit.action'

function logError(error) {
    console.log('Cannot delete cache from local database', error);
}

export function synchronizeData() {
    return dispatch => {
        return Promise.all([
            dispatch(synchronizeFiles()),
            dispatch(synchronizeMachineDetails()),
            dispatch(synchronizeProjects()),
            dispatch(synchronizeTagAssignmentsForOnlineTasks()),
            dispatch(synchronizeTagAssignmentRemovalsForOnlineTasks()),
        ])
        .then(() => Promise.all([
            dispatch(synchronizeAudits()),
            dispatch(synchronizeTasks()),
        ]));
    }
}

function synchronizeFiles() {
    return dispatch => {
        return fileDatabase.files
            .toCollection()
            .toArray()
            .then(files => {
                return awaitAll(files, synchronizeFile, dispatch);
            })
    }
}

function synchronizeFile(fileRecord, dispatch) {
    return fileDatabase.files.where('id').equals(fileRecord.id)
        .delete()
        .then(() => {
            return dispatch(uploadFileRecord(fileRecord));
        })
        .catch(function (error) {
            logError(error);
        });
}

function synchronizeAudits() {
    return dispatch => {
        return auditDatabase.executedPersonalAudits
            .toCollection()
            .toArray()
            .then(executedAudits => {
                return awaitAll(executedAudits, synchronizeAudit, dispatch);
            });
    }
}

function synchronizeAudit(executedAudit, dispatch) {
    return auditDatabase.executedPersonalAudits.where('id').equals(executedAudit.id)
        .delete()
        .then(() => {
            return dispatch(syncScheduledAudit(executedAudit.input))
        })
        .catch(function (error) {
            logError(error);
        });
}

function synchronizeTasks() {
    return dispatch => {
        return taskDatabase.editedTasks
            .toCollection()
            .toArray()
            .then(editedTasks => {
                return awaitAll(editedTasks, synchronizeTask, dispatch);
            });
    }
}

function synchronizeTask(editedTask, dispatch) {
    return taskDatabase.editedTasks.where('businessId').equals(editedTask.businessId)
        .delete()
        .then(() => {
            if (editedTask.businessId.startsWith('OFFLINE')) {
                return dispatch(syncTask(editedTask))
                    .then(syncedBusinessId => {
                        return Promise.all([
                            dispatch(synchronizeTagAssignmentForOfflineTasks(editedTask.businessId, syncedBusinessId)),
                            dispatch(synchronizeTagAssignmentRemovalForOfflineTasks(editedTask.businessId, syncedBusinessId))
                        ]).then(() => {
                            return syncedBusinessId;
                        });
                    });
            } else {
                return dispatch(syncTask(editedTask));
            }
        })
        .catch(function (error) {
            logError(error);
        });
}

function synchronizeMachineDetails() {
    return dispatch => {
        return kpiMonitoringDatabase.editedDetails
            .toCollection()
            .toArray()
            .then(editedMachineDetails => {
                return awaitAll(editedMachineDetails, synchronizeMachineDetail, dispatch);
            });
    }
}

function synchronizeMachineDetail(editedMachineDetail, dispatch) {
    return kpiMonitoringDatabase.editedDetails.where('machineId').equals(editedMachineDetail.machineId)
        .delete()
        .then(function () {
            return dispatch(synchronizeMachineStatus(editedMachineDetail))
        })
        .catch(function (error) {
            logError(error);
        });
}

function synchronizeProjects() {
    return dispatch => {
        return taskDatabase.editedProjects
            .toCollection()
            .toArray()
            .then(editedProjects => {
                return awaitAll(editedProjects, synchronizeProjectCall, dispatch);
            });
    }
}

function synchronizeProjectCall(editedProject, dispatch) {
    return taskDatabase.editedProjects.where('businessId').equals(editedProject.businessId)
        .delete()
        .then(function () {
            return dispatch(synchronizeProject(editedProject))
        })
        .catch(function (error) {
            logError(error);
        });
}

function synchronizeTagAssignmentsForOnlineTasks() {
    return dispatch => {
        return taskDatabase.editedTagAssignments
            .toCollection()
            .toArray()
            .then(editedTagAssignments => {
                return awaitAll(
                    editedTagAssignments.filter(editedAssignment => !editedAssignment.taskBusinessId.startsWith('OFFLINE')),
                    synchronizeTagAssignmentForOnlineTasks,
                    dispatch
                );
            });
    }
}

function synchronizeTagAssignmentForOnlineTasks(editedAssignment, dispatch) {
    return taskDatabase.editedTagAssignments.where('taskBusinessId').equals(editedAssignment.taskBusinessId)
        .delete()
        .then(function () {
            editedAssignment.fromSynchronization = true;
            return dispatch(synchronizeTaskTagAssignment(editedAssignment))
        })
        .catch(function (error) {
            logError(error);
        });
}

function synchronizeTagAssignmentForOfflineTasks(offlineId, syncedId) {
    return dispatch => {
        return taskDatabase.editedTagAssignments.where('taskBusinessId').equals(offlineId)
            .first(editedTagAssignment => {
                if (editedTagAssignment) {
                    return taskDatabase.removedTagAssignments.where('taskBusinessId').equals(editedTagAssignment.taskBusinessId)
                        .delete()
                        .then(() => {
                            editedTagAssignment.taskBusinessId = syncedId;
                            editedTagAssignment.fromSynchronization = true;
                            return dispatch(synchronizeTaskTagAssignment(editedTagAssignment));
                        })
                        .catch(error => {
                            logError(error);
                        });
                }
                return offlineId;
            })
            .catch(error => {
                logError(error);
            });
    }
}

function synchronizeTagAssignmentRemovalsForOnlineTasks() {
    return dispatch => {
        return taskDatabase.removedTagAssignments
            .toCollection()
            .toArray()
            .then(removedTagAssignments => {
                return awaitAll(
                    removedTagAssignments.filter(removedAssignment => !removedAssignment.taskBusinessId.startsWith('OFFLINE')),
                    synchronizeTagAssignmentRemovalForOnlineTask,
                    dispatch
                );
            });
    }
}

function synchronizeTagAssignmentRemovalForOnlineTask(removedAssignment, dispatch) {
    return taskDatabase.removedTagAssignments.where('taskBusinessId').equals(removedAssignment.taskBusinessId)
        .delete()
        .then(function () {
            removedAssignment.fromSynchronization = true;
            return dispatch(synchronizeTaskTagAssignmentRemoval(removedAssignment))
        })
        .catch(function (error) {
            logError(error);
        });
}

function synchronizeTagAssignmentRemovalForOfflineTasks(offlineId, syncedId) {
    return dispatch => {
        return taskDatabase.removedTagAssignments.where('taskBusinessId').equals(offlineId)
            .first(removedTagAssignment => {
                if (removedTagAssignment) {
                    return taskDatabase.removedTagAssignments.where('taskBusinessId').equals(removedTagAssignment.taskBusinessId)
                        .delete()
                        .then(function () {
                            removedTagAssignment.taskBusinessId = syncedId;
                            removedTagAssignment.fromSynchronization = true;
                            return dispatch(synchronizeTaskTagAssignmentRemoval(removedTagAssignment));
                        })
                        .catch(error => {
                            logError(error);
                        });
                }
            })
            .catch(error => {
                logError(error);
            });
    }
}

function awaitAll(array, actionFactory, dispatch) {
    const actionPromises = [];
    array.forEach(item => {
        actionPromises.push(actionFactory(item, dispatch))
    });
    return Promise.all(actionPromises);
}
