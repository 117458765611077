import * as actionTypes from "./Monitoring.action.types";
import {USER_GRAPHQL} from "domain/User.model";
import moment from "moment";
import utils from "utils/Utils";
import {ATTACHMENT_GRAPHQL} from "domain/Attachment.model";

export function fetchDeviations(auditId) {
    return {
        type: actionTypes.FETCH_DEVIATIONS,
        payload: {
            client: 'graphql',
            request: {
                method: 'post',
                data: {
                    query: `
                            query ($auditId: String!) {
                                listTasksForAudit(auditId: $auditId) { 	
                                        businessId
                                        sources {
                                            answerBusinessId
                                            questionBusinessId
                                        }
                                        translations {
                                            language {
                                                code
                                            }
                                            title
                                        }
                                        assignee {
                                           ${USER_GRAPHQL}
                                        }
                                        priority {
                                            type
                                        }
                                        actions {
                                            businessId
                                            assignee {
                                                ${USER_GRAPHQL}
                                            }
                                            translations {
                                                language {
                                                    code
                                                }
                                                title
                                            }
                                            progress
                                            creationDate
                                            dueDate
                                        }
                                        attachments {
                                            ${ATTACHMENT_GRAPHQL}
                                        }                                                                      
                                }  
                            }
                           `,
                    variables: {
                        auditId: auditId
                    }
                }
            }
        }
    };
}


export function updateAuditor(auditId, auditorId) {
    return (dispatch) => {
        return dispatch({
            type: actionTypes.UPDATE_AUDITOR,
            payload: {
                client: 'graphql',
                request: {
                    method: 'post',
                    data: {
                        query: `mutation assignAudit($auditId: String!, $auditorId: String!){
    	                        assignAudit(auditId: $auditId, auditorId: $auditorId) {   
    	                            businessId   	                            
    	                        }
                            }
                           `,
                        variables: {
                            auditId: auditId,
                            auditorId: auditorId
                        }
                    }
                }
            }
        });
    }
}

export function sendAuditReminder(auditId) {
    return (dispatch, getState) => {
        return dispatch({
            type: actionTypes.SEND_AUDIT_REMINDER,
            data: {
                supportedWeekdays: getState().generalConfigurationReducer.auditConfiguration.supportedWeekdays
            },
            payload: {
                client: 'graphql',
                request: {
                    method: 'post',
                    data: {
                        query: `mutation sendAuditReminder($auditId: String!){
    	                        sendAuditReminder(auditId: $auditId) {   
    	                            businessId
    	                            lastReminder {
    	                                sender {
                                                ${USER_GRAPHQL}
                                        }
                                        timestamp
    	                            }  	                            
    	                        }
                            }
                           `,
                        variables: {
                            auditId: auditId
                        }
                    }
                }
            }
        });
    }
}

export function cancelScheduledAudit(auditScheduleId, scheduleDate) {
    return (dispatch) => {
        return dispatch({
            type: actionTypes.CANCEL_AUDIT_SCHEDULE,
            payload: {
                client: 'graphql',
                request: {
                    method: 'post',
                    data: {
                        query: `mutation cancelScheduledAudit($auditScheduleId: String!, $scheduleDate: Date!){
    	                        cancelScheduledAudit(auditScheduleId: $auditScheduleId, scheduleDate: $scheduleDate) {   
    	                            businessId   	                            
    	                        }
                            }
                           `,
                        variables: {
                            auditScheduleId: auditScheduleId,
                            scheduleDate: moment(scheduleDate).format(utils.API_LOCAL_DATE_FORMAT)
                        }
                    }
                }
            }
        });
    }
}


export function fetchAudits(organizationLevelIds, fromDate, toDate) {
    const input = {
        organizationLevelIds,
        fromDate: moment(fromDate).format(utils.API_LOCAL_DATE_FORMAT),
        toDate: moment(toDate).format(utils.API_LOCAL_DATE_FORMAT)
    };
    return (dispatch, getState) => {
        return dispatch({
            type: actionTypes.FETCH_AUDITS,
            data: {
                supportedWeekdays: getState().generalConfigurationReducer.auditConfiguration.supportedWeekdays
            },
            payload: {
                client: 'graphql',
                request: {
                    method: 'post',
                    data: {
                        query: `
                            query ($filter: AuditMonitoringFilterInput!) {
                                listAuditsForMonitoring(filter: $filter) { 	
                                        businessId
                                        type
                                        cycle {
                                            label
                                        }
                                        durationType
                                        organizationLevel {
                                            businessId
                                            shortName
                                            members {
                                                user {
                                                    ${USER_GRAPHQL}
                                                }
                                                memberships
                                            }
                                        }                                   
                                        status
                                        creationDate
                                        dueDate
                                        finishedDate
                                        nextAuditDate
                                        result {
                                            label
                                            numberOfQuestions
                                            answerResults {
                                                label
                                                numberOfAnswers
                                            }                                 
                                        }
                                        auditor {
                                           ${USER_GRAPHQL}
                                        }
                                        schedule {
                                            businessId
                                            nextSchedule
                                            duration
                                            reoccurance {
                                                unit
                                                amount
                                            }
                                        }
                                        interval {
                                            start
                                            end
                                            days
                                        }
                                        answers {
                                            businessId
                                            severity
                                            value {
                                                point
                                            }                                      
                                            question {                                               
                                                businessId
                                                translations {
                                                    comment
                                                    language {
                                                        code
                                                    }
                                                }
                                            }
                                        }
                                        lastReminder {
                                            sender {
                                                ${USER_GRAPHQL}
                                            }
                                            timestamp
                                        }
                                        shift {
                                            businessId
                                            label
                                            startTime
                                            endTime
                                            durationInHours
                                            ordinal
                                        }
                                }  
                            }
                           `,
                        variables: {
                            filter: input
                        }
                    }
                }
            }
        })
    };
}