export default {
    mapStateToProps: (state) => {
        return {
            isAuditConfigLoaded: state.generalConfigurationReducer.auditConfiguration.ready,
            selectedOrganizationLevel: state.organizationTreeReducer.selectedOrganizationLevel,
            organizationTreeInitialized: state.organizationTreeReducer.initialized,
        };
    },
    mapDispatchToProps: (dispatch) => {
        return {
        };
    }
};
