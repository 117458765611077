import React, {Component} from 'react';
import {
    withStyles,
    IconButton,
    Typography,
    InputLabel
} from "@material-ui/core";
import DeleteIcon from '@material-ui/icons/Delete';
import H4TextField from 'components/h4-textfield/H4TextField';
import moment from 'moment';
import utils from 'utils/Utils';
import {connect} from 'react-redux';
import connector from './Comment.connect';
import {withTranslation} from "react-i18next";
import {
    onahauBlue,
    alabasterWhite,
    porcelainWhite
} from 'components/colors/Colors.js'
import Flex from 'components/grid/Flex';
import H4Avatar from 'components/avatar/H4Avatar';
import _ from 'lodash';

const styles = theme => ({
    iconButtonRoot: {
        padding: 2,
        fontSize: 10
    },
    commentContainer: {
        marginTop: 10,
    },
    justifyRight: {
        marginLeft: 'auto',
        marginRight: 10
    },
    justifyLeft: {
        marginRight: 'auto',
        marginLeft: 10
    },
    comment: {
        backgroundColor: alabasterWhite,
        borderRadius: 4,
        minWidth: 200,
        padding: 10,
        marginLeft: 10
    },
    input: {
        padding: 10
    }
});

export class InlineComments extends Component {

    state = {
        toBeAddedComment: '',
        commentInFocus: ''
    };

    handleChange = event => {
        this.setState({
            [event.target.name]: event.target.value,
        });
    };

    addComment = () => {
        this.props.addComment(this.state.toBeAddedComment);
        this.setState({
            toBeAddedComment: ''
        });
    };

    render() {
        const {classes, comments, user, deleteComment, t: translate} = this.props;
        const {toBeAddedComment} = this.state;
        const {handleChange, addComment} = this;
        const sortedComments = _.sortBy(comments || [], ['creationDate']);
        return (
            <Flex item container direction={'column'} style={{marginTop: 8}}>
                {
                    sortedComments.map((comment, index) => {
                        const isCurrentUserComment = user.businessId === _.get(comment, 'user.businessId');

                        return <Flex container item key={comment.businessId}
                                     className={classes.commentContainer}
                        >
                            <Flex item container direction={'column'}>
                                <Flex item container alignItems={'center'}>
                                    <InputLabel style={{marginLeft: 45}} shrink>
                                        {`${utils.formattedUserName(comment.user)}, ${moment(comment.creationDate).format(translate("global.dateTimeFormat"))}`}
                                    </InputLabel>
                                    {
                                        isCurrentUserComment &&
                                        <Flex item>
                                            <IconButton classes={{root: classes.iconButtonRoot}}
                                                        onClick={deleteComment(comment.businessId)}>
                                                <DeleteIcon/>
                                            </IconButton>
                                        </Flex>
                                    }
                                </Flex>

                                <Flex item container alignItems={'center'}>
                                    <H4Avatar {...{user: comment.user}}/>
                                    <Flex item className={classes.comment}
                                          style={{backgroundColor: isCurrentUserComment ? onahauBlue : porcelainWhite}}>
                                        <Typography>
                                            {comment.description}
                                        </Typography>
                                    </Flex>
                                </Flex>
                            </Flex>
                        </Flex>
                    })
                }
                <Flex item container style={{marginTop: 10}} alignItems={'center'}>
                    <H4Avatar {...{user}} style={{marginRight: 10}}/>
                    <H4TextField
                        value={toBeAddedComment}
                        variant={'outlined'}
                        InputProps={{
                            classes: {input: classes.input}
                        }}
                        onChange={handleChange}
                        placeholder={translate("comment.writeComment")}
                        fullWidth
                        onEnterKey={toBeAddedComment ? addComment : () => {
                        }}
                        name="toBeAddedComment"
                        id="toBeAddedComment-input"
                    />
                </Flex>
            </Flex>
        );
    }
}

export default withStyles(styles)(connect(connector.mapStateToProps, connector.mapDispatchToProps)(withTranslation()(InlineComments)));
