import React, {Component} from 'react';
import {withStyles, Snackbar, SnackbarContent, Typography, IconButton} from "@material-ui/core";
import {Trans} from "react-i18next";
import {htmlWhite} from "components/colors/Colors";
import {laPalmaGreen} from "components/colors/Colors";

const styles = ({
    snackbar: {
        paddingBottom: 10,
    },
    snackbarContent: {
        backgroundColor: laPalmaGreen,
        justifyContent: 'center'
    },
    snackbarText: {
        fontSize: 13,
        color: htmlWhite,
    },
    action: {
        margin: 0,
    },
    answer: {
        '&:hover': {
            cursor: 'pointer'
        }
    }
});

export class ConnectionBackMessage extends Component {
    render() {
        const {classes, open, toggleOnline, onClose} = this.props;

        return (
            <Snackbar
                className={classes.snackbar}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                open={open}
                onClose={onClose}
            >
                <SnackbarContent
                    className={classes.snackbarContent}
                    classes={{action: classes.action}}
                    message={
                        <div>
                            <Typography className={classes.snackbarText}>
                                <Trans i18nKey={"global.good-internet-connection"}/>
                            </Typography>
                            <Typography className={classes.snackbarText}>
                                <Trans i18nKey={"global.go-online"}/>
                            </Typography>
                        </div>
                    }
                    action={[
                        <IconButton style={{padding: 7}} key={'snackbarAction'} color="inherit"
                                    onClick={() => toggleOnline()} className={classes.snackbarText}>
                            <Trans i18nKey={"global.yes"}/>
                        </IconButton>,
                        <IconButton key="close" aria-label="close" color="inherit" onClick={onClose}
                                    className={classes.snackbarText}
                                    style={{marginLeft: 15, padding: 7, marginRight: -10}}>
                            <Trans i18nKey={"global.no"}/>
                        </IconButton>,
                    ]}
                />
            </Snackbar>
        )
    }
}

export default withStyles(styles)(ConnectionBackMessage)