import * as actionTypes from "./AuditReportingPareto.action.types";
import _ from 'lodash';
import {mapQuestion} from "domain/audit/Questionnaire.model"

const initialState = {
    statistics: [],
    questionStatistics: [],
    catalogueStatistics: [],
};

export default function (state = initialState, action) {
    switch (action.type) {
        case actionTypes.FETCH_AUDIT_QUESTION_STATISTICS_SUCCESS: {
            const newState = _.cloneDeep(state);
            const fetchAuditQuestionStatisticsData = _.get(action, 'payload.data.data.fetchAuditQuestionStatistics');
            if (fetchAuditQuestionStatisticsData) {
                newState.statistics = mapStatistics(fetchAuditQuestionStatisticsData);
                newState.questionStatistics = mapQuestionStatistics(fetchAuditQuestionStatisticsData);
                newState.catalogueStatistics = mapCatalogueStatistics(fetchAuditQuestionStatisticsData);
            }
            return newState;
        }
        default:
            return state;
    }
}

export function mapStatistics(fetchAuditQuestionStatisticsData) {
    let statistics = [];
    if (!_.isEmpty(fetchAuditQuestionStatisticsData.statistics)) {
        const numberOfAudits = fetchAuditQuestionStatisticsData.statistics.map(statistic => statistic.count).reduce((total, num) => total + num);
        statistics = fetchAuditQuestionStatisticsData.statistics.map(statistic => mapAuditStatistic(statistic, numberOfAudits));
    }
    return statistics;
}

export function mapQuestionStatistics(fetchAuditQuestionStatisticsData) {
    let questionStatistics = [];
    if (!_.isEmpty(fetchAuditQuestionStatisticsData.questionStatistics)) {
        const numberOfMostFailedQuestion = fetchAuditQuestionStatisticsData.questionStatistics[0].count;
        questionStatistics = fetchAuditQuestionStatisticsData.questionStatistics.map(questionStatistic => mapQuestionStatistic(questionStatistic, numberOfMostFailedQuestion));
    }
    return questionStatistics;
}

export function mapCatalogueStatistics(fetchAuditQuestionStatisticsData) {
    let catalogueStatistics = [];
    if (!_.isEmpty(fetchAuditQuestionStatisticsData.catalogueStatistics)) {
        const numberOfMostFailedCatalogue = fetchAuditQuestionStatisticsData.catalogueStatistics[0].count;
        catalogueStatistics = fetchAuditQuestionStatisticsData.catalogueStatistics.map(catalogueStatistic => mapCatalogueStatistic(catalogueStatistic, numberOfMostFailedCatalogue));
    }
    return catalogueStatistics;
}

export function mapAuditStatistic(auditStatistic, numberOfAudits) {
    return {
        count: auditStatistic.count,
        outcome: auditStatistic.outcome,
        percentage: Math.round(auditStatistic.count * 100 / numberOfAudits),
        all: numberOfAudits,
    }
}

export function mapQuestionStatistic(questionStatistic, numberOfMostFailedQuestion) {
    return {
        count: questionStatistic.count,
        outcome: questionStatistic.outcome,
        question: mapQuestion(questionStatistic.question),
        relativeFailurePercentage: Math.round(questionStatistic.count * 100 / numberOfMostFailedQuestion),
    }
}

export function mapCatalogueStatistic(catalogueStatistic, numberOfMostFailedCatalogue) {
    return {
        catalogue: catalogueStatistic.catalogue,
        count: catalogueStatistic.count,
        outcome: catalogueStatistic.outcome,
        relativeFailurePercentage: Math.round(catalogueStatistic.count * 100 / numberOfMostFailedCatalogue),
    }
}