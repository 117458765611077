import React, {Component} from 'react';
import {NavLink} from 'react-router-dom';
import {
    Drawer,
    List,
    ListItem,
    ListItemIcon,
    Tooltip,
    withStyles,
} from '@material-ui/core';
import {withRouter} from 'react-router-dom';
import _ from 'lodash';
import {tallPoppyRed, htmlWhite, silver, sideBarColor} from 'components/colors/Colors';
import Flex from 'components/grid/Flex';
import {LANDING, subRoutes} from 'routes/routes';
import {Trans, withTranslation} from 'react-i18next';
import {sideBarIndex} from 'components/zindex/zIndex'
import Profile from 'components/profile/Profile';
import WifiOffIcon from '@material-ui/icons/WifiOff';
import {connect} from 'react-redux';
import connector from './Sidebar.connector';

export const styles = theme => ({
    active: {
        background: `linear-gradient(90deg, ${htmlWhite} 10%, transparent 0%)`,
    },
    navlink: {
        textDecoration: 'none',
        color: 'unset'
    },
    drawerRoot: {
        width: 60,
        backgroundColor: sideBarColor,
        zIndex: sideBarIndex,
        overflow: 'visible'
    },
    paperRoot: {
        width: 60,
        backgroundColor: 'inherit',
        zIndex: sideBarIndex,
        overflow: 'visible'
    },
    drawerList: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column'
    },
    sidebarContent: {
        flex: 1,
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column'
    },
    icon: {
        width: 25,
        height: 25,
        color: htmlWhite,
        fill: htmlWhite
    },
    listItemButton: {
        '&:hover': {
            background: `linear-gradient(90deg, ${silver} 10%, transparent 0%)`,
        }
    },
    paperAnchorDockedLeft: {
        border: 'unset'
    },
    wifiIcon: {
        width: 30,
        height: 30,
        fill: tallPoppyRed,
    },
});

const SidebarListItem = (props) => {
    const {classes, route} = props;
    return (
        <Tooltip
            placement="right-start"
            disableFocusListener
            title={<Trans i18nKey={route.subtitle}/>}>
            <ListItem button
                      component={ForwardNavLink}
                      classes={{
                          button: classes.listItemButton
                      }}
                      to={route.path}
                      activeClassName={route.path !== LANDING.path ? classes.active : ''}>
                <ListItemIcon style={{minWidth: 0}}>
                    <route.icon className={classes.icon}/>
                </ListItemIcon>
            </ListItem>
        </Tooltip>
    );
};

const SidebarHeader = (props) => {
    const {classes} = props;
    return <SidebarListItem classes={classes} route={LANDING}/>;
};

const SidebarContent = (props) => {
    const {classes, routes} = props;
    return (
        <div className={classes.sidebarContent}>
            {
                routes.map((route, key) => {
                    return (
                        <SidebarListItem classes={classes} route={route} key={key}/>
                    );
                })
            }
        </div>
    );
};

const Footer = (props) => {
    const {classes, online, toggleOnline} = props;
    return <Flex container direction={'column'} alignItems={'center'}>
        {
            !online &&
            <Flex item container justifyContent={'center'} onClick={toggleOnline}>
                <WifiOffIcon className={classes.wifiIcon}/>
            </Flex>
        }
        <Profile/>
    </Flex>
};

const ForwardNavLink = React.forwardRef((props, ref) => ( <NavLink {...props} innerRef={ref} /> ));

export class Sidebar extends Component {

    render() {
        const {classes, location, toggleOnline, online} = this.props;
        const nestedRoutes = matchingNestedRoutes(location.pathname);
        const isSidebarVisible = location.pathname !== '/';
        return (
            isSidebarVisible &&
            <Drawer
                variant="persistent"
                classes={{
                    root: classes.drawerRoot,
                    paper: classes.paperRoot,
                    paperAnchorDockedLeft: classes.paperAnchorDockedLeft
                }}
                open={true}
                anchor={'left'}
            >
                <List className={classes.drawerList}>
                    <SidebarHeader classes={classes}/>
                    <SidebarContent classes={classes} routes={nestedRoutes}/>
                    <Footer {...{classes, online, toggleOnline}}/>
                </List>
            </Drawer>
        );
    }
}

export function matchingNestedRoutes(currentPath) {
    const betweenFirstTwoForwardSlashes = /\/(.*?)\//;
    const matches = currentPath.match(betweenFirstTwoForwardSlashes) || [];
    const mainRoute = matches[1];
    const matchingSubRoute = subRoutes.find(subRoute => subRoute.mainRoute.path.includes(mainRoute));
    return _.get(matchingSubRoute, 'subRoutes', []);
}

export default withRouter(withStyles(styles)(withTranslation()(connect(connector.mapStateToProps, connector.mapDispatchToProps)(Sidebar))));
