import React, {PureComponent} from 'react';
import {withStyles} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import CircleBar from "components/progress-bar/CircleBar";
import Flex from "components/grid/Flex";
import {dustyGrey} from "components/colors/Colors";


const styles = theme => ({
    container: {
        marginTop: '1vw'
    },
    title: {
        color: dustyGrey,
        textTransform: 'capitalize',
        fontSize: '1vw',
    },
});

export class StatisticCircle extends PureComponent {
    render() {
        const {classes, title, statisticItem, color} = this.props;

        return (
            <Flex item container direction={'column'} alignItems={'center'} className={classes.container}>
                <Typography className={classes.title}>
                    {title}
                </Typography>
                {
                    statisticItem
                        ?
                        <CircleBar {...{progressValue: statisticItem.percentage, color}}/>
                        :
                        <CircleBar {...{progressValue: 0, color, smaller: true}}/>
                }
            </Flex>
        );
    }
}
export default withStyles(styles)(StatisticCircle);
