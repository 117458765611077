import React, { Component } from 'react';
import { TextField } from "@material-ui/core";

export class H4TextField extends Component {

    selectAllOnFocus = event => {
        event.target.select && event.target.select();
    };

    handleKeyPress = (onEnterKey, onKeyPress) => event => {
        const isEnterPressed = (event.key && event.key === 'Enter') || (event.keyCode && event.keyCode === 13);
        const isShiftPressed = event.shiftKey;
        if (isEnterPressed && !isShiftPressed){
            onEnterKey && onEnterKey(event);
        } else {
            onKeyPress && onKeyPress(event);
        }
    };

    render() {
        const {
            onEnterKey, onKeyPress, onFocus, ...otherProps
        } = this.props;

        return (
            <TextField
                onKeyPress={this.handleKeyPress(onEnterKey, onKeyPress)}
                onFocus={onFocus ? onFocus : this.selectAllOnFocus}
                {...otherProps}
            />
        );
    }
}

export default H4TextField;
