import React, {Component} from 'react';
import {Button, Paper, withStyles} from "@material-ui/core";
import ImageMarker from "components/image-marker/ImageMarker";
import {connect} from 'react-redux';
import connector from './DocumentMachineStatus.connect';
import {Trans, withTranslation} from "react-i18next";
import Flex from 'components/grid/Flex';
import _ from 'lodash';
import DocumentMachineStatusPopup from "./components/DocumentMachineStatusPopup";
import DocumentMachineStatusMarker from "./components/DocumentMachineStatusMarker";
import isEqual from "react-fast-compare";
import DocumentMachineStatusExcelExportPopup from "./components/DocumentMachineStatusExcelExportPopup";
import utils from 'utils/Utils';
import configs from "configs/Configs";
import moment from 'moment';
import {htmlWhite} from 'components/colors/Colors.js'
import HeaderTitle from 'components/header/HeaderTitle';
import SynchronizationEventBus from "scenes/SynchronizationEventBus";

export const styles = theme => ({
    paper: {
        position: 'absolute',
        marginTop: 20,
        backgroundColor: htmlWhite,
        display: 'flex',
        flexDirection: 'column',
        flex: '1 1 auto',
    },
    map: {
        minWidth: 800,
    }
});

export const levelBusinessId = "ivcpkatqWS"; // TODO remove hardcoded level

export class DocumentMachineStatus extends Component {

    state = {markers: [], dialogOpen: false, dimensions: null, excelExportDialogOpen: false};

    componentDidMount() {
        this.initializeData();
        this.synchronizationSubscription = SynchronizationEventBus.update.subscribe(() => this.initializeData());
        this.setState({
            dimensions: {
                width: this.container.offsetWidth,
            },
        });
        window.addEventListener("resize", this.updateDimensions);
    }

    componentWillUnmount() {
        SynchronizationEventBus.update.unSubscribe(this.synchronizationSubscription);
    }

    initializeData = () => {
        this.props.fetchUsers();
        this.props.fetchOrganizationLevel(levelBusinessId).then(() => {
            const machineIds = this.props.markers.map(marker => marker.businessId);
            if (machineIds.length !== 0) {
                this.props.fetchAllMachineDetails(machineIds);
            }
        });
        this.props.fetchMachineStatusConfigurationData();
    };

    updateDimensions = () => {
        this.setState({
            dimensions: {
                width: _.get(this.container, "offsetWidth", 0)
            },
        });
    };

    loadMap = (markers) => {
        this.setState({markers: markers});

    };

    calculateMarkerPositions(markers, width, height) {
        return markers.map(marker => {
            const originalWidth = this.props.map.width;
            const originalHeight = this.props.map.height;
            const adjustedXCoordinate = (width / originalWidth) * marker.x;
            const adjustedYCoordinate = (height / originalHeight) * marker.y;
            return {
                ...marker,
                shape: "circle",
                coords: [adjustedXCoordinate, adjustedYCoordinate]
            }
        });
    }

    onMarkerClick = (machine) => {
        this.props.fetchMachineDetails(machine).then(() =>
            this.setState({dialogOpen: true})
        );
    };

    onExcelExportButtonClick = () => {
        this.setState({excelExportDialogOpen: true});
    };

    onDialogClose = (machineStatus) => {
        if (machineStatus && machineStatus.machineBusinessId) {
            this.props.updateMachineStatus(machineStatus).then(() => {
                this.props.fetchOrganizationLevel(levelBusinessId).then(() => {
                    this.setState({dialogOpen: false});
                });
            });
        } else {
            this.setState({dialogOpen: false});
        }
    };

    onExcelExportDialogClose = (input) => {
        if (input) {
            const from = moment.utc(input.fromDate).format(utils.SHORT_API_DATE_FORMAT);
            const to = moment.utc(input.toDate).format(utils.SHORT_API_DATE_FORMAT);
            const url = configs.getBackendServiceUrl() + "/api/report/machine-status/" + levelBusinessId + "/" + from + "/" + to + "/";
            utils.triggerNativeDownload(url, "machine-status" + from + "-" + to + ".xlsx");
            setTimeout(() => {
                this.setState({excelExportDialogOpen: false});
            }, 1000)
        } else {
            this.setState({excelExportDialogOpen: false});

        }
    };

    render() {
        const {
            classes, map, routeProps, machine, markers, statuses, users, applicationOnline, t: translate
        } = this.props;
        const {dialogOpen, dimensions, excelExportDialogOpen} = this.state;
        const {onMarkerClick, onDialogClose, onExcelExportDialogClose, onExcelExportButtonClick} = this;
        const width = _.get(dimensions, "width", 0);
        const height = _.get(map, "height", 0) && (map.height * (width / map.width));
        const historyUpdated = !isEqual(markers.map(m => m.history), this.state.markers.map(m => m.history));
        return (
            <Flex item container direction={'column'} style={{margin: 30}}>
                <HeaderTitle title={translate('routes.kpi.document-machine-status.subtitle')}/>
                <Flex item grow={1} basis={'100%'} style={{marginTop: 20}}>
                    <div ref={el => (this.container = el)} className={classes.popup}>
                        {
                            dimensions &&
                            <Paper className={classes.paper}>
                                {
                                    !_.isEmpty(map) &&
                                    <ImageMarker src={map.url}
                                                 map={{areas: this.calculateMarkerPositions(markers, width, height)}}
                                                 width={width}
                                                 height={height}
                                                 opacity={'0.3'}
                                                 reload={historyUpdated}
                                                 onLoad={(markers) => this.loadMap(markers)}
                                    />
                                }
                                {
                                    this.state.markers.length > 0 &&
                                    this.state.markers.map(marker => {
                                        return (
                                            <DocumentMachineStatusMarker key={marker.businessId} {...{
                                                marker,
                                                routeProps,
                                                applicationOnline,
                                                onMarkerClick
                                            }} />
                                        );
                                    })
                                }
                            </Paper>
                        }
                    </div>
                    {
                        this.state.dialogOpen && !_.isEmpty(machine) &&
                        <DocumentMachineStatusPopup {...{
                            routeProps,
                            dialogOpen,
                            machine,
                            statuses,
                            users,
                            onDialogClose,

                        }} />
                    }
                    {
                        this.state.excelExportDialogOpen &&
                        <DocumentMachineStatusExcelExportPopup {...{
                            excelExportDialogOpen,
                            onExcelExportDialogClose
                        }} />
                    }
                </Flex>
                <Flex container item justifyContent={"flex-end"} alignItems={"flex-end"}>
                    <Button onClick={onExcelExportButtonClick}
                            color="primary"
                            disabled={!applicationOnline}
                            variant="contained">
                        <Trans i18nKey={"kpi-management.document-machine-status.excelExport"}/>
                    </Button>
                </Flex>
            </Flex>
        );
    }
}

export default withStyles(styles)(connect(connector.mapStateToProps, connector.mapDispatchToProps)(withTranslation()(DocumentMachineStatus)));
