import {combineReducers} from 'redux';
import questionEditReducer from 'scenes/audit-manager/administration/question-edit/QuestionEdit.reducer';
import questionsReducer from 'scenes/audit-manager/administration/questions/Questions.reducer';
import questionnairesReducer from 'scenes/audit-manager/administration/questionnaires/Questionnaires.reducer';
import taskEditReducer from 'scenes/tasks/task-edit/TaskEdit.reducer';
import taskListReducer from 'scenes/tasks/task-list/TaskList.reducer';
import auditReducer from 'scenes/audit/Audit.reducer';
import mainReducer from 'scenes/Main.reducer';
import actionEditReducer from 'scenes/tasks/actions/action-edit/ActionEdit.reducer';
import mobileLandingReducer from 'scenes/landing/mobile/MobileLanding.reducer';
import documentMachineStatusReducer from "scenes/kpi-monitoring/document-machine-status/DocumentMachineStatus.reducer";
import socketReducer from 'socket/Socket.reducer';
import tasksReducer from 'scenes/tasks/Tasks.reducer';
import projectReducer from 'scenes/tasks/Project.reducer';
import myActionListReducer from 'scenes/tasks/actions/my-action-list/MyActionList.reducer';
import organizationTreeReducer from 'scenes/audit-manager/administration/organization-tree/OrganizationTree.reducer'
import questionnaireEditReducer from 'scenes/audit-manager/administration/questionnaire-edit/QuestionnaireEdit.reducer';
import schedulesReducer from 'scenes/audit-manager/administration/schedules/Schedules.reducer'
import scheduleEditReducer from 'scenes/audit-manager/administration/schedule-edit/ScheduleEdit.reducer'
import monitoringReducer from 'scenes/audit-manager/administration/monitoring/Monitoring.reducer'
import taskReportingReducer from 'scenes/tasks/reporting/TaskReporting.reducer';
import auditReportingMapReducer from "../scenes/audit-manager/reporting/map/AuditReportingMap.reducer";
import auditReportingHistoryReducer from 'scenes/audit-manager/reporting/history/AuditReportingHistory.reducer';
import auditReportingParetoReducer from 'scenes/audit-manager/reporting/pareto/AuditReportingPareto.reducer';
import organizationLevelConfigurationReducer from 'scenes/audit-manager/configuration/organization-level/OrganizationLevelConfiguration.reducer';
import generalConfigurationReducer from 'scenes/configuration/GeneralConfiguration.reducer';
import auditTypesReducer from 'scenes/configuration/audit-types/AuditTypes.reducer';

export default combineReducers({
    questionEditReducer,
    questionsReducer,
    questionnairesReducer,
    taskEditReducer,
    taskListReducer,
    auditReducer,
    mainReducer,
    actionEditReducer,
    mobileLandingReducer,
    documentMachineStatusReducer,
    socketReducer,
    tasksReducer,
    projectReducer,
    myActionListReducer,
    organizationTreeReducer,
    questionnaireEditReducer,
    schedulesReducer,
    scheduleEditReducer,
    monitoringReducer,
    taskReportingReducer,
    auditReportingMapReducer,
    auditReportingHistoryReducer,
    auditReportingParetoReducer,
    organizationLevelConfigurationReducer,
    generalConfigurationReducer,
    auditTypesReducer,
});
