import React, {Component} from 'react';
import {withStyles, Snackbar, SnackbarContent, IconButton} from "@material-ui/core";
import {Trans} from "react-i18next";
import {medium, htmlWhite} from "components/colors/Colors";
import RefreshIcon from "@material-ui/icons/Refresh";

const styles = theme => ({
    snackbar: {
        paddingBottom: 10,
    },
    snackbarContent: {
        backgroundColor: medium,
        justifyContent: 'center'
    },
    snackbarText: {
        fontSize: 12,
        color: htmlWhite,
    },
    action: {
        margin: 0,
    },
});

export class VersionMessage extends Component {
    render() {
        const {classes, open, onClose, onClick} = this.props;

        return (
            <Snackbar
                className={classes.snackbar}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                open={open}
                onClose={onClose}
            >
                <SnackbarContent
                    className={classes.snackbarContent}
                    classes={{action: classes.action}}
                    message={
                        <span className={classes.snackbarText}>
                            <Trans i18nKey={"global.newVersionAvailable"}/>
                        </span>
                    }
                    action={[
                        <IconButton style={{padding: 6}} key={'snackbarAction'} onClick={onClick}>
                            <RefreshIcon style={{fontSize: 25}}/>
                        </IconButton>
                    ]}
                />
            </Snackbar>
        )
    }
}

export default withStyles(styles)(VersionMessage)