import * as actionTypes from './MobileLanding.action.types';

export function fetchCounters() {
    return (dispatch, getState) => {
        //NOTE LM: Derive audit/task counter from indexedDB here, and dispatch a new action with the result for the reducer to use
        //NOTE LM: The indexedDB is still filled during the respective components listTasks/listAudits cycle, and not here
        dispatch(fetchAudits())
            .then()
            .catch(
                (error) => console.log(error)
            );
        dispatch(listTasksForCounter())
            .then()
            .catch(
                (error) => console.log(error)
            );
    }
}

function fetchAudits() {
    return (dispatch, getState) => {
        return dispatch({
            type: actionTypes.FETCH_AUDITS,
            payload: {
                client: 'graphql',
                request: {
                    method: 'post',
                    data: {
                        query: `
                            query($auditorId: String!){
                                    listCurrentAuditsForAuditor(auditorId: $auditorId) {
                                       businessId
                                        status
                                }
                            }
                           `,
                        variables: {
                            auditorId: getState().mainReducer.user.businessId
                        }
                    }
                }
            }
        })
    };
}

export function updateCounters(numberOfUnseenTasks) {
    return {
        type: actionTypes.UPDATE_COUNTERS,
        data: {
            numberOfUnseenTasks: numberOfUnseenTasks,
        }
    };
}

function listTasksForCounter() {
    return (dispatch, getState) => {
        return dispatch({
            type: actionTypes.LIST_TASKS_FOR_COUNTER,
            data: {user: getState().mainReducer.user},
            payload: {
                client: 'graphql',
                request: {
                    method: 'post',
                    data: {
                        query: `
                            {
                                listTasks {
                                    businessId
                                    tags {
                                        label
                                    }
                                    assignee {
                                        businessId
                                    }
                                }
                            }
                           `
                    }
                }
            }
        })
    };
}

