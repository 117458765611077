import {
    string,
    number,
} from "prop-types"

export const PRIORITY = {
    type: string.isRequired,
    ordinal: number
};

export const PRIORITY_GRAPHQL = `
    type
    ordinal
`;
