import {removeUnseenAttachmentTag, removeUnseenCommentTag} from 'scenes/tasks/Tags.action'
import {fetchActionFromServer, selectAction} from 'scenes/tasks/actions/my-action-list/MyActionList.action';
import {createAction} from 'scenes/tasks/actions/action-edit/ActionEdit.action'
import {archiveAction} from 'scenes/tasks/actions/action-list/ActionList.action'
import {toggleProjectFilterSelection} from 'scenes/tasks/Project.action';

export default {
    mapStateToProps: (state) => {
        return {
            actions: state.myActionListReducer.actions,
            user: state.mainReducer.user,
            forceRerender: state.tasksReducer.forceRerender,
            applicationOnline: state.mainReducer.online,
            configurationFromBackend: state.tasksReducer.configurationFromBackend,
            tagSuggestions: state.tasksReducer.configuration.freeTags,
            selectedAction: state.myActionListReducer.selectedAction,
            selectedProject: state.projectReducer.selectedProject,
            personalProject: state.projectReducer.personalProject,
            todoTaskBusinessId: state.tasksReducer.todoTaskBusinessId,
            selectedUserNames: state.projectReducer.selectedUserNames,
        };
    },
    mapDispatchToProps: (dispatch) => {
        return {
            archiveAction: action => dispatch(archiveAction(action)),
            createAction: (taskId, action) => dispatch(createAction(taskId, action)),
            removeUnseenCommentTag: task => dispatch(removeUnseenCommentTag(task)),
            removeUnseenAttachmentTag: task => dispatch(removeUnseenAttachmentTag(task)),
            selectAction: action => dispatch(selectAction(action)),
            fetchActionFromServer: actionId => dispatch(fetchActionFromServer(actionId)),
            toggleProjectFilterSelection: businessId => dispatch(toggleProjectFilterSelection(businessId)),
        };
    }
};
