import _ from "lodash";
import {isOrganizationAdmin} from "scenes/audit-manager/administration/organization-tree/OrganizationTree.reducer";
import {ACTIVE} from "./Schedule.model";
import {mapCatalogueLabel, mapCatalogueString} from "./AuditConfiguration.model";

export const APPROVED = 'APPROVED';
export const PENDING = 'PENDING';


export function isActive(status) {
    return status === APPROVED;
}

export function mapQuestionnaireForCreate(data) {
    return {
        name: data.name,
        cycle: data.cycle,
        auditType: data.auditType,
        organizationLevelIds: data.organizationLevelIds,
        randomizedQuestions: mapRandomizedQuestionInputs(data.randomQuestionsByCatalogue),
        fixQuestionIds: data.questionIds,
        status: data.status,
        topics: data.topics
    };
}

export function mapQuestionnaireForEdit(data) {
    return {
        ...mapQuestionnaireForCreate(data),
        businessId: data.businessId
    };
}

export function mapOrganizationLevels(questionnaire, user) {
    return questionnaire.organizationLevels
        .map(organizationLevel => {
            return {
                businessId: organizationLevel.businessId,
                isUserAdmin: isOrganizationAdmin(user, organizationLevel),
                title: organizationLevel.shortName
            }
        });
}

export function mapRandomizedQuestionInputs(randomQuestionsByCatalogue) {
    return Object.keys(randomQuestionsByCatalogue).map(value => {
        return {
            numberOfQuestions: randomQuestionsByCatalogue[value],
            catalogue: value
        }
    });
}

export function mapRandomizedQuestions(randomizedQuestions) {
    return randomizedQuestions.map(randomizedQuestion => {
        return {
            catalogue: randomizedQuestion.catalogue.toLowerCase(),
            numberOfQuestions: randomizedQuestion.numberOfQuestions,
        }
    })
}

function mapRandomQuestionsByCatalogue(questionnaire) {
    const randomQuestionsByCatalogue = {};
    mapRandomizedQuestions(questionnaire.randomizedQuestions).forEach(r => {
        randomQuestionsByCatalogue[r.catalogue] = r.numberOfQuestions
    });
    return randomQuestionsByCatalogue;
}

export function mapTranslations(translations = []) {
    return translations.map(translation => ({
        language: {
            code: translation.language.code,
            label: translation.language.label,
            ordinal: translation.language.ordinal
        },
        question: translation.question || '',
        quickFix: translation.quickFix || '',
        status: translation.status
    }));
}

export function emptyQuestionnaire() {
    return {
        businessId: '',
        name: '',
        catalogue: '',
        auditType: {},
        type: '',
        cycle: '',
        active: false,
        status: PENDING,
        assignedAuditSchedules: [],
        isAssignedToAuditSchedule: false,
        organizationLevelIds: [],
        topics: []
    }
}

export function mapQuestions(questions) {
    return questions.map(question => mapQuestion(question))
}

export function mapQuestion(question) {
    return {
        ...question,
        businessId: question.businessId,
        status: question.status,
        translations: mapTranslations(question.translations),
        catalogue: mapCatalogueLabel(question.catalogue)
    }
}

function getAssignedActiveSchedules(questionnaire) {
    return questionnaire.assignedAuditSchedules
        .filter(assignedAuditSchedule => assignedAuditSchedule.status === ACTIVE)
        .map(assignedAuditSchedule => assignedAuditSchedule.name);
}

export function mapQuestionnairesForListing(questionnaires, user) {
    return questionnaires.map(questionnaire => {
        const assignedAuditSchedules = getAssignedActiveSchedules(questionnaire);
        return {
            businessId: questionnaire.businessId,
            name: questionnaire.name,
            catalogue: mapCatalogueString(questionnaire.catalogue),
            numberOfQuestions: questionnaire.numberOfQuestions,
            numberOfRandomizedQuestions: questionnaire.numberOfRandomizedQuestions,
            cycle: questionnaire.cycle.label,
            organizationLevels: mapOrganizationLevels(questionnaire, user),
            status: questionnaire.status,
            active: isActive(questionnaire.status),
            questionIds: questionnaire.questions.map(q => q.businessId),
            randomQuestionsByCatalogue: mapRandomQuestionsByCatalogue(questionnaire),
            assignedAuditSchedules: assignedAuditSchedules,
            isAssignedToAuditSchedule: assignedAuditSchedules.length > 0,
            haveQuestions: (_.get(questionnaire, 'numberOfQuestions', 0) +
                _.get(questionnaire,'numberOfRandomizedQuestions', 0)) > 0,
        }
    })
}

export function mapQuestionnaire(questionnaire, user) {
    const assignedAuditSchedules = getAssignedActiveSchedules(questionnaire);
    return {
        ...questionnaire,
        businessId: questionnaire.businessId,
        name: questionnaire.name,
        catalogue: mapCatalogueString(questionnaire.catalogue),
        cycle: questionnaire.cycle.label,
        status: questionnaire.status,
        questions: mapQuestions(questionnaire.questions),
        randomQuestionsByCatalogue: mapRandomQuestionsByCatalogue(questionnaire),
        assignedAuditSchedules: assignedAuditSchedules,
        isAssignedToAuditSchedule: assignedAuditSchedules.length > 0,
        organizationLevels: mapOrganizationLevels(questionnaire, user),
        auditType: questionnaire.auditType,
        topics: questionnaire.topics,
    }
}
