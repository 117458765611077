import React, {Component} from 'react';
import {Switch, Route, Redirect} from 'react-router-dom';
import {AUDIT_ADMINISTRATION, AUDIT_REPORTING, AUDIT_CONFIGURATION} from 'routes/routes';
import {connect} from "react-redux";
import connector from "./AuditManagement.connect";

export class AuditManagement extends Component {

    componentDidMount() {
        this.props.fetchAuditManagementConfigurationData();
    }

    render() {
        return (
            <Switch>
                <Route path={AUDIT_ADMINISTRATION.path}
                       component={AUDIT_ADMINISTRATION.component}
                />
                <Route path={AUDIT_REPORTING.path}
                       component={AUDIT_REPORTING.component}
                />
                <Route path={AUDIT_CONFIGURATION.path}
                       component={AUDIT_CONFIGURATION.component}
                />
                <Redirect to={AUDIT_ADMINISTRATION.path}/>
            </Switch>
        );
    }
}

export default connect(connector.mapStateToProps, connector.mapDispatchToProps)(AuditManagement);
