import React, {Component} from 'react';
import {connect} from "react-redux";
import connector from "./UserManagement.connect";

export class UserManagement extends Component {

    componentDidMount() {
    }

    render() {
        return (
            <span>User management</span>
        );
    }
}

export default connect(connector.mapStateToProps, connector.mapDispatchToProps)(UserManagement);
