import {mapUser, USER_GRAPHQL} from 'domain/User.model';
import {ATTACHMENT_GRAPHQL, mapAttachment} from "domain/Attachment.model";

export const ORGANIZATION_LEVEL_GRAPHQL = `
    businessId
    shortName
    parentLevel {
        businessId
        shortName
    }
    members {
        memberships
        user {
            ${USER_GRAPHQL}
        }
    }
    parentHierarchy {
        organizationLevelBusinessId
        organizationLevelShortName
        ordinal
    }
    childLevels {
        businessId
        shortName
        members {
            memberships
            user {
                ${USER_GRAPHQL}
            }
         }               
    }
    ordinal
    timeZone
    attachments {
        ${ATTACHMENT_GRAPHQL}
    }
    shiftSchedule {
        businessId
        label
        shifts {
            businessId
            label
            startTime
            endTime
            durationInHours
            ordinal
        }
    }
`;

export const ORGANIZATION_LEVEL_WITH_MAP_GRAPHQL = `
    ${ORGANIZATION_LEVEL_GRAPHQL}
    view {
        map {
            source {
                downloadUrl
            }
            resolution {
                height
                width
            }
        }
        childViews {
            businessId
            ordinal
            x
            y                                              
        }
    }    
`;



export const ORGANIZATION_MANAGEMENT_CONFIGURATION_DATA_GRAPHQL = `
  timeZones
`;

export const MEMBERSHIPS = ['AUDITOR', 'OWNER', 'ADMIN'];

export function createOrganizationLevelInput(name, parentBusinessId, ownerId) {
    return {
        shortName: name,
        parentBusinessId,
        ownerId
    }
}

export function updateOrganizationLevelDetailsInput(name, businessId) {
    return {
        businessId,
        shortName: name,
    }
}

export function mapOrganizationLevel(organizationLevel) {
    return {
        businessId: organizationLevel.businessId,
        shortName: organizationLevel.shortName,
        parentLevel: organizationLevel.parentLevel && {
            businessId: organizationLevel.parentLevel.businessId,
            shortName: organizationLevel.parentLevel.shortName
        },
        members: organizationLevel.members.map(mapMember),
        parentHierarchy: organizationLevel.parentHierarchy.map(mapParentHierarchy),
        ordinal: organizationLevel.ordinal,
        timeZone: organizationLevel.timeZone,
        attachments: organizationLevel.attachments && organizationLevel.attachments.map(mapAttachment),
        shiftSchedule: organizationLevel.shiftSchedule,
    }
}

function mapMember(member){
    return {
        memberships: member.memberships,
        user: mapUser(member.user)
    }
}

function mapParentHierarchy(entry){
    return {
        organizationLevelBusinessId: entry.organizationLevelBusinessId,
        organizationLevelShortName: entry.organizationLevelShortName,
        ordinal: entry.ordinal,
    }
}
