import moment from "moment";

function translateDateTime(translate, timestamp) {
    const dateTime = moment(timestamp);
    if (dateTime.isSame(moment(),"day")) {
        return translate('global.dateTimeFormats.time', {
            hour: timestamp.format("HH"),
            minute: timestamp.format("mm"),
        });
    } else {
        return translate('global.dateTimeFormats.dateTime', {
            day: timestamp.date(),
            month: translate('global.month.' + timestamp.format("MMMM")),
            year: timestamp.format("YYYY"),
            hour: timestamp.format("HH"),
            minute: timestamp.format("mm"),
        });
    }
}

const dateTimeUtils = {
    translateDateTime
};

export default dateTimeUtils;