import {
    string,
} from "prop-types"

export const ARCHIVED_STATUS_ID = 'ARCHIVED';

export const STATUS = {
    label: string,
    businessId: string
};

export const STATUS_GRAPHQL = `
    businessId
    label
    ordinal
`;

export function mapStatus(status) {
    return {
        businessId: status.businessId,
        label: status.label,
        ordinal: status.ordinal,
    }
}
