import React, {Component, Fragment} from 'react';
import {
    Button,
    IconButton,
    Switch,
    TextField,
    Typography,
    withStyles,
} from "@material-ui/core";
import _ from 'lodash';
import {
    audit,
    criticalTextField,
    htmlBlack,
    htmlWhite,
    medium,
    mediumHighlight,
    silver as greyColor,
    jungleGreen,
    red,
    blackPearl2,
    sanMarinoBlue,
    transparentBlack02
} from 'components/colors/Colors';
import Flex from 'components/grid/Flex';
import PhotoCamera from '@material-ui/icons/PhotoCamera';
import PhotoOutlined from '@material-ui/icons/PhotoOutlined';
import {Trans, useTranslation, withTranslation} from 'react-i18next';
import {LensOutlined as OKIcon, Close as NOKIcon} from '@material-ui/icons';
import QuestionsMarkIcon from '@material-ui/icons/HelpOutline';
import CloseIcon from '@material-ui/icons/Close';

const styles = theme => ({
    textField: {
        minWidth: 200,
        marginTop: 10,
        borderRadius: 4,
        '@media (min-width: 800px)': {
            fontSize: '1.5em',
        }
    },
    descriptionContainer: {
        minHeight: 60,
        padding: 5
    },
    comment: {
        fontWeight: 600,
        textAlign: 'justify',
        '@media (min-width: 800px)': {
            fontSize: '1.2em',
        }
    },
    //TODO LM material ui 3.9.2 : Referencing css selectors injected by third party along arbitrary rules is fragile, and might break during dependency upgrade
    //TODO LM material ui 3.9.2 : Sadly I'm out of ideas on this one (apart from building one from scratch)
    severitySwitchBase: {
        '&$severitySwitchChecked': {
            color: red,
            '& + $severitySwitchBar': {
                backgroundColor: red,
            },
        },
    },
    severitySwitchBar: {
        width: 90,
        height: 25,
        borderRadius: 15,
        marginTop: -12
    },
    severitySwitchIcon: {
        width: 32,
        height: 32
    },
    severitySwitchChecked: {
        transform: 'translateX(80px)',
    },
    defaultIcon: {
        color: htmlBlack,
        fontSize: 44
    },
    answerButton: {
        height: 45,
        width: 130,
        textTransform: 'uppercase',
        fontWeight: 'bold',
        '@media (min-width: 800px)': {
            width: 200,
            fontSize: '1.4em',
        },
    },
    switchText: {
        textTransform: 'capitalize',
        '@media (min-width: 800px)': {
            fontSize: '1.4em',
        }
    },
    picture: {
        width: '100%',
        height: '100%',
        maxHeight: 350,
        maxWidth: 350,
        objectFit: 'contain',
        backgroundColor: htmlWhite,
        '@media (min-width: 1000px)': {
            maxHeight: 500,
            maxWidth: 500,
        },
    },
    questionContainer: {
        padding: 10,
        '@media (min-width: 900px)': {
            padding: 30,
        },
    },
    questionContainerPicture: {
        marginTop: 20,
        minHeight: 300,
        '@media (min-width: 900px)': {
            margin: 20,
        },
    },
    additionalInformationContainer: {
        flex: '1 1 auto',
        '@media (min-width: 900px)': {
            flex: '0 1 auto',
            margin: 20,
        },
    }
});

const Comment = (props) => {
    const {classes, currentQuestion, handleComment, handleSeverity, readOnly, handleFile, openGallery} = props;
    const {t: translate} = useTranslation();
    return (
        <Flex item container fullWidth direction={'column'}>
            {
                currentQuestion.answer.point === 0 &&
                <Fragment>
                    <Flex item grow={0}>
                        <TextField
                            disabled={readOnly}
                            placeholder={translate('audit-management.execute-audit.comment-placeholder')}
                            variant="outlined"
                            value={currentQuestion.answer.comment}
                            onChange={handleComment}
                            multiline
                            rows="3"
                            classes={{
                                root: classes.textField,
                            }}
                            inputProps={{
                                input: classes.textField,
                            }}
                            style={{
                                backgroundColor: currentQuestion.answer.severity === 'CRITICAL' ? criticalTextField : mediumHighlight,
                                border: `1px solid ${currentQuestion.answer.severity === 'CRITICAL' ? red : medium}`
                            }}
                            margin="none"
                            fullWidth
                        />
                    </Flex>
                    <Flex container item fullWidth alignItems={'center'} grow={0} style={{paddingLeft: 2}}>
                        <Flex item grow={0}>
                            <Typography className={classes.switchText} align={'left'}>
                                <Trans i18nKey={'audit-management.execute-audit.answer-severity.MINOR'}/>
                            </Typography>
                        </Flex>
                        <Switch
                            checked={currentQuestion.answer.severity === 'CRITICAL'}
                            onChange={handleSeverity}
                            disabled={readOnly}
                            classes={{
                                switchBase: classes.severitySwitchBase,
                                bar: classes.severitySwitchBar,
                                icon: classes.severitySwitchIcon,
                                checked: classes.severitySwitchChecked,
                            }}
                            inputProps={{style: {width: 200, left: -67}}}
                        />
                        <Flex item grow={0}>
                            <Typography className={classes.switchText} align={'center'}>
                                <Trans i18nKey={'audit-management.execute-audit.answer-severity.CRITICAL'}/>
                            </Typography>
                        </Flex>
                        <Flex item container justifyContent={'flex-end'} alignItems={'center'}>
                            <Flex item grow={0}>
                                {currentQuestion.answer.point === 0 &&
                                <Fragment>
                                    <input
                                        accept="image/*"
                                        style={{display: 'none'}}
                                        id="contained-button-file"
                                        capture="camera"
                                        type="file"
                                        disabled={readOnly}
                                        onChange={handleFile}
                                    />
                                    <label htmlFor="contained-button-file">
                                        <IconButton component={'span'} style={{padding: 0, marginTop: 4}}
                                                    disabled={readOnly}>
                                            <PhotoCamera className={classes.defaultIcon}
                                                         style={{color: readOnly ? greyColor : audit}}/>
                                        </IconButton>
                                    </label>
                                </Fragment>
                                }
                            </Flex>
                            <Flex item grow={0}>
                                <IconButton style={{padding: 0}}
                                            onClick={openGallery}
                                            disabled={currentQuestion.answer.attachments.length < 1}>
                                    <PhotoOutlined style={{fontSize: 45}}/>
                                    {
                                        currentQuestion.answer.attachments.length > 0 &&
                                        <Typography>({currentQuestion.answer.attachments.length})</Typography>
                                    }
                                </IconButton>
                            </Flex>
                        </Flex>

                    </Flex>
                </Fragment>
            }
        </Flex>
    );
};

const ButtonLabel = (props) => {
    const {answer, isQuestionAnswered} = props;
    const answerLabelTranslationKey = answer.label.toUpperCase();
    return (
        <>
            {(() => {
                switch (answerLabelTranslationKey) {
                    case 'OK': {
                        return (<OKIcon style={{color: !isQuestionAnswered && jungleGreen}}/>);
                    }
                    case 'NOK': {
                        return (<NOKIcon style={{color: !isQuestionAnswered && red}}/>);
                    }
                    default: {
                        return (
                            <Trans i18nKey={'audit-management.execute-audit.answer-type.' + answerLabelTranslationKey}/>
                        )
                    }
                }
            })()}
        </>
    )
};

export class RegularQuestionExecutor extends Component {

    state = {
        showAdditionalInformation: false
    }

    switchAdditionalInformation = () => {
        this.setState({
            showAdditionalInformation: !this.state.showAdditionalInformation
        })
    }

    render() {
        const {
            classes, currentQuestion, handleAnswer, readOnly, i18n, handleComment, handleSeverity, handleFile, openGallery,
            backgroundColor, textColor, t: translate
        } = this.props;
        const {showAdditionalInformation} = this.state;
        const currentTranslation = currentQuestion.translations.find(translation => translation.languageCode === i18n.language) || {};
        const currentPictureUrl = _.get(currentTranslation, 'picture.downloadUrl');

        const fallbackDescription = currentQuestion.translations[0].question || '';
        const fallBackPictureUrl = _.get(currentQuestion.translations[0], 'picture.downloadUrl');

        return (
            <Flex container fullWidth className={classes.questionContainer} column>

                <Flex item grow={0} fullWidth>
                    <div className={classes.descriptionContainer}>
                        <Typography className={classes.comment}>
                            {!_.isEmpty(currentTranslation.question) ? currentTranslation.question : fallbackDescription}
                        </Typography>
                    </div>
                </Flex>
                <Flex container item grow={0} fullWidth justifyContent={'space-between'}>
                    {
                        currentQuestion.possibleAnswers.map((answer, index) => {
                                const isQuestionAnswered = answer.point === currentQuestion.answer.point;
                                return <Button key={index}
                                               onClick={handleAnswer(answer)}
                                               disabled={readOnly}
                                               className={classes.answerButton}
                                               style={{
                                                   backgroundColor: isQuestionAnswered ? backgroundColor : htmlWhite,
                                                   color: isQuestionAnswered ? textColor : htmlBlack,
                                                   border: isQuestionAnswered ? 'none' : `2px solid ${blackPearl2}`
                                               }}
                                >
                                    <ButtonLabel {...{answer, isQuestionAnswered, textColor}}/>
                                </Button>
                            }
                        )
                    }
                </Flex>
                {
                    currentTranslation.comment &&
                    <Flex container item center style={{margin: 10}}
                          onClick={this.switchAdditionalInformation}>
                        {
                            showAdditionalInformation &&
                            <Flex container column padding={15} className={classes.additionalInformationContainer}
                                  style={{
                                      boxShadow: `0 0 8px ${transparentBlack02}`,
                                  }}>
                                <Flex item container alignItems={'center'}
                                      style={{
                                          marginBottom: 15
                                      }}>
                                    <Flex item container center>
                                        <Typography style={{
                                            color: blackPearl2,
                                            textTransform: 'uppercase',
                                            fontWeight: 'bold',
                                            fontSize: 16
                                        }}>
                                            {translate('audit-administration.questionnaireEdit.additional-information')}
                                        </Typography>
                                    </Flex>
                                    <IconButton size={'small'} onClick={this.switchAdditionalInformation}>
                                        <CloseIcon/>
                                    </IconButton>
                                </Flex>

                                <Typography style={{
                                    color: blackPearl2,
                                    fontWeight: 'bold'
                                }}>
                                    {currentTranslation.comment}
                                </Typography>
                            </Flex>
                        }
                        {
                            !showAdditionalInformation &&
                            <>
                                <QuestionsMarkIcon style={{color: sanMarinoBlue, marginRight: 5}}/>
                                <Typography style={{
                                    color: sanMarinoBlue,
                                    textTransform: 'uppercase',
                                    fontWeight: 'bold'
                                }}>
                                    {translate('audit-administration.questionnaireEdit.additional-information')}
                                </Typography>
                            </>
                        }
                    </Flex>

                }
                <Comment {...{
                    classes,
                    currentQuestion,
                    handleComment,
                    handleSeverity,
                    readOnly,
                    handleFile,
                    openGallery
                }}
                />
                {
                    (!_.isEmpty(currentPictureUrl) || !_.isEmpty(fallBackPictureUrl)) &&
                    <Flex container justifyContent={'center'} className={classes.questionContainerPicture}>
                        <img
                            src={!_.isEmpty(currentPictureUrl) ? currentPictureUrl : fallBackPictureUrl}
                            alt={''}
                            className={classes.picture}
                        />
                    </Flex>
                }

            </Flex>
        );
    }
}

export default withStyles(styles)(withTranslation()(RegularQuestionExecutor));
