import {
    addTaskComment,
    deleteTaskComment
} from 'components/comment/Comment.action';
import {fetchTask} from 'scenes/tasks/task-edit/TaskEdit.action';

export default {
    mapStateToProps: (state) => {
        return {
            user: state.mainReducer.user,
            comments: state.taskEditReducer.task.comments,
            taskBusinessId: state.taskEditReducer.task.businessId
        };
    },
    mapDispatchToProps: (dispatch) => {
        return {
            addTaskComment: (taskId, comment) => dispatch(addTaskComment(taskId, comment)),
            deleteTaskComment: commentId => dispatch(deleteTaskComment(commentId)),
            fetchTask: businessId => dispatch(fetchTask(businessId)),
        };
    }
};

