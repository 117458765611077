import React, {Component} from 'react';
import {withStyles, InputBase} from "@material-ui/core";
import _ from 'lodash';
import {htmlWhite, materialUIBorder, transparentAzureBlue025, malibuBlue} from 'components/colors/Colors.js'

export const styles = theme => ({
    root: {
        width: '100%',
    },
    input: {
        borderRadius: 4,
        position: 'relative',
        backgroundColor: htmlWhite,
        border: `1px solid ${materialUIBorder}`,
        fontSize: 16,
        padding: '8px 10px 8px 10px',
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        '&:focus': {
            borderRadius: 4,
            borderColor: malibuBlue,
            boxShadow: `0 0 0 0.2rem ${transparentAzureBlue025}`,
        },
    }
});

export class BootstrapInput extends Component {

    selectAllOnFocus = event => {
        event.target.select && event.target.select();
    };

    handleKeyPress = (onEnterKey, onKeyPress) => event => {
        const isEnterPressed = (event.key && event.key === 'Enter') || (event.keyCode && event.keyCode === 13);
        const isShiftPressed = event.shiftKey;
        if (isEnterPressed && !isShiftPressed) {
            onEnterKey && onEnterKey(event);
        } else {
            onKeyPress && onKeyPress(event);
        }
    };

    render() {
        const {bold, center, inputProps, onEnterKey, onKeyPress, onFocus, classes, ...otherProps} = this.props;
        return (
            <InputBase
                classes={classes}
                inputProps={{
                    style: {
                        ..._.get(inputProps, 'style'),
                        fontWeight: bold ? 'bold' : 'normal',
                        textAlign: center ? 'center' : 'left'
                    },
                    ...inputProps
                }}
                onKeyPress={this.handleKeyPress(onEnterKey, onKeyPress)}
                onFocus={onFocus ? onFocus : this.selectAllOnFocus}
                {...otherProps}
            />
        );
    }
}

export default withStyles(styles)(BootstrapInput);
